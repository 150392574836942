import qs from "qs";
import userService from "../../api/user";

const state = {
  id: null,
  username: null,
  email: null,
  externId: null,
  company: false,
  points: 0,
  picture: null,
  tokens: 0,
  premium: {},
  team: null,
  pendingRequest: false,
  isTeamOwner: false,
  token: null,
  notification: false,
  isLoggedIn: false,
  isHrpRecruiter: false,
  isHrpCompany: false,
  inCorporate: false,
  corporateTokens: 0,
};

const getters = {
  isLoggedIn: (state) => !!state.isLoggedIn,
  token: (state) => state.token,
  hasBeenAskedForNotifications: (state) => state.notifications,
  firstLogin: (state) => state.firstLogin,
};

const actions = {
  /**
   * login
   * @param commit
   * @param creds
   * @returns {Promise<unknown>|boolean}
   */
  login({ commit }, creds) {
    return new Promise((resolve, reject) => {
      userService
        .login(
          qs.stringify({
            // todo: if we remove this qs we can remove th whole dependency
            username: creds.username,
            password: creds.password,
            grant_type: "password",
            scope: "profile",
          })
        )
        .then((response) => {
          commit("setToken", response.data.access_token);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  /**
   * getUser
   * @param commit
   * @returns {Promise<unknown>}
   */
  getCurrentUser({ commit }) {
    return new Promise((resolve, reject) => {
      userService
        .getCurrentUser()
        .then((response) => {
          commit("setUser", response.data.user);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  /**
   * logout
   * @param commit
   */
  logout({ commit }) {
    commit("reset");
  },
};

const mutations = {
  reset(state) {
    state.token = null;
    state.username = null;
    state.id = null;
    state.tokens = 0;
    state.isLoggedIn = false;
  },
  setUser(state, user) {
    state.id = user.id;
    state.tokens = user.tokens;
    state.username = user.username;
    state.email = user.email;
    state.points = user.points;
    state.picture = user.picture;
    state.team = user.team;
    state.role = user.role;
    state.premium = user.premium;
    state.company = !!user.company;
    state.company_name = user.company_name;
    state.company_id = user.company_id;
    state.pendingRequest = user.pendingRequest;
    state.isTeamOwner = user.isTeamOwner;
    state.notifications = user.notifications;
    state.isLoggedIn = true;
    state.firstLogin = user.firstLogin || false;
    state.isHrpRecruiter = user.hrp_recruiter;
    state.isHrpCompany = user.hrp_company;
    state.inCorporate = user.in_corporate;
    state.corporateTokens = user.corporate_tokens;
    state.latestLabs = user.latestLabs;
    state.latestChapters = user.latestChapters;
    state.externId = user.externId;
  },
  setToken(state, token) {
    state.token = token;
    state.isLoggedIn = true;
  },
  setLogin(state) {
    state.firstLogin = false;
  },
  setPendingRequest(state, bool) {
    state.pendingRequest = bool;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
