// Styles
import "../src/assets/css/icons.scss";
import "../src/assets/css/styles.scss";
// Modules
import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import store from "./store";
import router from "./router";
import VueRouter from "vue-router";
import VModal from "vue-js-modal";
import VueSocialSharing from "vue-social-sharing";
import LoadScript from "vue-plugin-load-script";
import VueShepherd from "vue-shepherd";
import VueFlashMessage from "@smartweb/vue-flash-message";
import FlagIcon from "vue-flag-icon";
import "echarts/lib/chart/line";
import "echarts/theme/cool";
import VueObserveVisibility from "vue-observe-visibility";
import VueTyperPlugin from "vue-typer";
import device from "vue-device-detector";
import responsive from "vue-responsive";

// --- Axios global configurations
// - Add cookies to request in order to pass csrf token
axios.defaults.maxRedirects = 0;
axios.interceptors.request.use((config) => {
  config.withCredentials = true;
  return config;
});
axios.interceptors.response.use(
  (response) => {
    const data = response.data;
    const isErrorResponse = !!data && !!data.errorCode;
    if (isErrorResponse) {
      if (data.errorCode === "not_found") {
        router.push("/404").catch();
      } else if (data.errorCode === "insufficient_permission") {
        router.push("/home").catch();
      }
      if (!data.errorDescription) {
        data.errorDescription = "Ops, something went wrong. Please retry";
      }
      return Promise.reject(response);
    }
    return Promise.resolve(response);
  },
  (error) => {
    if (!!error && !!error.response) {
      const status = error.response.status;
      if (status === 401) {
        // store.dispatch("user/logout");
        router.push("/login").catch();
      }
    }
    return Promise.reject(error);
  }
);

// --- Plugins
Vue.use(responsive);
Vue.use(VueRouter);
Vue.use(VModal, { dynamic: true });
Vue.use(VueSocialSharing);
Vue.use(LoadScript);
Vue.use(VueShepherd);
Vue.use(VueFlashMessage, { time: 3000 });
Vue.use(FlagIcon);
Vue.use(VueObserveVisibility);
Vue.use(VueTyperPlugin);
Vue.use(device);

// --- Filters
Vue.filter("capitalize", (value) => {
  if (!value) {
    return "";
  }
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter("lowercase", (value) => {
  if (!value) {
    return "";
  }
  value = value.toString();
  return value.toLowerCase();
});
Vue.filter("truncate", (value, len = 33) => {
  if (!value) {
    return "";
  }

  if (value.length > len) {
    return `${value.slice(0, len - 3)}...`;
  }
  return value;
});

// --- Mixins
Vue.mixin({
  methods: {
    goBack() {
      router.go(-1);
    },
    flashSuccess(message) {
      // noinspection JSUnresolvedVariable
      this.flashMessage.success({ message });
    },
    flashError(message) {
      // noinspection JSUnresolvedVariable
      this.flashMessage.error({ message });
    },
    flashWarning(message) {
      // noinspection JSUnresolvedVariable
      this.flashMessage.warning({ message });
    },
    flashInfo(message) {
      // noinspection JSUnresolvedVariable
      this.flashMessage.info({ message });
    },
    fmtDate(date) {
      if (`${date}`.length < 2) {
        return `0${date}`;
      }
      return `${date}`;
    },
    transformUTCTime(dateToTransform) {
      // Util function
      const fmt = (x) => (`${x}`.length < 2 ? `0${x}` : `${x}`);
      // Fix date to standard JS Date
      const fixedDate = dateToTransform.replace(" ", "T"); // Replace space between date and time with "T"
      // Convert date with timezone
      const newDate = new Date(fixedDate);
      // Build date
      const day = fmt(newDate.getDate());
      const month = fmt(newDate.getMonth() + 1);
      const year = fmt(newDate.getFullYear());
      const date = `${year}-${month}-${day}`;
      // Build time
      const hour = fmt(newDate.getHours());
      const minutes = fmt(newDate.getMinutes());
      const time = `${hour}:${minutes}`;
      // Return results
      return [date, time];
    },
    linkedinShare(completed_id) {
      let badge_url = encodeURIComponent(
        process.env.VUE_APP_API_URL + "/badge?completion_id=" + completed_id
      );

      window.open(
        "https://www.linkedin.com/sharing/share-offsite/?url=" + badge_url,
        "_blank"
      );
    },
    twitterShare(completed_id) {
      let badge_url = encodeURIComponent(
        process.env.VUE_APP_API_URL + "/badge?completion_id=" + completed_id
      );
      let title = encodeURIComponent("I successfully completed a lab on PWNX!");
      let hashtags = "pwnx,labcompleted,pwning";
      window.open(
        `http://twitter.com/share?text=${title}&url=${badge_url}&hashtags=${hashtags}`,
        "_blank"
      );
    },
  },
});

// --- App
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
