<template>
  <div class="row m-0 p-0 event-leaderboard__row ">
    <div class="col-12 p-0 m-0 row px-0" :class="{ placeholder: leadData.placeholder }">
      <div class="col-12 event-leaderboard__header position-static" v-if="position === 1">
        <div class="row py-2 px-3 d-flex">
          <div class="col-1 event-leaderboard__rank p-0 text-center">#</div>
          <div class="col-1" v-if="isHrpAllowed && validRanks">{{ requireLabs ? 'AI' : 'RT' }}</div>
          <div :class="getLeaderboardColClass()" class="event-leaderboard__name">
            <p :class="{
              'mb-0': !this.shouldSeeUserNotes(),
              'mb-0 ml-5': this.shouldSeeUserNotes() && this.shouldSeeFlags(),
              'm-0': !this.shouldSeeFlags()
            }"><span v-if="!shouldSeeFlags()">resume - </span>name</p>
          </div>
          <div v-if="$parent.isGreenEvent && isHrpAllowed && validRanks && requireLabs"
            class="col-3 ml-5 event-leaderboard__submitted">
            submitted
          </div>
          <div class="col-1" v-if="this.$parent.$data.isRedEvent && !this.$parent.$data.isOverdue">
            <i class="fa-solid fa-users"></i>
          </div>
          <div :class="[$parent.isHrpRecruiter && validRanks ? 'col-1' : 'col-2']"
            class="text-center event-leaderboard__points">
            {{ requireLabs ? 'points' : '%' }}
          </div>
          <div v-if="this.$parent.$data.isBlueEvent" class="col-2 text-center">
            <img :src="this.$parent.$data.dobermannIcon" alt="dobermann" />
          </div>
          <div v-if="shouldSeeFlags()" class="col-1 text-center event-leaderboard__users">
            <i class="fas flag1 fa-flag"></i>
          </div>
          <div v-if="shouldSeeFlags()" class="col-1 text-center event-leaderboard__systems">
            <i class="fas flag2 fa-flag"></i>
          </div>
          <div v-if="isHrpAllowed && !this.$parent.$data.isBlueEvent && validRanks"
            class="col-1 text-center event-leaderboard__systems">
            <p class="m-0 p-0">actions</p>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex row align-items-center py-2 px-3" @click="handleChartReload(leadData.user_id)"
          :class="{ 'event-leaderboard__element': !leadData.placeholder }">
          <div class="col-1 event-leaderboard__rank p-0 text-center">
            <p class="mb-0">
              {{ !leadData.placeholder ? position : null }}
            </p>
          </div>
          <div class="col-1" v-if="isHrpAllowed && validRanks">
            <span @click="redirectToStats(leadData.id)" class="brain-icon">{{ requireLabs ? '🧠' : '💡' }}</span>
          </div>
          <div :class="getLeaderboardColClass()" class=" event-leaderboard__name">
            <h2 class="mb-0 d-flex flex-row">
              <span v-if="shouldSeeUserNotes() && validRanks" class="mr-3 notes-icon"
                @click="showUserNotes(leadData.id, leadData.name)">
                📄
              </span>
              <p id="username" v-if="!this.$parent.$data.isRedEvent" @click="redirectToUser"
                class="mb-0 pb-0 simple-hover"> {{ leadData.name }}
              </p>
              <p id="username" v-if="this.$parent.$data.isRedEvent" @click="redirectToTeam"
                class="mb-0 pb-0 simple-hover">
                {{ leadData.name }}
              </p>
            </h2>
          </div>
          <div v-if="$parent.isGreenEvent && isHrpAllowed && validRanks && requireLabs"
            class="col-3 text-center event-leaderboard__submitted">
            {{ leadData.submitted }}
          </div>
          <div class="col-1" v-if="this.$parent.$data.isRedEvent && !this.$parent.$data.isOverdue">
            {{ leadData.members }}
          </div>
          <div :class="[$parent.isHrpRecruiter && validRanks ? 'col-1' : 'col-2']"
            class="text-center event-leaderboard__points">
            <p class="mb-0 pb-0">
              {{ !leadData.points ? 0 : leadData.points }}
              <span v-if="!requireLabs">%</span>
            </p>
          </div>
          <div class="col-1 text-center event-leaderboard__users" v-if="shouldSeeFlags()">
            <p class="mb-0 pb-0">
              {{ !leadData.users ? 0 : leadData.users }}
            </p>
          </div>
          <div class="col-1 text-center event-leaderboard__systems" v-if="shouldSeeFlags()">
            <p class="mb-0 pb-0">
              {{ !leadData.systems ? 0 : leadData.systems }}
            </p>
          </div>
          <div class="col-1 row m-0 p-0 text-center event-leaderboard__actions d-flex flex-row justify-content-end"
            v-if="$parent.isHrpRecruiter && isHrpAllowed && validRanks">
            <div class="col-4 m-0 p-0" v-if="requireLabs">
              <i @click="handleHistoryBtn(leadData.user_id)" class="pointer fa fa-history"></i>
            </div>
            <div class="col-4 m-0 p-0">
              <i @click="showUserInfo(leadData.user_id)" class="fa-solid fa-circle-info"></i>
            </div>
            <div class="col-4 m-0 p-0"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row col-12 m-0 p-0 user-leaderboard-details"
      v-if="$parent.isHrpRecruiter && labs.length > 1 && validRanks">
      <!-- labs -->
      <div @click="showLogs(leadData.user_id, lab.id)"
        class="col-12 d-flex user-leaderboard-lab collapsed justify-content-center align-items-center m-0 p-0"
        v-for="(lab, index) of labs" :key="index" :id="lab.id">
        <p>{{ lab.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DockerLogsModal from "@/components/DockerLogsModal";
import InfoModal from "@/components/InfoModal";
import UserType from "@/enums/user.js";


export default {
  name: "EventLeaderboard",
  props: {
    index: null,
    leadData: {},
    position: null,
  },
  data() {
    return {
      isEventPrivate: false,
      labs: this.$parent.eventLabs,
      requireLabs: this.$parent.requireLabs
    }
  },
  created() {
    this.isEventPrivate = this.$parent.$data.event.private;
    this.resetCollapsable()
  },
  computed: {
    validRanks() {
      const ranks = this.$parent.ranks;
      return !ranks.some(item => item.placeholder);
    },
    isRecruiter() {
      if (!this.userData.role) return false;
      return this.userData.role === UserType.RECRUITER;
    },
    isHrpAllowed() {
      // const isHrpRecruiter = this.$parent.isHrpRecruiter;
      const companyContract = this.$parent.companyContract;

      const user = this.$parent.userData;
      const event = this.$parent.event;
      const mlService = event.mlService;
      // const isGreenEvent = this.$parent.isGreenEvent;

      const allowed = [
        UserType.CORPORATE,
        UserType.HRPLUS,
        UserType.RECRUITER,
        UserType.ENTERPRISE,
      ].some(role => role === user.role)

      if (!allowed) return false;
      // if (isGreenEvent && !companyContract) return false;
      if (!companyContract) return false;
      // if (companyContract && !companyContract && isGreenEvent) return false;
      if (companyContract && !companyContract.mlService) return false;
      if (!mlService) return false;

      return user.company_id == event.company_id;
    },
    ...mapState({
      userData: state => state.user
    }),
  },
  emits: ['reload-chart'],
  methods: {
    getLeaderboardColClass() {
      const isGreen = this.$parent.isGreenEvent;
      const isNormal = this.$parent.isNormalEvent;
      const isRed = this.$parent.isRedEvent;
      const isOverdue = this.$parent.$data.isOverdue;
      const isScheduled = this.$parent.$data.isScheduled;

      if (!this.shouldSeeFlags()) return "col-8";
      if (!this.validRanks && isScheduled) return "col-6"
      if (this.validRanks && isRed && !isOverdue) return "col-6";
      if (this.validRanks && this.isHrpAllowed && isGreen) return "col-3"
      if (this.validRanks && this.isHrpAllowed && isNormal) return "col-6"
      return "col-7"
    },
    shouldSeeFlags() {
      const isBlueEvent = this.$parent.$data.isBlueEvent;
      const requireLabs = this.requireLabs;
      if (isBlueEvent) return false;
      if (!requireLabs) return false;
      return true;
    },
    showUserInfo(userId) {
      this.$modal.show(
        InfoModal,
        { userId: userId },
        { height: 'auto', width: '80%' }
      )
    },
    handleChartReload(userId) {
      this.$emit('reload-chart', userId);
    },
    resetCollapsable() {
      let elements = document.querySelectorAll(".user-leaderboard-lab")
      for (const element of elements) {
        element.classList.add("collapsed")
        setTimeout(() => element.animate({ transform: "scale(0)" }, { duration: 2000, iterations: 0 }), 0)
      }
    },
    showLogs(userId, labId) {
      this.$modal.show(
        DockerLogsModal,
        {
          eventId: this.$parent.$data.event.id,
          labId: labId,
          userId: userId,
        },
        { height: "auto", width: "80%" }
      );
    },
    handleHistoryBtn(userId, labId) {
      if (this.labs.length <= 1) return this.showLogs(userId, this.labs[0].id)
      let elements = document.querySelectorAll(".user-leaderboard-lab")
      for (const element of elements) {

        if (element.id != labId) {
          element.classList.add("collapsed")
          continue
        }

        const isCollapsed = element.classList.contains("collapsed")
        if (isCollapsed) {
          element.classList.remove("collapsed")
          setTimeout(() => element.animate({ transform: "scale(1)" }, { duration: 2000, iterations: 0 }), 0)
        }

        if (!isCollapsed) {
          element.classList.add("collapsed")
          setTimeout(() => element.animate({ transform: "scale(0)" }, { duration: 2000, iterations: 0 }), 0)
        }
      }

    },
    redirectToStats(userId) {
      const eventId = this.$parent.eventId
      this.$router.push(`/event/${eventId}/statistics?userId=${userId}`)
    },
    shouldSeeUserNotes() {
      const isOnGoing = this.$parent.$data.isOnGoing;
      if (!isOnGoing) return false;
      const contract = this.$parent.$data.companyContract;
      const isNormalEvent = this.$parent.$data.isNormalEvent;
      const isBlueEvent = this.$parent.$data.isBlueEvent;
      const companyId = this.userData.company_id;
      const isGreenEvent = this.$parent.$data.isGreenEvent;
      const isRecruiter = this.isRecruiter;
      let isGreenAllowed = false;
      if (contract)
        isGreenAllowed = isRecruiter && isGreenEvent && companyId === contract.companyId
      if (isGreenAllowed) return true
      if (!isNormalEvent && !isBlueEvent) return false;
      return true;
    },
    showUserNotes(userId, username) {
      this.$parent.$emit("show-user-notes", {
        userId,
        username
      })
    },
    redirectToUser() {
      if (!this.isEventPrivate) this.$parent.$router.push(`/user/${this.leadData.id}`);
    },
    redirectToTeam() {
      this.$parent.$router.push(`/team/${this.leadData.team_id}`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";

.event-leaderboard__row {
  * {
    font-size: 0.8rem;
  }

  &:nth-child(odd) {
    background: rgba(255, 255, 255, 0.04);
  }
}

.event-leaderboard__header {
  * {
    font-size: 0.7rem;
    opacity: 0.8;
    background: $neutral--dark !important;
  }

  .flag1 {
    color: $soft-green;
  }

  .flag2 {
    color: $soft-orange;
  }
}

.placeholder {
  p {
    margin: 4px 0;
    color: transparent !important;
    height: 12px;
    border-radius: 6px;
    background: rgba(white, 0.1);
  }
}

.notes-icon {
  cursor: pointer;
}

.brain-icon {
  cursor: pointer;
}

.user-leaderboard-lab:hover {
  background: $neutral--dark !important;
}

.user-leaderboard-lab.collapsed {
  width: 100%;
  height: 0;
  transform: scale(0);
  transition: all 0.2s ease;
  transform-origin: top;
}

.user-leaderboard-lab {
  width: 100%;
  height: 100;
  transform: scale(1);
  transition: all 0.2s ease;
  transform-origin: top;
}

.event-leaderboard__element:hover {
  background: $soft-green;
  color: $neutral--darkest;
  cursor: pointer;
  transition: all 0.2s ease;
}

.event-leaderboard__element.selected {
  background: $soft-green;
  color: $neutral--darkest;
}
</style>
