<template>
  <div class="container-fluid table">
    <div class="row align-items-center mb-3">
      <div class="col-2">
        <h1 class="main-title-1">Events</h1>
      </div>
      <div class="col-10 px-0 d-flex justify-content-end">
        <div class="col-8 col-lg-4"></div>
        <div class="col-4 text-right px-0 pr-4 col-lg-3">
          <div class="d-flex justify-content-end flex-row">
            <px-button v-if="hrpCanCreate" @click.native="addEvent()" class="px-button--primary outline mr-1">
              Add
            </px-button>
            <px-button @click.native="goBack()" class="px-button--secondary outline icon-left" icon="left-arrow">
              back
            </px-button>
          </div>
        </div>
      </div>
    </div>
    <px-table class="px-table table__events">
      <div v-responsive.lg.xl class="row table-header mb-3 d-flex align-items-center px-0 mx-0">
        <div class="col-3 h-100 py-3 d-flex align-items-center">
          <h2 class="mb-0">name</h2>
        </div>
        <div class="col-1 h-100 py-3 d-flex align-items-center">
          <h2 class="mb-0">pts</h2>
        </div>
        <div class="col-2 h-100 py-3 d-flex align-items-center">
          <h2 class="mb-0">start date</h2>
        </div>
        <div class="col-2 h-100 py-3 d-flex align-items-center">
          <h2 class="mb-0">end date</h2>
        </div>
        <div class="col-1 h-100 py-3 d-flex align-items-center justify-content-center">
          <i class="fa-solid fa-users"></i>
        </div>
        <div class="col-3 h-100 py-3 d-flex align-items-center">
          <h2 class="mb-0">info</h2>
        </div>
      </div>
      <div class="pinned-events mx-2">
        <div class="mx-2 pinned-events-container">
          <div v-for="event of pinned" :key="event.id"
            class="pinned-events-data row d-flex p-lg-3 px-lg-4 align-items-center table__row table__row--events my-3">
            <div class="container-fluid" v-responsive.xs.sm.md>
              <div class="row p-2 align-items-center table__row--header">
                <div class="col-4">
                  <div class="d-flex align-items-center pointer" @click="goToEvent(event.id)">
                    <h2 class="mb-0">
                      {{ event.name | truncate(15) }}
                    </h2>
                  </div>
                </div>
                <div class="col-4">
                  <PxBadge :class="`px-badge--${event.status.toLowerCase()}`" class="outline">
                    {{ event.status.toLowerCase() }}
                  </PxBadge>
                </div>
                <div class="col-4">
                  <PxBadge :class="`px-badge--${event.type.toLowerCase()}`" class="outline">
                    {{ event.type.toLowerCase() }}
                  </PxBadge>
                </div>
              </div>
              <div class="row align-items-center p-2">
                <div class="col-4">
                  <b>points</b>
                  <br>
                  <p class="mb-0">{{ event.points }}</p>
                </div>
                <div class="col-4 text-center">
                  <span class="col__date--date">
                    START
                    {{ event.dStart }}
                  </span>
                  <br>
                  <span class="col__date--time">
                    {{ event.tStart }}
                  </span>
                </div>
                <div class="col-4 text-center">
                  <span class="col__date--date">
                    END
                    {{ event.dEnd }}
                  </span>
                  <br>
                  <span class="col__date--time">
                    {{ event.tEnd }}
                  </span>
                </div>
              </div>
            </div>
            <div class="container-fluid" v-responsive.lg.xl>
              <div class="row d-flex align-items-center">
                <div class="col-3 col__name text-left">
                  <div class="d-flex align-items-center pointer" @click="goToEvent(event.id)">
                    <h2 class="mb-0">
                      {{ event.name | truncate(50) }}
                    </h2>
                  </div>
                </div>
                <div class="col-1 col__points">
                  {{ event.points }}
                </div>
                <div class="col-2 d-flex flex-column col__date">
                  <span class="col__date--date">
                    {{ event.dStart }}
                  </span>
                  <span class="col__date--time">
                    {{ event.tStart }}
                  </span>
                </div>
                <div class="col-2 d-flex flex-column col__date">
                  <span class="col__date--date">
                    {{ event.dEnd }}
                  </span>
                  <span class="col__date--time">
                    {{ event.tEnd }}
                  </span>
                </div>
                <div class="col-1 col__participants d-flex justify-content-center align-items-center">
                  {{ event.participants }}
                </div>
                <div class="col-3 col__info">
                  <div class="row">
                    <div class="col-6 pr-1">
                      <PxBadge :class="`px-badge--${event.status.toLowerCase()}`" class="outline">
                        {{ event.status.toLowerCase() }}
                      </PxBadge>
                    </div>
                    <div class="col-6 pl-1">
                      <PxBadge :class="`px-badge--${event.type.toLowerCase()}`" class="outline">
                        {{ event.type.toLowerCase() }}
                      </PxBadge>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="show-more mx-1 m-0 p-0">
        <px-button v-if="!showUnpinned" @click.native="showMore" class="px-button--success m-0">show more</px-button>
      </div>
      <InfiniteScroll v-if="showUnpinned" :items="events" @refetch="fetchEvents">
        <template v-slot:item="event">
          <div class="row d-flex p-lg-3 align-items-center table__row table__row--events my-3">
            <div class="container-fluid" v-responsive.xs.sm.md>
              <div class="row p-2 align-items-center table__row--header">
                <div class="col-4">
                  <div class="d-flex align-items-center pointer" @click="goToEvent(event.item.id)">
                    <h2 class="mb-0">
                      {{ event.item.name | truncate(15) }}
                    </h2>
                  </div>
                </div>
                <div class="col-4">
                  <PxBadge :class="`px-badge--${event.item.status.toLowerCase()}`" class="outline">
                    {{ event.item.status.toLowerCase() }}
                  </PxBadge>
                </div>
                <div class="col-4">
                  <PxBadge :class="`px-badge--${event.item.type.toLowerCase()}`" class="outline">
                    {{ event.item.type.toLowerCase() }}
                  </PxBadge>
                </div>
              </div>
              <div class="row align-items-center p-2">
                <div class="col-4">
                  <b>points</b>
                  <br>
                  <p class="mb-0">{{ event.item.points }}</p>
                </div>
                <div class="col-4 text-center">
                  <span class="col__date--date">
                    START
                    {{ event.item.dStart }}
                  </span>
                  <br>
                  <span class="col__date--time">
                    {{ event.item.tStart }}
                  </span>
                </div>
                <div class="col-4 text-center">
                  <span class="col__date--date">
                    END
                    {{ event.item.dEnd }}
                  </span>
                  <br>
                  <span class="col__date--time">
                    {{ event.item.tEnd }}
                  </span>
                </div>
              </div>
            </div>
            <div class="container-fluid" v-responsive.lg.xl>
              <div class="row d-flex align-items-center">
                <div class="col-3 col__name text-left">
                  <div class="d-flex align-items-center pointer" @click="goToEvent(event.item.id)">
                    <h2 class="mb-0">
                      {{ event.item.name | truncate(50) }}
                    </h2>
                  </div>
                </div>
                <div class="col-1 col__points">
                  {{ event.item.points }}
                </div>
                <div class="col-2 d-flex flex-column col__date">
                  <span class="col__date--date">
                    {{ event.item.dStart }}
                  </span>
                  <span class="col__date--time">
                    {{ event.item.tStart }}
                  </span>
                </div>
                <div class="col-2 d-flex flex-column col__date">
                  <span class="col__date--date">
                    {{ event.item.dEnd }}
                  </span>
                  <span class="col__date--time">
                    {{ event.item.tEnd }}
                  </span>
                </div>
                <div class="col-1 col__participants d-flex justify-content-center align-items-center">
                  {{ event.item.participants }}
                </div>
                <div class="col-3 col__info">
                  <div class="row">
                    <div class="col-6 pr-1">
                      <PxBadge :class="`px-badge--${event.item.status.toLowerCase()}`" class="outline">
                        {{ event.item.status.toLowerCase() }}
                      </PxBadge>
                    </div>
                    <div class="col-6 pl-1">
                      <PxBadge :class="`px-badge--${event.item.type.toLowerCase()}`" class="outline">
                        {{ event.item.type.toLowerCase() }}
                      </PxBadge>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </InfiniteScroll>
    </px-table>
    <!--    <div v-observe-visibility="handleScrolledToBottom"></div>-->
    <!--    <px-table-event class="px-4" :events="events" v-if="events && events.length"></px-table-event>-->
    <!--    <div v-else class="h-100 w-100 d-flex justify-content-center align-items-center">-->
    <!--      <p class="text-center text-white">-->
    <!--        No events found-->
    <!--      </p>-->
    <!--    </div>-->
  </div>
</template>

<script>
import eventService from "../api/event";
import httpService from "../api/http";
import PxButton from "../components/PxButton";
import PxTable from "../components/PxTable";
import PxBadge from "../components/PxBadge";
import UserType from "@/enums/user";
import InfiniteScroll from "../components/InfiniteScroll";
import CompanyEventCreateModal from "@/components/CompanyEventCreateModal";
import EventUserJoinDataModal from "@/components/EventUserJoinDataModal";
import EventCreateTeamModal from "@/components/EventCreateTeamModal";
import { mapState } from 'vuex';
import hrpService from "@/api/hrp.js";
import { EventType, EventStatus } from "@/enums/event.js";

export default {
  components: {
    // PxTableEvent,
    PxBadge,
    PxButton,
    InfiniteScroll,
    "px-table": PxTable,
  },
  name: "Events",
  data() {
    return {
      events: [],
      pinned: [],
      lastPage: false,
      showUnpinned: false,
      isHeaderFixed: false,
      evRoles: {},
      hrpCanCreate: false,
    };
  },
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  methods: {
    createTeamAndGoToJoin(eventId) {
      this.$parent.loading = false;
      this.$modal.show(
        EventCreateTeamModal,
        {
          eventId: eventId,
          modalTitle: "Create Team",
          mustJoin: false,
          setCanApply: () => {
            this.canApplyTeam = false;
            this.canApplyUser = false;
          },
          allDone: () => {
            this.flashSuccess(
              "Team successfully created, redirecting to join page"
            );
            setTimeout(() => {
              let href = ["#/event", eventId, "join"].join("/");
              const params = Object.entries(this.$route.query).map(([k, v]) => k + "=" + v);

              if (params.length > 0) {
                href += "?" + params.join("&")
              }

              this.$parent.loadUser();
              window.location.href = href;
            }, 3000);
          },
        },
        { height: "auto" }
      );
    },
    async goToEvent(eventId, joinTicket = null) {
      const url = ["/event", eventId].join("/");
      const evList = [...this.events, ...this.pinned];

      let event = null;

      if (!joinTicket) {
        [event] = evList.filter(ev => {
          const evId = ev.id;
          return evId === eventId;
        });
      } else {
        event = await eventService.getEvent(eventId)
          .then((response) => response.data.event);
      }


      const user = this.user;
      const status = event.status.toUpperCase();
      const type = event.type;
      // const isPrivate = event.private;


      const dataEvents = [EventType.NORMAL, EventType.RED].some(typ_ => typ_ === type);
      const isNormalUser = user.role === UserType.USER;
      const isRedEvent = type === EventType.RED;

      if (!isNormalUser) {
        return this.$router.push(url)
      }

      if (status == EventStatus.OVERDUE) {
        return this.$router.push(url);
      }

      if (!dataEvents || !isNormalUser) {
        return this.$router.push(url);
      }

      this.$parent.loading = true;

      const stepMap = {
        "TICKET": ["/event", eventId, "join"].join("/"),
        "DETAILS": ["/event", eventId].join("/")
      }

      let globStepString = null;

      const requireTeam = !this.user.team && isRedEvent;
      eventService.getEventSteps(eventId)
        .then((response) => {
          const participating = response.data.participating
          if (participating) {
            return this.$router.push(url);
          }
          return httpService.all([eventService.getEventJoinSteps(eventId)]);
        }).then(async ([step]) => {
          globStepString = step.data.next;
          const isPrivate = event.private
          if (globStepString === "DATA") {
            const dataType = event.dataType;
            this.$modal.show(
              EventUserJoinDataModal,
              {
                eventId,
                isPrivate,
                dataType,
                joinTicket,
                requireTeam,
                toggleLoading: () => {
                  this.$parent.loading = !this.$parent.loading
                }
              },
              { width: '70%', height: 'auto' }
            )
            this.$parent.loading = false
            return { data: { canApplyUser: true, canApplyTeam: true } }
          } else if (globStepString === "TICKET") {
            // 
            let applicable = null;

            if (isRedEvent)
              applicable = eventService.canApplyTeam
            else
              applicable = eventService.canApplyUser

            return applicable(event.id)
          }
          this.$router.push(stepMap[globStepString]);
          return { data: { canApplyUser: true, canApplyTeam: true } }
        }).then((response) => {
          const { canApplyUser } = response.data;
          const { canApplyTeam } = response.data;

          if (globStepString == "TICKET") {
            if (isRedEvent && !canApplyTeam) {
              this.createTeamAndGoToJoin(event.id);
              return
            } else {
              let href = ["#/event", event.id, "join"].join("/");
              const params = Object.entries(this.$route.query).map(([k, v]) => k + "=" + v)

              if (params.length > 0) {
                href += "?" + params.join("&");
              }

              window.location.href = href
              return
            }
          }

          if (!canApplyUser && !canApplyTeam) {
            this.flashError("You're not allowed to visit this page");
            this.$router.push(`/event/${this.eventId}`);
            return;
          }

        }).finally(() => {
          // this.$parent.loading = false;
        })
    },
    getEventRoles() {
      const user = this.user;
      const allowedRoles = [
        UserType.HRPLUS,
        UserType.RECRUITER,
        UserType.ENTERPRISE
      ]
      if (!user) return false;
      if (!allowedRoles.some(role => role == user.role)) return false
      hrpService.canCreateEvents().then(response => {
        this.evRoles = response.data.eventRoles
        const evRoles = this.evRoles;
        const canCreate = Object.values(evRoles).some(value => value === true)
        this.hrpCanCreate = canCreate;
      })
    },
    showMore() {
      this.showUnpinned = true
    },
    fetchEvents(page) {
      if (this.lastPage) return
      this.$parent.loading = true;
      eventService.getEvents(page)
        .then(response => {
          if (!response.data.events.length) {
            this.lastPage = true
            return
          }

          this.events.push(...response.data.events);
          const localEvents = this.events
          const ongoing = localEvents.filter(event => event.status.toLowerCase() === "ongoing");
          const overdue = localEvents.filter(event => event.status.toLowerCase() === "overdue");
          const scheduled = localEvents.filter(event => event.status.toLowerCase() === "scheduled");

          const unique = (sequence) => {
            const matched = []
            const distinct = []

            for (const element of sequence) {
              const present = matched.some(item => item === element.id);
              if (!present) {
                matched.push(element.id);
                distinct.push(element);
              }
            }

            return distinct
          }

          let events = this.events.concat(ongoing, scheduled, overdue);
          let pinned = this.events.filter(event => event.participating === true || event.owner === true);
          events = this.events.filter(event => event.participating === false && event.owner === false);

          pinned = pinned.map((e) => {
            const [dStart, tStart] = this.transformUTCTime(e.dateStart);
            const [dEnd, tEnd] = this.transformUTCTime(e.dateEnd);
            return { ...e, dStart, tStart, dEnd, tEnd };
          })

          if (pinned.length === 0) {
            this.showUnpinned = true;
          }

          this.events = unique(events);
          this.pinned = unique(pinned);

          this.events = this.events.map((e) => {
            const [dStart, tStart] = this.transformUTCTime(e.dateStart);
            const [dEnd, tEnd] = this.transformUTCTime(e.dateEnd);
            return { ...e, dStart, tStart, dEnd, tEnd };
          });
        })
        .catch(error => {
          const manageableError =
            !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => {
          this.$parent.loading = false
          const qEventId = this.$route.query.eventId;
          const qJoinTicket = this.$route.query.joinTicket;
          if (qEventId && qJoinTicket) {
            this.goToEvent(qEventId, qJoinTicket);
          }
        });
    },
    addEvent() {
      // this.$router.push("/events/add")
      const evRoles = []
      for (const [key, value] of Object.entries(this.evRoles)) {
        if (!value) continue
        evRoles.push(key)
      }

      this.$modal.show(
        CompanyEventCreateModal,
        {
          evRoles: evRoles,
          createdCallback: () => {
            this.fetchEvents()
          }
        },
        { height: 'auto' }
      )
    },

    handleScroll() {

    }
  },
  beforeCreate() {
    this.$parent.breadcrumbs = [{ name: "events" }];
  },
  created() {
    this.page = 1
    this.fetchEvents()
    window.addEventListener('scroll', this.handleScroll);
    this.getEventRoles();
  },
};
</script>

<style lang="scss">
@import "../assets/css/colors";

.table__row--events {
  font-size: 0.9rem;

  h2 {
    color: white;
    font-size: 0.75rem;
    font-weight: bold;
    transition: all 0.2s;

    &:hover {
      color: rgba($soft-green, 1);
    }
  }
}

.table__row--header {
  background: rgba($neutral--medium, 0.5);
}

.col__date {
  line-height: calc(100% + 3px);
}

.table-header {
  background: rgba($neutral--medium, 0.5);

  h2 {
    font-size: 0.8rem;
    font-weight: bold;
  }

  [class*="col-"] {
    border-left: 2px solid rgba(white, 0.1);
  }
}

.col__date--time {
  opacity: 0.6;
}

.pinned-events-data {

  &:nth-child(odd) {
    background: $neutral--smooth !important;
  }

  &:nth-child(even) {
    background: $neutral--dark !important;
  }

}
</style>
