import httpService from "./http"

const tokensService = {
  getPackages() {
    return httpService.get("/v2/tokens/packages");
  },
  createTokens(data) {
    const httpOptions = {
      data
    };
    return httpService.post("/v2/tokens/new", httpOptions)
  },
  approveTokens(data) {
    const httpOptions = {
      data
    };
    return httpService.post(`/v2/tokens/approve`, httpOptions);
  },
  cancelTokens(data) {
    const httpOptions = {
      data
    };
    return httpService.post(`/v2/tokens/cancel`, httpOptions);
  },
}

export default tokensService
