<template>
  <div class="vh-90">
    <div class="row align-items-center padding-bottom-20">
      <div class="col-6">
        <h1 class="main-title-1">Edit team</h1>
      </div>
      <div class="col-6 text-right">
        <px-button
            @click.native="goBack()"
            class="px-button--secondary icon-left outline ml-3"
            icon="left-arrow">
          back
        </px-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-12">
        <form @submit.prevent class="rounded-card__item bg-gradient">
          <div class="form-group">
            <label class="card__label">Name</label>
            <strong>{{ team.name }}</strong>
          </div>
          <div class="form-group">
            <label for="url" class="card__label">URL</label>
            <input autocomplete="off" type="url" name="url" id="url" class="form-control" placeholder="https://"
                   maxlength="100" v-model="teamChanges.url"/>
          </div>
          <div class="form-group">
            <label for="profile" class="card__label">Upload new image</label>
            <div class="row">
              <div class="col-8">
                <input autocomplete="off" type="file" name="profile_file" ref="myFile" @change="selectedFile"
                       id="profile-picture"/>
                <input autocomplete="off" type="text" name="profile" id="profile" class="form-control file-upload"
                       required
                       readonly disabled v-model="file.name"/>
              </div>
              <div class="col-4">
                <px-button @click.native="triggerFileInput" class="px-button px-button--success outline">
                  browse
                </px-button>
              </div>
            </div>
          </div>
          <div>
            <px-button @click.native="update" class="px-button px-button--success" :disabled="updateDisabled()">
              update
            </px-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import teamService from "../api/team";
import {mapState} from "vuex";
import pxButton from "../components/PxButton";

export default {
  name: "TeamEdit",
  computed: mapState({
    user: state => state.user
  }),
  data() {
    return {
      loading: false,
      teamId: this.$route.params.teamId,
      team: {},
      teamChanges: {},
      file: {},
    }
  },
  components: {
    pxButton
  },
  beforeCreate() {
    this.$parent.breadcrumbs = [{name: "teams", navigation: "/teams"}];
  },
  created() {
    this.$parent.loading = true;
    teamService.getTeam(this.teamId)
        .then(response => {
          this.team = response.data.team;
          this.$parent.breadcrumbs.push(...[{
            name: `team: ${this.team.name}`,
            navigation: `/team/${this.team.id}`
          }, {name: "edit"}]);
          this.teamChanges = {...this.team};

          if (this.team.ownerId !== this.user.id) {
            this.$router.push('/404').catch();
          }
        })
        .catch(error => {
          const manageableError = !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }

          this.$router.push('/404').catch();
        })
        .finally(() => this.$parent.loading = false)
  },
  methods: {
    triggerFileInput() {
      const fileInput = document.getElementById("profile-picture");
      fileInput.click();
    },
    selectedFile() {
      this.file = this.$refs.myFile.files[0];

      if (this.file) {
        const file_ext = this.file.name.split('.').slice(-1)[0];

        if (!["png", "jpg", "jpeg"].includes(file_ext)) {
          this.flashError("Invalid file type, choose among .png, .jpg or .jpeg");
          this.file = null;
        }
      } else {
        this.file = null;
      }
    },
    updateDisabled() {
      return this.team.url === this.teamChanges.url && !this.file.name;
    },
    update() {
      const formData = new FormData();
      formData.append("url", this.teamChanges.url);
      formData.append("profilePicture", this.file);

      this.$parent.loading = true;
      teamService.update(this.teamId, formData)
          .then(() => {
            this.flashSuccess("Data successfully updated!");
            this.$router.push({name: "TeamDetails", params: {teamId: this.teamId}});
          })
          .catch(error => {
            const manageableError = !!error && !!error.data && !!error.data.errorDescription;
            if (manageableError) {
              this.flashError(error.data.errorDescription);
            }
          })
          .finally(() => this.$parent.loading = false)
    }
  }
}
</script>

<style scoped>

</style>
