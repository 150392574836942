import httpService from "./http";

const recruiterService = {
  // v1
  getTop10() {
    return httpService.get("/v2/recruiters/top10/users");
  },
  getTop10Events() {
    return httpService.get("/v2/recruiters/top10/events");
  },
  getTopStats() {
    return httpService.get("/v2/recruiters/stats");
  },
  getLatestEventCreated() {
    return httpService.get("/v2/recruiters/latest-created");
  },
  getFileDiff(eventId, labId, userId) {
    const httpOptions = {
      data: {
        lab_id: labId,
        user_id: userId,
      },
    };
    return httpService.post(`/v2/recruiters/${eventId}/diffs`, httpOptions);
  },
  getDockerLogs(eventId, labId, userId, page) {
    const httpOptions = {
      data: {
        lab_id: labId,
        user_id: userId,
      },
    };
    return httpService.post(`/v2/recruiters/${eventId}/logs?page=${page}`, httpOptions);
  },
  requestSupport(eventId) {
    return httpService.post(`/v2/recruiters/${eventId}/support`);
  },
  getUserNotes(eventId, userId) {
    return httpService.get(`/v2/recruiters/${eventId}/notes/${userId}`);
  },
  getTickets(eventId, include = null) {
    if (!include) return httpService.get(`/v2/recruiters/${eventId}/tickets`);
    return httpService.get(`/v2/recruiters/${eventId}/tickets?include=${include}`);
  },
  sendTicket(eventId, user) {
    const httpOptions = { data: {} };
    httpOptions["data"][user.includes("@") ? "email" : "user_id"] = user;
    return httpService.post(
      `/v2/recruiters/${eventId}/send-tickets`,
      httpOptions
    );
  },
  getRecruiterInHrp(eventId) {
    return httpService.get(`/v2/recruiters/hrp-recruiter/${eventId}`);
  },
  getHrpEvents() {
    return httpService.get("/v2/recruiters/hrp-events");
  },
  getTicketHistory(eventId) {
    const url = ["/v2/recruiters/ticket-history", eventId].join("/")
    return httpService.get(url);
  },
  getEventUsers(eventId) {
    return httpService.get(["/v2/recruiters", eventId, "event-users"].join("/"));
  },
  getUserEventStats(eventId, userId, before = null, labId = null) {
    if (!before && !labId) {
      return httpService.get(`/v2/recruiters/${eventId}/stats/${userId}`);
    } else if (!before && labId) {
      return httpService.get(`/v2/recruiters/${eventId}/stats/${userId}/${labId}`);
    } else if (before && labId) {
      return httpService.get(`/v2/recruiters/${eventId}/stats/${userId}/${labId}?before=${before}`)
    } else if (!before && labId) {
      return httpService.get(`/v2/recruiters/${eventId}/stats/${userId}`);
    }
    return httpService.get(`/v2/recruiters/${eventId}/stats/${userId}?before=${before}`);
  },
  getUserInfo(userId) {
    return httpService.get(["/v2/recruiters/user-info", userId].join("/"))
  },
  getJobApplications(jobId, status = null) {
    if (!status) return httpService.get(["/v2/recruiters/jobs", jobId, "applications"].join("/"))
    return httpService.get(["/v2/recruiters/jobs", jobId, "applications", status].join("/"))
  },
  downloadUserCv(jobId, userId) {
    return httpService.get(["/v2/recruiters/jobs", jobId, "applications", userId, "curriculum"].join("/"))
  },
  applyAction(jobId, userId, action) {
    return httpService.post(["/v2/recruiters/jobs", jobId, "applications", userId, action].join("/"))
  },
  getEventRole(eventId) {
    return httpService.get(["/v2/recruiters", eventId, "roles"].join("/"));
  },
  getUserReportToken(eventId, userId, labId = null) {
    if (!labId) {
      return httpService.get(["/v2/recruiters", eventId, "report", userId].join("/"))
    }
    return httpService.get(["/v2/recruiters", eventId, "report", userId, "lab", labId].join("/"))
  },
  generateUserReport(data) {
    const httpOptions = {data}
    return httpService.post("/v2/recruiters/report/generate", httpOptions);
  }
};

export default recruiterService;
