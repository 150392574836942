<template>
    <div class="container" v-html="privacy">
        
    </div>
</template>
<script>
import  { marked } from 'marked';
import { sanitize } from 'dompurify';

export default {
    name: "Privacy",
    data() {
        return {
            privacy: `
Your privacy and security is of the utmost importance to us. We describe in this policy
how we collect and use information about you in your use of our services, including our
platform and the website. If you are one of our customers, you should read this policy in
conjunction with our Terms of Service.

# **1. Who are we?**

We are PWNX, a platform designed to support individuals and organizations against the
widespread phenomenon of skill shortage in the Cyber Security field and Information
Technology more generally. PWNX provides original CTF (Capture the Flag) labs, in a
completely controlled environment, where it is possible to apply offensive and defensive
techniques on computer systems and applications for training purposes.
When collecting information about our customers or visitors of our website, we are, under
European Union (“EU") data protection laws, qualified as “data controller". This means that
we are responsible for deciding how we hold and use personal data about you.

# **2. How do we collect your data?**

We collect information about you when you fill in a sign up or contact form on our website
or send us an email. We may also receive your personal data from third parties, when you
express your interest for our services to them.
We collect information by automated means. When you visit our website, view one of our
advertisements on a third party-owned website, or read our marketing email, we
automatically collect information about you via cookies, web beacons and other similar
technologies. These are small files associated with information that your browser or our
servers will save and return as part of your use of the website and the services for
purposes such as saving your login session between visits, remembering your display
preferences, tracking your use of the website, and for audience measurement purposes.

# **3. What data do we collect?**

We collect two types of information about you: personal data and non-personal data.

**Personal data.** This is information that lets us know who you are. This includes the
information you provide us when registering to use the platform (i.e. your name, company
name, email address, postal address, other contact information you share with us,
associated domain name and credit card information). Your login credentials are also
personal data. This category also includes information tied to your identity that you
provide us through other means, such as emails to our support team.

**Non-personal** data. This is information that doesn’t let us determine your identity. This
generally comes from your use of the services after registering on our website. Non-
personal data includes information that could personally identify you in its original form,
but that we have modified (for instance, by aggregation) any personal data.

In addition, and in the context of using our services, namely creating and sending emails,
we have access to the information contained in the subject and content of the emails that
you send out, as well as the email contacts you send to via our services.

The data you submit should not include any sensitive personal data, such as Government
identifiers (i.e. social security, driving licence, or taxpayer identification numbers),
complete credit card or complete personal bank card numbers, medical records or
particulars connected with applications for care or treatment associated with private
individuals.

# **4. How do we use your data?**

If you are a visitor of our website, we use your personal and non-personal data to engage
with you and support live chat conversations on the website.

When you are one of our customers, we use the information we collect about you to
provide the services to you. As part of that purpose, we use your data:

1. to create and maintain your platform account, and to control access to it;

2. to provide our services and facilitate performance, including verifications relating to
you and for email verifications;

3. to provide you with real-time logs of your use of the platform;

4. to respond to any requests you may submit for support or sales information, or
similar communications;

5. to communicate with you about our services (for example through newsletters,
marketing emails, announcements or special offers);

6. for billing and collection purposes, if you have subscribed to one of our paid plans;

7. for the investigation and prevention of fraud and breaches of the Terms of service;

8. to enable third parties to provide services to us;

9. for customers that request allocated dedicated IP addresses, for the purposes of
assigning the dedicated IP address to that customer;

10. personalise, assess, and improve our services, content and materials;

11. to comply with applicable laws to which we are subject.

We may use your non-personal data to enhance the services, for instance through web
analytics or troubleshooting. We may also use aggregated or depersonalized information
to promote our services, such as by citing usage statistics.

# **5. What are our purposes and legal basis for collecting your personal data?**

We collect your personal data because we need it to perform a contract we have signed
with you or because you have taken steps to enter into a contract with us (for instance,
when you fill in a contact form to request information about our services or when you sign
up for an account). Otherwise, we collect personal data based on your consent for that
specific purpose.

6. With whom do we share personal data?

Except for the limited circumstances we describe here or in an applicable agreement or
our Terms of Service, we do not share your personal data with third parties. When we
need to provide your personal data to third parties, we will only share it to the extent
necessary to provide you with our services, and we ensure that we have in place data
protection requirements with these third parties (including standard contractual clauses
as well as the requisite technical and organisational measures).

We may also share your personal data as required or permitted by law and as to optimally
provide our services through third party providers as described below.

**Hosting Services**: We host the website and operate the platform using third parties,
including OVH and Microsoft Azure. The platform will be hosted from their data centers
throughout the Europe.

**Payment Providers**: We use Paypal, to process subscription payments, and therefore
provide them with the personal data required to charge your credit card and maintain any
payment mandate information as law requires.

**Website functionalities**: We may use third-party services either embedded into our
website (such as Discord) or outside of it (such as GitHub®, LinkedIn and Twitter®) to
communicate with you or to enhance the function of the website and the services.

**Customer engagement**: We use third-party service providers and platforms (such as
Mailgun) for customer engagement, customer chat, product feedback and customer
support ticketing.

**IP addresses**: We may share your contact information with ARIN (American Registry for
Internet Numbers) for the purposes of fulfilling your request to re-assign the dedicated IP
addresses.

While we provide these third parties with no more information than what is necessary to
enable them to provide the services to us, any information that you provide these
services providers independently is subject to their respective privacy policies and
practices.

In no case do we sell, share or rent out your contacts to third parties, nor use them for any
purpose other than those set forth in this policy.

In certain situations, we may be required to disclose personal data in response to lawful
requests by public authorities or regulatory bodies, including to meet law enforcement
requirements, in the case of a court order, a summons to appear in court or any other
similar requisition from a government or the judiciary, or to establish or defend a legal
application.

Additionally, we will provide information to a third party in the event of any reorganization,
merger, sale, joint venture, assignment, transfer or other disposition of all or any portion
of our business, assets or stock (including in connection with any bankruptcy or similar
proceedings).

# **7. For how long do we retain your personal data?**

We keep your personal data for as long as is necessary to provide our services to you
(unless otherwise required by law).

If you would like us to cease all of the described uses of your personal data, you may
delete your account at any time from the Account Settings section of our Dashboard. This
will delete your personal data from our records, and we will make no further use of it. We
may, however, retain copies of your personal data in backups for legal retention purposes
and/or for our own legitimate business purposes.

# **8. How do we transfer your data?**

Mailgun complies with the European General Data Protection Regulation 2016/679 (GDPR)
as well as all applicable data protection laws. For all transfers of personal data from the
EU and EEA, we maintain EU Standard contractual clauses where necessary, ensureadditional safeguards such as data encryption and data minimization, as well as perform
audits and controls on our important sub processors.

You can request to sign our Data Processing Agreement which lists out all our important
sub processors used and the measures in place to ensure proper data protections.

# **9. What are your rights in connection with personal data?**

In accordance with Data Protection laws, you have the right to:

1. **Request access** to your personal data. This enables you to receive a copy of the
personal data we hold about you and to check that we are lawfully processing it.

2. **Request correction** of the personal data that we hold about you. This enables you
to have any incomplete or inaccurate information we hold about you corrected.

3. **Request erasure** of your personal data. This enables you to ask us to delete or
remove personal data where there is no legitimate reason for us continuing to
process it. You also have the right to ask us to delete or remove your personal data
where you have exercised your right to object to processing (see below).

4. **Withdraw your consent** and opt-out from our communications. We will honor your
opt-out within 14 days. Please note that you cannot unsubscribe from service-
related messages if you remain a customer.

5. **Object to processing** of your personal data, for example, if we are relying on a
legitimate interest (or those of a third party) and there is something about your
particular situation which makes you want to object to processing on this basis.

6. **Request the restriction of processing** of your personal data. This enables you to
ask us to suspend the processing of personal data about you, for example if you
want us to establish its accuracy or the reason for processing it.

7. **Request the transfer** of your personal data (right to data portability).

If you want to exercise any of the above rights, please email our privacy team
at privacy@pwnx.io.
PWNX commits to resolve complaints about our collection or use of your personal
information. EU individuals with inquiries or complaints should first contact our Data
Protection Officer at: privacy@pwnx.io.

Mailgun has also committed to cooperate with the panel established by the EU data
protection authorities with regard to unresolved complaints concerning personal data
transferred from the EU.

If you are located in the EU, you also have the right to lodge a complaint to your data
protection authority.

# **10. Security**

The security and integrity of your personal information is very important to us. We follow
industry accepted standards to protect the personal information submitted to us, both
during transmission and once it is received. We ensure the appropriate electronic,
physical and managerial procedures are in place with a view to safeguarding and
preserving all the data handled. Our infrastructure is located in top-tier data centers. Each
of these locations adhere to strict physical and procedural controls which are frequently
audited. Our applications are routinely scanned for vulnerabilities and an independent
penetration test is conducted annually. Our employees undergo background checks
(when allowed) and sign non-disclosure agreements at the time of hire.

Remember, though, that some parts of the services are public and that email, by its
nature, is not a reliably private means of communication. If you voluntarily provide
personal data in a public area of the website, unrelated parties online will be able to view
it and collect it. If you don’t want to make this information publicly available, you shouldn’t
post it.
            `
        }
    },
    created() {
        this.privacy = sanitize(marked(this.privacy))
    }
}

</script>
<style labg="scss" scoped>
</style>