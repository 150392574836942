<template>
  <div class="container-fluid table vh-100">
    <div class="row align-items-center mb-3 header">
      <div class="col-6">
        <h2 class="main-title-1 margin-right-20">
          {{ lab.name }} <px-button v-if="canReadWriteup()" @click.native="showWriteup"
            class="px-button--primary mx-2">Writeup</px-button>
        </h2>
        <p>{{ lab.ip }}</p>
      </div>
      <div class="col-6 text-right px-0">
        <px-button @click.native="goBack" class="px-button--secondary outline icon-left" icon="left-arrow">
          Back
        </px-button>
      </div>
    </div>

    <div class="row">
      <px-card class="mt-3 py-4 w-100">
        <div class="big-lab-name w-100 h-100 position-absolute">
          <svg width="100%" height="100%" viewBox="0 0 500 75" preserveAspectRatio="xMinYMid meet">
            <text x="50%" y="50%" font-size="95" fill="white" dominant-baseline="middle" text-anchor="middle"
              v-if="this.lab.name !== undefined">{{ this.lab.name.toUpperCase() }}
            </text>
          </svg>
        </div>
        <v-chart :options="usersSystemsChart" autoresize class="w-100 graphs"></v-chart>
      </px-card>
    </div>
    <div class="row">
      <div class="col-lg-2 p-0 pr-1 col-6 py-lg-2 pl-lg-0 pr-lg-2">
        <px-card class="px-card d-flex flex-column align-items-center justify-content-around white diagonal">
          <i class="icon icon-cup white"></i>
          <p class="px-card__title">{{ lab.userPoints + lab.rootPoints }}</p>
          <p class="px-card__caption">points</p>
        </px-card>
      </div>
      <div class="col-lg-2 p-0 pl-1 col-6 p-lg-2">
        <px-card class="px-card d-flex flex-column align-items-center justify-content-around white diagonal">
          <i class="icon icon-calendar white"></i>
          <p class="px-card__title" v-if="lab.releaseDate !== undefined">{{
              lab.releaseDate.split("T")[0].split("-").reverse().join("/")
          }}</p>
          <p class="px-card__title" v-if="lab.releaseDate == undefined">--/--/--</p>
          <p class="px-card__caption">Released</p>
        </px-card>
      </div>
      <div class="col-lg-2 p-0 pr-1 col-6 p-lg-2">
        <px-card class="px-card d-flex flex-column align-items-center justify-content-center white diagonal">
          <i class="icon icon-flag white"></i>
          <p class="px-card__title">{{ lab.userPoints }}</p>
          <p class="px-card__caption">Flag 1</p>
        </px-card>
      </div>
      <div class="col-lg-2 p-0 pl-1 col-6 p-lg-2">
        <px-card class="px-card d-flex flex-column align-items-center justify-content-center white diagonal">
          <i class="icon icon-flag white"></i>
          <p class="px-card__title">{{ lab.rootPoints }}</p>
          <p class="px-card__caption">flag 2</p>
        </px-card>
      </div>
      <div class="col-lg-4 p-0 col-12 py-lg-2 pr-lg-0 pl-lg-2">
        <px-card class="px-card d-flex flex-column align-items-center justify-content-center white diagonal">
          <i class="fas fa-tint red"></i>
          <p class="px-card__title">
            <template v-if="lab.firstblood != null">
              <router-link :to="{ name: 'UserDetails', params: { userId: lab.firstblood.id } }" style="color:white;">
                <flag :iso="lab.firstblood.country" :squared="false" />
                {{ lab.firstblood.username }}
              </router-link>
            </template>
            <template v-else>
              N/A
            </template>
          </p>
          <p class="px-card__caption">first blood<span v-if="lab.firstblood != null"> in {{ lab.pwned_in }}</span></p>
        </px-card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 h-100 p-0">
        <px-card class="lab-description p-5" :class="`lab-description--${lab.os}`">
          <h2>Description</h2>
          <div class="tags" v-if="lab.tags && lab.tags.length">
            <px-badge class="outline" v-for="tag in lab.tags" :key="tag">{{ tag }}</px-badge>
          </div>
          <p class="mt-4">{{ lab.description }}</p>
        </px-card>
      </div>
      <!-- <div v-responsive.md.lg.xl class="col-md-5 px-0 actions">
        <px-card class="p-4 h-100">
          <h2>Actions</h2>
          <div class="row d-flex align-items-center my-2" v-if="shouldSeeFreeVPNDownloadButton()">
            <div class="col d-flex align-items-center mb-4">
              <p class="mb-0 pb-0 mr-4">download VPN config</p>
              <i class="fa fa-download pointer" style="font-size: 200%" @click="downloadOvpnFile()"></i>
            </div>
          </div>
          <div class="row d-flex align-items-center my-2" v-if="userCanDownloadPremiumVPN()">
            <div class="col d-flex align-items-center mb-4">
              <p class="mb-0 pb-0 mr-4">download VPN config</p>
              <i class="fa fa-download pointer" style="font-size: 200%" @click="downloadOvpnFile()"></i>
            </div>
          </div>
          <div class="row d-flex align-items-center my-2">
            <div class="col-12">
              <p class="mb-0 pb-1">flag 1</p>
            </div>
            <div class="col-9">
              <input id="flag1-submit" class="lab__flag-input w-100" type="text" placeholder="submit flag">
            </div>
            <div class="col-3 d-flex align-items-center justify-content-center lab__flag-submit"
              :class="{ 'disabled': 1 }">
              <i class="fas fa-flag pointer" @click="submitFlag('flag1')"></i>
            </div>
          </div>
          <div class="row d-flex align-items-center my-3" v-if="lab.type != 'ONESHOT'">
            <div class="col-12">
              <p class="mb-0 pb-1">flag 2</p>
            </div>
            <div class="col-9">
              <input id="flag2-submit" class="lab__flag-input w-100 flag2" type="text" placeholder="submit flag">
            </div>
            <div class="col-3 d-flex align-items-center justify-content-center lab__flag-submit">
              <i class="fas fa-flag pointer flag2" @click="submitFlag('flag2')"></i>
            </div>
          </div>
        </px-card>
      </div> -->
    </div>

  </div>
</template>

<script>
import labService from "../api/lab";
import PxButton from "@/components/PxButton";
import ECharts from "vue-echarts";
import httpService from "@/api/http";
import PxCard from "@/components/cards/PxCard";
import PxBadge from "@/components/PxBadge";
import userService from "../api/user";
import WriteupModal from '../components/WriteupModal';
import { mapState } from 'vuex';
import premiumService from "../api/premium";

export default {
  name: "LabDetails",
  components: {
    PxButton,
    "v-chart": ECharts,
    PxCard,
    PxBadge
  },
  data() {
    return {
      labId: this.$route.params.labId,
      lab: {},
      usersSystemsChart: {
        textStyle: {
          color: "#768aa3",
          fontFamily: "sans-serif"
        },
        color: ["rgb(250, 154, 65)", "rgb(114, 253, 133)"],
        legend: {
          data: ["Flag 2", "Flag 1"],
          textStyle: {
            color: "#768aa3",
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        xAxis: {
          show: true,
          type: "category",
          data: [],
          axisLine: {
            lineStyle: {
              color: "#768aa3",
              opacity: 1,
            },
          },
          splitLine: {
            lineStyle: {
              color: "#555",
              type: "dotted",
              opacity: 1,
            },
          },
        },
        yAxis: {
          type: "value",
          minInterval: 1,
          axisLine: {
            lineStyle: {
              color: "#768aa3",
              opacity: 1
            },
          },
          splitLine: {
            lineStyle: {
              color: "#555",
              type: "dotted",
              opacity: 1,
            },
          },
        },
        series: [
          {
            data: [],
            type: "line",
            symbol: "circle",
            symbolSize: 0,
            lineStyle: {
              color: "rgb(250, 154, 65)",
              width: 0.7,
            },
            areaStyle: {
              opacity: 0.8,
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgb(250, 154, 65)'
              }, {
                offset: 1,
                color: 'rgba(250, 154, 65,0)'
              }])
            },
            name: "Flag 2",
          },
          {
            data: [],
            type: "line",
            symbol: "circle",
            symbolSize: 0,
            lineStyle: {
              color: "rgb(114, 253, 133)",
              width: 0.7,
            },
            areaStyle: {
              opacity: 0.8,
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgb(114, 253, 133)'
              }, {
                offset: 1,
                color: 'rgba(114, 253, 133,0)'
              }])
            },
            name: "Flag 1",
          },
        ],
      },
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    }),
  },
  beforeCreate() {
    this.$parent.breadcrumbs = [{ name: "labs", navigation: "/labs" }]
  },
  created() {
    this.$parent.loading = true;
    const requests = [
      labService.getLab(this.labId),
      labService.getLabLastFlags(this.labId)
    ];
    httpService.all(requests)
      .then(([lab, chart]) => {
        this.lab = lab.data.lab;
        this.$parent.breadcrumbs.push({ name: `lab: ${this.lab.name}` });
        if (this.lab.description.trim().length === 0) {
          this.lab.description = "N/A";
        }
        if (!this.lab.rootPoints) {
          this.lab.rootPoints = 0;
        }
        if (!this.lab.userPoints) {
          this.lab.userPoints = 0;
        }

        this.usersSystemsChart.xAxis.data = chart.data.chartData.xData;
        this.usersSystemsChart.series[0].data = chart.data.chartData.series[0];
        this.usersSystemsChart.series[1].data = chart.data.chartData.series[1];
      })
      .catch(error => {
        const manageableError = !!error && !!error.data && !!error.data.errorDescription;
        if (manageableError) {
          this.flashError(error.data.errorDescription);
        }
        this.$router.push('/labs');
      })
      .finally(() => {
        this.$parent.loading = false;
      });
  },
  methods: {
    isEventLab() {
      const isEventLab = ["EVENT"].some(item => {
        return this.lab.scope && this.lab.scope.toUpperCase() == item
      })
      return isEventLab
    },
    isPremiumLab() {
      const isPremiumLab = ["WEB3", "WEB", "RETIRED"].some(item => this.lab.type !== undefined && this.lab.type.endsWith(item));
      return isPremiumLab
    },
    shouldSeeFreeVPNDownloadButton() {
      return !this.isPremiumLab() && !this.isEventLab();
    },
    getLabType() {
      let labType = this.lab.type;
      if (labType.includes("_")) {
        const splitted = labType.split("_");
        labType = splitted[splitted.length - 1];
      }
      return labType.toUpperCase();
    },
    isUserAllowedToSeePremiumLab() {
      const isUserPremium = this.$parent.isPremium();
      const hasPremium = this.$parent.hasPremium;
      const labType = this.getLabType();
      const isUserAllowed = hasPremium(labType)
      if (!isUserPremium) return false;
      if (!isUserAllowed) return false;
      return true;
    },
    userCanDownloadPremiumVPN() {
      const isPremiumLab = this.isPremiumLab();
      const isEventLab = this.isEventLab();
      if (isEventLab) return false;
      if (!isPremiumLab) return false;
      if (!this.isUserAllowedToSeePremiumLab()) return false;
      return true;
    },
    submitFlag(flagType) {
      let flagString = flagType == "flag1" ? document.getElementById("flag1-submit").value : document.getElementById("flag2-submit").value
      labService.submitLabFlag(this.labId, flagString, flagType == "flag1" ? 'user' : 'root')
        .then(response => {
          const successMessage = !!response && !!response.data && !!response.data.message;
          if (successMessage) {
            this.flashSuccess(response.data.message);
          }
          this.onClose(true);
        })
        .catch(error => {
          const manageableError = !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
          this.onClose(false);
        })
        .finally(() => {
          this.$parent.loading = false;
          this.modal.close();
        });
    },
    downloadOvpnFile() {
      this.$parent.loading = true;
      let prom = null;
      if (!this.isPremiumLab() || !this.isUserAllowedToSeePremiumLab()) {
        prom = userService.getOvpn();
      }

      if (this.isPremiumLab() && this.isUserAllowedToSeePremiumLab()) {
        prom = premiumService.getOvpnConfig();
      }

      prom.then(response => {
        const blob = new Blob([response.data], { type: "text/plain" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = "config.ovpn";
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        this.flashSuccess("VPN Config downloaded successfully");
      })
        .catch(error => {
          const manageableError = !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => this.$parent.loading = false);
    },

    canReadWriteup() {
      const userPremiumTypes = this.user.premium.map(item => item.premium.type);
      const labPremiumType = this.lab.type;
      const labPremiumScope = this.lab.scope
      const isAllowed = userPremiumTypes.some(item => {
        return userPremiumTypes.length > 0 && labPremiumType !== undefined && (labPremiumType.endsWith(item) || item == labPremiumType || labPremiumScope.endsWith(item) || item == labPremiumScope);
      })

      const isPublic = this.lab.scope == "PUBLIC" && (this.lab.type == "NORMAL" || this.lab.type == "ONESHOT");
      return !isPublic && isAllowed;
    },

    showWriteup() {
      const { name: lab_name, id: lab_id } = this.lab;
      this.$modal.show(
        WriteupModal,
        { "lab_id": lab_id, "lab_name": lab_name, stopLoading: () => (this.$parent.loading = !this.$parent.loading) },
        { height: 'auto', maxHeight: 600 }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";


//! ToDo: make input component

button,
input,
optgroup,
select,
textarea,
html input[type="button"],
input[type="reset"],
input[type="submit"],
button[disabled],
html input[disabled],
button::-moz-focus-inner,
input::-moz-focus-inner,
input[type="checkbox"],
input[type="radio"],
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="search"],
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.lab__flag-input {
  background: $neutral--dark;
  color: $neutral--soft;
  padding: 10px 20px;
  font-size: .8rem;
  z-index: 10;

  &:focus {
    border-left: 4px solid $soft-green;
  }

  &.flag2:focus {
    border-left: 4px solid $soft-orange;
  }
}

.lab__flag-submit {
  i {
    color: $neutral--dark;
    background: rgba($soft-green, 1);
    font-size: 15px !important;
    padding: 10px;
    border-radius: 50%;
    transition: all .3s;

    &:hover {
      background: rgba($soft-green, .5);
    }

    &.flag2 {
      background: rgba($soft-orange, 1) !important;

      &:hover {
        background: rgba($soft-orange, .5) !important;
      }
    }
  }
}

* {
  p {
    color: $neutral--soft;
  }
}

h2 {
  color: $soft-green;
}

.px-card {
  margin: 5px 0;

  i {
    font-size: 2rem;
    margin: 5px 0;
  }

  &__title {
    margin: 5px 0;
  }
}

.px-badge {
  display: inline-block;
  margin-right: 5px;
  min-width: 0;

}

.lab-description {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}

.lab-description {
  &--0 {
    background-image: url("../assets/images/icon-set/linux-inactive.png");
  }

  &--1 {
    background-image: url("../assets/images/icon-set/windows-logo.png");
  }
}

.icon,
px-card__caption {
  opacity: .3 !important;
}

.actions {
  font-size: .8rem;
}

.big-lab-name {
  opacity: .04;
}

.fa-download {
  transition: all .3s;

  &:hover {
    opacity: .7;
  }
}
</style>
