<template>
    <div class="main-overlay" :class="{ 'active': $parent[this.overlayOpenVar] }">
        <div class="main-overlay-wrapper">
            <div class="main-overlay-fixed">
                <div class="main-loading-overlay" v-if="loadingVar && $parent[this.loadingVar]">
                    <div class="loading-spinner-circle"></div>
                </div>
                <div class="main-overlay-content">
                    <slot name="overlay-content"></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "RightMenuOverlay",
    data() {
        return {}
    },
    props: {
        loadingVar: {
            type: String,
            required: false
        },
        overlayOpenVar: {
            type: String,
            required: true,
        }
    }
}
</script>
<style scoped lang="scss">
@import "../assets/css/colors.scss";

@media screen and (max-width: 768px) {
    .main-overlay {
        max-width: 100% !important;
        z-index: 10000 !important;

        &.active {
            width: 100% !important;
            border: none !important;
        }
    }

    .main-overlay-fixed {
        min-width: 100% !important;
        max-width: 100% !important;
    }

    .main-container {
        margin: 0px !important;
        padding: 0px !important;
    }

}

.main-overlay {
    position: absolute;
    right: 0%;
    top: 0%;
    height: 100%;
    width: 0%;
    max-width: 40%;
    background-color: var(--card-bg, #15191d);
    z-index: 100;
    margin: 0px !important;
    padding: 0px !important;
    transition: all 0.2s ease-in-out;

    &.active {
        padding: 5px !important;
        border-left: 1px solid var(--border-color, $soft-green);
        width: calc(40 / 100 * 100%);
    }
}

.main-overlay-wrapper {
    position: relative;
    min-height: 100%;
}

.main-overlay-fixed {
    position: fixed;
    min-height: 100% !important;
    margin: 0px !important;
    padding: 0px !important;
    min-width: 40%;
    max-width: 40%;
}

.main-overlay-content {
    min-height: 100% !important;
    min-width: 100% !important;
}

.main-loading-overlay {
    position: absolute;
    min-width: 100% !important;
    min-height: 100% !important;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    margin: -5px !important;
    padding: -10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-spinner-circle {
    width: 80px;
    height: 80px;
    border: 10px solid darken($soft-green, 50);
    border-left: 10px solid var(--border-color, $soft-green);
    border-radius: 50%;
    margin-bottom: 200px;
    animation: rotate-spinner 0.5s infinite forwards linear;
}

.main-container {
    max-height: calc(100vh - 5vh);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    min-width: 100% !important;
    border: 1px solid $neutral--darkest;
}

.main-overlay-content {
    position: relative !important;
    overflow-x: hidden !important;
}


@keyframes rotate-spinner {
    100% {
        transform: rotate(360deg);
    }

}
</style>
