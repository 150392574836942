<template>
  <div>
    <div class="containerfluid active-subs mb-5" v-if="this.$parent.user.premium.length > 0">
      <div class="container-fluid mb-3">
        <div class="row">
          <h1 class="main-title-1 margin-right-20">Your premium subscriptions</h1>
        </div>
      </div>
      <div v-for="(premium, index) in this.$parent.user.premium" :key="index" class="mx-3 row active-subs__row justify-content-between align-items-center p-2 my-2">
        <div class="col-3 active-subs__name">
          <p class="mb-0">{{ premium.premium.name }}</p>
        </div>
        <div class="col-3">
          <p class="mb-0">{{ premium.premium.description }}</p>
        </div>
        <div class="col-2 text-center active-subs__date">
          <b>start:</b>
          <p class="mb-0">{{ new Date(premium.start_date + "Z").toDateString() }}</p>
        </div>
        <div class="col-2 text-center active-subs__date">
          <b>end:</b>
          <p class="mb-0">{{ new Date(premium.end_date + "Z").toDateString() }}</p>
        </div>
      </div>
    </div>
    <div class="row position-relative">
      <div class="col-md-6 h-100 rounded">
        <!-- <div class="p-1 py-3 bg-dark h-100 mb-2 mb-md-0"> -->
        <!--   <div class="mx-4 mb-4 h-100"> -->
        <!--     <label for="token-type" class="card__label">subscriptions or pwnx tokens</label> -->
        <!--     <select v-model="tokenType" name="token-type" id="token-type" class="form-control my-1 text-uppercase"> -->
        <!--       <option v-for="(ttype, index) of tokenTypes" :value="ttype" :key="index" class="text-uppercase"> -->
        <!--         {{ ttype }} -->
        <!--       </option> -->
        <!--     </select> -->
        <!--     <div v-if="tokenType === 'tokens'" class="description mt-3"> -->
        <!--       buy tokens to start dedicated instances of any pwnx lab on demand -->
        <!--     </div> -->
        <!--     <div v-if="tokenType === 'subscriptions'" class="description mt-3"> -->
        <!--       subscribe to start any premium pwnx lab on demand -->
        <!--     </div> -->
        <!--   </div> -->
        <!-- </div> -->
      </div>
      <div class="col-md-6 w-100">
        <div class="mb-3 d-flex flex-row justify-content-center w-100">
          <form class="voucher-form" @submit.prevent>
            <div class="p-1 py-3 card d-flex flex-column justify-content-center rounded bg-dark"
              :class="{ 'green': isVoucherValid, 'red': !isVoucherValid }">
              <div class="form-group mb-4 mx-4">
                <label for="voucher" class="card__label">Do you have a voucher?</label>
                <input :disabled="isVoucherValid && voucher !== null" v-model="voucherInput" type="text"
                  placeholder="Voucher" class="form-control" @keyup.enter="checkVoucher()">
              </div>
              <div id="calculations" v-if="isVoucherValid && voucher !== null && selectedPackage !== null"
                class="w-100 my-1 mb-3 mx-5">
                <h6 class="text-uppercase fw-bold">Package Price: {{ selectedPackage.price }}€</h6>
                <!-- <h6 class="text-uppercase fw-bold">Voucher Discount: {{ voucher.discount }}%</h6> -->
                <!-- <h6 class="text-uppercase fw-bold">Total: {{ getPackageCost() }}€</h6> -->
              </div>
              <div class="row mx-4">
                <px-button @click.native="checkVoucher()" :class="{
                  'disabled': shouldDisableVoucherBtn(),
                  'col-sm-12': !isVoucherValid || voucher === null,
                  'col-sm-8': isVoucherValid && voucher !== null
                }" class="px-button--primary outline">submit</px-button>
                <px-button v-if="isVoucherValid && voucher !== null" @click.native="clearVoucher()"
                  class="px-button--danger col-sm-4 outline">clear</px-button>
              </div>
              <div class="px-card-overlay" v-if="isVoucherLoading">
                <div class="voucher-spinner"></div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <template v-if="tokenType === 'tokens'">
        <h1 class="main-title-1" style="font-size:26px">Buy Tokens</h1>
        <div class="my-3 m-0 p-0 text-success">
          <h1 class="main-title-1 text-success m-0 p-0 fw-bold">Balance: {{ user.tokens }} Tokens</h1>
        </div>
        <div class="my-3" v-if="user.inCorporate">
          <h1 class="main-title-1 text-success m-0 p-0 fw-bold">Company Balance: {{ user.corporateTokens }} Tokens</h1>
        </div>
      </template>
      <template v-if="tokenType === 'subscriptions' && getPackagesType(tokenType).length > 0">
        <div class="my-3 d-flex flex-row justify-content-center align-items-center">
          <h1 class="main-title-1 w-100" style="font-size:26px">Buy Subscription</h1>
        </div>
      </template>
    </div>
    <div class="w-100 row m-0" v-if="getPackagesType(tokenType).length > 0">
      <div :class="[!voucher ? 'bg-dark':'bg-gold']" class="card p-4 col-md-4 text-center" v-for="(pkg, index) of filterFrequency(getPackagesType(tokenType))" :key="pkg.id">
        <div class="d-flex justify-content-center align-items-center" id="image" style="height:100%;width:100%;">
          <img v-if="tokenType !== 'tokens'" class="card-img-top" :src="pkg.image"
            style="width: 100%;height: 100%;" alt="Card image cap" />
          <img v-if="tokenType === 'tokens'" class="card-img-top" :src="`${getImagePath(index)}`"
            style="max-height:300px;width: 250px;height: 250px;" alt="Card image cap" />
        </div>
        <h4 v-if="tokenType === 'tokens'" class="text-center my-2">{{ pkg.amount }} Tokens</h4>
        <h4 v-if="tokenType !== 'tokens'" class="text-center my-2">{{ pkg.name }}</h4>
        <div class="card-body text-center">
          <p class="card-text">{{ pkg.description }}</p>
        </div>

        <div class="d-flex mt-5 container justify-content-center align-items-center" v-if="tokenType === 'subscriptions'">
          <div @click="() => yearly = !yearly" class="toggle-container d-flex align-items-center px-1 pointer rounded-pill position-relative" :class="{'active': yearly  || pkg.frequency.includes('YEAR')}">
            <div class="toggle-circle" :class="{'active': yearly || pkg.frequency.includes('YEAR') }"></div>
            <div class="d-flex flex-row align-items-center toggle-description" :class="{'active': yearly || pkg.frequency.includes('YEAR')}">
              <p v-if="yearly || pkg.frequency.includes('YEAR')">yearly</p>
              <p v-if="!yearly && !pkg.frequency.includes('YEAR')">monthly</p>
            </div>
          </div>
        </div>

        <hr />
        <px-button
          v-if="tokenType != 'tokens'"
          :class="getButtonsColor(pkg.id)"
          @click.native="selectPackage(pkg)">
          SELECT FOR {{ pkg.price }}€
        </px-button>
        <px-button
          v-if="tokenType == 'tokens'"
          :class="getButtonsColor(pkg.id)"
          @click.native="selectPackage(pkg)">
          SELECT FOR {{ pkg.price }}€
        </px-button>
      </div>
    </div>
    <div v-if="getPackagesType(tokenType).length == 0 && this.packages[tokenType].length > 0" class="text-center">
      <h2 class="my-3 bg-dark p-3">You already bought all the premium packages</h2>
    </div>
    <div class="position-relative paypal-container w-100">
      <div class="tokens-container w-100 text-center my-3">
        <h3 v-if="isNotNullAndType('tokens')">{{ selectedPackage.amount }} Tokens</h3>
        <h3 v-if="isNotNullAndType('tokens')">{{ getPackageCost() }}€</h3>
        <div class="paypal-inside mt-5" id="paypal-button-container" v-show="isNotNullAndType('tokens')"></div>
      </div>
      <div class="subscriptions-container w-100 text-center my-3">
        <h3 v-if="isNotNullAndType('subscriptions')">Premium Package Subscription</h3>
        <h3 v-if="isNotNullAndType('subscriptions')">{{ getPackageCost() }}€</h3>
        <div class="paypal-inside mt-5" id="paypal-button-container-subscription" v-show="isNotNullAndType('subscriptions')"></div>
      </div>
    </div>
    <!-- <div id="paypal-button-container" class="container position-relative text-center" v-show="selectedPackage && tokenType == 'tokens'">
      <div id="paypal-inside" class="my-4" v-if="selectedPackage">
        <h3>{{ selectedPackage.amount }} Tokens</h3>
        <h3>{{ getPackageCost() }}€</h3>
      </div>
    </div>
    <div id="paypal-button-container-subscription" class="container position-relative text-center" v-show="selectedPackage && tokenType == 'subscriptions'">
      <div id="paypal-inside" class="my-4" v-if="selectedPackage">
        <h3>Premium Package Subscription</h3>
        <h3>{{ getPackageCost() }}€</h3>
      </div>
</div> -->
  </div>
</template>
<script>
import PxButton from '../components/PxButton.vue';
import tokensService from '../api/tokens';
import premiumService from '../api/premium';
import { mapState } from 'vuex';
// import PxCard from '../components/cards/PxCard';
import InvoiceRequestModal from '../components/InvoiceRequestModal.vue';
import boughtAudio from '../assets/audio/bought.wav';

export default {
  name: "BuyTokens",
  components: {
    PxButton,
    //       PxCard
  },
  computed: {
    isVoucherValid() {
      const isValid = !!this.voucher;
      if (!isValid) return true; // any null - not submitted
      return Object.keys(this.voucher).length > 0
    },
    ...mapState({
      user: state => state.user
    })
  },
  beforeCreate() {
    this.$parent.breadcrumbs = [{name: "premium", navigation: "/tokens/buy"}]
  },
  beforeMount() {
    this.loadPaypalButton();
  },
  methods: {
    getImagePath(index) {
      if (index + 1 == 1) {
        return this.token1
      } else if (index + 1 == 2) {
        return this.token2
      } else if (index + 1 == 3) {
        return this.token3
      }
      return this.token1
    },
    getButtonsColor(pkgId) {
      const color = this.yearly || this.showBoth ? 'success':'orange';
      if (this.tokenType == "subscriptions" && this.isSelected(pkgId)) return `px-button--${color}`;
      if (this.tokenType == "tokens" && this.isSelected(pkgId)) return "px-button--primary";
      return this.tokenType == "subscriptions" ? `px-button--${color} outline`:"px-button--primary outline";
    },
    filterFrequency(packages) {
      if (this.tokenType !== "subscriptions") return packages;
      let frequency = null;
      if (!this.showBoth) {
        frequency = this.yearly ? 'YEAR':'MONTH';
      } else {
        frequency = "BOTH";
      }
      return packages.filter(item => item.frequency === frequency || frequency === "BOTH")
    },
    downloadPremiumVPN() {
      premiumService.getOvpnConfig()
        .then(response => {
          const blob = new Blob([response.data], { type: "text/plain" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = "config.ovpn";
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch(error => {
          const manageableError = !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => this.$parent.loading = false);
    },
    isBought(pkgId) {
      if (!pkgId) return false;
      let bought = false;
      const premiums = this.$parent.user.premium;
      for ( const pkg of premiums ) {
        bought = pkg.premium.id == pkgId
        if (bought) return bought;
      }
      return bought
    },
    getPackagesType(pkgType) {
      const filtered = this.packages[pkgType].filter(pkg => {
        return !this.isBought(pkg.package_id)
      })
      return filtered
    },
    loadPremiumPackages() {
      this.$parent.loading = true;
      premiumService.getPackages().then(response => {
        this.packages["subscriptions"] = response.data.packages;
      }).finally(() => {
          this.$parent.loading = false;
        })
    },
    playAudio() {
      const audio = new Audio(boughtAudio);
      audio.volume = 0.3;
      audio.play()
    },
    clearVoucher() {
      this.voucherInput = null;
      this.voucher = null;
      this.showBoth = false;
      this.loadPackages();
      this.loadPremiumPackages();
    },
    getPackageCost() {
      if (!this.selectedPackage) return
      const selectedPrice = this.selectedPackage.price;
      if (!this.voucher) return selectedPrice;
      if (!this.isVoucherValid || this.voucher === null) return selectedPrice;
      return selectedPrice - (selectedPrice / 100 * this.voucher.discount)
    },
    shouldDisableVoucherBtn() {
      if (this.isVoucherValid && this.voucher != null) return true
      if (!this.isVoucherValid) return true;
      return false;
    },
    showInvoiceModal(orderId) {
      this.$modal.show(
        InvoiceRequestModal,
        {
          orderId,
          orderType: "premium"
        },
        { height: 'auto', width: '50%' }
      )
    },
    isNotNullAndType(type_) {
      return this.selectedPackage && this.tokenType === type_
    },
    isSelected(pkgId) {
      if (!this.selectedPackage) return false;
      return this.selectedPackage.id === pkgId;
    },
    loadPackages() {
      tokensService.getPackages()
        .then(response => this.packages["tokens"] = response.data.packages)
    },
    loadPaypalOrderButton() {
      const url = `https://www.paypal.com/sdk/js?client-id=${process.env.VUE_APP_payPalClientId}&currency=EUR&disable-funding=bancontact,blik,eps,giropay,ideal,mybank,p24,sepa,sofort,venmo`;
      const vm = this;
      this.$loadScript(url)
        .then(() => {
          // eslint-disable-next-line no-undef
          paypal.Buttons({
            createOrder: () => {
              const premiumData = {
                package_id: vm.selectedPackage.id,
                voucher: vm.voucherInput
              };

              return tokensService.createTokens(premiumData).then(order => {
                  return order.data.order_id;
                })
                .catch(error => {
                  const manageableError = !!error && !!error.data && !!error.data.errorDescription;
                  if (manageableError) {
                    vm.flashError(error.data.errorDescription);
                  }
                });
            },

            onCancel: (data, actions) => {
              console.log({data, actions})
              const cancelData = {
                order_id: data.orderID
              };

              return tokensService.cancelTokens(cancelData).then((response) => {
                vm.flashError(response.data.message);
              });
            },

            // Call your server to finalize the transaction
            onApprove: (data, actions) => {
              console.log({data, actions})
              const approveData = {
                order_id: data.orderID
              };

              return tokensService.approveTokens(approveData).then(() => {
                vm.playAudio();
                vm.showInvoiceModal(approveData.order_id)
              }).catch(error => {
                const manageableError = !!error && !!error.data && !!error.data.errorDescription;
                if (manageableError) {
                  vm.flashError(error.data.errorDescription);
                }
              });
            }
          }).render("#paypal-button-container")
      }).catch(() => {})
    },
    loadPaypalSubscriptionButton() {
      const url = `https://www.paypal.com/sdk/js?client-id=${process.env.VUE_APP_payPalClientId}&currency=EUR&disable-funding=bancontact,blik,eps,giropay,ideal,mybank,p24,sepa,sofort,venmo&vault=true&intent=subscription`;
      const vm = this;
      this.$loadScript(url)
        .then(() => {
          // eslint-disable-next-line no-undef
          paypal.Buttons({
            createSubscription: (data, actions) => {
              console.log(data, actions)
              console.log(vm.selectedPackage)
              const premiumData = {
                package_id: vm.selectedPackage.id,
                voucher: vm.voucherInput
              };

              return premiumService.createPremium(premiumData).then(order => {
                  vm.subscriptionID = order.data.order_id;
                  return order.data.order_id;
                })
                .catch(error => {
                  const manageableError = !!error && !!error.data && !!error.data.errorDescription;
                  if (manageableError) {
                    vm.flashError(error.data.errorDescription);
                  }
                });
            },


            onCancel: (data, actions) => {
              console.log({data, actions})
              const cancelData = {
                // order_id: data.orderID
                order_id: vm.subscriptionID
              };

              return premiumService.cancelPremium(cancelData).then((response) => {
                vm.flashError(response.data.message);
              });
            },

            // Call your server to finalize the transaction
            onApprove: (data, actions) => {
              console.log({data, actions})
              const approveData = {
                // order_id: data.orderID
                order_id: data.subscriptionID
              };

              return premiumService.approvePremium(approveData).then(() => {
                  vm.playAudio();
                  vm.showInvoiceModal(approveData.order_id)
                }).catch(error => {
                  const manageableError = !!error && !!error.data && !!error.data.errorDescription;
                  if (manageableError) {
                    vm.flashError(error.data.errorDescription);
                  }
                });

            }
          }).render("#paypal-button-container-subscription");
        })
        .catch((err) => {
          console.log("paypal render error", err)
        });
    },
    loadPaypalButton() {
      if (this.tokenType === 'tokens')
        this.loadPaypalOrderButton()
      else
        this.loadPaypalSubscriptionButton()
    },
    selectPackage(pkg) {
      this.selectedPackage = pkg
    },
    checkVoucher() {
      if (!this.voucherInput) {
        this.flashError("Voucher cannot be empty")
        return
      }
      this.isVoucherLoading = true;
      premiumService.checkVoucher({ "voucher": this.voucherInput })
        .then((response) => {
          const manageableMessage = !!response.data && !!response.data.voucher;
          if (manageableMessage) {
            this.voucher = response.data.voucher;
            this.packages.subscriptions = [response.data.voucher.plan];
            this.showBoth = true;
            this.flashSuccess("The voucher is valid")
          }
        })
        .catch((error) => {
          const manageableError = !!error.data && error.data.errorDescription;
          if (manageableError) {
            this.voucher = null
            this.showBoth = false;
            setTimeout(() => {
              this.voucher = null;
            }, 3000);
            this.flashError(error.data.errorDescription)
          }
        })
        .finally(() => {
          this.isVoucherLoading = false;
        });
    }
  },
  created() {
    this.loadPackages()
    this.loadPremiumPackages()
  },
  data() {
    return {
      yearly: false,
      showBoth: false,
      packages: { tokens: [], subscriptions: [] },
      subscriptionID: null,
      tokenTypes: ["tokens", "subscriptions"],
      tokenType: "subscriptions",
      selectedPackage: null,
      voucher: null,
      voucherInput: null,
      isVoucherLoading: false,
      token1: require("../assets/images/token-1.png"),
      token2: require("../assets/images/token-2.png"),
      token3: require("../assets/images/token-3.png")
    }
  },
  watch: {
    yearly: function() {
      this.selectedPackage = null;
    },
    showBoth: function() {
      this.selectedPackage = null;
    },
    tokenType: function() {
      this.selectedPackage = null;
      this.loadPaypalButton();
    }
  }
}
</script>
<style scoped lang="scss">
@import "../assets/css/colors.scss";

h1 {
  margin: 0px !important;
  padding: 0px !important;
}

.px-card--neon {
  position: relative;
}

.px-card-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 10;
  min-width: 100%;
  min-height: 100%;
}

@keyframes voucher-spinner {
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.voucher-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 80px;
  min-height: 80px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  border: 5px solid $neutral--dark;
  border-bottom: 5px solid $soft-green;
  animation: voucher-spinner 0.5s linear forwards infinite;
}

.px-card--neon.invalid {
  animation: invalid-card 0.2s linear alternate;
}

.voucher-form {
  width: 100%;
}

@keyframes invalid-card {
  0% {
    transform: translateX(-5%);
  }

  50% {
    transform: translateX(10%);
  }

  100% {
    transform: translateX(5%);
  }
}

.paypal-inside {
  position: relative;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .paypal-inside {
    position: absolute;
    width:100%;
    height:100%;
    top: 50%;
    left: 50%;
    // transform: translate(-50%, 0%);
    transform: translateX(-22.5%);
  }

}

.active-subs {
  font-size: .9rem;
  &__row {
    border-radius: 10px;
    border:1px solid rgba(255,255,255,.1);
    &:nth-child(odd) {
      background: $neutral--dark;
    }
  
    &:nth-child(even) {
      background: $neutral--medium;
    }
  }

  &__name {
    text-transform: uppercase;
    font-size: .9rem;
    font-weight: bold;
  }
  &__date {
    font-size: .8rem;
    opacity: .8;
  }
}

.toggle-container {
  position: relative;
  min-width: 130px;
  // max-width: 100px;
  height: 50px;
  max-height: 50px;
  transition: all 0.5s ease;
  background: $soft-orange;
}

.toggle-container.active {
  background: $soft-green;
}

.toggle-description {
  position: absolute;
  right: 0;
  margin: 15px 5px 0px;
  font-weight: bolder;
  text-transform: uppercase;
}

.toggle-description.active {
  left: 0!important;
  color: black;
  margin: 15px 5px 0px;
  font-weight: bolder;
}

.toggle-circle {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  transition: all 0.5s ease;
}

.toggle-circle.active {
  transform: translateX(200%);
  transition: all 0.5s ease;
}

.bg-gold {
  background: linear-gradient(to bottom, #AA8C2C, $neutral--dark);

}

</style>
