<template>
  <div class="px-card">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "PxCard"
}
</script>

<style scoped lang="scss">
@import "../../assets/css/colors";
@import "../../assets/css/variables";

$map_card_colors: (
    "red": $soft-red,
    "yellow": $soft-yellow,
    "green": $soft-green,
    "blue": $soft-blue,
    "orange": $soft-orange,
    "purple": $soft-purple,
    "white": white
);

.px-card {
  background: rgba($neutral--medium, 0.5);
  color: $neutral--light;
  padding: $gap--xs;
  position: relative;
  overflow: hidden;

  &.no-bg {
    background: none !important;
  }

  h3 {
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
  }

  &::after {
    content: "";
    height: 100%;
    width: 5px;
    right: 0;
    top: 0;
    position: absolute;
    transition: all 0.1s;
  }

  &__title {
    font-size: 1.2rem;
    margin-bottom: 0;
  }

  &__caption {
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 0;
  }

  &__title,
  &__caption,
  i {
    &.green {
      color: $soft-green;
    }

    &.red {
      color: $soft-red;
    }

    &.yellow {
      color: $soft-yellow;
    }

    &.blue {
      color: $soft-blue;
    }

    &.orange {
      color: $soft-orange;
    }

    &.purple {
      color: $soft-purple;
    }

    &.white {
      color: white;
    }
  }

  &--neon {
    @each $name, $color in $map_card_colors {
      &.#{$name} {
        &.px-card::after {
          background: $color;
        }

        &.left {
          &.px-card::after {
            left: 0;
          }
        }

        &.animate {
          &.px-card::after {
            animation-name: glowAnimation;
            animation-duration: 3s;
            animation-iteration-count: infinite;
          }
        }

        &.glow,
        &:hover {
          &.px-card::after {
            box-shadow: 0 0 25px $color;
          }
        }

        @keyframes glowAnimation {
          0% {
            box-shadow: 0 0 55px $color;
          }
          50% {
            box-shadow: 5px 0 30px $color;
          }
          100% {
            box-shadow: 0 0 55px $color;
          }
        }
      }
    }
  }

  &.diagonal {
    @each $name, $color in $map_card_colors {
      &.#{$name} {
        &.px-card::after {
          background: rgba($color, .04);
          pointer-events: none;
          content: "";
          width: 100%;
          height: 100%;
          transform: rotateZ(-45deg);
          top: 10%;
          right: -55%;
        }
      }
    }
  }
}
</style>