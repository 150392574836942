<template>
    <BaseModal :title="modal.title" :buttons="modal.buttons" :close="modal.close">
        <div>
            <p>To enable PWNX notification you need first to enable notification in your browser settings.</p>
            <div v-if="isChrome">
                <p>
                    Check your Chrome notification settings at this url.<br>
                    <a style="color: #1d7dff; text-decoration: underline">chrome://settings/content/siteDetails?site={{appUrl}}</a>
                </p>
                <ol>
                    <li>Copy and paste the url into a blank page (browsers does not allow redirect to its setting page)
                    </li>
                    <li>Then, in notification select, choose: <em>Allow</em></li>
                </ol>
            </div>
            <div v-else-if="isFirefox">
                <p>
                    Check your Firefox notification settings at this url.<br>
                    <a style="color: #1d7dff; text-decoration: underline">about:preferences#privacy</a>
                </p>
                <ol>
                    <li>Copy and paste the url into a blank page (browsers does not allow redirect to its setting page)
                    </li>
                    <li>Then, open notification settings</li>
                    <li>Finally, choose {{appUrl}} and select allow</li>
                </ol>
            </div>
            <div v-else>
                <p>
                    Check your browser notification settings, find {{appUrl}} and select allow.
                </p>
            </div>
            <p>After settings edit, enable notification inside PWNX settings</p>
        </div>
    </BaseModal>
</template>

<script>
    import BaseModal from "./BaseModal";

    export default {
        name: "EnableNotificationModal",
        components: {BaseModal},
        data() {
            return {
                modal: {
                    title: "Enable notifications",
                    buttons: [
                        {
                            name: "Ok",
                            class: "btn-primary",
                            action: () => this.selfClose()
                        },
                    ],
                    close: () => this.selfClose()
                },
                appUrl: process.env.VUE_APP_appUrl,
                isOpera: false,
                isFirefox: false,
                isSafari: false,
                isIE: false,
                isEdge: false,
                isChrome: false,
                isEdgeChromium: false,
                isBlink: false,
            }
        },
        created() {
            // Opera 8.0+
            this.isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
            // Firefox 1.0+
            this.isFirefox = typeof InstallTrigger !== 'undefined';
            // Safari 3.0+ "[object HTMLElementConstructor]"
            this.isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
                return p.toString() === "[object SafariRemoteNotification]";
            })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
            // Internet Explorer 6-11
            this.isIE = /*@cc_on!@*/false || !!document.documentMode;
            // Edge 20+
            this.isEdge = !this.isIE && !!window.StyleMedia;
            // Chrome 1 - 79
            this.isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
            // Edge (based on chromium) detection
            this.isEdgeChromium = this.isChrome && (navigator.userAgent.indexOf("Edg") !== -1);
            // Blink engine detection
            this.isBlink = (this.isChrome || this.isOpera) && !!window.CSS;
        },
        methods: {
            selfClose() {
                this.$emit("close");
            },
        }
    }
</script>

<style scoped>

</style>
