<template>
  <div class="container-fluid">
    <input type="hidden" value="" id="labIpClipboard">
    <div class="p-3 row justify-content-between team-row py-4" :id="`labs_${team.id}`"
         v-for="team in teams" :key="team.name">
      <div class="col-4 d-flex align-items-center team-name pointer" @click="handleExpandLab(team.id)">
        <i class="fas collapsed-icon fa-caret-right mr-2"></i>
        <i class="fas open-icon fa-caret-down mr-2"></i>
        <h2 class="mb-0 team-name py-1">
          {{ team.name }}
        </h2>
        <p class="mb-0" v-if="currentTeam === team.id">
          <px-badge class="outline ml-3" :class="'px-badge--purple'">your team</px-badge>
        </p>
      </div>
      <div class="col-6 text-right">
        <div class="row">
          <div class="col text-right" v-for="lab in team.labs" :key="lab.id">
            <p class="mb-0 underline-hover pointer" @click="copyToClipboard">{{ lab.ip }}<i
                class="ml-2 far fa-clipboard"></i></p>
            <p class="mb-0">{{ lab.name }}</p>
          </div>
        </div>
      </div>
      <div class="col-12 team-labs mt-4 closed">
        <div class="row labs-row py-1 d-flex align-items-center"
             v-for="lab in team.labs" :key="lab.id">
          <div class="col-2">
            <h2 class="mb-0">
              {{ lab.name }}
            </h2>
          </div>
          <div class="col-3">
            <p class="mb-0">
              {{ lab.ip }}
            </p>
          </div>
          <div class="col-2">
            <p class="mb-0 report-button">
              report
              <i class="fas ml-2 fa-exclamation-triangle"></i>
            </p>
          </div>
          <!--          <div class="col d-flex justify-content-end align-items-center" v-if="currentTeam !== team.id">-->
          <!--            <div class="row d-flex align-items-center">-->
          <!--              <div class="col">-->
          <!--                <input class="flag-submit" placeholder="submit flag" :id="`submit_${lab.id}`" type="text">-->
          <!--              </div>-->
          <!--              <div class="col pl-0">-->
          <!--                <i class="fas submit-flag fa-flag" @click="submitFlag(lab.id)"></i>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import labService from "../api/lab";
import PxBadge from "./PxBadge";

export default {
  name: "PurpleEventRow",
  data() {
    return {}
  },
  props: {
    teams: null,
    currentTeam: null
  },
  components: {
    "px-badge": PxBadge
  },
  methods: {
    handleExpandLab(teamId) {
      // let teamId = teamId
      let labContainer = document.getElementById(`labs_${teamId}`)

      if (labContainer.classList.contains("closed"))
        labContainer.classList.remove('closed')
      else
        labContainer.classList.add('closed')
    },
    submitFlag(labId) {
      let eventId = this.$parent.$data.event.id
      this.flagString = document.getElementById(`submit_${labId}`).value
      if (!this.flagString) {
        this.flashError("Flag cannot be empty");
        return;
      }
      this.$parent.loading = true;
      labService.submitFlag(eventId, labId, this.flagString)
          .then(response => {
            const successMessage = !!response && !!response.data && !!response.data.message;
            if (successMessage) {
              this.flashSuccess(response.data.message);
            }
            this.onClose(true);
          })
          .catch(error => {
            const manageableError = !!error && !!error.data && !!error.data.errorDescription;
            if (manageableError) {
              this.flashError(error.data.errorDescription);
            }
            this.onClose(false);
          })
          .finally(() => {
            this.$parent.loading = false;
            this.emit('submittedFlag')
          });
    },
    copyToClipboard(e) {
      let ip = e.target.innerHTML.split("<")[0]
      var copyText = document.getElementById("labIpClipboard");
      copyText.value = ip
      copyText.select();
      navigator.clipboard.writeText(copyText.value);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";

button,
input,
optgroup,
select,
textarea, html input[type="button"],
input[type="reset"],
input[type="submit"], button[disabled],
html input[disabled], button::-moz-focus-inner,
input::-moz-focus-inner, input[type="checkbox"],
input[type="radio"], input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button, input[type="search"], input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.team-row {
  font-size: .8rem;
  transition: all .3s;

  h2 {
    font-size: .8rem;
  }

  &:nth-child(odd) {
    background: $neutral--medium;
  }

  &:hover {
    background: rgba($neutral--real-medium, .2);
  }
}

.labs-row {
  h2 {
    font-size: .8rem;
  }
}

.flag-submit {
  background: $neutral--dark;
  color: $neutral--real-medium;
  padding: 5px 10px;

  &:focus {
    border-left: 4px solid $soft-purple;
  }
}

.team-row {
  &.closed {
    .team-labs {
      max-height: 0;
      margin-top: 0 !important;
    }

    .open-icon {
      display: none;
    }

    .collapsed-icon {
      display: block;
    }
  }

  .open-icon {
    display: block;
  }

  .collapsed-icon {
    display: none;
  }
}

.team-labs {
  transition: all .3s;
  max-height: 300px;
  white-space: nowrap;
  overflow: hidden;
}

.submit-flag {
  background: $soft-purple;
  font-size: .5rem;
  padding: 10px;
  border-radius: 50%;
}

.underline-hover {
  opacity: .6;

  &:hover {
    opacity: 1;
    text-decoration: underline;
  }
}

.report-button {
  text-transform: uppercase;
  font-size: .7rem;
}
</style>
