<template>
  <div class="container-fluid px-table" :style="{marginTop:this.$route.params.leadType == 'team' ? 50 + 'px': 0 + 'px'}">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PxTable",
};
</script>
<style lang="scss" scoped>
@import "../assets/css/colors";
@import "../assets/css/variables";

.px-table {
  // margin-top: 50px;
  padding: 20px 0;

  .avatar {
    height: 50px;
    width: 50px;
  }

  a,
  strong {
    color: #5f8e9e;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.8rem;
    letter-spacing: 1px;
    transition: all 0.2s;

    &:hover {
      color: $neutral--soft;
    }
  }

  .row:nth-child(1) {
    .col:before,
    .col-3:before {
      position: absolute;
      text-transform: uppercase;
      color: #768aa3;
    }
  }

  .table__row {
    display: flex;
    align-items: center;
    background: rgba($neutral--dark, 1);

    &:nth-child(odd) {
      background: rgba($neutral--darker, 1);
    }

    font-size: 0.9rem;

    h2 {
      color: white;
      cursor: pointer;
      font-size: 0.75rem;
      font-weight: bold;
      margin-bottom: 0 !important;
      transition: all 0.2s;

      &:hover {
        color: rgba($soft-green, 1);
      }
    }
  }
}
</style>
