import httpService from "./http";

export default {
  create(data) {
    const httpOptions = {
      data,
    };
    return httpService.post("/teams", httpOptions);
  },
  getTeams() {
    return httpService.get("/teams");
  },
  askJoin(team_id) {
    return httpService.post(`/teams/${team_id}/join`);
  },
  confirmJoin(teamId, joinCode) {
    return httpService.post(`/teams/${teamId}/join/${joinCode}`);
  },
  cancelJoin(teamId, joinCode) {
    return httpService.post(`/teams/${teamId}/join/${joinCode}/cancel`);
  },
  getTeam(teamId) {
    return httpService.get(`/v2/teams/${teamId}`);
  },
  update(teamId, data) {
    const httpOptions = {
      data,
    };
    return httpService.put(`/teams/${teamId}`, httpOptions);
  },
  leave(teamId) {
    return httpService.post(`/teams/${teamId}/leave`);
  },
  kick(teamId, userId) {
    return httpService.post(`/teams/${teamId}/kick/${userId}`);
  },
  delete(teamId) {
    return httpService.delete(`/teams/${teamId}`);
  },
  getTeamChartData(teamId) {
    return httpService.get(`/teams/${teamId}/data`);
  },
  getPendingsUsers() {
    return httpService.get("/v2/teams/members/pendings");
  },
  inviteMembers(data) {
    const httpOptions = {
      data,
    };
    return httpService.post("/v2/teams/members", httpOptions);
  },
  checkInviteToken(data) {
    const httpOptions = { data };
    return httpService.post("/v2/teams/members/check", httpOptions);
  },
  acceptJoin(token) {
    const httpOptions = {};
    return httpService.post(
      ["/v2/teams/members/accept", token].join("/"),
      httpOptions
    );
  },
  rejectJoin(token) {
    const httpOptions = {};
    return httpService.post(
      ["/v2/teams/members/reject", token].join("/"),
      httpOptions
    );
  },
};
