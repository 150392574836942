<template>
  <div class="px-select p-2">
    <p class="px-select__selected mb-0 pointer" @click="toggleSelection">
      <slot name="selected"></slot>
    </p>
    <div class="px-select__selection" :class="{active: this.showList}">
      <slot name="selection"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "PxSelect",
  data() {
    return {
      showList: false,
    }
  },
  mounted() {
    window.addEventListener("mousedown", this.closeSelection, false);
  },
  beforeDestroy() {
    window.removeEventListener("mousedown", this.closeSelection);
  },
  methods: {
    closeSelection() {
      this.showList = false;
    },
    toggleSelection() {
      this.showList = !this.showList;
    },
  }
}
</script>

<style scoped lang="scss">
@import "../assets/css/colors";

.px-select {
  background: $neutral--darker;
  width: auto;
  //z-index: 100000;
  &__selection {
    overflow: scroll;
    cursor: pointer;
    z-index: 10;
    margin: 0 5px;
    height: 0;
    transition: margin .1s, height .3s;

    &.active {
      margin: 10px 5px;
      height: 200px;
    }

    p {
      background: $neutral--medium;
      opacity: .6;
      padding: 5px 3px;
      margin: 0;

      span {
        margin: 0 5px;
      }
    }

    & > p:hover {
      opacity: 1;
    }

    p:active {
      background: lighten($neutral--medium, 8) !important;
    }

    & > p:nth-child(even) {
      background: $neutral--smooth;
    }
  }
}
</style>
