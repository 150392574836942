<template>
  <div class="promotion-wrapper">
    <div class="arrows-container">
      <div class="arrow-wrapper">
        <i v-if="pIndex != 0" @click="prevBtn" class="arrow left"></i>
        <i v-if="pIndex != (promotions.length - 1)" @click="nextBtn" class="arrow right"></i>
      </div>
    </div>
    <div class="row m-0 p-0 promotion-modal" :style="{maxHeight: maxHeight, maxWidth: maxWidth, height: descHeight}">
      <div class="col-md-6 m-0 p-0 image-container" :style="{maxHeight: maxHeight, backgroundImage: 'url('+text('image')+')'}">
        <!-- <img id="promo-img" :src="text('image')" class="h-100" alt="" :style="{maxHeight: maxHeight, height: descHeight}"> -->
      </div>
      <div class="col-md-6 m-0 p-0 promotion-description" :style="{maxHeight: maxHeight, height: descHeight() }">
        <div class="description-wrapper" :style="{maxHeight: maxHeight, height: descHeight() }">
          <div class="promo-title ml-4">
            <h2 style="font-size: 30px;" class="card__label">{{text('title')}}</h2>
          </div>
          <div class="promo-description mt-2" :style="{maxHeight: maxHeight, height: descHeight() }">
            <div class="promo-wrapper pl-4" :style="{maxHeight: maxHeight, height: descHeight() }" v-html="getMarkdown(text('description'))">
            </div>
          </div>
          <div class="buttons-container">
            <div class="buttons-wrapper ml-4">
              <px-button @click.native="closeAction" class="px-button--secondary outline mb-2 mx-1">close</px-button>
              <px-button @click.native="redirectUser" class="px-button--success mb-2 mx-1">find out more</px-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PxButton from "@/components/PxButton";
import { marked } from "marked"

export default {
  name: "PromotionModal",
  methods: {
    nextBtn() {
      clearTimeout(this.nextTimeout);
      this.pIndex += 1
      this.nextCountdown = 3;
    },
    prevBtn() {
      clearTimeout(this.nextTimeout);
      this.pIndex -= 1
      this.nextCountdown = 3
    },
    descHeight() {
      return '100%';
    },
    text(field) {
      const idx = this.pIndex;
      const promotion = this.promotions[idx]
      if (!promotion) return
      return promotion[field];
    },
    redirectUser() {
      let link = this.text("link");
      if (typeof link !== "string") return;
      if (!link.startsWith("/")) link = "/" + link;
      this.$router.push(link)
    },
    getMarkdown(description) {
      return marked(description)
    },
  },
  data() {
    return {
      pIndex: 0,
      nextCountdown: 3,
      nextTimeout: null,
    }
  },
  computed: {},
  components: {
    PxButton
  },
  props: {
    promotions: {
      type: Array
    },
    closeAction: {
      type: Function,
      required: true,
    },
    maxHeight: {
      type: String,
      required: false,
      default: '80rem'
    },
    maxWidth: {
      type: String,
      required: false,
      default: '80rem'
    },
  },
  beforeDestroy() {
    clearTimeout(this.nextTimeout)
  },
  watch: {
    nextCountdown: {
      handler(value) {
        this.nextTimeout = setTimeout(() => {
          const index = this.pIndex;
          const promotions = this.promotions

          if (value === 0 && index == (promotions.length - 1)) {
            this.pIndex = 0
            this.nextCountdown = 3
            return;
          } else if (value === 0 && index != (promotions.length - 1)) {
            this.pIndex += 1
            this.nextCountdown = 3
            return;
          }

          let countdown = value;
          countdown = Math.max(countdown - 1, 0)
          this.nextCountdown = countdown
        }, 1000)
      },
      immediate: true
    }
  }
}
</script>
<style scoped lang="scss">
@import "../assets/css/colors.scss";

.promotion-modal {
  position: relative;
  width: 100%;
  height: 30rem;
  padding: 0px 100px;
}

.promotion-description {
  min-height: 100%;
}

.promotion-description, .description-wrapper, .promo-description {
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: justify;
  text-justify: inter-word;
}

.description-wrapper {
  display: flex;
  flex-direction: column;
  text-align: justify;
  text-justify: inter-word;
  height: auto;
}


.promo-description {
  position: relative;
  text-transform: uppercase;
  text-align: justify;
  text-justify: inter-word;
}


.promo-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  top: 0;
  left: 0;
}

/* .buttons-container {
  position: absolute;
  width: 100%;
  bottom: 5%;
} */

.buttons-container {
  position: relative;
}

.buttons-wrapper {
  height: 100%;
}

.arrows-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.arrows-wrapper {
  position: relative;
}

.arrow.right {
  position: absolute;
  right: 3%;
  top: 50%;
  z-index: 10;
  opacity: 0.5;
}

.arrow.left {
  position: absolute;
  left: 3%;
  top: 50%;
  z-index: 10;
  opacity: 0.5;
}

.arrow:hover {
  border: solid black;
  border-width: 0 9px 9px 0;
  transition: all 0.2s ease;
  opacity: 1;
}

.arrow {
  border: solid gray;
  border-width: 0 9px 9px 0;
  display: inline-block;
  padding: 10px;
  transition: all 0.2s ease;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.promotion-wrapper {
  position: relative;
  background-color: $neutral--dark;
  padding-right: 100px;
}

// 640x480
.image-container {
  width: 700px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0%;
  position: relative;
}

.countdown-button{
  transition: all 0.5s ease;
}

@media screen and (max-width: 768px) {
  .buttons-container {
    display: none;
  }
}

</style>
