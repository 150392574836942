import httpService from "./http";

const statsService = {
  labs() {
    return httpService.get('/stats/labs');
  },
  users() {
    return httpService.get('/stats/users');
  },
  events() {
    return httpService.get('/stats/events');
  },
  companies() {
    return httpService.get('/stats/companies');
  },
  teams() {
    return httpService.get("/stats/teams");
  },
  lastSubscriptions() {
    return httpService.get('/stats/last-subs');
  },
  lastFlags() {
    return httpService.get('/stats/last-flags');
  },
  leaderBoard(type) {
    return httpService.get(`/stats/leader/${type}`);
  },
  lastMonthPoints(type, id) {
    return httpService.get(`/stats/leader/${type}/points/${id}`)
  },
  topPlayers() {
    return httpService.get("/stats/top-players");
  },
  top3Players(player_id, days = 30) {
    return httpService.get(`/v2/stats/topplayers/${player_id}/${days}`);
  },
  nextEvents() {
    return httpService.get("/stats/next-events");
  },
  latestUserAction() {
    return httpService.get("/stats/latest")
  },
  generalLeaderBoard() {
    return httpService.get("/v2/stats/leaderboard/general")
  },
  getCountries() {
    return httpService.get("/v2/stats/countries");
  },
  getLeadersByCountry(country) {
    return httpService.get(["/v2/stats", country].join("/"))
  },
  getPromotions() {
    return httpService.get("/v2/stats/promotions");
  },
  deleteUserPromotion() {
    return httpService.delete("/v2/stats/promotion/delete");
  },
  saveInvoiceRequest(data, orderType, orderId) {
    const URL = ["/v2/stats/request-invoice", orderType, orderId].join("/")
    const httpOptions = { data }

    return httpService.post(URL, httpOptions)
  },
  loadUserStatistics(userId = null) {
    if (!userId)
      return httpService.get(["/v2/stats", "user-statistics"].join("/"), {})
    return httpService.get(["/v2/stats", "user-statistics", userId].join("/"), {})
  },
  getCtfTimeData() {
    return httpService.get("/v2/stats/ctftime", {});
  },
  getFacts() {
    return httpService.get("/v2/stats/facts", {});
  },
  getRedirectLink(redirectId) {
    return httpService.get(["/v2/stats/link", redirectId].join("/"));
  },
  getPrefixes() {
    return httpService.get("/v2/stats/prefixes");
  },
};

export default statsService;
