<template>
  <div>
    <div class="row m-0 p-0 col-12 job-title" @click="expandOpportunity(job.id)">
      <div class="col-12 row m-0 p-0 d-flex align-items-center">
        <a target="_blank" :href="job.companyUrl" class="m-0 p-0 avatar avatar--sm" :style="{ backgroundImage: `url(${job.imageUrl})` }" />
        <div class="col-8 m-0 p-0 job-name">
          <h2 class="m-0 p-0 fw-bold orange mx-2">
            {{ job.name }}
          </h2>
        </div>
        <div class="row col-3 m-0 p-0 d-flex align-items-center">
          <div v-if="job.expanded && isUser()" class="col-8 m-0 p-0">
            <px-button v-if="!job.alreadyApplied" @click.native="showApplyModal(job)" class="px-button--success mx-2">apply now</px-button>
            <px-button v-if="job.alreadyApplied" class="px-button--primary disabled outline mx-2">applied</px-button>
          </div>
          <div class="col-8 m-0 p-0" v-if="job.expanded && !isUser()"></div>
          <div :class="[job.expanded ? 'col-4': 'col-12']" class="m-0 p-0 text-right">
            <i class="arrow down"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 m-0 p-0 opportunity-content" :class="{'active': job.expanded}">
      <px-button v-if="isUser() && !job.alreadyApplied" @click.native="showApplyModal(job)" class="px-button--success absolute-button mx-2">apply now</px-button>
      <px-button v-if="isUser() && job.alreadyApplied" class="px-button--primary outline disabled absolute-button mx-2">applied</px-button>
      <div v-html="job.description"></div>
    </div>
  </div>
</template>
<script>
import PxButton from "@/components/PxButton";
import UserType from "@/enums/user";
import { mapState } from "vuex";

export default {
  name: "Job",
  components: {
    PxButton
  },
  props: {
    job: {
      type: Object,
      required: true
    },
    expandOpportunity: {
      type: Function,
      required: true
    },
    showApplyModal: {
      type: Function,
      required: true
    }
  },
  computed: mapState({
    user: state => state.user
  }),
  methods: {
    isUser() {
      const user = this.user
      if (!user) return false;
      return user.role === UserType.USER
    }
  }
}

</script>
<style lang="scss" scoped>

@import "../assets/css/colors.scss";

* {
  text-align: justify;
  text-justify: inner-word;
}

b {
  color: $soft-orange;
  padding: 5px!important;
}

.job-title {
  background: $neutral--medium;
  padding: 10px!important;
  display: flex!important;
  flex-direction: row!important;
  align-items: center!important;
  color: $soft-green;
}

.opportunity-content {
  position: relative;
  width: 100%;
  max-height: 0px !important;
  // overflow: hidden;
  height: 0px !important;
  transform: scaleY(0%) !important;
  transform-origin: top;
  text-align: justify;
  transform-origin: top;
  transition: all 0.2s ease;
}

.opportunity-content.active {
  position: relative;
  width: 100%;
  height: 100%!important;
  max-height: 100%!important;
  text-align: justify;
  transform: scaleY(100%) !important;
  transform-origin: top;
  z-index: 10;
  transform-origin: top;
  transition: all 0.2s ease;
}

.orange {
  color: $soft-orange;
}

.opportunity-content.active {
  ::v-deep strong {
    color: $soft-orange;
  }

  ::v-deep h2 {
    color: $soft-orange;
  }
}

.opportunity-content.active>div {
  // position: absolute;
  padding: 2px 20px!important;
  // background: $neutral--medium!important;
  background: linear-gradient(to bottom, $neutral--medium, rgba(0, 0, 0, 0.5));
  box-shadow: 0px 20px 10px 10px rgba(0, 0, 0, 0.5);
  border-radius: 0px 0px 9px 9px;
}

.arrow {
  border: solid $soft-orange;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-right: 15px;
  cursor: pointer;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.align-right {
  display: flex;
  justify-content: flex-end;
}

.avatar {
  width: 56px;
  height: 56px;

  &:hover {
    border-color: #262936!important;
  }
}

.absolute-button {
  position: absolute!important;
  // z-index: !important;
  z-index: 10!important;
  bottom: 0!important;
  right: 0!important;
  margin-bottom: 80px;
  background: $soft-green!important;
  border-radius: 0%!important;

  &:hover {
    background-color: darken($soft-green, 15)!important;
  }
}

.absolute-button.disabled {
  background: transparent!important;
}

</style>
