<template>
    <base-modal
            :title="modal.title"
            :buttons="modal.buttons"
            :close="modal.close">
        <div class="">
            <p>
                Do you really want to shut down your account?
            </p>
            <form class="" autocomplete="off" @submit.prevent>
                <div class="form-group">
                <label class="" for="deactivation-email">If you are sure please insert your email:</label>
                <input id="deactivation-email" class="form-control" type="text" autocomplete="off"
                       placeholder="Your email" v-model="userEmail">
                </div>
            </form>
        </div>
        <div>
            <p class="important">
                This action is not reversible and will permanently disable your access to the platform.
            </p>
        </div>
    </base-modal>
</template>

<script>
    import BaseModal from "./BaseModal";

    export default {
        name: "DeactivateModal",
        components: {BaseModal},
        props: [
            "returnUserChoice"
        ],
        data() {
            return {
                userEmail: null,
                modal: {
                    title: "Account deactivation",
                    buttons: [
                        {
                            name: "deactivate",
                            class: "px-button--success",
                            action: () => this.selfClose(true, this.userEmail)
                        },
                    ],
                    close: () => this.selfClose(false)
                },
            }
        },
        methods: {
            selfClose(choice, userEmail) {
                this.returnUserChoice(choice, userEmail);
                this.$emit("close");
            }
        }
    }
</script>

<style scoped>
.important {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #dc3545;
    font-weight: 700;
    text-align: justify;
}
</style>
