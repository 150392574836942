<template>
  <div class="px-badge">
    <slot />
  </div>
</template>
<script>
export default {
  name: "PxBadge",
  props: {
    inline: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";

$map_badge_colors: (
  "primary": $soft-blue,
  "success": $soft-green,
  "secondary": #333,
  "normal": $soft-yellow,
  "red": $soft-red,
  "blue": hsl(200, 90%, 50%),
  "purple": $soft-purple,
  "public": $soft-green,
  "event": $soft-orange,
  "hidden": $soft-blue,
  "retired": $soft-red,
  "scheduled": $soft-blue,
  "team": $soft-green,
  "ongoing": $soft-green,
  "overdue": $soft-red,
  "danger": $soft-red,
  "orange": $soft-orange,
  "green": $soft-green,
);
.px-badge {
  min-width: 40%;
  padding: 1px 5px;
  margin: 2px 0;
  font-size: 65%;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: grey;

  &--redblue {
    color: black;
    background: linear-gradient(160deg, $soft-red 50%, $soft-blue 50%);

    &.outline {
      background: linear-gradient(160deg, $soft-red 50%, $soft-blue 50%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      border-color: $soft-red $soft-blue $soft-blue $soft-red !important;
    }
  }

  &.outline {
    border: 1px solid;
    background-color: transparent;
  }

  &.inline {
    display: inline-block;
  }

  @each $name, $color in $map_badge_colors {
    &--#{$name} {
      background-color: $color;
      color: black;

      &.outline {
        border-color: $color;
        color: $color;
      }
    }
  }
}
</style>
