<template>
    <base-modal
        :title="modal.title"
        :buttons="modal.buttons"
        :action="modal.action"
        :close="modal.close"
    ><div class="hide-scroll" :style="{'max-height':400 + 'px', 'word-wrap':'break-word'}" 
        v-html="terms" v-on:scroll="handleScrollToBottom">
    </div>
    </base-modal>
</template>
<script>
import BaseModal from './BaseModal';
import premiumService from '../api/premium';
import { marked } from 'marked';
import { sanitize } from 'dompurify';

export default {
    name: 'EventAddTermsAndConditions',
    props: {
        toggleLoading: {
            type: Function,
            required: true
        },
        termsAccept: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            modal: {
                title: "Terms and conditions",
                buttons: [
                    {
                        name: "Accept",
                        class: "disabled",
                        action: () => {
                            this.termsAccept();
                            this.selfClose();
                        }
                    }
                ],
                action: this.selfClose,
                close: this.selfClose
            },
            terms: null
        }
    },

    created() {
        this.toggleLoading();
        premiumService.getTermsAndConditions().then((response) => {
            this.terms = marked(sanitize(response.data.terms));
        })
            .catch(() => {
                this.modal.buttons = this.modal.buttons.filter(item => item.name.toLowerCase() === "close")
                this.terms = "Terms and conditions unavailable"
            })
            .finally(() => this.toggleLoading())
    },
    components:{
        BaseModal
    },
    methods: {
        selfClose() {
            this.$emit("close")
        },
        handleScrollToBottom(e) {
            const { scrollTop, offsetHeight, scrollHeight } = e.target
            if ((scrollTop + offsetHeight) >= scrollHeight) {
                this.modal.buttons[0].class = 'px-button--success'
            }
        }
    },
}
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
    width:0px;
    background-color:transparent;
}

.hide-scroll {
    overflow: scroll;
    scrollbar-width: none;
    word-wrap: break-word;


    code {
        color:red;
        scrollbar-width: 10px;
    }
    ::-webkit-scrollbar {
        width: 1em;
    }
 
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    
    ::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }
}

.disabled {
  background-color: rgba(0, 0, 0, 0.5) !important;
  color:white !important;
  outline:none !important;
}
</style>