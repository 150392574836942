<template>
  <div class="modal-container p-4">
    <form class="" @submit.prevent>
      <div class="row align-items-center">
        <h2 class="col-8 main-title-1">{{ title }}</h2>
        <div class="col-4 text-right">
          <a @click="close" class="modal-container__close pointer"></a>
        </div>
      </div>
      <div>
        <slot></slot>
      </div>
      <div class="pt-2">
        <px-button type="button" class="mr-4" :class="button.class" v-for="button in buttons" :key="button.name"
          @click.native="button.action">
          {{ button.name }}
        </px-button>
      </div>
    </form>
  </div>
</template>

<script>
import PxButton from "@/components/PxButton";

export default {
  name: "BaseModal",
  props: [
    "title",
    "buttons",
    "close",
    "isEventAdd"
  ],
  components: {
    PxButton,
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";

.modal-container {
  background: $neutral--dark;

  &__close {
    width: 18px;
    height: 19px;
    background: url('../assets/images/btn-close.svg') center no-repeat;
    background-size: contain;
    position: relative;
    display: inline-block;

    &:hover {
      text-decoration: none;
      color: #768aa3;
    }
  }
}
</style>
