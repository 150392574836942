import httpService from "./http";

const companyService = {
  getCurrentCompany() {
    return httpService.get("/company");
  },
  getCompanies() {
    return httpService.get("/company/all");
  },
  getNewMemberRoles() {
    return httpService.get("/company/new-members-role");
  },
  addPwnxUser(data) {
    const httpOptions = {
      data,
    };
    return httpService.post("/company/add-member", httpOptions);
  },
  kickUser(data) {
    const httpOptions = {
      data,
    };
    return httpService.post("/company/kick-user", httpOptions);
  },
  leaveCompany(data) {
    const httpOptions = {
      data,
    };
    return httpService.post("/company/leave", httpOptions);
  },
  addExternalUser(data) {
    const httpOptions = {
      data,
    };
    return httpService.post("/company/add-ext-member", httpOptions);
  },
  addCompanyUserV2(data) {
    const httpOptions = {
      data,
    };
    return httpService.post("/v2/company/add-member", httpOptions);
  },
  update(data) {
    const httpOptions = {
      data,
    };
    return httpService.put("/company", httpOptions);
  },
  getCompany(companyId) {
    return httpService.get(`/company/${companyId}`);
  },
  getCompanyChartData(companyId) {
    return httpService.get(`/company/${companyId}/chart-data`);
  },
  addPwnxUserFromHR(data) {
    // Change to default add user function
    const httpOptions = {
      data,
    };
    return httpService.post("/v2/company/add-member", httpOptions);
  },
  getAvailableTickets(companyId) {
    return httpService.get(["/v2/company", companyId, "tickets"].join("/"));
  },
  getCompanyUserStats(userId) {
    return httpService.get(["/v2/company", "users", userId, "stats"].join("/"));
  },

  getCompanyUserSuggestedLabs(userId, courseId) {
    return this.getCompanyUserSuggestedStats(userId, courseId, "labs");
  },
  getCompanyUserSuggestedChapters(userId, courseId) {
    return this.getCompanyUserSuggestedStats(userId, courseId, "chapters");
  },

  getCompanyUserSuggestedStats(userId, courseId = null, type = null) {
    let url = ["/v2/company", "users", userId, "suggestions"];
    if (type) url.push(type);

    url = url.join("/");
    if (!courseId) return httpService.get(url);
    console.log({ url });
    return httpService.get(url + `?courseId=${courseId}`);
  },
  getCompanyGraphData(graph, userId = null, global = false) {
    let url = ["/v2/company/graphs", graph];

    if (userId !== null) {
      url.push(userId);
    }

    url = url.join("/");

    if (global) {
      url += "?global=true";
    }

    return httpService.get(url);
  },
  getUsers() {
    return httpService.get("/v2/company/users");
  },
  getCompanyRanking(userId = null, global = false) {
    let url = ["/v2/company/users/ranks"];

    if (userId !== null) {
      url.push(userId);
    }

    url = url.join("/");
    if (global) {
      url += "?global=true";
    }

    return httpService.get(url);
  },
  getTopStartedLabs(before = null, userId = null, global = false) {
    let url = ["/v2/company/users/starteds"];

    if (userId !== null) {
      url.push(userId);
    }

    url = url.join("/");
    if (before !== null) {
      url += `?before=${before}`;
    }

    if (global && before !== null) {
      url += "&global=true";
    } else if (global && !before) {
      url += "?global=true";
    }

    return httpService.get(url);
  },
  getMonthlyLabsProgress(what = "all", userId = null, global = false) {
    let url = ["/v2/company/users/monthly", what];

    if (userId !== null) {
      url.push(userId);
    }

    url = url.join("/");

    if (global) {
      url += "?global=true";
    }

    return httpService.get(url);
  },
  async getUserReport(userId) {
    return httpService.get(["/v2/company/users", userId, "report"].join("/"));
  },
  async generateUserReport(data) {
    const httpOptions = {
      data,
    };
    return httpService.post("/v2/company/users/report", httpOptions);
  },
  getUserLabChart(labId, userId = null) {
    const url = ["/v2/company/users"];

    if (userId) url.push(userId);

    url.push(`labs/${labId}/chart`);

    return httpService.get(url.join("/"));
  },
  getUserComplianceQuiz() {
    return httpService.get("/v2/company/compliance");
  },
  saveUserComplianceResponse(data) {
    const httpOptions = {
      data,
    };
    return httpService.post("/v2/company/compliance", httpOptions);
  },
  getUserTechnicalApplicability() {
    return httpService.get("/v2/company/technical/applicable");
  },
  getUserComplianceApplicability() {
    return httpService.get("/v2/company/compliance/applicable");
  },
  getCourseLabs(courseId) {
    return httpService.get(["/v2/company/courses", courseId, "labs"].join("/"));
  },
  getUserResponses(userId = "all") {
    return httpService.get(["/v2/company/compliance", userId].join("/"));
  },
  getResponsesCategories(userId, timestamp) {
    return httpService.get(
      ["/v2/company/compliance", userId, timestamp, "categories"].join("/")
    );
  },
  getUserResponseChart(userId, timestamp) {
    return httpService.get(
      ["/v2/company/compliance", userId, timestamp, "chart"].join("/")
    );
  },
  getUserCourseCompletion(courseId) {
    if (!courseId) {
      return httpService.get("/v2/company/users/courses/completion");
    }
    return httpService.get(`/v2/company/users/courses/completion/${courseId}`);
  },
  getCompanyLabStatsById(labId) {
    return httpService.get(["/v2/company/labs", labId, "stats"].join("/"));
  },
  getCompanyCoursesChart() {
    return httpService.get(["/v2/company/courses/chart"].join("/"));
  },
  startTechnicalEvent() {
    return httpService.post("/v2/company/technical/start");
  },
  getCorporateEvents() {
    return httpService.get("/v2/company/technical/events");
  },
  async updateCompanyConfig(data) {
    const httpOptions = { data };
    return httpService.put("/v2/company/config", httpOptions);
  },
};

export default companyService;
