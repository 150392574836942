import httpService from "./http";

const paymentService = {
    getPrices() {
        return httpService.get("/payments/prices");
    },
    getCoupon() {
        return httpService.get("/payments/coupon");
    },
    create(data) {
        const httpOptions = {
            data
        };
        return httpService.post("/payments/create", httpOptions);
    },
    approve(orderId) {
        return httpService.post(`/payments/approve/${orderId}`);
    },
    cancel(orderId) {
        return httpService.post(`/payments/cancel/${orderId}`);
    }
};

export default paymentService;
