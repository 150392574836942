<template>
  <div class="my-2">
    <VueEditor v-model="text" />
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";

export default {
  name: "PxTextArea",
  data() {return {text:""}},
  components: {VueEditor},
  props:{
    initialFunction: {
      type: Function,
      default:() => ""
    },
    saveText:{
      type: Function
    },
  },
  created() {
    this.handleInitialFunction();
  },
  methods: {
    async handleInitialFunction() {
      this.text = await this.initialFunction();
    }
  },
  watch:{
    'text':function(new_) {
      this.saveText(new_)
    }
  }
}
</script>
<style lang="scss" scoped>
</style>