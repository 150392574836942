import httpService from "./http";

export default {
  getLab(labId) {
    return httpService.get(`/labs/${labId}`);
  },
  getLabWriteup(labId) {
    return httpService.get(`/v2/labs/${labId}/writeup`);
  },
  submitLabFlag(labId, flag, flagType) {
    let httpOptions = {
      data: {
        flag: flag,
      },
    };
    return httpService.post(`/labs/${labId}/flag/${flagType}`, httpOptions);
    // ${flag}
  },
  submitFlag(eventId, labId, flag) {
    return httpService.post(`/v2/events/submit/${eventId}/${labId}/${flag}`);
  },
  submitFlagPurple(eventId, flag) {
    let httpOptions = {
      data: {
        event_id: eventId,
        flag: flag,
      },
    };
    return httpService.post(`/v2/events/submit`, httpOptions);
  },
  cancelReset(resetId) {
    return httpService.post(`/labs/reset/${resetId}/cancel`);
  },
  getLabLastFlags(labId) {
    return httpService.get(`/labs/${labId}/last-flags`);
  },
  // v2
  getLabs(page) {
    return httpService.get(`/v2/labs?page=${page}`);
  },
  resetLab(labId) {
    return httpService.post(`/v2/labs/${labId}/reset`);
  },
  upcomingLab() {
    return httpService.get("/v2/labs/upcoming");
  },
  firstBlood() {
    return httpService.get("/v2/labs/firstblood");
  },
  upcomingLabStats() {
    return httpService.get("/v2/labs/upcoming/stats");
  },
  getBadges(page) {
    return httpService.get(
      page ? `/v2/labs/badges?page=${page}` : `/v2/labs/badges`
    );
  },
  getOneShots() {
    return httpService.get(`/v2/labs/oneshot`);
  },
  getCompanyLabs(page) {
    return httpService.get(`/v2/labs/company?page=${page}`);
  },
  getStartedLabs(labType, labId = null) {
    if (!labId) {
      return httpService.get(["/v2/labs/started", labType].join("/"));
    }
    return httpService.get(["/v2/labs/started", labType, labId].join("/"));
  },
  labAction(labId, action, data, tokenBased = true, userId = null) {
    if (!tokenBased)
      return httpService.post(["/v2/labs", labId, action, userId].join("/"), {
        data,
      });
    return httpService.post(["/v2/labs", labId, action].join("/"), { data });
  },
  submitFlagV2(data) {
    const httpOptions = {
      data,
    };
    return httpService.post("/v2/labs/submit", httpOptions);
  },
  downloadVPN(labId, tokenBased = true, userId = null) {
    if (!tokenBased)
      return httpService.get(["/v2/labs", labId, "vpn", userId].join("/"));
    return httpService.get(`/v2/labs/${labId}/vpn`);
  },
  getRetiredLabs(page) {
    // Adding -labs in order to point to the default endpoint for
    // retrieving "premium" labs
    // TODO: Change to /v2/labs/premium/retired when removing old premium
    return httpService.get(`/v2/labs/premium/retired-labs?page=${page}`);
  },
  extendLab(labId, tokenBased = true, userId = null, hours = 1) {
    const httpOptions = { data: hours };
    if (!tokenBased)
      return httpService.post(
        ["/v2/labs", labId, "extend", userId].join("/"),
        httpOptions
      );
    return httpService.post(`/v2/labs/${labId}/extend`, httpOptions);
  },
  getPaidLabs(labType, tokenBased = true, userId = null, sortBy = "name") {
    let path = ["/v2/labs/paid", labType].join("/");
    if (!tokenBased) path = ["/v2/labs/paid", labType, userId].join("/");
    return httpService.get(`${path}?order-by=${sortBy}`);
  },
  hasAllowance(labId) {
    return httpService.get(["/v2/labs", labId, "writeup/allowance"].join("/"));
  },
  acceptAllowance(labId) {
    return httpService.post(["/v2/labs", labId, "writeup"].join("/"));
  },
  labLiked(labId) {
    return httpService.post(["/v2/labs", labId, "like"].join("/"), {});
  },
  getSuggestions() {
    return httpService.get(["/v2/labs", "user-suggestions"].join("/"));
  },
  getLikedLabs(userId) {
    if (!userId) return httpService.get(["/v2/labs", "liked"].join("/"));
    return httpService.get(["/v2/labs", "liked", userId].join("/"));
  },
  getStartedLabIps(labType, labId = null, mode = null) {
    let url = `/v2/labs/started/${labType}/ip`;

    if (labId) {
      url += `/${labId}`;
    }

    if (mode) {
      mode += `?mode=${mode}`;
    }
    return httpService.get(url);
  },
  downloadLabTemplate(labId) {
    const httpOptions = {
      responseType: "blob",
    };
    return httpService.get(
      ["/v2/labs", labId, "download"].join("/"),
      httpOptions
    );
  },
};
