<template>
  <div class="container-fluid pt-5 pt-md-0 mt-5 mt-md-0 m-0 p-0">
    <div class="row m-0 p-0">
      <div class="m-0 p-0" :class="[inEvent ? 'col-2' : 'col-12']">
        <p class="text-uppercase ml-2 main-title-1">event statistics</p>
      </div>
      <div class="col-10 m-0 p-0 position-relative d-flex justify-content-start align-items-center h-100"
        v-if="inEvent">
        <div class="spinner"></div>
      </div>
    </div>
    <div class="row m-0 p-0">
      <div class="col-md-6 row mb-0 m-0 p-0 mb-md-2">
        <form class="col-12">
          <label for="range-selector" class="card__label">range selector</label>
          <select v-model="rangeMinuteSelected" name="range-selector" id="range-selector" class="form-control m-0 p-0">
            <option v-for="(minute, idx) of minutes" :key="idx" :value="minute">{{ minute }} minutes</option>
          </select>
        </form>
      </div>
      <div class="col-md-6 justify-content-end back-button row mb-0 mb-md-2 m-0 p-0">
        <form class="col-10">
          <label for="date-selector" class="card__label">date selector</label>
          <select class="form-control m-0 p-0" name="date-selector" id="date-selector" v-model="dateSelected">
            <option class="m-0 p-0" v-for="(date, idx) of getDates()" :key="idx">{{ date }}</option>
          </select>
        </form>
        <div class="col-2 text-right d-flex justify-content-center align-items-center">
          <px-button @click.native="goBack()" class="px-button--secondary outline icon-left" icon="left-arrow">
            back
          </px-button>
        </div>
      </div>
    </div>
    <div class="row m-0 p-0 mt-0 mt-md-0">
      <div class="col-md-6 mt-0 mt-md-0 m-0 p-0">
        <div class="categories row p-0 m-0 ml-1">
          <div
            class="col-12 position-relative row shadow-lg category bg-dark pt-1 mb-1 px-2 m-0 justify-content-center align-items-center"
            v-for="(percentage, key) in percentages" :key="key">
            <div class="col-6 d-flex m-0 p-0 justify-content-start align-items-center">
              <p class="category-text m-0 p-0 text-uppercase fw-bold py-1">{{ key }}</p>
            </div>
            <div class="category-solved row m-0 p-0 col-5 justify-content-center align-items-center">
              <div class="category-solved-bar col-12 m-0 p-0 w-100 rounded"
                :style="`--value: ${getPercentage(percentage.correct, percentage.total)}%`"></div>
            </div>
            <div class="category-numbers row m-0 p-0 col-1 justify-content-end align-items-center">
              {{ percentage.correct }} / {{ percentage.total }}
            </div>
          </div>
          <div v-if="percentagesEmpty()" class="m-0 p-0 no-data">
            <h1 class="card__label">no data collected yet</h1>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-1 m-0 p-0 shadow-lg" v-for="(graph, idx) of graphs" :key="idx">
        <div class="position-relative graph-container bg-dark mt-md-0 ml-1 my-md-0 mb-1 m-0">
          <div class="graph-container position-relative">
            <div class="w-100 h-100 skill-labels-wrapper-top position-absolute m-0 p-0" v-if="idx == 2">
              <div class="position-relative row m-0 p-0">
                <div class="skill-labels label-left label-purple col-6 m-0 p-0">expert</div>
                <div class="skill-labels label-right label-green col-6 m-0 p-0">professional</div>
              </div>
            </div>
            <div class="w-100 skill-labels-wrapper-bottom position-absolute m-0 p-0" v-if="idx == 2">
              <div class="position-relative row m-0 p-0">
                <div class="skill-labels label-left label-warning col-6 m-0 p-0">wannabe</div>
                <div class="skill-labels label-right label-red col-6 m-0 p-0">script kiddie</div>
              </div>
            </div>
            <v-chart id="charts" class="w-100 m-0 p-0 graph-chart py-1" :options="graph"></v-chart>
          </div>
          <div v-if="rangesEmpty()" class="no-data w-100 h-100">
            <h1 class="card__label">no data collected yet</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import eventService from "@/api/event";
import httpService from "@/api/http";
import ECharts from "vue-echarts";
import PxButton from "@/components/PxButton";

export default {
  name: "EventQuizStatistics",
  components: {
    "v-chart": ECharts,
    PxButton
  },
  computed: {
    minutes() {
      return Array(20).fill(null).map((_, index) => index).filter(item => item > 1 && item % 2 == 0)
    },
    palette() {
      return ["#99B080", "#F9B572"]
    },
  },
  created() {
    this.loadAllData();
    this.interval = setInterval(this.loadAllData, 15000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  data() {
    return {
      inEvent: false,
      percentages: [],
      datetimeMap: {},
      interval: null,
      rangeMinuteSelected: 2,
      dates: [],
      dateSelected: null,
      ticketSubmitted: null,
      ranges: [],
      originalRanges: [],
      eventId: this.$route.params.eventId,
      graphs: [
        {
          prepare: function (data, { palette = null }) {
            if (palette) {
              this.color = palette;
            }

            const [correct] = this.series.filter(serie => serie.name === "correct");
            const [wrong] = this.series.filter(serie => serie.name === "wrong");
            this.series = [];

            const [xAxis] = this.xAxis;
            this.xAxis = [];

            const options = {
              weekday: "short",
              month: "short",
              hour: "numeric",
              minute: "numeric"
            }

            for (const [key, value] of Object.entries(data)) {
              const [t1, t2] = key.split(" - ");

              const dateto = new Date(t1 * 1000).toLocaleString("en-US", options);
              const datefrom = new Date(t2 * 1000).toLocaleString("en-US", options);
              xAxis.data.push([datefrom, dateto].join(" -> "))

              correct.data.push(value.correct)
              wrong.data.push(value.wrong)

            }


            this.series = [correct, wrong]
            this.xAxis.push(xAxis)

          },
          reset: function () {
            const xAxis = this.xAxis;
            const series = this.series;


            for (const element of xAxis) {
              element.data = [];
            }

            for (const element of series) {
              element.data = []
            }
          },
          color: [],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            data: ["correct", "wrong"],
            textStyle: { color: "white" }
          },
          toolbox: {
            show: false,
          },
          xAxis: [
            {
              type: 'category',
              axisTick: { show: false },
              data: [],
              axisLabel: {
                textStyle: {
                  color: "white",
                  fontSize: 9
                }
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              axisLabel: {
                textStyle: {
                  color: "white"
                }
              }
            }
          ],
          series: [
            {
              name: 'correct',
              type: 'bar',
              barGap: 0,
              data: [],
            },
            {
              name: 'wrong',
              type: 'bar',
              // data: [20, 12, 51, 24, 90]
              // data: Array(5).fill(null).map(() => Math.floor(Math.random()*100))
              data: [],
            },
          ]
        },
        {
          prepare: function (data, { palette = null }) {
            if (palette) {
              this.color = palette;
            }

            const legend = this.legend;
            const series = this.series;

            let wrong = 0;
            let correct = 0;

            for (const value of Object.values(data)) {
              wrong += value.wrong;
              correct += value.correct;
            }

            series[0].data.push({ value: correct, name: "correct", itemStyle: { color: palette[0] } })
            series[0].data.push({ value: wrong, name: "wrong", itemStyle: { color: palette[1] } })
            series[0].data.push({ value: correct + wrong, itemStyle: { color: "transparent" } })

            const total = correct + wrong;
            legend.formatter = function (name) {
              const [object] = series[0].data.filter(item => item.name === name);
              const percentual = Number.parseFloat((object.value / total * 100).toFixed(2));
              return [name, String(percentual) + "%"].join(" - ")
            }

          },
          reset: function () {
            this.series[0].data = []
          },
          tooltip: {
            show: false,
            trigger: 'item',
          },
          legend: {
            orient: 'vertical',
            left: 10,
            data: ["correct", "wrong"],
            textStyle: { color: "white" }
          },
          series: [
            {
              name: 'solve-percentage',
              type: 'pie',
              radius: ['50%', '70%'],
              center: ["50%", "70%"],
              avoidLabelOverlap: false,
              label: {
                show: true,
                formatter(param) {
                  // correct the percentage
                  return param.name + " (" + param.percent * 2 + "%)";
                }
                // position: 'center',
                // color: "white",
                // left: "top",
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '15',
                  fontWeight: 'bold',
                }
              },
              startAngle: 180,
              labelLine: {
                show: true,
                color: "white"
              },
              data: []
              /* data: [
                { value: 235, name: 'correct' },
                { value: 310, name: 'wrong' },
                { value: 545, name: "transparent", itemStyle: {color: "transparent"}},
              ] */
            }
          ]
        },
        {
          prepare: function (data, { vctx = null }) {

            const PENALITY = 1000;
            const PRIZE = 1000;

            const series = this.series;
            const ticketSubmitted = vctx.ticketSubmitted;

            const submittedTs = new Date(+ticketSubmitted * 1000);

            let correct = 0;
            let wrong = 0;

            const timestamps = Object.keys(data).map((key) => {
              const keys = key.split(" - ")
              return keys.map(k => Number.parseInt(k))
            }).flat().sort();

            for (const value of Object.values(data)) {
              correct += value.correct;
              wrong += value.wrong;
            }

            const minutes = correct + wrong;
            const endDateTs = new Date(submittedTs.getTime() + minutes * 60000);
            const diffMs = endDateTs - submittedTs;
            const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000) * PRIZE;


            // let xValue = (timestamps[timestamps.length - 1] / 10) - (ticketSubmitted / 10);
            let xValue = (timestamps[0] / 10) - (ticketSubmitted / 10) - (PENALITY * wrong);
            let yValue = correct - wrong - (PENALITY * wrong);
            yValue += Math.abs(diffMins);

            // 1. timing difference 
            // 2. correct / wrong responses

            xValue = Number.parseInt(xValue);
            const boundaries = Math.max(xValue, yValue);

            series[0].data.push({ name: "points", value: [xValue, yValue], itemStyle: { color: "#99B080", } })
            if (!series[0].data.some(item => item.name === "hidden")) {
              series[0].data.push({ name: "hidden", value: [boundaries, boundaries], itemStyle: { color: "transparent" } })
              series[0].data.push({ name: "hidden", value: [-boundaries, -boundaries], itemStyle: { color: "transparent" } })
              series[0].data.push({ name: "hidden", value: [boundaries, -boundaries], itemStyle: { color: "transparent" } })
              series[0].data.push({ name: "hidden", value: [-boundaries, boundaries], itemStyle: { color: "transparent" } })
            }


          },
          reset: function () {
            const [series] = this.series;
            series.data = series.data.filter(serie => serie.name !== "points");
            this.series = [series];
          },
          id: "grid-chart",
          label: {
            data: ["abc", "def"],
          },
          xAxis: {
            axisLabel: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#fff"
              }
            },
            splitLine: {
              color: "#313131",
              lineStyle: {
                color: "#313131"
              }
            },
            grid: true,
          },
          yAxis: {
            axisLabel: {
              show: false
            },
            splitLine: {
              color: "#313131",
              lineStyle: {
                color: "#313131"
              }
            },
            axisLine: {
              lineStyle: {
                color: "#fff"
              }
            },
            grid: true,
          },
          series: [
            {
              symbolSize: 15,
              data: [],
              grid: true,
              type: "scatter",
            },
          ],
          autoDetection: 0,
          manualDetection: 0,
          linesGraphData: {},
          accelerometerGraphData: {},
          userId: this.$route.query.userId,
        },
      ]
    }
  },
  methods: {
    getDates() {
      return Object.keys(this.datetimeMap);
    },
    rangesEmpty() {
      return Object.keys(this.ranges).length === 0;
    },
    percentagesEmpty() {
      return Object.keys(this.percentages).length === 0;
    },
    async loadAllData() {
      const eventId = this.eventId;
      const userId = this.$route.query.userId;

      const defaultRange = this.rangeMinuteSelected;
      const requests = [
        eventService.getEventUserSolvedPercentages(eventId, userId),
        eventService.getEventUserRanges(eventId, userId, defaultRange),
      ];

      return httpService.all(requests)
        .then(([percentages, ranges]) => {
          this.percentages = percentages.data.percentages;
          this.originalRanges = ranges.data.ranges;
          // this.ranges = ranges.data.ranges;
          this.datetimeMap = {};
          const dates = Object.keys(ranges.data.ranges).map(item => {
            const dates = item.split(" - ").map(date => {
              const timestamp = new Date(+date * 1000).getTime();
              return timestamp;
            });

            dates.reverse();

            const toLocaleString = dates.map(item => new Date(item).toLocaleString())
            const toString = element => element.join(" - ");
            this.datetimeMap[toString(toLocaleString)] = toString(dates);

            return dates.join(" - ");
          });
          if (!this.dateSelected) {
            const dt = dates[dates.length - 1];
            if (dt) {
              let [d0, d1] = dt.split(" - ");
              d0 = new Date(+d0).toLocaleString();
              d1 = new Date(+d1).toLocaleString();
              this.dateSelected = [d0, d1].join(" - ");
            }
          }
          this.ticketSubmitted = ranges.data.submitted;
          this.dates = dates;
          this.inEvent = ranges.data.participating;
        })
        .finally(() => {
          this.ranges = this.filterByDateSelected(this.datetimeMap[this.dateSelected]);
          this.reloadGraphs();
        });
    },
    reloadGraphs() {
      const graphs = this.graphs;
      const palette = this.palette;
      const ranges = this.ranges;
      const vctx = this;
      // const length = ranges.length -1;
      for (const graph of graphs) {
        graph.reset();
        graph.prepare(ranges, { palette, vctx });
      }
    },
    sliceObject(object, split = 3) {
      let keys = Object.keys(object);
      let length = keys.length;

      if (length > 3) {
        keys = keys.slice(length - split, length);
        length = keys.length;
      }

      const obj = {}

      for (const [key, value] of Object.entries(object)) {
        const valid = keys.some(k => k === key);
        if (!valid) continue;
        obj[key] = value
      }

      return obj
    },
    getRandom() {
      return Math.floor(Math.random() * 101);
    },
    getPercentage(correct, total) {
      return Number.parseInt(100 / total * correct)
    },
    filterByDateSelected(value) {
      if (!value) return this.sliceObject(this.originalRanges);
      const ranges = {}
      // const key = Object.keys(this.originalRanges).filter(range => {
      //   const reversed = range.split(" - ").map(item => +item * 1000).reverse().join(" - ")
      //   return reversed === value
      // });
      for (const [key, range] of Object.entries(this.originalRanges)) {
        const fromdate = value.split(" - ").map(item => +item / 1000)
        const found = fromdate.some(item => key.includes(item));
        if (found) {
          ranges[key] = range;
          break;
        }
        ranges[key] = range;
        // if (value === key)
      }

      return this.sliceObject(ranges);
    }
  },
  watch: {
    rangeMinuteSelected: {
      handler(value) {
        if (!value || typeof value !== "number") return;
        this.loadAllData();
      },
      immediate: true,
    },
    dateSelected: {
      handler(value) {
        const timestamps = this.datetimeMap[value];
        if (!timestamps) return;
        this.ranges = this.filterByDateSelected(timestamps);
        this.reloadGraphs();
      },
      immediate: true
    },
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/css/colors.scss";

.graph-chart {
  min-width: 100% !important;
}

.category-solved-bar {
  background: $neutral--darker !important;
  height: 10px;
}

.category-text {
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  font-family: "Ubuntu", sans-serif;
  font-size: 11px;
}

.category-solved-bar {
  display: block;
  background: rgba($color: $neutral--medium, $alpha: 0.75);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  width: 100%;
  height: 10px;
  position: relative;
  font-size: 0;

  &:after {
    content: "";
    clip-path: inset(0px calc(100% - var(--value)) 0px 0px round 15px);
    border-radius: 10px 10px 10px 10px;
    -webkit-border-radius: 10px 10px 10px 10px;
    -moz-border-radius: 10px 10px 10px 10px;
    width: 100%;
    left: 0;
    position: absolute;
    height: 10px;
    background: #e5405e;
    background: linear-gradient(to right,
        #3fffa2 0%,
        #ffdb3a 35%,
        #ff993a 60%,
        #e5405e 100%);
  }
}

.no-data {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  background-color: $neutral--darkest;
  display: flex;
  margin: 0 !important;
  padding: 0 !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // transform: scale(1);
}

.spinner {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-left: 3px solid $soft-green;
  animation: rotate-spinner 0.5s linear infinite forwards;
}

.skill-labels-wrapper-top {
  top: 0;
}

.skill-labels-wrapper-bottom {
  bottom: 10%;
}

.skill-labels {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.label-left {
  top: 0;
  left: 0;
  height: 40px;
}

.label-right {
  top: 0;
  right: 0;
  height: 40px;
}

.label-purple {
  color: $soft-purple;
  text-transform: uppercase;
  border: 1px solid $soft-purple;
}

.label-green {
  color: $soft-green;
  text-transform: uppercase;
  border: 1px solid $soft-green;
}

.label-warning {
  color: $soft-orange;
  text-transform: uppercase;
  border: 1px solid $soft-orange;
}

.label-red {
  color: $soft-red;
  text-transform: uppercase;
  border: 1px solid $soft-red;
}

@keyframes rotate-spinner {
  to {
    transform: rotate(360deg);
  }
}
</style>
