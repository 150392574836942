<template>
    <base-modal
    :title="modal.title"
    :buttons="modal.buttons"
    :close="modal.close">
    <div class="my-3">
        The lab {{ lab.name }} will stop in {{ minutes }} minutes.
        Do you want to extend it?
        <form class="form-group" action="" @submit.prevent v-if="tokenBased">
            <div id="input-container" class="my-3">
                <label class="card__label" for="number"> Hours to extend ( 1 hour by default )</label>
                <input id="number" type="number" class="form-control" placeholder="Hours To Extend" v-model="hoursToExtend">
            </div>
        </form>
    </div>
    </base-modal>
</template>
<script>
import BaseModal from './BaseModal';
import labService from '../api/lab';
import { mapState } from 'vuex';

export default {
    name: "LabExtendModal",
    components: {
        BaseModal
    },
    computed: {
        ...mapState({
            user: state => state.user
        })
    },
    // props: ['lab', 'minutes'],
    props: {
        lab: {
            type: Object,
            required: true
        },
        minutes: {
            type: Number,
            required: true
        },
        tokenBased: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            hoursToExtend: 1,
            modal: {
                title: `${this.lab.name} imminent stop`,
                buttons: [
                    {
                        name: "Extend",
                        class: "px-button--success",
                        action: () => this.extendLab()
                    },
                    {
                        name: "Close ( The lab will stop )",
                        class: "px-button--danger",
                        action: () => this.selfClose()
                    }
                ],
                close: () => this.selfClose()
            }
        }
    },
    methods: {
        selfClose() {this.$emit('close')},
        extendLab() {
            this.$parent.loading = true;
            labService.extendLab(this.lab.id, this.tokenBased, this.user.id, {hours:this.hoursToExtend}) // 1 hour by default
                .then((response) => {
                    const manageableMessage = !!response.data && !!response.data.message;
                    if (manageableMessage) {
                        this.flashSuccess(response.data.message);
                        window.location.reload();
                    }
                })
                .catch((error) => {
                    const manageableError = !!error.data && !!error.data.errorDescription;
                    if (manageableError) this.flashError(error.data.errorDescription);
                    this.selfClose()
                })
                .finally(() => {this.$parent.loading = false})
        }
    }
}
</script>