<template>
  <base-modal :close="modal.close">
    <div class="full-page-modal container-fluid d-flex flex-column justify-content-between">
      <div class="row flex-column">
        <div class="col-3 mb-3">
          <img src="../assets/images/logo-pwnx-darkmode.svg" alt="">
        </div>
        <div class="col-12">
          <h2>Share PWNX with your friends and gain referral points!</h2>
        </div>
      </div>
      <div class="row mt-4 flex-column">
        <div class="col">
          <h2 class="mb-0 px-input__name mt-2">E-Mail</h2>

          <input type="text" class="px-input" v-model="referralEmail" placeholder="The e-mail address of the person you want to invite"><br/>
          <a href="#" class="px-link" @click="copyReferral()">copy your referral link <i class="fa fa-paste"></i></a><br/>
          <px-button class="px-button--success my-1 mt-3"
                     textInput="Submit"
                     :class="{'px-button--loading':isLoading}"
                     @click.native="submitReferral()"
                     ref="btnRegister"></px-button>

        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from "./BaseModal";
import PxButton from "@/components/PxButton";
import userService from "../api/user";

export default {
  name: "referralModal",
  components: {
    BaseModal,
    PxButton
  },
  props: {
    user: Object
  },
  data() {
    return {
      isLoading: false,
      referralEmail: "",
      modal: {
        close: () => this.selfClose()
      },
    }
  },
  methods: {
    copyReferral() {
      const url = `${process.env.VUE_APP_appUrl}/#/register/referral/${this.user.id}`
      navigator.clipboard.writeText(url).then(() => {
        this.flashSuccess("Referral copied to clipboard");
      }).catch(() => {
        this.flashError("something went wrong copying the ip")
      });
    },
    submitReferral() {
      this.isLoading = true;
      userService.send_referral(this.referralEmail).then(response => {
        this.flashSuccess(response.data.message);
      }).catch(error => {
        const manageableError = !!error && !!error.data && !!error.data.errorDescription;
        if (manageableError) {
          this.flashError(error.data.errorDescription);
        }
      }).finally(() => {
        this.isLoading = false;
      })
    },
    selfClose() {
      this.$emit("close");
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/css/colors.scss";

#submit-btn {
  font-weight: bold;
    background: #72fd85;
    color: #191e23;
    text-transform: uppercase;
    width: 40%;
    font-size: 0.7rem;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
    outline: 1px solid #72fd85;

  &:hover {
    color: $soft-green;
    background: transparent;
  }
}

.px-input {
  background: $neutral--darker;
  font-size: .8rem;
  padding: 10px 20px;
  margin: 5px 0;
  width: 25rem;
  color: rgba($neutral--light, 1);

  &::placeholder {
    color: rgba($neutral--light, .5);
  }

  &:focus {
    border-left: 1px solid $soft-green;
  }
}
input {
  all: unset;
}
.px-input {
  &__name {
    color: $neutral--soft;
    font-size: .8rem;
  }

  &__password {

  }


}
.px-link {
  color: white;
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: bold;
  transition: all 0.2s;
  text-transform: uppercase;

  &:hover {
    color: #72fd85;
  }
}
</style>
