<template>
  <base-modal :title="modal.title" :buttons="modal.buttons" :close="modal.close">
    <form @submit="sendUserData">
      <div class="row m-0 p-0 mt-2">
        <div class="col-md-6 form-group">
          <label for="firstName" class="card__label"><span class="text-danger">*</span>First Name</label>
          <input class="form-control" placeholder="Type your first name here" type="text" name="firstName" id="firstName"
            v-model="userData.firstName">
        </div>
        <div class="col-md-6 form-group">
          <label for="lastName" class="card__label"><span class="text-danger">*</span>Last Name</label>
          <input class="form-control" placeholder="Type your last name here" type="text" name="lastName" id="lastName"
            v-model="userData.lastName">
        </div>
        <div class="col-md-6 form-group">
          <label for="email" class="card__label"><span class="text-danger">*</span>Email</label>
          <input class="form-control" placeholder="Type your email here" type="email" name="email" id="email"
            v-model="userData.email">
        </div>
        <div class="col-md-6 form-group">
          <div class="labels col-12 row">
            <div class="col-2 ml-0 pl-0">
              <label for="phone_number" class="card__label">Prefix</label>
            </div>
            <div class="col-10 ml-0 pl-1">
              <label for="phone_number" class="card__label"><span class="text-danger">*</span>Phone Number</label>
            </div>
          </div>
          <div class="row m-0 p-0">
            <div class="col-2 ml-0 pl-0 py-0 my-0">
              <select name="country-select" id="country-select" v-model="selectedPrefix" class="form-control d-flex justify-content-between align-items-center">
                <!-- eslint-disable-next-line vue/no-unused-vars -->
                <option :key="country" v-for="(prefix, country) in prefixes" :value="prefix" class="text-center"> 
                  <p class="m-0 p-0 w-100">{{ country }}</p>
                </option>
              </select>
            </div>
            <div class="col-10 m-0 p-0">
              <input class="form-control" placeholder="Type your Phone Number here" type="phone_number" name="phone_number"
                id="phone_number" v-model="userData.phoneNumber" maxlength="15" pattern="[0-9]">
            </div>
          </div>
        </div>
        <div class="col-md-12 form-group">
          <label for="cv-upload" class="card__label">Upload your CV</label>
          <input type="file" id="cv-upload" @change="cvFileSelected" ref="cvFile">
          <div class="bg-medium d-flex justify-content-center align-items-center m-0 p-0 fileup-container"
            @click="uploadFile">
            <h2 class="fileup-container fw-bold m-0 p-2" v-if="!userData.cvFile">Upload your CV</h2>
            <h2 class="fileup-container fw-bold m-0 p-2" v-if="userData.cvFile">Selected file: {{ userData.cvFile.name }}
            </h2>
          </div>
        </div>
        <div class="form-group" :class="{ 'col-12': !requireTeam, 'col-6': requireTeam }">
          <label for="datepicker" class="card__label"><span class="text-danger">*</span>Birth Date</label>
          <date-picker id="datepicker" class="pwnx-date-picker" v-model="userData.birthDate" :max-date="new Date()"
            color="green" is-dark is-inline is-expanded></date-picker>
        </div>
        <div v-if="requireTeam" class="col-6">
          <div class="form-group">
            <label for="team-name" class="card__label"><span class="text-danger">*</span>Team Name</label>
            <input type="text" placeholder="Team Name" class="form-control" id="team-name" v-model="userData.team.name">
          </div>
          <div class="form-group">
            <label for="team-url" class="card__label">Team Url</label>
            <input type="text" placeholder="Team Url" class="form-control" id="team-url" v-model="userData.team.url">
          </div>
        </div>
        <div class="col-md-8 form-group row d-flex flex-row justify-content-start align-items-center"
          v-if="shouldAskParticipatingLoc()">
          <label for="participating-mode" class="card__label">Participation Method</label>
          <div class="ml-5 row m-0">
            <div class="col-md-6 participating-toggle m-0 p-0 p-2 px-3"
              :class="{ 'active': userData.joinMethod === 'remote' }" @click="toggleJoinMethod">
              Remote
            </div>
            <div class="col-md-6 participating-toggle m-0 p-0 p-2 px-3"
              :class="{ 'active': userData.joinMethod === 'onsite' }" @click="toggleJoinMethod">
              Onsite
            </div>
          </div>
        </div>
        <div class="col-md-4" v-if="isPrivate">
          <label for="ticket" class="card__label"><span class="text-danger">*</span>Ticket</label>
          <div class="d-flex flex-row justify-content-start align-items-center">
            <input id="ticket" type="text" class="form-control" placeholder="Event invitation ticket"
              v-model="userData.token">
          </div>
        </div>
      </div>
    </form>
  </base-modal>
</template>
<script>
import BaseModal from "@/components/BaseModal";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import eventService from "@/api/event.js";
import statsService from "@/api/stats.js";
import { EventsDataType } from "@/enums/event.js";

export default {
  name: "EventUserJoinDataModal",
  components: {
    BaseModal,
    DatePicker,
  },
  props: {
    requireTeam: {
      type: Boolean,
      required: false,
      default: false
    },
    joinTicket: {
      type: String,
      required: false,
      default: null
    },
    dataType: {
      type: String,
      required: false,
      default: "NO_DATA"
    },
    eventId: {
      type: String,
      required: true
    },
    isPrivate: {
      type: Boolean,
      default: false
    },
    toggleLoading: {
      type: Function,
      required: true
    }
  },
  created() {
    this.userData.token = this.joinTicket;
    this.loadPrefixes()
  },
  data() {
    return {
      prefixes: {},
      selectedPrefix: null,
      userData: {
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        joinMethod: "remote",
        cvShare: false,
        cvFile: null,
        birthDate: null,
        token: null,
        team: {
          name: null,
          url: null
        }
      },
      modal: {
        title: "Joining event", // TODO: Add event ID
        buttons: [
          {
            name: "Join",
            class: "px-button--success",
            action: this.sendUserData
          },
          {
            name: "Close",
            class: "px-button--danger",
            action: () => this.selfClose()
          }
        ],
        close: () => this.selfClose()
      },
    }
  },
  methods: {
    loadPrefixes() {
      this.$parent.loading = true;
      statsService.getPrefixes()
        .then((response) => {
          const manageableMessage = !!response.data && !!response.data.prefixes;
          if (manageableMessage) {
            this.prefixes = response.data.prefixes;
          }
        })
        .catch((error) => {
          const manageableError = !!error.data.errorDescription && !!error.data;
          if (manageableError) {
            this.flashError(error.data.errorDescription)
          }
        })
        .finally(() => {
          this.$parent.loading = false;
          const keys = Object.keys(this.prefixes);
          this.selectedPrefix = this.prefixes[keys[0]];
          this.setPrefix();
        })
    },
    setPrefix() {
      this.userData.phoneNumber = null;
      this.userData.phoneNumber = String(this.selectedPrefix);
    },
    shouldAskParticipatingLoc() {
      return this.dataType == EventsDataType.DATA_LOC
    },
    toggleCvShare() {
      this.userData.cvShare = !this.userData.cvShare;
    },
    toggleJoinMethod() {
      const joinMethod = this.userData.joinMethod;
      if (joinMethod === "remote") {
        this.userData.joinMethod = "onsite";
        return;
      }
      this.userData.joinMethod = "remote";
    },
    uploadFile() {
      const uploadBtn = this.$refs["cvFile"];
      uploadBtn.click();
    },
    cvFileSelected(event) {
      this.userData.cvFile = event.target.files[0];
    },
    selfClose() {
      this.$emit("close");
    },
    sendUserData() {
      const formData = new FormData();
      const userData = this.userData;

      for (let [key, value] of Object.entries(userData)) {
        if (key == "birthDate") {
          value = value.toISOString();
        }

        if (!value) continue;

        if (key === "team" && Object.values(value).some(item => item !== null)) {
          formData.append(key, JSON.stringify(value))
        }
        if (key !== "team") {
          formData.append(key, value);
        }
      }

      const eventId = this.eventId
      // this.$parent.loading = true;
      this.toggleLoading();
      eventService.joinEvent(eventId, formData, { "Content-Type": "muptipart/form-data" })
        .then((response) => {
          const manageableMessage = !!response.data && !!response.data.message;
          if (manageableMessage) {
            this.flashSuccess(response.data.message);
            this.$router.push(["/event", eventId].join("/"));
            this.selfClose();
          }
        })
        .catch((error) => {
          const manageableError = !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => {
          this.toggleLoading();
          // this.$parent.loading = false;
        })
    },
  },
  watch: {
    'userData.cvFile': function (new_) {
      const isValid = !!new_;
      if (isValid) {
        this.userData.cvShare = true;
      }
    },
    'userData.phoneNumber': function(phoneNew, phoneOld) {
      // eslint-disable-next-line no-unused-vars
      const latestIndex = phoneNew.length - 1;
      const latest = phoneNew[latestIndex];
      const notAllowed = Array.from(
        {length: (90 - 65) / 1 + 1}, 
        (_, index) => String.fromCharCode(65 + index)
      );
      if (notAllowed.some(char => char === latest || char.toLowerCase() === latest))  {
        this.userData.phoneNumber = phoneOld;
      }
    },
    'selectedPrefix': function() {
      this.setPrefix();
    }
  }
}
</script>
<style scoped lang="scss">
@import "../assets/css/colors.scss";

.fileup-container:hover {
  background-color: darken($neutral--dark, 15) !important;
  cursor: pointer !important;
  transition: all 0.5s ease;
}

.participating-toggle {
  cursor: pointer;
  background: $neutral--darkest !important;
  margin: 0;

  &.active {
    background: $soft-green !important;
    color: black;
  }
}

.cv-share-allowance {
  cursor: pointer;
  background-color: $neutral--darkest !important;

  &.not-allow {
    &.active {
      background-color: $soft-red !important;
    }
  }

  &.allow {
    &.active {
      background-color: $soft-green !important;
      color: black;
    }
  }
}
</style>
