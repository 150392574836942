<template>
  <div class="container-fluid table vh-100 padding-bottom-20">
    <div class="row mb-3">
      <div class="col-6">
        <h1 class="main-title-1">
          {{ this.leadType | capitalize }} Leader Board
        </h1>
      </div>
      <div class="col-6 text-right mx-0 pr-4">
        <px-button
          @click.native="goBack()"
          class="px-button--secondary icon-left outline"
          icon="left-arrow"
        >
          Back
        </px-button>
      </div>
    </div>
    <px-card class="no-bg">
      <v-chart :options="leadChart" autoresize class="w-100"></v-chart>
    </px-card>
    <div class="d-flex flex-row justify-content-end" v-if="leadType != 'team'">
      <px-button
        v-if="!retired"
        @click.native="getGeneralLeaderboard"
        class="mx-5 mt-5 px-button--secondary outline"
      >
        Show HOF leaderboard
      </px-button>
      <px-button
        v-if="retired"
        @click.native="getGeneralLeaderboard"
        class="mx-5 mt-5 px-button--secondary outline"
      >
        Hide HOF leaderboard
      </px-button>
      <form @submit.prevent class="mb-3">
        <div class="form-group d-flex flex-column text-white">
          <label for="country" class="fw-bold">Country:</label>

          <select
            v-model="country"
            class="form-control"
            name="country"
            id="country"
          >
            <option value="default">Default (ALL)</option>
            <option
              v-for="(field, index) in countries"
              :value="String(field).toLowerCase()"
              :key="index"
              >{{ field }}</option
            >
          </select>
        </div>
      </form>
    </div>
    <div
      :class="[
        this.leadType === 'team' ? 'padding-top-20 padding-bottom-20' : '',
      ]"
    >
      <px-table>
        <div class="row table-header mb-3 mx-0">
          <div class="col-1 py-3">
            <h2 class="mb-0">rank</h2>
          </div>
          <div class="col-2 col-md-1 py-3">
            <h2 class="mb-0">avatar</h2>
          </div>
          <div class="col-1 py-3" v-if="leadType == 'user'">
            <h2 class="mb-0">country</h2>
          </div>
          <div class="col-4 py-3">
            <h2 class="mb-0">name</h2>
          </div>
          <div class="col-1 py-3" v-if="leadType !== 'user'">
            <h2 class="mb-0">members</h2>
          </div>
          <div class="col-1 py-3">
            <h2 class="mb-0">points</h2>
          </div>
          <div
            v-responsive.md.lg.xl
            class="col-2 py-3"
            v-if="leadType !== 'team'"
          >
            <h2 class="mb-0">flag 1</h2>
          </div>
          <div
            v-responsive.md.lg.xl
            class="col-2 py-3"
            v-if="leadType !== 'team'"
          >
            <h2 class="mb-0">flag 2</h2>
          </div>
        </div>
        <InfiniteScroll :items="leaders">
          <template v-slot:item="leader">
            <div
              class="row my-2 my-md-0 table__row d-flex align-items-center py-1"
            >
              <div class="col-1 col__rank">
                {{ leader.item.rank }}
              </div>
              <div class="col-2 col-md-1">
                <div
                  class="avatar avatar--sm my-1"
                  :style="{ backgroundImage: `url(${leader.item.picture})` }"
                  :class="[
                    leadType == 'user' && isUserPremium(leader.item)
                      ? 'premium-border'
                      : '',
                  ]"
                  @click="navigateTo(leader.item.id)"
                />
              </div>
              <div class="col-1" v-if="leadType === 'user'">
                <flag :iso="leader.item.country" :squared="false" />
              </div>
              <div class="col-4" @click="navigateTo(leader.item.id)">
                <h2 class="mb-0">
                  {{ leader.item.name | truncate(15) }}
                </h2>
              </div>
              <div
                v-responsive.md.lg.xl
                class="col-1 col__members"
                v-if="leadType !== 'user'"
              >
                {{ leader.item.members }}
              </div>
              <div class="col-1 col__points">
                {{ leader.item.points }}
              </div>
              <div
                v-responsive.md.lg.xl
                class="col-2 col__users"
                v-if="leadType !== 'team'"
              >
                {{ leader.item.users }}
              </div>
              <div
                v-responsive.md.lg.xl
                class="col-2 col__systems"
                v-if="leadType !== 'team'"
              >
                {{ leader.item.systems }}
              </div>
            </div>
          </template>
        </InfiniteScroll>
      </px-table>
    </div>
  </div>
</template>

<script>
import ECharts from "vue-echarts";
import statsService from "../api/stats";
import PxTable from "@/components/PxTable";
import PxButton from "@/components/PxButton";
import PxCard from "../components/cards/PxCard";
import InfiniteScroll from "../components/InfiniteScroll";

export default {
  name: "LeaderBoard",
  components: {
    PxButton,
    PxTable,
    "v-chart": ECharts,
    "px-card": PxCard,
    InfiniteScroll,
  },
  data() {
    return {
      leadType: this.$route.params.leadType,
      otherLeaders: [],
      leaders: [],
      top: [],
      leadChart: {
        // color: ["rgba(195, 60, 84,1)", "rgba(37, 78, 112,1)", "rgba(55, 113, 142,1)", "rgba(99, 170, 191,1)", "rgba(142, 227, 239,1)"],
        textStyle: {
          color: "#768aa3",
          fontFamily: "sans-serif",
        },
        tooltip: { trigger: "axis" },
        legend: {
          data: [],
          textStyle: {
            color: "#768aa3",
            fontFamily: "sans-serif",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          show: true,
          type: "category",
          boundaryGap: false,
          data: [],
          axisLine: {
            lineStyle: {
              color: "#768aa3",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#768aa3",
            },
          },
          splitLine: {
            lineStyle: {
              color: "#555",
              type: "dotted",
            },
          },
        },
        series: [],
      },
      retired: false,
      country: "default",
      countries: [],
    };
  },
  beforeCreate() {
    this.$parent.breadcrumbs = [{ name: "leader board" }];
  },
  created() {
    const today = new Date();
    for (let i = 89; i >= 0; i--) {
      const day = new Date(new Date().setDate(today.getDate() - i));
      this.leadChart.xAxis.data.push(`${day.getDate()}-${day.getMonth() + 1}`);
    }
    const leadType = this.$route.params.leadType;
    statsService
      .leaderBoard(leadType)
      .then((response) => {
        if (leadType == "team") {
          var data = response.data.leaders;
          /**
           *
           * leaders.append({
              "id": prof.id,
              "rank": i + 1,
              "picture": prof.profile_pic,
              "name": prof.name,
              "points": points,
              "users": owned_users,
              "systems": owned_systems,
              "members": len(prof.members)
          })
           */
          var i = 1;
          for (const row of data) {
            var id, name, profile_pic, members, points;
            [id, name, profile_pic, members, points] = row;

            this.leaders.push({
              id: id,
              rank: i,
              picture: profile_pic,
              name: name,
              points: points,
              members: members,
            });
            i++;
          }
        } else {
          this.leaders = response.data.leaders;
        }
        this.top = this.leaders.slice(0, 5);
        for (const lead of this.top.sort((a, b) => a.rank - b.rank)) {
          const areaColors = [
            "rgba(195, 60, 84,1)",
            "rgba(37, 78, 112,1)",
            "rgba(55, 113, 142,1)",
            "rgba(142, 227, 239,1)",
            "rgba(142, 227, 239,1)",
          ];
          statsService.lastMonthPoints(leadType, lead.id).then((response) => {
            this.leadChart.legend.data.push(lead.name);
            this.leadChart.series[lead.rank] = {
              name: lead.name,
              type: "line",
              symbol: "circle",
              symbolSize: 6,
              stack: `${lead.name}Stack`,
              data: response.data.data,
              smooth: false,
              color: areaColors[lead.rank - 1],
              areaStyle: {
                opacity: 0.8,
                color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: `${areaColors[lead.rank - 1]}`,
                  },
                  {
                    offset: 1,
                    color: `${areaColors[lead.rank - 1].replace(",1", ",0")}`,
                  },
                ]),
              },
            };
          });
        }
      })
      .catch((error) => {
        const manageableError =
          !!error && !!error.data && !!error.data.errorDescription;
        if (manageableError) {
          this.flashError(error.data.errorDescription);
        }
      });
    this.getCountries();
  },
  methods: {
    getCountries() {
      statsService
        .getCountries()
        .then((response) => (this.countries = response.data));
    },
    getLeadersByCountry() {
      statsService
        .getLeadersByCountry(this.country)
        .then((response) => {
          this.leaders = response.data;
        })
        .catch((error) => {
          const manageableError =
            !!error && error.data && error.data.errorDescription;
          if (manageableError) this.flashError(error.data.errorDescription);
          this.$router.push("/leaderboards/user");
        });
    },
    getLeaders() {
      const leadType = this.$route.params.leadType;
      statsService
        .leaderBoard(leadType)
        .then((response) => (this.leaders = response.data.leaders))
        .catch((error) => {
          const manageableError =
            !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
          this.$router.push("/leaderboards/user");
        });
    },
    navigateTo(id) {
      let name = null;
      let params;
      if (this.leadType === "team") {
        name = "TeamDetails";
        params = { teamId: id };
      } else if (this.leadType === "user") {
        name = "UserDetails";
        params = { userId: id };
      } else if (this.leadType === "company") {
        name = "CompanyDetails";
        params = { companyId: id };
      }
      if (name) {
        this.$router.push({ name, params });
      }
    },
    getGeneralLeaderboard() {
      if (!this.retired)
        statsService.generalLeaderBoard().then((response) => {
          this.otherLeaders = this.leaders;
          this.leaders = response.data.leaders;
        });
      if (this.retired) {
        this.leaders = this.otherLeaders;
      }
      this.retired = !this.retired;
    },
    isUserPremium(user) {
      if (!user) return false;
      return user.premium !== undefined && user.premium.length > 0;
    },
  },
  watch: {
    country: function(new_, old_) {
      if (new_ !== old_ && new_ !== "default") {
        this.getLeadersByCountry();
      }
      if (new_ == "default" && old_ !== "default") this.getLeaders();
      if (this.retired && new_ !== "default") this.retired = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";

.avatar {
  height: 50px;
  width: 50px;
}

.table__row {
  font-size: 0.9rem;

  h2 {
    color: white;
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: bold;
    transition: all 0.2s;

    &:hover {
      color: rgba($soft-green, 1);
    }
  }
}

.premium-border {
  border: 2px solid gold;
}
</style>
