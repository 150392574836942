<template>
  <base-modal
    :title="modal.title"
    :buttons="modal.buttons"
    :close="modal.close"
  >
    <div class="py-5 text-lg" style="font-size:20px">
      <span class="text-warning">WARNING</span>: By reading this writeup in case
      you solve the lab the flag points will be decreased by the 50% for your
      account
    </div></base-modal
  >
</template>

<script>
import labService from "@/api/lab";
import BaseModal from "@/components/BaseModal";

export default {
  data() {
    return {
      modal: {
        title: "Writeup Allowance",
        buttons: [
          {
            name: "Accept",
            class: "px-button--success",
            action: this.acceptAllowance,
          },
          {
            name: "Decline",
            class: "px-button--danger",
            action: this.selfClose,
          },
        ],
        close: this.selfClose,
      },
    };
  },
  props: {
    lab: {
      type: Object,
      required: true,
    },
    openWriteup: {
      type: Function,
      required: true,
    },
    stopLoading: {
      type: Function,
      required: true,
    },
  },
  components: {
    BaseModal,
  },
  methods: {
    selfClose() {
      this.$emit("close");
    },
    acceptAllowance() {
      this.$parent.loading = true;
      const labId = this.lab.id;

      labService
        .acceptAllowance(labId)
        .then((response) => {
          const manageableMessage = !!response.data.message;
          if (manageableMessage) {
            this.flashSuccess(response.data.message);
            this.selfClose();
            this.openWriteup(this.lab);
          }
        })
        .catch((error) => {
          const manageableError = error.data.errorDescription;
          if (manageableError) {
            this.selfClose();
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => {
          this.stopLoading();
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
