<template>
    <base-modal
            :title="modal.title"
            :buttons="modal.buttons"
            :close="modal.close">
        <p>
            You have clicked on a link that redirect to an external resource.<br>
            The resource is: <a>{{extLink}}</a>
        </p>
        <p>Do you want to continue anyway?</p>
    </base-modal>
</template>

<script>
    import BaseModal from "./BaseModal";

    export default {
        name: "ExternalNavigationModal",
        components: {BaseModal},
        props: [
            "extLink"
        ],
        data() {
            return {
                modal: {
                    title: "Attention! You are leaving PWNX!",
                    buttons: [
                        {
                            name: "navigate",
                            class: "btn-primary",
                            action: () => this.confirm(),
                        },
                    ],
                    close: this.selfClose
                },
            }
        },
        methods: {
            selfClose() {
                this.$emit('close');
            },
            confirm() {
                window.open(this.extLink, "_blank");
                this.selfClose();
            }
        }
    }
</script>

<style scoped>

</style>
