<template>
  <div>
    <div class="container-fluid table">
      <form v-if="canRecruiterGetOthers() || checkTicketAvailability()" action="" @submit.prevent
        class="bg-dark p-3 rounded-card__item bg-gradient">
        <div class="row align-items-center mb-3">
          <div class="col-md-6 col-12 heading">
            <span class="no-text-transform">SEND A TICKET</span>
          </div>
        </div>
        <div class="form-group">
          <label for="u-search" class="card__label">Username or Email</label>
          <input type="text" v-model="uSearch" class="form-control" placeholder="Username or Email" @keyup="uSearchUser"
            id="u-search">
        </div>
        <div class="row">
          <div class="col-md-6" v-if="!isInvitingByEmail">
            <px-button class="px-button--danger w-100" @click.native="clearInputData">Clear</px-button>
          </div>
          <div :class="[!this.isInvitingByEmail ? 'col-md-6' : 'col-md-12']">
            <px-button :class="`w-100 ${btnClass}`" @click.native="sendInviteLink">
              {{ getBtnText }}
            </px-button>
          </div>
        </div>
        <div class="pwnx-dropdown">
          <h2 class="main-title-1 card__label my-3">available tickets: {{ availableTickets }} </h2>
          <ul class="pwnx-dropdown-menu" :class="{ 'active': this.uSearchResults.length > 0 }">
            <li v-for="user of uSearchResults" class="row" :key="user.id" @click="getNewTicket(user)">
              <div class="col-4">
                <px-badge class="px-badge--blue mr-5">
                  {{ user.role }}
                </px-badge>
              </div>
              <div class="col-8">
                {{ user.username }}
              </div>
            </li>
          </ul>
        </div>
      </form>
      <div class="d-flex my-5 flex-column justify-content-center align-items-center">
        <h3 class="text-white" v-if="!canRecruiterGetOthers() && !checkTicketAvailability()">There are no tickets
          available</h3>
        <!-- <div v-if="canRecruiterGetOthers() && !checkTicketAvailability()" class="text-center" id="request-new-tickets">
                    <h4 class="text-white">Click below to request new tickets!</h4>
                    <px-button class="px-button--success" @click.native="getNewTicket">
                        Get Ticket
                    </px-button>
                </div> -->
      </div>
      <div class="row mx-xxl-5" v-if="tickets.length > 0">
        <div id="card" v-for="ticket of tickets" :key="ticket.id"
          class="ticket-card mb-md-5 mx-md-0 mx-sm-5 rounded opacity col-lg-4 text-center rounded">
          <div class="card text-white mb-3 h-100"
            :class="{ 'recruiter-card-disabled': !ticket.available, 'bg-dark': ticket.available, 'bg-success': ticketId === ticket.id }">
            <div class="card-header">
              <img
                :src="ticket.user === undefined ? 'https://pwnxassets.blob.core.windows.net/pwnx-events-pics/pwnx-ticket-join.png' : ticket.user.profile"
                class="rounded-circle border border-dark" alt="user" width="100" height="100">
            </div>
            <div class="card-body overflow-hidden">
              <h6 class="card-title">{{ ticketUserData(ticket) }}</h6>
              <p class="card-text" v-if="ticket.available">You can use this ticket to make users able to join your HR+
                company event</p>
              <p class="card-text" v-if="!ticket.available">You can't use this ticket, another user is already using it
              </p>
              <div id="other-recruiter-data" class="mt-5" v-if="ticket.recruiter_id !== user.id && !ticket.available">
                <p>Used by recruiter: {{ ticket.recruiter_username }}</p>
              </div>
            </div>
            <px-button :class="`availability-button disabled my-2 px-button--${availableNames[availableNameIndex]}`"
              v-if="ticket.available">available</px-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import appService from '../api/app.js';
import eventService from '../api/event.js';
import recruiterService from '../api/recruiter.js';
import companyService from "@/api/company.js";
import PxBadge from '../components/PxBadge';
import PxButton from '../components/PxButton';
import UserType from '../enums/user';

export default {
  name: "RecruiterTicket",
  data() {
    return {
      tickets: [],
      ticketId: "",
      availableTickets: 0,
      isValidUser: false,
      ticketsInterval: null,
      selectedUser: null,
      eventId: this.$route.params.eventId,
      maxRecruiterTickets: null,
      uSearch: null,
      uSearchResults: [],
      availableNameIndex: 0,
      availableNames: ["success outline", "success"]
    }
  },
  components: {
    PxButton,
    PxBadge
  },
  mounted() {
    this.ticketsInterval = setInterval(this.getTickets, 30000);
  },
  created() {
    const allowedUsers = [
      UserType.RECRUITER,
      UserType.ENTERPRISE,
      UserType.HRPLUS
    ]
    if (!allowedUsers.some(role => role === this.user.role)) this.$router.push("/")
    this.$parent.loading = true;
    this.getTickets();
    this.getContract();
    this.$parent.loading = false;
    this.availabilityButtonInterval = setInterval(() => {
      this.availableNameIndex += 1;
      if (this.availableNameIndex == this.availableNames.length) this.availableNameIndex = 0;
    }, 3000)
  },
  beforeDestroy() {
    clearInterval(this.ticketsInterval);
    clearInterval(this.availabilityButtonInterval);
  },
  computed: {
    getBtnText() {
      if (!this.isValidUser) return "Invalid Username";
      if (this.isInvitingByEmail && !this.isValidEmail) return "Invalid Email";
      return "Invite";
    },
    btnClass() {
      if (this.isInvitingByEmail && !this.isValidEmail || !this.isValidUser) return "px-button--danger disabled"
      return "px-button--success"
    },
    isValidEmail() {
      if (!this.uSearch) return false;
      if (!this.isInvitingByEmail) return true;
      const pattern = /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@(?:[a-zA-Z0-9]+)\.(?:[a-zA-Z0-9]+)/i;
      return pattern.test(this.uSearch.trim())
    },
    isInvitingByEmail() {
      if (!this.uSearch) return false;
      return this.uSearch.includes("@")
    },
    ...mapState({
      user: state => state.user
    })
  },
  methods: {
    async getAvailableTickets() {
      const companyId = this.user.company_id
      this.$parent.loading = true;
      return await companyService.getAvailableTickets(companyId)
        .then((response) => {
           return response.data.tickets;
        })
        .catch((error) => {
          const manageableError = !!error.data && !!error.data.errorDescription;
          if (manageableError) this.flashError(error.data.errorDescription);
          return 0
        })
        .finally(() => {
          this.$parent.loading = false;
        });
    },
    clearInputData() {
      this.isValidUser = false;
      this.uSearch = "";
      this.selectedUser = null;
    },
    sendInviteLink() {
      const user = this.isInvitingByEmail ? this.uSearch.trim() : this.selectedUser.id;
      recruiterService.sendTicket(this.eventId, user).then(response => {
        const manageableMessage = !!response.data.message;
        if (manageableMessage) {
          this.flashSuccess(response.data.message);
          this.getTickets()
        }
      }).catch((error) => {
        const manageableError = error.data && error.data.errorDescription;
        if (manageableError) this.flashError(error.data.errorDescription);
      })
    },
    uSearchUser() {
      if (this.isValidUser) this.isValidUser = false;
      if (this.isInvitingByEmail) this.isValidUser = true;

      if (!this.uSearch || this.isInvitingByEmail) {
        this.uSearchResults = [];
        return;
      }
      appService.uSearch({ "searchTerm": this.uSearch }, "user")
        .then((response) => {
          this.uSearchResults = response.data.results;
        })
    },
    getContract() {
      eventService.getEventCompanyContract(this.eventId)
        .then((response) => {
          this.maxRecruiterTickets = response.data.contract.maxRecruiterTickets;
        })
        .catch((error) => {
          const manageableError = !!error.data && error.data.errorDescription && error.data.errorCode;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
    },
    canRecruiterGetOthers() {
      return this.tickets.length < this.maxRecruiterTickets;
    },
    ticketUserData(ticket, key = "username") {
      if (!ticket.user && key === "email") return "";
      if (!ticket.user) return "Not used yet";
      return ticket.user[key]
    },
    async getTickets() {
      const eventId = this.eventId;
      const initialTickets = await recruiterService.getTickets(eventId, "invalid");
      const tickets = []
      for (const ticket of initialTickets.data.tickets) {
        ticket["selectHidden"] = true
        tickets.push(ticket)
      }
      this.tickets = Array.from(new Set(tickets));
      const availableTickets = await this.getAvailableTickets();
      this.availableTickets = availableTickets;
    },
    checkTicketAvailability() {
      if (this.tickets.length === 0) return false;
      return this.tickets.some(ticket => ticket.available === true)
    },
    getNewTicket(userData = null) {
      this.uSearchResults = [];
      this.selectedUser = {}

      this.selectedUser['id'] = userData.id;
      this.selectedUser['username'] = userData.username;
      this.selectedUser['email'] = "";
      this.isValidUser = true;
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/colors.scss";

.recruiter-card-disabled {
  border: 1px solid #999999;
  background-color: #1c1e21;
  color: #666666;
}

#card {
  transition: all 0.5s ease;
}

.pwnx-dropdown {
  position: relative;
  text-decoration: none;

  & :hover {
    background-color: $neutral--darker;
  }
}

.pwnx-dropdown-menu {

  position: absolute;
  list-style-type: none;
  top: 0;
  left: 0;
  margin: 0px;
  padding: 0px;
  width: 100%;
  min-height: 0%;
  z-index: -100;
  visibility: hidden;
  max-height: 500px;
  overflow-y: scroll;

  & li {
    padding: 20px;
  }

  &.active {
    visibility: visible;
    z-index: 10;
    margin-top: -16px;
    top: 100%;
    width: 100%;
    min-height: 100%;
    display: inline-block;
    background-color: $neutral--darkest;
  }

}

.ticket-card {
  height: 350px;
  min-height: 350px;
  max-height: 350px;
}

.availability-button {
  transition: all 3s ease;
}</style>
