<template>
  <div class="container">
    <div v-if="pageMode === 'login'" class="row align-items-center justify-content-center vh-100 px-login">
      <div class="col-12 col-md-7 col-lg-5 d-flex flex-column login__container px-5 py-5">
        <div class="row justify-content-center align-items-center mb-5">
          <div class="col-5 login--header justify-content-center align-items-center">
            <img class="logo-img w-100" src="../assets/images/logo-pwnx-darkmode.svg" alt="">
          </div>
        </div>
        <h2 class="mb-0 px-input__name mt-2">username</h2>
        <input type="text" class="px-input" v-model="login.username" placeholder="username">
        <h2 class="mb-0 px-input__name mt-2">password</h2>
        <input type="password" v-model="login.password" class="px-input__password px-input" placeholder="password">
        <p class="text-center simple-hover click-text" @click="startForgot()">Forgot password?</p>
        <px-button key="keyLogin" class="px-button--success outline my-1 mt-3"
          :class="{ 'px-button--loading': login.isLoading }" @click.native="submitLogin($event)" textInput="Sign in"
          ref="btnLogin">
        </px-button>
        <p class="text-center mb-0 pb-0">New user? <b class="simple-hover click-text" @click="startRegister">Create an
            account</b>
        </p>
      </div>
    </div>
    <div v-if="pageMode === 'registration' && !hasAccepted()"
      class="row align-items-center justify-content-center vh-100 px-login">
      <div class="col-12 col-md-7 col-lg-5 d-flex flex-column login__container px-5 py-5">
        <div class="row justify-content-center align-items-center mb-5">
          <div class="col-5 login--header justify-content-center align-items-center">
            <img class="logo-img w-100" src="../assets/images/logo-pwnx-darkmode.svg" alt="">
          </div>
        </div>
        <div class="conditions">
          <h1><b>Privacy Policy</b></h1>
          Updated for the new European Data Protection Regulation 2016/679 (GDPR)
          <hr />
          This website collects some Personal Data from its Users: the information concerning the data controller, the
          types of
          data collected, the methods and location of the processing and the rights of the data subject are described in
          the
          appropriate sections of this page.
          <hr />
          <b>Data Controller and Owner</b>
          <hr />
          Name: PWNX
          <hr />
          Address: Via di Valle Lupara, 10 – 00148 Roma (RM) Italy
          <hr />
          E-mail: privacy@pwnx.io
          <hr />
          <b>Types of Data collected</b>
          <hr />
          Among the types of Personal Data that this Application collects, by itself: email address.
          <hr />
          Complete details on each type of Personal Data collected are provided in the dedicated sections of this
          privacy policy
          or by specific explanation texts displayed prior to the Data collection.
          <hr />
          The Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when
          using this
          Application.
          <hr />
          All Data requested by this Application is mandatory and failure to provide this Data may make it impossible
          for this
          Application to provide its services. In cases where this Application specifically states that some Data is not
          mandatory, Users are free not to communicate this Data without any consequences on the availability or the
          functioning
          of the service.
          <hr />
          Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner.
          <hr />
          Any use of Cookies – or of other tracking tools – by this Application or by the owners of third party services
          used by
          this Application serves the purpose of providing the service required by the User, in addition to any other
          purposes
          described in the present document and in the Cookie Policy, if available.
          <hr />
          Users are responsible for any third party Personal Data obtained, published or shared through this Application
          and
          confirm that they have the third party’s consent to provide the Data to the Owner.
          <hr />
          <b>Mode and place of processing the Data</b>
          <hr />
          The Data Controller processes the Data of Users in a proper manner and shall take appropriate security
          measures to
          prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.
          <hr />
          <b>Methods of processing</b>
          <hr />
          The Data processing is carried out using computers and/or IT enabled tools, following organizational
          procedures and
          modes strictly related to the purposes indicated. In addition to the Data Controller, in some cases, the Data
          may be
          accessible to certain types of persons in charge, involved with the operation of the site (administration,
          sales,
          marketing, legal, system administration) or external parties (such as third party technical service providers,
          mail
          carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data
          Processors by the
          Owner. The updated list of these parties may be requested from the Data Controller at any time.
          <hr />
          <b>Place</b>
          <hr />
          The Data is processed at the Data Controller’s operating offices and in any other places where the parties
          involved with
          the processing are located. For further information, please contact the Data Controller.
          <hr />
          <b>Retention time</b>
          <hr />
          The Data is kept for the time necessary to provide the service requested by the User, or stated by the
          purposes outlined
          in this document, and the User can always request that the Data Controller suspend or remove the data.
          <hr />
          <b>The use of the collected Data</b>
          <hr />
          The Data concerning the User is collected to allow the Owner to provide its services, as well as for the
          following
          purposes: Content commenting, Contacting the User, Analytics and Interaction with external social networks and
          platforms.
          <hr />
          The Personal Data used for each purpose is outlined in the specific sections of this document.
          <hr />
          <b>Detailed information on the processing of Personal Data</b>
          <hr />
          Personal Data is collected for the following purposes and using the following services:
          <hr />
          <b>Content commenting</b>
          <hr />
          Content commenting services allow Users to make and publish their comments on the contents of this
          Application.
          Depending on the settings chosen by the Owner, Users may also leave anonymous comments. If there is an email
          address
          among the Personal Data provided by the User, it may be used to send notifications of comments on the same
          content.
          Users are responsible for the content of their own comments.
          <hr />
          If a content commenting service provided by third parties is installed, it may still collect web traffic data
          for the
          pages where the comment service is installed, even when Users do not use the content commenting service.
          <hr />
          Personal Data collected: E-Mail
          <hr />
          Place of processing: Italy, European Union.
          <hr />
          <b>Contacting the User</b>
          <hr />
          The User’s email address will be added to the contact list of those who may receive email messages containing
          information of commercial or promotional nature concerning this Application. Your email address might also be
          added to
          this list as a result of signing up to this Application or after making a purchase.
          <hr />
          Personal Data collected: E-Mail
          <hr />
          Place of processing: Italy, European Union.
          <hr />
          <b>Interaction with external social networks and platforms</b>
          <hr />
          This type of service allows interaction with social networks or other external platforms directly from the
          pages of this
          Application. The interaction and information obtained through this Application are always subject to the
          User’s privacy
          settings for each social network. This type of service might still collect traffic data for the pages where
          the service
          is installed, even when Users do not use it.
          <hr />
          <b>Additional information about Data collection and processing</b>
          <hr />
          <b>Legal action</b>
          <hr />
          The User’s Personal Data may be used for legal purposes by the Data Controller, in Court or in the stages
          leading to
          possible legal action arising from improper use of this Application or the related services.
          <hr />
          The User declares to be aware that the Data Controller may be required to reveal personal data upon request of
          public
          authorities.
          <hr />
          <b>Additional information about User’s Personal Data</b>
          <hr />
          In addition to the information contained in this privacy policy, this Application may provide the User with
          additional
          and contextual information concerning particular services or the collection and processing of Personal Data
          upon
          request.
          <hr />
          <b>System logs and maintenance</b>
          <hr />
          For operation and maintenance purposes, this Application and any third-party services may collect files that
          record
          interaction with this Application (System logs) or use for this purpose other Personal Data (such as IP
          Address).
          <hr />
          <b>Information not contained in this policy</b>
          <hr />
          More details concerning the collection or processing of Personal Data may be requested from the Data
          Controller at any
          time. Please see the contact information at the beginning of this document.
          <hr />
          <b>The rights of Users</b>
          <hr />
          Users have the right, at any time, to know whether their Personal Data has been stored and can consult the
          Data
          Controller to learn about their contents and origin, to verify their accuracy or to ask for them to be
          supplemented,
          cancelled, updated or corrected, or for their transformation into anonymous format or to block any data held
          in
          violation of the law, as well as to oppose their treatment for any and all legitimate reasons. Requests should
          be sent
          to the Data Controller at the contact information set out above.
          <hr />
          This Application does not support “Do Not Track” requests.
          <hr />
          To determine whether any of the third-party services it uses honor the “Do Not Track” requests, please read
          their
          privacy policies.
          <hr />
          <b>Changes to this privacy policy</b>
          <hr />
          The Data Controller reserves the right to make changes to this privacy policy at any time by giving notice to
          its Users
          on this page. It is strongly recommended to check this page often, referring to the date of the last
          modification listed
          at the bottom. If a User objects to any of the changes to the Policy, the User must cease using this
          Application and can
          request that the Data Controller remove the Personal Data. Unless stated otherwise, the then-current privacy
          policy
          applies to all Personal Data the Data Controller has about Users.
          <hr />
          <b>Information about this privacy policy</b>
          <hr />
          The Data Controller is responsible for this privacy policy.
          <hr />
          <b>Definitions and legal references</b>
          <hr />
          Personal Data (or Data)
          <hr />
          Any information regarding a natural person, a legal person, an institution or an association, which is, or can
          be,
          identified, even indirectly, by reference to any other information, including a personal identification
          number.
          <hr />
          Usage Data
          <hr />
          Information collected automatically from this Application, which can include: the IP addresses or emails by
          the Users
          who use this Application, the URI addresses (Uniform Resource Identifier), the time of the request, the method
          utilized
          to submit the request to the server, the size of the file received in response, the numerical code indicating
          the status
          of the server’s answer (successful outcome, error, etc.), the country of origin, the features of the browser
          and the
          operating system utilized by the User, the various time details per visit (e.g., the time spent on each page
          within the
          Application) and the details about the path followed within the Application with special reference to the
          sequence of
          pages visited, and other parameters about the device operating system and/or the User’s IT environment.
          <hr />
          User
          <hr />
          The individual using this Application, which must coincide with or be authorized by the Data Subject, to whom
          the
          Personal Data refers.
          <hr />
          Data Subject
          <hr />
          The legal or natural person to whom the Personal Data refers.
          <hr />
          Data Processor (or Data Supervisor)
          <hr />
          The natural person, legal person, public administration or any other body, association or organization
          authorized by the
          Data Controller to process the Personal Data in compliance with this privacy policy.
          <hr />
          Data Controller (or Owner)
          <hr />
          The natural person, legal person, public administration or any other body, association or organization with
          the right,
          also jointly with another Data Controller, to make decisions regarding the purposes, and the methods of
          processing of
          Personal Data and the means used, including the security measures concerning the operation and use of this
          Application.
          The Data Controller, unless otherwise specified, is the Owner of this Application.
          <hr />
          Interruzione pagina
          <hr />
          This Application
          <hr />
          The software tool by which the Personal Data of the User is collected.
          <hr />
          Cookies
          <hr />
          https://pwnx.io/privacy
          <hr />
          Legal information
          <hr />
          Notice to European Users: this privacy statement has been prepared in fulfillment of the obligations under
          Art. 10 of EC
          Directive n. 95/46/EC, and under the provisions of Directive 2002/58/EC, as revised by Directive 2009/136/EC,
          on the
          subject of Cookies. The information was updated on 25/05/2018 in accordance with the principles and general
          provisions
          of the EU Data Protection Regulation 2016/679 (GDPR), adding the necessary references to the Privacy Shield.
          <hr />
          This privacy policy relates solely to this Application.
          <hr />
          Latest update: October 25th 2022
        </div>
        <div class="row d-flex my-2">
          <input class="form-check-input col-2 mx-3 my-3" type="checkbox" id="gdpr1" :style="{
            backgroundColor: terms.gdpr1 ? 'rgb(114, 253, 133)' : '#fff',
            width: 15 + 'px',
            height: 15 + 'px',
            transition: 'all 5ms linear'
          }" :checked="terms.gdpr1" required @click="terms.gdpr1 = !terms.gdpr1">
          <label class="form-check-label col-10" for="gdpr1">
            <span class="mr-3" :style="{ color: 'red' }">*</span> I hereby consent to the processing of my personal data
            for the selected purposes (newsletter, or other communications coming from the company), and agree that PWNX
            may collect, store, process and use the personal data I have provided. My data will only be processed for
            any purpose beyond those specified if required by law. I can withdraw my consent to the processing of my
            data at any time: privacy@pwnx.io
          </label>
        </div>
        <div class="row d-flex my-2">
          <input class="form-check-input col-2 mx-3 my-3" type="checkbox" id="gdpr2" :style="{
            backgroundColor: terms.gdpr2 ? 'rgb(114, 253, 133)' : '#fff',
            width: 15 + 'px',
            height: 15 + 'px',
            transition: 'all 5ms linear'
          }" :checked="terms.gdpr2" required @click="terms.gdpr2 = !terms.gdpr2">
          <label class="form-check-label col-10" for="gdpr2">
            <span class="mr-3" :style="{ color: 'red' }">*</span> I hereby consent to the sending of personal data to
            business partners/third parties so that they can send prizes collected during the CTF events. I can withdraw
            my consent to the processing of my data at any time: privacy@pwnx.io
          </label>
        </div>
        <px-button key="keyTermsNext" class="px-button--success outline my-1 mt-3"
          :class="{ 'px-button--loading': login.isLoading, 'disabled': !terms.gdpr1 || !terms.gdpr2 }"
          @click.native="terms.confirmed = !terms.confirmed" textInput="Confirm" ref="btnNext">
        </px-button>
      </div>
    </div>
    <div v-if="pageMode === 'registration' && hasAccepted()"
      class="row align-items-center justify-content-center vh-100 px-login">
      <div class="col-12 col-md-7 col-lg-5 d-flex flex-column login__container px-5 py-4">
        <div class="row justify-content-end mb-4">
          <div class="col-6 px-0">
            <p class="mb-0 simple-hover text-right display-inline-block" @click="startLogin"><i
                class="fas fa-sign-in-alt mr-2"></i>go to
              login
            </p>
          </div>
        </div>
        <div class="row justify-content-center align-items-center mb-5">
          <div class="col-5 login--header justify-content-center align-items-center">
            <img class="logo-img w-100" src="../assets/images/logo-pwnx-darkmode.svg" alt="">
          </div>
        </div>
        <div class="row justify-content-between align-items-center mb-3">
          <div class="col-3 pr-0 text-right">
            <p class="mb-0 simple-hover" v-if="registration.step > 0" @click="handleRegistrationStep(0)"><i
                class="fas fa-arrow-left"></i> back
            </p>
          </div>
        </div>
        <div v-if="registration.step === 0" class="row flex-column">
          <h2 class="mb-1">step 1 / 2</h2>
          <p>Usernames has to be longer than 2 characters</p>
          <template v-if="registration.referralCode">
            <h2 class="mb-0 px-input__name mt-2">Referral code</h2>
            <input type="text" class="px-input" v-model="registration.referralCode" placeholder="Referral code"
              disabled />
          </template>
          <h2 class="mb-0 px-input__name mt-2">username</h2>
          <input type="text" class="px-input" v-model="registration.username.value" placeholder="username"
            ref="inputUsername">
          <p class="invalid-entry" v-if="registration.username.isTaken">
            <i class="fas fa-times-circle"></i>
            Username is not available.
          </p>
          <p class="valid-entry" v-else-if="registration.username.isChecked">
            <i class="fas fa-check-circle"></i>
            Username is available.
          </p>
          <p v-else>Insert your username</p>
          <h2 class="mb-0 px-input__name mt-2">email address</h2>
          <input type="text" class="px-input" :disabled="isInvite" v-model="registration.email.value"
            placeholder="email">
          <p class="invalid-entry" v-if="registration.email.isTaken">
            <i class="fas fa-times-circle"></i>
            The email address has already been used.
          </p>
          <p class="valid-entry" v-else-if="registration.email.isChecked">
            <i class="fas fa-check-circle"></i>
            The email address is valid.
          </p>
          <p v-else>Insert your email address</p>
          <px-button class="px-button--success outline my-1 mt-3"
            :class="{ 'disabled': !registration.canGoNext, 'px-button--loading': registration.isLoading, 'px-button--danger': registration.isTaken }"
            @click.native="handleRegistrationStep(1)" textInput="next" key="keyRegNext" ref="btnUsernameEmail">
          </px-button>
        </div>
        <div v-if="registration.step === 1" class="row flex-column">
          <h2 class="mb-1">step 2 / 2</h2>
          <h2 class="mb-0 px-input__name mt-2">password</h2>
          <p>The password must contain special characters, and be at least 12 characters long</p>
          <div class="row">
            <div class="col-9">
              <input v-if="registration.password.isVisible" type="text" v-model="registration.password.value"
                class="px-input w-100 mr-0 pr-0" placeholder="password">
              <input v-else type="password" v-model="registration.password.value" class="px-input w-100 mr-0 pr-0"
                placeholder="password">
            </div>
            <div class="col-3 d-flex align-items-center justify-content-center" @click="handlePasswordVisibility">
              <i v-if="registration.password.isVisible" class="fas fa-eye-slash"></i>
              <i v-else class="fas fa-eye"></i>
            </div>
          </div>
          <password-meter :password="registration.password.value" class="mb-2" @score="handlePasswordScore" />
          <div class="row">
            <div class="col">
              <p class="invalid-entry" v-if="registration.password.value.length < 12">
                <i class="fas fa-times-circle"></i>
                Password must be at least 12 characters long
              </p>
            </div>
          </div>
          <input type="password" class="px-input" v-model="registration.password.repeatValue"
            placeholder="repeat password">
          <vue-recaptcha ref="recaptcha" @verify="onCaptchaVerified" @expired="onCaptchaExpired" size="invisible"
            :sitekey="gRecaptchaKey" :loadRecaptchaScript="true">
          </vue-recaptcha>
          <px-button class="px-button--success outline my-1 mt-3"
            :class="{ 'disabled': !registration.canSubmit, 'px-button--loading': registration.isLoading }"
            @click.native="submitRegister()" textInput="Sign up" ref="btnRegister" />
        </div>
      </div>
    </div>
    <div v-if="pageMode === 'forgot'" class="row align-items-center justify-content-center vh-100 px-login">
      <div class="col-12 col-md-7 col-lg-5 d-flex flex-column login__container px-5 py-4">
        <div class="row justify-content-end mb-4">
          <div class="col-6 px-0">
            <p class="mb-0 simple-hover text-right display-inline-block" @click="startLogin"><i
                class="fas fa-sign-in-alt mr-2"></i>go to
              login
            </p>
          </div>
        </div>
        <div class="row justify-content-center align-items-center mb-5">
          <div class="col-5 login--header justify-content-center align-items-center">
            <img class="logo-img w-100" src="../assets/images/logo-pwnx-darkmode.svg" alt="">
          </div>
        </div>
        <div class="row flex-column">
          <h2>Forgot password?</h2>
          <p>Enter your email address to receive a password reset link.</p>
          <input type="text" class="px-input" v-model="forgot.email.value" placeholder="email address">
          <px-button class="px-button--success outline my-1 mt-3" :class="{ 'px-button--loading': forgot.isLoading }"
            @click.native="resetPassword()" textInput="submit" key="keyRegSubmit" ref="btnReset">
          </px-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import appService from "../api/app";
import { authGuard, cMap } from "../router";
import userService from "../api/user";
import ActivationEmail from "../components/ActivationEmail";
import PxButton from "@/components/PxButton";
import passwordMeter from "vue-simple-password-meter";

export default {
  name: "Login",
  components: {
    PxButton,
    passwordMeter,
    VueRecaptcha,
  },
  data() {
    return {
      pageMode: 'login',
      isInvite: false,
      registration: {
        step: 0,
        companyToken: null,
        referralCode: null,
        canGoNext: false,
        isLoading: false,
        isTaken: false,
        canSubmit: false,
        username: {
          btnText: "next",
          value: "",
          isValidFormat: false,
          isTaken: false,
          isLoading: false,
          isChecked: false
        },
        email: {
          value: "",
          isValidFormat: false,
          isTaken: false,
          isLoading: false,
          isChecked: false
        },
        password: {
          value: "",
          repeatValue: "",
          score: null,
          isValid: false,
          isVisible: false,
          isChecked: false
        },
      },
      login: {
        username: null,
        password: null,
        isLoading: false
      },
      forgot: {
        email: {
          value: null,
          isValidFormat: false
        },
        isLoading: false
      },
      typingTimeout: () => {
      },
      gRecaptchaKey: null,
      companyToken: null,
      inviteId: null,
      returnUrl: null,
      terms: {
        gdpr1: false,
        gdpr2: false,
        confirmed: false
      }
    }
  },
  watch: {
    'registration.username.value': function () {
      this.registration.username.isValidFormat = this.registration.username.value.length >= 3
      this.handleIsTyping(this.checkUsername)
    },
    'registration.email.value': function () {
      this.checkEmailFormat('registration')
      this.handleIsTyping(this.checkEmail)
    },
    'registration.password.value': function () {
      this.checkPassword()
      this.handleCanSubmit()
    },
    'registration.password.repeatValue': function () {
      this.checkPasswordRepeat()
      this.handleCanSubmit()
    },
    'forgot.email.value': function () {
      this.checkEmailFormat('forgot')
    }
  },
  created() {
    this.gRecaptchaKey = process.env.VUE_APP_gRecaptchaKey;
    const isSignOn = this.$route.query.signOn === "true";
    const code = this.$route.params.referralCode;
    if (code !== undefined) {
      if (code.match("\\w{8}\\-\\w{4}\\-\\w{4}\\-\\w{4}\\-\\w{12}") == null) {
        this.flashError("Invalid referral code")
        this.$router.push('/login');
      }
      this.registration.referralCode = this.$route.params.referralCode;
      this.startRegister();
    }
    window.addEventListener("keypress", this.handleKeyboard);
    // window.addEventListener("keyup", this.handleIsTyping);
    if (isSignOn) {
      this.companyToken = this.$route.query.companyToken;
      this.startRegister();
    } else {
      this.returnUrl = this.$route.query.returnUrl;
    }
    if (this.pageMode === "registration" && this.companyToken !== undefined) {
      userService.isInviteLink(this.companyToken)
        .then(response => {
          const isInvite = Object.keys(response.data.invite).length > 0;
          if (isInvite) {
            this.isInvite = isInvite;
            const email = response.data.invite.email;
            this.registration.email.value = email;
            this.registration.email.isValidFormat = true;
          }
        })
    }
  },
  beforeDestroy() {
    window.removeEventListener("keypress", this.handleKeyboard);
  },
  computed: {
    shoudlSeeUserTypeToggle() {
      const isInviteLink = !!this.companyToken;
      return !isInviteLink;
    }
  },
  emits: ["loggedIn"],
  methods: {
    startLogin() {
      this.pageMode = "login"
    },
    hasAccepted() {
      return this.terms.gdpr1 && this.terms.gdpr2 && this.terms.confirmed
    },
    startRegister() {
      this.registration.step = 0
      this.pageMode = "registration"
      for (let usernameProp in this.registration.username) {
        if (typeof this.registration.username[usernameProp] === "string") {
          this.registration.username[usernameProp] = ""
        }
        if (typeof this.registration.username[usernameProp] === "boolean") {
          this.registration.username[usernameProp] = false
        }
      }
      for (let emailProp in this.registration.email) {
        if (typeof this.registration.email[emailProp] === "string") {
          this.registration.email[emailProp] = ""
        }
        if (typeof this.registration.email[emailProp] === "boolean") {
          this.registration.email[emailProp] = false
        }
      }
      for (let passwordProp in this.registration.password) {
        if (typeof this.registration.password[passwordProp] === "string") {
          this.registration.password[passwordProp] = ""
        }
        if (typeof this.registration.password[passwordProp] === "boolean") {
          this.registration.password[passwordProp] = false
        }
      }
    },
    startForgot() {
      this.pageMode = "forgot"
    },
    handlePasswordScore(score) {
      this.registration.password.score = score.score
      this.checkPassword()
    },
    handleRegistrationStep(step) {
      if (step) this.registration.step = this.registration.step + 1
      else this.registration.step = this.registration.step - 1
    },
    handlePasswordVisibility() {
      this.registration.password.isVisible = !this.registration.password.isVisible
    },
    checkEmailFormat() {
      this.registration.email.isValidFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.registration.email.value)
    },
    checkUsername() {
      if (this.registration.username.isValidFormat) {
        this.registration.isLoading = true
        userService.checkUsername(this.registration.username.value.trim())
          .then(() => {
            this.registration.username.isTaken = true
            this.$refs.btnUsernameEmail.showMessage("username is taken", "danger");
          })
          .catch(() => {
            this.registration.username.isTaken = false
            this.registration.username.isChecked = true
          })
          .finally(() => {
            this.registration.isLoading = false
            this.handleFirstStep()
          });
      }
    },
    checkEmail() {
      if (this.registration.email.isValidFormat) {
        this.registration.isLoading = true
        userService.checkEmail(this.registration.email.value.trim())
          .then(() => {
            this.registration.email.isTaken = true
            this.$refs.btnUsernameEmail.showMessage("email is taken", "danger");
          })
          .catch(() => {
            this.registration.email.isTaken = false
            this.registration.email.isChecked = true
          })
          .finally(() => {
            this.registration.isLoading = false
            this.handleFirstStep()
          });
      }
    },
    handleFirstStep() {
      this.registration.canGoNext = this.registration.username.isValidFormat && !this.registration.username.isTaken &&
        this.registration.email.isValidFormat && !this.registration.email.isTaken
      this.registration.isTaken = this.registration.username.isTaken || this.registration.email.isTaken
    },
    checkPassword() {
      if (this.registration.password.value.length < 12) this.registration.password.isValid = false
      else this.registration.password.isValid = this.registration.password.score >= 3
    },
    checkPasswordRepeat() {
      this.registration.password.isChecked = this.registration.password.value === this.registration.password.repeatValue
    },
    getRandomByte() {
      const result = new Uint8Array(1);
      if (window.crypto && window.crypto.getRandomValues) {
        window.crypto.getRandomValues(result);
        return result[0];
      } else {
        return Math.floor(Math.random() * 256);
      }
    },
    generate(length = 16) {
      let pattern = /[a-zA-Z0-9_\-+.]/;
      return Array.apply(null, { 'length': length })
        .map(function () {
          let result = '';
          while (!pattern.test(result)) {
            result = String.fromCharCode(this.getRandomByte());
          }
          return result;
        }, this)
        .join('');
    },
    generatePassword() {
      this.input.password = this.generate();
      this.checkPassStrength();
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    onCaptchaVerified(recaptchaToken) {
      this.$refs.recaptcha.reset();
      this.submitRegisterData(recaptchaToken);
    },
    submitLogin() {
      this.login.isLoading = true
      if (!(this.login.username && this.login.password)) {
        // this.flashError('Both username and password are required!');
        this.$refs.btnLogin.showMessage("Both username and password are required!", "danger");
        // this.$parent.loading = false;
        this.login.isLoading = false;
        return;
      }
      this.$store.dispatch('user/login', this.login)
        .then(() => {
          this.$store.dispatch('user/getCurrentUser');
          this.$parent.$emit("loggedIn");
          return appService.getRoutes();
        })
        .then(response => {
          this.login.isLoading = false
          const routes = response.data.routes.map(r => {
            return {
              path: r.path,
              component: cMap[r.component],
              name: r.name,
              beforeEnter: authGuard
            }
          });
          this.$router.addRoutes(routes);
          this.$store.dispatch("router/init", { routes });
          const redirect = this.returnUrl || "/home?login";
          this.$router.push(redirect);
          this.$parent.loadMenus();
          this.$parent.notificationIntervalId = setInterval(this.$parent.getNotifications, 300000);
          this.$parent.userIntervalId = setInterval(this.$parent.loadUser, 300000);
          this.$parent.shouldStartTour();
        })
        .catch(error => {
          const manageableError = !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.$refs.btnLogin.showMessage(error.data.errorDescription, "danger");
          }
        })
        .finally(() => {
          this.login.isLoading = false
        }
        );
    },
    submitRegister() {
      this.$refs.recaptcha.execute();
      this.registration.isLoading = true;
    },
    submitRegisterData(recaptchaToken) {
      if (!this.hasAccepted()) {
        this.$router.push(`/`);
        return
      }
      let registerData = {
        username: this.registration.username.value,
        email: this.registration.email.value,
        password: this.registration.password.value,
        passwordRepeat: this.registration.password.repeatValue,
        referralCode: this.registration.referralCode,
        recaptchaToken: recaptchaToken,
        terms: this.hasAccepted(),
        returnUrl: this.$route.query.returnUrl,
        redirectId: this.$route.query.redirectId
      }
      if (this.companyToken) {
        registerData['companyToken'] = this.companyToken
      }

      userService.register(registerData)
        .then(response => {
          const successMessage = !!response && !!response.data && !!response.data.message;
          if (successMessage) {
            this.flashSuccess(response.data.message);
          }
          if (response.data.warn) {
            // possible company link warning
            setTimeout(() => this.flashWarning(response.data.warn), 3000);
          }
          this.pageMode = 'login'
        })
        .catch(error => {
          const manageableError = !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => {
          this.registration.isLoading = false;
        });
    },
    resetPassword() {
      this.forgot.isLoading = true;
      userService.passwordReset({ email: this.forgot.email.value })
        .then(response => {
          const successMessage = !!response && !!response.data && !!response.data.message;
          if (successMessage) {
            this.$refs.btnReset.showMessage(response.data.message, "primary");
          }
        })
        .catch(error => {
          const manageableError = !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            // this.flashError(error.data.errorDescription);
            this.$refs.btnReset.showMessage(error.data.errorDescription, "danger");
          }
        })
        .finally(() => {
          this.forgot.isLoading = false
          this.forgot.email.value = ""
        });
    },
    resendActivationEmail() {
      this.$modal.show(ActivationEmail, {}, { height: 'auto' })
        .then(() => this.showLogin());
    },
    goToPricing() {
      this.$router.push("/pricing-estimate").catch();
    },
    handleKeyboard(e) {
      clearTimeout(this.typingTimeout)
      if (e.key === "Enter") {
        if (this.pageMode === "login") {
          this.submitLogin()
        }
        if (this.pageMode === "forgot") this.resetPassword()
      }
    },
    handleIsTyping(callback, step) {
      this.typingTimeout = setTimeout(() => {
        callback(step)
      }, 1000)
    },
    handleCanSubmit() {
      this.registration.canSubmit = this.registration.canGoNext && this.registration.password.repeatValue === this.registration.password.value
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";

i.fas {
  color: #768aa3;
}

.link {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    color: #0056b3;
    cursor: pointer;
  }
}

.recruiter {
  display: flex;
  align-items: flex-start;

  &__label {
    margin-top: -.2rem;
  }

  &__switch {
    position: relative;
    display: inline-block;
    width: 2rem;
    height: 1rem;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;

    &::before {
      position: absolute;
      content: "";
      height: .7rem;
      width: .7rem;
      left: 3px;
      bottom: 2px;
      background-color: #555;
      transition: 0.4s;
    }

    &.round {
      border-radius: 1rem;

      &::before {
        border-radius: 50%;
      }
    }

    input:checked+& {
      background-color: rgba(75, 198, 106, 0.28);
    }

    input:checked+&::before {
      transform: translateX(14px);
      background-color: #4bc66a;
    }
  }
}

.px-login {
  p {
    font-size: .8rem;
    color: $neutral--real-medium;
  }
}

#logo {
  width: 130px;
}

input {
  all: unset;
}

.px-input {
  background: $neutral--darker;
  font-size: .8rem;
  padding: 10px 20px;
  margin: 5px 0;
  color: rgba($neutral--light, 1);

  &::placeholder {
    color: rgba($neutral--light, .5);
  }

  &:focus {
    border-left: 1px solid $soft-green;
  }
}

.px-input {
  &__name {
    color: $neutral--soft;
    font-size: .8rem;
  }

  &__password {}


}


.login__container {
  font-size: .8rem;
  background: $neutral--smooth;
  color: $neutral--soft;

}

.click-text {
  color: $soft-green !important;
}

.invalid-entry {
  color: $soft-red !important;

  * {
    color: $soft-red !important;
  }
}

.valid-entry {
  color: $soft-green !important;

  * {
    color: $soft-green !important;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f49837;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #72fd85;
}

input:focus+.slider {
  box-shadow: 0 0 1px #72fd85;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.conditions {
  overflow-y: scroll;
  overflow-x: hidden;
  word-wrap: break-word;
  max-height: 300px;
  min-height: 300px;
  padding: 30px;
  background-color: #191e23;
  position: relative;
}
</style>
