import httpService from "./http";


const reportService = {
  report(cid) {
    return httpService.post(`/v2/events/report/${cid}`);
  }
};

export default reportService;
