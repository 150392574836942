<template>
  <div>
    <div class="row align-items-center padding-bottom-20">
      <div class="col-lg-10 col-12">
        <div class="row mx-0 align-items-center">
          <div v-responsive.xs.sm class="w-100 d-flex justify-content-end">
            <px-button @click.native="goBack()" class="px-button--secondary icon-left outline" icon="left-arrow">
              back
            </px-button>
          </div>
          <div class="col-12 d-flex justify-content-center align-items-center d-md-block col-md-2 p-0">
            <figure class="user-details__figure">
              <!--suppress HtmlUnknownTarget -->
              <img :src="user.picture" alt="my profile picture" class="avatar mb-3"
                :class="this.isPremium() ? 'premium-border' : null" />
            </figure>
          </div>
          <div v-responsive.xs.sm class="col-12 text-center">
            <p class="user-details__username">
              <span class="premium-label" v-show="this.isPremium()">PREMIUM</span>
              {{ user.username }}
              <flag :iso="user.contacts.country" :squared="false" />
              <a class="ml-3 social__icon" v-if="user.contacts.twitter && user.social.twitter != 'null'"
                :href="'https://twitter.com/' + user.social.twitter">
                <i class="fab fa-twitter"></i>
              </a>
              <a class="ml-3 social__icon" v-if="user.contacts.linkedin && user.social.linkedin != 'null'"
                :href="'https://linkedin.com/in/' + user.social.linkedin">
                <i class="fab fa-linkedin"></i>
              </a>
              <a class="ml-3 social__icon" v-if="user.contacts.email && user.social.email"
                :href="'mailto:' + user.social.email">
                <i class="fas fa-envelope"></i>
              </a>
            </p>
          </div>
          <div v-responsive.md.lg.xl class="col-10 user-details__header">
            <p class="user-details__username">
              <span class="premium-label" v-show="this.isPremium()">PREMIUM</span>
              {{ user.username }}
              <flag :iso="user.contacts.country" :squared="false" />
              <a class="ml-3 social__icon" v-if="user.contacts.twitter && user.social.twitter != 'null'"
                :href="'https://twitter.com/' + user.social.twitter">
                <i class="fab fa-twitter"></i>
              </a>
              <a class="ml-3 social__icon" v-if="user.contacts.linkedin && user.social.linkedin != 'null'"
                :href="'https://linkedin.com/in/' + user.social.linkedin">
                <i class="fab fa-linkedin"></i>
              </a>
              <a class="ml-3 social__icon" v-if="user.contacts.email && user.social.email"
                :href="'mailto:' + user.social.email">
                <i class="fas fa-envelope"></i>
              </a>
            </p>
            <!-- disabled for pre release-->
            <!--            -->
            <p class="user-details__team" v-if="user.team">
              <router-link :to="'/team/' + user.team.id">
                <span class="user-details__team">{{ user.team.name }}</span>
              </router-link>
            </p>
            <p class="user-details__team" v-if="user.teamRequest">
              Awaiting response from
              <router-link :to="'/team/' + user.teamRequest.id">
                <span class="user-details__team">
                  {{ user.teamRequest.name }}
                </span>
              </router-link>
            </p>
            <div class="row mt-3">
              <div class="col-2">
                <i class="mr-2 fas fa-trophy"></i>{{ user.points.points }}
              </div>
              <div class="col-2">
                <i class="mr-2 fas fa-flag green"></i>{{ user.points.users }}
              </div>
              <div class="col-2">
                <i class="mr-2 fas fa-flag orange"></i>{{ user.points.systems }}
              </div>
              <div class="col-2" v-if="amITheUser">
                <i class="mr-2 fas fa-users"></i>{{ user.referrals }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-responsive.md.lg.xl class="col-2 text-right pr-4">
        <px-button @click.native="goBack()" class="px-button--secondary icon-left outline" icon="left-arrow">
          back
        </px-button>
      </div>
    </div>
    <div class="container-fluid"> 
      <div class="row my-4 p-0">
        <div class="col-md-6 m-0 p-0 shadow-lg latest-submissions">
          <div class="row mx-1 bg-medium mt-0">
            <div class="col-12">
              <h2 class="mb-0 py-3">
                Latest submissions
              </h2>
            </div>
          </div>
          <px-table class="mt-0 table__submissions pt-0 p-2 mx-0">
            <div class="row table-header mx-0 mb-1 py-2">
              <div class="col-4 border-right">
                <h2 class="mb-0">lab</h2>
              </div>
              <div class="col-4 d-flex justify-content-center align-items-center border-right">
                <h2 class="mb-0">flag</h2>
              </div>
              <div class="col-4 d-flex justify-content-center align-items-center">
                <h2 class="mb-0">points</h2>
              </div>
            </div>
            <template v-if="userActions.length > 0">
              <div class="row table__row mx-0 py-2" v-for="action in userActions" :key="action.key">
                <div class="col-4 d-flex align-items-center">
                  <h2 class="mb-0" @click="$parent.$router.push(`/lab/${action.id}`)">
                    {{ action.labType == 'PREMIUM_WEB' || action.labType == 'PREMIUM_WEB3' ? '👑' : null }}
                    {{ action.labName }}
                    {{ action.first_blood == true ? '🩸' : null }}

                  </h2>
                </div>
                <div class="col-4 d-flex justify-content-center align-items-center">
                  <p class="mb-0" :class="action.flagType ? 'green' : 'orange'"><i class="fas fa-flag"></i></p>
                </div>
                <div class="col-4 d-flex justify-content-center align-items-center">
                  <p class="mb-0">{{ action.points }}</p>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="position-relative m-0 p-0">
                <div class="empty-overlay m-0 p-0">
                  <p class="fw-bold text-uppercase">No data collected yet</p>
                </div>
                <div class="placeholder-table row table__row mx-1 py-2" v-for="index in 10" :key="index">
                  <div class="col-4">
                    <div class="colorize"></div>
                  </div>
                  <div class="col-4">
                    <div class="colorize"></div>
                  </div>
                  <div class="col-4">
                    <div class="colorize"></div>
                  </div>
                </div>
              </div>
            </template>
          </px-table>
        </div>
        <div class="col-md-6 w-100 row m-0 p-0">
          <div class="col-12 m-0 p-0 d-flex flex-column px-0 mx-0 w-100">
            <div class="row bg-gradient bg-medium p-0 m-0 w-100">
              <div class="col p-3 w-100">
                <h2 class="mb-0">
                  user stats
                </h2>
              </div>
            </div>
            <px-card class="m-0 p-0 pt-3 bg-dark shadow-lg graph-card w-100">
              <div v-if="userSystemsChartEmpty" class="empty-overlay">
                <p class="fw-bold text-uppercase">No data collected yet</p>
              </div>
              <v-chart class="w-100" :options="usersSystemsChart" autoresize></v-chart>
            </px-card>
          </div>
        </div>
      </div>
    </div>
    <div class="my-4 p-0 m-0 w-100">
      <div class="row p-0 m-0 w-100">
        <div 
          class="m-0 p-0 mb-5 w-100 col-12 col-md-8"
          v-if="isCurrentUser || !emptyStats"
          >
          <div class="row bg-medium mt-0 m-0 mr-1 p-0">
            <div class="col-12">
              <h2 class="mb-0 py-3">
                Lab statistics
              </h2>
            </div>
          </div>
          <div class="content h-100 b-5 bg-dark mr-1 position-relative">
              <div v-if="areStatisticsEmpty" class="empty-overlay">
                <p class="fw-bold text-uppercase">No data collected yet</p>
              </div>
            <v-chart :options="userStatistics" autoresize class="w-100 graph"></v-chart>
          </div>
        </div>
        <div 
          class="row p-0 m-0 w-100" 
          :class="{
            'col-md-4 col-12': isCurrentUser || !emptyStats,
            'col-12': !isCurrentUser || emptyStats
          }">
          <div class="row p-0 ml-1 w-100 m-0 bg-medium mb-5">
            <div class="col-12 w-100 p-0 m-0">
              <h2 class="m-0 p-3">
                User stats
              </h2>
            </div>
          </div>
          <div class="col-12 row m-0 p-0 w-100">
            <div class="col-12 px-0 mx-0 w-100">
              <px-card
                class="px-card no-bg mx-0 d-flex flex-column align-items-center justify-content-around diagonal white">
                <i class="icon icon-cup"></i>
                <p class="px-card__title py-2">{{ user.points.points }}</p>
                <p class="px-card__caption">points</p>
              </px-card>
            </div>
            <div class="col-12 px-0 mx-0">
              <px-card
                class="px-card no-bg mx-0 d-flex flex-column align-items-center justify-content-around diagonal white">
                <i class="icon icon-flag"></i>
                <p class="px-card__title py-2">{{ user.points.users }}</p>
                <p class="px-card__caption text-center">flag 1</p>
              </px-card>
            </div>
            <div class="col-12 px-0 mx-0">
              <px-card
                class="px-card no-bg mx-0 d-flex flex-column align-items-center justify-content-around diagonal white">
                <i class="icon icon-flag"></i>
                <p class="px-card__title py-2">{{ user.points.systems }}</p>
                <p class="px-card__caption text-center">flag 2</p>
              </px-card>
            </div>
            <div class="col-12 px-0 mx-0">
              <px-card
                class="px-card no-bg mx-0 d-flex flex-column align-items-center justify-content-around diagonal white">
                <i class="icon icon-cup"></i>
                <p class="px-card__title py-2">{{ user.daysMember }}</p>
                <p class="px-card__caption text-center">days member</p>
              </px-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid my-4 shadow-lg">
      <div class="row">
        <div class="col-md-12 bg-dark">
          <div class="row bg-medium mt-0">
            <div class="col-12">
              <h2 class="mb-0 py-3">
                Ranking
              </h2>
            </div>
          </div>
          <px-table class="mt-0 col-12 p-2">
            <div class="row table-header mb-3 py-2">
              <div class="col-2 border-right">
                <h2 class="mb-0">rank</h2>
              </div>
              <div class="col-8 col-md-3">
                <h2 class="mb-0">user</h2>
              </div>
              <div v-responsive.md.lg.xl class="border-left col-2 col-md-2 d-flex justify-content-center align-items-center border-right">
                <h2 class="mb-0">points</h2>
              </div>
              <div v-responsive.md.lg.xl class="col-2 d-flex justify-content-center align-items-center border-right">
                <h2 class="mb-0">
                  <i class="fas fa-flag green"></i>
                </h2>
              </div>
              <div v-responsive.md.lg.xl class="col-2 d-flex justify-content-center align-items-center">
                <h2 class="mb-0">
                  <i class="fas fa-flag orange"></i>
                </h2>
              </div>
            </div>
            <template>
            <div class="row table__row py-2" v-for="rank in ranks" :key="rank.rank"
              :class="rank.userId === userId ? 'highlights' : ''">
              <div class="col-2 d-flex align-items-center">
                <h2 class="mb-0 pb-0">{{ rank.rank }}</h2>
              </div>
              <div class="col-8 col-md-3 d-flex align-items-center">
                <h2 class="mb-0 text-nowrap" @click="$parent.$router.push(`/user/${rank.userId}`)">
                  <flag class="mr-2" :iso="rank.country" :squared="false" />
                  {{ rank.username | truncate(20) }}
                </h2>
              </div>
              <div v-responsive.md.lg.xl class="col-2 col-md-3 d-flex justify-content-center align-items-center">
                <p class="mb-0">{{ rank.points }}</p>
              </div>
              <div v-responsive.md.lg.xl class="col-2 d-flex justify-content-center align-items-center">
                <p class="mb-0">{{ rank.users }}</p>
              </div>
              <div v-responsive.md.lg.xl class="col-2 d-flex justify-content-center align-items-center">
                <p class="mb-0">{{ rank.systems }}</p>
              </div>
            </div>

            </template>
            <!-- <template v-else> -->
            <!--   <div class="position-relative m-2 p-0"> -->
            <!--     <div class="empty-overlay m-0 p-0"> -->
            <!--       <p class="fw-bold text-uppercase">No data collected yet</p> -->
            <!--     </div> -->
            <!--     <div class="placeholder-table row table__row mx-0 px-0 py-2" v-for="index in 10" :key="index"> -->
            <!--       <div class="col-2 m-0 p-0"> -->
            <!--       </div> -->
            <!--       <div class="col-8 col-md-3 m-0 p-0"> -->
            <!--       </div> -->
            <!--       <div class="col-2 m-0 p-0 col-md-3"> -->
            <!--       </div> -->
            <!--       <div class="col-2 m-0 p-0"> -->
            <!--       </div> -->
            <!--       <div class="col-2 m-0 p-0"> -->
            <!--       </div> -->
            <!--     </div> -->
            <!--   </div> -->
            <!-- </template> -->

          </px-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ECharts from "vue-echarts";
import userService from "../api/user";
import httpService from "../api/http";
import PxButton from "../components/PxButton";
import PxCard from "../components/cards/PxCard";
import PxTable from "../components/PxTable.vue";
import statsService from "@/api/stats";
import UserType from "@/enums/user";

export default {
  name: "UserDetails",
  computed: {
    userSystemsChartEmpty() {
      const users = this.usersSystemsChart.series[0].data;
      const systems = this.usersSystemsChart.series[1].data;

      const usersEmpty = users.every(item => item === 0)
      const systemsEmpty = systems.every(item => item === 0)
      return usersEmpty && systemsEmpty || this.usersSystemsChartIsEmpty;
    },
    userActions() {
      if (!this.user) return [];
      return this.user.actions
    },
    isCompany() {
      if (!this.userData) return false;
      if (this.isEnterprise) return true;
      if (this.isCorporate) return true;
      return false;
    },
    isCurrentUser() {
      if (!this.userData.id) return false
      return this.userData.id === this.userId
    },
    isEnterprise() {
      if (!this.userData.role) return false
      return this.userData.role === UserType.ENTERPRISE
    },
    isCorporate() {
      if (!this.userData.role) return false
      return this.userData.role === UserType.CORPORATE
    },
    emptyStats() {
      const stats = this.userStatistics;
      const [series] = stats.series;
      const indicator = stats.radar.indicator;
      return  indicator.length === 0 && series.data.length === 0;
    },
    ...mapState({
      userData: (state) => state.user,
    })
  },
  components: {
    "v-chart": ECharts,
    "px-button": PxButton,
    "px-card": PxCard,
    "px-table": PxTable,
  },
  data() {
    return {
      ranksEmpty: false,
      areStatisticsEmpty: true,
      labSuggestion: null,
      labIndex: 0,
      userStatistics: {
        title: {
          show: false,
        },
        legend: {
          show: false,
        },
        radar: {
          // shape: 'circle',
          indicator: [
            {name: "", max: 1000},
            {name: "", max: 1000},
            {name: "", max: 1000},
            {name: "", max: 1000}
            // { name: 'Web', max: 6500 },
            // { name: 'Pwn', max: 16000 },
            // { name: 'Web3', max: 30000 },
            // { name: 'Reverse', max: 38000 },
            // { name: 'OSINT', max: 52000 },
          ]
        },
        series: [
          {
            type: 'radar',
            itemStyle: {color: "rgb(114, 253, 133)"},
            data: [
              {
                name: "test", value: Array(10).fill(null).map(() => Math.floor(Math.random()*1000)),
              },
            ]
          }
        ]
      },
      usersSystemsChartIsEmpty: false,
      usersSystemsChart: {
        textStyle: {
          color: "#768aa3",
          fontFamily: "sans-serif"
        },
        color: ["rgba(250, 154, 65,1)", "rgba(114, 253, 133,1)"],
        legend: {
          data: ["Flag2", "Flag1"],
          textStyle: {
            color: "#768aa3",
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        xAxis: {
          show: true,
          type: "category",
          data: Array(30).fill(null).map(() => {
            const dt = new Date();
            return String(dt.getDay()) + "-" + String(dt.getMonth())
          }),
          axisLine: {
            lineStyle: {
              color: "#768aa3",
              opacity: 1,
            },
          },
          splitLine: {
            lineStyle: {
              color: "#555",
              type: "dotted",
              opacity: 1,
            },
          },
        },
        yAxis: {
          type: "value",
          minInterval: 1,
          axisLine: {
            lineStyle: {
              color: "#768aa3",
              opacity: 1
            },
          },
          splitLine: {
            lineStyle: {
              color: "#555",
              type: "dotted",
              opacity: 1,
            },
          },
        },
        series: [
          {
            data: Array(30).fill(null).map(() => Math.floor(Math.random()*10)),
            type: "line",
            symbol: "circle",
            symbolSize: 0,
            lineStyle: {
              color: "rgba(250, 154, 65,1)",
              width: 2,
            },
            areaStyle: {
              opacity: 0.3,
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(250, 154, 65,1)'
              }, {
                offset: 1,
                color: 'rgba(250, 154, 65,0)'
              }])
            },
            name: "Flag2",
          },
          {
            data: Array(30).fill(null).map(() => Math.floor(Math.random()*10)),
            type: "line",
            symbol: "circle",
            symbolSize: 0,
            lineStyle: {
              color: "rgba(114, 253, 133,1)",
              width: 2,
            },
            areaStyle: {
              opacity: 0.1,
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(114, 253, 133,1)'
              }, {
                offset: 1,
                color: 'rgba(114, 253, 133,0)'
              }])
            },
            name: "Flag1",
          },
        ],
      },
      userId: this.$route.params.userId,
      amITheUser: false,
      initUser: {
        id: this.$route.params.userId,
        name: "",
        surname: "",
        username: "",
        picture: "",
        daysMember: 0,
        points: {
          points: 0,
          systems: 0,
          users: 0,
        },
        role: 0,
        actions: [],
        team: null,
        teamRequest: null,
        social: {
          email: null,
          linkedin: null,
          twitter: null,
        },
        contacts: {
          email: false,
          linkedin: false,
          twitter: false,
        },
      },
      user: {},
      ranks: [],
    };
  },
  beforeCreate() {
    this.$parent.breadcrumbs = [{ name: "profile" }];
  },
  created() {
    this.getUser();
    this.loadUserStatistics();
  },
  methods: {
    getImage(image) {
      return image
    },
    loadUserStatistics() {
      if (!this.isCurrentUser && !this.isCompany) return;
      let seriesData = {}

      const userId = this.isUser ? null: this.userId;
      statsService.loadUserStatistics(userId)
        .then((response) => {
          if (Object.keys(response.data.chart).length === 0) return;
          const indicator = response.data.indicator;
          const chart = response.data.chart;

          this.areStatisticsEmpty = indicator.length === 0  || Object.keys(chart).length === 0

          if (this.areStatisticsEmpty) {

            return;
          }

          this.userStatistics.radar.indicator = indicator;
          seriesData = response.data.chart;

        }).finally(() => {
          if (this.areStatisticsEmpty) return;
          this.userStatistics.series[0].data = [seriesData]
        })
    },
    isPremium() {
      if (this.user.premium == undefined) return false;
      return this.user.premium.length > 0;
    },
    getUser() {
      this.$parent.loading = true;
      this.user = this.initUser;

      this.amITheUser = this.userId === this.userData.id;

      const requests = [
        userService.getUser(this.userId),
        userService.getActivities(this.userId),
        userService.getTeam(this.userId),
        userService.getData(this.userId),
        userService.getRank(this.userId)
      ];

      httpService
        .all(requests)
        .then(([user, activities, team, chart, rank]) => {
          // User
          const userTmp = user.data.user;
          this.user.username = userTmp.username;
          this.user.picture = userTmp.picture;
          this.user.points.points = userTmp.points;
          this.user.social.linkedin = userTmp.linkedin;
          this.user.social.twitter = userTmp.twitter;
          this.user.social.email = userTmp.email;
          this.user.contacts = userTmp.contacts;
          this.user.referrals = userTmp.referrals;
          this.user.premium = userTmp.premium;
          this.user.role = userTmp.role;

          const signInDate = new Date(userTmp.daysMember);
          const today = new Date();
          const timeDelta = today.getTime() - signInDate.getTime();
          this.user.daysMember = Math.round(
            timeDelta / (1000 * 3600 * 24)
          ).toFixed(0);

          // Activities
          this.user.actions = activities.data.actions;
          // Calculate user owns
          let ownedSystems = 0;
          let ownedUsers = 0;
          for (const action of this.user.actions) {
            if (action.flagType === 0) {
              ownedSystems++;
            } else if (action.flagType === 1) {
              ownedUsers++;
            }
          }
          this.user.points.systems = ownedSystems;
          this.user.points.users = ownedUsers;

          // Team
          // eslint-disable-next-line no-prototype-builtins
          if (team.data.team.hasOwnProperty("team")) {
            this.user.team = team.data.team.team;
            // eslint-disable-next-line no-prototype-builtins
          } else if (team.data.team.hasOwnProperty("teamRequest")) {
            this.user.teamRequest = team.data.team.teamRequest;
          }

          const users = chart.data.chartData.series[0]
          const systems = chart.data.chartData.series[1]

          const isEmpty = users.every(item => item === 0) && systems.every(item => item === 0)
          if (isEmpty) {
            this.usersSystemsChartIsEmpty = true;
          }

          // Chart
          if (!isEmpty) {
            this.usersSystemsChart.xAxis.data = chart.data.chartData.xData;
            this.usersSystemsChart.series[0].data = chart.data.chartData.series[0];
            this.usersSystemsChart.series[1].data = chart.data.chartData.series[1];
          }

          // Rank
          this.ranks = rank.data.ranks;
          this.ranksEmpty = this.ranks.length === 0

          if (this.user !== undefined && this.$parent.user.role === 3 && this.user.id == this.$parent.user.id) {
            this.flashError("You're not a user, cannot view your data");
            this.$router.push("/home");
          }

          if (this.user !== undefined && this.user.role === 3) {
            this.flashError("Cannot view this user details");
            this.$router.push("/home");
          }
        })
        .catch((error) => {
          const manageableError =
            !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => (this.$parent.loading = false));
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/css/colors";

.table__row {
  font-size: 0.9rem;

  h2 {
    color: white;
    font-size: 0.75rem;
    font-weight: bold;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      color: rgba($soft-green, 1);
    }
  }
}

.green {
  color: $soft-green;
}

.orange {
  color: $soft-orange;
}

.main-container {
  background: $neutral--darker;
}

.graph-card {
  background: $neutral--dark;
}

.social__icon {
  color: inherit;
  opacity: .7;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    opacity: 1;
  }
}

.premium-border {
  border: 2px solid gold;

}

.premium-label {
  padding: 0.2em 0.6em 0.3em;
  color: black;
  font-weight: normal;
  font-size: 0.9rem;
  border-radius: 8px;
  background-color: $soft-green;
}

.table__submissions {
  background: $neutral--dark;
  z-index: 10;
}

.loading-spinner {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position:absolute;
  border-radius: 50%;
  border: 8px solid $neutral--darker;
  border-left: 8px solid $soft-green;
  width: 50px;
  height: 50px;
  animation: loading_spinner 0.5s linear forwards infinite;
}

@media screen and (max-width: 500px) {
  .loading-spinner {
    margin: 20px 0px;
  }
}

@keyframes loading_spinner {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}


.minimal-lab-card {
  position: relative;
  background: $neutral--darkest;
}

.lab-difficulty {
  position: absolute;
  top: 8%;
  right: 3%;
  width: 15px;
  height: 15px;
  background: var(--color);
  border-radius: 50%;
  box-shadow: 0px 0px 1px 2px var(--color);
}

.text-glow {
  color: $soft-green;
  text-shadow: white 1px 0 10px;
}

.suggested-lab {
  background: $neutral--darkest;
  transition: all 0.5s ease;
}

.arrow-container {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.arrow-container:hover {
  background: $neutral--dark;
  transition: all 0.5s ease;
}

.arrow {
  border: solid $soft-green;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.progressbar-wrapper {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  // background: red;
  margin: 0;
  padding: 0;
  display: flex;
  // overflow-x: hidden;
  // text-overflow: ellipsis;
}

.progress-bar-wrapper {
  position: relative;
  border-radius: 50%;
  margin: 0px 20px;
  width: 100%;
  height: 100%;
}

.progress-bar {
  width: 100%;
  position: absolute;
  bottom: 10%;
  height: 5%;
  border: 1px solid white;
  background: transparent;
  border-radius: 600px;
}

.progress-width-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.progress-width {
  position: absolute;
  top: 0%;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
  width: var(--_width, 0%);
  border-radius: 600px;
  transition: all 0.5s ease;
  background: linear-gradient(
    to right,
    #3fffa2 0%,
    #ffdb3a 35%,
    #ff993a 60%,
    #e5405e 100%
  );
}

.empty-overlay {
  position: absolute;
  background-color: black;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display:flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  z-index: 10;
  opacity: 0.5;
}

.placeholder-table {
  position: relative;

  div {
    height: 20px;
    width: 100%;
    border-radius: 600px;
    inset: 0;
  }

  .colorize {
    background: $neutral--medium;
    margin: 0px 10px;
  }
}

.latest-submissions {
  max-width: 812px;
  max-height: 468px;
  overflow-y: scroll;
}

</style>
