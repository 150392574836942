<template>
  <div class="container-fluid table vh-100">
    <div class="row">
      <div class="col-2">
        <h1 class="main-title-1 margin-right-20">Badges</h1>
      </div>
      <div class="col-10 px-0 d-flex justify-content-end">
        <div class="col-6 text-right mx-0 px-0 pr-1 col-lg-6">
          <px-button @click.native="goBack()" class="px-button--secondary icon-left outline" icon="left-arrow">
            back
          </px-button>
        </div>
      </div>
    </div>
    <div class="mb-5">
      <px-table>
        <div v-responsive.lg.xl class="row table-header mb-3 d-flex align-items-center justify-content-between mx-1">
          <div class="col-1 h-100 py-3 d-flex align-items-center">
            <h2 class="mb-0">lab</h2>
          </div>
          <div class="col-1 h-100 py-3 d-flex align-items-center">
            <h2 class="mb-0">Total points</h2>
          </div>
          <div class="col-2 h-100 py-3 d-flex align-items-center text-center">
            <h2 class="mb-0">Difficulty</h2>
          </div>
          <div class="col-2 h-100 py-3 d-flex align-items-center text-center">
            <h2 class="mb-0">completed</h2>
          </div>
          <div class="col-2 h-100 py-3 d-flex align-items-center">
            <h2 class="mb-0">actions</h2>
          </div>
        </div>
        <template v-if="badges.length == 0">

          <div class="col-12">No badges</div>

        </template>
        <template v-else>
          <InfiniteScroll ref="infiniteScroll" :items="badges" @refetch="loadBadges">
            <template v-slot:item="badge">
              <div class="row table__row px-3 my-3 my-lg-0 d-flex justify-content-between align-content-center">
                <div v-responsive.xs.sm.md class="container-fluid px-0">
                  <div class="row">
                    <div class="col-10">
                      <div class="row p-3 align-items-center justify-content-between"
                        style="background-color: rgba(255,255,255,.1)">
                        <div class="col-3">
                          <h2 class="mb-0 pointer" @click="$parent.$router.push(`/lab/${badge.item.lab_id}`)">
                            {{ badge.item.lab_name | truncate(25) }}
                          </h2>
                        </div>
                        <div class="col-4 text-right">
                          <p class="mb-0" style="font-size: .6rem">{{ new Date(badge.item.submitted +
    "Z").toLocaleString()
}}</p>
                        </div>
                      </div>
                      <div class="row align-items-center">
                        <div class="col p-4 d-flex flex-column">
                          <p class="mb-1"><b>Points: </b> {{ badge.item.points }}</p>
                          <span class="lab__difficulty--bar" :style="`--value:${badge.item.difficulty}%`">
                            {{ badge.item.difficulty }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-2 d-flex pr-0 flex-column justify-content-around align-items-center">
                      <div @click="linkedinShare(badge.item.completed_id)" class="mr-4 icon-buttons flag-icon d-flex">
                        <i :class="'pointer'" class="fab fa-linkedin action-button action-button--linkedin"></i>
                      </div>
                      <div @click="twitterShare(badge.item.completed_id)" class="mr-4 icon-buttons flag-icon d-flex">
                        <i :class="'pointer'" class="fab fa-twitter action-button action-button--twitter"></i>
                      </div>
                      <div @click="goTo(badge.item.completed_id)" class="mr-4 icon-buttons flag-icon d-flex">
                        <i :class="'pointer'" class="fas fa-external-link-alt action-button action-button--reset"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-responsive.lg.xl class="container-fluid">
                  <div class="row">
                    <div class="col-2 d-flex align-items-center">
                      <div class="row d-flex align-items-center lab__name">
                        <h2 class="mb-0 pointer" @click="$parent.$router.push(`/lab/${badge.item.lab_id}`)">
                          {{ badge.item.lab_name | truncate(20) }}
                        </h2>
                      </div>
                    </div>
                    <div class="col-2 d-flex align-items-center">
                      <px-badge class="px-badge--success"><i class="fas fa-flag mr-1"></i>{{ badge.item.points
}}</px-badge>
                    </div>
                    <div class="col-3 d-flex align-items-center lab__difficulty">
                      <span class="lab__difficulty--bar" :style="`--value:${badge.item.difficulty}%`">
                        {{ badge.item.difficulty }}
                      </span>
                    </div>
                    <div class="col-3 position-relative d-flex align-items-center">
                      <div class="row d-flex align-items-center lab__ip d-flex align-items-center">
                        {{ new Date(badge.item.submitted + "Z").toLocaleString() }}
                      </div>
                    </div>
                    <div class="col-2 position-relative d-flex align-items-center">
                      <div @click="linkedinShare(badge.item.completed_id)" class="mr-4 icon-buttons flag-icon d-flex">
                        <i :class="'pointer'" class="fab fa-linkedin action-button action-button--linkedin"></i>
                        <!--                        <img src="../assets/images/icon-user-flag.svg" alt="user flag">-->
                      </div>
                      <div @click="twitterShare(badge.item.completed_id)" class="mr-4 icon-buttons flag-icon d-flex">
                        <i :class="'pointer'" class="fab fa-twitter action-button action-button--twitter"></i>
                        <!--                        <img src="../assets/images/icon-user-flag.svg" alt="user flag">-->
                      </div>
                      <div @click="goTo(badge.item.completed_id)" class="mr-4 icon-buttons flag-icon d-flex">
                        <i :class="'pointer'" class="fas fa-external-link-alt action-button action-button--reset"></i>
                        <!--                        <img src="../assets/images/icon-user-flag.svg" alt="user flag">-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </InfiniteScroll>
        </template>
      </px-table>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "../assets/css/colors";
@import "../assets/css/variables";

.table__row {
  min-height: 60px;
}

.lab__name {
  h2 {
    font-size: 0.8rem;
    font-weight: bold;
    transition: all 0.2s;

    &:hover {
      color: rgba($soft-green, 1);
    }
  }
}

.action-button {
  color: black;
  padding: 7px;
  border-radius: 50%;
  position: relative;
  transition: all .3s;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    height: 0;
    width: 0;
    transform: translate(-50%, -50%);
    z-index: -1;
    border-radius: 50%;
    transition: all .3s;
    opacity: .5;
  }

  &:not(.action-disabled) {
    &:hover {
      transform: scale(.8);

      &:after {
        height: 160%;
        width: 160%;
      }
    }
  }

  &--flag-1,
  &--flag-1:after {
    background: $soft-green;
  }

  &--flag-2,
  &--flag-2:after {
    background: $soft-orange;
  }

  &--reset,
  &--reset:after {
    color: white;
    font-size: .9rem;
    background: $neutral--real-medium;
  }

  &--linkedin,
  &--linkedin:after {
    background: #1974b5;
    color: white;
  }

  &--twitter,
  &--twitter:after {
    background: #23abdd;
    color: white;
  }
}

.lab__difficulty--bar {
  display: block;
  background: rgba($color: $neutral--medium, $alpha: 0.75);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  width: 100%;
  height: 10px;
  position: relative;
  font-size: 0;

  &:after {
    content: "";
    clip-path: inset(0px calc(100% - var(--value)) 0px 0px round 15px);
    border-radius: 10px 10px 10px 10px;
    -webkit-border-radius: 10px 10px 10px 10px;
    -moz-border-radius: 10px 10px 10px 10px;
    width: 100%;
    left: 0;
    position: absolute;
    height: 10px;
    background: #e5405e;
    background: linear-gradient(to right,
        #3fffa2 0%,
        #ffdb3a 35%,
        #ff993a 60%,
        #e5405e 100%);
  }
}
</style>

<script>
import labService from "../api/lab";
import InfiniteScroll from "../components/InfiniteScroll";
import PxButton from "@/components/PxButton";
import PxTable from "@/components/PxTable";
import PxBadge from "@/components/PxBadge";

export default {
  name: "Badges",
  components: {
    InfiniteScroll,
    PxButton,
    PxTable,
    PxBadge
  },
  data() {
    return {
      badges: [],
      lastPage: false
    }
  },
  methods: {
    goTo(completed_lab_id) {
      window.open(process.env.VUE_APP_API_URL + `/badge?completion_id=${completed_lab_id}`, "_blank");
    },
    loadBadges(page) {
      if (this.lastPage) return
      labService.getBadges(page).then(response => {
        if (!response.data.badges.length) {
          this.lastPage = true
          return
        }
        this.badges.push(...response.data.badges);
      }).finally(() => {
        this.$parent.loading = false;
      })
    },
    download(badge_url) {
      window.open(badge_url, "_blank");
    }
  },
  beforeCreate() {
    this.$parent.breadcrumbs = [{ name: "badges" }];
  },
  created() {
    this.$parent.loading = false;
    this.page = 1;
    this.loadBadges();
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";

.table__row {
  font-size: 0.9rem;

  h2 {
    color: white;
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: bold;
    transition: all 0.2s;

    &:hover {
      color: rgba($soft-green, 1);
    }
  }
}
</style>
