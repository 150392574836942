<template>
  <div class="event-details__main">
    <div class="row">
      <div class="eventimage__col col-12 col-md-3 px-0">
        <div id="eventimage" class="h-100 solid-bg d-flex align-items-center justify-content-end mb-3 px-0 event__icon"
          :style="{
            backgroundImage:
              this.event.sponsor && this.event.sponsor_image
                ? `url(${this.event.sponsor_image})`
                : `url('http://picsum.photos/500')`,
          }">
          <p href="" v-if="shouldShowStartButton()" @click="manageStartEvent(true)"
            class="pointer mb-0 mr-n4 join-button d-flex align-items-center justify-content-center"
            :class="[event.type !== undefined ? event.type.toLowerCase() : '']">
            start
          </p>
          <p href="" v-if="this.user.data !== undefined &&
            this.user.data.user.role !== 3 &&
            this.isNormalEvent &&
            !this.isOnGoing &&
            this.isOverdue &&
            this.isParticipating
          " @click="openOverlay('isJoining')"
            class="pointer mb-0 mr-n4 join-button d-flex align-items-center justify-content-center" :class="[
              event.type !== undefined ? event.type.toLowerCase() : '',
              this.canApplyTeam || this.canApplyUser ? '' : 'disabled',
            ]">
            {{
              this.canApplyTeam || (this.canApplyUser && this.isStarted)
                ? "join"
                : "joined"
            }}
          </p>
        </div>
      </div>
      <div class="col-12 my-3 my-md-0 col-md-5">
        <div class="d-flex align-items-center flex-row w-100">
          <div :class="{ 'mb-5': this.isOverdue }" class="position-relative">
            <h2>Event: {{ event.name }}</h2>
            <div class="mb-5 antenna-container-absolute" v-if="this.isOnGoing && this.isParticipating">
              <div class="antenna-container-wrapper">
                <i v-if="isValidBannerText" @click="showEventBannerText()"
                  :class="{ 'animate': shouldAnimateBannerTextBtn }"
                  class="antenna-bg fa-solid fa-tower-broadcast p-2 rounded-circle pointer align-items-center"></i>
              </div>
            </div>
          </div>
        </div>
        <p class="mb-5" v-if="!this.isOverdue">
          {{ this.isParticipating ? "Participating" : "Spectating" }}
        </p>
        <div class="row event-stats">
          <div class="col-12">
            <div class="d-flex flex-row justify-content-start align-items-center">
              <div class="mr-5">
                <h2>Points</h2>
                <p>{{ event.points }}</p>
              </div>
              <div class="ml-3">
                <h2>Players</h2>
                <p>{{ event.players }}</p>
              </div>
            </div>
          </div>
          <div class="col-12 py-3">
            <h2>start time</h2>
            <p>{{ eventDateStart }}</p>
          </div>
          <div class="col-12">
            <h2>End time</h2>
            <p>{{ eventDateEnd }}</p>
          </div>
        </div>
      </div>
      <div class="col-4 col-md-4 my-3 my-md-0  pr-0 event-stats">
        <div class="col-12 d-flex justify-content-between">
          <px-badge v-if="event.type !== undefined" :class="`px-badge--${event.type.toLowerCase()}`"
            class="outline mr-3">
            {{ event.type.toLowerCase() }}
          </px-badge>
          <px-badge v-if="event.type !== undefined" :class="`px-badge--${event.status.toLowerCase()}`" class="outline">
            {{ event.status.toLowerCase() }}
          </px-badge>
        </div>
        <div class="col-12 d-flex justify-content-between" v-if="shouldSeeHrpRecruiterBtns() && isValidJob()">
          <px-button class="w-100 h-50 px-button--orange mt-2" @click.native="redirectToApplications">
            <i class="fa-solid fa-briefcase"></i>job applications <div v-if="eventJob.applications > 0"
              class="rounded-circle px-2 mx-3 applications-count"> {{ eventJob.applications }}</div>
          </px-button>
        </div>
        <div class="col-12 d-flex justify-content-between" v-if="shouldSeeHrpRecruiterBtns() || shouldSeeHrpUserBtns()">
          <px-button class="w-100 h-50 px-button--orange mt-2" @click.native="redirectToInvite">
            <i class="fas fa-users"></i>invite users
          </px-button>
        </div>
        <div class="col-12 my-3 my-md-0 text-right d-flex mt-4 d-flex justify-content-end">
          <div v-if="!this.isStarted && !this.isOverdue && !this.isOnGoing">
            <h2>Starting in</h2>
            <p class="time-counter mt-n2">{{ countdown }}</p>
          </div>
        </div>
        <div class="col-12 text-right">
          <div v-if="allowedToJoinNormalEvent()">
            <px-button @click.native="applyUser" class="px-button--orange w-100">join event</px-button>
          </div>
        </div>
      </div>
    </div>
    <div class="position-relative row mt-3 w-100 h-100" style="max-height: 450px">

      <div id="event-leaderboard" class="col-md-6 p-0 m-0 bg-dark my-3 my-md-0 w-100 mx-md-0 h-100"
        style="max-height: 450px;overflow-y:scroll">

        <div v-if="ranks.length > 0" class="w-100 h-100" :style="{
          maxHeight: this.isOnGoing || this.isOverdue ? 95 + '%' : 75 + '%',
          top: this.isOnGoing || this.isOverdue ? 0 + '%' : 20 + '%',
        }">
          <event-leaderboard class="" v-for="(rank, index) in ranks" :key="index" :leadData="rank" :position="index + 1"
            @reload-chart="reloadChart"></event-leaderboard>
        </div>
        <div class="col w-100 h-100" v-else>
          <h2>no user has registered yet</h2>
        </div>
      </div>
      <div id="event-leaderboard" class="col-md-6 w-100 h-100">
        <div class="px-0 d-flex align-items-center justify-content-center position-relative w-100 h-100">
          <h2 v-if="this.graphPlaceholder"
            class="d-flex align-items-center justify-content-center position-absolute graph-placeholder w-100 h-100">
            No data has been collected {{ !this.isOverdue ? "yet" : "" }}
          </h2>
          <v-chart v-if="!this.isPurpleEvent" :options="leadChart" class="p-3" autoresize></v-chart>
        </div>
      </div>
    </div>
    <!-- <div class="row mt-3">
      
    </div> -->
    <div class="overlay" v-if="this.user.data !== undefined &&
      this.user.data.user.role !== 3 &&
      this.overlayOpen &&
      !this.isParticipating
    ">
      <div class="container-fluid h-100 d-flex align-items-center justify-content-center">
        <div class="row text-center d-flex flex-column align-items-center"
          v-if="this.isOnGoing && !this.isJoining && this.isNormalEvent && !this.isExtern">
          <div class="col mb-5 d-flex align-items-center">
            <h2>{{ event.name }} is started</h2>
          </div>
          <div class="col mb-3">
            <px-button class="px-button--secondary outline w-100" @click.native="closeOverlay()">spectate</px-button>
          </div>
          <div class="col">
            <px-button v-if="this.isParticipating" class="px-button--success w-100 mb-2"
              @click.native="manageStartEvent(true)">join event
            </px-button>
            <px-button v-if="!this.isParticipating && this.isPrivate"
              class="px-button--success w-100 mb-2 disabled">private event
            </px-button>
          </div>
          <div class="col-8">
            <p class="disclaimer" v-if="this.isParticipating">
              by participating you are sharing your email address with the event
              owner
            </p>
          </div>
        </div>
        <div class="row text-center d-flex flex-column align-items-center"
          v-if="this.isOnGoing && !this.isJoining && !this.isNormalEvent && !this.isExtern">
          <div class="col mb-2">
            <h2>{{ event.name }} is started</h2>
            <p>You can only spectate this event now</p>
          </div>
          <div class="col mb-3">
            <px-button class="px-button--secondary outline w-100" @click.native="closeOverlay()">spectate</px-button>
          </div>
        </div>
        <div class="row text-center d-flex flex-column" v-if="!this.isJoining && !this.isNormalEvent">
          <div class="col-12">
            <h2 class="mb-0">the game is starting soon</h2>
          </div>
          <div class=" col-12 mt-5">
            <px-button class="px-button--secondary mb-2 w-100" :class="{ disabled: !this.canApplyUser }"
              v-if="this.isScheduled && this.canApplyUser" @click.native="this.isJoining = false">join as user
            </px-button>
            <px-button class="px-button--primary mb-2 w-100"
              v-if="this.isScheduled && !this.isPrivate && !this.isExtern && this.canApplyTeam && this.$parent.user.team"
              @click.native="applyTeam">
              join the event
            </px-button>
            <px-button class="px-button--orange outline mb-2 w-100"
              v-if="!this.isPrivate && this.isExtern && this.canApplyTeam && !this.canApplyUser && this.$parent.user.team"
              @click.native="applyExternTeam">
              join with ctftime
              <img src="../assets/images/ctftime-favicon.png" alt="CTFTIME" class="mx-2">
              <i class="ml-3 mr-0 fa-solid fa-lock" v-if="this.isWhitelisted"></i>
            </px-button>
            <px-button class="mb-2 w-100 px-button--primary"
              v-if="!this.canApplyTeam && !this.$parent.user.team && !this.isExtern" @click.native="openCreateTeamModal"
              id="create-team-btn">
            </px-button>
            <px-button class="px-button--orange outline mb-2 w-100"
              v-if="!this.isPrivate && this.isExtern && !this.canApplyTeam" @click.native="applyExternTeam">
              join with ctftime
              <img src="../assets/images/ctftime-favicon.png" alt="CTFTIME" class="mx-2">
              <i class="ml-3 mr-0 fa-solid fa-lock" v-if="this.isWhitelisted"></i>
            </px-button>
            <px-button v-if="this.isScheduled && !this.isRedEvent" class="px-button--secondary outline w-100"
              @click.native="closeOverlay()">spectate
            </px-button>
          </div>
        </div>
        <div class="row text-center d-flex flex-column" v-if="this.isScheduled &&
          !this.isJoining &&
          this.isNormalEvent &&
          !this.isOnGoing
        ">
          <div class="col-12">
            <h2 class="mb-2">the game is starting soon</h2>
            <p>Wait until {{ this.event.dateStart }} to join</p>
          </div>
        </div>
        <div class="row d-flex flex-column text-center" v-if="this.isJoining && this.isOnGoing">
          <div class="col px-5">
            <h2>Join {{ event.name }}</h2>
            <p></p>
          </div>
          <div class="col">
            <px-button class="px-button--secondary mb-2 w-100" :class="{ disabled: !this.canApplyUser }"
              @click.native="closeOverlay">join as user
            </px-button>
          </div>
          <div class="col mb-2">
            <px-button class="px-button--primary mb-2 w-100" :class="{ disabled: !this.canApplyTeam }"
              @click.native="applyTeam">
              join as team
            </px-button>
          </div>
        </div>
        <div class="row overlay__footer position-absolute" style="bottom:5%">
          <px-button class="px-button--secondary outline w-100" @click.native="closeOverlay()">close</px-button>
        </div>
      </div>
    </div>
    <div v-if="this.isPurpleEvent" class="container-fluid mt-5">
      <div class="row">
        <div class="col p-4 solid-bg d-flex align-items-center justify-content-center">
          <h2 v-if="this.graphPlaceholder" class="position-absolute mb-0">
            No data has been collected yet
          </h2>
          <v-chart :options="leadChart" autoresize class="graphs"
            :class="{ 'graphs-placeholder': this.graphPlaceholder }"></v-chart>
        </div>
      </div>
    </div>
    <div class="container-fluid mt-5 solid-bg current-team-labs__container"
      v-if="!this.isNormalEvent && this.isParticipating">
      <div class="container-fluid p-3" v-if="this.isPurpleEvent">
        <h2 class="mt-2 mb-4">Your team machines</h2>
        <div class="row" v-if="this.labs.length === 0">
          <p>
            Wait until the game start to see your team's machines information
          </p>
        </div>
        <div class="row py-3 d-flex justify-content-between current-team-labs" v-for="(lab, index) of this.labs"
          :key="index">
          <div class="col-5">
            <div class="row d-flex align-items-center">
              <div class="col">
                <h2 class="mb-0">
                  {{ lab.name }}
                </h2>
              </div>
              <div class="col">
                <p class="mb-0">
                  {{ lab.ip }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row d-flex align-items-center">
              <div class="col text-right">
                <p @click="getLabKeys(lab)" class="mb-0 underline-hover pointer">
                  download key<i class="fas ml-2 fa-key"></i>
                </p>
              </div>
              <div class="col text-right">
                <p class="mb-0 underline-hover pointer">
                  reset<i class="fas fa-undo ml-2"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col mt-5 game-stats" v-if="this.isParticipating && this.isOnGoing">
      <div class="row solid-bg px-0 d-flex justify-content-between">
        <div class="col-12 col-xl-6 p-4">
          <div class="col-12">
            <div class="container-fluid px-0" id="submissions">
              <h2>submissions</h2>
              <div class="row submissions--header">
                <div class="col-2 text-center pl-0">from start</div>
                <div class="col-2" v-if="!this.isBlueEvent && !this.isRedEvent"></div>
                <div :class="[this.isNormalEvent ? 'col-5' : 'col-4']" class="text-center">
                  lab
                </div>
                <div class="col-4 text-center" v-if="this.isBlueEvent || this.isRedEvent">
                  username
                </div>
                <div class="col-1 text-center" v-if="this.isRedEvent">
                  team
                </div>
                <div :class="{
                  'col-1': this.isRedEvent,
                  'col-3': !this.isRedEvent,
                }" class="text-center">
                  points
                </div>
              </div>
              <div class="row">
                <div class="col submissions--container px-0">
                  <p v-if="this.eventLabs.flag">
                    no flag has been submitted yet
                  </p>
                  <!-- <template v-if="!this.isRedEvent">
                    <submission-log
                      v-for="lab in this.eventLabs"
                      :lab="lab"
                      :key="lab.id"
                    ></submission-log>
                  </template>
<template v-if="this.isRedEvent"> -->
                  <submission-log></submission-log>
                  <!-- </template> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :class="{ 'col-xl-6': this.isPurpleEvent }"
          class="col-12 col-xl-6 p-3 d-flex flex-column game-stats--container">
          <div class="row">
            <div class="col">
              <h2 v-if="!this.isBlueEvent && this.requireLabs">Submit flag</h2>
              <h2 v-if="this.isBlueEvent">Countdown</h2>
            </div>
          </div>
          <div class="row my-4" :style="getBlueStyling()">
            <div v-if="!this.isBlueEvent && this.requireLabs" class="col-6 d-flex align-items-center" id="flag-submit">
              <div class="row d-flex align-items-center">
                <div class="col">
                  <input :disabled="!this.isStarted" id="purple-submit" class="lab__flag-input w-100"
                    v-model="flagString" type="text" :placeholder="[
                      this.isStarted
                        ? 'submit flag'
                        : 'start the event to submit',
                    ]" :class="{
                      purple: this.isPurpleEvent,
                      red: isRedEvent,
                    }" />
                </div>
                <div class="col-2 pl-0">
                  <i class="lab__flag-submit d-flex align-items-center justify-content-center fas fa-flag pointer"
                    :class="{
                      purple: this.isPurpleEvent,
                      red: isRedEvent,
                    }" v-if="this.isStarted" @click="submitFlagPurple()"></i>
                </div>
              </div>
            </div>
            <div class="end-countdown--container" :class="[requireLabs ? 'col-5' : 'col-12']">
              <div class="row" :style="[
                this.isBlueEvent ? 'display:flex;justify-content:end;' : '',
              ]">
                <div class="col" :class="[requireLabs ? 'text-right' : 'text-center']">
                  <h2 class="mb-0">
                    <span v-if="event.type == 'GREEN'">ticket</span> time left
                  </h2>
                </div>
              </div>
              <div class="row">
                <div class="col" :class="[requireLabs ? 'text-right' : 'text-center']">
                  <p class="pb-0 mb-0 end-countdown" :style="{ 'font-size:40px;': !requireLabs }">
                    {{ this.countdownEnd }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isStarted" class="row" id="vpn-button">
            <div @click="downloadOvpnFile" class="col-sm-6 d-flex align-items-center" v-if="requireLabs">
              <div class="download-icon">
                <i class="fas fa-download" aria-hidden="true" :style="{ 'text-color': 'white' }"></i>
              </div>
              <div class="col ml-0 pl-0">
                <p class="mb-0 orange">download your vpn configuration</p>
              </div>
            </div>
            <div v-if="shouldShowMessageBox() && requireLabs"
              class="col-sm-6 d-flex align-items-center justify-content-center bg-dark p-3 m-0 messages-box">
              <h2 class="m-0 p-0 relative-message">
                Any reverse shell / callback needs to be done via external services
                <div class="blinking-cursor"></div>
              </h2>
            </div>
            <div class="row m-0 p-0 col-sm-12 bg-dark">
              <div class="d-flex justify-content-end w-100 col-sm-6" v-if="!requireLabs">
                <px-button class="px-button--success w-100 my-2" @click.native="startQuiz">start quiz</px-button>
              </div>
              <div v-if="this.isGreenEvent" class="d-flex justify-content-end my-2 w-100"
                :class="[requireLabs ? 'col-sm-12' : 'col-sm-6']">
                <px-button class="px-button--danger outline w-100" :class="{ disabled: userWantsToLeave }"
                  @click.native="leaveEvent">Leave Event</px-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid solid-bg mt-5">
        <div class="row">
          <purple-event-teams :currentTeam="this.$parent.user.team" :teams="this.teams"></purple-event-teams>
        </div>
      </div>
      <div class="row mx-0 w-100 my-2 px-0 event-row--container" v-if="!this.isPurpleEvent">
        <div class="w-100">
          <div class="col-12 px-0 m-0 w-100">
            <template v-if="!this.isBlueEvent">
              <event-row @getLabIp="getLabIp" class="mb-3" v-for="(lab, index) in eventLabs" :key="index" :labData="lab"
                :labId="lab.id" :eventData="event" @downloadKey="getLabKeys" @runDoberman="runDoberman"
                @submittedFlag="fetchStatistics" @askReset="performResetAction" :labNote="labNote(lab.id)"></event-row>
            </template>
            <template v-if="this.isBlueEvent">
              <event-row @getLabIp="getLabIp" class="mb-3" v-for="(lab, index) in eventLabs" :key="lab.id + index"
                :labData="lab" :labId="lab.id" :eventData="event" @downloadKey="getLabKeys" @runDoberman="runDoberman"
                @submittedFlag="fetchStatistics" @askReset="performResetAction" :labNote="labNote(lab.id)"></event-row>
            </template>
          </div>
        </div>
      </div>
      <div class="notes-container" v-if="shouldSeeNotes()">
        <h3>NOTES</h3>
        <PxTextArea :initialFunction="getUserNotes" :saveText="(t) => (notes = t)" />
        <px-button :disable="saving" :class="`px-button--${this.saving ? 'secondary outline' : 'success'}`"
          @click.native="saveUserNotes">
          <template v-if="!saving">
            <span>SAVE</span>
          </template>
          <template v-else>
            <svg class="saving-notes" viewBox="-75 -75 250 250" xmlns="http://www.w3.org/2000/svg">
              <circle cx="50" cy="50" r="30" fill="transparent" stroke-width="8px" stroke-dasharray="160" />
            </svg>
          </template>
        </px-button>
        <span class="saving-text" v-if="saving">{{ savingText }}</span>
      </div>
      <div id="event-end" class="d-flex flex-column justify-content-center align-items-center" v-if="eventTerminated">
        <h1 class="fw-bold">{{ eventEndMessage }}</h1>
        <px-button class="px-button--secondary outline container" :style="{ width: 500 + 'px' }"
          @click.native="closeEventTerminatedOverlay">close</px-button>
      </div>
      <div v-if="pageReloading" id="redirecting-overlay"></div>
    </div>
    <template v-if="isFirstblood">
      <div v-if="isFirstblood" id="firstblood-overlay"
        class="d-flex flex-column justify-content-center align-items-center">
        <h1>🩸</h1>
        <h1>FIRSTBLOOD</h1>
        <h2>{{ filteredFirstblood.username }}</h2>
        <h2 v-if="this.isRedEvent">{{ filteredFirstblood.team }}</h2>
        <h2>{{ filteredFirstblood.submission_date }}</h2>
        <px-button class="px-button--secondary outline container" :style="{ width: 500 + 'px' }"
          @click.native="closeFirstbloodOverlay">close</px-button>
      </div>
    </template>
  </div>
</template>

<script>
// import labService from "../api/lab";
import ECharts from "vue-echarts";
import { mapState } from "vuex";
import eventService from "../api/event";
import httpService from "../api/http";
import reportService from "../api/report";
import FlagSubmissionModal from "../components/FlagSubmissionModal";
import PxBadge from "../components/PxBadge";
import PxButton from "../components/PxButton";
import ReportModal from "../components/ReportModal";
import StartEventModal from "../components/StartEventModal";
// import PxCard from "../components/cards/PxCard";
import labService from "../api/lab";
import userService from "../api/user";
import EventCreateTeamModal from "../components/EventCreateTeamModal";
import EventLeaderboard from "../components/EventLeaderboard";
import EventRow from "../components/EventRow";
import EventSubmissionsLog from "../components/EventSubmissionsLog";
import PurpleEventTeams from "../components/PurpleEventTeams";
import PxTextArea from "../components/PxTextArea.vue";
import RecruiterUserNotes from "../components/RecruiterUserNotes";
import { EventStatus, EventType, EventsDataType } from "../enums/event.js";
import UserType from "../enums/user.js";
import EventQuizModal from "@/components/EventQuizModal";
import EventBannerTextModal from "@/components/EventBannerTextModal";

export default {
  name: "EventDetails",
  computed: {
    eventDateStart() {
      return this.event.dateStart;
    },
    isValidBannerText() {
      return this.bannerText !== null;
    },
    eventDateEnd() {
      return this.event.dateEnd;
    },
    ...mapState({
      userData: (state) => state.user,
    }),
  },
  components: {
    PxButton,
    PxBadge,
    "v-chart": ECharts,
    // "px-card": PxCard,
    "event-row": EventRow,
    EventLeaderboard,
    "submission-log": EventSubmissionsLog,
    "purple-event-teams": PurpleEventTeams,
    PxTextArea,
  },
  data() {
    return {
      notes: "",
      bannerText: null,
      labNotes: {},
      shouldAnimateBannerTextBtn: false,
      eventJob: null,
      dataType: null,
      companyContract: null,
      requireLabs: true,
      initialNotes: "",
      isNormalUser: true,
      saving: false,
      chartUserId: null,
      savingText: "Retrieving your notes...",
      eventTerminated: false,
      eventEndMessage: "",
      userId: this.$route.query.userId,
      eventId: this.$route.params.eventId,
      dobermannIcon: null,
      userWantsToLeave: false,
      isHrpRecruiter: false,
      user: {},
      event: {
        name: "",
        points: 0,
        players: 0,
        status: "",
        dateStart: null,
        dateEnd: null,
        mlService: false,
      },
      eventLabs: [],
      eventStatusClass: {
        overdue: "red",
        ongoing: "green",
        scheduled: "blue",
      },
      canApplyTeam: false,
      canApplyUser: false,
      canDownloadKeys: false,
      ranks: [],
      leadChart: {
        tooltip: { trigger: "axis" },
        legend: {
          data: [],
          textStyle: {
            color: "#768aa3",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          show: true,
          type: "category",
          boundaryGap: false,
          data: [],
          axisLine: {
            lineStyle: {
              color: "#768aa3",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#768aa3",
            },
          },
          splitLine: {
            lineStyle: {
              color: "#555",
              type: "dotted",
            },
          },
        },
        series: [],
      },
      initNetTopChart: {
        legend: {
          data: [],
          textStyle: {
            color: "#768aa3",
          },
        },
        animationDurationUpdate: 1500,
        animationEasingUpdate: "quinticInOut",
        series: [
          {
            type: "graph",
            layout: "circular",
            data: [],
            links: [],
            categories: [],
            roam: true,
            label: {
              show: true,
            },
            lineStyle: {
              opacity: 0.9,
              width: 2,
              curveness: 0,
            },
            symbolSize: 50,
            edgeSymbol: ["circle"],
            edgeSymbolSize: [4, 10],
            edgeLabel: {
              fontSize: 20,
            },
          },
        ],
      },
      netTopChart: {},
      teams: [],
      labs: [],
      intervalId: null,
      teamLabsFilter: {
        lab: "",
        team: "",
      },
      filteredLabs: [],
      showLabsFilter: false,
      isRedAndBlueEvent: false,
      isPurpleEvent: false,
      isBlueEvent: false,
      isNormalEvent: false,
      isRedEvent: false,
      isGreenEvent: false,
      isScheduled: false,
      isStarted: false,
      isOverdue: false,
      isOnGoing: false,
      canStart: false,
      canGetReport: false,
      isExtern: false,
      isWhitelisted: false,
      isPrivate: false, // check
      labReloadInterval: null,
      labDobermanChecked: false,
      labDobermanRunning: false,
      labDobermanPassed: false,
      showLabIp: false,
      showSubmitFlag: false,
      showAskReset: false,
      showConnectionKey: false,
      isParticipating: false,
      showTopologyChart: false,
      showTeamEventFlags: false,
      overlayOpen: false,
      overlayContext: null,
      flagString: null,
      isJoining: false,
      isRunningCounter: false,
      countdownTimestamp: null,
      countdownEndTimestamp: null,
      countdown: null,
      countdownEnd: null,
      pageReloading: false,
      totalPoints: 120,
      testSubmissions: [
        {
          placeholder: true,
        },
        {
          placeholder: true,
        },
        {
          placeholder: true,
        },
        {
          placeholder: true,
        },
        {
          placeholder: true,
        },
      ],
      graphPlaceholder: false,
      loadStatsInterval: null,
      firstbloodInterval: null,
      firstbloodData: [],
      filteredFirstblood: [],
      firstbloodIndex: 0,
      isFirstblood: false,
      eventTour: null,
      eventTourSteps: [],
      isMouseOverJoinBtn: false,
      countdownStartCheckValid: null,
      saveNotesTimeout: null,
    };
  },
  beforeCreate() {
    this.$parent.breadcrumbs = [{ name: "events", navigation: "/events" }];
  },
  created() {
    this.isNormalUser = this.userData.role === UserType.USER;
    this.getAllData();
    if (this.shouldSeeNotes()) this.getUserNotes();
    this.$on("show-user-notes", this.openRecruiterNotesModal);
  },
  mounted() {
    const interval = 60000;
    this.intervalId = setInterval(this.handleRanks, interval);
    this.labReloadInterval = setInterval(this.handleLabs, interval);
    this.loadStatsInterval = setInterval(this.handleStatus, interval);
    this.firstbloodInterval = setInterval(
      this.handleFirstblood,
      interval / 2
    );
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
    clearInterval(this.labReloadInterval);
    clearInterval(this.loadStatsInterval);
    clearInterval(this.firstbloodInterval);
  },
  methods: {
    labNote(labId) {
      if (!this.labNotes) return null;
      const keys = Object.keys(this.labNotes);
      if (keys.length === 0) return null;
      const notes = this.labNotes[labId];
      return notes;
    },
    async oauth2FlowCheck() {
      const eventId = this.event.id;
      const flow = await eventService.oauth2FlowCheck(eventId)
        .then(resp => resp.data)
      return flow.flow;
    },
    async applyExternTeam() {
      const eventId = this.event.id;
      const url = await eventService.joinEventExtern(eventId)
        .then((response) => response.data.url)
        .catch((error) => {
          const manageableError = !!error.data && !!error.data.errorDescription;
          if (manageableError) this.flashSuccess(error.data.errorDescription);
        })

      if (url === undefined || url === null) return;
      window.location = url;
    },

    /** @param {string} labId */
    async getLabIp(labId) {
      this.$parent.loading = true;

      const eventId = this.eventId;
      const ip = await eventService.getEventLabData(eventId, labId)
        .then((response) => response.data.lab.ip).finally(() => {
          this.$parent.loading = false
        })

      if (!ip) {
        this.flashError("IP Not available yet, please try again later");
        return
      }

      this.copyToClipboard(ip);
      this.handleLabs()
    },
    copyToClipboard(ip) {
      if (!this.isOnGoing || !this.isParticipating) return;
      navigator.clipboard.writeText(ip);
      this.flashSuccess(`${ip} copied to clipboard`);
    },
    getBannerTextCheckedValue() {
      let lsData = localStorage.getItem(this.eventId)
      if (!lsData) return true;
      lsData = JSON.parse(lsData);
      let isChecked = lsData["banner"] || { checked: false }
      return !isChecked["checked"]
    },
    async showEventBannerText() {
      let bannerText = this.bannerText;
      if (!bannerText) return;

      this.$modal.show(
        EventBannerTextModal,
        {
          bannerText: bannerText.text,
          title: bannerText.title,
          closeCallback: () => { // Set localStorage to disable icon animation
            let lsData = localStorage.getItem(this.eventId);
            if (!lsData) {
              this.shouldAnimateBannerTextBtn = false;
              lsData = {
                banner: {
                  checked: true
                }
              };
              localStorage.setItem(this.eventId, JSON.stringify(lsData));
              return;
            }
            lsData = JSON.parse(lsData);
            lsData["banner"] = { checked: true };
            localStorage.setItem(this.eventId, JSON.stringify(lsData));
            this.shouldAnimateBannerTextBtn = false;
          }
        },
        { height: "auto" }
      )
    },
    createTeamAndGoToJoin() {
      const eventId = this.eventId;
      this.$modal.show(
        EventCreateTeamModal,
        {
          eventId: eventId,
          setCanApply: () => {
            this.canApplyTeam = false;
            this.canApplyUser = false;
          },
          allDone: () => {
            this.flashSuccess(
              "Team successfully created, redirecting to join page"
            );
            setTimeout(() => this.$router.push(["/event", eventId, "join"].join("/")), 3000);
          },
        },
        { height: "auto" }
      );
    },
    allowedToJoinNormalEvent() {
      if (!this.isNormalUser) return false;
      if (!this.isNormalEvent) return false;
      if (!this.isScheduled) return false;
      if (this.isPrivate) return false;
      if (this.isParticipating) return false;
      if (this.dataType != EventsDataType.NO_DATA) return false;
      return true;
    },
    shouldShowMessageBox() {
      const isParticipating = this.isParticipating;
      const isOnGoing = this.isOnGoing;
      const isStarted = this.isStarted;
      if (!isParticipating) return false;
      if (!isOnGoing) return false
      if (!isStarted) return false;
      return true;
    },
    async startQuiz() {
      const requireLabs = this.requireLabs;
      if (requireLabs) return;
      const eventId = this.eventId;
      this.$parent.loading = true;
      const examQuiz = await eventService.getEventQuiz(eventId)
        .then((response) => {
          const examQuiz = response.data.quiz;
          return examQuiz;
        })
        .catch((error) => {
          const manageableError = !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashSuccess(error.data.errorDescription);
            return
          }
        })
        .finally(() => {
          this.$parent.loading = false;
        }) || []
      const name = this.event.name;
      const endTimestamp = this.countdownEndTimestamp;
      this.$modal.show(
        EventQuizModal,
        {
          questions: examQuiz,
          evName: name,
          endTimestamp: function () { return endTimestamp },
          completeCallback: this.leaveEvent,
          eventId: eventId
        },
        { height: 'auto', width: '80%' }
      )
    },
    isValidJob() {
      return this.eventJob !== null;
    },
    redirectToApplications() {
      const job = this.eventJob;
      this.$router.push(["/jobs", job.id, "applications"].join("/"))
    },
    shouldSeeHrpUserBtns() {
      const allowedRoles = [UserType.HRPLUS, UserType.ENTERPRISE];
      const isHrp = allowedRoles.some(role => role === this.userData.role);
      const isAllowed = this.userData.company_id == this.event.company_id

      if (!isHrp) return false;
      if (!isAllowed) return false;
      return true;
    },
    shouldSeeHrpRecruiterBtns() {
      // this.isHrpRecruiter && this.isOnGoing && this.isGreenEvent

      const isHrpRecruiter = this.isHrpRecruiter;
      const inCompany = this.event.company_id == this.userData.company_id;
      const isEventAllowed = this.isGreenEvent || this.isNormalEvent;
      const isOngoing = this.isOnGoing || this.isScheduled && this.isNormalEvent;

      return inCompany && isHrpRecruiter && isEventAllowed && isOngoing
    },
    reloadChart(userId) {
      this.chartUserId = userId;
      this.getRankChart()
    },
    closeFirstbloodOverlay() {
      this.isFirstblood = false;
    },
    closeEventTerminatedOverlay() {
      this.eventTerminated = false;
    },
    manageMouseOverJoinBtn() {
      this.isMouseOverJoinBtn = true;
    },
    resetMouseOverJoinBtn() {
      this.isMouseOverJoinBtn = false;
    },
    redirectToInvite() {
      const eventId = this.eventId;
      this.$router.push(["/event", eventId, "tickets"].join("/"));
    },
    shouldShowStartButton() {
      // this.user.data !== undefined && this.user.data.user.role !== 3) && !this.isStarted && !this.isOverdue && this.isParticipating && this.isPrivate
      if (!this.isPrivate) return false;
      if (this.isStarted) return false;
      if (!this.isOnGoing || this.isOverdue) return false;
      if (this.user.data !== undefined && this.user.data.user.role === 3)
        return false;
      if (!this.isParticipating) return false;
      return true;
    },
    leaveEvent() {
      this.userWantsToLeave = true;
      eventService
        .leaveEvent(this.eventId)
        .then((response) => {
          const manageableResponse = !!response.data.message;
          if (manageableResponse) {
            this.flashSuccess(response.data.message);
            window.location.reload();
          }
        })
        .catch((error) => {
          const manageableError =
            !!error.data.errorCode && !!error.data.errorDescription;
          if (manageableError) this.flashError(error.data.errorDescription);
        });
    },
    openRecruiterNotesModal({ userId, username }) {
      const eventId = this.eventId;
      this.$modal.show(
        RecruiterUserNotes,
        {
          eventId: eventId,
          userId: userId,
          username: username,
        },
        { height: "auto" }
      );
    },
    shouldSeeNotes() {
      if (!this.isOnGoing) return false;
      if (!this.isParticipating) return false;
      if (!this.isStarted) return false;
      if (!this.isNormalEvent && !this.isGreenEvent) return false;
      if (!this.requireLabs) return false;
      return true;
    },
    async getUserNotes() {
      this.saving = true;
      let { notes } = await eventService
        .getUserNotes(this.eventId)
        .then((response) => response.data)
        .catch((error) => {
          const manageableError =
            !!error.data.errorCode && !!error.data.errorDescription;
          if (manageableError) this.flashError(error.data.errorDescription);
        })
        .finally(() => {
          this.saving = false;
          this.savingText = "Saving...";
        });
      return notes;
    },
    saveUserNotes() {
      clearTimeout(this.saveNotesTimeout);
      this.saving = true;
      eventService
        .saveUserNotes(this.eventId, this.notes)
        .then((response) => {
          const { success, message } = response.data;
          if (!message) return;
          if (success) return this.flasSuccess(message);
        })
        .catch((response) => {
          const { success, message } = response.data;
          if (!message) return;
          if (!success) return this.flashError(message);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    openCreateTeamModal() {
      this.$modal.show(
        EventCreateTeamModal,
        {
          eventId: this.eventId,
          setCanApply: () => {
            this.canApplyTeam = false;
            this.canApplyUser = false;
          },
          allDone: () => {
            this.flashSuccess(
              "Successfully created a team and joined the event"
            );
            setTimeout(() => window.location.reload(), 3000);
          },
        },
        { height: "auto" }
      );
    },
    async getAllData() {
      this.$parent.loading = true;
      const initialRequests = [userService.getCurrentUser()];
      const requests = [];
      // const requests = [
      //   eventService.getEventLabs(this.eventId)
      // ]
      return await httpService
        .all(initialRequests)
        .then(([user]) => {
          this.user = user;
          const isHrpRecruiter = this.user.data.user.hrp_recruiter;

          if (isHrpRecruiter) {
            this.isHrpRecruiter = true;
          }

          requests.unshift(eventService.getEvent(this.eventId));
          requests.push(eventService.getEventLabs(this.eventId));
          return httpService.all(requests);
        })
        .then(async ([event, labs]) => {
          this.event = event.data.event;
          if (event.data.reason !== undefined) this.eventTerminated = true;
          if (this.eventTerminated) this.eventEndMessage = event.data.reason;
          this.dobermannIcon =
            this.event.dobermann_icon !== undefined
              ? this.event.dobermann_icon
              : null;
          this.eventLabs = labs.data.labs;
          const [dStart, tStart] = this.transformUTCTime(this.event.dateStart);
          const [dEnd, tEnd] = this.transformUTCTime(this.event.dateEnd);
          this.event.dateStart = `${dStart} ${tStart}`;
          this.event.dateEnd = `${dEnd} ${tEnd}`;

          const breadcrumb_name =
            this.event.name.length > 32
              ? `${this.event.name.slice(0, 30)}...`
              : this.event.name;
          this.$parent.breadcrumbs.push({ name: `event: ${breadcrumb_name}` });

          // todo: crea un enum ed usa quello per fare le verifiche
          this.isNormalEvent = this.event.type === EventType.NORMAL;
          this.isRedEvent = this.event.type === EventType.RED;
          this.isBlueEvent = this.event.type === EventType.BLUE;
          this.isPurpleEvent = this.event.type === EventType.PURPLE;
          this.isRedAndBlueEvent = this.event.type === EventType.REDBLUE;
          this.isGreenEvent = this.event.type === EventType.GREEN;
          this.isExtern = this.event.extern;
          this.isWhitelisted = this.event.isWhitelisted;

          let flowCheck = null;
          if (this.isExtern) {
            flowCheck = await this.oauth2FlowCheck();
          }

          if (flowCheck !== undefined && flowCheck !== null) {
            this.flashError(flowCheck.errorDescription);
          }

          this.dataType = this.event.dataType;

          this.isPrivate = !!this.event.private;
          this.isParticipating = this.event.participating;
          this.requireLabs = this.event.requireLabs;

          this.isScheduled =
            this.event.status.toUpperCase() === EventStatus.SCHEDULED;
          this.isOnGoing =
            this.event.status.toUpperCase() === EventStatus.ONGOING;
          this.isOverdue =
            this.event.status.toUpperCase() === EventStatus.OVERDUE;

          this.isStarted = this.isOnGoing && this.event.startId.started;
          const canStartNormal = this.isNormalEvent && this.event.startId;
          const isPurpleOrRedAndBlue =
            this.isPurpleEvent || this.isRedAndBlueEvent;
          const canStartPurpleOrRedAndBlue =
            isPurpleOrRedAndBlue && this.isParticipating;
          const canStartBlue = this.isBlueEvent && this.event.startId;
          this.canStart =
            this.isOnGoing &&
            (canStartNormal ||
              (canStartPurpleOrRedAndBlue && this.event.startId) ||
              canStartBlue);

          this.canGetReport =
            this.isOverdue && this.event.owner && !this.isBlueEvent;
          this.shouldStartEventTour();

          // Visible fields
          const participatingAndBlue = this.isParticipating && this.isBlueEvent;
          const participatingAndPurple =
            this.isParticipating && this.isPurpleEvent;
          this.showLabIp =
            this.isStarted &&
            (this.isNormalEvent ||
              participatingAndBlue ||
              participatingAndPurple);
          this.showSubmitFlag = this.isStarted && this.isNormalEvent;
          this.showAskReset =
            this.isStarted && this.isPrivate && this.isNormalEvent;
          this.showConnectionKey =
            this.isStarted &&
            (participatingAndBlue ||
              (isPurpleOrRedAndBlue && this.isParticipating));
          this.showTopologyChart = isPurpleOrRedAndBlue && !this.isScheduled;
          this.showTeamEventFlags =
            isPurpleOrRedAndBlue && this.isStarted && this.isParticipating;
          this.overlayOpen = !this.isParticipating && !this.isOverdue;
          this.isRunningCounter = !this.isOnGoing;

          this.bannerText = this.event.bannerText;
          this.labNotes = {};

          if (this.bannerText !== undefined && this.bannerText !== null) {
            this.labNotes = this.bannerText.labs === null ? {} : this.bannerText.labs;
          }

          this.shouldAnimateBannerTextBtn = this.getBannerTextCheckedValue();
          const subsequentRequests = [];

          const isParticipating = this.isParticipating;
          const isPrivate = this.isPrivate;
          const isGreenAllowed = this.checkGreenEvent();
          const redirectTo = `/event/${this.eventId}/join`;
          const isNormalUser = this.isNormalUser;
          const hasTeam = !!this.userData.team;

          if (!isGreenAllowed && this.isOnGoing && isNormalUser) {
            this.flashError("You need to join the event to participate");
            this.$router.push(redirectTo);
          } else if (!isGreenAllowed && !this.isOnGoing && isNormalUser) {
            this.flashError("The event is not ongoing, cannot join")
            this.$router.push("/events");
          } else if (isPrivate && !isParticipating && this.isScheduled && isNormalUser && hasTeam) {
            this.$router.push(redirectTo);
          } else if (isPrivate && !isParticipating && this.isScheduled && isNormalUser && !hasTeam) {
            this.flashWarning("You need to create a team in order to participate this event");
            this.createTeamAndGoToJoin()
          } else {
            this.handleRanks();

            if ((this.isBlueEvent || this.isRedEvent) && this.isScheduled)
              subsequentRequests.push(eventService.canApplyUser(this.eventId));
            else subsequentRequests.push(false);

            if (
              (this.isRedAndBlueEvent ||
                this.isRedEvent ||
                this.isPurpleEvent) &&
              this.isScheduled
            )
              subsequentRequests.push(eventService.canApplyTeam(this.eventId));
            else subsequentRequests.push(false);

            if (
              (this.isRedAndBlueEvent || this.isPurpleEvent) &&
              !this.isScheduled
            )
              subsequentRequests.push(
                eventService.getParticipatingTeams(this.eventId)
              );
            else subsequentRequests.push(false);

            // if (this.isGreenEvent && this.isNormalUser) {
            if (!this.isRedEvent) {

              subsequentRequests.push(
                eventService.getEventCompanyContract(this.eventId)
              );
            }
            // }

            // if (this.isGreenEvent && this.isHrpRecruiter) {
            //   subsequentRequests.push(
            //     eventService.getEventCompanyContract(this.eventId, this.userId)
            //   );
            // }
            this.startCountdown();
            this.endCountdown();

            if (subsequentRequests.length === 0) return;
          }

          if (this.isGreenEvent) {
            const eventId = this.eventId;
            if (!this.isNormalUser) subsequentRequests.push(eventService.getEventJob(eventId))
          }

          return httpService.all(subsequentRequests);
        })
        .then(([apply_user, apply, teams, contract, job]) => {
          if (job) this.eventJob = job.data.job
          if (apply) this.canApplyTeam = apply.data.canApplyTeam;
          if (apply_user) this.canApplyUser = apply_user.data.canApplyUser;

          if (contract) this.companyContract = contract.data.contract;

          if (teams) {
            // Compute participating teams
            this.teams = teams.data.teams;
            this.labs = [];

            // Chart data
            this.netTopChart = this.initNetTopChart;
            const netTopCategories = [];
            const netTopNodes = [];
            const netTopLinks = [];

            const network = teams.data.network;
            // Add network node
            netTopNodes.push({
              id: network.id,
              name: network.value,
              symbol: "diamond",
              itemStyle: {
                color: "green",
              },
            });

            // Add team and member nodes
            let teamLabsCounter = 0;
            for (const team of this.teams) {
              // Push category
              netTopCategories.push({ name: team.name });

              // Add team
              netTopNodes.push({
                category: team.name,
                id: team.id,
                name: team.name,
              });
              // Link team to network
              netTopLinks.push({
                source: team.id,
                target: network.id,
                symbolSize: [5, 20],
                label: {
                  show: false,
                },
              });

              // Add labs and link labs to team
              if (team.labs) {
                for (const lab of team.labs) {
                  if (this.userData.team !== team.id) {
                    this.labs.push({
                      id: lab.id,
                      cid: lab.cid,
                      name: lab.name,
                      flags: lab.flags,
                      ip: lab.ip,
                      team: {
                        id: team.id,
                        name: team.name,
                      },
                    });
                  }
                  // Add member first
                  const tmpLabId = `${team.id}+${lab.labId}`;
                  netTopNodes.push({
                    category: team.name,
                    id: tmpLabId,
                    team: team.id,
                    name: lab.name,
                    symbolSize: 40,
                  });
                  // Add link
                  netTopLinks.push({
                    source: tmpLabId,
                    target: team.id,
                  });

                  teamLabsCounter++;
                }
              } else {
                let placeholderModel = {
                  placeholder: true,
                };
                for (let i = 0; i < 5; i++) {
                  this.labs.push(placeholderModel);
                }
              }
            }

            this.netTopChart.series[0].categories = netTopCategories;
            this.netTopChart.series[0].data = netTopNodes;
            this.netTopChart.series[0].links = netTopLinks;
            this.netTopChart.legend.data = netTopCategories.map((c) => c.name);

            this.updateTeamLabs();
            this.showLabsFilter = teamLabsCounter > 25;

            // Subscribe red and blue own
            this.$store.subscribe((mutation) => {
              if (mutation.type === "lab/flashOwn") {
                const labId = mutation.payload.labId;
                const teamId = mutation.payload.teamId;
                this.flash(labId, teamId);
              }
            });
          }
        })
        .catch((error) => {
          const connAborted = error.code === "ECONNABORTED"
          const manageableError =
            !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
          if (this.isHrpRecruiter && !connAborted) {
            this.flashError(
              "Event not found, probably you provided an invalid user id"
            );
            this.$router.push(`/recruiter`);
          }
          if (!this.isHrpRecruiter && !connAborted) this.$router.push("/events");
        })
        .finally(() => {
          this.$parent.loading = false;
          this.getRankChart();
        });
    },
    checkGreenEvent() {
      const allowed = [
        UserType.RECRUITER,
        UserType.ADMIN,
        UserType.HRPLUS,
        UserType.ENTERPRISE
      ]
      if (!this.isGreenEvent) return true;
      if (this.event.owner) return true;
      if (this.isParticipating) return true;
      if (this.checkUserType(allowed)) return true;
      if (this.userData.role === UserType.ADMIN) return true;
      // TODO: Check if the recruiter is in the company and has invited users participating
      // Allow the recruiter to see event details if it is so
      return false;
    },
    checkUserType(userList) {
      return userList.some((role) => role === this.userData.role);
    },
    loadLocalStorage() {
      let ls = localStorage.getItem(this.eventId);
      if (!ls) {
        return {}
      }
      return JSON.parse(ls);
    },
    shouldStartEventTour() {
      const { "event-tour": eventTour } = this.$route.query;

      const ls = this.loadLocalStorage();
      const tourWatched = ls["tour"];

      if (!this.isStarted) return;
      if (!this.isParticipating) return;
      if (!this.isOnGoing) return;
      if (eventTour !== "true" && tourWatched) return;
      this.eventTour = this.$shepherd({
        useModalOverlay: true,
        confirmCancel: true,
        keyboardNavigation: true,
        tourName: "PWNX-Events-tour",
        defaultStepOptions: {
          cancelIcon: {
            enabled: true,
          },
          scrollTo: { behavior: "smooth", block: "center" },
        },
      });
      eventService.getEventTour(this.eventId).then((response) => {
        this.eventTourSteps = response.data.tour;
        this.eventTour.addSteps(this.buildEventTour(this.eventTourSteps));
        this.eventTour.start();
      });
    },
    buildEventTour(eventTourSteps) {
      let tourSteps = [];
      const vm = this;
      for (const [index, step] of eventTourSteps.entries()) {
        if (index === 0 && eventTourSteps.length - 1 > 0) {
          step.buttons = [
            {
              action() {
                this.next();
              },
              text: "Yes",
            },
            {
              action() {
                this.complete();

                const ls = vm.loadLocalStorage()
                ls["tour"] = true;
                localStorage.setItem(vm.eventId, JSON.stringify(ls))

                return vm.$router.push(`/event/${vm.eventId}`);
              },
              text: "No",
            },
          ];
        }
        if (index > 0 && index != eventTourSteps.length - 1) {
          step.buttons = [
            {
              action() {
                return this.next();
              },
              text: "Next",
            },
          ];
        }
        if (index == eventTourSteps.length - 1) {
          step.buttons = [
            {
              action() {

                const ls = vm.loadLocalStorage()
                ls["tour"] = true;
                localStorage.setItem(vm.eventId, JSON.stringify(ls))

                this.complete();
                return vm.$router.push(`/event/${vm.eventId}`);
              },
              text: "Done",
            },
          ];
        }
        tourSteps.push(step);
      }
      return tourSteps;
    },
    async handleFirstblood() {
      eventService.getFirstbloods(this.event.id).then((response) => {
        if (response.data.firstbloods.length === 0) return;
        // this.isFirstblood = true;
        const firstbloods = response.data.firstbloods;
        let firstbloodsLS = localStorage.getItem(this.eventId)

        if (!firstbloodsLS) {
          firstbloodsLS = JSON.stringify({
            firstblood: {
              check: 0
            }
          })
          localStorage.setItem(this.eventId, firstbloodsLS)
        }

        firstbloodsLS = JSON.parse(firstbloodsLS)
        let firstbloodObj = firstbloodsLS["firstblood"];
        let check = 0;

        if (firstbloodObj) {
          check = firstbloodObj["check"] ?? new Date().getTime();
        }

        let newCheck = null;
        let isFirstblood = false;
        for (const firstblood of firstbloods) {
          newCheck = new Date(firstblood.submission_date).getTime()
          if (newCheck <= check) continue;
          this.filteredFirstblood = firstblood;
          isFirstblood = true
          break;
        }

        this.firstbloodData = firstbloods;
        this.isFirstblood = isFirstblood;
        if (!isFirstblood) return;

        firstbloodsLS["firstblood"] = { check: newCheck };
        localStorage.setItem(this.eventId, JSON.stringify(firstbloodsLS))

        // setTimeout(() => {this.isFirstblood = false}, 30000);
      });
    },
    eventMainColor() {
      if (this.isRedEvent) {
        return "#FF0000";
      } else if (this.isNormalEvent) {
        return "#72FD85";
      } else if (this.isBlueEvent) {
        return "#526CFF";
      } else if (this.isPurpleEvent) {
        return "#A741FA";
      }
    },
    applyUser() {
      this.$parent.loading = true;
      eventService
        .joinEvent(this.eventId)
        .then((response) => {
          const successMessage =
            !!response && !!response.data && !!response.data.message;
          if (successMessage) {
            this.flashSuccess(response.data.message);
          }
          this.canApplyTeam = false;
          this.canApplyUser = false;
        })
        .catch((error) => {
          const manageableError =
            !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => {
          this.$parent.loading = false;
          window.location.reload()
        });
    },
    applyTeam() {
      this.$parent.loading = true;

      if (this.isRedEvent && this.isPrivate && this.isOnGoing) {
        this.flashError("This event can only be joined with a valid ticket");
        this.$router.push(`/event/${this.eventId}/join`);
        this.$parent.loading = false;
        return;
      } else if (this.isRedEvent && this.isPrivate && !this.isOnGoing) {
        this.$router.push("/events");
        this.$parent.loading = false;
        return;
      }

      eventService
        .joinEvent(this.eventId)
        .then((response) => {
          const successMessage =
            !!response && !!response.data && !!response.data.message;
          if (successMessage) {
            this.flashSuccess(response.data.message);
          }
          this.canApplyTeam = false;
          this.canApplyUser = false;
        })
        .catch((error) => {
          const manageableError =
            !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => {
          this.$parent.loading = false;
          this.closeOverlay();
        });
    },
    getBlueStyling() {
      return this.isBlueEvent
        ? {
          display: "flex",
          justifyContent: "end",
        }
        : {};
    },
    getRank() {
      const request = [];
      // if (this.isBlueEvent || this.isPurpleEvent)
      //   request.push(eventService.getEventRankV2(this.eventId));
      // else if (this.isGreenEvent && this.userId)
      //   request.push(eventService.getRankWithUserId(this.eventId, this.userId));
      // else request.push(eventService.getEventRank(this.eventId));

      const eventId = this.eventId;
      const userId = this.userId;

      if (this.isGreenEvent && this.userid) {
        request.push(eventService.getRankWithUserId(eventId, userId))
      } else {
        request.push(eventService.getEventRankV2(eventId))
      }

      httpService
        .all(request)
        .then(
          ([response]) =>
            (this.ranks = response.data.data))
        // .catch(() => clearInterval(this.intervalId))
        .finally(() => {
          if (this.ranks.length <= 0) {
            for (let i = 0; i < 5; i++) {
              this.ranks.push({ placeholder: true });
            }
          }
        });
    },
    cleanRankChartData(chartData) {
      return !chartData.placeholder
        ? {
          legend: [],
          series: [],
        }
        : chartData;
    },
    async getRankChartData(request) {
      return await httpService
        .all(request)
        .then(([response]) => response.data.data);
    },
    async getRankChart() {
      const request = [];
      if (this.isBlueEvent || this.isPurpleEvent)
        request.push(eventService.getEventRankChartV2(this.eventId));
      else if (this.isHrpRecruiter && !this.requireLabs && this.isGreenEvent)
        request.push(eventService.getEventRankChartV2(this.eventId));
      else if (this.isRedEvent) {
        request.push(eventService.getEventRankChartV2(this.eventId));
      } else if (this.isGreenEvent && this.chartUserId) {
        const userId = this.chartUserId
        request.push(eventService.getEventRankChart(this.eventId, userId));
      } else request.push(eventService.getEventRankChart(this.eventId));
      let data = await this.getRankChartData(request);
      this.graphPlaceholder = false;
      if (data.legend.length === 0) {
        this.graphPlaceholder = true;
        data = {
          placeholder: true,
          legend: ["-", "-", "-", "-"],
          series: [
            {
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
              ],
              name: "-",
            },
            {
              data: [
                0,
                0,
                0,
                0,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                30,
                30,
                30,
                30,
                30,
                30,
                30,
                30,
              ],
              name: "-",
            },
            {
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
              ],
              name: "-",
            },
            {
              data: [
                0,
                0,
                0,
                0,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
              ],
              name: "-",
            },
          ],
        };
      }
      const getGraphColor = (i) => {
        const greenGradient = [
          "#50808e",
          "#69A297",
          "#84B59F",
          "#A3C9A8",
          "#DDD8C4",
          "#1ECC0A",
          "#24F70C",
          "#139E03",
          "#3B7035",
          "#4D8248",
        ];
        const blueGradient = [
          "#4586FF",
          "#546B96",
          "#C5CCD9",
          "#103C8F",
          "#A8ACB3",
        ];
        const purpleGradient = [
          "#8C015E",
          "#E3079A",
          "#A87E9A",
          "#D147A3",
          "#8C5179",
          "#BA25B0",
          "#A8039D",
          "#EB60E1",
          "#992691",
          "#8C0B84",
        ];
        const redGradient = [
          "#E84A4A",
          "#D42424",
          "#700808",
          "#BA0F0F",
          "#FF0000",
        ];
        if (this.isNormalEvent || this.isGreenEvent)
          return greenGradient[i > greenGradient.length - 1 ? 0 : i];
        else if (this.isBlueEvent)
          return blueGradient[i > blueGradient.length - 1 ? 0 : i];
        else if (this.isPurpleEvent)
          return purpleGradient[i > purpleGradient.length - 1 ? 0 : i];
        else if (this.isRedEvent)
          return redGradient[i > redGradient.length - 1 ? 0 : i];
      };
      this.leadChart.series = [];
      const isPlaceholder = !!data.placeholder;
      this.leadChart = isPlaceholder
        ? this.leadChart
        : {
          tooltip: { trigger: "axis" },
          legend: {
            data: [],
            textStyle: {
              color: "#768aa3",
            },
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            show: true,
            type: "category",
            boundaryGap: false,
            data: [],
            axisLine: {
              lineStyle: {
                color: "#768aa3",
              },
            },
          },
          yAxis: {
            type: "value",
            axisLine: {
              lineStyle: {
                color: "#768aa3",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#555",
                type: "dotted",
              },
            },
          },
          series: [],
        };

      this.leadChart.legend.data = data.legend;
      for (const [i, s] of data.series.entries()) {
        this.leadChart.series.push({
          name: s.name,
          type: "line",
          symbol: "circle",
          symbolSize: 6,
          color: getGraphColor(i),
          stack: `${s.name}Stack`,
          data: s.data,
        });
      }

      // If event is ongoing get last hour minutes, otherwise get an interval of 60 points within the
      // overall time span
      const fmt_hm = (hm) => (`${hm}`.length > 1 ? `${hm}` : `0${hm}`);
      const xAxis = [];
      let secondConversion = 1000;
      let interval = 60;
      let intervalStart, intervalStop;
      if (this.isOnGoing && !(this.isHrpRecruiter && this.isGreenEvent)) {
        intervalStop = new Date();
        intervalStart = new Date();
        intervalStart.setHours(intervalStart.getHours() - 1);
      } else if (!this.isScheduled || this.isGreenEvent) {
        // == event is overdue
        let dateStart, dateEnd;

        if (!data.dateStart || !data.dateEnd) {
          dateStart = this.event.dateStart
          dateEnd = this.event.dateEnd
        } else {
          dateStart = data.dateStart * 1000
          dateEnd = data.dateEnd * 1000
        }

        intervalStop = new Date(dateEnd);
        intervalStart = new Date(dateStart);
      }

      let diff = intervalStop - intervalStart;
      if (!this.isScheduled && !this.isOnGoing) {
        interval = Math.round(diff / interval);
      }

      if (this.isGreenEvent && this.isHrpRecruiter && this.isOnGoing) {
        const dStart = this.transformUTCTime(intervalStart.toISOString())
        const dEnd = this.transformUTCTime(intervalStop.toISOString())

        intervalStart = new Date(dStart.join(" ") + "Z").toLocaleString("en-US", {
          timeZone: "UTC"
        })

        intervalStop = new Date(dEnd.join(" ") + "Z").toLocaleString("en-US", {
          timeZone: "UTC"
        })

        intervalStart = new Date(intervalStart)
        intervalStop = new Date(intervalStop)

        const timestampStart = intervalStart.getTime()
        const timestampStop = intervalStop.getTime()

        secondConversion = 60
        interval = (timestampStop - timestampStart) / (60 * 60)
      }
      diff = diff + interval;

      let i = this.isOnGoing ? interval : 0;
      while (i < diff) {
        const chart_date = new Date(intervalStart.getTime() + i);
        const fmt_h = fmt_hm(chart_date.getHours());
        const fmt_m = fmt_hm(chart_date.getMinutes());
        const axisValue = `${fmt_h}:${fmt_m}`;
        xAxis.push(axisValue);
        i += this.isOnGoing ? interval * secondConversion : interval;
      }
      this.leadChart.xAxis.data = xAxis;
      // clearInterval(this.intervalId);
    },
    handleRanks() {
      // Get ranks
      this.getRank();
      this.getRankChart();

      // Check event status
      const eventStartDate = Date.parse(this.event.dateStart) / 1000;
      const eventEndDate = Date.parse(this.event.dateEnd) / 1000;
      const now = Math.round(new Date().getTime() / 1000);
      if (now > eventEndDate || now < eventStartDate) {
        // clearInterval(this.intervalId);
      }
    },
    async chCol(x, col) {
      this.netTopChart.series[0].data[x].itemStyle =
        col === "reset"
          ? {}
          : {
            borderColor: col,
            borderWidth: 2,
            shadowColor: col,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowBlur: 10,
          };
      const oDioJS = { ...this.netTopChart };
      this.netTopChart = { ...oDioJS };
    },
    async flash(labId, teamId) {
      let x;
      for (let i = 0; i < this.netTopChart.series[0].data.length; i++) {
        const ntcTeam = this.netTopChart.series[0].data[i].team;
        let ntcId = null;

        try {
          ntcId = this.netTopChart.series[0].data[i].id.split("+")[1];
        } catch {
          continue;
        }

        if (!!ntcId && !!ntcTeam) {
          const isLab = ntcId === labId;
          const isTeam = ntcTeam === teamId;
          if (isLab && isTeam) {
            x = i;
            break;
          }
        }
      }
      for (let k = 0; k < 4; k++) {
        this.chCol(x, "red");
        await new Promise((r) => setTimeout(r, 500));
        this.chCol(x, "blue");
        await new Promise((r) => setTimeout(r, 500));
      }
      this.chCol(x, "reset");
    },
    openReportModal(cid, team) {
      this.$modal.show(
        ReportModal,
        {
          returnUserChoice: this.handleReport,
          cid: cid,
          team: team,
        },
        { height: "auto" }
      );
    },
    handleReport(success, cid) {
      if (success) {
        reportService
          .report(cid)
          .then((response) => {
            this.flashSuccess(response.data.message);
          })
          .catch((error) => {
            this.flashError(error.response.data.error);
          });
      }
    },
    openFlagModal(flagType, lab) {
      const teamId = lab.team ? lab.team.id : null;
      this.$modal.show(
        FlagSubmissionModal,
        {
          flagType,
          labId: lab.id,
          teamId,
          eventId: this.eventId,
          onClose: (success) => {
            if (success) {
              lab.flags[flagType] = true;
            }
          },
        },
        {
          height: "auto",
        }
      );
    },
    updateTeamLabs() {
      this.filteredLabs = this.labs.filter((l) => {
        if (l.name === undefined || l.team === undefined) return false;
        if (this.teamLabsFilter === undefined) return false;
        const includeLab = l.name
          .toLowerCase()
          .includes(this.teamLabsFilter.lab.toLowerCase());
        const includeTeam = l.team.name
          .toLowerCase()
          .includes(this.teamLabsFilter.team.toLowerCase());
        return includeLab && includeTeam;
      });
    },
    hasBeenOwned(who, labId) {
      const lab = this.eventLabs.find((l) => l.id === labId);
      return lab.flags[who];
    },
    userHasBeenOwned(labId) {
      return this.hasBeenOwned("user", labId);
    },
    rootHasBeenOwned(labId) {
      return this.hasBeenOwned("root", labId);
    },
    shouldOpenModal(type, lab) {
      if (!this.hasBeenOwned(type, lab.id)) {
        this.openFlagModal(type, lab);
      }
    },
    preStartEvent() { },
    startEvent() {
      this.$modal.show(
        StartEventModal,
        {
          event: this.event,
          returnUserChoice: this.manageStartEvent,
        },
        {
          height: "auto",
        }
      );
    },
    manageStartEvent(confirm) {
      if (confirm) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.event.hasOwnProperty("startId")) {
          this.$parent.loading = true;
          eventService
            .startEvent(this.event.id, this.event.startId.id)
            .then((response) => {
              const successMessage =
                !!response && !!response.data && !!response.data.message;
              if (successMessage) {
                this.flashSuccess(response.data.message);
              }

              this.$router.push(`/event/${this.eventId}?event-tour=true`);
            })
            .catch((error) => {
              const manageableError =
                !!error && !!error.data && !!error.data.errorDescription;
              if (manageableError) {
                this.flashError(error.data.errorDescription);
              }
            })
            .finally(() => (this.$parent.loading = false));
        }
      }
    },
    navigateToPeeps(id) {
      let name;
      const params = {};
      if (this.isNormalEvent || this.isBlueEvent) {
        name = "UserDetails";
        params.userId = id;
      } else {
        name = "TeamDetails";
        params.teamId = id;
      }

      this.$router.push({ name, params });
    },
    getLabLevel(difficulty) {
      const lvl = Math.round(difficulty / 10);
      if (lvl < 1) {
        return 1;
      } else if (lvl > 9) {
        return 9;
      }
      return lvl;
    },
    downloadReport() {
      this.$parent.loading = true;
      eventService
        .generateReport(this.event.id)
        .then((response) => {
          const successMessage =
            !!response && !!response.data && !!response.data.message;
          if (successMessage) {
            this.flashSuccess(response.data.message);
          }
        })
        .catch((error) => {
          const manageableError =
            !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => (this.$parent.loading = false));
    },
    handleLabs() {
      const eventStartDate = Date.parse(this.event.dateStart) / 1000;
      const eventEndDate = Date.parse(this.event.dateEnd) / 1000;
      const now = Math.round(new Date().getTime() / 1000);
      if (now > eventEndDate || now < eventStartDate) {
        clearInterval(this.labReloadInterval);
        return;
      }

      let promise = null;
      if (this.isHrpRecruiter)
        promise = eventService.getEventLabsWithUserId(
          this.eventId,
          this.userId
        );
      else promise = eventService.getEventLabs(this.eventId);

      promise
        .then(
          (response) =>
            (this.eventLabs = response.data.labs)
        )
        .catch((error) => {
          const manageableError =
            !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        });
    },
    getLabKeys(lab) {
      this.$parent.loading = true;

      eventService
        .getEventLabKeys(this.event.id, lab.id)
        .then((response) => {
          const blob = new Blob([response.data]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "connection_key";
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          const manageableError =
            !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => (this.$parent.loading = false));
    },
    openOverlay(type) {
      this.overlayOpen = true;
      this.isJoining = type === "isJoining";
    },
    closeOverlay() {
      this.overlayOpen = false;
      this.isJoining = false;
    },
    runDoberman() {
      // still have to implement backend
      // this.labDobermanRunning = true
      eventService
        .runDobermann(this.event.id)
        .then((response) => {
          const manageableMessage = !!response.data && response.data.message;
          const message = response.data.message;
          if (manageableMessage) {
            this.flashSuccess(message);
          }
        })
        .catch((error) => {
          const manageableError = !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        });
    },
    startCountdown() {
      setInterval(() => {
        this.countdownTimestamp =
          new Date(this.event.dateStart).getTime() - new Date().getTime();
        let duration = this.countdownTimestamp;
        if (duration >= 0) {
          let milliseconds = parseInt((duration % 1000) / 100),
            seconds = Math.floor((duration / 1000) % 60),
            minutes = Math.floor((duration / (1000 * 60)) % 60),
            hours = Math.floor((duration / (1000 * 60 * 60)) % 24),
            days = Math.ceil(duration / (1000 * 3600 * 24)) - 1;
          if (days < 0) days = 0;
          hours = hours < 10 ? "0" + hours : hours;
          minutes = minutes < 10 ? "0" + minutes : minutes;
          seconds = seconds < 10 ? "0" + seconds : seconds;
          days = days < 10 ? "0" + days : days;
          if (days != 0) {
            this.countdown =
              days +
              "d - " +
              hours +
              ":" +
              minutes +
              ":" +
              seconds +
              "." +
              milliseconds;
          } else {
            this.countdown =
              hours + ":" + minutes + ":" + seconds + "." + milliseconds;
          }
        } else {
          clearInterval(this.t);
        }
      }, 100);
    },
    endCountdown() {
      setInterval(() => {
        let dateEnd = new Date();
        if (
          this.event.type !== EventType.GREEN ||
          this.userData.role !== UserType.USER
        ) {
          dateEnd = new Date(
            new Date(this.event.dateEnd + "Z").toLocaleString("en-US", {
              timeZone: "UTC",
            })
          ).getTime();
        } else {
          if (!this.companyContract) return;
          const [dEnd, tEnd] = this.transformUTCTime(
            this.companyContract.ticketEnd
          );
          const ticketEnd = `${dEnd} ${tEnd}`;
          dateEnd = new Date(
            new Date(ticketEnd + "Z").toLocaleString("en-US", {
              timeZone: "UTC",
            })
          ).getTime();
        }
        this.countdownEndTimestamp = dateEnd - new Date().getTime();
        let duration = this.countdownEndTimestamp;
        if (duration >= 0) {
          // milliseconds = parseInt((duration % 1000) / 100),
          let seconds = Math.floor((duration / 1000) % 60),
            minutes = Math.floor((duration / (1000 * 60)) % 60),
            hours = Math.floor((duration / (1000 * 60 * 60)) % 24),
            days = Math.floor(duration / 1000 / 86400);

          days = days < 10 ? "0" + days : days;
          hours = hours < 10 ? "0" + hours : hours;
          minutes = minutes < 10 ? "0" + minutes : minutes;
          seconds = seconds < 10 ? "0" + seconds : seconds;
          let countdownEnd = "";
          if (days > 0) {
            countdownEnd = days + "d - ";
          }
          countdownEnd += hours + ":" + minutes + ":" + seconds;
          this.countdownEnd = countdownEnd;
        } else {
          clearInterval(this.t);
        }
      }, 1000);
    },
    fetchStatistics() {
      this.handleRanks();
      this.handleLabs();
      this.handleStatus();
    },
    handleStatus() {
      if (!this.isOnGoing) return;
      if (!this.isHrpRecruiter)
        eventService.getEvent(this.eventId).then((response) => {
          const event = response.data.event;
          if (event.status.toLowerCase() === "overdue") this.isOnGoing = false;
        });
      if (this.isHrpRecruiter)
        eventService
          .getEventWithUserId(this.eventId, this.userId)
          .then((response) => {
            const event = response.data.event;
            if (event.status.toUpperCase() === EventStatus.OVERDUE)
              this.isOnGoing = false;
          });
    },
    downloadOvpnFile() {
      this.$parent.loading = true;
      // const httpPromise = this.isRedEvent ? eventService.getOvpn(this.event.id):userService.getOvpn();
      // httpPromise
      eventService
        .getOvpn(this.event.id)
        .then((response) => {
          const blob = new Blob([response.data], { type: "text/plain" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "config.ovpn";
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          const manageableError =
            !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => (this.$parent.loading = false));
    },

    async performResetAction(labId) {
      const eventId = this.event.id;
      const askReset = this.event.askReset;

      let promise = null;

      this.$parent.loading = true;
      if (askReset) {
        promise = eventService.askReset(eventId, labId);
      } else {
        const team = this.user.team != "NORMAL" ? this.user.team : null;
        promise = eventService.resetLab(eventId, labId, team);
      }

      promise
        .then((response) => {
          const successMessage = !!response.data && !!response.data.message;

          if (successMessage) {
            this.flashSuccess(response.data.message);
          }

          return eventService.getEventLabs(eventId);
        })
        .then((response) => {
          this.eventLabs = response.data.labs;
        })
        .catch((error) => {
          const manageableError =
            !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => {
          this.$parent.loading = false;
        });
    },
    async submitFlagPurple() {
      if (!this.flagString) {
        this.flashError("Flag cannot be empty");
        return;
      }
      this.$parent.loading = true;
      labService
        .submitFlagPurple(this.eventId, this.flagString)
        .then((response) => {
          const successMessage =
            !!response && !!response.data && !!response.data.message;
          if (successMessage) {
            this.flashSuccess(response.data.message);
          }
          // this.onClose(true);
        })
        .catch((error) => {
          const manageableError =
            !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
          // this.onClose(false);
        })
        .finally(() => {
          this.$parent.loading = false;
          this.$emit("submittedFlag");
          this.flagString = "";
        });
      this.fetchStatistics();
    },
  },
  watch: {
    notes: function () {
      clearTimeout(this.saveNotesTimeout);
      this.saveNotesTimeout = setTimeout(this.saveUserNotes, 5000);
    },
    isOnGoing: function (new_, old_) {
      if (old_ !== false && new_ === false) {
        this.$router.push(`/event/${this.eventId}`);
      }
    },
    countdown: function (new_) {
      if (new_.includes("00:00:00")) {
        this.pageReloading = true;
        this.$parent.loading = true;
        setTimeout(() => {
          if (!this.isRedEvent) {
            window.location.reload();
          } else {
            this.$router.push(`/event/${this.eventId}?event-tour=true`);
          }
        }, 10000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";
@import "../assets/css/variables";

.vh-20 {
  height: 30vh;
}

.vh-30 {
  height: 30vh;
}

.vh-50 {
  height: 50vh;
}

.table__row:nth-child(1) {
  .col__name {
    &--name {
      text-align: left;

      &:before {
        content: "name";
        text-align: left;
      }
    }

    &--difficulty {
      &:before {
        content: "difficulty";
        text-align: left;
      }
    }

    &--points {
      &:before {
        content: "points";
        text-align: center;
      }
    }

    &--submissions {
      &:before {
        content: "submit flag";
        text-align: center;
      }
    }

    &--connection {
      &:before {
        content: "connection key";
        text-align: center;
      }
    }
  }
}

.form-control {
  &--small {
    height: initial !important;
    width: 40%;
    display: inline;
  }
}

.lab-reset {
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  &__text {
    font-weight: 700;
    margin-left: 0.1rem;
    color: #333;
  }
}

.connection-key {
  &__icon {
    font-size: 1.2rem;
  }

  &:hover {
    color: #768aa3;
  }

  &:active {
    color: #b1d2eb;
  }
}

.col__name {
  &--difficulty {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;

    >span {
      background: rgba($color: $neutral--dark, $alpha: 0.15);
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      width: 100%;
      height: 10px;
      position: relative;
      font-size: 0;

      &:after {
        clip-path: inset(0px calc(100% - var(--value)) 0px 0px round 15px);
        border-radius: 10px 10px 10px 10px;
        -webkit-border-radius: 10px 10px 10px 10px;
        -moz-border-radius: 10px 10px 10px 10px;
        content: "";
        width: 100%;
        left: 0;
        position: absolute;
        height: 10px;
        background: #e5405e;
        background: linear-gradient(to right,
            #3fffa2 0%,
            #ffdb3a 35%,
            #ff993a 60%,
            #e5405e 100%);
      }
    }
  }
}

.event-info {
  p {
    font-size: 0.9rem !important;
  }
}

.solid-bg {
  background: $neutral--dark;
}

.table__data--userPoints,
.table__data--rootPoints {
  font-size: 1rem !important;
}

.overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  position: fixed;
  background: rgba($neutral--dark, 0.9);
}

.join-button {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.7rem;
  position: relative;

  &:hover {
    &:after {
      width: 140%;
      height: 140%;
      opacity: 0.4;
    }
  }

  &:after {
    content: "";
    width: 130%;
    height: 130%;
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    opacity: 0.2;
    transition: all 0.3s;
  }

  &.normal {
    background: $soft-green;
    color: $neutral--dark;

    &:after {
      background: $soft-green;
    }
  }

  &.blue {
    background: $soft-blue;
    color: $neutral--light;

    &:after {
      background: $soft-blue;
    }
  }

  &.purple {
    background: $soft-purple;
    color: $neutral--light;

    &:after {
      background: $soft-purple;
    }
  }

  &.red {
    background: $soft-red !important;
    color: $neutral--light !important;

    &:after {
      background: $soft-red !important;
    }
  }

  &.disabled {
    pointer-events: none;

    &:after {
      width: 200%;
      height: 200%;
    }
  }
}

.event__icon {
  // background-size: cover;
  // background-position: center center;
}

.time-counter {
  font-size: 2.5rem;
  vertical-align: text-top;
}

.control-icons {
  i {
    font-size: 0.8rem;
    padding: 15px;
    border: 2px solid rgba($soft-green, 1);
    border-radius: 50%;
    color: rgba($soft-green, 1);
    position: relative;
    opacity: 0.8;
    cursor: pointer;
    transition: all 0.1s;
  }

  &.purple {
    i {
      border: 2px solid rgba($soft-purple, 1);
      color: rgba($soft-purple, 1);

      &:hover {
        background: rgba($soft-purple, 1);
      }
    }
  }

  &.red {
    i {
      border: 2px solid rgba($soft-red, 1) !important;
      color: rgba($soft-red, 1) !important;

      &:hover {
        background: rgba($soft-red, 1) !important;
      }
    }
  }

  &.blue {
    i {
      border: 2px solid rgba($soft-blue, 1);
      color: rgba($soft-blue, 1);

      &:hover {
        background: rgba($soft-blue, 1);
      }
    }
  }

  i:hover {
    color: $neutral--dark;
    background: rgba($soft-green, 1);
  }
}

.disclaimer {
  opacity: 0.5;
  font-size: 0.75rem;
}

.game-stats {
  font-size: 0.9rem;
}

.game-stats--container {
  background: $neutral--medium;
  overflow: hidden;

  i:not(.lab__flag-submit) {
    background: rgba(white, 0.1);
    padding: 10px;
    border-radius: 50%;
    transition: all 0.1s;
    cursor: pointer;
    position: relative;
    z-index: 3;

    &:after {
      content: "";
      position: absolute;
      top: -1300%;
      left: -400%;
      z-index: -2;
      width: 1000%;
      height: 1500%;
      transform: rotateZ(45deg);
      background: rgba(white, 0.025);
      transition: all 0.2s;
      pointer-events: none;
    }

    &:hover {
      background: rgba(white, 0.05);

      &:after {
        left: -300%;
      }
    }
  }
}

.submissions--header {
  font-size: 0.8rem;
  opacity: 0.6;
}

.submissions--container {
  height: 15vh;
  overflow-y: scroll;
}

.statistics-badge {
  font-size: 0.7rem;
  padding: 5px;
  height: 60px;
  width: 60px;
  color: $soft-green;
  background: rgba($soft-green, 0.2);
  display: table-cell;
  vertical-align: middle;

  &.purple {
    color: $soft-purple;
    background: rgba($soft-purple, 0.1);
  }

  &.red {
    color: $soft-red !important;
    background: rgba($soft-red, 0.1) !important;
  }
}

.end-countdown {
  font-size: 2rem;
}

.end-countdown--container {
  h2 {
    opacity: 0.7;
    font-size: 0.7rem;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.event-row::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.event-row {
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* Firefox */
}

.event-row--container {
  overflow-y: scroll;
}

.event-leaderboard--container {
  position: absolute;
  // left: 50%;
  // top: 0%;
  // max-height: 95%;
  overflow-y: scroll;
  // transform: translate(-50%, 3%);
}

#event-leaderboard {
  // max-height: 25vh;
  // overflow: scroll;
  place-items: center;
}

.current-team-labs {
  &:nth-child(odd) {
    background: $neutral--medium;
  }
}

.current-team-labs__container {
  * {
    font-size: 0.8rem;
  }
}

.underline-hover {
  &:hover {
    text-decoration: underline;
  }
}

.graphs-placeholder {
  pointer-events: none;
  opacity: 0.3;
}

button,
input,
optgroup,
select,
textarea,
html input[type="button"],
input[type="reset"],
input[type="submit"],
button[disabled],
html input[disabled],
button::-moz-focus-inner,
input::-moz-focus-inner,
input[type="checkbox"],
input[type="radio"],
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="search"],
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.lab__flag-input {
  background: $neutral--dark;
  color: $neutral--soft;
  padding: 10px 20px;
  font-size: 0.8rem;
  z-index: 10;

  &:focus {
    border-left: 4px solid $soft-green;
  }

  &.purple:focus {
    border-left: 4px solid $soft-purple;
  }

  &.red:focus {
    border-left: 4px solid $soft-red !important;
  }
}

.lab__flag-submit {
  background: $soft-green;
  height: 30px;
  width: 30px;
  border-radius: 50%;

  &:hover {
    &:after {
      width: 130%;
      height: 130%;
    }
  }

  &:after {
    content: "";
    transition: all 0.2s;
    position: absolute;
    width: 120%;
    height: 120%;
    background: rgba($soft-green, 0.8);
    border-radius: 50%;
    z-index: -1;
  }

  &.purple {
    background: $soft-purple !important;

    &:after {
      background: rgba($soft-purple, 0.8);
    }
  }

  &.red {
    background: $soft-red !important;

    &:after {
      background: rgba($soft-red, 0.8) !important;
    }
  }
}

.orange {
  color: $soft-orange !important;
}

.download-icon {
  margin: 0px 5px 0px 20px;
  border-radius: 50%;
  background-color: $soft-orange;

  &:hover {
    background-color: rgb(224, 127, 36);
  }
}

#redirecting-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 100;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

#firstblood-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  // transform: translate(-14.2%, -50%);
}

#event-end {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
}

#no-data-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
}

#create-team-btn {
  position: relative;
}

#create-team-btn::before {
  content: "Join The Event";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.notes-container {
  width: 100%;
  position: relative;
  background-color: $neutral--darker;
  padding: 20px;
}

.notes-container textarea {
  width: 100%;
  min-height: 250px;
  border: 0;
  background-color: $neutral--dark;
  color: #fff;
  outline: none;
  padding: 20px;
  font-size: 18px;
  resize: none;
}

.saving-notes {
  width: 100px;
  overflow: visible;
}

.saving-notes circle {
  stroke: $soft-green;
  transform-origin: 50px 50px;
  animation: spin 1.2s linear infinite;
}

.saving-text {
  position: absolute;
  left: 0%;
  transform: translate(300%, 30%);
  animation: text-pulse 1.2s linear infinite alternate;
  opacity: 0;
}

.graph-placeholder {
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba($neutral--dark, 0.7);
}

.event-stats {
  h2 {
    font-size: 0.8rem;
    margin: 0;
    opacity: 0.5;
  }

  p {
    font-size: 1.5rem;
    margin: 0;
  }
}

#eventimage {
  height: 30vh !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.applications-count {
  font-weight: 900;
  background-color: black;
  color: $soft-orange;
}

.messages-box {
  border-left: 1px solid white;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  // border-right: 10px solid $soft-green;
  color: $soft-green;
}

.relative-message {
  position: relative;
}

.blinking-cursor {
  position: absolute;
  right: 33%;
  bottom: 0;
  width: 10px;
  height: 20px;
  background-color: $soft-green;
  animation: blinking 0.5s linear infinite alternate;
  opacity: 0;
}

.antenna-bg {

  &:hover {
    background-color: darken($soft-green, 50);
    animation-play-state: paused;
  }

  &.animate {
    animation: antenna-animation 2s linear 2s infinite alternate;
    border: 2px solid darken($soft-green, 50);

    &:hover {
      background-color: darken($soft-green, 50) !important;
      animation-play-state: paused;
    }
  }

  background-color: darken($soft-green, 50);
}

.antenna-container-absolute {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(200%, -10%);
}

@keyframes antenna-animation {
  100% {
    background-color: darken($soft-green, 70);
  }
}

@keyframes blinking {
  to {
    opacity: 1;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes text-pulse {
  to {
    opacity: 1;
  }
}
</style>
