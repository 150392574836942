<template>
  <div>
    <div class="page-head row m-0 p-0">
      <div class="page-title col-md-3 d-flex align-items-center p-0 m-0">
        <h2 class="main-title-1 card__label">job applications</h2>
      </div>
      <div class="page-title col-md-4 p-0 m-0">
        <label for="status" class="card__label">filter by status</label>
        <select v-model="status" name="status" id="status" class="form-control w-100 border">
          <option v-for="(st, index) of statuses" :value="st" :key="index">{{ st }}</option>
        </select>
      </div>
    </div>
    <div class="applications row mt-3 m-0 p-0">
      <template v-if="applications.length > 0">
        <div class="col-md-4 m-0 p-0" v-for="application of applications" :key="application.id">
          <form class="application-wrapper mx-1 my-2 p-1 pt-3 border row">
            <div class="form-group col-md-12">
              <label for="first-name" class="card__label">first name</label>
              <input disabled id="first-name" type="text" class="form-control" :value="application.firstName">
            </div>
            <div class="form-group col-md-12">
              <label for="last-name" class="card__label">last name</label>
              <input disabled id="last-name" type="text" class="form-control" :value="application.lastName">
            </div>
            <div class="form-group col-md-12">
              <label for="email-number" class="card__label">email</label>
              <input disabled id="email-number" type="text" class="form-control" :value="application.email">
            </div>
            <div class="form-group col-md-12">
              <label for="phone-number" class="card__label">phone number</label>
              <input disabled id="phone-number" type="text" class="form-control" :value="application.phoneNumber">
            </div>
            <div class="form-group col-md-12">
              <label for="user-cv" class="card__label">user cv</label>
              <px-button @click.native="downloadUserCv(application.userId)" style="fontWeight: 900" class="px-button--primary py-4 w-100">download user cv</px-button>
            </div>
            <div class="form-group col-md-12">
              <label for="status" class="card__label">status</label>
              <input 
                :style="{
                  backgroundColor: getStatusBackground(application.status),
                  color: 'black',
                  textAlign: 'center',
                  fontWeight: 900
                }" 
                disabled 
                id="status" 
                type="text" 
                class="form-control" 
                :value="application.status">
            </div>
            <div class="form-group col-md-12" v-if="application.status === 'PENDING'">
              <h2 class="card__label">action</h2>
              <div class="buttons-container row m-0 p-0">
                <div class="col-md-6 w-100 ml-md-0 pl-md-0 my-1 my-md-0">
                  <px-button @click.native="applyAction(application.userId, 'accept')" class="w-100 px-button--success">accept</px-button>
                </div>
                <div class="col-md-6 w-100 mr-md-0 pr-md-0 my-1 my-md-0">
                  <px-button @click.native="applyAction(application.userId, 'reject')" class="w-100 px-button--danger">reject</px-button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </template>
      <template v-else>
        <div class="text-center">
          <h2 class="main-title-1 card__label">No job applications yet</h2>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import PxButton from "@/components/PxButton";
import recruiterService from "@/api/recruiter";

export default {
  name: "JobApplications",
  beforeCreate() {
    this.$parent.breadcrumbs = [{name: "Job Applications"}]
  },
  created() {
    const halfMinute = 30000;
    this.loadApplications()
    this.applicationsInterval = setInterval(this.loadApplications, halfMinute)
  },
  components: {
    PxButton
  },
  methods: {
    getStatusBackground(status) {
      const colors = {
        "PENDING": "orange",
        "REJECTED": "red",
        "ACCEPTED": "green"
      }
      return colors[status]
    },
    loadApplications() {
      this.$parent.loading = true;
      const jobId = this.jobId
      const status = this.status
      recruiterService.getJobApplications(jobId, status)
        .then((response) => {
          const applications = response.data.applications;
          this.applications = applications;
        })
        .catch((error) => {
          const manageableError = !!error.data && !!error.data.errorDescription;
          if (manageableError) this.flashError(error.data.errorDescription)
        })
        .finally(() => {
          this.$parent.loading = false;
        })
    },
    downloadUserCv(userId) {
      recruiterService.downloadUserCv(this.jobId, userId)
        .then((response) => {
          const decoded = Buffer.from(response.data, 'base64')
          const blob = new Blob([decoded], { type: "application/pdf" })
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement("a");
          link.href = url;
          link.download = [userId, "cv.pdf"].join("-");
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
    },
    applyAction(userId, action) {
      this.$parent.loading = false
      recruiterService.applyAction(this.jobId, userId, action)
        .then((response) => {
          const manageableMessage = !!response.data && !!response.data.message
          if (manageableMessage) {
            this.flashSuccess(response.data.message)
            this.loadApplications()
          }
        })
        .catch((error) => {
          const manageableError = !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription)
          }
        })
        .finally(() => {
          this.$parent.loading = true;
        })
    }
  },
  beforeDestroy() {
    clearInterval(this.applicationsInterval)
  },
  data() {
    return {
      applications: [],
      jobId: this.$route.params.jobId,
      status: "PENDING",
      applicationsInterval: null,
      statuses: [
        "PENDING",
        "ACCEPTED",
        "REJECTED"
      ]
    }
  },
  watch: {
    status: function() {this.loadApplications()}
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/css/colors.scss";

.application-wrapper {
  background: $neutral--darkest;
}
</style>
