<template>
    <div class="spinner-container">
        <div class="spinner"></div>
    </div>
</template>

<script>
export default {
    name: "LocalSpinner",
}
</script>

<style lang="scss" scoped>
@import "../assets/css/colors.scss";

.spinner-container {
    position: relative;
}

.spinner {
    min-height: var(--size, 30px);
    min-width: var(--size, 30px);
    max-height: var(--size, 30px);
    max-width: var(--size, 30px);

    border: 5px solid darken($soft-green, 50);
    border-radius: 50%;
    animation: spin 500ms infinite forwards linear;
    border-left: 5px solid $soft-green;

}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}
</style>
