<template>
  <div class="px-button"
       :class="{'px-button--danger':danger,'px-button--success':success,'px-button--primary':primary}">
    <i id="loader" class="fas fa-spinner"></i>
    <div class="px-button__content">
      <i class="px-button__icon" :class="`icon-${icon}`" v-if="icon"></i>
      <Roller class="" v-if="btnText" :text="btnText" :isNumberFormat="false" :charList="rollerCharlist"
              defaultChar="A"></Roller>
      <slot></slot>
    </div>
  </div>
</template>
<script>
import Roller from "vue-roller";

export default {
  name: "PxButton",
  data() {
    return {
      rollerCharlist: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"],
      btnText: null,
      danger: false,
      success: false,
      primary: false,
      isAnimationGoing: false
    }
  },
  props: {
    icon: {
      type: String,
    },
    textInput: {
      type: String
    }
  },
  components: {
    Roller
  },
  mounted() {
    this.btnText = this.textInput
  },
  methods: {
    showMessage(message, type) {
      this[type] = true
      let time = message.length * 80
      if (time <= 750) time = 750
      this.isAnimationGoing = true

      this.btnText = message
      setTimeout(() => {
        this.btnText = this.textInput
        this[type] = false
        this.isAnimationGoing = false
      }, time);
    },
    setText(text, type) {
      if (type) this[type] = true
      this.btnText = text
    }
  }
};
</script>
<style lang="scss">
@import "../assets/css/colors";

$map_badge_colors: (
    "primary": $soft-blue,
    "secondary": #6c757d,
    "success": $soft-green,
    "danger": $soft-red,
    "orange": $soft-orange,
    "purple": $soft-purple
);

.px-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  display: inline-block;
  padding: 5px 20px;
  //border-radius: 4px;
  border: none;
  text-transform: uppercase;
  font-size: .8rem;
  letter-spacing: 1px;
  height: 2.6rem;
  text-align: center;
  position: relative;

  #loader {
    display: none;
    position: absolute;
    top: 50%;
    right: 5%;
    animation-iteration-count: infinite;
    animation-name: rotate;
    animation-duration: 2s;
  }

  &--loading {
    #loader {
      display: block;
    }
  }

  &__content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & i {
      margin-right: 10px;
      font-size: 1rem;
    }
  }

  &[disable] {
    pointer-events: none;
    opacity: .5;
    cursor: not-allowed;
  }

  &.disabled {
    pointer-events: none;
    opacity: .5;
    cursor: not-allowed;
  }

  &.rounded {
    height: 3rem;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50% !important;

    & i {
      margin-right: 0 !important;
    }

    &.px-button__content {
      width: 100% !important;
      height: 100% !important;
    }
  }

  @function text-color($color) {
    @if (lightness($color) > 50) {
      @return #000;
    } @else {
      @return #FFF;
    }
  }

  @function dark-color($color) {
    @return darken($color, 15);
  }

  @each $name, $color in $map_badge_colors {
    &--#{$name} {
      background-color: $color;
      color: text-color($color);
      cursor: pointer;

      &:hover {
        background-color: dark-color($color);
      }

      &:active {
        box-shadow: 0 0 8px $color;
      }

      &.outline {
        color: $color;
        background: none;
        border: 1px solid;

        &:hover {
          background: $color;
          color: text-color($color);
          border-color: transparent;
        }
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: translateY(-50%) rotateZ(0);
  }
  50% {
    transform: translateY(-50%) rotateZ(360deg);
  }
  100% {
    transform: translateY(-50%) rotateZ(0);
  }
}
</style>
