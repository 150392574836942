<template>
    <base-modal
            :title="modal.title"
            :buttons="modal.buttons"
            :close="modal.close">
        <div class="">
            Do you really want to cancel the join request?
        </div>
    </base-modal>
</template>

<script>
    import BaseModal from "./BaseModal";

    export default {
        name: "CancelRequestModal",
        components: {BaseModal},
        props: [
            "onClose",
        ],
        data() {
            return {
                modal: {
                    title: "Cancel Join Request",
                    buttons: [
                        {
                            name: "confirm",
                            class: "px-button--success",
                            action: () => this.confirm()
                        },
                    ],
                    close: () => this.selfClose()
                },
            }
        },
        methods: {
            selfClose() {
                this.$emit("close");
            },
            confirm() {
                this.onClose(true);
                this.selfClose();
            },
        }
    }
</script>

<style scoped>

</style>
