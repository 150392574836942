import httpService from "./http";

const hrpService = {
  getHrpTickets() {
      return httpService.get("/v2/hrp/tickets");
  },
  revokeTicket(ticketId) {
      const httpOptions = {
          data: {
              "ticketId":ticketId
          }
      }
      return httpService.post("/v2/hrp/revoke-ticket", httpOptions);
  },
  canCreateEvents() {
    return httpService.get("/v2/hrp/can-create");
  },
  createEvent(data) {
    const httpOptions = {data}
    return httpService.post("/v2/hrp/create-event", httpOptions);
  }

}

export default hrpService;
