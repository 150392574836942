<template>
  <base-modal :title="modal.title" :buttons="modal.buttons" :close="modal.close">
    <form class="row my-3 m-0 p-0" @submit.prevent>
      <div class="form-group m-0 p-0 col-md-6" id="first-name-container">
        <div class="mx-3 my-1">
          <label for="first-name" class="card__label">First Name<span class="text-danger ml-2 mr-1">*</span></label>
          <input v-model="userData.firstName" type="text" class="form-control" id="first-name" placeholder="First Name">
        </div>
      </div>
      <div class="form-group m-0 p-0 col-md-6" id="last-name-container">
        <div class="mx-3 my-1">
          <label for="last-name" class="card__label">Last Name<span class="text-danger ml-2 mr-1">*</span></label>
          <input v-model="userData.lastName" type="text" class="form-control" id="last-name" placeholder="Last Name">
        </div>
      </div>
      <div class="form-group m-0 p-0 col-md-6" id="email-container">
        <div class="mx-3 my-1">
          <label for="email" class="card__label">Email<span class="text-danger ml-2 mr-1">*</span></label>
          <input v-model="userData.email" type="email" class="form-control" id="email" placeholder="Email">
        </div>
      </div>
      <div class="form-group m-0 p-0 col-md-6" id="phone-number-container">
        <div class="mx-3 my-1">
          <label for="phone-number" class="card__label">Phone Number<span class="text-danger ml-2 mr-1">*</span></label>
          <input pattern="[0-9]" inputmode="numeric" v-model="userData.phoneNumber" type="text" class="form-control"
            id="phone-number" placeholder="Phone Number">
        </div>
      </div>
      <div class="form-group m-0 p-0 col-md-12" id="cv-container">
        <div class="mx-3 my-1">
          <label for="cv" class="card__label">cv upload<span class="text-danger ml-2 mr-1">*</span></label>
          <input @change="uploadFile" ref="file" type="file" class="form-control" id="cv" placeholder="CV">
          <div v-if="!userData.file" @click="handleFileUpload"
            class="form-control d-flex justify-content-center align-items-center upload-button">
            <p class="card__label m-0 p-0 text-uppercase">upload cv</p>
          </div>
          <div @click="userData.file = null" v-else
            class="form-control d-flex justify-content-center align-items-center upload-button--selected">
            <p class="card__label m-0 p-0 text-uppercase">file selected - (click to deselect)</p>
          </div>
        </div>
      </div>
      <div class="form-group container col-md-12" id="gdpr">
        <div class="fw-bold p-2 gdpr-checkbox-container border-0 mx-2 d-flex justify-content-start align-items-center"
          style="font-size: 20px;">
          <input v-model="userData.gdpr" type="checkbox" name="gdpr" id="gdpr-checkbox" />
          <span class="text-danger ml-2 mr-1">*</span>I authorize the processing of personal data pursuant to GDPR
          Regulation 2016/679
        </div>
      </div>
    </form>
  </base-modal>
</template>
<script>
import BaseModal from "@/components/BaseModal";
import jobService from "@/api/job";

export default {
  name: "JobApplyModal",
  data() {
    return {
      userData: {
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        file: null,
        gdpr: null
      },
      modal: {
        title: `Applying job ${this.jobName}`,
        buttons: [
          {
            name: "Apply",
            class: "px-button--success",
            action: this.applyForJob
          },
          {
            name: "Close",
            class: "px-button--danger",
            action: this.selfClose
          }
        ],
        close: this.selfClose
      }
    }
  },
  props: {
    jobName: {
      type: String,
      required: true
    },
    jobId: {
      type: String,
      required: true
    },
    appliedCallback: {
      type: String,
      required: false,
      default: () => { }
    }
  },
  methods: {
    handleFileUpload() {
      this.$refs.file.click();
    },
    uploadFile() {
      this.userData.file = this.$refs.file.files[0];
    },
    selfClose() {
      this.$emit("close");
    },
    applyForJob() {
      this.$parent.loading = true;
      const data = new FormData();
      const userData = this.userData;

      for (const [key, value] of Object.entries(userData)) {
        if (!value) {
          this.flashError("Cannot apply, invalid data")
          return
        }
        data.append(key, value);
      }

      jobService.applyForJob(this.jobId, data)
        .then((response) => {
          const manageableMessage = !!response.data && !!response.data.message;
          if (manageableMessage) {
            this.flashSuccess(response.data.message)
            this.appliedCallback()
          }
        })
        .catch((error) => {
          const manageableError = !!error.data && !!error.data.errorDescription;
          if (manageableError) this.flashError(error.data.errorDescription);
        })
        .finally(() => {
          this.$parent.loading = false;
          this.selfClose();
        })
    }
  },
  components: {
    BaseModal
  }
}
</script>
<style scoped lang="scss">
@import "../assets/css/colors.scss";

.upload-button:hover {
  background-color: rgba($neutral--darkest, 0.9);

}

.upload-button {
  &--selected {
    &:hover {
      background-color: rgba($soft-red, 0.5);
    }
  }
}

#gdpr-checkbox {
  width: 20px;
  height: 20px;
}
</style>
