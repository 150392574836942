import httpService from "./http";

const appService = {
    gSearch(data) {
        const httpOptions = {
            data: `gSearchTerm=${data.gSearchTerm}`
        };
        return httpService.post("/gsearch", httpOptions);
    },
    uSearch(data, include=null) {
        // find user for companies
        const httpOptions = {
            data: `searchTerm=${data.searchTerm}`
        };
        if (include !== null) httpOptions["data"] += `&include=${include}`
        return httpService.post("/u-search", httpOptions);
    },
    subscribePushNotifications(subscription) {
        const httpOptions = {
            data: JSON.stringify(subscription),
            headers: {"Content-Type": "application/json"}
        };
        return httpService.post("/save-push-subscription", httpOptions);
    },
    sendMessage(message) {
        const httpOptions = {
            data: `message=${encodeURIComponent(message)}`
        };
        return httpService.post("/message", httpOptions);
    },
    getRoutes() {
        return httpService.get("/routes");
    },
    getMenus() {
        return httpService.get("/menus");
    },
    getTourSteps() {
        return httpService.get("/tour-steps");
    },
    askQuotation(data) {
        const httpOptions = {
            data
        };
        return httpService.post("/quotation", httpOptions);
    }
};

export default appService;
