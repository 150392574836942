<template>
  <base-modal :close="modal.close">
    <div class="full-page-modal container-fluid d-flex flex-column justify-content-between">
      <div class="row flex-column align-items-center">
        <div class="col-3 mb-3">
          <img src="../assets/images/logo-pwnx-darkmode.svg" alt="">
        </div>
        <div class="col-9 text-center">
          <h2 class="mb-0" v-if="labType == null">Congratulations for solving {{ labName }}</h2>
          <h2 class="mb-0" v-if="labType != null">Congratulations for solving {{ labType }} labs!</h2>
          <p>Share the result on your social media profiles!</p>
        </div>
        <div class="col-3 d-flex justify-content-around">
          <div class="col mr-1 share-button text-center">
            <i @click="linkedinShare(submissionId)" class="fab p-2 fa-linkedin-in"></i>
          </div>
          <div class="col ml-1 share-button text-center">
            <i @click="twitterShare(submissionId)" class="fab p-2 fa-twitter"></i>
          </div>
        </div>
      </div>
      <div class="row flex-column align-items-center">
        <div class="col-4 text-center">
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from "./BaseModal";

export default {
  name: "shareLabResultModal",
  components: {
    BaseModal,
  },
  props: {
    labName: String,
    submissionId: String,
    labType:{
      type:String,
      default:null
    }
  },
  data() {
    return {
      modal: {
        close: () => this.selfClose()
      },
    }
  },
  methods: {
    selfClose() {
      this.$emit("close");
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/css/colors.scss";

.share-button {
  background: rgba($neutral--soft, .2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    background: rgba($neutral--soft, .4);
  }
}
</style>
