<template>
    <section>
    <div class="container-fluid table vh-100" v-if="brokenInternet">
        <div class="row flex-column align-items-center justify-content-center">
            <h1 class="z1 glitch" data-text="OPS...">OPS...</h1>
        </div>
        <div class="not-found row flex-column align-items-center justify-content-center">
            <div class="z1 text">It looks like you broke the internet</div>
          <px-button class="px-button--secondary z1 mt-3" @click.native="$router.go(-2)">
            Go back and pretend you did nothing
          </px-button>
          <px-button class="px-button--secondary outline mt-3 z1" @click.native="fixInternet">
            Fix the error
          </px-button>            
        </div>
    </div>
    <div class="container-fluid table vh-100" v-else>
        <div class="row flex-column align-items-center justify-content-center">
            <h2 class="main-title-1 mt-5">Thank you, {{user}}, you fixed the internet!</h2>
            <p class="not-found">You will be remembered over the centuries as the savior of the internet</p>
            <small class="not-found">You will be redirected to the homepage soon</small>
        </div>
    </div>
    </section>
</template>

<script>
    import {mapState} from "vuex";
    import PxButton from "@/components/PxButton";

    export default {
        name: "PageNotFound",
      components: {PxButton},
      computed: mapState({
            user: state => state.user.username
        }),
        data() {
            return {
                brokenInternet: true,
            }
        },
        beforeCreate() {
            this.$parent.breadcrumbs = [{name: "page not found"}];
        },
        mounted() {
            
        },
        methods: {
            fixInternet() {
                this.brokenInternet = false;
                setTimeout(() => this.$router.push("/home"), 5000);
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../assets/css/colors";

    .not-found {
      color: $neutral--light;

        & .text {
            font-size: 2.5rem;
            font-weight: 700;
            text-shadow: 0 0 1rem #1af0dc;
        }
    }

    .z1 {
        z-index: 10001;
    }

    .marquee {
        display: block;
        white-space: nowrap;
        position: absolute;
        animation: marquee 2s linear infinite;
        user-select: none;
        z-index: 1000;
    }

    @keyframes marquee {
        0% {
            left: 100%;
        }
        100% {
            left: -100%;
        }
    }


    :root {
        --f-size: 15;
        --f-unit: 1vmin;
        --f: calc(var(--f-size) * var(--f-unit));
        --bg: #181717;
    }

    @import url('https://fonts.googleapis.com/css?family=Bungee+Shade');
    h1 {
        font-family: 'Bungee Shade', cursive;
        background-color: var(--bg);
        display: flex;
        min-height: 10vh;
        margin: 0;
        overflow: hidden;
    }
</style>
