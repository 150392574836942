<template>
    <base-modal
            :title="modal.title"
            :buttons="modal.buttons"
            :close="modal.close">
        <div class="">
            <p>
                Do you really want to leave your company?
            </p>
            <form class="" autocomplete="off" @submit.prevent>
                <div class="form-group">
                <label class="" for="leave-company">If you are sure please insert the company name:</label>
                <input id="leave-company" class="form-control" type="text" autocomplete="off"
                       placeholder="Company name" v-model="userCompany">
                </div>
            </form>
        </div>
        <div>
            <p class="important">
                This action is not reversible.
            </p>
        </div>
    </base-modal>
</template>

<script>
    import BaseModal from "./BaseModal";

    export default {
        name: "LeaveCompanyModal",
        components: {BaseModal},
        props: [
            "returnUserChoice"
        ],
        data() {
            return {
                userCompany: null,
                modal: {
                    title: "Leave the company",
                    buttons: [
                        {
                            name: "leave",
                            class: "px-button--success",
                            action: () => this.selfClose(true, this.userCompany)
                        },
                    ],
                    close: () => this.selfClose(false)
                },
            }
        },
        methods: {
            selfClose(choice, userCompany) {
                this.returnUserChoice(choice, userCompany);
                this.$emit("close");
            }
        }
    }
</script>

<style scoped>
.important {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #dc3545;
    font-weight: 700;
    text-align: justify;
}
</style>
