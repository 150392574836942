<template>
  <div class="container">
    <h2 class="fw-bold my-2">Frequently Asked Questions (FAQ)</h2>
    <div class="row my-1 m-0 p-0" v-for="(faq, index) of faqs" :key="index">
      <div class="row m-0 p-0 col-12 faq-title" @click="expandFaq('faq-' + index)">
        <div class="col-11 m-0 p-0">
          <h2 class="m-0 p-0 fw-bold">
            {{ faq.title }}
          </h2>
        </div>
        <div class="col-1 m-0 p-0 d-flex justify-content-end">
          <i class="arrow down" :id="'faq-' + index + '-arrow'"></i>
        </div>
      </div>
      <div class="col-12 m-0 p-0 faq-response-content" :id="'faq-' + index">
        <p class="m-0 p-0">
          {{ faq.answer }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Faq",
  data() {
    return {
      faqs: [
        {
          title: "What is a Capture the Flag (CTF) lab?",
          answer: "A CTF lab is a cybersecurity training environment where participants engage in simulated challenges to solve various security scenarios and exploit vulnerabilities. It aims to improve participants' skills in ethical hacking, penetration testing, and cybersecurity."
        },
        {
          title: "How does PWNX work?",
          answer: "Our CTF lab service provides access to a secure virtual environment with a range of challenges to solve. Participants can register, select the desired lab, and start competing individually or in teams. They will be given points for each successfully solved challenge, and the leaderboard will track their progress."
        },
        {
          title: "Who can use PWNX?",
          answer: "Our CTF lab service is open to anyone interested in learning or enhancing their cybersecurity skills. It caters to beginners, intermediate learners, and experienced professionals alike."
        },
        {
          title: "What skill level is required to participate?",
          answer: "Our labs are designed to accommodate all skill levels. We offer challenges ranging from basic to advanced, allowing participants to choose tasks that match their expertise."
        },
        {
          title: "Can I participate as a team or an individual?",
          answer: "You can participate in our CTF labs whenever you want as an individual or as part of a team during public CTF events. Collaborating with teammates can be an excellent way to enhance your problem-solving skills and tackle more complex challenges together."
        },
        {
          title: "What do I need to access PWNX labs?",
          answer: "All you need is a computer with an internet connection and a modern web browser. Our labs are cloud-based, and all you need is a client VPN software."
        },
        {
          title: "Is the CTF lab environment safe and legal?",
          answer: "Yes, our CTF lab environment is entirely safe and legal. It is a controlled virtual environment isolated from the real world. All the challenges are designed to be ethical and comply with applicable laws."
        },
        {
          title: "Can I track my progress and performance?",
          answer: "Absolutely! Our CTF lab service includes a comprehensive dashboard that allows you to track your progress, view completed challenges, and monitor your points. You can also see your rank on the leaderboard and compare your performance with others."
        },
        {
          title: "Are there rewards for completing challenges?",
          answer: "While we do not offer monetary rewards, participants will receive recognition and achievement badges based on their performance. Completing challenges successfully will also boost your cybersecurity credentials and enhance your employability."
        },
        {
          title: "How can I report issues or seek assistance during the lab?",
          answer: "We have a dedicated support team available to assist you with any technical issues or questions you may encounter during the lab. You can reach out to them through our Discord server reachable from https://play.pwnx.io (link on top-right corner)."
        },
        {
          title: "Are there any time limits for completing challenges?",
          answer: "Each challenge may have its time limit (i.e. during a public CTF event). However, most labs provide ample time for participants to complete their tasks successfully."
        },
        {
          title: "Can I host my own CTF lab using your service?",
          answer: "Yes, we offer enterprise solutions for organizations interested in hosting their CTF labs using our platform. Please contact at info@pwnx.io for more information on custom solutions."
        },
        {
          title: "How can I get started with PWNX?",
          answer: "To begin, simply visit our website, create an account, and explore the available labs. Select the one that interests you and dive into the exciting world of cybersecurity challenges!"
        },
        {
          title: "What plans are available for paid individual lab access?",
          answer: "The \"subscription\" plan is available, granting access to all premium labs and automatically renewing on a monthly basis. Additionally, there is the \"token\" plan, which provides access to all premium labs on an hourly basis. Each hour of a premium lab costs 3 tokens."
        },
        {
          title: "How can I start a hiring selection with PWNX?",
          answer: "Contact us at info@pwnx.io, and we will walk you through our packages at the best price available. If you have any other questions or need further assistance, feel free to reach out to our support team. Happy hacking!"
        },
        {
          title: "How can I change my username?",
          answer: "To change your username click on the \"update profile\" link in the left-down corner, there you will find your account information and the ability to change the username by clicking on the pencil near your current username."
        }
      ]
    }
  },
  beforeCreate() {
    this.$parent.breadcrumbs = [{name: "FAQ"}]
  },
  methods: {
    expandFaq(faqId) {
      const contents = document.querySelectorAll(".faq-response-content");
      for (const content of contents) {
        const found = content.id === faqId
        const arrow = document.querySelector("#" + content.id + "-arrow")
        if (!found) {
          content.classList.remove("active")
          arrow.classList.remove("up");
          arrow.classList.add("down");
          continue;
        }

        const isExpanded = content.classList.contains("active");

        if (isExpanded) {
          content.classList.remove("active");
          arrow.classList.remove("up");
          arrow.classList.add("down")
        } else {
          content.classList.add("active")
          arrow.classList.remove("down");
          arrow.classList.add("up")
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import "../assets/css/colors.scss";

.faq-title {
  background: $neutral--medium;
  padding: 5px!important;
  display: flex!important;
  flex-direction: row!important;
  align-items: center!important;
  color: $soft-green;
}

.faq-response-content {
  position: relative;
  width: 100%;
  max-height: 0px !important;
  // overflow: hidden;
  height: 0px !important;
  transform: scaleY(0%) !important;
  transform-origin: top;
  text-align: justify;
  transform-origin: top;
  transition: all 0.2s ease;
}

.faq-response-content.active {
  position: relative;
  width: 100%;
  height: 100%!important;
  max-height: 100%!important;
  text-align: justify;
  transform: scaleY(100%) !important;
  transform-origin: top;
  z-index: 10;
  transform-origin: top;
  transition: all 0.2s ease;
}

.faq-response-content.active p {
  // position: absolute;
  padding: 2px 20px!important;
  // background: $neutral--medium!important;
  background: linear-gradient(to bottom, $neutral--medium, rgba(0, 0, 0, 0.5));
  border-radius: 0px 0px 9px 9px;
}

.arrow {
  border: solid $soft-green;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-right: 15px;
  cursor: pointer;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.align-right {
  display: flex;
  justify-content: flex-end;
}
</style>
