<template>
    <base-modal :title="title" :buttons="modal.buttons" :close="modal.close">
        <p v-html="getMarkdown()"></p>
    </base-modal>
</template>
<script>
import BaseModal from "@/components/BaseModal";
import { marked } from "marked";

export default {
    name: "EventBannerTextModal",
    components: {
        BaseModal,
    },
    props: {
        title: {
            type: String,
            required: true
        },
        bannerText: {
            type: String,
            required: true
        },
        closeCallback: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            modal: {
                buttons: [
                    {
                        name: "Close",
                        class: "px-button--danger",
                        action: () => this.selfClose()
                    }
                ],
                close: () => this.selfClose()
            }
        }
    },
    methods: {
        getMarkdown() {
            return marked(this.bannerText);
        },
        selfClose() {
            this.closeCallback();
            this.$emit("close");
        }
    }
}
</script>
