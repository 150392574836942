<template>
  <base-modal 
    :title="modal.title"
    :buttons="modal.buttons"
    :close="modal.close">
    <template v-if="userInfo != null">
      <form autocomplete="off" class="row m-0 p-0">
        <div 
          class="form-group my-2 m-0 p-0" 
          :class="getCol(index)" 
          v-for="(uinfo, index) of userInfoArray" :key="index">
          <div class="px-2">
            <label :id="uinfo.key.replace(' ', '-')" class="card__label">
              {{ uinfo.key }}
            </label>
            <div 
              :id="uinfo.key.replace(' ', '-')" 
              class="form-control disabled d-flex align-items-center">
              {{ uinfo.value }}
            </div>
          </div>
        </div>
      </form>
    </template>
    <template v-else>
      <h3 class="text-uppercase my-3">No data for this user</h3>
    </template>
  </base-modal>
</template>
<script>
import BaseModal from "./BaseModal";
import recruiterService from "@/api/recruiter.js";

export default {
  name: "InfoModal",
  data() {
    return {
      modal: {
        title: "User Info",
        buttons: [
          {
            name: "Close",
            action: () => this.selfClose(),
            class: "px-button--danger"
          }
        ],
        close: () => this.selfClose()
      },
      userInfo: null,
      userInfoArray: [],
    }

  },
  components: {
    BaseModal
  },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    getCol(index) {
      const length = this.userInfoArray.length
      if (!this.isEven(length) && index === (length - 1)) {
        return "col-12"
      }
      return "col-md-6"
    },
    isEven(number) {
      return number % 2 === 0;
    },
    prepareUserInfo() {
      const userInfoArray = [];

      const isUppercase = (char) => {
        const charCode = char.charCodeAt(0)
        return Array(26).fill(null).map((_, i) => 65 + i).some(item => item == charCode)
      }

      for (const [key, value] of Object.entries(this.userInfo)) {
        let preparedKey = "";

        for (const char of key) {
          if (isUppercase(char)) {
            preparedKey += " " + char.toLowerCase()
            continue
          }
          preparedKey += char
        }
        userInfoArray.push({key: preparedKey, value: value})
      }

      this.userInfoArray = userInfoArray
    },
    selfClose() {
      this.$emit("close");
    },
    getUserInfo() {
      this.$parent.loading = true
      recruiterService.getUserInfo(this.userId)
        .then((response) => {
          this.userInfo = response.data.info;
        }).catch((error) => {
          const manageableError = !!error.data && error.data.errorDescription;
          if (manageableError) this.flashError(error.data.errorDescription);
        }).finally(() => {
          this.$parent.loading = false
          this.prepareUserInfo()
        })

    }
  }
}

</script>
<style scoped></style>
