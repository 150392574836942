<template>
  <component :is="statisticsComponent"></component>
</template>
<script>
import EventStatistics from "@/views/EventStatistics";
import EventQuizStatistics from "@/views/EventQuizStatistics";
import recruiterService from "@/api/recruiter";

const QuizRoles = Object.freeze({
  COMPLIANCE: "COMPLIANCE"
});

export default {
  name: "EventStatisticsWrapper",
  components: {
    EventStatistics,
    EventQuizStatistics
  },
  created() {
    this.$parent.loading = true;
    const eventId = this.eventId;
    recruiterService.getEventRole(eventId)
      .then(response => {
        const role = response.data.role;
        if(Object.keys(QuizRoles).some(r => r === role)) {
          this.statisticsComponent = "EventQuizStatistics";
        } else {
          this.statisticsComponent = "EventStatistics";
        }
      })
      .finally(() => {
        this.$parent.loading = false;
        console.log(this.statisticsComponent)
      })
  },
  data() {
    return {
      eventId: this.$route.params.eventId,
      statisticsComponent: null
    }
  }
}
</script>
