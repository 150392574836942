import httpService from "./http";

const premiumService = {
    getPackages() {
        return httpService.get("/v2/premium/packages");
    },
    createPremium(data) {
        const httpOptions = {
            data
        };
        return httpService.post("/v2/premium/new", httpOptions)
    },
    getOvpnConfig() {
        return httpService.get("/v2/premium/vpnconfig");
    },
    approvePremium(data) {
        const httpOptions = {
            data
        };
        return httpService.post(`/v2/premium/approve`, httpOptions);
    },
    getTermsAndConditions() {
        return httpService.get(`/v2/premium/privacy`);
    },
    cancelPremium(data) {
        const httpOptions = {
            data
        };
        return httpService.post(`/v2/premium/cancel`, httpOptions);
    },
    getPremiumWebLabs(page) {
        return httpService.get(`/v2/labs/premium/web?page=${page}`);
    },
    getPremiumWeb3Labs(page) {
        return httpService.get(`/v2/labs/premium/web3?page=${page}`);
    },
    getRetiredLabs() {
        return httpService.get("/v2/labs/premium/retired");
    },
    getLabsCount() {
        return httpService.get("/v2/premium/labs/count");
    },
    checkVoucher(data) {
        const httpOptions = {
            data
        }
        return httpService.post("/v2/premium/check-voucher", httpOptions);
    },
}
export default premiumService;
