<template>
    <base-modal
    :title="modal.title"
    :buttons="modal.buttons"
    :close="modal.close"
    :isEventAdd="true"
    customStyle="min-width:1000px;">
        <div :style="{maxHeight: 600 + 'px', overflowY: 'scroll'}">
            <div class="d-flex flex-column justify-content-center" v-for="(file, index) in files" :key="index">
                <!-- <div v-if="index === 0">
                    <px-button 
                        class="px-button--secondary my-2" 
                        textInput="Request Support"
                        @click.native="requestSupport">
                    </px-button>
                </div> -->
                <h2>Filename: {{ getFilename(file) }} </h2>
                <div class="row">
                    <div id="initial" class="col-6">
                        <p>initial</p>
                        <code-highlight :filename="getFilename(file)" ext="javascript" :content="getText(file) == '' ? 'File deleted':getText(file)">
                        </code-highlight>
                    </div>
                    <div id="final" class="col-6">
                        <p>final</p>
                        <code-highlight :filename="getFilename(file)" ext="javascript" :content="getText(file, 'final') == '' ? 'File deleted':getText(file, 'final')">
                        </code-highlight>
                    </div>
                </div>
            </div>
        </div>
    </base-modal>
</template>
<script>
import BaseModal from './BaseModal';
import recruiterService from '../api/recruiter';
// import PxButton from './PxButton';
import CodeHighlight from './CodeHighlight';

export default {
    name:"FileDiffModal",
    components: {
        'base-modal':BaseModal,
        // PxButton,
        CodeHighlight
    },
    props:{
        eventId:{
            type: String,
            required: true
        },
        userId: {
            type: String,
            required: true
        },
        labId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            modal:{
                title: "Event file diffs",
                buttons: [
                    {
                        name:"Close",
                        class:"px-button--danger",
                        action:() => this.selfClose()
                    }
                ],
                close: () => this.selfClose()
            },
            files: null
        }
    },
    created() {
        const eventId = this.eventId;
        const userId = this.userId;
        const labId = this.labId;

        this.$parent.loading = true;
        recruiterService.getFileDiff(eventId, labId, userId)
            .then(response => {
                this.files = response.data.files;
            }).catch(error => {
                const manageableError = !!error.error && !!error.errorDescription
                if (manageableError) {
                    this.flashError(error.errorDescription);
                }
            }).finally(() => (this.$parent.loading = false))
    },
    methods: {
        selfClose() {
            this.$emit('close')
        },
        getText(file, type) {
            const obj = file[Object.keys(file)[0]];
            if (!type) return obj.initial

            // const diff = this.makeDiff(obj.final, obj.initial)
            // return diff.toString()
            return obj.final;
        },
        getFilename(file) {
            if (file === undefined) return;
            return Object.keys(file)[0]
        },
        getFileExt(file) {
            const filename = this.getFilename(file);
            if (!filename) return "javascript"
            return filename.includes(".") ? filename.split(".")[1]:"javascript"
        },
        requestSupport() {
            recruiterService.requestSupport(this.eventId)
                .then(response => {
                    const manageableResponse = !!response.data
                    if (manageableResponse) this.flashSuccess(response.data);
                }).catch(error => {
                    const manageableError = !!error.data && !!error.data.errorDescription
                    if (manageableError) this.flashError(error.data.errorDescription);
                })
        },
        makeDiff(text, text2) {
            const Diff = require("diff");
            require("colors");

            let result = Diff.diffLines(text, text2).map((part) => {
                const color = part.added ? 'green':part.removed ? 'red':'grey';
                return part.value[color];
            });
            return result;
        },
    }
}
</script>
<style lang="scss">

</style>