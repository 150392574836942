<template>
    <div>
        <base-modal :title="spoilerModal.title" :buttons="spoilerModal.buttons" :action="spoilerModal.action"
            :close="spoilerModal.close" v-if="!spoilerAccept">
            <b>Spoilers about the lab {{ lab_name }} will be shown after this message</b>
        </base-modal>
        <base-modal :title="modal.title" :buttons="modal.buttons" :action="modal.action" :close="modal.close"
            v-if="spoilerAccept">
            <template v-if="writeup === null">
                <p>{{ writeup }}</p>
            </template>
            <template v-else>
                <div class="row">
                    <div class="col">
                        <div class="hide-scroll" :style="{ 'max-height': 600 + 'px', 'word-wrap': 'break-word' }"
                            v-html="writeup">
                        </div>
                    </div>
                </div>
            </template>
        </base-modal>
    </div>
</template>

<script>
import BaseModal from './BaseModal';
import labService from '../api/lab';
import { marked } from 'marked';
import { sanitize } from 'dompurify';

export default {
    name: "WriteupModal",
    components: { BaseModal },

    props: {
        lab_id: {
            type: String,
            required: true
        },
        lab_name: {
            type: String,
            required: true
        },
        stopLoading: {
            type: Function,
            required: true
        }
    },

    data() {
        return {
            // pdfPath: null,
            modal: {
                title: null,
                buttons: [
                    {
                        name: "Close",
                        class: "px-button--danger",
                        action: this.confirm,
                    }
                ],
                close: this.confirm
            },
            writeup: null,
            spoilerAccept: false,
            spoilerModal: {
                title: "Spoiler Alert",
                buttons: [
                    {
                        name: "Accept",
                        class: "px-button--success",
                        action: this.getLabWriteup
                    },
                    {
                        name: "Decline",
                        class: "px-button--danger",
                        action: this.confirm

                    }
                ],
                close: this.confirm
            }
        }
    },

    methods: {
        selfClose(bool) {
            this.$emit("close");
            return bool
        },
        confirm() {
            this.selfClose(true);
        },
        getLabWriteup() {
            this.stopLoading();
            this.modal.title = `"${this.lab_name}" Writeup`;
            labService.getLabWriteup(this.lab_id).then((response) => {
                if (response.status == 404) throw new Error();
                this.writeup = sanitize(marked(response.data));
            }).catch((error) => {
                const manageableError = !!error.data && !!error.data.errorDescription;
                if (manageableError) this.flashError(error.data.errorDescription);
                this.selfClose()
            }).finally(() => {
                this.stopLoading();
                this.spoilerAccept = true;
            })
        }
    }
}
</script>
<style lang="scss">
::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
}

.hide-scroll {
    overflow: scroll;
    scrollbar-width: none;
    word-wrap: break-word;


    code {
        color: red;
        scrollbar-width: 10px;
    }

    ::-webkit-scrollbar {
        width: 1em;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }
}
</style>