<template>
    <base-modal
    :title="modal.title"
    :buttons="modal.buttons"
    :close="modal.close">
        <template v-if="this.tokenBased">
            <div class="my-4" id="warning" v-if="action === 'start'">
                <p>
                    Starting this lab will cost you {{ this.lab.hcost }} tokens, the lab will stop after {{ hours }} hour(s) if you don't extend it.
                    In the last 10 minutes PWNX will warn you about the imminent lab stop, and will ask you if you want to extend the lab.
                    You will pay the cost of the lab for every hour the lab is extended, meaning if the lab costs {{ this.lab.hcost }} tokens
                    and you want to extend for 2 hours, you'll pay {{ this.lab.hcost * 2}} tokens. Do you want to continue?
                </p>
                <form action="#" @submit.prevent>
                    <label class="card__label" for="hours">Start for {{ hours }} hours</label>
                    <input
                        id="hours"
                        min="1"
                        v-model="hours"
                        class="form-control"
                        :class="{'border border-danger':shouldBorderInput}"
                        type="number"
                        placeholder="Hours">
                    <p>the lab will cost {{ this.lab.hcost * hours }} tokens</p>
                    <template v-if="user.inCorporate">
                        <label class="card__label" for="payer">Payer</label>
                        <select v-model="payer" name="payer" id="payer" class="form-control">
                            <option v-for="(p, index) of payerList" :value="p" :key="index">{{ p }}</option>
                        </select>
                    </template>
                </form>
            </div>
            <div v-else class="my-4" id="warning">
                Are you sure you want to {{ this.action.toLowerCase() }} the lab {{ toUpperCase(lab.name) }}?
            </div>
        </template>
        <template v-else>
            <div class="my-4" id="warning" v-if="action === 'start'">
                This lab after 1 hour will stop by default, PWNX will warn you 10 minutes before the lab gets expired to extend it.
                Any callback / reverse shell needs to be done via a collaborator like utilities ( ngrok, interact-sh, Burp Collaborator, etc. ).
                Do you want to continue?
            </div>
            <div v-else id="warning" class="my-4">
                Are you sure you want to {{ this.action.toLowerCase() }} the lab {{ toUpperCase(lab.name) }}?
            </div>
        </template>
    </base-modal>
</template>
<script>
import BaseModal from './BaseModal';
import { mapState } from 'vuex';

export default {
    name: "LabManagmentModal",
    components: {BaseModal},
    computed: {
        shouldBorderInput() {
            const lab = this.lab;
            const hours = this.hours;
            const payer = this.payer;
            const user = this.user;

            if (!payer) return user.tokens < lab.hcost * hours;
            if (payer.toLowerCase() === "company") return user.corporateTokens < lab.hcost * hours;
            return user.tokens < lab.hcost * hours;
        },
        ...mapState({
            user: state => state.user
        })
    },
    data() {
        return {
            hours: 1,
            payer: null,
            payerList: ["USER", "COMPANY"],
            modal:{
                title:`${this.lab.name.toUpperCase()}`,
                buttons: [
                    {
                        name: "Confirm",
                        class: "px-button--success",
                        action: () => this.labAction(this.lab.id)
                    },
                    {
                        name: "Close",
                        class: "px-button--danger",
                        action: () => this.selfClose()
                    }
                ],
                close: () => this.selfClose()
            }
        }
    },
    props: {
        manageLab: {
            type: Function,
            required: true
        },
        lab: {
            type: Object,
            required: true
        },
        action: {
            type: String,
            required: false,
            default: 'start'
        },
        tokenBased: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    methods: {
        selfClose() {
            this.$emit("close")
        },
        toUpperCase(labName) {
            if (!labName) return labName;
            if (typeof labName !== 'string') return labName;
            return labName.toUpperCase()
        },
        labAction(labId) {
            const data = {"hours":this.hours};
            if (this.payer) data["payer"] = this.payer
            this.manageLab(labId, this.action, data);
            this.selfClose();
        }
    },
    created() {
        const actions = {
            'start':`STARTING ${this.toUpperCase(this.lab.name)}`,
            'stop':`STOPPING ${this.toUpperCase(this.lab.name)}`,
            'reset':`RESET ${this.toUpperCase(this.lab.name)}`
        };
        this.modal.title = actions[this.action];
        if (!this.modal.title) {
            this.selfClose()
        }
        if (this.user.inCorporate) this.payer = this.payerList[0];
    },
    watch: {
        'hours':function(new_) {
            if (!new_) this.hours = 1
        }
    }
}
</script>
