<template>
  <div>
    <div class="container-fluid mb-3">
      <div class="row">
        <h1 class="main-title-1 align-middle margin-right-20 ml-2">Teams</h1>
      </div>
      <div class="row">
        <PxButton @click.native="createTeam()" class="px-button--success newteam ml-2 mt-3" v-if="!this.$parent.user.team && shouldSeeUserBtns">
          create team
        </PxButton>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <TeamCard class="col-12 col-md-4 p-2 my-2" :team="team" v-for="team in teams" :key="team.key" :value="team.value"></TeamCard>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import PxButton from '../components/PxButton';
import teamService from "../api/team";
import TeamCard from '../components/TeamCard';
import UserType from '../enums/user';

export default {
  name: "Teams",
  components: {
    TeamCard,
    PxButton
  },
  computed: {
    shouldSeeUserBtns() {
      const { role:userRole } = this.$parent.user;
      return userRole == UserType.USER;
    },
    ...mapState({
      user: (state) => state.user,
    })
  },
  data() {
    return {
      searchedTeam: "",
      showTeams: [],
      teams: [],
      canCreateTeam: false,
      teamsInterval: null
    };
  },
  beforeCreate() {
    this.$parent.breadcrumbs = [{name: "teams"}];
  },
  created() {
    this.loadTeams();
    this.teamsInterval = window.setInterval(() => {
      this.loadTeams();
    }, 300000)
  },
  destroyed() {
    window.clearInterval(this.teamsInterval);
  },
  methods: {
    createTeam() {
      this.$router.push("/teams/add");
    },
    loadTeams() {
    teamService.getTeams()
      .then((response) => {
        this.teams = response.data.teams;
      }).catch(() => this.flashError("An error occurred while loading teams"))
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";


</style>
