<template>
  <base-modal :title="modal.title" :buttons="modal.buttons" :close="modal.close">
    <div class="form-group">
      <!--suppress HtmlFormInputWithoutLabel -->
      <input class="form-control center" type="text" placeholder="Flag" v-model="flagString" autocomplete="off">
    </div>
  </base-modal>
</template>

<script>
import BaseModal from "./BaseModal";
import labService from "../api/lab";
import ShareLabResultModal from "./ShareLabResultModal";

export default {
  name: "FlagSubmissionModal",
  components: {BaseModal},
  props: [
    'flagType',
    'labId',
    'onClose',
    // Events props
    'teamId',
    'eventId',
  ],
  data() {
    return {
      modal: {
        title: `Submit flag ${this.flagType.toUpperCase() == "USER" ? 1 : 2}`,
        buttons: [
          {
            name: 'send',
            class: 'px-button--success',
            action: () => this.submitFlag()
          },
        ],
        close: () => this.$emit('close')
      },
      flagString: '',
    }
  },
  mounted() {
  },
  methods: {
    submitFlag() {
      if (!this.flagString) {
        this.flashError("Flag cannot be empty");
        return;
      }
      this.$parent.loading = true;
      const payload = {
        labId: this.labId,
        flagType: this.flagType,
        flag: this.flagString
      };
      if (this.teamId && this.eventId) {
        payload.teamId = this.teamId;
        payload.eventId = this.eventId;
      }
      labService.submitLabFlag(this.labId, this.flagString, this.flagType)
          .then(response => {
            const successMessage = !!response && !!response.data && !!response.data.message;
            if (response.data.completed_id) {
              this.$modal.show(
                  ShareLabResultModal,
                  {
                    labName: response.data.lab_name,
                    submissionId: response.data.completed_id
                  },
                  {height: "auto"})
            }
            if (successMessage) {
              this.flashSuccess(response.data.message);
            }
            this.onClose(true);
          })
          .catch(error => {
            const manageableError = !!error && !!error.data && !!error.data.errorDescription;
            if (manageableError) {
              this.flashError(error.data.errorDescription);
            }
            this.onClose(false);
          })
          .finally(() => {
            this.$parent.loading = false;
            this.modal.close();
          });
    }
  }
}
</script>

<style scoped>

</style>
