<template>
    <base-modal
    :title="modal.title"
    :buttons="modal.buttons"
    :close="modal.close">
    <div class="modal-notes">
        <prism v-if="!isEmpty" language="javascript">{{notes}}</prism>
        <span v-if="isEmpty">{{notes}}</span>
    </div>
    </base-modal>
</template>
<script>
import BaseModal from './BaseModal';
import recruiterService from '../api/recruiter.js';
import Prism from 'vue-prism-component';
import "../assets/css/prism-funky.scss";
import "prismjs/prism";

export default {
    name: "RecruiterUserNotes",
    props: {
        userId: {
            type: String,
            required: true
        },
        eventId: {
            type: String,
            required: true
        },
        username: {
            type: String,
            default: "User",
            required: false
        }
    },
    data() {
        return {
            modal:{
                title: this.username + " notes", // Add username
                buttons: [
                    {
                        name: "Close",
                        class: "px-button--danger",
                        action: () => this.selfClose(),
                    }
                ],
                close: () => this.selfClose()
            },
            notes: null,
            isEmpty: true
        }
    },
    created() {
        this.getUserNotes()
    },
    methods: {
        selfClose() {
            this.$emit('close');
        },
        getUserNotes() {
            this.$parent.loading = true;
            const eventId = this.eventId;
            const userId = this.userId;
            recruiterService.getUserNotes(eventId, userId)
                .then((response) => {
                    const notes = response.data.notes;
                    if (notes.trim() == "") {
                        this.notes = "No notes for this user"
                        return
                    }
                    const html = new DOMParser().parseFromString(notes, "text/html");
                    this.notes = html.documentElement.textContent;
                    this.isEmpty = false;
                })
                .catch((error) => {
                    const manageableError = !!error.data.errorCode && !!error.data.errorDescription;
                    if (manageableError){
                        const {errorDescription:message } = error.data; 
                        this.flashError(message);
                        this.selfClose()
                    }
                })
                .finally(() => {
                    this.$parent.loading = false;
                })
        }
    },
    components:{
        BaseModal,
        Prism
    }
}
</script>
<style scoped lang="scss">
@import "../assets/css/colors.scss";
.modal-notes {
    padding: 5px;
    background-color: $neutral--darker;
    max-height: 400px;
    overflow-y:scroll;
    word-wrap: break-word;
}
</style>