<template>
  <base-modal :title="modal.title" :buttons="modal.buttons" :close="modal.close">
    <form @submit.prevent>
      <div class="form-group">
        <label for="team-name" class="card__label">Team Name</label>
        <input v-model="teamName" type="text" name="name" class="form-control w-100" id="team-name"
          placeholder="Team Name" autocomplete="off" required maxlength="40" :style="{ outline: 'none' }">
      </div>
      <div class="form-group">
        <label for="team-url" class="card__label">Team URL</label>
        <input v-model="teamUrl" type="url" name="url" class="form-control w-100" id="team-url" placeholder="https://"
          autocomplete="off" required maxlength="100" :style="{ outline: 'none' }">
      </div>
    </form>
  </base-modal>
</template>
<script>
import BaseModal from './BaseModal';
import httpService from '../api/http';
import teamService from '../api/team';
import eventService from '../api/event';

// import PxButton from './PxButton';

export default {
  name: "EventCreateTeamModal",
  components: {
    BaseModal,
    // PxButton
  },
  props: {
    eventId: {
      type: String,
      required: true
    },
    setCanApply: {
      type: Function,
      required: true
    },
    allDone: {
      type: Function,
      required: true
    },
    mustJoin: {
      type: Boolean,
      default: true
    },
    modalTitle: {
      type: String,
      default: "Create a Team and join the event"
    }
  },
  data() {
    return {
      modal: {
        title: this.modalTitle,
        buttons: [
          {
            name: "Create",
            class: "px-button--success",
            action: () => this.createAndJoin()
          },
          {
            name: "Close",
            class: "px-button--danger",
            action: () => this.selfClose()
          }
        ],
        close: () => this.selfClose()
      },
      teamName: "",
      teamUrl: ""
    }
  },
  methods: {
    selfClose() { this.$emit("close") },
    createAndJoin() {
      const createTeamPayload = {
        teamName: this.teamName,
        teamUrl: this.teamUrl
      }
      const initialRequest = [
        teamService.create(createTeamPayload)
      ]
      httpService.all(initialRequest)
        .then(() => {
          const secondaryRequest = []
          if (this.mustJoin) this.secondaryRequest.push(eventService.joinEvent(this.eventId));
          return httpService.all(secondaryRequest)
        })
        .then(([response]) => {
          if (!this.mustJoin) {
            this.setCanApply();
            this.selfClose();
            this.allDone();
            return
          }
          const successMessage = !!response && !!response.data && !!response.data.message;
          if (successMessage) {
            this.setCanApply();
            this.selfClose();
            this.allDone();
          }
        })
        .catch(error => {
          const manageableError = !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
    }
  }
}
</script>
<style lang="scss" scoped></style>
