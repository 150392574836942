import httpService from "./http";

const eventService = {
  // v1
  getEvents(page) {
    return httpService.get(page ? `/events?page=${page}` : "/events");
  },
  getEvent(eventId) {
    // return httpService.get(`/events/${eventId}`);
    return httpService.get(`/v2/events/${eventId}`);
  },
  getEventWithUserId(eventId, userId) {
    return httpService.get(`/v2/events/${eventId}?userId=${userId}`);
  },
  apply(eventId, teamId) {
    return httpService.post(`/events/${eventId}/apply/${teamId}`);
  },
  getEventTour(eventId) {
    return httpService.get(`/v2/events/${eventId}/tour-steps`);
  },
  canApplyTeam(eventId) {
    return httpService.get(`/events/${eventId}/applicable-team`);
  },
  canApplyUser(eventId) {
    return httpService.get(`/events/${eventId}/applicable-user`);
  },
  canDownloadKeys(eventId) {
    return httpService.get(`/events/${eventId}/can-download-keys`);
  },
  getEventCompanyContract(eventId, userId = null) {
    if (!userId) return httpService.get(`/v2/events/${eventId}/contract`);
    return httpService.get(
      ["/v2/events", eventId, "contract", userId].join("/")
    );
  },
  getParticipatingTeams(eventId) {
    return httpService.get(`/events/${eventId}/teams`);
  },
  getEventRank(eventId) {
    return httpService.get(`/events/${eventId}/rank`);
  },
  getEventRankChart(eventId, userId = null) {
    if (!userId) return httpService.get(`/events/${eventId}/rank/chart`);
    return httpService.get(
      ["/events", eventId, "rank/chart", userId].join("/")
    );
  },
  getLabs(labType = 0) {
    return httpService.get(`/events/labs/${labType}`);
  },
  acceptInvite(inviteId) {
    return httpService.post(`/events/accept/${inviteId}`);
  },
  startEvent(eventId, startId) {
    return httpService.post(`/events/${eventId}/start/${startId}`);
  },
  getTypes() {
    return httpService.get("/events/types");
  },
  generateReport(eventId) {
    const httpOptions = {
      data: {
        eventId,
      },
    };
    return httpService.post("/events/generate-report", httpOptions);
  },
  askReset(eventId, labId, teamId = null) {
    return httpService.post("/v2/events/reset", {
      data: {
        event_id: eventId,
        lab_id: labId,
        team_id: teamId,
      },
    });
  },
  eventLabResetAccept(resetId) {
    return httpService.post("/v2/events/reset/accept", {
      data: {
        reset_id: resetId,
      },
    });
  },
  eventLabResetResponse(resetId, response) {
    return httpService.post("/v2/events/reset/response", {
      data: {
        reset_id: resetId,
        response: response,
      },
    });
  },
  eventLabResetReject(resetId) {
    return httpService.post("/v2/events/reset/reject", {
      data: {
        reset_id: resetId,
      },
    });
  },
  cancelReset(resetId) {
    return httpService.post("/v2/events/reset/cancel", {
      data: {
        reset_id: resetId,
      },
    });
  },
  resetLab(eventId, labId) {
    return httpService.post(`/v2/events/${eventId}/labs/${labId}/reset`);
  },
  // v2
  create(data) {
    const httpOptions = {
      data,
    };
    return httpService.post("/v2/events", httpOptions);
  },
  getEventLabs(eventId) {
    return httpService.get(`/v2/events/${eventId}/labs`);
  },
  getEventLabKeys(eventId, labId) {
    return httpService.get(`/v2/events/${eventId}/keys/${labId}`);
  },
  joinEvent(
    eventId,
    data = null,
    headers = { "Content-Type": "application/json" }
  ) {
    if (!data) {
      const httpOptions = { data: {} };
      return httpService.post(`/v2/events/${eventId}/join`, httpOptions);
    }
    const httpOptions = { data, headers };
    return httpService.post(`/v2/events/${eventId}/join`, httpOptions);
  },
  joinEventWithTicket(eventId, token) {
    const httpOptions = {
      data: { ticket: token },
    };
    return httpService.post(`/v2/events/${eventId}/join`, httpOptions);
  },
  getEventRankV2(eventId) {
    return httpService.get(`/v2/events/${eventId}/ranks`);
  },
  getEventRankChartV2(eventId) {
    return httpService.get(`/v2/events/${eventId}/ranks-chart`);
  },
  upcomingEvent() {
    return httpService.get("/v2/events/upcomingevent");
  },
  getOvpn(eventId) {
    return httpService.get(`/v2/events/ovpn/${eventId}`, {
      responseType: "blob",
    });
  },
  runDobermann(eventId) {
    return httpService.post(`/v2/events/${eventId}/dobermann`);
  },
  getFirstbloods(eventId) {
    return httpService.get(`/v2/events/${eventId}/firstblood`);
  },
  saveUserNotes(eventId, notes) {
    const httpOptions = {
      data: { notes },
    };
    return httpService.post(`/v2/events/${eventId}/notes/save`, httpOptions);
  },
  getUserNotes(eventId) {
    return httpService.get(`/v2/events/${eventId}/notes`);
  },
  leaveEvent(eventId) {
    return httpService.post(`/v2/events/${eventId}/leave`);
  },
  getRankWithUserId(eventId, userId) {
    return httpService.get(`/events/${eventId}/rank?userId=${userId}`);
  },
  getEventLabsWithUserId(eventId, userId) {
    return httpService.get(`/v2/events/${eventId}/labs?userId=${userId}`);
  },
  getEventJob(eventId) {
    return httpService.get(["/v2/events", eventId, "job"].join("/"));
  },
  getEventQuiz(eventId) {
    return httpService.get(["/v2/events", eventId, "quiz"].join("/"));
  },
  getEventQuizResponse(eventId) {
    return httpService.get(["/v2/events", eventId, "quiz/responses"].join("/"));
  },
  saveEventQuizResponse(eventId, data) {
    const httpOptions = { data };
    return httpService.post(
      ["/v2/events", eventId, "quiz/responses"].join("/"),
      httpOptions
    );
  },
  getEventCategories(eventId) {
    return httpService.get(
      ["/v2/events", eventId, "quiz/categories"].join("/")
    );
  },
  getEventUserSolvedPercentages(eventId, userId) {
    return httpService.get(
      ["/v2/events", eventId, "quiz", userId, "percentages"].join("/")
    );
  },
  getEventUserRanges(eventId, userId, range = null) {
    if (!range)
      return httpService.get(
        ["/v2/events", eventId, "quiz", userId, "ranges"].join("/")
      );
    return httpService.get(
      ["/v2/events", eventId, "quiz", userId, "ranges?range=" + range].join("/")
    );
  },
  getEventSteps(eventId) {
    return httpService.get(["/v2/events", eventId, "steps"].join("/"));
  },
  getEventJoinSteps(eventId) {
    return httpService.get(["/v2/events", eventId, "join", "steps"].join("/"));
  },
  getEventSubmissions(eventId) {
    return httpService.get(["/v2/events", eventId, "submissions"].join("/"));
  },
  getEventLabData(eventId, labId) {
    return httpService.get(["/v2/events", eventId, "labs", labId].join("/"));
  },
  /**
   * @param {string} eventId
   * @returns {Promise}
   * */
  joinEventExtern(eventId) {
    return httpService.get(["/v2/events", eventId, "join/ctftime"].join("/"));
  },
  oauth2FlowCheck(eventId) {
    const url = ["/v2/events", eventId, "oauth2", "flow"];
    return httpService.get(url.join("/"));
  },

  checkEventsReset() {
    return httpService.get("/v2/events/resets/check");
  },
};

export default eventService;
