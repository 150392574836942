const state = {
    "id": null,
    "name": null,
    "description": null,
    "difficulty": 0,
    "userPoints": 0,
    "rootPoints": 0,
    "flags": {
        "root": null,
        "user": null,
    },
    "isRunning": false,
    "ip": null,
};

const actions = {
    flashOwn({commit}, {labId, teamId}) {
        commit('flashOwn', {labId, teamId});
        return {labId, teamId}
    }
};

const mutations = {
    flashOwn(state, data) {
        return {state, data};
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
