<template>
  <div class="vh-84 table">
    <div class="row align-items-center padding-bottom-20">
      <div class="col-6">
        <h1 class="main-title-1">New team</h1>
      </div>
      <div class="col-6 text-right">
        <px-button
            @click.native="goBack()"
            class="px-button--secondary icon-left outline"
            icon="left-arrow">
          Back
        </px-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-12">
        <form class="rounded-card__item rounded-card__item__createam bg-gradient" @submit.prevent>
          <div class="form-group">
            <label for="name" class="card__label">Name</label>
            <input type="text" name="name" id="name" class="form-control"
                   maxlength="40" required v-model="team.name" autocomplete="off"/>
          </div>
          <div class="form-group">
            <label for="url" class="card__label">URL</label>
            <input type="url" name="url" id="url" class="form-control" placeholder="https://"
                   maxlength="100" v-model="team.url" autocomplete="off"/>
          </div>
          <div>
            <px-button
                @click.native="createTeam" :disabled="!team.name.length"
                class="px-button--success icon-left"
                icon="edit">
              create team
            </px-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import teamService from "../api/team";
import {mapState} from "vuex";
import pxButton from "../components/PxButton";

export default {
  name: "TeamsAdd",
  computed: mapState({
    user: state => state.user
  }),
  data() {
    return {
      team: {
        name: "",
        url: "",
      },
    }
  },
  components: {
    pxButton
  },
  beforeCreate() {
    this.$parent.breadcrumbs = [{name: "teams", navigation: "/teams"}, {name: "new team"}];
  },
  created() {
    if (this.user.team || this.user.pendingRequest) {
      this.$router.push("/home").catch();
    }
  },
  methods: {
    createTeam() {
      const payload = {
        teamName: this.team.name,
        teamUrl: this.team.url,
      };

      this.$parent.loading = true;
      teamService.create(payload)
          .then(response => {
            const successMessage = !!response && !!response.data && !!response.data.message;
            if (successMessage) {
              this.flashSuccess(response.data.message);
            }

            return this.$store.dispatch("user/getCurrentUser");
          })
          .then(() => this.$router.push("/teams"))
          .catch(error => {
            const manageableError = !!error && !!error.data && !!error.data.errorDescription;
            if (manageableError) {
              this.flashError(error.data.errorDescription);
            }
          })
          .finally(() => this.$parent.loading = false)
    }
  }
}
</script>

<style scoped>

</style>
