import httpService from "@/api/http";

const jobService = {
  getAllJobs() {
    return httpService.get("/v2/jobs")
  },
  getJob(jobId) {
    return httpService.get(`/v2/jobs/${jobId}`)
  },
  applyForJob(jobId, data) {
    const httpOptions = {
      data,
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
    return httpService.post(["/v2/jobs", jobId, "apply"].join("/"), httpOptions)
  },
  canApply(jobId) {
    return httpService.get(["/v2/jobs", jobId, "applicable-user"].join("/"))
  }
}

export default jobService
