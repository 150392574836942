<template>
  <px-table class="table__events">
    <!-- <h2 v-if="events.length === 0">no data</h2> -->
  </px-table>
</template>

<script>
import PxTable from "./PxTable";
// import PxBadge from "./PxBadge";

export default {
  name: "PxTableEvent",
  components: {
    PxTable,
    // PxBadge,
  },
  props: {
    events: {type: Array},
  },
};
</script>

<style lang="scss" scoped>
.table__events {
  .row:nth-child(1) {
    .col:nth-child(1):before {
      content: "name";
    }

    .col:nth-child(2):before {
      content: "points";
    }

    .col:nth-child(3):before {
      content: "starting";
    }

    .col:nth-child(4):before {
      content: "ending";
    }

    .col:nth-child(5):before {
      content: "info";
      text-align: center;
    }
  }

  .row {
    justify-content: space-between;
  }

  .col {
    &:not(.col__info) {
      flex-direction: column;
    }

    &__name {
      word-wrap: break-word;
    }

    &__points {
      max-width: 7vw;
    }

    &__date {
      display: flex;

      &--time {
        font-size: 90%;
        opacity: 0.7;
      }
    }

    &__info {
      display: flex;
      width: 100%;
      justify-content: space-evenly !important;
      align-content: center;
      flex-wrap: wrap;
    }
  }
}
</style>
