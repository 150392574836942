<template>
  <base-modal :title="modalTitle" :buttons="buttons" :close="closeAction">
    <slot></slot>
  </base-modal>
</template>
<script>
import BaseModal from "./BaseModal"

export default {
  name: "ConfirmationModal",
  components: { BaseModal },
  props: {
    modalTitle: {
      type: String,
      required: true
    },
    confirmAction: {
      type: Function,
      required: true
    },
    closeAction: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      buttons: [
        {
          name: "confirm",
          class: "px-button--success",
          action: this.confirmAction
        },
        {
          name: "decline",
          class: "px-button--danger",
          action: this.closeAction
        }
      ]
    }
  }
}

</script>
<style></style>
