

const state = {
    routes: [],
};

const getters = {
    routes: state => state.routes,
};

const mutations = {
    setRoutes(state, payload) {
        // payload = {newRoutes, shouldInit}
        if (payload.shouldInit) {
            state.routes = [];
        }
        const alreadyExist = r => !!state.routes.filter(_r => _r.name === r).pop();
        for (const route of payload.newRoutes) {
            if (!alreadyExist(route.name) && !!route.name) {
                state.routes.push(route);
            }
        }
    },
};

const actions = {
    init({commit}, payload) {
        const shouldInit = !!payload.shouldInit;
        const newRoutes = payload.routes;
        commit("setRoutes", {newRoutes, shouldInit});
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
