import { render, staticRenderFns } from "./EnableNotificationModal.vue?vue&type=template&id=436032ef&scoped=true"
import script from "./EnableNotificationModal.vue?vue&type=script&lang=js"
export * from "./EnableNotificationModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "436032ef",
  null
  
)

export default component.exports