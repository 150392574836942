<template>
    <prism :language="language">{{content}}</prism>
</template>
<script>
import "prismjs/prism";
import "../assets/css/prism-funky.scss"

import Prism from 'vue-prism-component'

export default {
    name: 'CodeHighlithing',
    props: {
        filename: {
            type: String,
            required: false
        },
        ext: {
            type: String,
            required: false
        },
        content: {
            type: String,
            required: false
        }
    },
    components: {
        Prism
    },
    data() {
        return {
            language: "javascript" // default
        }
    },    
    created() {
        if (this.ext) this.language = this.ext
        // const language = this.filename.split(".")[1];
        // const prism = () => import(`prismjs/components/prism-${this.ext}`);
        // prism();
        // this.language = language;
    },
}
</script>
<style>

</style>
