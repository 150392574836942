<template>
  <div class="quiz-modal-background row m-0">
    <div class="col-6 m-0 p-0 text-left">
      <h2 class="text-uppercase pl-2 main-title-1">{{ evName }}</h2>
    </div>
    <div class="col-6 m-0 p-0 text-right">
      <i class="fas fa-close pr-2 pointer" style="font-size: 30px"></i>
    </div>
    <div class="col-12 m-0 p-0">
      <div class="counter-timer-row row m-0 p-2">
        <div :class="[isReviewing ? 'col-4' : 'col-6']" class="counter m-0 p-0 text-left">
          <label class="card__label" for="counter" style="font-size: 15px">answered:</label>
          <h2 style="font-weight: 900;font-size: 50px">{{ countAnswered() }}/{{ questions.length }}</h2>
        </div>
        <div class="col-4 text-center" v-if="isReviewing">
          <h2 style="shadow-lg font-weight: 900;font-size: 20px;" class="text-uppercase reviewing-text">reviewing</h2>
        </div>
        <div :class="[isReviewing ? 'col-4' : 'col-6']" class="counter m-0 p-0 text-right">
          <label for="timer" class="card__label" style="font-size: 15px">remaining</label>
          <h2 style="font-weight: 900;font-size: 50px">{{ getStringTime() }}</h2>
        </div>
      </div>
      <div class="questions d-flex flex-row m-0 p-0">

        <div :id="question.id" :class="{ 'active': getIndex() === idx }" class="w-100 m-0 p-0 py-2 question-container"
          v-for="(question, idx) of questions" :key="question.id">
          <div class="d-flex justify-content-center question py-3 rounded">
            <p class="m-0 p-0" style="font-weight: 700">{{ question.question }}</p>
          </div>
          <div class="responses mt-2">
            <div :class="{ 'active': isSelected(question.id, index) }" @click="selectResponse(question.id, index)"
              class="p-3 bg-darker response d-flex justify-content-start position-relative"
              v-for="(response, index) of question.responses" :key="index">
              <div class="quiz-checkbox" :class="{ 'active': isSelected(question.id, index) }">
              </div>
              <p class="pl-5 m-0 p-0">{{ response }}</p>
            </div>
          </div>
          <div class="questions-list d-flex flex-row justify-content-center align-items-center w-100 position-relative">
            <div @click="gotoQuestion(idx + 1)" class="q-number-container p-2" v-for="(q, idx) of questions" :key="q.id"
              :class="{ 'active': isActive(q.id) || isSelectedFromList(idx + 1), 'reviewing': isCurrentInReview(idx) }">
              {{ idx + 1 }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 row m-0 p-0">
      <px-button v-if="shouldShowNextButton()" @click.native="nextQuestion"
        class="mr-2 px-button--success">next</px-button>
      <px-button v-if="shouldShowReviewButton()" @click.native="startReview"
        class="mr-2 px-button--success">review</px-button>
      <px-button v-if="shouldShowCompleteButton()" @click.native="completeQuiz"
        class="mr-2 px-button--success">complete</px-button>
      <px-button @click.native="selfClose" class="mr-2 px-button--danger">close</px-button>
    </div>
  </div>
</template>
<script>
import PxButton from "@/components/PxButton";
import eventService from '@/api/event';


export default {
  name: "EventQuizModal",
  components: {
    PxButton
  },
  props: {
    questions: {
      type: Array,
      required: true
    },
    eventId: {
      type: String,
      required: false,
      default: null
    },
    evName: {
      type: String,
      required: true,
    },
    endTimestamp: {
      type: Function,
      required: true
    },
    completeCallback: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      qIndex: 0,
      revId: 0,
      isReviewing: false,
      timerCount: this.endTimestamp(),
      userResponses: {},
    };
  },
  created() {
    this.loadUserPreviousResponses()
    this.sortResponses()
  },
  methods: {
    isCurrentInReview(idx) {
      const isReviewing = this.isReviewing;
      if (!isReviewing) return false;
      return this.revId === idx;
    },
    isSelectedFromList(idx) {
      let index = this.getIndex()
      return idx - 1 === index;
    },
    completeQuiz() {
      this.flashSuccess("You've successfully completed the quiz!");
      this.saveResponses();
      this.completeCallback();
      this.selfClose();
    },
    saveResponses() {
      const eventId = this.eventId;
      eventService.saveEventQuizResponse(eventId, { "responses": this.userResponses })
        .then((response) => {
          console.log("saved status:", response.data.message)
        })
    },
    shouldShowNextButton() {
      const length = this.questions.length;
      const index = this.getIndex();
      const answered = this.countAnswered()
      if (answered != length) return true;
      return index != (length - 1)
    },
    shouldShowReviewButton() {
      if (this.shouldShowNextButton()) return false;
      if (this.isReviewing) return false;
      return true
    },
    shouldShowCompleteButton() {
      if (this.shouldShowNextButton()) return false;
      if (this.shouldShowReviewButton()) return false;
      return true;
    },
    startReview() {
      this.isReviewing = true;
      this.revId = 0;
    },
    selectResponse(qId, rId) {
      const userResponses = this.userResponses;
      userResponses[qId] = rId
      this.userResponses = userResponses;
    },
    selfClose() {
      this.$emit("close");
    },
    isActive(qId) {
      let responses = this.userResponses;
      return responses[qId] !== null && responses[qId] !== undefined;
    },
    isSelected(qId, rId) {
      const responses = this.userResponses;
      return responses[qId] === rId
    },
    getStringTime() {
      const timestamp = this.timerCount;
      const date = new Date(timestamp);
      const minute = date.getMinutes();
      const second = Math.max(Math.floor(date.getSeconds() % 60), 0);
      return [
        minute < 10 ? "0" + String(minute) : minute,
        second < 10 ? "0" + String(second) : second,
      ].join(":")
    },
    countAnswered() {
      const responses = this.userResponses;
      return Object.values(responses).filter(response => response !== null).length
    },
    getIndex() {
      if (this.isReviewing) return this.revId;
      return this.qIndex;
    },
    updateIndex(idx, save = true) {
      const isReviewing = this.isReviewing;

      if (save) this.saveResponses()
      if (!isReviewing) {
        this.qIndex = idx
        return
      }
      this.revId = idx
    },
    nextQuestion() {
      const length = this.questions.length;
      // const isAnswered = this.isAnswered(index)
      const isReviewing = this.isReviewing;
      // if (!isAnswered) return;
      if (this.countAnswered() === length && !isReviewing) return;
      const index = isReviewing ? this.getIndex() : this.progress();
      this.updateIndex(index + 1);
    },
    progress() {
      let counter = 0;
      for (const response of Object.values(this.userResponses)) {
        if (response !== undefined && response !== null) {
          counter += 1
          continue;
        }
        break;
      }
      return counter - 1;
    },
    isAnswered(idx) {
      let responses = this.userResponses;
      responses = this.sortResponses(responses);

      const question = this.questions.at(idx);
      const key = Object.keys(question)[0];

      const response = responses[key];
      return response !== undefined && response !== null;
    },
    gotoQuestion(idx) {
      this.updateIndex(idx - 1, false)
    },
    sortResponses() {
      const responses = this.userResponses;
      const values = Object.entries(responses).sort(function (a, b) {
        const v1 = a[1];
        const v2 = b[1];
        if (!v1) return 1;
        if (!v2) return -1;
        return 0;
      });
      const obj = {}
      for (const element of values) {
        const key = element[0]
        const value = element[1]
        obj[key] = value
      }
      return obj
    },
    loadUserPreviousResponses() {
      const questions = this.questions;
      const userResponses = {};

      this.$parent.loading = true;
      for (const question of questions) {
        const questionId = question.id;
        userResponses[questionId] = null;
      }

      const eventId = this.eventId;
      eventService.getEventQuizResponse(eventId)
        .then((response) => {
          const responses = response.data.responses || []
          if (Object.keys(responses).length > 0) {
            for (const [key, value] of Object.entries(responses)) {
              userResponses[key] = value
            }
          }
          this.userResponses = userResponses;
        })
        .finally(() => {
          let index = 0;
          const sorted = this.sortResponses();
          for (const value of Object.values(sorted)) {
            if (!value) break;
            index += 1;
          }
          this.$parent.loading = false;
          if (index === questions.length) {
            this.flashError("You've already completed the quiz")
            this.selfClose()
            return;
          }
          this.qIndex = index;
        })
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        const validated = Math.max(0, value);
        if (validated > 0) {
          setTimeout(() => { this.timerCount -= 1000 }, 1000)
        }

        if (validated === 0) {
          window.location.reload()
        }
      },
      immediate: true
    },
  }
};
</script>
<style scoped lang="scss">
@import "../assets/css/colors.scss";

.quiz-modal-background {
  background-color: $neutral--dark;
  padding: 20px;
}

.question-container {
  display: none;

  &.active {
    display: block;
  }
}

.index-container {
  cursor: pointer;
  // overflow-y: hidden;
  // overflow-x: scroll;
}

.index-container:hover {
  transition: all 0.2s ease;
  background-color: white;
  color: black;
}

.index-container.active {
  transition: all 0.2s ease;
  background-color: white;
  color: black;
}

.quiz-checkbox {
  position: absolute;
  width: 15px;
  height: 15px;
  left: 2%;
  top: 50%;
  background-color: white !important;
  color: white !important;
  transform: translateY(-50%);
  cursor: pointer;
  // transform: translateX(-50%);

  &.active {
    background-color: $light-blue !important;
    border-radius: 10%;
  }
}

.response {
  transition: all 0.2s ease;
  font-size: 20px;


  &:hover,
  &.active {
    background-color: $soft-green !important;
    color: black !important;
  }
}

.question {
  background-color: darken($soft-green, 50);
  color: white;
  font-size: 20px;
}

.reviewing-text {
  color: $soft-green;
  animation: reviewing 1s ease infinite alternate;
  opacity: 0;
}

.q-number-container {
  // min-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid $soft-green;
}

.q-number-container:hover {
  background-color: $soft-green;
}

.q-number-container.active {
  background-color: $soft-green;
}

.q-number-container.active.reviewing {
  background-color: $soft-blue;
  border-color: $soft-blue;
}


.questions-list {
  margin-top: 10px;
  overflow-x: scroll;
  width: 100%;
}

@keyframes reviewing {
  100% {
    opacity: 1;
  }
}
</style>
