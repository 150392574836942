import Vue from "vue";
import Vuex from 'vuex';
import user from './modules/user';
import lab from './modules/lab';
import SecureLS from 'secure-ls';
import createPersistedState from "vuex-persistedstate";
import router from "./modules/router";

// I've to add this here or Vuex complains
Vue.use(Vuex);

const ls = new SecureLS({ isCompression: false, encodingType: "aes" });

const vuexLocal = createPersistedState({
  storage: {
    getItem: key => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: key => ls.remove(key)
  },
  key: process.env.VUE_APP_lsKey,
});


export default new Vuex.Store({
  modules: {
    user,
    lab,
    router,
  },
  plugins: [vuexLocal]
})
