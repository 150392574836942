// recruiter dashboard
// event details

import Router from "vue-router";
import store from "../store";

import Login from "../views/Login.vue";

import Dashboard from "../views/Dashboard";
import BuyTokens from "../views/BuyTokens";

import Labs from "../views/Labs.vue";
import PaidLabs from "../views/PaidLabs.vue";

import LabDetails from "../views/LabDetails";

import UserDetails from "../views/UserDetails";
import UserEdit from "../views/UserEdit";

// Disabled for pre-release
import Events from "../views/Events";

import EventsAdd from "../views/EventsAdd";

// Disabled for pre-release
import EventDetails from "../views/EventDetails";
import EventTicketJoin from "../views/EventTicketJoin";

// Disabled for pre-release
import Teams from "../views/Teams";

import TeamsAdd from "../views/TeamsAdd";

// Disabled for pre-release
import TeamDetails from "../views/TeamDetails";

import TeamEdit from "../views/TeamEdit";

import LeaderBoard from "../views/LeaderBoard";

import Badges from "../views/Badges";

// Disabled for pre-release
// import OneShot from "../views/OneShot";

// import Messages from "../views/Messages";
import Actions from "../components/Actions";
import CompanyAdmin from "../views/CompanyAdmin";
import CookiePolicy from "../views/CookiePolicy";
import PageNotFound from "../views/PageNotFound";
import Settings from "../views/Settings";
import TermsOfService from "../views/TermsOfService";
// import Companies from "../views/Companies";
// import CompanyDetails from "../views/CompanyDetails";
import CompanyRanking from "../views/CompanyRanking";
import CompanyEstimate from "../views/CompanyEstimate";
// import PremiumOld from '../views/PremiumOld';

// Disabled for pre-release
import Notifications from "@/views/Notifications";
// import RecruiterDashboard from '../views/RecruiterDashboard';
import RecruiterTicket from "../views/RecruiterTicket";

import Privacy from "@/views/Privacy";

// import EventStatistics from "@/views/EventStatistics"
import EventStatisticsWrapper from "@/views/EventStatisticsWrapper";
import CourseOverview from "@/views/CourseOverview";
import CourseAchievement from "@/views/CourseAchievement";
import CourseChapter from "@/views/CourseChapter";
import Faq from "@/views/Faq";
import JobOpportunities from "@/views/JobOpportunities";
import JobApplications from "@/views/JobApplications";
import statsService from "@/api/stats";

import LearningPathList from "@/views/LearningPathList";
// Disabled for pre-release
// import Match from "@/views/Match";

// import HallOfFame from "../views/HallOfFame";
// import SecurityPolicy from "../views/SecurityPolicy";
// import Jobs from "../views/Jobs";

// import PreRelease from "@/views/PreRelease";

const unauthenticatedRoutes = ["Actions"];

const checkRedirectId = async (redirectId) => {
  if (!redirectId) return;
  const link = await statsService
    .getRedirectLink(redirectId)
    .then((response) => response.data.link);
  if (!link) return;
  window.location = link;
};

export const authGuard = (to, from, next) => {
  const userIsLoggedIn = store.getters["user/isLoggedIn"];
  if (userIsLoggedIn) checkRedirectId(to.query.redirectId);

  const shouldNavigate =
    userIsLoggedIn || unauthenticatedRoutes.includes(to.name);
  const toPath = to.fullPath;

  if (shouldNavigate) {
    next();
  } else if (userIsLoggedIn) {
    next("/404");
  } else {
    const redirectTo = ["/login?returnUrl=" + toPath];
    next(redirectTo.join("&"));
  }
};

const routes = [
  // Login
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: (to, from, next) => {
      const userIsLoggedIn = store.getters["user/isLoggedIn"];
      if (userIsLoggedIn) {
        next("/home");
      } else {
        next();
      }
    },
  },
  {
    path: "/register/referral/:referralCode(.*)",
    name: "RegisterWithReferral",
    component: Login,
    beforeEnter: (to, from, next) => {
      const userIsLoggedIn = store.getters["user/isLoggedIn"];
      if (userIsLoggedIn) {
        next("/home");
      } else {
        next();
      }
    },
  },
  // Dashboard
  {
    path: "/home",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: authGuard,
  },
  // Users
  {
    path: "/user/:userId",
    name: "UserDetails",
    component: UserDetails,
    beforeEnter: authGuard,
  },
  {
    path: "/user/:userId/edit",
    name: "UserEdit",
    component: UserEdit,
    beforeEnter: authGuard,
  },
  // LeaderBoard
  // {
  //     path: "/leaderboards/team",
  //     name: "LeaderBoard",
  //     component: PreRelease,
  //     // Disabled for pre-release
  //     // component: LeaderBoard,
  //     beforeEnter: authGuard,
  // },
  // {
  //     path: "/recruiter",
  //     name: "RecruiterDashboard",
  //     component: RecruiterDashboard,
  //     beforeEnter: (to, from, next) => {
  //         const user = store.state.user;
  //         if (user.role !== 3) {
  //             return next("/home")
  //         }
  //         next()
  //     }
  // },
  {
    path: "/event/:eventId/tickets",
    name: "RecruiterTicket",
    component: RecruiterTicket,
  },
  {
    path: "/leaderboards/:leadType",
    name: "LeaderBoard",
    // component: PreRelease,
    component: LeaderBoard,
    beforeEnter: authGuard,
  },
  // Labs
  {
    path: "/labs",
    component: Labs,
    name: "Labs",
    beforeEnter: authGuard,
  },
  {
    path: "/lab/:labId",
    component: LabDetails,
    name: "LabDetails",
    beforeEnter: authGuard,
  },
  {
    path: "/badges",
    component: Badges,
    name: "Badges",
    beforeEnter: authGuard,
  },
  // Events
  {
    path: "/events",
    // component: PreRelease,
    // Disabled for pre-release
    component: Events,
    name: "Events",
    beforeEnter: authGuard,
  },
  {
    path: "/event/:eventId",
    // component: PreRelease,
    // Disabled for pre-release
    component: EventDetails,
    name: "EventDetails",
    beforeEnter: authGuard,
  },
  {
    path: "/event/:eventId/join",
    // component: PreRelease,
    // Disabled for pre-release
    component: EventTicketJoin,
    name: "EventTicketJoin",
    beforeEnter: authGuard,
  },
  // Teams
  {
    path: "/teams",
    // component: PreRelease,
    // Disabled for pre-release
    component: Teams,
    name: "Teams",
    beforeEnter: authGuard,
  },
  {
    path: "/teams/add",
    component: TeamsAdd,
    name: "TeamsAdd",
    beforeEnter: authGuard,
  },
  {
    path: "/team/:teamId",
    // component: PreRelease,
    // Disabled for pre-release
    component: TeamDetails,
    name: "TeamDetails",
    beforeEnter: authGuard,
  },
  // Companies
  // {
  //   name: "CompanyRank",
  //   path: "/company-ranking",
  //   component: CompanyRanking
  // },
  // {
  //     path: "/companies",
  //     component: Companies,
  //     name: "Companies"
  // },
  // {
  //     path: "/company/:companyId",
  //     component: CompanyDetails,
  //     name: "CompanyDetails"
  // },
  // Messages
  // {
  //     path: "/messages",
  //     name: "Messages",
  //     component: Messages,
  //     beforeEnter: authGuard
  // },
  // Actions
  {
    path: "/actions/:scope/:action",
    name: "Actions",
    component: Actions,
  },
  // Settings
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    beforeEnter: authGuard,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
    beforeEnter: authGuard,
  },
  // 404
  {
    path: "/404",
    name: "PageNotFound",
    component: PageNotFound,
  },
  // tos
  {
    path: "/tos",
    name: "TermsOfService",
    component: TermsOfService,
  },
  // cookie policy
  {
    path: "/cookie-policy",
    name: "CookiePolicy",
    component: CookiePolicy,
  },
  // Company Estimate
  {
    path: "/pricing-estimate",
    name: "CompanyEstimate",
    component: CompanyEstimate,
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
  },
  // // Hall of Fame
  // {
  //     path: "/hof",
  //     name: "HallOfFame",
  //     component: HallOfFame
  // },
  // // Security Policy
  // {
  //     path: "/security-policy",
  //     name: "SecurityPolicy",
  //     component: SecurityPolicy
  // },
  // // Jobs
  // {
  //     path: "/jobs",
  //     name: "Jobs",
  //     component: Jobs
  // },
  // Redirects
  // EventStatistics
  {
    path: "/event/:eventId/statistics",
    component: EventStatisticsWrapper,
    name: "EventStatisticsWrapper",
    beforeEnter: authGuard,
  },
  {
    path: "/jobs/:jobId/applications",
    component: JobApplications,
    name: "JobApplications",
    beforeEnter: authGuard,
  },
  // {
  //     path: "/event/:eventId/statistics",
  //     component: EventStatistics,
  //     name: "EventStatistics"
  //
  // },
  // {
  //   path: "/courses/:name/labs",
  //   component: PaidLabs,
  //   name: "PaidLabs",
  //   props: { labType: "beginners Hub" },
  //   beforeEnter: authGuard,
  // },
  // {
  //   path: "/courses/:name/achievements",
  //   component: CourseAchievement,
  //   name: "CourseAchievement",
  //   beforeEnter: authGuard,
  // },
  // {
  //   path: "/courses/:name/:chapterId",
  //   component: CourseChapter,
  //   name: "CourseChapter",
  //   beforeEnter: authGuard,
  // },
  // {
  //   path: "/courses/:name",
  //   component: CourseOverview,
  //   name: "CourseOverview",
  //   beforeEnter: authGuard,
  // },
  {
    path: "/faq",
    component: Faq,
    name: "Faq",
  },
  {
    path: "/jobs",
    component: JobOpportunities,
    name: "JobOpportunities",
    beforeEnter: authGuard,
  },
  { path: "/", redirect: "/home" },
  {
    path: "*",
    component: PageNotFound,
    name: "PageNotFound",
    beforeEnter: authGuard,
  },
];

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      return err; // resolve err
    }
    return Promise.reject(err); // rethrow error
  });
};
const router = new Router({ routes });
router.beforeEach(function (to, from, next) {
  setTimeout(() => {
    document.body.scrollTop = 0;
  }, 1);
  next();
});

export default router;

export const cMap = {
  EventsAdd: EventsAdd,
  TeamsAdd: TeamsAdd,
  TeamEdit: TeamEdit,
  CompanyAdmin: CompanyAdmin,
  BuyTokens: BuyTokens,
  PaidLabs: PaidLabs,
  CompanyRanking: CompanyRanking,
  LearningPathList: LearningPathList,
  CourseOverview: CourseOverview,
  CourseChapter: CourseChapter,
  CourseAchievement: CourseAchievement,
};
