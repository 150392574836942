const EventType = Object.freeze({
  NORMAL:"NORMAL",
  RED:"RED",
  BLUE:"BLUE",
  REDBLUE:"REDBLUE",
  PURPLE:"PURPLE",
  GREEN:"GREEN"
})

const EventStatus = Object.freeze({
  ONGOING: 'ONGOING',
  OVERDUE: 'OVERDUE',
  SCHEDULED: 'SCHEDULED'
})

const EventsDataType = Object.freeze({
  NO_DATA: "NO_DATA",
  DATA: "DATA",
  DATA_LOC: "DATA_LOC"
});

export {
  EventType,
  EventStatus,
  EventsDataType
}
