<template>
  <div class="container w-100">
    <div class="page-banner w-100">
      <img class="w-100" :src="course.image" alt="">
    </div>
    <div class="descriptions my-4">
      <h2 style="font-size: 16px" class="card__label">course plan</h2>
      <div v-for="chapter of getChapters()" class="course-description row my-2 m-0 p-0" :key="chapter.id">
        <div @click="expandChapter(chapter.id)"
          class="col-12 m-0 p-2 bg-medium chapter-banner justify-content-between d-flex align-items-center">
          <div class="chapter-name-container">
            <a target="_blank" :href="`/#/courses/${cName}/${chapter.id}`"
              style="text-decoration: none; cursor: pointer" class="m-0 p-0 chapter-name">
              {{ chapter.name }}
            </a>
          </div>
          <div class="arrow-container mr-2">
            <i class="arrow down" :id="chapter.id + '-arrow'"></i>
          </div>
        </div>
        <div class="col-12 row p-0 m-0 bg-medium chapter-description collapsed" :id="chapter.id">
          <div class="col-12 p-0 m-0 mx-2">
            {{ chapter.description }}
          </div>
          <div class="text-right col-12 p-0 m-0 chapter-footer">
            <a :href="`/#/courses/${cName}/${chapter.id}`" target="_blank" class="chapter-link m-0 p-0 mr-3">
              read more
            </a>
          </div>
        </div>
        <div class="col-12 my-1 m-0 p-2 bg-medium row chapter-quiz">
          <div class="col-11 m-0 p-0">
            <p @click="startChapterQuestions(chapter.id)" style="text-decoration: none; cursor: pointer"
              class="m-0 p-0 chapter-name orange">
              {{ chapter.name }}: quiz
            </p>
          </div>
          <div class="text-right col-1 m-0 p-0" v-if="isCompleted(chapter.id)">
            <i class="fa-solid fa-check check-icon"></i>
          </div>
          <div class="text-right col-1 m-0 p-0" v-if="!isCompleted(chapter.id)">
            <!-- <i class="fa-solid fa-check check-icon"></i> -->
            <p class="orange p-0 m-0">
              {{ getCorrectValues(chapter.id) }}
            </p>
          </div>
        </div>
      </div>
      <div class="row m-0 p-0">
        <div class="col-12 m-0 p-0 bg-medium p-4 exam-bar" :class="{ 'active': this.applicableExam }"
          @click="startExam">
          <h2 class="main-title-1 m-0 p-0" v-if="!courseCompleted">EXAM</h2>
          <h2 class="main-title-1 m-0 p-0" v-if="courseCompleted">EXAM COMPLETED</h2>
          <div class="row mt-2 m-0 p-0" v-if="examData">
            <div class="col-md-12 m-0 p-0" v-if="!courseCompleted">
              <h2>QUESTIONS: {{ examData.questions }}</h2>
              <h2>PASSING PERCENTAGE: {{ examData.passPercentage }}%</h2>
            </div>
            <div class="col-md-12 m-0 p-0" v-if="courseCompleted">
              <px-button @click.native="redirectToCert" class="px-button--success">download your completion
                certificate</px-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import userService from "@/api/user";
import courseService from "@/api/courses";
import SurveyModal from "@/components/SurveyModal";
import PxButton from "@/components/PxButton";

export default {
  name: "CourseOverview",
  components: {
    PxButton
  },
  computed: mapState({
    user: state => state.user
  }),
  data() {
    return {
      course: [],
      courseCompleted: false,
      applicableExam: false,
      cName: this.$route.params.name,
      completed: {},
      courseInterval: null,
      completedInterval: null,
      examData: null
    }
  },
  beforeCreate() {
    const location = window.location.href.split("//")
    const isBh = location.some(item => item === "courses")
    this.$parent.breadcrumbs = [{ name: isBh ? "beginners hub" : "courses" }]
  },
  created() {
    this.getCourse(true);
    this.getCompletedChapters();

    const milliseconds = 300000;
    this.courseInterval = setInterval(() => this.getCourse(), milliseconds);
    this.completedInterval = setInterval(() => this.getCompletedChapters(), milliseconds);
  },
  beforeDestroy() {
    clearInterval(this.courseInterval);
    clearInterval(this.completedInterval);
  },
  methods: {
    async redirectToCert() {
      const url = await courseService.getUserCertUrl(this.cName, this.user.id)
        .then(response => response.data.url)
      if (!url) return;
      window.open(url, "_blank");
    },
    async downloadExamCertificate() {
      console.log("exam certificate");
    },
    async startExam() {
      // if (!this.applicableExam) return;
      if (this.courseCompleted) return this.downloadExamCertificate();
      const questionObject = await courseService.getExamQuestions(this.cName)
        .then((response) => response.data.questions)
        .catch((error) => {
          const manageableError = !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
            return [];
          }
        })

      if (Array.isArray(questionObject) && questionObject.length === 0) return;
      const questions = []

      for (const key of Object.keys(questionObject)) {
        const element = questionObject[key];
        const responses = Object.keys(element.options).map((key) => {
          return {
            "id": key,
            "response": element.options[key],
            "checked": false
          }
        })

        let obj = {
          "id": element.id,
          "question": element.question,
          "responses": responses,
        }
        questions.push(obj)
      }

      this.$modal.show(
        SurveyModal,
        {
          survey: questions,
          surveySaved: () => { },
          title: "Beginners Path Exam",
          isExam: true
        },
        { height: 'auto' }
      )
    },
    isCompleted(chapterId) {
      const completed = this.completed;
      if (!completed[chapterId]) return false;
      const [correct, total] = completed[chapterId].completed.split("/");
      return correct == total;
    },
    getCorrectValues(chapterId) {
      if (!this.completed[chapterId]) return;
      return this.completed[chapterId].completed;
    },
    async startChapterQuestions(chapterId) {
      if (this.isCompleted(chapterId)) return this.flashError("You've already completed this chapter");
      const survey = await userService.getSurvey(chapterId).then(resp => resp.data.questions)
      const [chapter] = this.course.chapters.filter(chapter => chapter.id === chapterId)
      this.$modal.show(
        SurveyModal,
        {
          survey: survey,
          chapterId: chapterId,
          surveySaved: () => {
            this.getCompletedChapters();
          },
          title: chapter.name
        },
        { height: "auto" }
      )
    },
    expandChapter(chapterId) {
      const chapters = document.querySelectorAll(".chapter-description");

      for (const chapter of chapters) {
        let classList = Array.from(chapter.classList).join(" ")
        const arrow = document.getElementById(chapter.id + "-arrow");
        if (chapter.id == chapterId) {
          if (classList.includes("collapsed")) {
            chapter.classList.remove("collapsed")
            arrow.classList.remove("down")
            arrow.classList.add("up")
          } else {
            chapter.classList.add("collapsed")
            arrow.classList.remove("up")
            arrow.classList.add("down")
          }
        } else {
          chapter.classList.add("collapsed");
          arrow.classList.remove("up");
          arrow.classList.add("down");
        }
      }
    },
    getChapters() {
      if (!this.course) return []
      return this.course.chapters
    },
    getCourse(start = false) {
      if (start) this.$parent.loading = true;
      courseService.getCourse(this.cName)
        .then((response) => {
          this.course = response.data.course;
          return courseService.getApplicableExam(this.cName)
        })
        .then((response) => {
          this.applicableExam = response.data.applicableExam;
          this.courseCompleted = response.data.courseCompleted
          this.examData = response.data.examData;
        })
        .catch((error) => {
          const manageableError = !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
            this.$router.push("/courses/web-security");
          }
        })
        .finally(() => {
          this.$parent.loading = false;
        })
    },
    getCompletedChapters() {
      courseService.getCompletedChapters(this.cName)
        .then(response => {
          this.completed = response.data.completed;
        })
        .catch(error => {
          const manageableError = !!error.data && !!error.data.errorDescription;
          if (manageableError) this.flashError(manageableError);
        })
    }
  },
}
</script>
<style lang="scss" scoped>
@import "../assets/css/colors.scss";

.chapter-name {
  color: $soft-green;
  font-weight: 700;
  text-transform: uppercase;

  &.orange {
    color: lighten($soft-orange, 5);
  }
}

.orange {
  color: lighten($soft-orange, 5);
}

.arrow {
  border: solid $soft-green;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  cursor: pointer;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}


.chapter-link {
  color: $soft-green;

  &:hover {
    text-decoration: underline;
  }
}

.chapter-description {
  position: relative;
  transform-origin: top;
  transition: all 0.2s linear;
  max-height: 100%;
}

.collapsed {
  transform: scaleY(0%);
  transform-origin: top;
  transition: all 0.2s linear;
  max-height: 0;
}

.check-icon {
  background-color: darken($soft-green, 50);
  border-radius: 50%;
  padding: 5px;
  transition: all 0.2s ease;
}

.check-icon:hover {
  background-color: $soft-green;
  transition: all 0.2s ease;
}

.exam-bar {
  position: relative;
  transition: all 0.5s ease;

  // filter: grayscale(100%);
  &.active {
    &:hover {
      background: rgba($soft-green, 0.2) !important;
      transition: all 0.5s ease;
    }
  }

  &:hover {
    background: rgba($soft-orange, 0.2) !important;
    transition: all 0.5s ease;
  }

}
</style>
