<template>
  <div>
    <div class="container mb-1 banner" v-if="isCourse">
      <img class="w-100 shadow-lg" :src="imageUrl" />
    </div>
    <div class="container-fluid">
      <div class="row w-100 align-items-center">
        <div class="page-title col-md-2 mt-3 d-flex flex-column justify-content-center align-items-start h-100"
          :class="[isPremiumAllowed && !hideToggle ? 'col-md-2' : 'col-md-8']">
          <h1 v-if="!isCourse" class="main-title-1 m-0 p-0">{{ capitalize(labType) }} Labs</h1>
          <h1 v-if="isCourse" class="card__label m-0 p-0" style="font-size: 16px">{{ capitalize(labType) }} Labs</h1>
        </div>
        <div class="labtype-toggle col-md-6 mt-2" v-if="isPremiumAllowed && !hideToggle">
          <div id="labtype-toggle" class="p-2 rounded-pill toggle-bg" :class="[tokenBased ? 'orange-bg' : 'green-bg']"
            @click="handleToggle">
            <div class="text-uppercase text-right w-100 labtype" :class="{ 'active': tokenBased }">tokens</div>
            <div class="text-uppercase text-left text-dark labtype" :class="{ 'active': !tokenBased }">subscription
            </div>
            <div class="toggle-circle rounded-circle bg-white" :class="{ 'active': !tokenBased }"></div>
          </div>
        </div>
        <div class="col-md-4 d-flex flex-row justify-content-center align-items-center w-100">
          <div id="selector-icon" class="mx-3 mt-4" style="font-size: 30px;">
            <i class="fa-solid fa-arrow-up-wide-short"></i>
          </div>
          <div id="selector" class="w-100 m-0 p-0">
            <label for="sort-by" class="card__label">Sort By</label>
            <select v-model="sortBy" name="sort-by" id="sort-by" class="form-control text-uppercase">
              <option class="text-uppercase" value="name">Name</option>
              <option class="text-uppercase" value="tags">Category</option>
              <option class="text-uppercase" value="date">Date</option>
              <option class="text-uppercase" value="likes">Likes</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="breaking-news-container m-0 p-0"> -->
    <!--   <i class="fa-solid fa-circle-info"></i> -->
    <!--   <Facts @closefacts="closeFacts" v-if="!hideFacts" /> -->
    <!-- </div> -->
    <div class="container-fluid">
      <InfiniteScroll ref="infiniteScroll" col=4 isCard :items="premiumLabs" @refetch="loadLabs">
        <template v-slot:item="lab">
          <ChallengeCard :isOldPremium="false" :lab="lab.item" :key="lab.item.key" :value="lab.item.value"
            :isLink="true">
            <div class="w-100 text-center d-flex justify-content-center position-relative">
              <px-writeup-btn @show-writeup="getLabWriteup"
                v-if="(isLabStarted(lab.item.id) && lab.item.writeup) || lab.item.solved"
                :lab="toWriteupBtn(lab.item)">writeup</px-writeup-btn>
              <span v-if="(!isLabStarted(lab.item.id) || !lab.item.writeup) && !lab.item.solved" id="submit-btn"
                style="cursor:auto;" class="disabled text-center py-1 mb-3 rounded-sm">writeup</span>
              <px-button class="px-1 m-0" v-if="isLabStarted(lab.item.id)" @click.native="downloadVPN(lab.item.id)">
                <p class="px-button--success mt-0 mb-3 rounded-sm px-1 fw-bold py-1">VPN</p>
              </px-button>
            </div>
            <template #ip-container>
              <!-- <p @click="copyToClipboard(labIp(lab.item.id))" class="clipboard-btn pointer simple-hover mb-0" -->
              <!--   v-if="!isLabStarting(lab.item.id) && isLabStarted(lab.item.id) && isJoined(lab.item.id) && ipValid(lab.item.id)"> -->
              <!--   <i class="far fa-clipboard mr-1"></i>{{ labIp(lab.item.id) }} -->
              <!-- </p> -->

              <p class="clipboard-btn starting-up pointer simple-hover mb-0"
                v-if="isLabStarting(lab.item.id) && isJoined(lab.item.id) && !ipValid(lab.item.id)"><i
                  class="far fa-clipboard mr-1"></i>starting up</p>
              <p @click="userGetLabIp(lab.item.id)" class="clipboard-btn ip-info-text pointer simple-hover mb-0"
                v-if="!isLabStarting(lab.item.id) && isLabStarted(lab.item.id) && isJoined(lab.item.id) && !ipValid(lab.item.id)">
                <i class="far fa-clipboard mr-1"></i>copy to clipboard
              </p>
              <p class="clipboard-btn mb-0" v-if="!isLabStarted(lab.item.id)">Lab not started</p>
              <p class="clipboard-btn text-white rounded px-1 fw-bold join-to-play orange mb-0"
                v-if="isLabStarted(lab.item.id) && !isJoined(lab.item.id)">JOIN TO PLAY</p>
            </template>
            <template #flag-input>
              <template v-if="shouldShowFlagInput(lab.item)">
                <input class="flag-submit p-2 my-3" placeholder="pwnx{...}" type="text"
                  @keyup="(event) => updateLabFlag(event, lab.item.id)" v-if="labIp(lab.item.id) != 'N/A'">
                <input class="flag-submit p-2 my-3" placeholder="Lab not started yet" disabled="disabled" v-else>
              </template>
              <template v-else>
                <div class="p-2 my-3 completed">Solved</div>
              </template>
            </template>
            <template #not-old-premium>
              <div class="row w-100 mx-5 d-flex justify-content-around">
                <px-button class="disabled col-sm-12 px-button--primary outline rounded-pill"
                  v-if="thereArePendingLabs() && !isLabStarted(lab.item.id) && !isLabStarting(lab.item.id)">There are
                  pending tasks, wait until they finish to start other labs</px-button>
                <px-button @click.native="openLabManagmentModal(lab.item, 'start')"
                  v-if="!thereArePendingLabs() && shouldShowStartLabBtn(lab.item.id) && !isLabStarting(lab.item.id)"
                  class="rounded-pill col-sm-12 px-button--success outline">
                  start lab <span v-if="tokenBased"><i class="ml-3 fa-solid fa-coins"></i>{{ lab.item.hcost }}T per
                    hour</span>
                </px-button>
                <px-button disabled class="disabled px-button--secondary outline col-sm-12 rounded-pill"
                  v-if="isLabStarting(lab.item.id)">
                  starting - checked at: {{ getCheckedIn(lab.item.id) }}
                  <div id="wrapper-background">
                    <div id="line"></div>
                    <div id="fill">
                    </div>
                  </div>
                </px-button>
                <px-button id="stop-btn" :class="{ 'disabled': lab.item.owner === undefined }"
                  @click.native="openLabManagmentModal(lab.item, 'stop')"
                  v-if="isLabStarted(lab.item.id) && !isLabStarting(lab.item.id) && isJoined(lab.item.id) && lab.item.owner"
                  class="col-sm-5 rounded-pill px-button--danger outline">
                  {{ labTimer(lab.item.id) }}
                </px-button>
                <px-button @click.native="labAction(lab.item.id, 'leave')"
                  :class="{ 'disabled': lab.item.owner === undefined }" id="leave-btn"
                  v-if="isLabStarted(lab.item.id) && !isLabStarting(lab.item.id) && isJoined(lab.item.id) && !lab.item.owner"
                  class="rounded-pill px-button--primary outline w-100 col-md-5">{{ labTimer(lab.item.id) }}</px-button>
                <px-button id="join-btn" @click.native="labAction(lab.item.id, 'join')"
                  v-if="isLabStarted(lab.item.id) && !isLabStarting(lab.item.id) && !isJoined(lab.item.id)"
                  class="col-sm-5 rounded-pill text-white px-button--orange fw-bold">
                  join to play {{ labTimer(lab.item.id) }}
                </px-button>
                <px-button @click.native="openLabManagmentModal(lab.item, 'reset')"
                  v-if="!getLabFlag(lab.item.id) && isLabStarted(lab.item.id) && !isLabStarting(lab.item.id) && isJoined(lab.item.id)"
                  class="col-sm-5 rounded-pill px-button--secondary outline">reset</px-button>
                <px-button @click.native="submitFlag(lab.item.id)"
                  v-if="getLabFlag(lab.item.id) && isLabStarted(lab.item.id) && !isLabStarting(lab.item.id)"
                  class="col-sm-5 rounded-pill px-button--success">submit</px-button>
              </div>
            </template>
          </ChallengeCard>
        </template>
      </InfiniteScroll>
    </div>
  </div>
</template>

<script>
import ChallengeCard from "../components/ChallengeCard";
import InfiniteScroll from '../components/InfiniteScroll';
import PxWriteupBtn from "../components/PxWriteupBtn";
import PxButton from '../components/PxButton.vue';
import labService from '../api/lab.js';
// import premiumService from "../api/premium";
import LabManagmentModal from "../components/LabManagmentModal.vue";
import LabExtendModal from "../components/LabExtendModal.vue";
import correctAudio from '../assets/audio/correct.mp3';
import wrongAudio from '../assets/audio/wrong.wav';
import { mapState } from 'vuex';

export default {
  name: "PaidLabs",
  components: {
    ChallengeCard,
    InfiniteScroll,
    'px-writeup-btn': PxWriteupBtn,
    PxButton,
  },
  computed: {
    isPremiumAllowed() {
      if (this.isCourse) return false;
      const premiums = this.$parent.user.premium;
      let allowed = false;
      for (const pkg of premiums) {
        allowed = pkg.premium.type.toUpperCase() == "COMPLETE"
        if (allowed) break;
      }
      return allowed;
    },
    ...mapState({
      user: state => state.user
    })
  },
  // props:['labType'],
  props: {
    labType: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      sortBy: "name",
      imageUrl: null,
      hideFacts: false,
      hideToggle: false,
      isCourse: false,
      startedLabsInterval: null,
      premiumLabs: [],
      startedLabs: null,
      startedLabIpMap: {},
      stopDates: null,
      premiumLabInterval: null,
      timers: [],
      labFlags: {},
      pendingLabs: [],
      timerIntervals: [],
      extendModalOpened: false,
      tokenBased: false
    }
  },
  beforeDestroy() {
    // clearInterval(this.timerIntervals)
    this.clearTimerIntervals()
    clearInterval(this.premiumLabInterval)
    clearInterval(this.startedLabsInterval)
  },
  methods: {
    async userGetLabIp(labId) {
      let labType = null;
      const isCorporate = this.user.inCorporate;
      if (isCorporate) {
        labType = "FREE";
      } else {
        labType = this.labType;
      }

      const labIp = await this.getLabIp(labType, labId);
      if (!labIp) {
        this.flashError("Ip not available yet, please try again later");
        return;
      }
      console.log({ labType, labIp })
      this.copyToClipboard(labIp);
    },
    labIp(labId) {
      const labIp = this.startedLabIpMap[labId];
      return labIp
    },
    closeFacts() {
      this.hideFacts = true
    },
    handleToggle() {
      this.tokenBased = !this.tokenBased
      this.loadLabs(true)
      this.getStartedLabs()
    },
    getLabWriteup(lab) {
      this.$parent.getLabWriteup({ "id": lab.lab_id, "name": lab.name })
    },
    copyToClipboard(labIp) {
      navigator.clipboard.writeText(labIp).then(() => {
        this.flashSuccess(`ip ${labIp} copied successfully`);
      }).catch(() => {
        this.flashError("something went wrong copying the ip")
      });
    },
    isJoined(labId) {
      return this.startedLabs.some(lab => lab.id == labId && !!lab.joined)
    },
    getCheckedIn(labId) {
      if (this.pendingLabs.length === 0) return "";
      const checkedAt = this.pendingLabs.filter(lab => lab.id === labId)[0].checked;
      return checkedAt
    },
    thereArePendingLabs() {
      const pendingLabs = this.pendingLabs;
      if (!pendingLabs) return false;
      return pendingLabs.length > 0 && pendingLabs.some(lab => lab.userId === this.user.id)
    },
    openExtendModal(lab, minutes) {
      if (this.extendModalOpened) return;
      this.extendModalOpened = true;
      this.$modal.show(
        LabExtendModal,
        { lab: lab, minutes: minutes, tokenBased: this.tokenBased },
        { height: 'auto' }
      )
    },
    labTimer(labId) {
      if (!this.timers) return "";
      const timerObj = this.timers.filter(timer => timer.id === labId)[0];
      if (!timerObj) return "";
      return timerObj.timer;
    },
    openLabManagmentModal(lab, action = 'start') {
      this.$modal.show(
        LabManagmentModal,
        {
          manageLab: this.labAction,
          lab: lab,
          action: action,
          tokenBased: this.tokenBased
        },
        { height: 'auto' }
      )
    },
    clearTimerIntervals(labId) {
      if (this.timerIntervals.length === 0) return;
      if (!labId) this.timerIntervals.forEach(interval => clearInterval(interval.interval));
      else this.timerIntervals.forEach(interval => {
        if (interval.id === labId) clearInterval(interval.interval)
      })
    },
    shouldShowStartLabBtn(labId) {
      if (!this.startedLabs) return true;
      const isStarted = this.startedLabs.some(lab => lab.id == labId);
      if (isStarted) return false;
      return true;
    }, // TODO: Check for multiple started labs
    capitalize(string) {
      if (!string) return "";
      const stringLower = string.toLowerCase();
      return stringLower.charAt(0).toUpperCase() + stringLower.slice(1);
    },
    downloadVPN(labId) {
      this.$parent.loading = true;
      labService.downloadVPN(labId, this.tokenBased, this.user.id)
        .then((response) => {
          const blob = new Blob([response.data], { type: "text/plain" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "config.ovpn";
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          let error = null;
          if (err.data) {
            error = err.data
          } else {
            error = err.response.data;
          }
          const manageableError = !!error.errorCode && !!error.errorDescription;
          if (manageableError) this.flashError(error.errorDescription);
        })
        .finally(() => {
          this.$parent.loading = false;
        })
    },
    shouldShowFlagInput(lab) {
      if (lab.solved) return false;
      return true;
    },
    getLabFlag(labId) {
      return this.labFlags[labId] ?? null;
    },
    updateLabFlag(event, labId) {
      this.labFlags[labId] = event.target.value;
    },
    submitFlag(labId) {
      const data = {
        "flag": this.getLabFlag(labId),
        "labId": labId
      }
      const [lab] = this.premiumLabs.filter(lab => lab.id === labId)
      labService.submitFlagV2(data)
        .then((response) => {
          const manageableMessage = !!response.data && !!response.data.message;
          if (manageableMessage) this.flashSuccess(response.data.message);
          this.playAudio(true);
          const action = lab.owner ? "stop" : "leave"
          this.labAction(labId, action);
        })
        .catch((err) => {
          let error = null;
          if (err.data) {
            error = err.data
          } else {
            error = err.response.data;
          }

          const manageableError = !!error.errorCode && !!error.errorDescription;
          if (manageableError) this.flashError(error.errorDescription);
          this.playAudio(false)
        })
        .finally(() => {
          this.loadLabs()
        })
    },
    playAudio(correct = false) {
      const audio = new Audio(correct ? correctAudio : wrongAudio);
      audio.volume = 0.3;
      audio.play()
    },
    pendings() {
      const pendings = this.pendingLabs.length > 0
      const userPendings = this.pendingLabs.some(lab => lab.userId == this.user.id)
      return pendings && userPendings
    },
    async labAction(labId, action, hours = 1) {
      if (typeof action !== "string") return;
      action = action.toLowerCase()
      if (action === "stop" && this.isLabStarting(labId)) return
      if (action === "start" && this.pendings()) return;
      if (["stop", "leave"].some(act => act === action)) {
        this.timerIntervals = this.timerIntervals.filter(item => {
          this.clearTimerIntervals();
          return item.id !== labId;
        })
        this.timers = this.timers.filter(item => item.id !== labId)
      }

      labService.labAction(labId, action, hours, this.tokenBased, this.user.id)
        .then((response) => {
          const manageableMessage = !!response.data && !!response.data.message;
          if (manageableMessage) this.flashSuccess(response.data.message);
        })
        .catch((err) => {
          let error = null;
          if (err.data) {
            error = err.data
          } else {
            error = err.response.data;
          }

          const manageableError = !!error.errorCode && !!error.errorDescription;
          if (manageableError) {
            const errorMessage = error.errorDescription;
            this.flashError(errorMessage);
            if (errorMessage.includes("cannot afford")) this.$router.push("/tokens/buy");
          }
        })
        .finally(() => {
          if (action != null && action.toLowerCase() !== "stop") this.getStartedLabs()
          else {
            this.startedLabs = this.startedLabs.filter(lab => lab.id != labId);
            this.stopDates = this.stopDates.filter(lab => lab.id != labId);
            this.clearTimerIntervals(labId);
            this.loadLabs()
          }
        })
    },
    getCountdown() {
      return this.timers;
    },
    ipValid(labId) {
      let ip = this.labIp(labId);
      if (!ip) return false;
      if (typeof ip !== "string") return false;
      ip = ip.toUpperCase()
      if (ip === "N/A") return false;
      if (ip === "") return false;
      return true
    },
    isLabStarted(labId) {
      if (!this.startedLabs) return false;
      return this.startedLabs.some(lab => lab.id === labId)
    },
    isLabStarting(labId) {
      const pendingLabs = this.pendingLabs;
      if (pendingLabs.length === 0) return false;
      return pendingLabs.some(lab => lab.id === labId)
    },
    getStartedLabs() {
      let labType = null;

      if (this.tokenBased) {
        labType = "TOKEN"
      } else if (!this.tokenBased && !this.isCourse) {
        labType = "PREMIUM"
      } else if (!this.tokenBased && this.isCourse) {
        labType = "FREE"
      }

      labService.getStartedLabs(labType)
        .then((response) => {
          this.startedLabs = response.data.labs;
          let pendingLabs = [];
          if (response.data.labs !== null) {
            pendingLabs = response.data.labs.filter(lab => lab.status === "PENDING")
          }
          this.pendingLabs = pendingLabs;

          this.pendingLabs = pendingLabs.map((lab) => {

            return {
              id: lab.id,
              checked: new Date().toLocaleTimeString(),
              userId: lab.userId,
            }
          })

          if (response.data.labs) {

            this.startedLabIpMap = {};
            this.stopDates = response.data.labs.map((lab) => {
              return {
                "id": lab.id,
                "stopDate": lab.stopDate,
                "userId": lab.userId,
              }
            });
            this.setupTimers();
          }
        });
    },
    setupTimers() {
      this.clearTimerIntervals();
      for (const lab of this.stopDates) {
        const stopDate = lab.stopDate;
        const interval = {
          "id": lab.id, "interval": setInterval(() => {

            this.timers = this.timers.filter(item => item.id != lab.id)
            // this.timerIntervals = this.timerIntervals.filter(item => {
            //   return item.id != lab.id
            // })

            let timestamp = (new Date(stopDate + "Z").getTime() - new Date().getTime())
            let seconds = Math.max(Math.floor((timestamp / 1000) % 60), 0);
            let minutes = Math.max(Math.floor((timestamp / (1000 * 60)) % 60), 0);
            let hours = Math.max(Math.floor((timestamp / (1000 * 60 * 60)) % 24), 0);
            let days = Math.max(Math.floor((timestamp / 1000) / 86400), 0);

            const owner = this.user.id === lab.userId

            if (minutes <= 10 && hours === 0 && owner) {
              const labObj = this.premiumLabs.filter(l => l.id == lab.id)[0];
              this.openExtendModal(labObj, minutes);
            }

            // days = days < 10 ? "0" + days:days;
            seconds = seconds < 10 ? "0" + seconds : seconds;
            minutes = minutes < 10 ? "0" + minutes : minutes;
            hours = hours < 10 ? "0" + hours : hours;

            let timer = null;
            if (days === 0) {
              timer = hours + ":" + minutes + ":" + seconds;
            } else {
              timer = days + "d - " + hours + ":" + minutes + ":" + seconds;
            }
            this.timers = this.filterUniqueLabs([{ "id": lab.id, "timer": timer }, ...this.timers]);
          }, 1000)
        };
        this.timerIntervals.push(interval);
      }
    },
    toWriteupBtn(lab) {
      if (!this.startedLabs && !lab.solved) return false;
      if (this.startedLabs && !this.startedLabs.some(l => l.id === lab.id) && !lab.solved) return false;

      return {
        lab_id: lab.id,
        name: lab.name
      }
    },
    filterUniqueLabs(labs) {
      const alreadyMatched = [];
      if (!labs) return alreadyMatched;
      labs.filter(lab => {
        if (!alreadyMatched.some(aLab => aLab.id === lab.id)) {
          alreadyMatched.push(lab);
        }
      })
      return alreadyMatched
    },
    async getLabIp(labType, labId) {
      return await labService.getStartedLabIps(labType, labId)
        .then((response) => {
          const ips = response.data.ips;

          if (ips === null || ips === undefined) return null;
          if (Object.keys(ips).length === 0) return null;
          return ips[labId];
        });
    },
    loadLabs(start = false) {
      if (start) this.$parent.loading = true;

      const isCorporate = this.user.inCorporate;

      let isCourse = this.isCourse;
      let lab_type = null;

      if (isCorporate) {
        lab_type = "RETIRED";
        isCourse = false;
      } else {
        lab_type = this.labType.toLowerCase();
      }

      if (isCourse) {
        lab_type = this.$route.params.name.toLowerCase();
      }

      labService.getPaidLabs(lab_type, this.tokenBased, this.user.id, this.sortBy).then(response => {
        const tokenBtn = response.data.tokenBtn;
        const subBtn = response.data.subBtn;
        const hideToggle = this.hideToggle;

        if (isCourse) {
          const name = this.$route.params.name;
          this.imageUrl = response.data.labs_image;
          this.$parent.breadcrumbs = [{ name: "beginners hub: " + name + " labs" }];
        }

        if (!subBtn && !tokenBtn && !hideToggle) {
          this.tokenBased = false;
          this.hideToggle = true;
        } else if (!tokenBtn && subBtn && !hideToggle) {
          this.hideToggle = true;
          this.tokenBased = false;
        } else if (tokenBtn && !subBtn && !hideToggle) {
          this.hideToggle = true;
          this.tokenBased = true;
        } else if (tokenBtn && subBtn && !hideToggle) {
          this.hideToggle = false;
          // this.tokenBased = false;
        }

        let list = [];
        response.data.labs.forEach(async (element) => {
          let obj = {
            "name": element.name,
            "hcost": element.hcost,
            "solves": element.solves,
            "points": element.user_points,
            "id": element.id,
            "writeup": element.writeup,
            "type": element.type,
            "likes": element.likes,
            "liked": element.liked,
            "owner": this.startedLabs && this.startedLabs.some((lab) => lab.id === lab.id && lab.ownerId === this.user.id),
          };
          try {
            const solved = element.solved_user_ids.split(",").includes(this.$parent.user.id);
            const isRootFlag = element.flag_type === "SYSTEM";
            if (solved && this.labType.toUpperCase() !== "RETIRED") obj['solved'] = true;
            else if (solved && isRootFlag) obj['solved'] = true;
          } catch (error) {
            obj['solved'] = false;
          }
          if (element.difficulty < 40) {
            obj['difficulty'] = 'easy';
          } else if (element.difficulty >= 40 && element.difficulty < 60) {
            obj['difficulty'] = 'medium';
          } else if (element.difficulty >= 60) {
            obj['difficulty'] = 'hard';
          }
          list.push(obj);
        });

        const compare = (a, z) => {
          if (a.name < z.name) {
            return -1;
          }
          if (a.name > z.name) {
            return 1;
          }
          return 0;
        }

        this.premiumLabs = this.filterUniqueLabs([
          ...list, ...this.premiumLabs
        ]);
        if (this.sortBy === "name") this.premiumLabs.sort(compare)
      }).catch((error) => {
        if (error.response !== undefined && error.response.status == 401) {
          this.flashError(error.response.data.error);
          this.$router.push("/home");
        } else {
          this.flashError("Error, please try again later");
          this.$router.push("/home");
        }
      }).finally(() => {
        this.getStartedLabs();
        this.$parent.loading = false
      });
    }
  },
  created() {
    const name = this.$route.params.name;
    const isCorporate = this.user.inCorporate;

    if (name || isCorporate) {
      this.isCourse = true;
      this.tokenBased = false;
    }

    try {
      this.getStartedLabs();
      this.loadLabs(true);
    } catch (e) {
      window.location.reload();
    }
    this.premiumLabInterval = setInterval(this.loadLabs, 30000);
    this.startedLabsInterval = setInterval(this.getStartedLabs, 30000);
    this.$parent.breadcrumbs = [{ name: this.labType + " labs" }];
  },
  watch: {
    'sortBy': function () { this.loadLabs(true) }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/css/colors.scss";

#stop-btn:hover::after {
  content: 'stop lab';
  position: absolute;
  background-color: $soft-red;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 700px;
}

#leave-btn:hover::after {
  content: 'leave';
  position: absolute;
  background-color: $soft-blue;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 700px;
}

.completed {
  background-color: $soft-green;
  text-transform: uppercase;
  letter-spacing: 5px;
  width: 100%;
  margin: 0px 50px;
  font-size: 12px;
  font-weight: 900;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}

#wrapper-background {
  width: 30px;
  height: 30px;
  margin: 0px 40px;
  background-color: $neutral--dark;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: premium_loading 1s linear infinite forwards;
}

#fill {
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 80%;
  min-height: 80%;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#line {
  background-color: $soft-green;
  border-radius: 100% 0% 0% 0%;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 50%;
  min-height: 50%;
}

@keyframes premium_loading {
  100% {
    transform: rotate(360deg);
  }
}

#labtype-toggle {
  display: flex;
  max-width: 200px;
  cursor: pointer;
  min-height: 40px;
  transition: all 0.5s ease;
  font-weight: bolder;
  position: relative;
}

.toggle-circle {
  position: absolute;
  max-width: 200px;
  width: 30px;
  height: 30px;
  top: 50;
  left: 0;
  margin: -3px 5px;
  transition: all 0.5s ease;
}

.toggle-circle.active {
  left: 80%;
  transition: all 0.5s ease;
}

@media screen and (max-width: 768px) {
  #labtype-toggle {
    margin: 20px 0px;
  }
}

.green-bg {
  background-color: $soft-green;
}

.orange-bg {
  background-color: $soft-orange;
}

.labtype {
  display: none;
  transition: all 2s ease;
}

.labtype.active {
  display: block;
  transition: all 2s ease;
}

.starting-up {
  color: $soft-green;
  font-weight: 900;
  letter-spacing: 2px;
  animation: starting-up 1s linear infinite alternate;
  transition: all 1s linear;
}

.ip-info-text {
  color: $soft-blue;
  letter-spacing: 2px;
  font-weight: 900;
}

.join-to-play {
  color: $soft-orange !important;
  font-weight: 900;
  letter-spacing: 2px;
  transition: all 1s ease;
  animation: starting-up 1s linear infinite alternate;
}

.breaking-news-container {
  position: fixed;
  width: 100%;
  left: 15%;
  bottom: 0%;
  z-index: 10;
  font-size: 50px;
  color: #4f6a89;

  &:hover {
    color: lighten(#4f6a89, 10);
    box-shadow: 20px solid lighten(#4f6a89, 10);
  }
}

@keyframes starting-up {
  100% {
    opacity: 0;
  }
}
</style>
