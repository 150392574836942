<template>
  <div class="row m-0 p-0">
    <right-menu-overlay overlayOpenVar="overlay" loadingVar="overlayLoading">
      <template v-slot:overlay-content>
        <div class="row m-0 p-0">
          <div class="col-12 m-0 p-0 d-flex justify-content-start">
            <div class="xmark-container" @click="overlay = false">
              <i class="pointer fa-solid fa-xmark fa-2xl"></i>
            </div>
          </div>
        </div>
        <div id="noaitext-container" v-if="!aiText">
          <div class="noaitext-content d-flex justify-content-center flex-column w-100">
            <div id="ai-user-selector" class="my-5 w-100 px-5">
              <h2 class="main-title-2">Search Company User</h2>
              <input @keyup="aiUser = null" v-model="aiSearch" type="text" name="search-user" id="search-user"
                class="form-control" placeholder="Search User">
              <div class="report-generator-btn w-100 position-relative px-5 pt-5" v-if="aiUser">

                <h2 class="main-title-2" v-if="aiUser !== null && aiText == null">
                  Seems like no AI evaluation has been generated for {{ aiUser.username }} yet.
                </h2>
                <px-button @click.native="generateUserReport()" class="px-button--success outline w-100"
                  v-if="aiUser !== null && aiText == null">generate
                  now</px-button>
              </div>
              <div id="found-users" class="mt-5 d-flex justify-content-center align-items-center flex-row">
                <div id="user-card" v-for="(user) of searchUser()" :key="user.id" @click="aiUser = user"
                  :class="{ 'active': aiUser !== null && aiUser.id === user.id }">
                  <img :src="user.profile_pic" :alt="user.username">
                  <div>
                    <p class="m-0 p-0">
                      {{ user.username }}
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div id="aitext-container" v-if="aiText">
          <div class="back-btn-container">
            <div class="back-btn">
              <i class="fa-solid fa-arrow-left" @click="resetAiUser()">
                BACK
              </i>
            </div>
          </div>
          <div class="aiuser-data">
            <img :src="aiUser.profile_pic" :alt="aiUser.username">
            <p class="m-0 p-0">{{ aiUser.username }}</p>
          </div>
          <div class="my-3 text-center" id="deprecation-warning" v-if="aiDeprecated">
            <h2 class="main-title-2 text-warning">This report is older than 30 days</h2>
            <px-button @click.native="generateUserReport()" class="px-button--orange outline">generate a new report for
              {{ aiUser.username }}</px-button>
          </div>
          <div class="aitext" v-html="parsedAiText"></div>
        </div>
      </template>
    </right-menu-overlay>
    <div class="page-title col-12 d-flex flex-row justify-content-between align-items-center">
      <div class="row m-0 p-0 w-100">
        <div class="col-11">
          <h2 class="main-title-2 m-0 p-0 mt-2 mb-3 w-100">Company Ranking</h2>
        </div>
        <div class="col-1" id="brain-container">
          <i @click="overlay = !overlay" class="fa-solid fa-brain"></i>
        </div>
      </div>
      <div id="detail-selector">
        <select name="detail-selector" id="company-global" v-if="companyGlobal" class="form-control" v-model="sType">
          <option v-for="(stype, idx) of searchTypes" :key="idx" :value="stype">{{ stype }}</option>
        </select>
        <div id="user-selector" v-if="!companyGlobal">
          <div id="activate-global" @click="activateCompanyMode()">Activate Company Mode</div>
          <select name="detail-selector" class="form-control w-100" v-model="filterByUserId">
            <option v-for="(user) of users" :key="user.id" class="pl-2" :value="user.id">{{ user.username }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="graphs col-12 row m-0 p-0">
      <div class="my-2"
        :class="[(idx == 3 || idx == 4 || idx >= (Object.keys(graphs).length - 2)) ? 'col-md-6 col-12 m-0 p-0' : 'col-md-4 col-12 m-0 p-0']"
        v-for="(graph, _, idx) in graphs" :key="idx" id="graph-container">
        <div id="graph" class="h-100">
          <div id="graph-options" class="m-0 p-0" v-if="graph.title">
            <h2 class="main-title-2 m-0 p-0 w-100">{{ graph.title.name }}</h2>
            <select name="graph-option" id="option" v-model="graph.chosenDate"
              v-if="graph.days && graph.days.length > 0">
              <option v-for="(date, idx) in graph.days" :key="idx" :value="date">{{ date }}</option>
            </select>
            <select name="graph-option" id="option" v-model="graph.chosenDate"
              v-if="graph.months && graph.months.length > 0">
              <option v-for="(date, idx) in graph.months" :key="idx" :value="date">{{ date }}</option>
            </select>
          </div>
          <v-chart class="w-100 graphs rounded" :options="graph"></v-chart>
          <div id="graph-actions" class="d-flex justify-content-end align-items-center flex-row fa-xl mb-2">
            <i @click="expandGraph(graph)" class="fa-solid fa-maximize"></i>
          </div>
        </div>
      </div>
      <div v-if="Object.keys(categories).length > 0" id="compliance-stats-container"
        class="d-flex justify-content-start align-items-start w-100 mt-4 flex-column">
        <h2 class="main-title-2">compliance stats</h2>
        <div class="compliance-stats w-100 mt-3 m-0 p-0 d-flex justify-content-start align-items-start shadow-me">
          <div class="d-flex flex-column justify-content-start align-items-start compliance-options w-25 p-2"
            v-if="!isGlobal && !companyGlobal">
            <div class="compliance-selector-container" v-if="complianceDates !== null && complianceDates.length > 0">
              <label for="date-selector">From Date</label>
              <select name="date-selector" id="date-selector" class="compliance-selector" v-model="complianceDate">
                <option :value="compDate" v-for="(compDate, idx) of complianceDates" :key="idx">{{
                  prepareTimestamp(compDate) }}</option>
              </select>
            </div>
            <div class="compliance-selector-container"
              v-if="Object.keys(categories).length > 0 && !companyGlobal && !isGlobal">
              <label for="date-selector">By Category</label>
              <select name="date-selector" id="date-selector" class="compliance-selector" v-model="complianceCategory">
                <option v-for="(category, idx) of Object.keys(categories)" :key="idx" :value="category">{{ category }}
                </option>
              </select>
            </div>
          </div>
          <div class="compliance-graph-container w-100">
            <div class="compliance-data-container" v-for="(category, name, idx) of filterComplianceData(categories)"
              :key="idx">
              <h2 class="main-title-2">{{ name }}</h2>
              <div class="compliance-quiz-completion-bar-wrapper"
                :style="`--completion: '${getPercentage(category)}%'`">
                <div class="compliance-quiz-completion-bar" :style="`--completion: ${getPercentage(category)}%`"></div>
              </div>
              <p class="m-0 p-0">{{ category.correct }}/{{ category.total }}</p>
            </div>
          </div>
        </div>

        <div class="technical-events mt-5 w-100" v-if="events !== null && events.length > 0">
          <h2 class="main-title-2">corporate events</h2>
          <div class="technical-events-container">
            <ul>
              <li v-for="event of filterEventsByUserId(events)" :key="event.id">
                <p @click="$router.push(`/event/${event.id}`)">
                  {{ event.name }}
                </p>
                <div class="technical-created">
                  <h2 class="main-title-2">{{ getDate(event.created) }}</h2>
                </div>
                <div class="technical-events-labs">
                  <ul>
                    <li v-for="lab of event.labs" :key="lab.id">
                      LAB: {{ lab.name }}
                    </li>
                  </ul>
                </div>
                <div class="technical-events-users">
                  <ul>
                    <li v-for="user of event.users" :key="user.id">
                      USER: {{ user.username }}
                    </li>
                  </ul>
                </div>
                <div class="technical-events-flags flags-green" v-if="event.flags.length > 0">
                  <ul>
                    <li v-for="flag of event.flags" :key="flag.id">
                      FLAG: {{ flag.submitted }}
                    </li>
                  </ul>
                </div>
                <div class="technical-events-flags flags-red" v-if="event.flags.length == 0">
                  <ul>
                    <li>
                      NO FLAG
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import ECharts from "vue-echarts";
import companyService from "@/api/company.js";
import { mapState } from "vuex";
import RightMenuOverlay from "@/components/RightMenuOverlay"
import PxButton from "@/components/PxButton"
import { marked } from "marked";
import GraphVisualizer from "@/components/GraphVisualizer";

export default {
  name: "CompanyRanking",
  components: {
    RightMenuOverlay,
    PxButton,
    'v-chart': ECharts
  },
  beforeDestroy() {
    clearInterval(this.aiCheckInterval);
  },
  beforeCreate() {
    this.$parent.breadcrumbs = [{ name: "company", navigation: "/company-ranking" }]
  },
  data() {
    return {
      complianceDate: null,
      complianceDates: [],
      compliancetimeRange: null,
      complianceCategory: null,

      categories: {},
      events: [],

      sType: "COMPANY",
      searchTypes: [
        "USER",
        "COMPANY",
        "GLOBAL"
      ],
      users: [],
      dates: [],
      flags: [],
      starteds: [],
      eventFlags: [],
      labs: [],
      writeups: [],
      chaptersMonthly: [],
      labsMonthly: [],
      answerrsMonthly: [],
      overlay: false,
      overlayLoading: false,
      aiSearch: null,
      aiUser: null,
      aiText: null,
      aiDeprecated: false,
      aiCheckInterval: null,
      isGlobal: false,
      companyGlobal: true,
      chosenDay: null,
      chosenMonth: null,
      filterByUserId: null,
      graphs: {
        // Monthly Users Progress
        monthly: {
          chosenDate: null,
          title: {
            name: "Monthly users progress",
            textStyle: {
              color: "#fff"
            }
          },
          color: this.getColors(),
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              // Use axis to trigger tooltip
              type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
          },
          legend: {
            orient: 'vertical',
            left: 'right',
            textStyle: {
              color: "#fff"
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            axisLabel: {
              textStyle: { color: "#fff" },
            }
          },
          yAxis: {
            type: 'category',
            data: ['08/24', '09/24'],
            axisLabel: {
              textStyle: { color: "#fff" },
            }
          },
          series: [
            {
              name: 'Courses',
              type: 'bar',
              stack: 'total',
              label: {
                show: true
              },
              emphasis: {
                focus: 'series'
              },
              data: Array(2).fill(null).map(() => Math.floor(Math.random() * 420))
            },
            {
              name: 'Labs',
              type: 'bar',
              stack: 'total',
              label: {
                show: true
              },
              emphasis: {
                focus: 'series'
              },
              data: Array(2).fill(null).map(() => Math.floor(Math.random() * 420))
            }
          ],
          days: [], months: []
        },
        // Completed Courses
        courses: {
          chosenDate: null,
          title: {
            name: "Users completed courses",
            textStyle: {
              color: "#fff"
            }
          },
          legend: {
            data: [
            ],
            textStyle: { color: "#fff" }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          xAxis: {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            axisLabel: {
              textStyle: {
                color: "#fff"
              }
            }
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              textStyle: {
                color: "#fff"
              }
            }
          },
          series: [],
          color: this.getColors(),
          days: [], months: []
        },
        // Started Labs
        labs: {
          chosenDate: null,
          title: {
            name: "Started labs count per day",
            textStyle: {
              color: "#fff"

            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          xAxis: {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            axisLabel: {
              textStyle: {
                color: "#fff"
              }
            }
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              textStyle: {
                color: "#fff"
              }
            }
          },
          series: [
            {
              data: Array(8).fill(null).map((_, i) => Math.floor(Math.random() * i * 10)).sort(),
              type: 'bar',
              showBackground: false,
              backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
              }
            }
          ],
          color: this.getRandomColor(),
          days: [], months: []
        },
        // Ranking Donut
        ranking: {
          chosenDate: null,
          title: {
            name: "First 5 Users",
          },
          color: this.getColors(),
          tooltip: {
            trigger: 'item'
          },
          legend: {
            formatter: (name) => {
              const ranks = this.graphs["ranking"]
              const serie = ranks.series[0]
              const data = serie.data;
              const [user] = data.filter(item => item.name === name)
              if (!user) return;
              return `${name} - ${user.value}`;
            },
            top: '5%',
            left: 'left',
            orient: "vertical",
            textStyle: {
              color: "#fff"
            },
          },
          series: [
            {
              name: 'Ranking',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: true,
              itemStyle: {
                borderRadius: 4,
                borderColor: '#000',
                borderWidth: 2
              },
              label: {
                show: false,
                position: 'center',
                textStyle: {
                  color: "#fff"
                }
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 20,
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: [
                { value: 1048, name: 'Search Engine' },
                { value: 735, name: 'Direct' },
                { value: 580, name: 'Email' },
                { value: 484, name: 'Union Ads' },
                { value: 300, name: 'Video Ads' }
              ]
            }
          ],
          days: [], months: []
        },
        // 5 Most Started Labs
        mostStartedLabs: {
          chosenDate: null,
          title: {
            name: '5 Most Started Labs',
            left: 'center',
            textStyle: {
              color: "#fff"
            }
          },
          avoidLabelOverlap: false,
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: 'left',
            textStyle: {
              color: "#fff"
            }
          },
          series: [
            {
              name: 'Labs',
              type: 'pie',
              radius: ['40%', '75%'],
              itemStyle: {
                borderRadius: 5,
                borderColor: '#000',
                borderWidth: 2
              },
              data: [
                { value: Math.floor(Math.random() * 100), name: 'Deserialization1' },
                { value: Math.floor(Math.random() * 100), name: 'Deserialization2' },
                { value: Math.floor(Math.random() * 100), name: 'Deserialization3' },
                { value: Math.floor(Math.random() * 100), name: 'Deserialization4' },
                { value: Math.floor(Math.random() * 100), name: 'Deserialization5' }
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgb(82, 108, 255)",
                }
              }
            }
          ],
          color: this.getColors(),
          days: [], months: []
        },
        // Flags
        flags: {
          chosenDate: null,
          title: {
            name: "Users flags",
            textStyle: {
              color: "#fff"
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            axisLabel: {
              textStyle: {
                color: "#fff"
              }
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              textStyle: {
                color: "#fff"
              }
            }
          },
          series: [
            {
              data: Array(8).fill(null).map((_, i) => Math.floor(Math.random() * i * 10)).sort(),
              type: 'line',
              areaStyle: {}
            }
          ],
          color: this.getRandomColor(),
          days: [], months: []
        },
        // Event Flags
        eventFlags: {
          chosenDate: null,
          title: {
            name: "Users event flags",
            textStyle: {
              color: "#fff"
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            axisLabel: {
              textStyle: {
                color: "#fff"
              }
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              textStyle: {
                color: "#fff"
              }
            }
          },
          series: [
            {
              data: Array(8).fill(null).map((_, i) => Math.floor(Math.random() * i * 10)).sort(),
              type: 'line',
              areaStyle: {}
            }
          ],
          color: "rgb(114, 253, 133)",
          days: [], months: []
        },
        // Completed Chapters
        chapters: {
          chosenDate: null,
          title: {
            name: "Users completed chapters",
            textStyle: {
              color: "#fff"
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          xAxis: {
            type: 'category',
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            axisLabel: {
              textStyle: {
                color: "#fff"
              }
            }
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              textStyle: {
                color: "#fff"
              }
            }
          },
          series: [
            {
              data: Array(8).fill(null).map((_, i) => Math.floor(Math.random() * i * 10)).sort(),
              type: 'line'
            }
          ],
          color: this.getRandomColor(),
          days: [], months: []
        },
        // Writeups Reads
        writeups: {
          chosenDate: null,
          title: {
            name: 'Writeup Reads',
            textStyle: {
              color: "#fff"
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            },
            show: false
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
              axisLabel: {
                textStyle: { color: "#fff" },
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              axisLabel: {
                textStyle: { color: "#fff" },
              }
            }
          ],
          series: [
            {
              name: 'Writeups',
              type: 'line',
              stack: 'Total',
              label: {
                show: true,
                position: 'top'
              },
              areaStyle: {},
              emphasis: {
                focus: 'series'
              },
              data: Array(10).fill(null).map((_, i) => Math.floor(Math.random() * i * 10)).sort()
            }
          ],
          color: this.getRandomColor(),
          days: [], months: []
        },
        // Questions Aswered
        answers: {
          chosenDate: null,
          title: {
            name: "Users answered questions",
            textStyle: {
              color: "#fff"
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            axisLabel: {
              textStyle: {
                color: "#fff"
              }
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              textStyle: {
                color: "#fff"
              }
            }
          },
          series: [
            {
              data: Array(8).fill(null).map((_, i) => Math.floor(Math.random() * i * 10)).sort(),
              type: 'line',
              areaStyle: {}
            }
          ],
          color: this.getRandomColor(),
          days: [], months: []
        }
      },
      ranks: [],
      lastPage: false
    }
  },
  created() {
    this.$parent.breadcrumbs.push({ name: ["company:", this.user.company_name].join(" ") })
    this.loadData(true);
  },
  methods: {
    getDate(input) {
      const date = new Date(input);

      let year = date.getFullYear();
      let month = date.getMonth();
      let day = date.getDay();

      month = month < 10 ? "0" + String(month) : month
      day = day < 10 ? "0" + String(day) : day

      return `${year}/${month}/${day}`;
    },
    filterEventsByUserId(events) {
      const userId = this.filterByUserId;
      if (!userId) return events;
      const filtered = [];

      for (const event of events) {
        const [user] = event.users
        if (user.id !== userId) continue;
        filtered.push(event);
      }

      return filtered;
    },
    getPercentage(category) {
      return Number.parseInt(100 / category.total * category.correct);
    },
    expandGraph(graph) {
      this.$modal.show(
        GraphVisualizer,
        { graph },
        { height: "auto", width: "80%" }
      )
    },
    setupChosenDates() {
      for (const [key, value] of Object.entries(this.graphs)) {
        const isDaily = value.days.length > 0;
        const length = isDaily ? (value.days.length - 1) : (value.months.length - 1);
        this.graphs[key]["chosenDate"] = isDaily ? value.days[length] : value.months[length]
      }
    },
    resetAiUser() {
      this.aiUser = null;
      this.aiText = null;
    },
    searchUser() {
      const users = this.users;
      const search = this.aiSearch;

      if (!search) return users;

      const found = users.filter(item => {
        const username = item.username;
        return username.startsWith(search) || username == search;
      })
      return found
    },
    async getReport() {

      const userId = this.aiUser.id;
      this.aiDeprecated = false;
      let response = await companyService.getUserReport(userId)
        .then(resp => resp.data)
        .catch(error => {
          return error.response.data
        })

      let isError = !!response.errorCode && !!response.errorDescription;
      if (isError) {
        const message = response.errorDescription;
        if (response.errorCode == "invalid_request") {
          this.flashError(message);
        } else if (response.errorCode == "report_not_ready") {
          this.overlay = true;
          this.overlayLoading = true;
          this.flashWarning(message);
        } else if (response.errorCode == "generation_failed") {
          this.flashError(message);
          clearInterval(this.aiCheckInterval);
          this.overlayLoading = false;
        }
        return
      }

      this.overlayLoading = false;
      const report = response.report
      clearInterval(this.aiCheckInterval);
      if (!report) return;
      this.overlay = true;
      this.aiDeprecated = response.deprecated

      this.aiText = report;
    },
    async generateUserReport() {
      this.overlayLoading = true;
      const aiUser = this.aiUser;
      if (!aiUser) return;
      const userId = aiUser.id;

      const response = await companyService.generateUserReport({ userId })
        .then(resp => resp.data)
        .catch(err => err.response.data)

      const isError = !!response.errorCode && !!response.errorDescription;
      if (isError) {
        const message = response.errorDescription;
        this.flashError(message);
        this.overlayLoading = false;
        return;
      }
      this.getReport()
      this.aiCheckInterval = setInterval(this.getReport, 10000)
    },
    getColors() {
      const colors = [
        "#01DCEB",
        "#04EA1B",
        "#EBA50F",
        "#EB5E05",
        "#2D6B4A",
        "#58BCFA",
        "#0043EA",
        "#C15A3C",
        "#6C68FF",
        "#B37F40"
      ];
      return colors
        .map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)

    },
    getRandomColor() {
      const colors = this.getColors();
      const length = colors.length;
      const random = Math.floor(Math.random() * length);
      return colors[random];
    },
    activateCompanyMode() {
      this.sType = "COMPANY";
      this.companyGlobal = true;
    },
    setupGraphs(data) {

      const graphs = this.graphs

      // Labs 
      const labs = graphs["labs"];
      const labsData = data["labs"];

      this.setupGraphSingle(labsData, labs, (prepared) => {
        labs.series[0]["data"] = Object.values(prepared);
        labs.xAxis["data"] = Object.keys(prepared);
        labs.days = labsData["dates"];
      })


      // Writeups
      const writeups = graphs["writeups"];
      const writeupsData = data["writeups"]

      this.setupGraphSingle(writeupsData, writeups, (prepared) => {
        writeups["series"][0]["data"] = Object.values(prepared);
        writeups["xAxis"][0]["data"] = Object.keys(prepared);
        writeups["days"] = writeupsData["dates"]
      })

      // Flags
      const flags = graphs["flags"];
      const flagsData = data["flags"];

      this.setupGraphSingle(flagsData, flags, (prepared) => {
        flags["series"][0]["data"] = Object.values(prepared);
        flags["xAxis"]["data"] = Object.keys(prepared);
        flags["days"] = flagsData["dates"];
      });

      // EventFlags
      const eventFlags = graphs["eventFlags"];
      const eventFlagsData = data["eventFlags"];

      this.setupGraphSingle(eventFlagsData, eventFlags, (prepared) => {
        eventFlags["series"][0]["data"] = Object.values(prepared);
        eventFlags["xAxis"]["data"] = Object.keys(prepared);
        eventFlags["months"] = eventFlagsData["dates"];
      });

      const ranks = graphs["ranking"];
      const ranksData = data["ranks"];
      ranks["series"][0]["data"] = []

      for (const user of ranksData) {
        ranks["series"][0]["data"].push({
          value: user.points,
          name: user.username
        })

      }

      const mostStarted = graphs["mostStartedLabs"];
      const mostStartedData = data["starteds"];
      mostStarted["series"][0]["data"] = []

      for (const started of mostStartedData) {
        mostStarted["series"][0]["data"].push({
          value: started.count,
          name: started.name
        })

      }

      const monthly = graphs["monthly"];

      monthly["months"] = []
      monthly["yAxis"]["data"] = []

      monthly["series"][0]["data"] = [];
      monthly["series"][1]["data"] = [];

      monthly["months"] = data["labsMonthly"]["dates"]
      let chosenMonth = monthly["chosenDate"];
      monthly["yAxis"]["data"] = Object.keys(data["labsMonthly"]).filter(item => item !== "dates" && item <= chosenMonth);


      const filterDate = (obj, date) => {
        return Object.fromEntries(
          // eslint-disable-next-line no-unused-vars
          Object.entries(obj).filter(([key, _]) => key <= date)
        )
      }

      const answers = graphs["answers"];
      const chapters = graphs["chapters"];
      const courses = graphs["courses"];

      answers["months"] = data["answersMonthly"]["dates"]
      chapters["months"] = data["chaptersMonthly"]["dates"]
      courses["months"] = data["chaptersMonthly"]["dates"]

      let monthlyLabsData = Object.values(filterDate(data["labsMonthly"], chosenMonth));
      let monthlyAnswersData = Object.values(filterDate(data["answersMonthly"], chosenMonth));
      let monthlyChaptersData = Object.values(filterDate(data["chaptersMonthly"], chosenMonth));


      monthly["series"][1]["data"] = monthlyLabsData;

      monthlyChaptersData.forEach((el, idx) => {
        if (idx <= monthlyAnswersData.length - 1) {
          monthlyAnswersData[idx] += el;
        }
      })

      monthly["series"][0]["data"] = monthlyAnswersData;

      chosenMonth = chapters["chosenDate"];
      chapters["xAxis"]["data"] = []
      chapters["xAxis"]["data"] = Object.keys(filterDate(data["chaptersMonthly"], chosenMonth)).filter(item => item !== "dates");

      chapters["series"][0]["data"] = Object.values(filterDate(data["chaptersMonthly"], chosenMonth), chosenMonth);

      chosenMonth = answers["chosenDate"];
      answers["xAxis"]["data"] = []
      answers["series"][0]["data"] = []

      answers["xAxis"]["data"] = Object.keys(filterDate(data["answersMonthly"], chosenMonth)).filter(item => item !== "dates");
      answers["series"][0]["data"] = Object.values(filterDate(data["answersMonthly"], chosenMonth));

      chosenMonth = courses["chosenDate"];
      monthlyLabsData = Object.values(filterDate(data["labsMonthly"], chosenMonth));
      monthlyAnswersData = Object.values(filterDate(data["answersMonthly"], chosenMonth));
      monthlyChaptersData = Object.values(filterDate(data["chaptersMonthly"], chosenMonth));

      courses["series"] = []

      courses["series"].push({
        name: "labs",
        data: monthlyLabsData,
        type: "line"
      })

      courses["series"].push({
        name: "courses",
        data: monthlyChaptersData,
        type: "line"
      })


      courses["legend"]["data"] = []
      courses["legend"]["data"].push("labs");
      courses["legend"]["data"].push("courses");

      courses["xAxis"]["data"] = []
      chosenMonth = courses["chosenDate"];
      courses["xAxis"]["data"] = Object.keys(filterDate(data["answersMonthly"], chosenMonth))

      graphs["labs"] = labs;
      graphs["writeups"] = writeups;
      graphs["flags"] = flags;
      graphs["eventFlags"] = eventFlags;
      graphs["ranking"] = ranks;
      graphs["mostStartedLabs"] = mostStarted;
      graphs["chapters"] = chapters;
      graphs["answers"] = answers;
      graphs["monthly"] = monthly;
      graphs["courses"] = courses;

      this.graphs = graphs;
    },
    filterComplianceData(data) {
      // TODO: REFORMAT ME
      const results = {};
      const fin = {}
      const date = this.complianceDate;
      const category = this.complianceCategory;

      for (const [key, value] of Object.entries(data)) {
        if (date !== null && value.created >= date) continue;
        results[key] = value
      }

      for (const [key, value] of Object.entries(results)) {
        if (category !== null && category !== key) continue;
        fin[key] = value
      }

      return fin
    },
    setupGraphSingle(data, graph, callback) {
      const chosenDay = graph["chosenDate"];
      const length = Object.keys(data).length;
      const dayIndex = Object.keys(data).indexOf(chosenDay);
      const limit = 5;

      let start = Math.max(0, (dayIndex - (limit - 1)))
      let end = Math.min(length, dayIndex + 1);

      const prepared = Object.fromEntries(
        Object.entries(data).slice(start, end)
      );

      callback(prepared);
    },
    async loadData(init = false) {

      const userId = this.filterByUserId;
      const requests = [
        companyService.getCompanyRanking(null, this.isGlobal),
        companyService.getCompanyGraphData("all", userId, this.isGlobal),
        companyService.getUsers(),
        companyService.getTopStartedLabs(null, null, this.isGlobal),
        companyService.getMonthlyLabsProgress("all", userId, this.isGlobal)
      ];


      this.$parent.loading = true;

      return await Promise.allSettled(requests)
        .then(async ([ranks, chart, users, starteds, monthly]) => {

          const ranksStatus = ranks.status;
          const chartStatus = chart.status;
          const usersStatus = users.status;
          const startedsStatus = starteds.status;
          const monthlyStatus = monthly.status;

          if (ranksStatus !== "fulfilled") {
            this.flashError("There was an error retrieving company ranking.");
          }

          if (chartStatus !== "fulfilled") {
            this.flashError("There was an error retrieving company charts.");
            return
          }

          if (usersStatus !== "fulfilled") {
            this.flashError("There was an error retrieving company users.");
            return
          }

          if (startedsStatus !== "fulfilled") {
            this.flashError("There was an error retrieving most started labs.");
            return
          }

          if (monthlyStatus !== "fulfilled") {
            this.flashError("There was an error retrieving most started labs.");
            return
          }

          this.users = users.value.data.users.sort((a, b) => {
            const aUsername = a.username;
            const bUsername = b.username;
            return aUsername.charCodeAt(0) - bUsername.charCodeAt(0)
          });
          // this.starteds = starteds.value.data.starteds;
          this.starteds = []
          for (const started of starteds.value.data.starteds) {
            for (const key of Object.keys(started)) {
              if (typeof started[key] === "number") started[key] = Math.floor(Math.random() * 1000);
            }
            this.starteds.push(started)
          }

          const sortFunc = (sortable) => {
            return Object.entries(sortable).sort((a, b) => {
              const key1 = a[0]
              const key2 = b[0]
              return key1 > key2
            }).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
          }

          this.monthly = monthly.value.data.data;

          const flags = sortFunc(chart.value.data.flags);
          const labs = sortFunc(chart.value.data.labs);
          const writeups = sortFunc(chart.value.data.writeups);
          const eventFlags = sortFunc(chart.value.data.eventFlags);

          const labsMonthly = sortFunc(monthly.value.data.data.labs);
          const answersMonthly = sortFunc(monthly.value.data.data.courses.answers);
          const chaptersMonthly = sortFunc(monthly.value.data.data.courses.chapters);

          const dates = new Set([
            ...Object.keys(flags),
            ...Object.keys(labs),
            ...Object.keys(writeups),
            ...Object.keys(eventFlags)
          ])

          const months = [...new Set([
            ...Object.keys(chaptersMonthly),
            ...Object.keys(answersMonthly),
            ...Object.keys(labsMonthly)
          ])]

          flags.dates = Object.keys(flags);
          labs.dates = Object.keys(labs);
          writeups.dates = Object.keys(writeups);
          eventFlags.dates = Object.keys(eventFlags);

          labsMonthly.dates = Object.keys(labsMonthly);
          answersMonthly.dates = Object.keys(answersMonthly);
          chaptersMonthly.dates = Object.keys(chaptersMonthly);

          this.flags = this.fakeObject(flags);
          this.labs = this.fakeObject(labs);
          this.writeups = this.fakeObject(writeups);
          this.eventFlags = this.fakeObject(eventFlags);

          this.labsMonthly = this.fakeObject(labsMonthly);
          this.answersMonthly = this.fakeObject(answersMonthly);
          this.chaptersMonthly = this.fakeObject(chaptersMonthly);

          // this.ranks = ranks.value.data.ranks.sort((a, b) => a["rank"] < b["rank"])
          this.ranks = [];

          for (const rank of ranks.value.data.ranks) {
            for (const key of Object.keys(rank)) {
              if (typeof rank[key] === "number") rank[key] = Math.floor(Math.random() * 1000);
            }
            this.ranks.push(rank)
          }

          this.ranks = this.ranks.sort((a, b) => a["rank"] < b["rank"]);

          this.dates = [...dates];
          this.chosenDay = this.dates[this.dates.length - 1];
          this.chosenMonth = months[months.length - 1];

          this.handleSetupGraphs();
          if (init) this.setupChosenDates();

          const newRequests = [
            companyService.getCorporateEvents(),
          ]
          return await Promise.allSettled(newRequests);
        })
        .then(async ([events]) => {
          this.events = events.value.data.events;

          return await this.loadComplianceData();
        })
        .finally(() => {
          this.$parent.loading = false;
        })
    },
    async loadComplianceData() {

      const userId = this.filterByUserId;
      return companyService.getUserResponses(userId === null ? "all" : userId)
        .then(async (data) => {
          const newReqs = [];
          const responses = data.data.responses;

          responses.forEach(item => {
            const userId = item.userId;
            const timestamp = item.endDate;

            newReqs.push(companyService.getUserResponseChart(
              userId,
              timestamp,
            ))
          })

          if (this.filterByUserId !== null) {
            this.complianceDates = responses.map(item => {
              return item.endDate * 1000;
            });

            this.complianceDates = [...new Set(this.complianceDates)];
            this.complianceDate = this.complianceDates[0] || null
          }

          return await Promise.allSettled(newReqs)
        })
        .then(async (responses) => {
          const chart = {}

          for (const response of responses) {
            const userChart = response.value.data.chart;

            if (!userChart) continue;
            for (const [key, value] of Object.entries(userChart)) {
              if (!chart[key]) chart[key] = { correct: 0, total: 0 }
              chart[key].correct += value.correct || 0;
              chart[key].total += value.total || 0;
            }
          }

          this.categories = chart
        });


    },
    sameData(d1, d2) {
      return d1 === d2
    },
    prepareTimestamp(timestamp) {
      const date = new Date(timestamp);
      const month = date.getMonth();
      const day = date.getDay();
      const year = date.getFullYear();
      return [year, month < 10 ? "0" + month : month, day < 10 ? "0" + day : day].join("-");
    },
    fakeObject(inp) {
      const obj = {};

      for (const key of Object.keys(inp)) {
        if (typeof inp[key] === "number") obj[key] = Math.floor(Math.random() * 1000);
        if (typeof inp[key] !== "number") obj[key] = inp[key];
      }

      return obj
    },
    handleSetupGraphs() {
      this.setupGraphs({
        "flags": this.flags,
        "eventFlags": this.eventFlags,
        "labs": this.labs,
        "writeups": this.writeups,
        "ranks": this.ranks,
        "starteds": this.starteds,
        "chaptersMonthly": this.chaptersMonthly,
        "labsMonthly": this.labsMonthly,
        "answersMonthly": this.answersMonthly
      })
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    }),
    parsedAiText() {
      return marked(this.aiText);
    },
    graphsLength() {
      const graphs = this.graphs;
      return Object.keys(graphs).length;
    }
  },
  watch: {
    'graphs.flags.chosenDate': function () {
      this.handleSetupGraphs();
    },
    'graphs.eventFlags.chosenDate': function () {
      this.handleSetupGraphs();
    },
    'graphs.writeups.chosenDate': function () {
      this.handleSetupGraphs();
    },
    'graphs.answers.chosenDate': function () {
      this.handleSetupGraphs();
    },
    'graphs.chapters.chosenDate': function () {
      this.handleSetupGraphs();
    },
    'graphs.mostStartedLabs.chosenDate': function () {
      this.handleSetupGraphs();
    },
    'graphs.ranking.chosenDate': function () {
      this.handleSetupGraphs();
    },
    'graphs.courses.chosenDate': function () {
      this.handleSetupGraphs();
    },
    'graphs.monthly.chosenDate': function () {
      this.handleSetupGraphs();
    },
    'graphs.labs.chosenDate': function () {
      this.handleSetupGraphs();
    },
    sType: function (new_) {
      this.filterByUserId = null;
      if (new_ === "COMPANY") {
        this.companyGlobal = true;
        this.isGlobal = false;
      } else if (new_ === "USER") {
        this.companyGlobal = false;
        this.isGlobal = false;
        const length = this.users.length;
        this.filterByUserId = length > 0 ? this.users[0].id : null
      } else if (new_ === "GLOBAL") {
        this.companyGlobal = true;
        this.isGlobal = true;
      }
      this.loadData(true);
    },
    companyGlobal: function () {
      this.filterByUserId = null;
      this.complianceDate = null;
      this.complianceDates = [];
      this.categories = {};
      this.complianceCategory = null;
    },
    complianceCategory: function () {
      this.complianceDate = null;
      this.loadComplianceData();
    },
    complianceDate: function () {
      this.loadComplianceData();
    },
    filterByUserId: function () {
      this.loadData()
    },
    aiUser: function (new_) {
      if (!new_) return;
      this.getReport()
    },
    chosenDay: function (new_) {
      if (!new_) return;
      this.handleSetupGraphs();
    },
    chosenMonth: function (new_) {
      if (!new_) return;
      this.handleSetupGraphs();
    },
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/css/colors.scss";

#graph-container {
  position: relative;
  color: #fff !important;
}

#graph {
  margin: 0px 10px;
  padding: 0px 10px;
  background-color: $neutral--dark;
  box-shadow: $neutral--darkest 10px 0px 10px 0px,
    $neutral--darkest -10px 0px 10px 0px,
    $neutral--darkest 0px 10px 10px 0px, $neutral--darkest 0px -10px 10px 0px;
}

#graph-options {
  display: flex;
  justify-content: start;
}

#option {
  flex: 0 2 auto;
  margin-left: auto !important;
  border: 1px solid #fff;
  text-transform: uppercase;
  background-color: $neutral--darker;
  color: #fff;
  padding: 8px 5px;
  width: 100%;
}

#activate-global {
  margin: 0px 10px !important;
  padding: 0px !important;
  min-height: 100% !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $soft-green;
  color: #000;
  cursor: pointer;

  &:hover {
    background-color: darken($soft-green, 10);
  }
}

#detail-selector {
  flex: 0 1 auto;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-left: auto !important;

  select {
    text-transform: uppercase !important;
    border: 1px solid $soft-green;
    padding-left: 20px !important;
  }
}

#user-selector {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  min-width: 100% !important;
}

#brain-container {
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    border: 1px solid #fff;
    padding: 10px;
    cursor: pointer;
    transition: all 100ms linear;
    color: darken(pink, 10);
  }

  i:hover {
    background-color: white;
    color: #000;
    transition: all 100ms linear;
  }

}

#noaitext-container {
  min-height: 100dvh !important;
  min-width: 100%;
  display: grid;
  place-items: center;
}

#search-user {
  min-width: 100% !important;
  width: 100% !important;
}

#ai-user-selector {
  position: relative;
}

#found-users {
  position: relative;
  overflow-y: scroll;
  width: 100% !important;
  height: auto !important;
  flex-wrap: wrap;
  min-height: 100dvh;
  max-height: 100dvh;
  padding-bottom: 30rem;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

#user-card {
  background-color: $neutral--darkest;
  padding: 10px;
  min-width: 200px !important;
  max-width: 200px !important;
  min-height: 200px !important;
  max-height: 200px !important;
  position: relative;
  display: flex;
  justify-content: center !important;
  overflow: hidden !important;
  flex-direction: column !important;
  align-items: center;
  border: 1px solid gray;
  border-radius: 5px;
  margin: 0px 10px;
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: 10px;
  transition: all 100ms linear;
  box-shadow: $neutral--darkest 10px 0px 10px 0px,
    $neutral--darkest -10px 0px 10px 0px,
    $neutral--darkest 0px 10px 10px 0px, $neutral--darkest 0px -10px 10px 0px;

  &:hover {
    background-color: $neutral--darker;
  }

  &.active {
    border: 1px solid $soft-green;
    background-color: $neutral--darker;

    img {
      border: 1px solid $neutral--darkest;
    }

    box-shadow: $soft-green 10px 0px 10px 0px,
    $soft-green -10px 0px 10px 0px,
    $soft-green 0px 10px 10px 0px,
    $soft-green 0px -10px 10px 0px;
  }

  img {
    max-height: 100px !important;
    max-width: 100px !important;
    height: 100px !important;
    width: 100px !important;
    margin: 10px 0px;

    // object-fit: contain;
    border: 1px solid $neutral--dark;
    border-radius: 50%;
  }

  p {
    font-size: 14px;
    overflow-wrap: break-word !important;
    max-width: 100px;
  }
}

#aitext-container {
  padding-right: 50px;
  margin-left: 20px;
  overflow-y: scroll;
  max-height: 100dvh;
  min-height: 100dvh;
  text-justify: center;
  text-align: justify;
  font-size: 17px;
  padding-bottom: 50px;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.aitext ::v-deep {
  h1 {
    color: $soft-green !important;
    font-size: 25px;
    text-transform: uppercase;
    text-align: left;
  }
}

.aiuser-data {
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 20px;
  margin-bottom: 20px;

  img {
    max-height: 200px;
    max-width: 200px;
    min-height: 200px;
    min-width: 200px;
    border-radius: 50%;
    margin: 10px 0px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;
    border: 1px solid #fff;
  }
}

.back-btn-container {
  position: relative;
  min-width: 100% !important;

  i {
    padding: 10px;
    width: auto;
    cursor: pointer;
    height: auto;

    border: 1px solid gray;
    transition: all 100ms linear;

    &:hover {
      background-color: gray;
    }
  }
}

.xmark-container {
  text-align: left !important;
  margin: 0px !important;
  padding: 0px !important;
  margin-left: 10px;
  position: relative;
  border: 1px solid $neutral--dark;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  cursor: pointer;

  i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    padding: 0 !important;

  }

  &:hover {
    background-color: $neutral--dark;
  }
}

#graph-actions {
  color: $neutral--darkest;

  i {
    cursor: pointer;
  }
}

.compliance-stats-loading-overlay {
  position: absolute;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  z-index: 100;
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
}

.compliance-stats {

  position: relative;
  background-color: $neutral--dark;

  &.shadow-me {
    box-shadow: $neutral--darkest 10px 0px 10px 0px,
      $neutral--darkest -10px 0px 10px 0px,
      $neutral--darkest 0px 10px 10px 0px, $neutral--darkest 0px -10px 10px 0px;
  }
}

.compliance-selector-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 10px 0px;
}

.compliance-selector {
  padding: 10px;
  width: 100% !important;
  border: none;
  font-weight: 900;
  color: #fff;
  background-color: $neutral--darkest;
}

.compliance-graph-container {
  display: flex;
  flex-direction: column;
}

.compliance-data-container {
  margin: 2px 2px;
  padding: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: $neutral--darkest;

  h2 {
    margin: 0;
    padding: 0;
    line-height: 1;
    width: 100%;
    max-width: 40%;
    white-space: nowrap;
  }
}

.compliance-quiz-completion-bar-wrapper {
  position: relative;
  width: 100%;
  margin: 0px 10px;
  min-height: 15px;
  border-radius: 10px;
  overflow: hidden;
  background-color: $neutral--dark;
}

.compliance-quiz-completion-bar {
  border-radius: 10px;
  position: absolute;
  width: var(--completion, 0%);
  height: 100%;
  left: 0%;
  top: 0%;

  background: linear-gradient(to right,
      #3fffa2 0%,
      #ffdb3a 35%,
      #ff993a 60%,
      #e5405e 100%);
}

.compliance-quiz-completion-bar-wrapper::after {
  content: var(--completion, "0%");
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  line-height: 1;
  left: 50%;
  top: 0%;
}

.technical-events-container {
  width: 100%;
  text-transform: uppercase;

  box-shadow: $neutral--darkest 10px 0px 10px 0px,
    $neutral--darkest -10px 0px 10px 0px,
    $neutral--darkest 0px 10px 10px 0px, $neutral--darkest 0px -10px 10px 0px;

  ul {
    list-style-type: none;
    margin: 0 !important;
    padding: 0 !important;
    // width: 100%;

  }

  li {
    font-weight: 900 !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 20px;
    // width: 100%;
    line-height: 1;

    &:nth-child(even) {
      background-color: $neutral--dark;
    }

    &:nth-child(odd) {
      background-color: $neutral--darkest;
    }


  }

  p {
    line-height: 1;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
}

.technical-events-flags,
.technical-events-users,
.technical-events-labs {
  text-transform: uppercase;

  ul {
    list-style-type: none;
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
  }

  li {
    margin: 0 !important;
    font-weight: 900 !important;
    width: 100%;
    line-height: 1;
    background-color: $soft-green !important;
    color: $neutral--darkest;
    padding: 10px;
  }

  &.flags-red {
    li {
      background-color: $soft-red !important;
    }
  }
}

.technical-created {
  margin: 0 !important;
  padding: 0 !important;

  h2 {
    margin: 0 !important;
    padding: 0 !important;
    line-height: 1 !important;
  }
}
</style>
