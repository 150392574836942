import axios from "axios";
import store from "../store";

const httpService = {
  request(method, endpoint, httpOptions = {}, authenticated = true) {
    const baseUrl = process.env.VUE_APP_API_URL;

    if (!httpOptions.headers) {
      httpOptions.headers = {};
    }

    if (authenticated) {
      const authToken = store.state.user.token;
      httpOptions.headers.Authorization = `Bearer ${authToken}`;
    }
    // GET
    if (method === "GET") {
      return axios({
        method,
        url: `${baseUrl}${endpoint}`,
        ...httpOptions,
        withCredentials: true,
        timeout: 30000,
        maxRedirects: 0
      });
    }

    // POST, PUT, DELETE
    return axios.get(`${baseUrl}/csrf`).then(response => {
      httpOptions.headers["X-CSRFToken"] = response.data.antiCSRFToken;
      return axios({
        method,
        url: `${baseUrl}${endpoint}`,
        ...httpOptions,
        withCredentials: true,
        timeout: 30000,
        maxRedirects: 0
      });
    });
  },

  get(endpoint, httpOptions = {}, authenticated = true) {
    return new Promise((resolve, reject) => {
      this.request("GET", endpoint, httpOptions, authenticated)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },

  post(endpoint, httpOptions = {}, authenticated = true) {
    return new Promise((resolve, reject) => {
      this.request("POST", endpoint, httpOptions, authenticated)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },

  put(endpoint, httpOptions = {}, authenticated = true) {
    return new Promise((resolve, reject) => {
      this.request("PUT", endpoint, httpOptions, authenticated)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },

  delete(endpoint, httpOptions = {}, authenticated = true) {
    return new Promise((resolve, reject) => {
      this.request("DELETE", endpoint, httpOptions, authenticated)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  all(requests) {
    return axios.all(requests);
  }
};

export default httpService;
