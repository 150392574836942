<template>
  <div class="container">
    <div class="my-5" v-html="getContent">
    </div>
  </div>
</template>
<script>
// import PxButton from "@/components/PxButton";
import SurveyModal from "@/components/SurveyModal";
import userService from "@/api/user";
import coursesService from "@/api/courses";
import { marked } from "marked";

export default {
  name: "CourseChapter",
  data() {
    return {
      chapter: null,
      cName: this.$route.params.name,
      cId: this.$route.params.chapterId,
    }
  },
  created() { this.getChapterContent() },
  beforeCreate() {
    const cName = this.$route.params.name;
    this.$parent.breadcrumbs = [{ name: "beginners hub > " + cName }]
  },
  computed: {
    getContent() {
      if (!this.chapter) return;
      return marked(this.chapter.content);
    }
  },
  methods: {
    async startChapterQuestions() {
      const survey = await userService.getSurvey(this.cId).then(resp => resp.data.questions)
      this.$modal.show(
        SurveyModal,
        {
          survey: survey,
          chapterId: this.cId,
          surveySaved: () => { }
        },
        { height: "auto" }
      )
    },
    getChapterContent() {
      coursesService.getChapter(this.cId)
        .then((response) => {
          this.chapter = response.data.chapter;
        })
    },
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/colors.scss";

::v-deep h1 {
  font-size: 26px !important;
  color: $soft-green !important;
}

::v-deep h2 {
  color: $soft-green !important;
}

::v-deep p {
  text-align: justify !important;
}
</style>
