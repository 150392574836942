import { render, staticRenderFns } from "./PxTextArea.vue?vue&type=template&id=75fd0296&scoped=true"
import script from "./PxTextArea.vue?vue&type=script&lang=js"
export * from "./PxTextArea.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75fd0296",
  null
  
)

export default component.exports