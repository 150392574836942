<template>
  <div class="container-fluid table vh-100">
    <div class="table__center">
      <div class="row align-items-center mb-3">
        <div class="col-md-6 col-12">
          <h1 class="main-title-1">PWNX Pricing Quotation</h1>
        </div>
        <div class="col-md-6 col-12 text-right">
          <div class="w-100 d-flex align-items-center justify-content-end">
            <div class="col-3 w-100">
              <px-button
                  @click.native="goBack()"
                  class="px-button--secondary icon-left outline"
                  icon="left-arrow">
                Back
              </px-button>
            </div>
          </div>
        </div>
      </div>

      <form autocomplete="off" class="rounded-card__item bg-gradient" @submit="askQuotation">
        <div class="heading">Company information</div>
        <!-- name - email -->
        <div class="row">
          <div class="col form-group">
            <label for="name" class="card__label">Name</label>
            <input autocomplete="off" type="text" name="name" id="name" class="form-control"
                   v-model="input.name" required placeholder="Your name">
          </div>
          <div class="col form-group">
            <label for="email" class="card__label">Email</label>
            <input autocomplete="off" type="email" name="email" id="email" class="form-control"
                   v-model="input.email" placeholder="your@email.com" required>
          </div>
        </div>

        <!-- company name and website -->
        <div class="row">
          <div class="col form-group">
            <label for="company-name" class="card__label">Company Name</label>
            <input autocomplete="off" type="text" name="company-name" id="company-name" class="form-control"
                   v-model="input.companyName" required placeholder="Company name">
          </div>
          <div class="col form-group">
            <label for="company-website" class="card__label">Company website</label>
            <input autocomplete="off" type="url" name="company-website" id="company-website" required
                   class="form-control" v-model="input.companyWebsite" placeholder="https://my-company.com">
          </div>
        </div>

        <div class="heading">PWNX estimate data</div>
        <!-- max members and total event hours -->
        <div class="row">
          <div class="col form-group">
            <label for="max-members" class="card__label">Company Members <small>(on PWNX)</small></label>
            <input autocomplete="off" type="text" name="max-members" id="max-members" class="form-control"
                   v-model="input.maxMembers" required placeholder="PWNX members">
          </div>
          <div class="col form-group">
            <label for="total-event-hours" class="card__label">Total Events hours</label>
            <input autocomplete="off" type="text" name="total-event-hours" id="total-event-hours" required
                   class="form-control" v-model="input.totalEventHours" placeholder="Total event hours">
          </div>
        </div>

        <!-- labs per event - users per event -->
        <div class="row padding-bottom-20">
          <div class="col form-group">
            <label for="labs-event" class="card__label">Labs per event</label>
            <input autocomplete="off" type="text" name="labs-event" id="labs-event" class="form-control"
                   v-model="input.eventLabs" required placeholder="Labs per event">
          </div>
          <div class="col form-group">
            <label for="users-event" class="card__label">Users per event</label>
            <input autocomplete="off" type="text" name="users-event" id="users-event" required
                   class="form-control" v-model="input.eventUsers" placeholder="Users per event">
          </div>
        </div>

        <div class="row">
          <div class="col">
            <vue-recaptcha
                ref="recaptcha"
                @verify="onCaptchaVerified"
                @expired="onCaptchaExpired"
                :sitekey="gRecaptchaKey"
                :loadRecaptchaScript="true">
              <button type="submit" class="px-button px-button--success">
                Ask quotation
              </button>
            </vue-recaptcha>
          </div>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
import appService from "../api/app";
import VueRecaptcha from "vue-recaptcha";
import PxButton from "@/components/PxButton";

export default {
  name: "CompanyEstimate",
  components: {
    PxButton,
    VueRecaptcha,
  },
  data() {
    return {
      gRecaptchaKey: null,
      input: {
        name: "",
        email: "",
        companyName: "",
        companyWebsite: "",
        maxMembers: null,
        totalEventHours: null,
        eventLabs: null,
        eventUsers: null
      }
    }
  },
  created() {
    this.gRecaptchaKey = process.env.VUE_APP_gRecaptchaKey;
  },
  methods: {
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    onCaptchaVerified(recaptchaToken) {
      this.$refs.recaptcha.reset();
      this.askQuotation(recaptchaToken);
    },
    checkFields() {
      for (const key of Object.keys(this.input)) {
        if (!this.input[key]) {
          return false;
        }
      }
      return true;
    },
    askQuotation(recaptchaToken) {
      if (this.checkFields()) {
        this.$parent.loading = true;
        this.input.recaptchaToken = recaptchaToken;
        appService.askQuotation(this.input)
            .then(response => {
              const successMessage = !!response && !!response.data && !!response.data.message;
              if (successMessage) {
                this.flashSuccess(response.data.message);
              }
            })
            .catch(error => {
              const manageableError = !!error && !!error.data && !!error.data.errorDescription;
              if (manageableError) {
                this.flashError(error.data.errorDescription);
              }
            })
            .finally(() => {
              this.$router.push("/login").catch();
              this.$parent.loading = false;
            })
      } else {
        this.flashError("Please fill in all fields");
      }

    }
  }
}
</script>

<style scoped>
.heading {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  display: block;
  color: #768aa3;
  font-weight: 700;
  width: 100%;
  border-bottom: 1px solid #768aa3;
  margin-bottom: 1rem;
}
</style>
