<template>
  <div>
    <div class="container-fluid mx-0">
      <div v-responsive.md.lg.xl class="row mx-0">
        <div class="col mx-0">
          <h3 class="m-2">Top players chart - Last {{ graphDays }} days</h3>
          <v-chart :options="top3Users" autoresize class="graphs w-100"></v-chart>
        </div>
      </div>
      <div class="row d-flex align-items-center">
        <div class="col-md-3 col-12 px-0 pl-md-0 ml-0">
          <px-card class="px-card--neon mx-0 mr-md-2 h-100 pointer green" @click.native="navigateTo('/labs')">
            <div class="row d-flex justify-content-center text-md-center flex-md-column flex-lg-row text-lg-left">
              <div class="col">
                <p class="px-card__title">{{ labs }}</p>
                <p class="px-card__caption green">Labs</p>
              </div>
              <div class="col d-flex justify-content-center align-items-center">
                <i class="icon p-1 icon-flask green"></i>
              </div>
            </div>
          </px-card>
        </div>
        <div class="col-md-3 col-12 px-0">
          <px-card class="px-card--neon mx-0 mx-md-2 h-100 pointer blue"
            @click.native="navigateTo('/leaderboards/user')">
            <div class="row d-flex justify-content-center text-md-center flex-md-column flex-lg-row text-lg-left">
              <div class="col">
                <p class="px-card__title">{{ users }}</p>
                <p class="px-card__caption blue">Users</p>
              </div>
              <div class="col d-flex justify-content-center align-items-center">
                <i class="icon p-1 icon-user blue"></i>
              </div>
            </div>
          </px-card>
        </div>
        <div class="col-md-3 col-12 px-0">
          <px-card class="px-card--neon mx-0 mx-md-2 h-100 pointer red" @click.native="navigateTo('/events')">
            <div class="row d-flex justify-content-center text-md-center flex-md-column flex-lg-row text-lg-left">
              <div class="col">
                <p class="px-card__title">{{ events }}</p>
                <p class="px-card__caption red">events</p>
              </div>
              <div class="col d-flex justify-content-center align-items-center">
                <i class="icon p-1 icon-calendar red"></i>
              </div>
            </div>
          </px-card>
        </div>
        <div class="col-md-3 col-12 px-0 pr-md-0 mr-0">
          <px-card class="px-card--neon mx-0 ml-md-2 h-100 pointer yellow"
            @click.native="navigateTo('/leaderboards/team')">
            <div class="row d-flex justify-content-center text-md-center flex-md-column flex-lg-row text-lg-left">
              <div class="col">
                <p class="px-card__title">{{ teams }}</p>
                <p class="px-card__caption yellow">teams</p>
              </div>
              <div class="col d-flex justify-content-center align-items-center">
                <i class="p-1 icon icon-users-group yellow"></i>
              </div>
            </div>
          </px-card>
        </div>
      </div>
    </div>
    <div class="container-fluid my-3">
      <h1 class="main-title-1 mb-2">Leaderboard</h1>
      <px-table class="py-0">
        <div v-responsive.lg.xl class="row table-header mb-3">
          <div class="col-1 py-3">
            <h2 class="mb-0">rank</h2>
          </div>
          <div class="col-2 col-md-1 py-3">
            <h2 class="mb-0">avatar</h2>
          </div>
          <div class="col-1 py-3">
            <h2 class="mb-0">country</h2>
          </div>
          <div class="col-4 py-3">
            <h2 class="mb-0">name</h2>
          </div>
          <div class="col-1 py-3">
            <h2 class="mb-0">points</h2>
          </div>
          <div v-responsive.md.lg.xl class="col-2 py-3">
            <h2 class="mb-0">flag 1</h2>
          </div>
          <div v-responsive.md.lg.xl class="col-2 py-3">
            <h2 class="mb-0">flag 2</h2>
          </div>
        </div>
        <div class="row my-2 my-md-0 table__row d-flex align-items-centers py-0" v-for="player in topPlayers"
          :key="player.id">
          <div class="col-1 col__rank">
            {{ player.rank }}
          </div>
          <div class="col-2 col-md-1">
            <div class="avatar avatar--sm my-1" :style="{ backgroundImage: `url(${player.picture})` }"
              @click="navigateTo('/user/' + player.id)" />
          </div>
          <div class="col-1">
            <flag :iso="player.country" :squared="false" />
          </div>
          <div class="col-4" @click="navigateTo(player.id)">
            <h2 class="mb-0 simple-hover" @click="$parent.$router.push(`/user/${player.id}`)">
              {{ player.name | truncate(15) }}
            </h2>
          </div>
          <div class="col-1 col__points">
            {{ player.points }}
          </div>
          <div v-responsive.md.lg.xl class="col-2 col__users">
            {{ player.users }}
          </div>
          <div v-responsive.md.lg.xl class="col-2 col__systems">
            {{ player.systems }}
          </div>
        </div>
      </px-table>
    </div>
    <div v-responsive.md.lg.xl class="container-fluid">
      <div class="row">
        <div class="col-12 col-lg-6 p-0">
          <px-card class="ml-0 mr-1">
            <h3 class="m-2">
              Daily subscriptions
            </h3>
            <div class="row">
              <v-chart :options="dailySubs" autoresize class="w-100 graphs"></v-chart>
            </div>
          </px-card>
        </div>
        <div class="col-12 col-lg-6 p-0">
          <px-card class="mr-0 ml-1">
            <h3 class="m-2">Daily flags</h3>
            <div class="row">
              <v-chart :options="dailyFlags" autoresize class="w-100 graphs"></v-chart>
            </div>
          </px-card>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <px-table-event class="px-4" :events="nextEvents"></px-table-event>
      </div>
    </div>
    <!-- <div class="promotion-overlay" v-if="promotion !== null"> -->
    <!--   <div id="image-container" :style="{ width: 40 + '%' }" class="py-3"> -->
    <!--     <img -->
    <!--       :src="promotion.image" -->
    <!--       :style="{ width: 'auto', height: 'auto' }" -->
    <!--       alt="promotion-image" -->
    <!--     /> -->
    <!--   </div> -->
    <!--   <p>{{ promotion.description }}</p> -->
    <!--   <div class="checkbox-container d-flex flex-row"> -->
    <!--     <input -->
    <!--       type="checkbox" -->
    <!--       name="promotion-checkbox" -->
    <!--       v-model="promotion.checked" -->
    <!--     /> -->
    <!--     <span class="ml-3">don't show me this promotion again</span> -->
    <!--   </div> -->
    <!--   <div -->
    <!--     id="promotion-buttons" -->
    <!--     class="d-flex flex-row justify-content-between" -->
    <!--   > -->
    <!--     <px-button -->
    <!--       class="px-button--secondary mx-3 outline mt-3" -->
    <!--       @click.native="closePromotionOverlay" -->
    <!--     > -->
    <!--       Close -->
    <!--     </px-button> -->
    <!--     <px-button -->
    <!--       class="px-button--success mt-3 mx-3" -->
    <!--       @click.native="$router.push(`/${promotion.link}`)" -->
    <!--     > -->
    <!--       Find out more -->
    <!--     </px-button> -->
    <!--   </div> -->
    <!-- </div> -->
    <div class="promotion-overlay" v-if="promotions.length > 0">
      <PromotionModal :promotions="promotions" :closeAction="() => promotions = []" />
    </div>
    <div class="confirm-overlay-wrapper" v-if="surveyTailModal">
      <div class="confirm-overlay-background"></div>
      <div class="confirm-overlay">
        <ConfirmationModal class="confirmation-modal" :confirmAction="modalConfirmAction"
          :closeAction="modalCloseAction" modalTitle="User Survey">
          <h2>Would you like to start with a free basic course to learn the fundamentals and kickstart your professional
            journey?</h2>
        </ConfirmationModal>
      </div>
    </div>
  </div>
</template>
<script>
import ECharts from "vue-echarts";
import { mapState } from "vuex";
import statsService from "../api/stats";
import userService from "../api/user";
import httpService from "../api/http";
import labService from "../api/lab";
import eventService from "../api/event";
import PxCard from "../components/cards/PxCard";
import PxTable from "../components/PxTable.vue";
import PxTableEvent from "../components/PxTableEvent";
// import PxButton from "../components/PxButton";
import SurveyModal from '@/components/SurveyModal';
import ConfirmationModal from '@/components/ConfirmationModal';
import UserType from "../enums/user";
import PromotionModal from "@/components/PromotionModal";

export default {
  name: "Dashboard",
  components: {
    "v-chart": ECharts,
    "px-card": PxCard,
    "px-table": PxTable,
    // "px-button": PxButton,
    PromotionModal,
    ConfirmationModal,
    PxTableEvent,
  },
  computed: {
    isUser() {
      return this.user.role === UserType.USER
    },
    ...mapState({
      user: (state) => state.user,
    })
  },
  data() {
    return {
      survey: [],
      promotions: [],
      surveyTailModal: false,
      graphDays: null,
      labs: 0,
      users: 0,
      events: 0,
      teams: 0,
      upcomingEvent: null,
      upcomingLab: {},
      firstBlood: null,
      upcomingLabStats: [],
      dailySubs: {
        textStyle: {
          color: "#768aa3",
          fontFamily: "sans-serif",
        },
        xAxis: {
          show: true,
          type: "category",
          data: [],
          axisLine: {
            lineStyle: {
              color: "#768aa3",
              // opacity: 1
              width: 0,
            },
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        yAxis: {
          type: "value",
          show: true,
          axisLine: {
            lineStyle: {
              color: "#768aa3",
              opacity: 1,
              width: 0,
            },
          },
          splitLine: {
            lineStyle: {
              color: "#555",
              type: "solid",
              opacity: 0,
            },
          },
        },
        series: [
          {
            data: [],
            markLine: {
              symbol: ["none"],
              label: { show: false },
              lineStyle: {
                color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgba(255, 255, 255,0)",
                  },
                  {
                    offset: 1,
                    color: "rgba(255, 255, 255,0.1)",
                  },
                ]),
              },
              // data: [
              //   { xAxis: 1 },
              //   { xAxis: 2 },
              //   { xAxis: 3 },
              //   { xAxis: 4 },
              //   { xAxis: 5 },
              //   { xAxis: 6 },
              //   { xAxis: 7 },
              // ],
              data: (function () {
                return Array(30)
                  .fill(null)
                  .map((_, i) => ({ xAxis: i + 1 }));
              })(),
            },
            smooth: false,
            type: "line",
            symbol: "circle",
            symbolSize: 0,
            lineStyle: {
              color: "rgb(253,114,114)",
              width: 0.7,
            },
            areaStyle: {
              opacity: 0.8,
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgb(253,114,114)",
                },
                {
                  offset: 1,
                  color: "rgba(253,114,114,0)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
          },
        ],
      },
      dailyFlags: {
        textStyle: {
          fontFamily: "sans-serif",
        },
        color: ["rgba(250, 154, 65,1)", "rgba(114, 253, 133,1)"],
        legend: {
          data: ["Flag 2", "Flag 1"],
          textStyle: {
            color: "#768aa3",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        xAxis: {
          show: true,
          type: "category",
          data: [],
          axisLine: {
            lineStyle: {
              color: "#768aa3",
              opacity: 0,
            },
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#768aa3",
              opacity: 0,
            },
          },
          splitLine: {
            lineStyle: {
              color: "#555",
              type: "dotted",
              opacity: 0,
            },
          },
        },
        series: [
          {
            data: [],
            type: "line",
            symbol: "circle",
            symbolSize: 0,
            lineStyle: {
              color: "rgba(250, 154, 65,1)",
              width: 2,
            },
            areaStyle: {
              opacity: 0.8,
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(250, 154, 65,1)",
                },
                {
                  offset: 1,
                  color: "rgba(250, 154, 65,0)",
                },
              ]),
            },
            name: "Flag 2",
          },
          {
            data: [],
            type: "line",
            symbol: "circle",
            symbolSize: 0,
            lineStyle: {
              color: "rgba(114, 253, 133,1)",
              width: 2,
            },
            areaStyle: {
              opacity: 0.8,
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(114, 253, 133,1)",
                },
                {
                  offset: 1,
                  color: "rgba(114, 253, 133,0)",
                },
              ]),
            },
            name: "Flag 1",
          },
        ],
      },
      topPlayers: [],
      top3Users: {
        color: ["rgb(108,255,128)", "rgb(238,85,85)", "rgb(112,148,255)"],
        legend: {
          show: true,
          data: [],
          textStyle: {
            color: "#768aa3",
            fontFamily: "sans-serif",
          },
        },
        grid: {
          left: 0,
          right: 0,
        },
        xAxis: {
          show: false,
          type: "category",
          data: [],
          zlevel: 4,
          axisLine: {
            lineStyle: {
              color: "#768aa3",
              // opacity: 1
              width: 0,
            },
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        yAxis: {
          type: "value",
          show: false,
          axisLine: {
            lineStyle: {
              color: "#768aa3",
              // opacity: 1
              width: 0,
            },
          },
          splitLine: {
            lineStyle: {
              color: "#555",
              type: "solid",
              opacity: 0,
            },
          },
        },
        series: [
          {
            data: [],
            name: null,
            zlevel: 3,
            markLine: {
              symbol: ["none", "none"],
              label: { show: false },
              lineStyle: {
                color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgba(255, 255, 255,0)",
                  },
                  {
                    offset: 1,
                    color: "rgba(255, 255, 255,0.1)",
                  },
                ]),
              },
              data: [],
            },
            smooth: true,
            type: "line",
            symbol: "circle",
            symbolSize: 0,
            lineStyle: {
              color: "rgb(114, 253, 133)",
              width: 1,
            },
            areaStyle: {
              opacity: 0.8,
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgb(108,255,128)",
                },
                {
                  offset: 1,
                  color: "rgba(151,255,165,0.37)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
          },
          {
            data: [],
            name: null,
            zlevel: 2,
            markLine: {
              symbol: ["none", "none"],
              label: { show: false },
              lineStyle: {
                color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgba(255, 255, 255,0)",
                  },
                  {
                    offset: 1,
                    color: "rgba(255, 255, 255,0.1)",
                  },
                ]),
              },
              // data: [
              //   { xAxis: 1 },
              //   { xAxis: 2 },
              //   { xAxis: 3 },
              //   { xAxis: 4 },
              //   { xAxis: 5 },
              //   { xAxis: 6 },
              //   { xAxis: 7 },
              // ],
              data: (function () {
                return Array(30)
                  .fill(null)
                  .map((_, i) => ({ xAxis: i + 1 }));
              })(),
            },
            smooth: true,
            type: "line",
            symbol: "circle",
            symbolSize: 0,
            lineStyle: {
              color: "rgb(238, 85, 118)",
              width: 1,
            },
            areaStyle: {
              opacity: 0.8,
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgb(238,85,85)",
                },
                {
                  offset: 1,
                  color: "rgba(243,135,135,0.51)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
          },
          {
            data: [],
            name: null,
            zlevel: 1,
            markLine: {
              symbol: ["none", "none"],
              label: { show: false },
              lineStyle: {
                color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgba(255, 255, 255,0)",
                  },
                  {
                    offset: 1,
                    color: "rgba(255, 255, 255,0.1)",
                  },
                ]),
              },
              // data: [
              //   { xAxis: 1 },
              //   { xAxis: 2 },
              //   { xAxis: 3 },
              //   { xAxis: 4 },
              //   { xAxis: 5 },
              //   { xAxis: 6 },
              //   { xAxis: 7 },
              // ],
              data: (function () {
                return Array(30)
                  .fill(null)
                  .map((_, i) => ({ xAxis: i + 1 }));
              })(),
            },
            smooth: true,
            type: "line",
            symbol: "circle",
            symbolSize: 0,
            lineStyle: {
              color: "rgb(65,90,250)",
              width: 1,
            },
            areaStyle: {
              opacity: 0.8,
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgb(112,148,255)",
                },
                {
                  offset: 1,
                  color: "rgba(65,117,250,0.41)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
          },
        ],
      },
      nextEvents: [],
      eventTag: {
        NORMAL: "normal",
        REDBLUE: "redblue",
      },
      eventStatus: {
        ongoing: "green",
        scheduled: "blue",
        overdue: "red",
      },
      lastSubmitted: [],
      labSuggestion: null,
    };
  },
  beforeCreate() {
    this.$parent.breadcrumbs = [{ name: "home" }];
  },
  created() {
    // console.log(this.$route.query.login !== undefined)
    // this.loadUpcomingLab();
    // this.loadFirstBlood();
    // this.loadUpcomingLabStats();
    // this.loadUpcomingEvent();

    this.$parent.loading = true;
    new Promise(res => this.loadAll(res))
      .finally(() => this.$parent.loading = false);
  },
  methods: {
    async loadAll(res) {
      // const start = new Date()

      return await Promise.allSettled([
        this.getSurvey(),
        this.getPromotions(),
        this.loadStats(),
      ])
        .finally(() => {
          // const end = new Date();
          // const duration = end - start;
          res();
        })
    },
    modalConfirmAction() {
      this.surveyTailModal = false;
      // this.$router.push("/courses/fundamentals");
      this.$router.push("/home");
    },
    modalCloseAction() {
      this.surveyTailModal = false;
      this.$router.push("/labs");
    },
    closePromotionOverlay() {
      const isChecked = this.promotion.checked;
      this.promotion = null;
      if (!isChecked) return;
      statsService.deleteUserPromotion().then((response) => {
        const result = response.data.result;
        if (!result)
          return this.flashError("There was an error processing your request");
        return this.flashSuccess(
          "We will not notify you again for this promotion"
        );
      });
    },
    async getPromotions() {
      const hasLoggedIn = this.$route.query.login !== undefined;
      const clientWidth = window.screen.width;
      if (clientWidth < 769) return;
      if (!hasLoggedIn) return;

      await statsService.getPromotions().then((response) => {
        const promotions = response.data.promotions;
        this.promotions = promotions
      });
    },
    async getSurvey() {
      const hasLoggedIn = this.$route.query.login !== undefined;
      if (!hasLoggedIn) return;
      if (!this.isUser) return;
      // if (this.promotion !== null) return; // Do after promotion
      await userService.getSurvey()
        .then((response) => {
          this.survey = response.data.questions;
          if (this.survey.length > 0) this.openSurveyModal();
        })
    },
    openSurveyModal() {
      this.$modal.show(
        SurveyModal,
        {
          survey: this.survey,
          surveySaved: () => {
            this.surveyTailModal = true;
            this.$router.push("/courses/web-security");
          }
        },
        { height: 'auto', width: '40%' }
      )
    },
    loadUpcomingLabStats() {
      this.$parent.loading = true;
      labService
        .upcomingLabStats()
        .then(() => {
          // this.dailySubs["series"][0]["data"] = response.data;
        })
        .finally(() => (this.$parent.loading = false));
    },
    loadFirstBlood() {
      this.$parent.loading = true;
      labService
        .firstBlood()
        .then((response) => {
          this.firstBlood = response.data;
        })
        .finally(() => (this.$parent.loading = false));
    },
    loadUpcomingEvent() {
      this.$parent.loading = true;
      eventService
        .upcomingEvent()
        .then((response) => {
          if (response.data.id !== undefined) {
            this.upcomingEvent = response.data;
          }
        })
        .finally(() => (this.$parent.loading = false));
    },
    loadUpcomingLab() {
      this.$parent.loading = false;
      labService
        .upcomingLab()
        .then((response) => {
          this.upcomingLab = response.data;
        })
        .finally(() => (this.$parent.loading = false));
    },
    async loadStats() {
      this.$parent.loading = true;
      const requests = [
        statsService.labs(),
        statsService.users(),
        statsService.events(),
        statsService.teams(),
        statsService.lastSubscriptions(),
        statsService.lastFlags(),
        statsService.topPlayers(),
        statsService.nextEvents(),
      ];
      await httpService
        .all(requests)
        .then(
          ([
            labs,
            users,
            events,
            teams,
            lastSubscriptions,
            lastFlags,
            topPlayers,
            nextEvents,
          ]) => {
            this.labs = labs.data.labs;
            this.users = users.data.users;
            this.events = events.data.events;
            this.teams = teams.data.teams;
            this.dailySubs.xAxis.data = lastSubscriptions.data.data.xAxis;
            this.dailySubs.series[0].data = lastSubscriptions.data.data.data;
            this.dailyFlags.xAxis.data = lastFlags.data.data.xAxis;
            this.dailyFlags.series[0].data = lastFlags.data.data.systems;
            this.dailyFlags.series[1].data = lastFlags.data.data.users;
            this.topPlayers = topPlayers.data.topPlayers;
            this.lastSubmitted = topPlayers.data.lastSubmitted;
            this.nextEvents = nextEvents.data.nextEvents;

            this.graphDays = topPlayers.data.days;
            const today = new Date();
            today.setDate(today.getDate() - this.graphDays);

            for (let i = 0; i <= this.graphDays - 1; i++) {
              today.setDate(today.getDate() + 1);
              this.top3Users.xAxis.data.push(`${today.toDateString()}`);
            }

            this.nextEvents = this.nextEvents.map((e) => {
              const [dStart, tStart] = this.transformUTCTime(e.dateStart);
              const [dEnd, tEnd] = this.transformUTCTime(e.dateEnd);
              return { ...e, dStart, tStart, dEnd, tEnd };
            });
          }
        )
        .then(() => {
          let top3Players = this.lastSubmitted.slice(0, 3);
          top3Players.forEach((player, i) => {
            this.top3Users.legend.data.push(player.name);
            this.top3Users.series[i].name = player.name;

            statsService.top3Players(player.id, this.graphDays).then((top3stats) => {
              this.top3Users.series[i].data = top3stats.data.data;
            });
          });
        })
        .catch((error) => {
          const manageableError =
            !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
      // .finally(() => (this.$parent.loading = false));
    },
    navigateTo(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/colors.scss";

.avatar {
  height: 50px;
  width: 50px;
}

.table__row {
  font-size: 0.9rem;

  h2 {
    font-size: 0.85rem;
  }

  &:nth-child(odd) {
    background: rgba($neutral--medium, $alpha: 0.5) !important;
  }

  &:nth-child(even) {
    background: rgba($neutral--dark, $alpha: 0.5) !important;
  }
}

.banner__graph--container {
  position: relative;
}

.banner__card {
  background: none !important;
}

.banner__graph {
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 100%;

  canvas {
    height: 90%;
    width: 100%;
  }
}

.promotion-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

#image-container img {
  display: block;
  float: left;
  -webkit-box-shadow: inset 5px 1px 100px 15px #000000,
    -1px -1px 42px -1px rgba(2, 156, 22, 0.5);
  -moz-box-shadow: inset 5px 1px 100px 15px #000000,
    -1px -1px 42px -1px rgba(2, 156, 22, 0.5);
  -o-box-shadow: inset 5px 1px 100px 15px #000000,
    -1px -1px 42px -1px rgba(2, 156, 22, 0.5);
  box-shadow: inset 5px 1px 100px 15px #000000,
    -1px -1px 42px -1px rgba(2, 156, 22, 0.5);
}

.confirm-overlay-wrapper {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
}

.confirm-overlay-background {
  top: 0;
  left: 0;
  position: absolute;
  background-color: black;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 99;
}

.confirm-overlay {
  position: absolute;
  width: 100%;
  transform: translate(35%, 50%);
  height: 100%;
  z-index: 100;
}

.confirmation-modal {
  width: 600px;
}
</style>
