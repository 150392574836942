<template>
  <div class="">
    <template v-if="selector !== 'default'">
      <div class="row px-3">
        <div class="col-md-6 d-flex justify-content-start">
          <h2 class="my-3">PWNX NLP Log attack detection</h2>
        </div>
        <div class="col-md-5 row w-100">
          <div class="col-7 d-flex flex-row justify-content-end align-items-center">
            <select v-model="selector" class="text-right form-control w-100" name="user" id="user-select">
              <option v-for="(user, index) of filteredUsers" :value="user.username" :key="index">
                {{ user.username }}
              </option>
            </select>
          </div>
          <div class="col-5">
            <select v-model="historySelector" name="history" @change="selectHistory"
              class="text-right form-control w-100">
              <option v-for="(date, index) of dates" :key="index" :value="date">
                {{ new Date(date).toLocaleDateString("en", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                }) }}</option>
            </select>
          </div>
        </div>
        <div class="col-1 text-right d-flex justify-content-end m-0 p-0 align-items-end">
          <px-button @click.native="goBack()" class="back-btn px-button--secondary icon-left outline" icon="left-arrow">
            back
          </px-button>
        </div>
      </div>
      <div class="row">
        <div class="col-12" v-if="!userInEvent">
          <px-button v-if="reportReady && !reportGenerationInterval" @click.native="getUserReportToken(userId)"
            class="ml-3 px-button--orange outline">download report</px-button>
          <px-button v-if="reportGenerationInterval" disable
            class="ml-3 generating-btn px-button--orange outline">generating</px-button>
          <px-button v-if="!reportReady && !reportGenerationInterval" @click.native="generateUserReport(userId)"
            class="ml-3 px-button--orange outline">generate report</px-button>
        </div>
      </div>
      <div class="row px-3" v-if="labs.length > 1">
        <div class="col-12 d-flex justify-content-center align-items-center flex-row">
          <i class="mr-5 arrow left" @click="changeLab('decrement')"></i>
          <transition tag="template" class="data-slider" name="slide">
            <div :key="selectedLabId">
              <h2 class="mx-5 lab-name">
                {{ getLabName(selectedLabId) }}
              </h2>
            </div>
          </transition>
          <i class="ml-5 arrow right" @click="changeLab('increment')"></i>
        </div>
      </div>
      <transition tag="template" class="data-slider" name="slide">
        <template>
          <div class="w-100 row" :key="selectedLabId">
            <div v-for="(graphData, index) of radarGraphDatas" :key="index" class="col-md-6 mt-5">
              <div v-if="userInEvent" id="loading-spinner"></div>
              <div v-if="graphData.isEmpty()"
                class="position-relative h-100 w-100 py-2 px-2 graphs shadow-lg bg-dark rounded">
                <div class="no-data bg-darker">
                  No data processed yet
                </div>
              </div>
              <div class="m-0 p-0 graph-label w-100 graphs shadow-lg bg-dark rounded position-relative"
                v-if="graphData.id == 'grid-chart' && !graphData.isEmpty()">
                <div class="position-absolute m-0 p-0 w-100 label-top">
                  <div class="row m-0 p-0 w-100">
                    <div class="col-6 m-0 p-1 px-border--purple">EXPERT</div>
                    <div class="col-6 m-0 p-1 px-border--green">PROFESSIONAL</div>
                  </div>
                </div>
                <div class="position-absolute p-0 m-0 w-100 label-bottom">
                  <div class="row m-0 p-0 w-100">
                    <div class="col-6 m-0 p-1 px-border--orange">WANNABE</div>
                    <div class="col-6 m-0 p-1 px-border--red">SCRIPT KIDDIE</div>
                  </div>
                </div>
                <v-chart v-if="!graphData.isEmpty()" id="charts" autoresize class="w-100 m-0 p-0" :ref="graphData.id"
                  :options="graphData"></v-chart>
              </div>
              <v-chart v-if="!graphData.isEmpty() && graphData.id != 'grid-chart'" id="charts" autoresize
                class="w-100 py-2 px-2 graphs shadow-lg bg-dark rounded" :ref="graphData.id"
                :options="graphData"></v-chart>
            </div>
          </div>
        </template>
      </transition>
    </template>
    <template v-else>
      <div class="d-flex flex-column justify-content-center">
        <h2>select event user</h2>
        <select name="user" v-model="selector" class="form-control">
          <option :value="user.username" v-for="(user, index) of users" :key="index">{{ user.username }}</option>
        </select>
      </div>
    </template>
  </div>
</template>
<script>
import ECharts from "vue-echarts";
import PxButton from "../components/PxButton";
import recruiterService from "../api/recruiter";
import eventService from "../api/event";
import httpService from "../api/http";
import { mapState } from "vuex"

export default {
  name: "EventStatistics",
  components: {
    "v-chart": ECharts,
    PxButton,
  },
  data() {
    return {
      reportReady: false,
      reportGenerationInterval: null,
      userInEvent: false,
      dataInterval: null,
      labsCount: 0,
      labs: [],
      selectedLabId: null,
      labIndex: 0,
      tags: [],
      maxDuration: 0,
      graphBorders: 0,
      users: [],
      selector: "default",
      historySelector: new Date().toISOString(),
      eventId: this.$route.params.eventId,
      // tmpInterval: null,
      mlOutput: [],
      dates: [],
      radarGraphDatas: [
        {
          id: "pie-chart",
          reset: function () {
            this.series[0].data = [];
          },

          isEmpty: function () {
            const [series] = this.series
            const isEmpty = series && series.data.length === 0
            return isEmpty
          },
          prepare: function (data, { palette = null, vctx = null }) {
            const series = this.series;
            const [serieElement] = series;
            const legend = this.legend;


            if (palette) this.color = palette;
            const entries = data.map((item) => Object.entries(item))
            const reduced = entries.reduce((acc, x) => ([...acc, ...x]), [])

            for (const entry of reduced) {
              const [k, v] = entry
              if (vctx.filterInvalidKeys(k)) continue;
              const element = serieElement.data.filter((ser) => {
                return ser.name == k
              })

              if (element.length === 0) {
                const toPush = {
                  name: k,
                  value: v,
                };

                serieElement.data.push(toPush);
                continue;
              }

              const [mlData] = element;
              mlData.value += v;
            }

            const limit = serieElement.data.reduce((acc, x) => {
              return (acc || 0) + (x.value || 0)
            }, 0)

            legend.formatter = function (name) {
              const [object] = serieElement.data.filter(item => item.name === name)
              const percentual = Number.parseFloat((object.value / limit * 100).toFixed(2));
              return [name, String(percentual) + "%"].join(" - ")
            }
          },
          legend: {
            textStyle: {
              color: "#fff",
            },
            orient: "vertical",
            x: "left"
          },
          toolbox: {
            show: false,
          },
          series: [
            {
              name: "pwnx attack detection",
              type: "pie",
              radius: [50, 150],
              center: ["50%", "50%"],
              label: {
                normal: {
                  show: true,
                  // show: false,
                  // position: 'center',
                  formatter: function (object) {
                    return [object.name, String(object.percent) + "%"].join(" - ")
                  }
                },
              },
              roseType: "area",
              itemStyle: {
                borderRadius: 10,
              },
              data: [],
            },
          ],
        },
        {
          id: "bar-chart",
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          reset: function () {
            this.xAxis[0].data = [];
            this.series = [];
            this.legend.data = [];

          },
          isEmpty: function () {
            const series = this.series
            const isEmpty = series.length == 0
            return isEmpty
          },
          prepare(data, { palette = null, vctx = null }) {
            if (palette) this.color = palette;

            const series = this.series;
            const [xAxis] = this.xAxis;
            const legend = this.legend.data

            const filterKeys = (keys) => {
              const exclude = ["created", "CLEAN", "detection", "flags"];
              const newKeys = []

              for (const key of keys) {
                if (exclude.some(item => item === key)) continue;
                newKeys.push(key)
              }

              return newKeys
            }

            let sliced = null;
            if (data.length > 5) {
              sliced = data.slice(data.length - 6, data.length - 1)
            } else {
              sliced = data
            }

            let index = 0;
            const dateMap = {}
            for (const entry of sliced) {
              if (!entry.created) continue;
              const date = vctx.transformUTCTime(entry.created + "Z").join(" ")
              xAxis.data.push(date)
              dateMap[date] = index;
              index += 1;
            }

            const dateLength = xAxis.data.length;

            let keys = []
            for (const entry of sliced) {
              keys.push(...filterKeys(Object.keys(entry)))
            }

            keys = Array.from(new Set(keys))

            for (const key of keys) {
              const element = {
                name: key,
                data: Array(dateLength).fill(0),
                type: "bar",
                emphasis: { focus: "series" }
              }
              series.push(element)
              legend.push(key)
            }

            for (const entry of sliced) {
              const created = vctx.transformUTCTime(entry.created + "Z").join(" ")
              const elIndex = dateMap[created];

              for (const [key, value] of Object.entries(entry)) {
                if (vctx.filterInvalidKeys(key)) continue;
                const [element] = series.filter((item) => item.name === key)
                element.data[elIndex] += value

              }
            }

            this.xAxis[0] = xAxis;
            this.series = series;
            this.legend.data = legend;
          },
          legend: {
            textStyle: {
              color: "#fff",
            },
            data: [],
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              data: [],
              axisLabel: {
                textStyle: { color: "#fff" },
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisLabel: {
                textStyle: { color: "#fff" },
              },
            },
          ],
          series: [],
        },
        {
          reset: function () {
            this.series[0].data = [];
          },
          id: "gauge-chart",
          isEmpty: function () {
            const [series] = this.series
            const isEmpty = series && series.data.length === 0
            return isEmpty
          },
          prepare: function (data, { palette = null, vctx = null }) {
            // TODO: REFACTOR
            const [series] = this.series

            let colorLength = 0;
            // let notIncluded = {}
            if (palette) colorLength = palette.length - 1;
            let entries = data.map((item) => {
              const object = {}
              for (const [key, value] of Object.entries(item)) {
                if (vctx.filterInvalidKeys(key)) {
                  continue
                }

                const exists = !!object[key];
                if (!exists) {
                  object[key] = value;
                  continue
                }
                object[key] += value
              }
              return object
            })

            const attackDetections = entries.filter((item) => JSON.stringify(item) !== "{}")
            if (!attackDetections.length === 0) return;

            const attackDetection = {}

            for (const entry of attackDetections) {
              for (const [key, value] of Object.entries(entry)) {
                const exists = !!attackDetection[key];
                if (!exists) {
                  attackDetection[key] = value
                  continue
                }
                attackDetection[key] += value;
              }
            }

            const total = Object.values(attackDetection).reduce((acc, x) => {
              return (acc || 0) + (x || 0)
            })
            const included = Object.entries(attackDetection).filter(([key]) => {
              return vctx.includeKeys(key, vctx.tags)
            })

            let colorIndex = Math.floor(Math.random() * colorLength)
            let prevColor = palette != null ? palette[colorIndex] : "none"

            series.data.push({
              value: 0,
              name: "Adherence",
              itemStyle: {
                color: prevColor
              }
            })

            let newColor = prevColor

            while (prevColor === newColor) {
              colorIndex = Math.floor(Math.random() * colorLength)
              newColor = palette != null ? palette[colorIndex] : "none"
            }

            series.data.push({
              value: 0,
              name: "No Adherence",
              itemStyle: {
                color: newColor
              }
            })
            for (const percentual of Object.entries(attackDetection)) {
              const [key, value] = percentual

              if (vctx.includeKeys(key, included.map(item => item[0]))) {
                const [adherence] = series.data.filter((item) => item.name == "Adherence")
                adherence.value += value
                continue
              }

              const [noadherence] = series.data.filter((item) => item.name == "No Adherence")
              noadherence.value += value
            }

            series.data.push({
              // make an record to fill the bottom 50%
              value: total,
              itemStyle: {
                // stop the chart from rendering this piece
                color: "none",
                decal: {
                  symbol: "none",
                },
              },
              label: {
                show: false,
              },
            });
          },
          tooltip: {
            trigger: "item",
            show: false,
          },
          legend: {
            textStyle: {
              color: "#fff",
            },
            top: "5%",
            left: "top",
            // doesn't perfectly work with our tricks, disable it
            selectedMode: false,
          },
          series: [
            {
              name: "Access From",
              type: "pie",
              radius: ["40%", "70%"],
              center: ["50%", "70%"],
              // adjust the start angle
              startAngle: 180,
              label: {
                show: true,
                formatter(param) {
                  // correct the percentage
                  return param.name + " (" + param.percent * 2 + "%)";
                },
              },
              data: [],
            },
          ],
        },
        {
          reset: function () {
            this.series[0].data = [];
          },
          isEmpty: function () {
            const [series] = this.series
            const isEmpty = series && series.data.length === 0
            return isEmpty
          },
          id: "grid-chart",
          prepare: function (data, { palette = null, vctx = null }) {

            // WARNING: BAD SHITTY CODE AHEAD
            // TODO: REWRITE
            if (data.length === 0) return;
            let flags = data.map(item => item.flags)
            flags = flags.filter(item => item !== undefined)
            flags = flags.flat(Infinity)

            const attacks = []
            const notIncluded = []
            // 1. Filtering
            for (const entry of data) {
              const attack = {}
              const notInclude = {}
              for (const [key, value] of Object.entries(entry)) {
                if (vctx.filterInvalidKeys(key)) continue;
                const exists = !!attack[key]
                if (!exists) attack[key] = value
                else attack[key] += value
                if (!vctx.includeKeys(key, vctx.tags)) {
                  const notIncludeExists = !!notInclude[key]
                  if (!notIncludeExists) notInclude[key] = attack[key]
                  else notInclude[key] += attack[key]
                }
              }
              if (JSON.stringify(attack) !== "{}") attacks.push(attack)
              if (JSON.stringify(notInclude) !== "{}") notIncluded.push(notInclude)
            }

            // 2. Total included
            const total = attacks.reduce((acc, x) => {
              const accValues = acc || 0
              const xValues = Object.values(x) || [0, 0]
              // const accTotal = accValues.reduce((acc, x) => (acc || 0) + (x || 0), 0)
              const accTotal = accValues || 0
              const xTotal = xValues.reduce((acc, x) => (acc || 0) + (x || 0), 0)
              return accTotal + xTotal
            }, 0)


            // 3. Total not included
            const totalNotIncluded = notIncluded.reduce((acc, x) => {
              const accValues = acc || 0
              const xValues = Object.values(x).reduce((ac, y) => ac + y, 0) || 0
              // const accTotal = accValues.reduce((acc, x) => (acc || 0) + (x || 0), 0)
              const accTotal = accValues
              const xTotal = xValues
              return accTotal + xTotal
            }, 0)


            if (palette)
              this.series[0].color =
                palette[Math.floor(Math.random() * palette.length - 1)];

            let xAxis = 0;
            let yAxis = 0;

            // 4. Counting points and minutes
            const flagObject = flags.reduce((acc, x) => {
              const xMinutes = x.minutes || 0
              const accMinutes = acc.minutes || 0
              const minutes = xMinutes + accMinutes
              const points = (acc.points || 0) + (x.points || 0)

              return {
                points: points,
                minutes: minutes,
              }
            }, {})

            const totalPoints = flagObject.points || 0
            // const limit = Math.floor(result.AUTO + result.MANUAL);
            const limit = total
            const maxDuration = vctx.maxDuration;
            const minutes = flagObject.minutes;
            const noadherence = Number.parseFloat(totalNotIncluded)
            // const manual = Number.parseInt(result.MANUAL)
            const noadherenceperc = Number.parseFloat((noadherence / limit * 100).toFixed(2)) || 0;
            // const manualperc = Number.parseFloat((manual / limit * 100).toFixed(2));
            // let perc = Math.abs(maxDuration - timePoints - maxDuration) / manualperc *  100;
            const timing = maxDuration - minutes;
            let timePerc = Number.parseFloat((timing / maxDuration * 100).toFixed(2));
            let timeCoeff = Number.parseFloat((1.0 - (1.0 / 100 * timePerc)).toFixed(2))
            // MANUAL = xAxis
            // AUTO = yAxis
            //
            // MANUAL / AUTO * 100
            // const manualCoeff = Number.parseFloat((manualperc / 100 * 1.0).toFixed(1))
            const noadherenceCoeff = Number.parseFloat((noadherenceperc / 100).toFixed(1))
            xAxis = totalPoints - (2 * (totalPoints * timeCoeff));
            yAxis = totalPoints - (2 * (totalPoints * noadherenceCoeff));

            // AUTO 82, MANUAL 18
            // 100
            // Y = totalPoints * timeCoeff
            // X = totalPoints * autoCoeff
            // console.log({
            //   X: xAxis,
            //   Y: yAxis,
            //   TIMECOEFF: timeCoeff,
            //   // MANUALCOEFF: manualCoeff,
            //   NOADHERENCECOEFF: noadherenceCoeff,
            //   totalPoints: totalPoints,
            //   // timePoints: timePoints,
            //   maxDuration: maxDuration,
            //   minutes: minutes,
            //   timing: timing,
            //   NOADHERENCE: noadherence,
            //   // MANUAL: manual,
            //   NOADHERENCEPERC: noadherenceperc,
            //   // MANUALPERC: manualperc,
            //   // PERC: perc,
            //   TOTALNOTINCLUDE: totalNotIncluded,
            //   LIMIT: limit
            // })

            const borders = vctx.graphBorders

            if (yAxis > borders) {
              yAxis = borders - (borders / yAxis) * borders;
            }

            if (xAxis > borders) {
              xAxis = borders - (borders / xAxis) * borders;
            }

            if (xAxis < -borders) {
              const positive = Math.abs(xAxis);
              const finPoints = positive % borders;
              xAxis = -finPoints;
            }

            if (yAxis < -borders) {
              const positive = Math.abs(yAxis);
              const finPoints = positive % borders;
              yAxis = -finPoints;
            }

            this.series[0].data = [
              {
                name: "filler",
                value: [borders, borders],
                itemStyle: { color: "transparent" },
              },
              {
                name: "filler",
                value: [borders, -borders],
                itemStyle: { color: "transparent" },
              },
              {
                name: "filler",
                value: [-borders, borders],
                itemStyle: { color: "transparent" },
              },
              {
                name: "filler",
                value: [-borders, -borders],
                itemStyle: { color: "transparent" },
              },
            ];
            this.series[0].data.unshift({ name: "result", value: [xAxis || 0, yAxis || 0] })
          },
          label: {
            data: ["abc", "def"],
          },
          xAxis: {
            axisLabel: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#fff"
              }
            },
            splitLine: {
              color: "#313131",
              lineStyle: {
                color: "#313131"
              }
            },
            grid: true,
          },
          yAxis: {
            axisLabel: {
              show: false
            },
            splitLine: {
              color: "#313131",
              lineStyle: {
                color: "#313131"
              }
            },
            axisLine: {
              lineStyle: {
                color: "#fff"
              }
            },
            grid: true,
          },
          series: [
            {
              symbolSize: 15,
              data: [],
              grid: true,
              type: "scatter",
            },
          ],
        },
      ],
      autoDetection: 0,
      manualDetection: 0,
      linesGraphData: {},
      accelerometerGraphData: {},
      userId: this.$route.query.userId,
    };
  },
  beforeDestroy() {
    // clearInterval(this.tmpInterval);
    clearInterval(this.dataInterval)
  },
  created() {
    // if (!this.isHrpRecruiter) {
    //   this.$router.push("/")
    //   this.flashError("You're not allowed");
    //   return
    // }
    this.loadAllData();
    // this.loadUsers();
    this.dataInterval = setInterval(() => this.loadAllData(), 60000) // 1 Minute
  },
  computed: {
    isHrpRecruiter() {
      if (!this.user) return false;
      return this.user.isHrpRecruiter
    },
    filteredUsers() {
      if (!this.users) return []
      return this.users.filter(user => user.username !== "default")
    },
    shouldShowLoadingSpinner() {
      if (this.inEvent) return true;
      return false;
    },
    automaticDetection() {
      return "";
    },
    palette() {
      return ["#80bf63", "#6ECCAF", "#ADE792", "#F3ECB0", "#e6d865"];
    },
    ...mapState({
      user: state => state.user
    })
  },
  methods: {
    changeLab(mode) {
      const length = this.labs.length;
      const labIndex = this.labIndex;
      if (mode == "increment") {
        this.selectedLabId = labIndex < length - 1 ? this.labs[labIndex + 1].id : this.labs[0].id;
        this.labIndex = labIndex < length - 1 ? this.labIndex + 1 : 0;
      } else {
        this.selectedLabId = labIndex > 0 ? this.labs[labIndex - 1].id : this.labs[length - 1].id;
        this.labIndex = labIndex > 0 ? this.labIndex - 1 : length;
      }
    },
    getLabName(labId) {
      const length = this.labs.length;
      if (length === 0) return "";
      const [lab] = this.labs.filter(lab => lab.id === labId)
      return lab.name
    },
    loadAllData() {
      this.$parent.loading = true;
      const eventId = this.eventId
      eventService.getEventLabs(eventId)
        .then((response) => {
          this.labs = response.data.labs.map(lab => ({ "name": lab.name, "id": lab.id }));
          if (!this.selectedLabId) {
            this.selectedLabId = this.labs.length == 0 ? null : this.labs[0].id;
          }
          const requests = [recruiterService.getEventUsers(eventId)]
          return httpService.all(requests)
        })
        .then(([data]) => {
          const users = data.data.users;
          this.users = users;
          this.users.push({ username: "default" });

          let userId = null
          if (this.selectedUser && this.userId !== this.selectedUser.id) {
            userId = this.selectedUser.id
          } else {
            userId = this.userId
          }
          if (userId) {
            const [selectedUser] = this.users.filter((u) => u.id === userId);
            this.selector = selectedUser.username;
            this.selectedUser = selectedUser;
            this.getUserData(userId, null, this.selectedLabId)
          }
        })
        .catch((error) => {
          const manageableError = !!error.data && !!error.data.errorDescription;
          if (manageableError) this.flashError(error.data.errorDescription);
        })
        .finally(() => {
          this.$parent.loading = false;
        })
    },
    async generateUserReport(userId) {

      const eventId = this.eventId;
      const labId = this.selectedLabId;

      const body = {
        "eventId": eventId,
        "userId": userId
      }

      if (labId) {
        body["labId"] = labId
      }

      const response = await recruiterService.generateUserReport(body)
        .then((response) => response.data)

      if (response.errorDescription) {
        const message = response.errorDescription;
        this.flashError(message);
        return;
      }

      this.reportGenerationInterval = setInterval(async () => {
        await this.getUserReportToken(userId)
      }, 10 * 1000);

      const message = response.message;
      this.flashSuccess(message);

    },
    async getUserReportToken(userId) {
      const eventId = this.eventId;
      const labId = this.selectedLabId
      const response = await recruiterService.getUserReportToken(eventId, userId, labId)
        .then(resp => resp.data)
        .catch(error => error.data);


      const message = response.errorDescription;
      if (response.errorCode == "invalid_request") {
        this.flashError(message);
        return;
      } else if (response.errorCode == "report_not_ready") {
        this.flashWarning(message);
        return;
      } else if (response.errorCode == "generation_failed") {
        this.flashError(message);
        this.clearReportGenerationInterval();
        this.reportGenerationInterval = null;
        return;
      }

      this.clearReportGenerationInterval();
      this.reportGenerationInterval = null;
      this.reportReady = true;

      const token = response.token;
      const baseUrl = process.env.VUE_APP_API_URL;
      const url = `${baseUrl}/v2/events/${eventId}/report/${token}`;
      // window.open(String(url), "_blank");
      window.location = url;

    },
    clearReportGenerationInterval() {
      try {
        clearInterval(this.reportGenerationInterval);
      } catch (e) {
        // ...
      }
    },
    filterInvalidKeys(key, include = null) {
      const exclude = ["created", "CLEAN", "detection", "flags"];
      return exclude.some((item) => item == key && item !== include);
    },
    includeKeys(key, includes) {
      if (!includes) return false
      return includes.some((item) => item.toLowerCase() === key.toLowerCase())
    },
    organizeData(data) {
      const palette = this.palette;
      for (const graph of this.radarGraphDatas) {
        graph.reset();
        graph.prepare(data, { palette: palette, vctx: this });
      }
    },
    selectHistory(event) {
      const date = event.target.value;

      for (const graph of this.radarGraphDatas) {
        graph.reset();
      }

      const userId = this.selectedUser.id
      this.getUserData(userId, new Date(date).toISOString(), this.selectedLabId)
    },
    getUserData(userId, indata = null, labId) {
      let promise = null;
      const eventId = this.eventId;
      if (!indata) promise = recruiterService.getUserEventStats(eventId, userId, null, labId);
      else promise = recruiterService.getUserEventStats(eventId, userId, indata, labId);
      promise.then((response) => {
        const data = response.data.data;

        this.userInEvent = response.data.inEvent;
        this.labsCount = response.data.labs.count;
        this.tags = response.data.labs.tags;
        this.graphBorders = response.data.labs.borders
        this.maxDuration = response.data.contract.maxDuration;
        this.reportReady = response.data.reportReady;

        if (indata === null) {
          this.mlOutput = data;
          this.dates = data.map((item) => item.created).sort();
          this.dates = this.dates.filter(item => item !== undefined)
          this.dates = this.dates.map((date) => {
            const newDate = date + "Z"
            return this.transformUTCTime(newDate).join(" ")
          })
          this.dates.push(this.historySelector)
        }
        this.organizeData(data);
      }).finally();
    }
  },
  watch: {
    selectedLabId: function (new_) {
      if (new_) {
        this.loadAllData()
      }
    },
    selector: function (new_) {
      const [selectedUser] = this.users.filter((u) => u.username === new_);
      this.selectedUser = selectedUser;
      this.getUserData(this.selectedUser.id, null, this.selectedLabId)
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/css/colors.scss";
@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@800&display=swap");

.data-analysis {
  font-family: "Exo 2", sans-serif;
}

.fireblink {
  animation: fireblink 5s linear infinite alternate;
}

@keyframes fireblink {
  50% {
    opacity: 0;
  }
}

.pwnx-colors {
  background: linear-gradient(to right, #638d9e, #e4eabb);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}


.no-data {
  min-height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  text-transform: uppercase;
}

@media screen and (min-width: 768px) {
  .back-btn {
    height: 100%;
  }
}

#user-select {
  text-transform: uppercase;
  font-weight: bolder;
}

#loading-spinner {
  z-index: 10;
  width: 28px;
  height: 28px;
  position: absolute;
  right: 0;
  margin: 15px 30px;
  border: 2px solid $neutral--darkest;
  border-bottom-color: $soft-green;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 0.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.label-bottom {
  bottom: 0;
}

.graph-label {
  margin: 100px 0px;
  text-align: center;
}

.px-border--orange {
  border: 1px solid $soft-orange;
  color: $soft-orange;
}

.px-border--green {
  border: 1px solid $soft-green;
  color: $soft-green;
}

.px-border--red {
  border: 1px solid $soft-red;
  color: $soft-red;
}

.px-border--purple {
  border: 1px solid $soft-purple;
  color: $soft-purple;
}

.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}

.slide-enter {
  transform: translate(100%, 0);
  opacity: 1;
}

.slide-leave-to {
  transform: translate(-100%, 0);
  opacity: 0;
}

.fade-leave-active,
.fade-enter-active {
  transition: 1s;
}

.fade-enter-to {
  opacity: 0;
}

.fade-enter {
  opacity: 1;
}

.fade-leave-to {
  opacity: 0;
}

.arrow {
  border: solid $soft-green;
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.lab-name {
  color: $soft-green;
}

.data-slider {
  overflow: hidden;
  position: relative;
}

.data-slider div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.generating-btn {
  animation: blink 1s linear infinite alternate;
  transition: all 2s linear;
}

@keyframes blink {
  100% {
    opacity: 1;
  }
}

// #638d9e
// #e4eabb</style>
