<template>
  <base-modal
      :title="modal.title"
      :buttons="modal.buttons"
      :close="modal.close">
    <form class="" autocomplete="off" @submit.prevent>
      <div class="form-group">
        <label class="" for="activation-email">Please insert your activation email:</label>
        <input id="activation-email" class="form-control" type="text" autocomplete="off"
               placeholder="Your email" v-model="email">
      </div>
    </form>
  </base-modal>
</template>

<script>
import BaseModal from "./BaseModal";
import userService from "../api/user";

export default {
  name: "ActivationEmail",
  components: {BaseModal},
  data() {
    return {
      email: null,
      modal: {
        title: "Send a new activation email",
        buttons: [
          {
            name: "Send",
            class: "btn-primary",
            action: () => this.resendActivationEmail()
          },
        ],
        close: () => this.$emit("close")
      },
    }
  },
  methods: {
    resendActivationEmail() {
      if (!this.email) {
        this.flashWarning("Please insert an email");
      }
      this.$parent.loading = true;
      const data = {
        email: this.email,
      };
      userService.resendActivationEmail(data)
          .then(response => {
            const successMessage = !!response && !!response.data && !!response.data.message;
            if (successMessage) {
              this.flashSuccess(response.data.message);
            }
          })
          .catch(error => {
            const manageableError = !!error && !!error.data && !!error.data.errorDescription;
            if (manageableError) {
              this.flashError(error.data.errorDescription);
            }
          })
          .finally(() => {
            this.$parent.loading = false;
            this.modal.close();
          });
    }
  }
}
</script>

<style scoped>

</style>
