<template>
  <base-modal
    :title="modal.title"
    :buttons="modal.buttons"
    :close="modal.close"
  >
    <h2>Do you want to request an invoice?</h2>
    <form v-if="userWantsToSave" class="form-group" @submit="handleFormSubmit">
      <div class="row">
        <!-- invoice-name -->
        <div class="col-6 my-2">
          <label for="invoice-name" class="card__label">Name <span class="text-danger">*</span></label>
          <input
            placeholder="Name"
            type="text"
            id="invoice-name"
            v-model="invoice.name"
            class="form-control"
          />
        </div>
        <!-- invoice-surname -->
        <div class="col-6 my-2">
          <label for="invoice-surname" class="card__label">Surname <span class="text-danger">*</span></label>
          <input
            placeholder="Surname"
            type="text"
            id="invoice-surname"
            v-model="invoice.surname"
            class="form-control"
          />
        </div>
      </div>
      <div class="row">
        <!-- invoice-company-name -->
        <div class="col-6 my-2">
          <label for="invoice-company-name" class="card__label"
            >Company Name</label
          >
          <input
            placeholder="Company Name"
            type="text"
            id="invoice-company-name"
            v-model="invoice.companyName"
            class="form-control"
          />
        </div>
        <!-- invoice-vat -->
        <div class="col-6 my-2">
          <label for="invoice-vat" class="card__label">VAT number <span class="text-danger">*</span></label>
          <input
            placeholder="VAT number"
            :class="{'border border-danger': isAlphaNum(invoice.vatNumber)}"
            type="text"
            id="invoice-vat"
            v-model="invoice.vatNumber"
            class="form-control"
          />
        </div>
      </div>
      <div class="row">
        <!-- invoice-email -->
        <div class="col-6 my-2">
          <label for="invoice-email" class="card__label">Email <span class="text-danger">*</span></label>
          <input
            placeholder="Email"
            type="text"
            id="invoice-email"
            v-model="invoice.email"
            class="form-control"
            :class="{'border border-danger': !isEmailValid(invoice.email)}"
          />
        </div>
        <!-- invoice-address -->
        <div class="col-6 my-2">
          <label for="invoice-address" class="card__label">Address <span class="text-danger">*</span></label>
          <input
            placeholder="Address"
            type="text"
            id="invoice-address"
            v-model="invoice.address"
            class="form-control"
          />
        </div>
      </div>
      <div class="row">
        <!-- invoice-pec -->
        <div class="col-6 my-2">
          <label for="invoice-pec" class="card__label">Email PEC</label>
          <input
            :placeholder="[!invoice.isEe ? 'Email PEC':'(Disabled)']"
            :disabled="invoice.isEe"
            type="text"
            id="invoice-pec"
            v-model="invoice.pec"
            class="form-control"
            :class="{'border border-danger': !isEmailValid(invoice.pec)}"
          />
        </div>
        <!-- invoice-sdi -->
        <div class="col-6 my-2">
          <label for="invoice-sdi" class="card__label">SDI</label>
          <input
            :placeholder="[!invoice.isEe ? 'SDI':'(Disabled)']"
            :disabled="invoice.isEe"
            :class="{'border border-danger':isAlphaNum(invoice.sdi)}"
            type="text"
            id="invoice-sdi"
            v-model="invoice.sdi"
            class="form-control"
            maxlength="7"
          />
        </div>
      </div>
      <div class="row">
        <!-- invoice-country -->
        <div class="col-6 my-2">
          <label for="invoice-country" class="card__label">Country</label>
          <select
            name="country"
            v-model="invoice.country"
            id="invoice-country"
            class="form-control"
          >
            <option
              v-for="(country, index) in countries"
              :key="index"
              :value="country.toLowerCase()"
            >
              {{ country }}
            </option>
          </select>
        </div>
        <!-- invoice-city -->
        <div class="col-6 my-2">
          <label for="invoice-city" class="card__label">City <span class="text-danger">*</span></label>
          <input
            placeholder="City"
            type="text"
            id="invoice-city"
            v-model="invoice.city"
            class="form-control"
          />
        </div>
      </div>
      <div class="row">
        <!-- invoice-phone-number -->
        <div class="col-6 my-2">
          <label for="invoice-phone-number" class="card__label"
            >Phone Number <span class="text-danger">*</span></label
          >
          <input
            placeholder="Phone Number"
            :class="{'border border-danger':isAlphaNum(invoice.phoneNumber)}"
            type="text"
            id="invoice-phone-number"
            v-model="invoice.phoneNumber"
            class="form-control"
          />
        </div>
      </div>
    </form>
  </base-modal>
</template>

<script>
import BaseModal from "./BaseModal";
import statsService from "../api/stats";

// 1. Receives OrderType and OrderID as input
// 2. Uses OrderType and OrderID in the invoice save request

export default {
  name: "InvoiceRequestModal",
  data() {
    return {
      modal: {
        title: "Invoice Request",
        buttons: [
          {
            name: `${this.userWantsToSave ? 'Save':'Make'} invoice request`,
            class: "px-button--success",
            action: () => this.handleFormSubmit(),
          },
          {
            name: "Close",
            class: "px-button--danger",
            action: () => this.selfClose(),
          },
        ],
        close: () => this.selfClose(),
      },
      countries: [],
      invoice: {
        name: null,
        surname: null,
        companyName: null,
        vatNumber: null,
        email: null,
        pec: null,
        sdi: null,
        isEe: false,
        country: "it",
        city: null,
        address: null,
        phoneNumber: null,
      },
      userWantsToSave: false
    };
  },
  props: {
    redirectUser: {
      type: Function,
      required: false,
      default: function() {this.$router.push("/")}
    },
    orderType: {
        type: String,
        // required: true
        default: 'premium'
    },
    orderId: {
        type: String,
        // required: true
        default: 'idonotexist'
    }
  },
  created() {
    statsService
      .getCountries()
      .then((countries) => (this.countries = countries.data));
  },
  components: {
    BaseModal,
  },
  methods: {
    isEmailValid(email) {
      if (!email) return true;
      const pattern = /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@(?:[a-zA-Z0-9]+)\.(?:[a-zA-Z0-9]+)/i;
      const isValid = pattern.test(String(email).trim())
      if (!email) return true;
      return isValid;
    },
    isAlphaNum(string) {
      const splittedString = String(string).split("")
      if (splittedString.length == 0 || !string) return false;
      return splittedString.some(ch => ch.charCodeAt(0) < 48 || ch.charCodeAt(0) > 57)
    },
    selfClose() {
      this.$emit("close");
      // this.$router.push("/")
      this.redirectUser()
    },
    handleFormSubmit() {
      if (!this.userWantsToSave) {
          this.userWantsToSave = true;
          return;
      }
      this.$parent.loading = true;

      const notAlphaNum = [
        this.invoice.vatNumber,
        this.invoice.phoneNumber,
        this.invoice.sdi
      ]
      if (notAlphaNum.some(string => this.isAlphaNum(string))) {
        this.flashError("There are some invalid values, cannot save.")
        return;
      }

      const areMailsInvalid = [this.invoice.email, this.invoice.pec].some(mail => !this.isEmailValid(mail))
      if (areMailsInvalid) {
        this.flashError("Some email addresses seems to be invalid, cannot save.")
        return;
      }

      statsService
        .saveInvoiceRequest(this.invoice, this.orderType, this.orderId)
        .then((response) => {
          const manageableMessage = !!response.data && !!response.data.message;
          if (manageableMessage) this.flashSuccess(response.data.message);
          this.selfClose();
        })
        .catch((error) => {
          const manageableError = !!error.data && !!error.data.errorDescription;
          if (manageableError) this.flashError(error.data.errorDescription)
        })
        .finally(() => {
          this.$parent.loading = false;
        });
    },
  },
  watch: {
    "invoice.country": function(new_) {
      if (new_.toLowerCase() == "it") {
        this.invoice.isEe = false;
      } else {
        this.invoice.isEe = true;
        this.invoice.pec = null;
        this.invoice.sdi = null;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
