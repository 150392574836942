<template>
    <Spinner></Spinner>
</template>

<script>
    import userService from "../api/user";
    import eventService from "../api/event";
    import teamService from "../api/team";
    import Spinner from "./Spinner";

    export default {
        name: "Actions",
        components: {Spinner},
        beforeCreate() {
            const scope = this.$route.params.scope;
            const action = this.$route.params.action;
            const params = this.$route.query;
            const authenticated = this.$store.getters["user/isLoggedIn"];

            if (scope !== "user" && !authenticated) {
                this.flashMessage.info({message: "Please login"});
                const returnUrl = this.$router.currentRoute.fullPath;
                this.$router.push(`/login?returnUrl=${returnUrl}`);
                return;
            }

            if (scope === 'user') {
                if (action === 'pw-reset') {
                    const resetId = params.resetId;
                    userService.resetPassword(resetId)
                        .then(response => {
                            const successMessage = !!response && !!response.data && !!response.data.message;
                            if (successMessage) {
                                this.flashSuccess(response.data.message);
                            }
                        })
                        .catch(error => {
                            const manageableError = !!error && !!error.data && !!error.data.errorDescription;
                            if (manageableError) {
                                this.flashError(error.data.errorDescription);
                            }
                        });
                } else if (action === 'sign-on') {
                    const signOnId = params.signOnId;
                    userService.confirmSignOn(signOnId)
                        .then(response => {
                            const successMessage = !!response && !!response.data && !!response.data.message;
                            if (successMessage) {
                                this.flashSuccess(response.data.message);
                            }
                        })
                        .catch(error => {
                            const manageableError = !!error && !!error.data && !!error.data.errorDescription;
                            if (manageableError) {
                                this.flashError(error.data.errorDescription);
                            }
                        });
                }
            } else if (scope === 'events') {
                if (action === 'accept') {
                    const inviteId = params.inviteId;
                    eventService.acceptInvite(inviteId)
                        .then(response => {
                            const successMessage = !!response && !!response.data && !!response.data.message;
                            if (successMessage) {
                                this.flashSuccess(response.data.message);
                            }
                        })
                        .catch(error => {
                            const manageableError = !!error && !!error.data && !!error.data.errorDescription;
                            if (manageableError) {
                                this.flashError(error.data.errorDescription);
                            }
                        });
                } else if (action === "join") {
                    const eventId = params.eventId;
                    eventService.joinEvent(eventId)
                        .then(response => {
                            const successMessage = !!response && !!response.data && !!response.data.message;
                            if (successMessage) {
                                this.flashSuccess(response.data.message);
                            }
                        })
                        .catch(error => {
                            const manageableError = !!error && !!error.data && !!error.data.errorDescription;
                            if (manageableError) {
                                this.flashError(error.data.errorDescription);
                            }
                        });
                }
            } else if (scope === 'team') {
                const teamId = params.teamId;
                const joinCode = params.joinCode;
                if (action === 'accept') {
                    teamService.confirmJoin(teamId, joinCode)
                        .then(response => {
                            const successMessage = !!response && !!response.data && !!response.data.message;
                            if (successMessage) {
                                this.flashSuccess(response.data.message);
                            }
                        })
                        .catch(error => {
                            const manageableError = !!error && !!error.data && !!error.data.errorDescription;
                            if (manageableError) {
                                this.flashError(error.data.errorDescription);
                            }
                        });
                } else if (action === 'reject') {
                    teamService.cancelJoin(teamId, joinCode)
                        .then(response => {
                            const successMessage = !!response && !!response.data && !!response.data.message;
                            if (successMessage) {
                                this.flashSuccess(response.data.message);
                            }
                        })
                        .catch(error => {
                            const manageableError = !!error && !!error.data && !!error.data.errorDescription;
                            if (manageableError) {
                                this.flashError(error.data.errorDescription);
                            }
                        });
                }
            }
            const returnUrl = this.$route.query.returnUrl;
            this.$router.push(returnUrl || "/home");
        }
    }
</script>

<style scoped>

</style>
