<template>
    <base-modal :title="modal.title" :buttons="modal.buttons" :close="modal.close" :isEventAdd="true">
        <div class="padding-top-20 form-group">
            <!--suppress HtmlFormInputWithoutLabel -->
            <input type="text" name="username" class="form-control"
                   placeholder="Search lab..." @input="searchLab" v-model="searchTerm"/>
        </div>
        <div class="padding-top-20 form-group"  :style="{'overflow-y':'scroll', 'max-height':450 + 'px'}">
            <table class="table-1">
                <thead>
                <tr class="row px-2">
                    <th class="col-3">Name</th>
                    <th class="col-2">Difficulty</th>
                    <th class="col-1">System</th>
                    <th class="col-1">User</th>
                    <th class="col-4">Tags</th>
                    <th class="col-1">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="lab in matchLabs" :key="lab.id" class="row px-2" :style="[lab.companyLab ? 'font-size:700px':'']">
                    <td class="col-3 d-flex align-items-center pl-5">
                        <i 
                            v-if="lab.companyLab" 
                            class="fa-solid fa-users-viewfinder mx-3" 
                            :style="{fontSize: 16 + 'px', cursor:'pointer'}"
                            title="Company Lab"
                        ></i>
                        {{lab.name }}
                    </td>
                    <td class="col-2 d-flex align-items-center">
                        <div class="d-flex align-items-center lab__difficulty justify-content-center" :style="{width: 100 + '%'}">
                            <span class="lab__difficulty--bar d-flex align-items-center justify-content-center" :style="`--value:${lab.difficulty}%`">
                                {{ lab.difficulty }}
                            </span>
                        </div>
                    </td>
                    <td class="col-1 d-flex align-items-center">{{lab.rootPoints}}</td>
                    <td class="col-1 d-flex align-items-center">{{lab.userPoints}}</td>
                    <td class="col-4 d-flex align-items-center">
                        <div class="row">
                            <div class="tag col-4" v-for="tag in lab.tags" :key="tag.id">
                                {{ tag }}
                            </div>
                        </div>
                        <!-- <div class="tag" v-for="tag in lab.tags" :key="tag.id">{{tag}}</div> -->
                    </td>
                    <td class="col-1 d-flex align-items-center">
                        <a @click="select(lab)" class="btn-round green">
                            {{isLabSelected(lab) ? "-" : "+"}}
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </base-modal>
</template>

<script>
    import BaseModal from "./BaseModal";

    export default {
        name: "SelectLabModal",
        components: {BaseModal},
        props: [
            "initLabs",
            "initChosenLabs",
            "returnChosenLabs",
        ],
        data() {
            return {
                modal: {
                    title: "Choose Labs for Event",
                    buttons: [{
                        name: "confirm",
                        class: "px-button--success",
                        action: () => this.confirm()
                    }],
                    close: () => this.selfClose()
                },
                labs: [],
                chosenLabs: [],
                matchLabs: [],
                searchTerm: "",
            }
        },
        created() {
            this.labs = [...this.initLabs];
            this.chosenLabs = [...this.initChosenLabs];
            this.matchLabs = this.labs;
        },
        methods: {
            selfClose() {
                this.$emit("close");
            },
            confirm() {
                const response = {
                    labs: this.labs,
                    chosenLabs: this.chosenLabs,
                };
                this.returnChosenLabs(response);
                this.selfClose();
            },
            searchLab() {
                this.matchLabs = this.labs.filter(
                    l => l.name.includes(this.searchTerm) || l.tags.some(t => t.includes(this.searchTerm))
                );
            },
            isLabSelected(lab) {
                return !!this.chosenLabs.filter(l => l.id === lab.id).pop();
            },
            select(lab) {
                if (this.chosenLabs.includes(lab)) {
                    this.chosenLabs = this.chosenLabs.filter(l => l.id !== lab.id);
                } else {
                    this.chosenLabs.push(lab);
                }
                this.searchLab();
            },
        }
    }
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";

.lab__difficulty {
  width: 100%;
}

.lab__difficulty--bar {
  display: block;
  background: rgba($color: $neutral--medium, $alpha: 0.75);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  width: 100%;
  height: 10px;
  position: relative;
  font-size: 0;

  &:after {
    content: "";
    clip-path: inset(0px calc(100% - var(--value)) 0px 0px round 15px);
    border-radius: 10px 10px 10px 10px;
    -webkit-border-radius: 10px 10px 10px 10px;
    -moz-border-radius: 10px 10px 10px 10px;
    width: 100%;
    left: 0;
    position: absolute;
    height: 10px;
    background: #e5405e;
    background: linear-gradient(
            to right,
            #3fffa2 0%,
            #ffdb3a 35%,
            #ff993a 60%,
            #e5405e 100%
    );
  }
}

</style>
