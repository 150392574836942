<template>
  <div class="course-badges container">
    <div class="my-3 banner w-100 shadow-lg mb-3">
      <img class="w-100" src="../assets/images/pwnx-achievements.png" alt="achievements">
    </div>
    <h2 style="font-size: 16px" class="m-0 p-1 card__label">achievements</h2>
    <div class="row m-0 p-1 bg-medium shadow-lg">
      <div v-show="areLoaded" class="row m-0 p-0 col-6 col-md-4 col-xl-3 col-sm-6 mh-100 mw-100 w-100 h-100 text-center" v-for="(img, index) of badges" :key="index">
        <div class="badge-container row col-12 m-0 p-0 mh-100 mw-100 w-100 h-100">
          <div class="image-container col-12 p-0 m-0 mh-100 mw-100 w-100 h-100">
            <div v-if="!isCompleted(img)" class="position-absolute h-100 w-100 d-flex justify-content-center align-items-center m-0 p-0 flex-column">
              <div class="d-flex m-0 p-0 justify-content-center scale-me align-items-center text-center h-100" :style="'width: '+ String(getImageSize().width) + 'px'">
              </div>
              <div class="completed-text position-absolute fw-bold">
                {{ img.completed }}
              </div>
            </div>
            <div v-if="isCompleted(img)" class="completition-icon">
              <i class="fa-solid fa-check check-icon p-1 mx-5"></i>
            </div>
            <img @load="imgLoaded" class="badge-image rounded-circle shadow-lg m-0 p-0 mh-100 mw-100" :src="img.image" :id="['badge', index].join('-')">
          </div>
          <div class="achievement-name-container m-0 p-0 col-12">
            <p class="badge-name">{{img.name}}</p>
          </div>
        </div>
      </div>
      <template v-if="!areLoaded">
        <div v-show="!areLoaded" class="spinner-wrapper d-flex justify-content-center align-items-center position-relative w-100 p-2">
          <div v-show="!areLoaded" class="spinner"></div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import courseService from "@/api/courses";

export default {
  name: "CourseAchievement",
  data() {
    return {
      badges: [],
      cName: this.$route.params.name,
      loaded: 0
    }
  },
  created() {
    this.getCompletedChapters()
  },
  computed: {
    areLoaded() {
      console.log("badges", this.badges.length);
      console.log("loaded", this.loaded);
      return this.badges.length == this.loaded
    }
  },
  methods: {
    imgLoaded() {
      this.loaded += 1;
    },
    getImageSize() {
      return {
        width: 128,
        height: 128
      }
    },
    getCompletedChapters() {
      courseService.getCompletedAchievements()
        .then(response => {
          // console.log(response.data)
          this.badges = response.data.completed;
        })
    },
    isCompleted(img) {
      if (!img.completed) return;
      const [flag, lab] = img.completed.split("/");
      return Number(flag) === Number(lab);
    }
  },
  watch: {
    areCompleted: function(new_) {
      if (new_) this.getCompletedAchievements();
    }
  }
}

</script>
<style lang="scss" scoped>
// .completition-icon {}
@import "../assets/css/colors.scss";

.badge-name {
  color: $soft-orange;
  text-transform: uppercase;
  font-weight: 700;
  margin: 10px 0px;
  transition: all 0.2s ease;
}

.image-container {
  position: relative;
  /* min-width: 271px;
  min-height: 128px; */
}

/* .completed-wrapper {
  position: absolute;
  // background-color: rgba(0, 0, 0, 0.5);
  top: 0%;
  z-index: 10;
  overflow: scroll;
} */

.completed-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  font-weight: 900;
  font-size: 20px;
  z-index: 10;
}

.completed-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-weight: 900;
}

.completition-icon {
  position: absolute;
  top: 10%;
  left: 15%;
  width: 100%;
  height: 100%;
  z-index: 100;
  // transform: translateX(50%);
}

.check-icon {
  background-color: darken($soft-green, 50);
  border-radius: 50%;
  z-index: 10;
}

.badge-image {
  transform: scale(80%);
  transition: all 0.2s ease;
}

.badge-image:hover {
  transform: scale(100%);
  transition: all 0.2s ease;
}

.scale-me {
  // transform: scaleX(50%) scaleY(100%);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  z-index: 10;
}

.completed-text {
  z-index: 11;
  font-size: 20px;
}

.spinner {
  border: 5px solid transparent;
  border-left: 5px solid $soft-green;
  min-width: 50px;
  height: 50px;
  border-radius:50%;
  animation: spinner-rotate 0.5s linear infinite forwards;
}

@keyframes spinner-rotate {
  to {
    transform: rotate(360deg);
  }
}

</style>
