<template>
  <div class="team_card__container">
    <div class="team_card">
      <input type="hidden" value="" id="labIpClipboard">
      <div class="container-fluid team_card__face team_card__face--front"
        :class="this.$parent.user.team == team.id ? 'yourteam' : null">
        <p class="mb-0 top-data">{{ team.members }} <i class="fas fa-users"></i> </p>
        <div v-responsive.md.lg.xl class="row justify-content-center">
          <h2 class="mt-4 mb-0">{{ team.name | truncate(20) }}</h2>
        </div>
        <div v-responsive.md.lg.xl class="row mt-2 justify-content-center">
          <p class="mb-0 points">{{ team.points }} <i class="fas fa-trophy"></i> </p>
          <p class="ml-3 mb-0 points">{{ team.red }} <i class="fas fa-flag flag-red"></i> </p>
          <p class="ml-3 mb-0 points">{{ team.blue }} <i class="fas fa-flag flag-blue"></i> </p>
          <p class="ml-3 mb-0 points">{{ team.purple }} <i class="fas fa-flag flag-purple"></i> </p>
        </div>
        <div class="row justify-content-center">
          <div class="col team-picture" :style="`background-image:url('${team.profile_pic}')`"></div>
        </div>
        <div v-responsive.xs.sm class="row justify-content-center">
          <h2 class="mt-4 mb-0">{{ team.name | truncate(20) }}</h2>
        </div>
        <div class="row justify-content-center align-items-center mt-2">
          <span id="details-btn" @click="teamDetails(team.id)" class="text-center py-1 mt-3 mb-3">details</span>
          <span id="join-btn" @click="joinTeam(team.id)" class="text-center py-1 ml-2 mt-3 mb-3"
            v-if="!team.has_join_request && !this.$parent.user.team && shouldSeeUserBtns">join the team</span>
          <span id="leave-btn" @click="leaveTeam(team.id)"
            v-else-if="this.$parent.user.team == team.id && team.owner_id != this.$parent.user.id"
            class="text-center py-1 ml-2 mt-3 mb-3">leave the team</span>
          <span id="dismiss-btn" @click="dismissTeam(team.id)" v-else-if="this.$parent.user.id == team.owner_id"
            class="text-center py-1 ml-2 mt-3 mb-3">{{ dismissClicked ? 'Are you sure?' : 'dismiss team' }}</span>
          <span id="details-btn" class="text-center py-2 mt-3 ml-2 mb-3 disabled"
            v-else-if="team.has_join_request">request sent</span>
        </div>
        <div v-responsive.xs.sm class="row mt-2 justify-content-center">
          <p class="mb-0 points">{{ team.points }} <i class="fas fa-trophy"></i> </p>
          <p class="ml-3 mb-0 points">{{ team.red }} <i class="fas fa-flag flag-red"></i> </p>
          <p class="ml-3 mb-0 points">{{ team.blue }} <i class="fas fa-flag flag-blue"></i> </p>
          <p class="ml-3 mb-0 points">{{ team.purple }} <i class="fas fa-flag flag-purple"></i> </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import teamService from '../api/team';
import UserType from '../enums/user';

export default {
  name: "TeamCard",
  components: {},
  created() {
    this.dismissClicked = false;
  },
  data() {
    return {
      dismissClicked: false
    }
  },
  props: {
    team: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    shouldSeeUserBtns() {
      const { role: userRole } = this.$parent.user;
      return userRole == UserType.USER;
    }
  },
  methods: {
    teamDetails(team_id) {
      this.$router.push(`/team/${team_id}`);
    },
    leaveTeam(team_id) {
      this.$parent.loading = true;
      teamService.leave(team_id)
        .then(response => {
          const successMessage = !!response && !!response.data && !!response.data.message;
          if (successMessage) {
            this.flashSuccess(response.data.message);
          }

          this.$store.dispatch("user/getCurrentUser");
          this.$parent.user.team = null;
          this.$router.push("/teams");
        })
        .catch(error => {
          const manageableError = !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => this.$parent.loading = false);
    },
    dismissTeam(team_id) {
      if (!this.dismissClicked) {
        this.dismissClicked = true;
        return;
      } else {
        this.$parent.$parent.loading = true;
        this.dismissClicked = false;
        teamService.delete(team_id)
          .then(response => {
            const successMessage = !!response && !!response.data && !!response.data.message;
            if (successMessage) {
              this.flashSuccess(response.data.message);
            }
            return this.$store.dispatch("user/getCurrentUser");
          })
          .then(() => this.$router.push("/teams"))
          .catch(error => {
            const manageableError = !!error && !!error.data && !!error.data.errorDescription;
            if (manageableError) {
              this.flashError(error.data.errorDescription);
            }
          })
          .finally(() => {
            this.$parent.$parent.loading = false;
            this.$parent.loadTeams();
          })
      }
    },
    joinTeam(team_id) {
      teamService.askJoin(team_id)
        .then(response => {
          const successMessage = !!response && !!response.data && !!response.data.message;
          if (successMessage) {
            this.flashSuccess(response.data.message);
          }

          this.$parent.loadTeams();
        })
        .catch(error => {
          const manageableError = !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => this.$parent.loading = false);
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/css/colors";

input {
  all: unset;
}

.team_card__container {
  border-radius: 5px;
  position: relative;
  min-height: 260px;
}

.team_card {
  position: relative;
  transition: all .3s, transform 1s;
  transform-style: preserve-3d;
  border-radius: 5px;
  height: 100px;

  &__header {
    border-radius: 5px;
    z-index: 5;
    position: absolute;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: .7rem;
  }

  &__face {
    border-radius: 5px;
    position: absolute;
    min-width: 100%;
    left: 0;
    top: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    min-height: 260px;

    &--front,
    &--back {
      background: $neutral--medium;
    }

    &--front {}

    &--back {
      color: $neutral--darker;
      background: $soft-green;
      transform: rotateY(180deg);
    }
  }
}

#ip-address,
#points {
  font-size: .8rem;
}

.flag-submit {
  width: 80%;
  background: $neutral--dark;
  border-radius: 5px;
  height: 20px;
  font-size: .8rem;

  &::placeholder {
    opacity: .2;
  }
}

#submit-btn {
  font-weight: bold;
  background: $soft-green;
  color: $neutral--darker;
  text-transform: uppercase;
  width: 40%;
  font-size: .7rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all .3s;
  outline: 1px solid $soft-green;

  &:hover {
    color: $soft-green;
    background: transparent;
  }
}

#reset-btn {
  font-weight: bold;
  background: #808080;
  color: white;
  text-transform: uppercase;
  width: 10%;
  font-size: .7rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all .3s;
  outline: 1px solid #808080;

  &:hover {
    background: transparent;
  }
}

.disabled {
  background-color: rgba(0, 0, 0, 0.5) !important;
  color: white !important;
  outline: none !important;
}

.completion {
  font-size: .8rem;
}

.difficulty,
.top-data {
  position: absolute;
  top: 20px;
  font-size: .8rem;
  transform: translateY(-50%);
}

.difficulty {
  left: 20px;

  &.easy {
    color: $soft-green;
    text-shadow: 0px 0px 20px $soft-green;
  }

  &.medium {
    color: $soft-orange;
    text-shadow: 0px 0px 20px $soft-orange;
  }

  &.hard {
    color: $soft-red;
    text-shadow: 0px 0px 20px $soft-red;
  }
}

.top-data {
  right: 20px;
}

.points {
  font-size: .8rem;
}

.team-picture {
  height: 90px;
  width: 90px;
  margin-top: 1em;
  max-height: 90px;
  max-width: 90px;
  background-size: contain;
  border-radius: 50%;
  transition: all 0.3s;
  background-color: $neutral--dark;

  &:hover {
    border: 4px solid $neutral--real-medium;
  }
}

.fa-flag {

  &.flag-1,
  &.flag-1:after {
    color: $soft-green;
  }

  &.flag-2,
  &.flag-2:after {
    color: $soft-orange;
  }

  &.flag-red,
  &.flag-red:after {
    color: $soft-red;
  }

  &.flag-blue,
  &.flag-blue:after {
    color: $soft-blue;
  }

  &.flag-purple,
  &.flag-purple:after {
    color: $soft-purple;
  }
}

#details-btn {
  font-weight: bold;
  background: $soft-green;
  color: $neutral--darker;
  text-transform: uppercase;
  width: 5rem;
  font-size: .7rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all .3s;
  outline: 1px solid $soft-green;

  &:hover {
    color: $soft-green;
    background: transparent;
  }
}

#join-btn {
  font-weight: bold;
  background: $neutral--real-medium;
  color: white;
  text-transform: uppercase;
  width: 7rem;
  font-size: .7rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all .3s;
  outline: 1px solid $neutral--real-medium;

  &:hover {
    background: transparent;
  }
}

#leave-btn,
#dismiss-btn {
  font-weight: bold;
  background: $soft-red;
  color: $neutral--darker;
  text-transform: uppercase;
  width: 8rem;
  font-size: .7rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all .3s;
  outline: 1px solid $soft-red;

  &:hover {
    color: $soft-red;
    background: transparent;
  }
}

.disabled {
  background-color: rgba(0, 0, 0, 0.5) !important;
  color: white !important;
  outline: none !important;
  width: 6rem !important;
}

.team_card__face {
  height: 260px;
}

.yourteam {
  border: 2px solid $soft-green;

  & h2::before {
    content: "🏆 "
  }
}
</style>












