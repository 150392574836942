<template>
    <base-modal
            :title="modal.title"
            :buttons="modal.buttons"
            :close="modal.close">
        <div class="">
            <p>
                Do you really want to kick user {{user.username}} ({{user.email}})?
            </p>
        </div>
    </base-modal>
</template>

<script>
    import BaseModal from "./BaseModal";

    export default {
        name: "CompanyKickConfirm",
        components: {BaseModal},
        props: [
            "user",
            "returnUserChoice",
        ],
        data() {
            return {
                modal: {
                    title: "Kick member from company",
                    buttons: [
                        {
                            name: "Kick",
                            class: "btn-primary",
                            action: () => this.selfClose(true, this.user)
                        },
                    ],
                    close: () => this.selfClose(false)
                },
            }
        },
        methods: {
            selfClose(choice, user) {
                this.returnUserChoice(choice, user);
                this.$emit("close");
            },
        }
    }
</script>

<style scoped>

</style>
