import { render, staticRenderFns } from "./PxWriteupBtn.vue?vue&type=template&id=25514777&scoped=true"
import script from "./PxWriteupBtn.vue?vue&type=script&lang=js"
export * from "./PxWriteupBtn.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25514777",
  null
  
)

export default component.exports