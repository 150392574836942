<template>
  <div>
    <div>
      <!--suppress HtmlUnknownTag -->
      <FlashMessage :position="'right top'"></FlashMessage>
      <!-- Main wrapper -->
      <div class="main-wrapper">
        <LeftMenu id="left-menu" @collapse-sidebar="toggleLeftMenu" :isCollapsed="!leftMenuActive"></LeftMenu>
        <!-- Content right -->
        <header class="header-user" v-if="userIsLoggedIn">
          <div class="px-md-4 row d-flex align-items-center header__row justify-content-between justify-content-md-end"
            :class="bannerStyle">
            <div class="col d-none d-md-block" v-show="latestData !== null">
              <div class="row">
                <p v-if="bannerText.type === 'user'" class="mb-0 banner-title">Latest user</p>
                <p v-else class="mb-0 banner-title">Latest submission</p>
              </div>
              <div class="row">
                <div @click=goToSubmission()
                  class="col mx-0 px-0 d-flex flex-row align-items-center banner-text__container"
                  :class="bannerText.type === 'user' ? '' : `flag${latestSubmission.type}`">
                  <flag v-if="bannerText.type === 'user'" :iso="latestUser.country_code" :squared="false"></flag>
                  <i v-else class="fas fa-flag"></i>
                  <Roller :key="bannerText.id" class="ml-2 banner-text" :text="bannerText.text" :isNumberFormat="false"
                    :charList="rollerCharlist" defaultChar="A"></Roller>
                </div>

              </div>
            </div>
            <div v-responsive.xs.sm class="col-2 pr-0" @click="$router.push('/home')">
              <img src="./assets/images/logo-pwnx-darkmode.svg" alt="">
            </div>
            <div v-responsive.xs.sm.md.lg.xl class="col-7 col-md-4 pl-0">
              <form @submit.prevent class="w-100 header-user__form-search position-relative" id="gsearch">
                <i class="header-user__form-btn-search position-absolute"></i>
                <!--suppress HtmlFormInputWithoutLabel -->
                <input type="text" name="s" id="s" class="form-control header-user__input-search w-100"
                  placeholder="Search for peoples, labels, teams, events..." autocomplete="off" v-model="gSearchTerm"
                  @input="gSearchUpdate" @focus="setGSearchFocus(true)" @blur="setGSearchFocus(false)" />
                <ul class="header-user__search menu-dropdown position-absolute"
                  :class="{ active: this.gSearchIsFocus && this.gSearchTerm.length && gSearchResults.length }">
                  <li v-for="gRes in gSearchResults" :key="gRes.type + gRes.id"
                    @mousedown="navigateTo(gRes.type, gRes.id)" class="pointer search__results">
                    <px-badge :class="`px-badge--${tagFor[gRes.type]}`">
                      {{ gRes.type }}
                    </px-badge>

                    <a>
                      {{ gRes.res | truncate(24) }}
                      <span v-if="gRes.isExtern">
                        <img src="./assets/images/ctftime-favicon.png" alt="CTFTIME" class="ml-2">
                      </span>
                    </a>
                  </li>
                </ul>
                <ul class="header-user__search menu-dropdown position-absolute"
                  :class="{ active: this.gSearchIsFocus && this.gSearchTerm.length && !gSearchResults.length }">
                  No result found
                </ul>
              </form>
            </div>
            <div v-responsive.md.lg.xl class="col-3">
              <div class="row text-center header__icons">
                <div class="col" id="discord">
                  <a href="https://discord.gg/bcBAzBBXFf" target="_blank" class="header__icon">
                    <i class="fab fa-discord fa-lg"></i>
                  </a>
                </div>
                <div class="col" id="notifications">
                  <router-link to="/notifications" class="header__icon">
                    <i class="fas fa-bell fa-lg"></i>
                    <div v-if="this.notificationCount > 0" class="notification-counter notification-counter--pulse">
                      {{ this.notificationCount }}
                    </div>
                  </router-link>
                </div>
                <div class="col" id="settings">
                  <router-link to="/settings" class="header__icon">
                    <i class="fas fa-cog fa-lg"></i>
                  </router-link>
                </div>
                <div class="col" id="referral" @click="showReferralModal()">
                  <div class="header__icon">
                    <i class="fas fa-users orange"></i>
                  </div>
                </div>
                <div class="col pr-0 header-icon" id="logout" @click="logout()">
                  <i class="fas fa-sign-out-alt"></i>
                </div>
              </div>
            </div>
            <div v-responsive.xs.sm class="col-2 d-flex align-items-center justify-content-end">
              <i class="fa-solid fa-bars mr-3" @click="toggleLeftMenu"></i>
              <i class="fa-solid fa-bell" @click="$router.push('/notifications')"></i>
            </div>
          </div>
          <ul v-responsive.md.lg.xl class="header-user__breadcrumb">
            <li v-for="crumb in breadcrumbs" :key="crumb.name">
              <router-link :to="crumb.navigation" v-if="crumb.navigation">
                {{ crumb.name }}
              </router-link>
              <span v-else>{{ crumb.name }}</span>
            </li>
          </ul>
        </header>
        <div :class="{ 'content-right': this.userIsLoggedIn }" class="p-4">
          <!-- Header -->
          <!-- End header -->
          <router-view class="main-content px-0 pl-md-5 h-100" :class="{ 'main-content__logged-in': userIsLoggedIn }"
            :key="$route.fullPath"></router-view>
        </div>
        <!-- End content right -->

        <!-- Footer -->
        <footer class="main-footer">
          <p class="main-footer__content text-right">&copy; 2021 PWNX™</p>
        </footer>
        <!-- End footer -->
      </div>
      <!-- End main wrapper -->
      <!-- Cookie policy -->
      <cookie-law theme="pwnx">
        <div slot-scope="props" class="w-100">
          <div class="row align-items-center justify-content-between">
            <div class="col-9">
              We use cookies to ensure you a safe and rich experience.
              <router-link to="/cookie-policy" target="_blank">Find out more</router-link>
            </div>
            <div class="col-3">
              <div class="row justify-content-around">
                <px-button class="Cookie__button px-button--success" @click.native="props.accept">
                  <span>I accept</span>
                </px-button>
                <px-button class="Cookie__button px-button--danger" @click.native="props.close">
                  <span>Close</span>
                </px-button>
                <px-button class="Cookie__button Cookie__button--icon popup__btn-close" @click.native="props.decline">
                  <img src="./assets/images/btn-close.svg" alt="close">
                </px-button>
              </div>
            </div>
          </div>
        </div>
      </cookie-law>
      <div class="background-logo d-flex">
        <video autoplay muted loop id="myVideo">
          <!--        <source src="./assets/videos/sfondo_pwnx_b2.mp4" type="video/mp4">-->
        </video>
      </div>
      <!-- End cookie policy -->

      <!-- Loading -->
      <Spinner v-if="loading"></Spinner>
      <!-- End loading -->

      <modals-container></modals-container>
    </div>
    <!--    <div class="mobile-placeholder">-->
    <!--      <div class="container vh-100 d-flex align-items-center">-->
    <!--        <div class="row justify-content-center">-->
    <!--          <div class="col-6 mb-4">-->
    <!--            <img src="./assets/images/logo-pwnx-darkmode.svg" alt="">-->
    <!--          </div>-->
    <!--          <div class="col-11 text-center mb-4">-->
    <!--            <h2 class="mb-1">This platform doesn't work on mobile yet</h2>-->
    <!--            <p>Join and play from a laptop or desktop device.</p>-->
    <!--          </div>-->
    <!--          <div class="col-3 mobile-placeholder__icon text-center">-->
    <!--            <a href="https://twitter.com/pwnx_official" target="_blank">-->
    <!--              <i class="fab fa-twitter"></i></a>-->
    <!--          </div>-->
    <!--          <div class="col-3 mobile-placeholder__icon text-center">-->
    <!--            <a href="https://www.instagram.com/pwnx_official/" target="_blank">-->
    <!--              <i class="fab fa-instagram"></i>-->
    <!--            </a>-->
    <!--          </div>-->
    <!--          <div class="col-3 mobile-placeholder__icon text-center">-->
    <!--            <a href="https://www.linkedin.com/company/pwnx/" target="_blank">-->
    <!--              <i class="fab fa-linkedin"></i>-->
    <!--            </a>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import eventService from "@/api/event.js";
import AllowanceModal from "@/components/AllowanceModal";
import EventLabResetConfirmModal from "@/components/EventLabResetConfirmModal";
import PxBadge from "@/components/PxBadge";
import PxButton from "@/components/PxButton";
import WriteupModal from "@/components/WriteupModal";
import { connect } from "@/utils/websocket.js";
import CookieLaw from "vue-cookie-law";
import Roller from "vue-roller";
import { mapGetters, mapMutations, mapState } from "vuex";
import appService from "./api/app";
import httpService from "./api/http";
import labService from './api/lab';
import notificationService from './api/notification';
import statsService from "./api/stats";
import userService from "./api/user";
import resetAudio from "./assets/audio/reset.mp3";
import EnableNotificationModal from "./components/EnableNotificationModal";
import LeftMenu from "./components/LeftMenu";
import PushNotificationPromptModal from "./components/PushNotificationPromptModal";
import ReferralModal from './components/ReferralModal.vue';
import Spinner from "./components/Spinner";
import UserType from './enums/user';
import { authGuard, cMap } from "./router";

// noinspection JSUnusedGlobalSymbols
export default {
  name: "App",
  components: {
    PxBadge,
    PxButton,
    Spinner,
    LeftMenu,
    CookieLaw,
    Roller,
  },
  computed: {
    ...mapGetters({
      userIsLoggedIn: "user/isLoggedIn",
      userFirstLogin: "user/firstLogin",
      userHasBeenAskedForNotifications: "user/hasBeenAskedForNotifications"
    }),
    ...mapMutations({
      setLogin: "user/setLogin"
    }),
    ...mapState({
      user: state => state.user
    }),
  },
  watch: {
    "$route"() {
      // Close left menu
      this.leftMenuActive = false;
    },
    'userIsLoggedIn'() {
      this.buildBannerAnimation();
      this.bannerInterval = setInterval(() => this.bannerAnimation(), 30000);
    }
  },
  data() {
    return {
      ws: null,
      wsInterval: null,
      labResetModalOpen: false,
      rCheckInterval: null,
      loading: false,
      latestData: null,
      gSearchTerm: "",
      gSearchIsFocus: false,
      bannerInterval: null,
      gSearchResults: [],
      gSearchTimeout: null,
      tagFor: {
        user: "blue",
        lab: "success",
        event: "event",
        team: "team"
      },
      showUserMenu: false,
      leftMenuActive: false,
      breadcrumbs: [],
      sw: null,
      menus: [],
      tourSteps: {},
      tour: null,
      firstLogin: false,
      notifications: [],
      notificationIntervalId: null,
      userIntervalId: null,
      notificationCount: null,
      latestUser: {},
      latestSubmission: {},
      bannerStyle: "user",
      bannerText: {
        type: "user",
        text: ""
      },
      rollerCharlist: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"],
      showFullPageModal: false,
      lastSubmitted: {
        labName: null,
        submissionId: null
      }
    }
  },
  beforeCreate() {
    this.$store.dispatch("router/init", { routes: this.$router.options.routes });
  },
  created() {
    // Login actions
    this.setupIntervals();
    this.$on("loggedIn", () => {
      clearInterval(this.wsInterval);
      if (this.ws) this.ws.close();
      this.ws = null;
      this.setupWebSocket();
    })
    if (this.userIsLoggedIn) {
      this.loadMenus();
      // Get user routes
      appService.getRoutes()
        .then(response => {
          const routes = response.data.routes.map(r => {
            const newRoutes = {
              path: r.path,
              component: cMap[r.component],
              name: r.name,
              beforeEnter: authGuard
            }
            if (r.props) newRoutes["props"] = r.props;
            return newRoutes;
          });
          this.$store.dispatch("router/init", { routes });
          this.$router.addRoutes(routes);
        });
      this.setupResetCheckInterval()
    }
    this.firstLogin = this.userFirstLogin;
  },
  mounted() {
    window.addEventListener("resize", this.onWindowResize, false);
    window.addEventListener("mousedown", this.closeUserMenu, false);
    // Product tour
    this.shouldStartTour();
    this.setupWebSocket();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
    window.removeEventListener("mousedown", this.closeUserMenu);
    if (this.tour) {
      this.tour.destroy();
    }
    clearInterval(this.bannerInterval);
    clearInterval(this.wsInterval);
    clearInterval(this.rCheckInterval);
    if (this.ws) this.ws.close();
    this.ws = null;
  },
  methods: {
    setupResetCheckInterval() {
      this.checkEventResets()
      this.rCheckInterval = setInterval(this.checkEventResets, 15000);
    },
    playResetAudio() {
      const audio = new Audio(resetAudio);
      audio.volume = 0.3;
      audio.play();
    },
    openLabResetConfirmModal(resetId, lab) {
      let isCurrentUser = false;

      if (lab.user !== undefined && lab.user !== null) {
        isCurrentUser = lab.user.id === this.user.id;
      }

      if (isCurrentUser) return;

      this.playResetAudio();

      this.labResetModalOpen = true;
      this.$modal.show(
        EventLabResetConfirmModal,
        {
          lab,
          resetId,
          callback: () => {
            this.labResetModalOpen = false;
          }
        },
        { height: "auto", width: "60%" }
      )
    },
    ensureWebSocketIsConnected() {
      if (!this.ws) {
        clearInterval(this.wsInterval);
        this.setupWebSocket();
        return;
      }
      const state = this.ws.readyState;
      if (state === WebSocket.OPEN) {
        // clearInterval(this.wsInterval);
      } else if (state === WebSocket.CLOSED) {
        if (!this.userIsLoggedIn) return;
        clearInterval(this.wsInterval);
        this.setupWebSocket();
        return;
      }
    },
    setupWebSocket() {
      const token = this.user.token;
      if (!token) return;
      this.ws = connect(token);
      this.wsInterval = setInterval(this.ensureWebSocketIsConnected, 10000);

      this.ws.onmessage = (event) => {

        const messages = JSON.parse(event.data);

        const isArray = Array.isArray(messages);

        if (isArray) {
          for (const msg of messages) {
            if (msg.type === "LAB-RESET") {
              const resetId = msg.reset_id;
              const lab = msg.lab;
              if (this.labResetModalOpen) return;
              this.openLabResetConfirmModal(resetId, lab)
            }
          }
        }
      }
    },
    async checkEventResets() {
      const resets = await eventService.checkEventsReset().then(resp => resp.data.resets)

      for (const reset of resets) {
        const { lab } = reset;
        const { reset_id: resetId } = lab;

        console.log({ resetId, lab })
        if (this.labResetModalOpen) return;

        this.openLabResetConfirmModal(resetId, lab)
      }
    },
    setupIntervals() {
      this.buildBannerAnimation();
      this.bannerAnimation();
      this.bannerInterval = setInterval(() => this.bannerAnimation(), 30000);

      if (!this.userIsLoggedIn) {
        return;
      }

      this.getNotifications();
      this.loadUser();

      this.notificationIntervalId = setInterval(this.getNotifications, 300000);
      this.userIntervalId = setInterval(this.loadUser, 300000);
    },
    openWriteupModal(lab) {
      this.$modal.show(
        WriteupModal,
        {
          lab_id: lab.id,
          lab_name: lab.name,
          stopLoading: () => {
            this.$parent.loading = false;
          },
        },
        { height: "auto" }
      );
    },
    openAllowanceWriteup(lab) {
      this.$modal.show(
        AllowanceModal,
        {
          lab,
          openWriteup: this.getLabWriteup,
          stopLoading: () => {
            this.$parent.loading = false;
          },
        },
        { height: "auto" }
      );
    },
    async getLabWriteup(lab) {
      const hasAllowance = await labService.hasAllowance(lab.id)
        .then(response => response.data.allowance)


      if (!hasAllowance) this.openAllowanceWriteup(lab);
      else labService
        .getLabWriteup(lab.id)
        .then(() => {
          this.openWriteupModal(lab);
        })
        .catch((error) => {
          const manageableError = !!error.data && !!error.data.errorDescription;
          if (manageableError) this.flashError(error.data.errorDescription);
        });
    },
    isUserType(uType) {
      if (!this.user) return false;
      return this.user.role === UserType[uType.toUpperCase()];
    },
    isPremium() {
      return this.user.premium !== undefined && Object.entries(this.user.premium).length > 0;
    },
    hasPremium(type) {
      let result = false
      if (this.isPremium()) {
        this.user.premium.forEach((p) => {
          if (type == p.premium.type) {
            result = true;
          }
        })
      }
      return result
    },
    handleResponseError(error) {
      let status = null;
      if (!error.response) {
        status = error.request.status
      } else {
        status = error.response.status
      }
      if (status === 401 || status == 403) {
        this.flashError("Session expired, please login again");
        this.$store.commit("user/reset");
        clearInterval(this.notificationIntervalId);
        clearInterval(this.userIntervalId);
        this.$router.push("login");
        this.$emit("close");
      }
    },
    loadUser() {
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('user/getCurrentUser').then(_ => { }, error => {
        this.handleResponseError(error);
      });
    },
    showReferralModal() {
      this.$modal.show(ReferralModal, { user: this.user }, { height: "auto" })
    },
    getNotifications() {
      notificationService.getNotifications().then(response => {
        this.notifications = response.data.notifications;
        this.getNotificationsCount()
      }).catch((error) => {
        this.handleResponseError(error);
      });
    },
    loadMenus() {
      // Load menus
      appService.getMenus()
        .then(response => this.menus = response.data.leftMenu)
        .catch(error => {
          const manageableError = !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        });
    },
    gSearchUpdate() {
      if (this.gSearchTerm.length === 0) {
        this.gSearchResults = [];
        return;
      }
      this.gSearchTerm = this.gSearchTerm.trimLeft();
      const data = {
        gSearchTerm: encodeURIComponent(this.gSearchTerm)
      };
      clearTimeout(this.gSearchTimeout);
      this.gSearchTimeout = setTimeout(() => {
        appService.gSearch(data)
          .then(response => this.gSearchResults = response.data.results)
          .catch(error => {
            const manageableError = !!error && !!error.data && !!error.data.errorDescription;
            if (manageableError) {
              this.flashError(error.data.errorDescription);
            }
          });
      }, 1000)
    },
    setGSearchFocus(state) {
      this.gSearchIsFocus = state
    },
    navigateTo(type, id) {
      const getParams = t => {
        const key = `${t}Id`;
        const params = {};
        params[key] = id;
        return params;
      };
      const name = type[0].toUpperCase() + type.slice(1) + "Details";
      this.$router.push({ name, params: getParams(type) });
      this.gSearchTerm = "";
    },
    toggleUserMenu() {
      this.showUserMenu = !this.showUserMenu;
    },
    closeUserMenu() {
      this.showUserMenu = false;
    },
    toggleLeftMenu() {
      this.leftMenuActive = !this.leftMenuActive;
    },
    logout() {
      if (this.ws) this.ws.close();
      this.ws = null;
      httpService.all([
        userService.logout(),
        this.$store.dispatch("router/init", { routes: this.$router.options.routes, shouldInit: true }),
        this.$store.dispatch("user/logout")
      ]).then(() => {
        this.$router.push("/login");
        clearInterval(this.notificationIntervalId);
      });
    },
    back() {
      this.$router.go(-1);
    },
    showNotificationPrompt() {
      if (!this.userHasBeenAskedForNotifications) {
        this.$modal.show(
          PushNotificationPromptModal,
          {
            returnUserChoice: this.manageUserPushChoice
          },
          { height: "auto" }
        );
      }
    },
    manageUserPushChoice(choice) {
      if (choice) {
        this.loading = true;

        const applicationServerKey = this.urlB64ToUint8Array(process.env.VUE_APP_applicationServerPublicKey);

        this.sw.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: applicationServerKey
        })
          .then(subscription => this.updateSubscriptionOnServer(subscription))
          .catch(error => {
            if (error.name === "NotAllowedError") {
              this.$modal.show(EnableNotificationModal, {}, { height: "auto" })
            } else {
              const manageableError = !!error && !!error.data && !!error.data.errorDescription;
              if (manageableError) {
                this.flashError(error.data.errorDescription);
              }
            }
          })
          .finally(() => this.loading = false);
      }

      userService.hasBeenNotified().then();
    },
    urlB64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - base64String.length % 4) % 4);
      const base64 = (base64String + padding)
        .replace(/-/g, "+")
        .replace(/_/g, "/");

      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
    updateSubscriptionOnServer(subscription) {
      appService.subscribePushNotifications(subscription)
        .then(response => {
          const successMessage = !!response && !!response.data && !!response.data.message;
          if (successMessage) {
            this.flashSuccess(response.data.message);
          }
        })
        .catch(error => {
          const manageableError = !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        });
    },
    formatTime(t) {
      const tt = `${t}`;
      if (tt.length < 2) {
        return `0${t}`;
      }
      return t;
    },
    shouldStartTour() {
      const page = this.$route;
      const forceTour = page.query && page.query.tour && page.query.tour === "true";
      if (this.userFirstLogin || forceTour) {
        if (Object.keys(this.tourSteps).length === 0) {
          appService.getTourSteps()
            .then(response => {
              this.tourSteps = response.data.tourSteps;
              this.startTour();
            })
            .catch();
        } else {
          this.startTour();
        }
      }
    },
    startTour() {
      this.tour = this.$shepherd({
        useModalOverlay: true,
        confirmCancel: true,
        keyboardNavigation: true,
        tourName: "PWNX-tour",
        defaultStepOptions: {
          cancelIcon: {
            enabled: true
          },
          scrollTo: { behavior: "smooth", block: "center" }
        }
      });

      const steps = this.buildSteps();
      this.tour.addSteps(steps);
      this.tour.start();
      this.setLogin();
    },
    buildSteps() {
      const tourSteps = [];

      let tourStep;
      for (const [index, step] of this.tourSteps.entries()) {
        if (!this.userFirstLogin && index === 0) {
          continue;
        }

        tourStep = {
          title: step.title,
          text: step.text,
          id: step.id
        };
        if (step.attachToElement) {
          tourStep.attachTo = {
            element: step.attachToElement,
            on: step.attachToOn
          };
        }
        if (index === 0) {
          tourStep.buttons = [{
            action() {
              return this.next();
            },
            text: 'Yes'
          }, {
            action() {
              return this.complete();
            },
            text: 'No'
          }];
        } else if (index === 1) {
          tourStep.buttons = [{
            action() {
              return this.next();
            },
            text: 'Next'
          }];
        } else if (index === this.tourSteps.length - 1) {
          tourStep.buttons = [{
            action() {
              return this.back();
            },
            classes: 'shepherd-button-secondary',
            text: 'Back'
          }, {
            action() {
              return this.complete();
            },
            text: 'Done'
          }];
        } else {
          tourStep.buttons = [{
            action() {
              return this.back();
            },
            classes: 'shepherd-button-secondary',
            text: 'Back'
          },
          {
            action() {
              return this.next();
            },
            text: 'Next'
          }];
        }

        tourSteps.push(tourStep);
      }

      return tourSteps;
    },
    getNotificationsCount() {
      let count = this.notifications.filter(el => new Date(el.created + "Z").getTime() >= localStorage.getItem("lastNotificationCheck")).length
      this.notificationCount = count <= 9 ? count : "9+"

    },
    bannerAnimation() {
      const response = this.latestData
      if (!response) return;

      if (this.bannerText.type === "user") {
        this.bannerText = {
          type: "flag",
          text: `by ${response.flag.username} on ${response.flag.lab_name}`,
          id: response.flag.id
        }
      } else {
        this.bannerText = {
          type: "user",
          text: this.latestUser.username,
          id: this.latestUser.id
        }
      }
    },
    buildBannerAnimation() {
      if (!this.userIsLoggedIn) {
        return;
      }
      statsService.latestUserAction().then(response => {
        this.latestUser = response.data.user;
        this.latestSubmission = response.data.flag;

        this.latestData = response.data;
      }).catch(e => {
        console.error(e)
      }).finally(() => { this.bannerAnimation() })
    },
    goToSubmission() {
      if (this.bannerText.type === 'user') {
        this.$router.push(`/user/${this.latestUser.id}`)
      } else {
        this.$router.push(`/lab/${this.latestSubmission.id}`)
      }
    },
    getHeaderHeight() {
      return document.getElementsByClassName("header-user")[0].offsetHeight;
    },
    setAppMarginTop(height) {
      document.getElementsByClassName("main-content")[0].style.marginTop = `${height + 5}px`
    }
  }
}
</script>

<style lang="scss" scoped>
@import "assets/css/colors";

.background-logo {
  background: #1f252a;
  pointer-events: none;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 5%;
  height: 100vh;
  width: 100vw;
  margin: 0;
  display: flex;
  justify-content: center;
  opacity: 0.3;

  video {
    opacity: 0.4;
    width: 100vw;
    position: absolute;
    bottom: 0;
  }
}

.main-content {
  &__logged-in {
    @media (max-width: 767px) {
      margin-top: 25%;
    }
  }
}

// Main footer
.main-footer {
  padding: 20px;
  position: fixed;
  bottom: 0;
  right: 0;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
  z-index: -1;

  &__content {
    margin: 0;
    font-size: 15px;
    color: #768aa3;
    opacity: 0.8;
  }
}

// Cookie law
.Cookie {
  &--pwnx {
    padding: 2rem;
    background-color: $neutral--dark;

    .Cookie__button {
      text-transform: uppercase;
      font-size: 0.75rem;
      letter-spacing: 2.2px;
      box-shadow: 0 10px 11px 0 rgba(0, 0, 0, 0.32);
      padding: 0.5rem 1rem;
      font-weight: 500;
      border-radius: 4px;

      &--icon {
        background-color: transparent;
        box-shadow: none;
        border: none;
      }
    }
  }
}

.lemme-see {
  min-width: 60px;
  margin-left: 2rem;
  text-align: center;
  font-size: 1.3rem;

  &.first i {
    color: #4bc66a;
  }

  &.last {
    text-align: center;
    margin-right: 20px;
  }
}

.search {
  &__results {
    display: grid;
    grid-template-columns: 30% 70%;
  }
}

.header__icon i {
  color: rgb(68, 72, 79);
}

.notification-counter {
  margin-bottom: 5px;
  border-radius: 50%;
  display: flex;
  color: $neutral--smooth;
  background: white;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  font-weight: bold;
  position: absolute;
  bottom: -70%;
  right: 20%;
  height: 15px;
  width: 15px;
  z-index: 2;
}

.notification-counter--pulse {
  &:after {
    content: "";
    width: 150%;
    height: 150%;
    background: white;
    opacity: 0.3;
    border-radius: 50%;
    position: absolute;
    z-index: -1;
    animation-name: notificationPulse;
    animation-iteration-count: infinite;
    animation-duration: 2s;
  }
}

.header-user {
  background-color: $neutral--dark;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000 !important;
  min-height: 10%;

  &__search {
    z-index: 1000 !important;
  }

  @media only screen and (min-width: 768px) {
    margin-left: 250px;
    position: unset;
    width: unset;
  }
}

@keyframes notificationPulse {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0.3;
  }
}

.header__icons {
  #logout {
    i {
      color: white !important;

      &:after {
        background: white;
      }
    }
  }

  .orange {
    color: $soft-orange !important;

    &:after {
      background-color: $soft-orange !important;
    }
  }

  i {
    color: $soft-green !important;
    position: relative;
    font-size: 1rem;


    &:after {
      content: "";
      position: absolute;
      height: 35px;
      width: 35px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: $soft-green;
      opacity: 0.1;
      transition: all 0.15s;
    }


    &:hover {
      &:after {
        opacity: 0.3;
      }
    }
  }
}

header {
  background: #15191d !important;

}

.header__row {
  min-height: 100px;
  max-height: 100px;
}

.last-avatar {
  border-radius: 50%;
  height: 40px;
  max-width: 40px;
  background-size: cover;
  background-position: center center;
  border: 2px solid;
  box-shadow: 0px 0px 10px;
}

.banner-text {
  font-size: .9rem;

  &__container {
    &.flag1 {
      * {
        color: $soft-green !important;
      }
    }

    &.flag0 {
      * {
        color: $soft-orange !important;
      }
    }
  }
}

.banner-text,
.rollerBlock {
  li {
    display: flex;
    color: blue !important;
  }
}

.banner-title {
  text-transform: uppercase;
  font-size: .8rem;
  opacity: .7;
}

#referral {
  cursor: pointer;
}

#logo {
  width: 100%;
}

.mobile-placeholder {
  p {
    color: $neutral--soft;
  }

  &__icon {
    background: rgba($neutral--soft, .3);
    padding: 7px;
    margin: 5px;
  }

  i {
    font-size: 1.5rem;
  }

  a {
    color: inherit;
  }
}
</style>
