<template>
  <div class="container-fluid table">

    <div class="row align-items-center mb-3">
      <div class="col-md-6 col-12">
        <h1 class="main-title-1">Company Administration</h1>
      </div>
      <div class="col-md-6 col-12 text-right">
        <a @click="goBack()" class="btn btn-dark btn--icon btn--icon--back">Back</a>
      </div>
    </div>

    <div class="padding-bottom-20">
      <form autocomplete="off" class="padding-bottom-20 rounded-card__item bg-gradient" @submit.prevent>
        <div class="heading">My Company: <span class="no-text-transform">{{ companyNameUpper }}</span></div>
        <div class="row">
          <div class="col form-group">
            <label for="name" class="card__label">Name</label>
            <input autocomplete="off" type="text" name="name" id="name" class="form-control"
              v-model="companyChanges.name" required>
          </div>
          <div class="col form-group">
            <label for="url" class="card__label">Website</label>
            <input autocomplete="off" type="text" name="url" id="url" class="form-control" v-model="companyChanges.url"
              placeholder="https://">
          </div>
        </div>

        <label for="profile" class="card__label">Profile picture</label>
        <div class="row align-items-center padding-bottom-20">
          <div class="company__picture">
            <figure class="user-details__figure">
              <img :src="company.picture" alt="picture" class="header-user__user-thumb" />
            </figure>
          </div>
          <div class="col">
            <input type="file" name="profile_file" ref="myFile" @change="selectFile" id="profile-picture" />
            <input type="text" name="profile" id="profile" class="form-control file-upload" readonly
              v-model="companyChanges.file.name">
          </div>
          <div class="company__browse-button">
            <button type="button" class="btn btn-dark btn-browse" @click="triggerFileInput">
              Browse
            </button>
          </div>
        </div>
        <div class="padding-bottom-20">
          <button type="submit" class="btn btn-success btn--icon btn--icon--edit" @click="update"
            :disabled="updateDisabled()">
            Update
          </button>
        </div>

        <div class="heading">My Contract</div>
        <div class="row">
          <div class="col-md-6 col-12 form-group" v-if="!company.contract.isHrp && !isCorporate">
            <label for="max-labs-event" class="card__label">Max labs per event</label>
            <input type="number" name="max-labs-event" id="max-labs-event" class="form-control" disabled
              v-model="company.contract.maxLabs">
          </div>
          <div class="col-md-6 col-12 form-group" v-if="company.contract.isHrp">
            <label for="max-recruiter-tickets" class="card__label">Max Recruiter Tickets</label>
            <input type="number" name="max-recruiter-tickets" id="max-recruiter-tickets" class="form-control" disabled
              v-model="company.contract.maxRecruiterTickets">
          </div>
          <div class="col-md-6 col-12 form-group" v-if="!company.contract.isHrp && !isCorporate">
            <label for="max-users-event" class="card__label">Max users per event</label>
            <input type="number" name="max-users-event" id="max-users-event" class="form-control" disabled
              v-model="company.contract.maxUsers">
          </div>
          <div class="col-md-6 col-12 form-group" v-if="company.contract.isHrp">
            <label for="max-labs-count" class="card__label">Event Labs Count</label>
            <input type="number" name="max-labs-count" id="max-labs-count" class="form-control" disabled
              v-model="company.contract.labsCount">
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-12 form-group" v-if="!isCorporate">
            <label for="total-event-hours" class="card__label" v-if="!company.contract.isHrp">Total event hours</label>
            <label for="total-event-hours" class="card__label" v-if="company.contract.isHrp">Max ticket expire
              days</label>
            <input type="number" name="total-event-hours" id="total-event-hours" class="form-control" disabled
              v-model="company.contract.totalHours">
          </div>
          <div class="col-md-6 col-12 form-group" v-if="!company.contract.isHrp && !isCorporate">
            <label for="remaining-event-hours" class="card__label">Remaining event hours</label>
            <input type="number" name="remaining-event-hours" id="remaining-event-hours" class="form-control" disabled
              v-model="company.contract.remainingHours">
          </div>
          <div class="col-md-6 col-12 form-group" v-if="company.contract.isHrp">
            <label for="remaining-event-hours" class="card__label">Max Ticket duration (in hours)</label>
            <input type="number" name="remaining-event-hours" id="remaining-event-hours" class="form-control" disabled
              v-model="company.contract.maxTicketDuration">
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-12 form-group" v-if="!company.contract.isHrp">
            <label for="max-members" class="card__label">Max company members</label>
            <input type="number" name="max-members" id="max-members" class="form-control" disabled
              v-model="company.contract.maxMembers">
          </div>
          <div class="col-md-6 col-12 form-group" v-if="company.contract.isHrp">
            <label for="max-event-lab-type" class="card__label">Event Labs Type</label>
            <input type="text" name="max-event-lab-type" id="max-event-lab-type" class="form-control"
              :value="company.contract.onPrem ? 'On Premise' : 'Using Cloud Computing'">
          </div>
          <div class="col-md-6 col-12 form-group">
            <label for="current-members" class="card__label">Current company members</label>
            <input type="number" name="current-members" id="current-members" class="form-control" disabled
              v-model="company.contract.currentMembers">
          </div>
          <div class="col-6 col-md-12 form-group" v-if="isCorporate">
            <label class="card__label" for="corporate-tokens">Corporate Tokens</label>
            <input type="text" name="corporate-tokens" id="corporate-tokens" style="font-weight: 900;"
              class="form-control text-lg-center text-success" disabled v-model="user.tokens">
          </div>
        </div>
      </form>
    </div>

    <div class="padding-bottom-20 row">
      <div class="col">
        <form autocomplete="off" class="rounded-card__item bg-gradient" @submit.prevent>
          <h2 class="main-title-1 padding-bottom-20" v-if="!isCorporate">Add new company member</h2>
          <h2 class="main-title-1 padding-bottom-20" v-else>Invite new user</h2>
          <div class="w-100" v-if="company.contract.isHrp && !isHRPUser || this.isEnterprise">
            <label for="invite-usertype" class="card__label">User Type</label>
            <select id="invite-usertype" v-model="inviteUserType" name="invite-usertype"
              class="form-control my-2 text-uppercase">
              <option v-for="(utype, index) of userTypes" :key="index" :value="utype">{{ utype }}</option>
            </select>
          </div>
          <div class="row">
            <div class="col form-group">
              <label for="invite-user" class="card__label">Username or email</label>
              <input type="text" name="invite-user" id="invite-user" class="form-control"
                :class="{ 'border border-danger': this.isEmail && this.isInvalidEmail }" placeholder="Username or email"
                v-model="uSearchTerm" @input="uSearchUpdate" @focus="setUSearchFocus(true)"
                @blur="setUSearchFocus(false)">
              <ul v-if="!isEmail" style="margin-top:60px;"
                class="header-user__search menu-dropdown position-absolute zorro"
                :class="{ active: this.uSearchIsFocus && this.uSearchTerm.length && uSearchResults.length }">
                <li v-for="uRes in uSearchResults" :key="uRes.id" @mousedown="selectUser(uRes)" class="pointer">
                  <span class="tag" :class="uRes.role === 'USER' ? 'tag-blue' : 'tag-orange'">
                    {{ uRes.role }}
                  </span>
                  <a>
                    <img :src="uRes.picture" alt="picture" class="user-avatar--thumb mr-1" />
                    <strong>
                      {{ uRes.username | truncate(18) }}
                    </strong>

                  </a>
                </li>
              </ul>
              <ul v-if="!isEmail" style="margin-top:60px;"
                class="header-user__search menu-dropdown position-absolute zorro"
                :class="{ active: this.uSearchIsFocus && this.uSearchTerm.length && !uSearchResults.length }">
                No result found
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col form-group mb-5">
              <div v-if="inviteUserSelected.username" class="d-flex align-items-center">
                <div class="tag" :class="inviteUserSelected.role === 'USER' ? 'tag-blue' : 'tag-orange'">
                  {{ inviteUserSelected.role }}
                </div>
                <div class="d-flex align-items-center">
                  <figure class="user-details__figure">
                    <img :src="inviteUserSelected.picture" alt="picture" class="user-avatar--thumb mr-1">
                  </figure>
                  <strong>{{ inviteUserSelected.username }}</strong>
                </div>
                <div class="ml-auto">
                  <strong><span @click="deselectUser()" class="pointer">&times;</span></strong>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <px-button type="submit" class="w-100 mt-2"
                :class="`px-button--${this.isEmail && this.isInvalidEmail ? 'danger disabled' : 'success'}`"
                @click.native="handleUserInvite" :disabled="!inviteUserSelected.username">
                {{ getButtonText() }}
              </px-button>
            </div>
          </div>
        </form>
      </div>
      <!-- <div class="col">
        <form autocomplete="off" class="rounded-card__item bg-gradient" @submit.prevent>
          <h2 class="main-title-1 padding-bottom-20">Add new member not yet on PWNX</h2>
          <div class="row">
            <div class="col form-group">
              <label for="ext-user-email" class="card__label">External user email</label>
              <input type="email" name="ext-user-email" id="ext-user-email" class="form-control"
                     placeholder="External user email" v-model="externalUser.email">
            </div>
          </div>
          <div class="row">
            <div class="col form-group" v-if="roles.length > 1">
              <label for="ext-user-role" class="card__label">External user role</label>
              <select type="email" name="ext-user-role" id="ext-user-role"
                      class="form-control form-control-empty"
                      v-model="externalUser.role">
                <option value="null" selected>Select the role...</option>
                <option v-for="role in roles" :value="role.value" :key="role.value">
                  {{ role.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <px-button type="submit" class="px-button--success w-100"
                         @click.native="inviteExternalUser"
                         :disabled="!(externalUser.email && externalUser.role)">
                Invite external user
              </px-button>
            </div>
          </div>
        </form>
      </div> -->
    </div>

    <div class="padding-bottom-20">
      <div class="heading heading--big">Company members</div>
      <table class="table-1">
        <thead>
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th>Role</th>
            <th>Points</th>
            <th>Active</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in company.members" :key="user.id">
            <td class="d-flex align-items-center">
              <router-link :to="'/user/' + user.id">
                <img :src="user.picture" alt="picture" class="user-avatar--thumb mr-1" />
                <strong>
                  {{ user.username }}
                </strong>
              </router-link>
            </td>
            <td>{{ user.email }}</td>
            <td>{{ user.role }}</td>
            <td>{{ user.points }}</td>
            <td>
              <div class="user__status" :class="'user__status--' + user.active"
                :title="user.active ? 'ACTIVE' : 'INACTIVE'"></div>
            </td>
            <td>
              <a v-if="user.id !== company.ownerId" @click="shouldKickUser(user)"
                class="btn btn-dark red btn-kick btn--icon btn--icon--kick">
                Kick
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="padding-bottom-20" v-if="company.contract.isHrp">
      <div class="heading heading--big">Company Tickets</div>
      <table class="table-1">
        <thead>
          <tr>
            <th>Ticket value</th>
            <th>Used By Recruiter</th>
            <th>Availability</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="ticket in companyTickets" :key="ticket.id">
            <td id="ticket-value" class="my-2 d-flex align-items-center">
              <strong>
                {{ ticket.ticket }}
              </strong>
            </td>
            <td>{{ ticket.recruiter }}</td>
            <td>
              <px-button :class="`disabled px-button${ticket.status ? '--success' : '--danger'}`"
                :title="!ticket.status ? 'AVAILABLE' : 'NOT AVAILABLE'">
                {{ ticket.status ? 'available' : 'not available' }}
              </px-button>
            </td>
          </tr>
        </tbody>
      </table>
      <h3 class="text-center text-white" v-if="companyTickets.length === 0">No ticket have been used yet</h3>
    </div>

    <div class="padding-bottom-20" v-if="!isCorporate">
      <div class="heading heading--big">Company events</div>
      <table class="table-1" v-if="company.events.length">
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Points</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="event in company.events" :key="event.id">
            <td class="d-flex align-items-center">
              <router-link :to="'/event/' + event.id">
                <strong>
                  {{ event.name }}
                </strong>
              </router-link>
            </td>
            <td>{{ event.type }}</td>
            <td>{{ event.points }}</td>
            <td>{{ event.dateStart }}</td>
            <td>{{ event.dateEnd }}</td>
            <td>{{ event.status }}</td>
          </tr>
        </tbody>
      </table>
      <!-- <div v-else class="text-center text-warning">No event found</div> -->
      <template v-if="getEventRoles().length > 0">
        <table class="table-1">
          <thead v-if="company.events.length === 0">
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Points</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
          </tbody>
        </table>
        <div @click="openCreateEventModal(evRole)" v-for="(evRole, index) of getEventRoles()" :key="index"
          class="ev-roles-row my-2 p-2 position-relative d-flex justify-content-center align-items-center pointer">
          <h2>create {{ evRole }} event</h2>
        </div>
      </template>
    </div>

  </div>
</template>

<script>
import PxButton from "@/components/PxButton";
import { mapState } from 'vuex';
import appService from "../api/app";
import companyService from "../api/company";
import hrpService from '../api/hrp';
import CompanyKickConfirm from "../components/CompanyKickConfirm";
import UserType from '../enums/user';
import httpService from "@/api/http";
import CompanyEventCreateModal from "@/components/CompanyEventCreateModal";

export default {
  name: "CompanyAdmin",
  components: {
    PxButton,
  },
  data() {
    return {
      inviteUserType: "recruiter",
      eventRoles: [],
      userTypes: ["user", "recruiter"],
      companyTickets: [],
      hrpTicketsInterval: null,
      isEmail: false,
      isInvalidEmail: false,
      company: {
        id: null,
        ownerId: null,
        name: null,
        url: null,
        picture: null,
        members: [],
        events: [],
        contract: {
          maxLabs: null,
          maxUsers: null,
          totalHours: null,
          remainingHours: null,
          maxMembers: null,
          currentMembers: null
        },
        isHrp: false,
      },
      companyChanges: {
        name: null,
        url: null,
        file: {},
      },
      uSearchTerm: "",
      uSearchIsFocus: false,
      uSearchResults: [],
      inviteUserSelected: {},
      externalUser: {
        email: null,
        role: null,
      },
      roles: [],
      events: [],
    }
  },
  beforeDestroy() {
    clearInterval(this.hrpTicketsInterval);
  },
  beforeCreate() {
    this.$parent.breadcrumbs = [{ name: "Company admin" }];
  },
  created() {
    this.getCompany();
  },
  computed: {
    isEnterprise() {
      if (!this.user.role) return false;
      return this.user.role === UserType.ENTERPRISE;
    },
    isCorporate() {
      return this.user.role !== undefined && this.user.role == UserType.CORPORATE
    },
    isHrpCompany() {
      if (!this.isEnterprise && !this.isHrp) return false;
      return true;
    },
    isHRPUser() {
      if (!this.user.role) return false;
      return this.user.role === UserType.HRPLUS
    },
    isHRUser() {
      return this.user.role === 4;
    },
    companyNameUpper() {
      if (this.company === undefined || !this.company.name) return "";
      return this.company.name.toUpperCase();
    },
    ...mapState({
      user: state => state.user
    })
  },
  methods: {
    openCreateEventModal(evRole) {
      this.$modal.show(
        CompanyEventCreateModal,
        {
          evRoles: this.getEventRoles(),
          evRoleDefault: evRole,
          createdCallback: () => {
            this.getCompany()
            this.getEventRoles()
          }
        },
        { height: 'auto' }
      )
    },
    getEventRoles() {
      const evRoles = this.eventRoles;
      if (evRoles.length === 0) return []
      const valids = []
      for (const [key, value] of Object.entries(evRoles)) {
        if (!value) continue
        valids.push(key)
      }
      return valids
    },
    handleUserInvite() {
      if (this.isEmail && !this.isInvalidEmail) this.inviteExternalUser()
      else this.invitePwnxUser()
    },
    getButtonText() {
      if (this.isInvalidEmail) return "Invalid Email"
      return this.isEmail ? "Invite External User" : "Invite User"
    },
    revokeTicket(ticketId) {
      hrpService.revokeTicket(ticketId)
        .then(response => {
          const manageableMessage = !!response.data.message;
          if (manageableMessage) {
            this.flashSuccess(response.data.message);
            this.getHrpTickets()
          }
        })
        .catch(error => {
          const manageableError = !!error.data.errorDescription && !!error.data && !!error.errorCode;
          if (manageableError) this.flashError(error.data.errorDescription);
        })
    },
    getHrpTickets() {
      hrpService.getHrpTickets()
        .then((response) => {
          const areHrpTickets = !!response.data.tickets;
          if (areHrpTickets) {
            this.companyTickets = response.data.tickets
          }
        })
    },
    getCompany() {
      this.$parent.loading = true;
      companyService.getCurrentCompany()
        .then(response => {
          this.company = response.data.company;
          this.companyChanges.name = this.company.name;
          this.companyChanges.url = this.company.url;

          const requests = [
            companyService.getNewMemberRoles(),
          ]

          if (this.isHRPUser) requests.push(hrpService.canCreateEvents())
          // return companyService.getNewMemberRoles();
          return httpService.all(requests)
        })
        .then(([response, evRoles]) => {
          this.roles = response.data.roles;
          if (evRoles) {
            this.eventRoles = evRoles.data.eventRoles;
          }
          if (this.roles.length === 1) {
            this.externalUser.role = this.roles[0].value;
          }
          if (this.company.contract.isHrp) {
            this.hrpTicketsInterval = setInterval(this.getHrpTickets, 30000);
            this.getHrpTickets()
          }
        })
        .catch(error => {
          const manageableError = !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => this.$parent.loading = false);
    },
    triggerFileInput() {
      const fileInput = document.getElementById("profile-picture");
      fileInput.click();
    },
    selectFile() {
      this.companyChanges.file = this.$refs.myFile.files[0];
      if (this.companyChanges.file) {
        const file_ext = this.companyChanges.file.name.split('.').slice(-1)[0];
        if (!["png", "jpg", "jpeg"].includes(file_ext)) {
          this.flashError("Invalid file type, choose among .png, .jpg or .jpeg file");
          this.companyChanges.file = {};
        }
      }
    },
    companyNameHasChanges() {
      return this.companyChanges.name && this.company.name !== this.companyChanges.name;
    },
    companyUrlHasChanges() {
      return this.companyChanges.url && this.company.url !== this.companyChanges.url;
    },
    updateDisabled() {
      // True if nothing has changes, False otherwise
      const nameHasChanges = this.companyNameHasChanges();
      const urlHasChanges = this.companyUrlHasChanges();
      const pictureHasChanges = !!this.companyChanges.file.name;
      return !(nameHasChanges || urlHasChanges || pictureHasChanges);
    },
    update() {
      const formData = new FormData();
      let count = 0;
      if (this.companyNameHasChanges()) {
        count += 1;
        formData.append("name", this.companyChanges.name);
      }
      if (this.companyUrlHasChanges()) {
        count += 1;
        formData.append("url", this.companyChanges.url);
      }
      if (this.companyChanges.file.name) {
        count += 1;
        formData.append("profilePicture", this.companyChanges.file);
      }
      if (count === 0) {
        this.flashError("No change detect");
      }
      this.$parent.loading = true;
      companyService.update(formData)
        .then(response => {
          const successMessage = !!response && !!response.data && !!response.data.message;
          if (successMessage) {
            this.flashSuccess(response.data.message);
          }
          this.getCompany();
        })
        .catch(error => {
          const manageableError = !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => this.$parent.loading = false)
    },
    shouldKickUser(user) {
      this.$modal.show(
        CompanyKickConfirm,
        {
          user,
          returnUserChoice: this.kickUser
        }, { height: "auto" }
      );
    },
    kickUser(shouldKick, user) {
      if (shouldKick && user) {
        this.$parent.loading = true;
        const data = {
          userId: user.id,
        };
        companyService.kickUser(data)
          .then(response => {
            const successMessage = !!response && !!response.data && !!response.data.message;
            if (successMessage) {
              this.flashSuccess(response.data.message);
            }
            this.getCompany();
          })
          .catch(error => {
            const manageableError = !!error && !!error.data && !!error.data.errorDescription;
            if (manageableError) {
              this.flashError(error.data.errorDescription);
            }
          })
          .finally(() => this.$parent.loading = false);

      }
    },
    uSearchUpdate() {
      this.isEmail = this.uSearchTerm.includes("@")
      if (this.isEmail) {
        this.uSearchResults = [];
        const pattern = /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@(?:[a-zA-Z0-9]+)\.(?:[a-zA-Z0-9]+)/i;
        const isValid = pattern.test(this.uSearchTerm.trim());
        if (!isValid) this.isInvalidEmail = true;
        else this.isInvalidEmail = false;
        return;
      }
      this.isInvalidEmail = false;
      this.uSearchTerm = this.uSearchTerm.trimLeft();
      if (this.uSearchTerm.length) {
        const data = {
          searchTerm: encodeURIComponent(this.uSearchTerm)
        };
        let userType = null
        if (this.isEnterprise) {
          userType = this.inviteUserType;
        } else if (this.isHRPUser) {
          userType = "recruiter"
        } else {
          userType = "user"
        }

        appService.uSearch(data, userType)
          .then(response => this.uSearchResults = response.data.results)
          .catch(error => {
            const manageableError = !!error && !!error.data && !!error.data.errorDescription;
            if (manageableError) {
              this.flashError(error.data.errorDescription);
            }
          });
      }
    },
    setUSearchFocus(state) {
      this.uSearchIsFocus = state;
    },
    selectUser(user) {
      this.inviteUserSelected = user;
    },
    deselectUser() {
      this.inviteUserSelected = {};
    },
    invitePwnxUser() {
      this.$parent.loading = true;
      const data = {
        userId: this.inviteUserSelected.id,
        role: this.inviteUserType
      };

      if (this.isHRPUser) data["role"] = "recruiter";
      if (this.isCorporate) data["role"] = "user";

      let addUserFunction = companyService.addCompanyUserV2;
      addUserFunction(data)
        .then(response => {
          const successMessage = !!response && !!response.data && !!response.data.message;
          if (successMessage) {
            this.flashSuccess(response.data.message);
          }

          this.getCompany();
        })
        .catch(error => {
          const manageableError = !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => {
          this.inviteUserSelected = {};
          this.uSearchTerm = "";
          this.$parent.loading = false;
        });
    },
    inviteExternalUser() {
      this.$parent.loading = true;
      // let addUserFunction = !this.isHRUser ? companyService.addExternalUser:companyService.addPwnxUserFromHR
      // if (this.company.isHrp) addUserFunction = companyService.addCompanyUserV2;

      let addUserFunction = companyService.addCompanyUserV2;

      const data = {
        email: this.uSearchTerm,
        role: this.inviteUserType,
      };
      if (this.isHRPUser) data["role"] = "recruiter";
      if (this.isCorporate) data["role"] = "user";
      addUserFunction(data)
        .then(response => {
          const successMessage = !!response && !!response.data && !!response.data.message;
          if (successMessage) {
            this.flashSuccess(response.data.message);
          }
        })
        .catch(error => {
          const manageableError = !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => {
          this.externalUser = {
            email: "",
            role: null,
          };
          this.$parent.loading = false;
        });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/colors.scss";

.heading {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  display: block;
  color: #768aa3;
  font-weight: 700;
  width: 100%;
  border-bottom: 1px solid #768aa3;
  margin-bottom: 1rem;

  &--big {
    font-size: 1.2rem;
  }
}

.no-text-transform {
  text-transform: initial;
}

.company {
  &__picture {
    margin-left: 15px; // replace col padding
  }

  &__browse-button {
    min-width: 200px;
    margin-right: 15px; // replace col padding
  }
}

.user-avatar--thumb {
  width: 3rem;
  height: 3rem;
}

.user {
  &__status {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;

    &--true {
      background-color: #0a8c3c;
    }

    &--false {
      background-color: #d94545;
    }
  }
}

.ev-roles-row {
  width: 100%;
  animation: animate-border 5s ease infinite alternate;
  transition: all 0.2s ease;
  background-color: $soft-green;
  color: white !important;
}

.ev-roles-row:hover {
  background-color: $soft-green !important;
  color: black !important;
}

@keyframes animate-border {
  100% {
    background-color: transparent;
    color: black !important;
  }
}

.zorro {
  z-index: 999;
}
</style>
