<template>
    <base-modal :title="modal.title"  :buttons="modal.buttons" :close="modal.close">
        <div>
            <p>
                In order to provide a better experience, we send notification to let the user know if any machine is going
                to be reset or to keep the user up to date with upcoming events.
            </p>
            <p>Do you want to receive these updates?</p>
            <small>You can change this preference later in your settings</small>
        </div>
    </base-modal>
</template>

<script>
    import BaseModal from "./BaseModal";

    export default {
        name: "PushNotificationPromptModal",
        components: {
          BaseModal,
        },
        props: [
            "returnUserChoice"
        ],
        data() {
            return {
                modal: {
                    title: "Greetings from PWNX",
                    buttons: [
                        {
                            name: "accept",
                            class: "btn-success",
                            action: () => this.selfClose(true)
                        },
                        {
                            name: "reject",
                            class: "btn-danger",
                            action: () => this.selfClose(false)
                        },
                    ],
                    close: () => this.selfClose(false)
                },
            }
        },
        methods: {
            selfClose(choice) {
                this.returnUserChoice(choice);
                this.$emit("close");
            }
        }
    }
</script>

<style scoped>

</style>
