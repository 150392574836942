<template>
  <div
    class="challenge-card__container"
    :class="[propClass !== '' ? propClass : '']"
  >
    <div
      class="challenge-card"
      :class="{
        rotated: shouldRotate(lab),
        completed: shouldSetCompleted(lab),
      }"
    >
      <input type="hidden" value="" id="labIpClipboard" />
      <div
        class="container-fluid challenge-card__face challenge-card__face--front"
      >
        <i
          class="fas fa-circle difficulty"
          :class="lab.difficulty"
          :title="lab.difficulty.toUpperCase()"
        ></i>
        <p class="mb-0" id="likes-counter">
          <template v-if="!lab.liked">
            {{ lab.likes }}<i @click="labLiked()" title="solved by" class="mx-2 fa-regular fa-heart pointer"></i>
          </template>
          <template v-else>
            {{ lab.likes }}<i @click="labLiked()" title="solved by" class="mx-2 text-danger fa-solid fa-heart pointer"></i>
          </template>
        </p>
        <p class="mb-0" id="solves-counter">
          {{ lab.solves }} <i title="solved by" class="ml-1 fas fa-users"></i>
        </p>
        <div class="row justify-content-center">
          <h2 v-if="!isLink" class="mt-5 mb-0">{{ lab.name }}</h2>
          <h2
            v-if="isLink"
            @click="() => $router.push(`/lab/${lab.id}`)"
            class="mt-5 mb-0"
            :style="{ cursor: 'pointer' }"
          >
            {{ lab.name }}
          </h2>
        </div>
        <div
          :id="[isPremiumLab() ? 'writeup' : 'points']"
          class="row justify-content-center mt-2 flex-column align-items-center"
          :class="[isPremiumLab() ? 'mt-2' : '']"
        >
          <p v-if="!isPremiumLab()">points: {{ lab.points }}</p>
          <slot v-if="isPremiumLab()"></slot>
        </div>
        <div
          id="ip-address"
          class="row justify-content-center"
          v-if="isOldPremium"
        >
          <p
            @click="copyToClipboard(lab.ip)"
            class="clipboard-btn pointer simple-hover mb-0"
          >
            <i class="far fa-clipboard mr-1"></i>{{ lab.ip }}
          </p>
        </div>
        <div
          id="ip-address"
          class="row justify-content-center"
          v-if="!isOldPremium"
        >
          <slot name="ip-container"></slot>
        </div>
        <div
          class="row justify-content-center align-items-center"
          v-if="isOldPremium"
        >
          <template v-if="lab.ip != 'N/A'">
            <div class="d-flex align-items-center w-100 justify-content-center flex-row">
              <input
                id="flag-text"
                class="flag-submit p-2 my-3 mx-3"
                placeholder="pwnx{...}"
                type="text"
                v-model="flag"
              />
              <span @click="getLabWriteup" id="writeup-btn" class="text-center writeup-btn py-1 px-2 my-1" >
                <i class="fas fa-pencil"></i>
              </span>
            </div>
          </template>
          <template v-else>
            <input
              class="flag-submit p-2 my-3"
              placeholder="Lab not started yet"
              disabled="disabled"
            />
          </template>
        </div>
        <div
          class="row justify-content-center align-items-center"
          v-if="!isOldPremium"
        >
          <slot name="flag-input"></slot>
        </div>
        <div class="row justify-content-center align-items-center">
          <template v-if="lab.ip !== 'N/A' && isOldPremium">
            <span
              id="vpn-btn"
              @click="downloadOvpnFile"
              class="text-center py-1 mb-3 mx-1"
              v-if="lab.ip !== 'N/A'"
              ><i class="fas fa-download"></i
            ></span>
            <span
              id="submit-btn"
              @click="submitFlag"
              class="text-center py-1 mb-3"
              v-if="lab.ip !== 'N/A'"
              >submit</span
            >
            <span
              id="reset-btn"
              @click="requestReset"
              class="text-center py-1 mb-3 mx-1"
              ><i class="fas fa-redo"></i
            ></span>
          </template>
          <template v-else>
            <span
              id="submit-btn"
              class="text-center py-1 mb-3 disabled"
              v-if="isOldPremium"
            >
              lab not started yet
            </span>
            <slot name="not-old-premium" v-if="!isOldPremium"></slot>
          </template>
        </div>
      </div>
      <div
        class="container-fluid challenge-card__face challenge-card__face--back d-flex align-items-center justify-content-center"
      >
        <p class="mb-0" id="likes-counter">
          <template v-if="!lab.liked">
            {{ lab.likes }}<i @click="labLiked()" title="solved by" class="mx-2 fa-regular fa-heart pointer"></i>
          </template>
          <template v-else>
            {{ lab.likes }}<i @click="labLiked()" title="solved by" class="mx-2 text-danger fa-solid fa-heart pointer"></i>
          </template>
        </p>
        <p class="mb-0" id="solves-counter">
          {{ lab.solves }} <i title="solved by" class="fas fa-users"></i>
        </p>
        <div class="container">
          <div class="row justify-content-center">
            <h2 v-if="!isLink">{{ lab.name }}</h2>
            <h2
              v-if="isLink"
              @click="() => $router.push(`/lab/${lab.id}`)"
              :style="{ cursor: 'pointer' }"
            >
              {{ lab.name }}
            </h2>
          </div>
          <div id="points" class="row justify-content-center">
            <p>points: {{ lab.points }}</p>
          </div>
          <div id="ip-address" class="row justify-content-center">
            <p
              @click="copyToClipboard(lab.ip)"
              class="clipboard-btn pointer simple-hover mb-0"
            >
              <i class="far fa-clipboard mr-1"></i>{{ lab.ip }}
            </p>
          </div>
          <div class="row completion flex-column align-items-center mt-3">
            <p class="mb-0">You completed this lab!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import labService from "../api/lab.js";
import userService from "../api/user.js";
import ShareLabResultModal from "../components/ShareLabResultModal";



export default {
  name: "ChallengeCard",
  data() {
    return {
      isRotated: false,
      flag: null,
    };
  },
  props: {
    isOldPremium: {
      required: false,
      type: Boolean,
      default: true,
    },
    lab: {
      type: Object,
      default: () => {},
    },
    isLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    propClass: {
      type: String,
      default: "",
    },
  },
  methods: {
    labLiked() {
      // eslint-disable-next-line vue/no-mutating-props
      this.lab.liked = !this.lab.liked;
      const likes = this.lab.liked ? this.lab.likes + 1: this.lab.likes - 1;
      // eslint-disable-next-line vue/no-mutating-props
      this.lab.likes = likes;
      labService.labLiked(this.lab.id)
    },
    getLabWriteup() {
      this.$emit("show-writeup", this.lab)
    },
    downloadOvpnFile() {
      this.$parent.loading = true;

      userService
        .getOvpn()
        .then((response) => {
          const blob = new Blob([response.data], { type: "text/plain" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "config.ovpn";
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          const manageableError =
            !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => (this.$parent.loading = false));
    },
    shouldSetCompleted(lab) {
      if (this.isOldPremium || !lab.solved) return false;
      return true;
    },
    shouldRotate(lab) {
      if (!this.isOldPremium || !lab.solved) return false;
      return true;
    },
    isPremiumLab() {
      if (this.lab.type === undefined) return false;
      if (this.lab.type === "ONESHOT") return false;
      if (this.lab.type === "NORMAL") return false;
      return true;
    },
    requestReset() {
      labService
        .resetLab(this.lab.id)
        .then((response) => {
          if (response.data.errorCode) {
            this.flashError(response.data.errorDescription);
          } else {
            this.flashSuccess(response.data.message);
          }
        })
        .catch((error) => {
          const manageableError =
            !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        });
    },
    submitFlag() {
      labService
        .submitLabFlag(this.lab.id, this.flag, "user")
        .then((response) => {
          const premiumCompleted = !!response.data.lab_type;
          if (!premiumCompleted) this.flashSuccess("Congratulations!");
          if (premiumCompleted) this.flashSuccess(response.data.message);
          // eslint-disable-next-line vue/no-mutating-props
          this.lab.solved = true;
          let modalProps = {
            labName: response.data.lab_name,
            submissionId: response.data.completed_id,
          };
          if (premiumCompleted)
            modalProps = { labType: response.data.lab_type, ...modalProps };
          this.$modal.show(ShareLabResultModal, modalProps, { height: "auto" });
        })
        .catch((error) => {
          const manageableError =
            !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        });
    },
    copyToClipboard(labIp) {
      navigator.clipboard
        .writeText(labIp)
        .then(() => {
          this.flashSuccess(`ip ${labIp} copied successfully`);
        })
        .catch(() => {
          this.flashError("something went wrong copying the ip");
        });
    },
    handleRotation() {
      this.isRotated = !this.isRotated;
    },
  },
  watch: {
    flag: function(new_) {
      const isEmpty = new_ == "";
      const element = document.getElementById("writeup-btn");
      if (isEmpty) {
        element.classList.remove("d-none");
      } else {
        element.classList.add("d-none");
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../assets/css/colors";

input {
  all: unset;
}

.challenge-card__container {
  border-radius: 5px;
  position: relative;
  min-height: 260px;
}

.challenge-card {
  position: relative;
  transition: all 0.3s, transform 1s;
  transform-style: preserve-3d;
  border-radius: 5px;
  height: 100px;

  &__header {
    border-radius: 5px;
    z-index: 5;
    position: absolute;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.7rem;
  }

  &__face {
    border-radius: 5px;
    position: absolute;
    min-width: 100%;
    left: 0;
    top: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    min-height: 260px;

    &--front,
    &--back {
      background: $neutral--medium;
    }

    &--front {
    }

    &--back {
      color: $neutral--darker;
      background: $soft-green;
      transform: rotateY(180deg);
    }
  }

  &.rotated {
    transform: rotateY(180deg);
  }

  &.completed {
    // TODO: This
  }
}

#ip-address,
#points {
  font-size: 0.8rem;
}

.flag-submit {
  width: 80%;
  background: $neutral--dark;
  border-radius: 5px;
  height: 20px;
  font-size: 0.8rem;

  &::placeholder {
    opacity: 0.2;
  }
}

#submit-btn {
  font-weight: bold;
  background: $soft-green;
  color: $neutral--darker;
  text-transform: uppercase;
  width: 40%;
  font-size: 0.7rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  outline: 1px solid $soft-green;

  &:hover {
    color: $soft-green;
    background: transparent;
  }
}

#vpn-btn {
  font-weight: bolder;
  background: #808080;
  text-transform: uppercase;
  font-size: 0.7rem;
  width: 10%;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  outline: 1px solid #808080;

  &:hover {
    background: transparent;
  }
}

#reset-btn {
  font-weight: bold;
  background: #808080;
  color: white;
  text-transform: uppercase;
  width: 10%;
  font-size: 0.7rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  outline: 1px solid #808080;

  &:hover {
    background: transparent;
  }
}

.disabled {
  background-color: rgba(0, 0, 0, 0.5) !important;
  color: white !important;
  outline: none !important;
}

.completion {
  font-size: 0.8rem;
}

#likes-counter {
  position: absolute;
  top: 20px;
  right: 60px;
  font-size: 0.8rem;
  transform: translateY(-50%);
}

.difficulty,
#solves-counter {
  position: absolute;
  top: 20px;
  font-size: 0.8rem;
  transform: translateY(-50%);
}

.difficulty {
  left: 20px;

  &.easy {
    color: $soft-green;
    text-shadow: 0px 0px 20px $soft-green;
  }

  &.medium {
    color: $soft-orange;
    text-shadow: 0px 0px 20px $soft-orange;
  }

  &.hard {
    color: $soft-red;
    text-shadow: 0px 0px 20px $soft-red;
  }
}

#solves-counter {
  right: 20px;
}


#flag-text {
  transition: all 0.5s ease;
}

#writeup-btn {
  cursor: pointer;
  transition: all 0.5s ease;
  color: $soft-green;
  border-radius: 9%;
}

#writeup-btn:hover {
  background-color: $soft-green;
  color: black;
}

</style>
