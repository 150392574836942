const UserType = Object.freeze({
    ADMIN: 1,
    USER: 2,
    RECRUITER: 3,
    HR: 4,
    CORPORATE: 5,
    ENTERPRISE: 6,
    HRPLUS: 7
})

export default UserType