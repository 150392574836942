<template>
    <base-modal :title="modal.title" :buttons="modal.buttons" :close="modal.close">
        <div class="form-group">
            <!--suppress HtmlFormInputWithoutLabel -->
            <input type="text" name="username" class="form-control" :class="{'not-valid': invalidEmail}"
                   placeholder="Search user..." v-model="searchTerm" @input="searchUser"
                   @keyup.enter="selectUserEmail"/>
            <small class="form-text text-danger" v-show="invalidEmail">Email not valid</small>
        </div>
        <div class="row">
            <div class="col-md-6 col-12">
                <a @click="shouldShowNotInvited" class="pointer btn-invite" :class="{active: !showInvited}">Not
                    invited</a>
            </div>
            <div class="col-md-6 col-12">
                <a @click="shouldShowInvited" class="pointer btn-invite" :class="{active: showInvited}">Invited</a>
            </div>
        </div>
        <div class="row justify-content-center padding-top-20 users-invited h-100 users-list" v-if="matchUsers.length">
            <div class="col-md-3 col-12 card-user text-center" v-for="user in matchUsers" :key="user.id">
                <figure class="table-1__user-avatar">
                    <img :src="user.picture"
                         :alt="user.username + ' picture'" class="table-1__user-avatar--thumb"/>
                </figure>
                <h3 class="card-user__username mb-2">{{user.username}}</h3>
                <!-- <p class="card-user__email">{{user.email}}</p> -->
                <div class="card-user__button">
                    <a @click="shouldSelect(user)" class="pointer btn-round btn-event-add white">{{isUserSelected(user) ? "-" : "+"}}</a>
                </div>
            </div>
        </div>
        <div class="row padding-top-20" v-else-if="!showInvited">
            <div class="col-12 text-justify">
                <p class="">
                    No user with the given username or email found.
                </p>
                <p class="text-green font-weight-bold">
                    But you can still add the user, he will be contacted and
                    invited.
                </p>
                <p class="text-green">
                    If the email is correct hit ENTER to add this user to Invited users.
                </p>
            </div>
        </div>
        <div class="row padding-top-20" v-else>
            <div class="col-12 text-center">
                <p>
                    No user found with the given search term.
                </p>
            </div>
        </div>
    </base-modal>
</template>

<script>
    import BaseModal from "./BaseModal";

    export default {
        name: "InviteUsersModal",
        components: {BaseModal},
        props: [
            "initUsers",
            "initChosenUsers",
            "returnChosenUsers",
        ],
        data() {
            return {
                modal: {
                    title: "Choose Users for Event",
                    buttons: [
                        {
                            name: "confirm",
                            class: "px-button--success",
                            action: () => this.confirm()
                        },
                    ],
                    close: () => this.selfClose()
                },
                users: [],
                chosenUsers: [],
                matchUsers: [],
                searchTerm: "",
                showInvited: false,
                invalidEmail: false,
            }
        },
        created() {
            this.users = [...this.initUsers];
            this.chosenUsers = [...this.initChosenUsers];
            this.matchUsers = this.users.slice(0, 24);
        },
        methods: {
            selfClose() {
                this.$emit('close')
            },
            shouldShowNotInvited() {
                this.toggleShow(false);
            },
            shouldShowInvited() {
                this.toggleShow(true);
            },
            toggleShow(bool) {
                if (bool !== this.showInvited) {
                    this.showInvited = bool;
                    this.searchTerm = "";
                    this.searchUser();
                }
            },
            isUserSelected(user) {
                return !!this.chosenUsers.filter(u => u.id === user.id).pop();
            },
            searchUser() {
                this.invalidEmail = false;
                if (this.showInvited) {
                    this.matchUsers = this.chosenUsers.filter(
                        u => u.username.includes(this.searchTerm) || u.email.includes(this.searchTerm)
                    ).slice(0, 24);
                } else {
                    this.matchUsers = this.users.filter(
                        u => u.username.includes(this.searchTerm) || u.email.includes(this.searchTerm)
                    ).slice(0, 24);
                }
            },
            selectUserEmail() {
                const userEmail = this.searchTerm;
                if (!/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:)])/.test(userEmail)) {
                    this.invalidEmail = true;
                } else {
                    this.addNewUser(userEmail);
                    this.searchTerm = "";
                    this.searchUser();
                }
            },
            shouldSelect(user) {
                if (this.showInvited) {
                    this.deSelect(user);
                } else {
                    this.select(user);
                }
            },
            select(user) {
                this.chosenUsers.push(user);
                this.users = this.users.filter(u => u.id !== user.id);
                this.searchUser();
            },
            deSelect(user) {
                this.chosenUsers = this.chosenUsers.filter(u => u.id !== user.id);
                if (user.username !== "N/A") {
                    this.users.push(user);
                }
                this.searchUser()
            },
            addNewUser(email) {
                const user = {email};
                user.picture = `${process.env.VUE_APP_API_URL}/avatar/default/pwnx-${Math.floor(Math.random() * 104)}.png`;
                user.username = "N/A";
                this.chosenUsers.push(user);
            },
            confirm() {
                const response = {
                    users: this.users,
                    chosenUsers: this.chosenUsers,
                };
                this.returnChosenUsers(response);
                this.selfClose();
            },
        }
    }
</script>

<style scoped>
    .text-green {
        color: #0a8c3c;
    }
    .users-list {
        max-height: 50vh;
        overflow: auto;
    }
</style>
