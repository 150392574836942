<template>
    <base-modal
            :title="modal.title"
            :buttons="modal.buttons"
            :close="modal.close">
        <div class="">
            <p>
                Do you really want to report the team {{ team }} ? The report request rate is limited to 1 per 30 minutes.
            </p>
        </div>
    </base-modal>
</template>

<script>
    import BaseModal from "./BaseModal";

    export default {
        name: "ReportModal",
        components: {BaseModal},
        props: [
            "team",
            "cid",
            "returnUserChoice"
        ],
        data() {
            return {
                modal: {
                    title: "Cheating report",
                    buttons: [
                        {
                            name: "report",
                            class: "px-button--success",
                            action: () => this.selfClose(true, this.cid)
                        },
                    ],
                    close: () => this.selfClose(false)
                },
            }
        },
        methods: {
            selfClose(choice, cid) {
                this.returnUserChoice(choice, cid);
                this.$emit("close");
            }
        }
    }
</script>

<style scoped>
.important {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #dc3545;
    font-weight: 700;
    text-align: justify;
}
</style>
