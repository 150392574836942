<template>
  <div :class="isCard ? 'row':''">
    <div v-for="item in items" :class="[isCard ? `col-12 col-lg-${col} py-3`:'infinite-scroll__row px-3']" :key="item.id">
      <slot name="item" v-bind:item="item"></slot>
    </div>
    <div v-if="!!items.length" v-observe-visibility="handleScrolledToBottom"></div>
  </div>
</template>

<script>
export default {
  name: "InfiniteScroll",
  props: {
    items: {
      required: true,
      type: Array
    },
    isCard: {
      type: Boolean,
      default: false
    },
    col: {
      type: String,
      default: "3"
    },
    propClass: {
      type: String,
      default: ""
    },
    elementClass: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      articles: [],
      page: null,
      lastPage: false
    }
  },
  methods: {
    handleScrolledToBottom(isVisible) {
      if (!isVisible || this.lastPage) return
      this.page++
      this.$emit("refetch", this.page)
    },
    resetPage() {
      this.page = 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";

.infinite-scroll__row {
  background: rgba($neutral--medium, $alpha: 0.5);

  &:nth-child(odd) {
    background: rgba($neutral--dark, $alpha: 0.5);
  }
}
</style>