<template>
    <div class="graph-visualizer mb-3">
        <div id="actions-container">
            <i @click="$emit('close')" class="fa-solid fa-xmark fa-2xl pointer p-1 w-100 text-right"></i>
        </div>
        <v-chart class="p-3 w-100 border-top mt-2" :options="graph"></v-chart>
    </div>
</template>
<style lang="scss" scoped>
@import "@/assets/css/colors.scss";

.graph-visualizer {
    position: relative;
    background-color: $neutral--dark;
    border-radius: 0% !important;
    margin: 0px !important;
    border: 2px solid $neutral--dark;
    overflow: hidden;
    text-align: right;
}
</style>
<script>
import ECharts from "vue-echarts";

export default {
    name: "GraphVisualizer",
    components: {
        "v-chart": ECharts
    },
    data() {
        return {
        }
    },
    props: {
        graph: {
            type: Object,
            required: true
        }
    }
}
</script>
