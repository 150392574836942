<template>
  <div class="container-fluid no-bg">
    <div class="row align-items-center justify-content-end justify-content-md-between mx-1 mb-4">
      <div class="col-md-6">
        <h1 v-responsive.md.lg.xl class="main-title-1">Edit user: {{ isEditingUsername ?
          newRequestingUsername : user.username }}</h1>
      </div>
      <div class="col-6 text-right pr-0">
        <px-button class="px-button--secondary outline icon-left" icon="left-arrow" @click.native="goBack">
          Back
        </px-button>
      </div>
    </div>
    <div class="row user-edit bg-dark mx-1" v-if="userChanges.load">
      <div class="col-12 col-md-4 px-0 m-0">
        <px-card class="px-card--neon py-4 green glow left bg-darker">
          <p class="text-center mb-4 card__label">profile picture</p>
          <div class="row d-flex justify-content-center">
            <input id="fileUpload" type="file" hidden @change="onUploadChange">
            <figure class="profile-picture" :style="`background-image:url(${user.picture})`" @click="uploadImage">
            </figure>
          </div>
          <div class="row mt-2 d-flex justify-content-center">
            <label class="sidebar-left__switch">
              <input type="checkbox" class="" id="gravatar" v-model="useGravatar" @click="toggleGravatar" />
              <span class="sidebar-left__slider round"></span>
            </label>
            <label class="ml-2">Use Gravatar</label>
          </div>
        </px-card>
        <px-card class="bg-darker">
          <p class="mb-0 card__label">username</p>
          <div class="username-container row" v-if="!isEditingUsername">
            <div class="col-11">
              <p>{{ user.username }}</p>
            </div>
            <div class="col-1 pencil-container d-flex justify-content-center align-items-center"
              v-if="!isEditingUsername" @click="toggleUsernameEditingMode">
              <i class="fa-solid fa-pencil"></i>
            </div>
          </div>
          <div class="username-edit form-group row m-0 p-0 my-3" v-if="isEditingUsername">
            <div class="col-12 m-0 p-0 px-1">
              <input maxlength="40" autocomplete="none" @input="sanitizeRequestingUsername"
                v-model="newRequestingUsername" type="text" placeholder="type your new username here"
                class="form-control border border-primary" @keyup.enter="updateUsername">
            </div>
            <div class="col-12 mt-2 m-0 p-0 d-flex flex-row w-100 justify-content-center align-items-center"
              style="min-height: 100%!important;">
              <px-button class="px-button--success w-100 m-0 p-0 outline" style="min-height: 100%!important;"
                @click.native="updateUsername">
                <i class="fa-solid fa-check"></i>
              </px-button>
              <px-button class="px-button--danger w-100 m-0 p-0 ml-1 outline" style="min-height: 100%!important;"
                @click.native="toggleUsernameEditingMode">
                <i class="fa-solid fa-xmark"></i>
              </px-button>
            </div>
          </div>
          <div class="emai-container">
            <p class="mb-0 card__label">e-mail</p>
            <p>{{ user.email }}</p>
          </div>
        </px-card>
        <px-card class="py-5 bg-darker">
          <p class="m-3 card__label">linkedin username</p>
          <input type="text" name="linkedin" id="linkedin" class="form-control" v-model="userChanges.linkedin"
            autocomplete="off" />
          <p class="m-3 card__label">twitter username</p>
          <input type="text" name="twitter" id="twitter" class="form-control" autocomplete="off"
            v-model="userChanges.twitter" />
        </px-card>
        <template v-if="getActiveSubscriptions().length > 0">
          <px-card v-for="(pkg, index) of getActiveSubscriptions()" :key="index" class="py-5 bg-darker">
            <p class="card__label">premium subscriptions</p>
            <div class="d-flex flex-column my-2">
              <p class="m-3 card__label">name:</p>
              <input type="text" disabled class="bg-dark form-control" :value="pkg.premium.name">
            </div>
            <div class="d-flex flex-column my-2">
              <p class="m-3 card__label">description:</p>
              <input type="text" disabled class="bg-dark form-control" :value="pkg.premium.description">
            </div>
            <div class="d-flex flex-column my-2">
              <p class="m-3 card__label">type:</p>
              <input type="text" disabled class="bg-dark form-control" :value="pkg.premium.type">
            </div>
            <div class="d-flex flex-column my-2">
              <p class="m-3 card__label">start:</p>
              <input type="text" disabled class="bg-dark form-control" :value="pkg.start_date">
            </div>
            <div class="d-flex flex-column my-2">
              <p class="m-3 card__label">end:</p>
              <input type="text" disabled class="bg-dark form-control" :value="pkg.end_date">
            </div>
            <div class="d-flex flex-column my-2">
              <px-button class="px-button--danger outline d-flex justify-content-center align-items-center"
                @click.native="cancelSubscription(pkg.id)" v-if="!subscriptionCancelConfirm">
                <i class="fa-solid fa-xmark"></i>deactivate
              </px-button>
              <h2 class="fw-bold text-center p-2" v-if="subscriptionCancelConfirm">Are you sure?</h2>
              <div class="d-flex flex-row justify-content-center align-items-center" v-if="subscriptionCancelConfirm">
                <px-button @click.native="cancelSubscription(pkg.id)" class="px-button--success mx-1 w-100">
                  <i class="fa-solid fa-face-sad-cry"></i>yes
                </px-button>
                <px-button @click.native="cancelSubscription(pkg.id, 'no')" class="px-button--danger mx-1 w-100">
                  <i class="fas fa-smile"></i>no
                </px-button>
              </div>
            </div>
            <hr v-if="index != $parent.user.premium.length - 1" />
          </px-card>
        </template>
      </div>
      <div class="col-12 col-md-8 p-0 m-0 d-flex flex-column justify-content-between flex-wrap-reverse bg-dark">
        <px-card class="bg-dark">
          <form autocomplete="off" @submit.prevent>
            <div class="row m-3">
              <div class="col-12 mb-4 mb-md-0 col-md-6">
                <p class="card__label">Country</p>
                <px-select-country :selected="userChanges.contacts.country"
                  @triggerChange="getSelectedCountry"></px-select-country>
              </div>
              <div class="col m-0">
                <!-- email -->
                <div :class="{ 'mb-2': userChanges.contacts.email }">
                  <label class="sidebar-left__switch">
                    <input type="checkbox" class="sidebar-event--button" id="contact-email"
                      v-model="userChanges.contacts.email">
                    <span class="sidebar-left__slider round"></span>
                  </label>
                  <label class="ml-2" for="contact-email"> Show email</label>
                </div>

                <!-- linkedin -->
                <div class="mb-2">
                  <label class="sidebar-left__switch">
                    <input type="checkbox" class="sidebar-event--button" id="contact-linkedIn"
                      v-model="userChanges.contacts.linkedin">
                    <span class="sidebar-left__slider round"></span>
                  </label>
                  <label class="ml-2" for="contact-linkedIn">
                    Show LinkedIn
                  </label>
                </div>

                <!-- twitter -->
                <div class="mb-3">
                  <label class="sidebar-left__switch">
                    <input type="checkbox" class="sidebar-event--button" id="contact-twitter"
                      v-model="userChanges.contacts.twitter">
                    <span class="sidebar-left__slider round"></span>
                  </label>
                  <label class="ml-2" for="contact-twitter">
                    Show Twitter
                  </label>
                </div>
              </div>
            </div>
            <!-- contact information -->
          </form>
          <template v-if="userData.inCorporate">
            <h2 class="main-title-2 mb-5">Company Configuration</h2>
            <form @submit.prevent class="row m-0 p-0">
              <div class="form-group col-6 m-0 p-0">
                <label for="frequency_ms">Learning Path Notification Delay</label>
                <select class="form-control" name="frequency_ms" id="frequency_ms" v-model="uFrequency">
                  <option :value="freq" :key="idx" v-for="(freq, idx) of frequencies">{{ freq }} min</option>
                </select>
              </div>
              <px-button @click.native="updateCompanyConf()"
                class="col-12 px-button--success outline m-0 p-0 mt-3">update</px-button>
            </form>
          </template>
        </px-card>
        <px-card class="bg-dark">
          <div class="row d-flex justify-content-end">
            <px-button class="px-button--success icon-left" icon="edit" @click.native="update"
              :disabled="updateDisabled()">
              Update
            </px-button>
          </div>
        </px-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import userService from "../api/user";
import PxButton from "../components/PxButton";
import PxCard from "../components/cards/PxCard";
import PxSelectCountry from "@/components/PxSelectCountry";
import premiumService from "@/api/premium.js";
import companyService from "@/api/company.js";
import UserType from "@/enums/user.js";

export default {
  name: "UserEdit",
  components: {
    PxButton,
    PxCard,
    PxSelectCountry
  },
  computed: {
    isUser() {
      return this.userData.role === UserType.USER;
    },
    ...mapState({
      userData: state => state.user
    })
  },
  data() {
    return {
      uFrequency: 15,
      frequencies: [
        60,
        30,
        15,
      ],
      isEditingUsername: false,
      subscriptionCancelConfirm: false,
      newRequestingUsername: null,
      allowedChars: Array(0).concat(
        Array(26).fill(null).map((_, i) => String.fromCharCode(65 + i)),
        Array(26).fill(null).map((_, i) => String.fromCharCode(97 + i)),
        Array(10).fill(null).map((_, i) => String(i)),
        ["-", "_", "."]
      ),
      userId: this.$route.params.userId,
      user: {},
      userChanges: {
        load: false,
        contacts: {
          linkedin: null,
          twitter: null,
          email: null,
          country: null,
        }  // note: avoid reference on undefined
      },
      file: {},
      useGravatar: false,
      profilePicChanges: {
        useGravatar: false,
        hasProfilePic: true,
      }
    }
  },
  created() {
    this.$parent.breadcrumbs = [{ name: "profile", navigation: `/users/${this.userId}` }, { name: "edit" }];

    if (!(this.userId === this.userData.id)) {
      this.$store.dispatch("user/logout");
      this.$router.push("/login");
      return;
    }
    this.$parent.loading = true;
    userService.getUser(this.userId)
      .then(response => {
        this.user = response.data.user;
        this.userChanges = JSON.parse(JSON.stringify(this.user));
        this.userChanges.load = true;

        if (this.userData.inCorporate) {
          this.uFrequency = this.user.companyConfig.frequency;
        }

        if (this.user.picture.includes("gravatar")) {
          this.useGravatar = true;
          this.profilePicChanges.useGravatar = true;
          this.profilePicChanges.hasProfilePic = false;
        }
      })
      .catch(error => {
        const manageableError = !!error && !!error.data && !!error.data.errorDescription;
        if (manageableError) {
          this.flashError(error.data.errorDescription);
        }
      })
      .finally(() => this.$parent.loading = false)
  },
  methods: {
    async updateCompanyConf() {
      const frequency = this.uFrequency;
      this.$parent.loading = true;

      const data = {
        "frequency_ms": frequency,
      }

      await companyService.updateCompanyConfig(data)
        .then((response) => {
          const manageable = !!response.data && !!response.data.message;

          if (manageable) {
            this.flashSuccess(response.data.message);
          }
        })
        .finally(() => {
          this.$parent.loading = false;
        })
    },
    updateUsername() {
      const username = this.newRequestingUsername;
      if (username.length === 0) return;

      this.$parent.loading = true;

      userService.updateUsername({ username: username })
        .then((response) => {
          const manageableMessage = !!response.data && !!response.data.message
          if (manageableMessage) {
            this.flashSuccess(response.data.message);
            window.location.reload();
          }
        })
        .catch((err) => {
          let error = null;
          if (!err.data) {
            error = err.response;
          } else {
            error = err;
          }

          const manageableError = !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription)
          }
        })
        .finally(() => {
          this.$parent.loading = false;
        })
    },
    sanitizeRequestingUsername() {
      const allowed = this.allowedChars;
      let username = this.newRequestingUsername;
      const latestChar = username[username.length - 1];

      if (allowed.some(char => char === latestChar)) {
        return;
      }

      this.newRequestingUsername = username.slice(0, username.length - 1);
    },
    toggleUsernameEditingMode() {
      this.isEditingUsername = !this.isEditingUsername;
    },
    getActiveSubscriptions() {
      const premiums = this.$parent.user.premium;
      return premiums.filter(pkg => pkg.subscription_status === "ACTIVE")
    },
    cancelSubscription(pkgId, answer = null) {
      if (!this.subscriptionCancelConfirm) {
        this.subscriptionCancelConfirm = true;
        return
      }

      if (this.subscriptionCancelConfirm && answer === "no") {
        this.subscriptionCancelConfirm = false;
        return
      }

      this.$parent.loading = true;
      premiumService.cancelPremium({ "pkg_id": pkgId }).then((response) => {
        const manageableMessage = !!response.data && !!response.data.message;
        if (manageableMessage) this.flashSuccess(response.data.message);
      }).catch((error) => {
        const manageableError = !!error.data && !!error.data.errorDescription;
        if (manageableError) this.flashError(error.data.errorDescription);
      }).finally(() => {
        this.$parent.loading = false;
      })
    },
    getSelectedCountry(country) {
      this.userChanges.contacts.country = country;
    },
    selectedFile(selectedFile) {
      this.file = selectedFile;

      if (this.file) {
        this.useGravatar = false;
        const file_ext = this.file.name.split('.').slice(-1)[0];

        if (!["png", "jpg", "jpeg"].includes(file_ext)) {
          this.flashError("Invalid file type, choose among .png, .jpg or .jpeg");
          this.file = null;
        }
      } else {
        this.file = null;
      }
    },
    updateDisabled() {
      const props = Object.keys(this.user);
      for (const prop of props) {
        if (prop === "contacts") {
          for (const contact of Object.keys((this.user.contacts))) {
            if (this.userChanges.contacts[contact] !== this.user.contacts[contact]) {
              return false;
            }
          }
        } else if (this.userChanges[prop] !== this.user[prop]) {
          return false;
        }
      }
      // Check profile pic
      const gravatarChanged = this.useGravatar !== this.profilePicChanges.useGravatar;
      const profilePicChanged = !!this.file.name;
      return !(gravatarChanged || profilePicChanged);


    },
    update() {
      const formData = new FormData();
      for (const key in this.userChanges) {
        if (!["username", "email", "daysMember", "id", "picture", "points"].includes(key)) {
          if (key !== "contacts") {
            formData.append(key, this.userChanges[key]);
          } else {
            for (const sk in this.userChanges[key]) {
              formData.append(`${key}.${sk}`, this.userChanges[key][sk])
            }
          }
        }
      }
      let profilePicture = null;
      if (this.useGravatar) {
        profilePicture = this.user.email;
      } else if (this.file.name) {
        profilePicture = this.file;
      }
      formData.append("profilePicture", profilePicture);

      this.$parent.loading = true;
      userService.update(this.userId, formData)
        .then(() => {
          this.flashSuccess("Data successfully updated!");
          this.$store.dispatch('user/getCurrentUser');
          this.$router.push({ name: 'UserDetails', params: { userId: this.userId } });

        })
        .catch(error => {
          const manageableError = !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => this.$parent.loading = false)
    },
    toggleGravatar() {
      this.useGravatar = !this.useGravatar;
      if (this.useGravatar && this.file) {
        this.file = {};
      }
    },
    uploadImage() {
      document.getElementById("fileUpload").click()
    },
    onUploadChange(e) {
      let image = e.target.files[0]
      this.user.picture = URL.createObjectURL(image)
      this.selectedFile(image)
    },
    test() {
    }
  },
  watch: {
    'newRequestingUsername': function (value) {
      if (!value || value === "") {
        this.newRequestingUsername = null;
        return;
      }
    },
    'isEditingUsername': function (value) {
      if (!value) {
        this.newRequestingUsername = null;
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/css/colors";


.profile-picture {
  height: 120px;
  width: 120px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  border: 5px solid $neutral--dark;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    &::after {
      opacity: .5;
    }
  }

  &::after {
    content: "edit";
    opacity: 0;
    transition: all .2s;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    align-content: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: black;
  }
}

.px-card {
  margin: 0;
  padding: 5%;
  border: 1px solid rgba(255, 255, 255, 0.04);
}

input:focus,
textarea:focus,
select:focus,
a:focus {
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pencil-container {
  cursor: pointer;
  filter: grayscale(100%);
  transition: all 0.1s ease-in-out;
}

.pencil-container:hover {
  filter: grayscale(0%);
  color: $soft-green;
  transition: all 0.1s ease-in-out;
}
</style>
