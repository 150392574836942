<template>
  <base-modal
    :title="modal.title"
    :buttons="modal.buttons"
    :close="modal.close"
  >
    <div class="loading-spinner-wrapper" v-if="loading">
      <div class="circleloader-container">
        <div class="circleloader"></div>
      </div>
    </div>
    <template v-if="dockerLogs != null">
      <div
        id="logs-container"
        style="max-height: 400px; overflow-y: scroll; background-color: black; color: white;"
        @scroll="reloadLogs(true)"
      >
        <div v-for="(log, index) in dockerLogs" :key="index">
          {{ purifyText(log) }}
          <br />
        </div>
      </div>
    </template>
    <template v-else>
      <div v-if="isParticipating">
        <h2>No logs available</h2>
      </div>
      <div v-if="!isParticipating">
        <h2>Log retention is guaranteed for 7 days</h2>
      </div>
    </template>
  </base-modal>
</template>
<script>
import { sanitize } from "dompurify";
import recruiterService from "../api/recruiter";
import BaseModal from "./BaseModal";

export default {
  name: "DockerLogsModal",
  data() {
    return {
      loading: false,
      reloadTimeout: null,
      isParticipating: false,
      isLoading: true,
      page: 0,
      modal: {
        title: "Logs",
        buttons: [
          {
            name: "Close",
            class: "px-button--danger",
            action: this.selfClose,
          },
        ],
        close: this.selfClose,
      },
      dockerLogs: null,
      isToBottom: false,
    };
  },
  props: {
    eventId: {
      type: String,
      required: true,
    },
    labId: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  components: { BaseModal },
  created() {
    this.reloadLogs();
  },
  methods: {
    isBottom() {
      const container = document.getElementById("logs-container");
      if (!container) return;
      const height = Number.parseInt(container.style.maxHeight.split("px")[0])
      this.isToBottom = Math.floor(container.scrollHeight - container.scrollTop) === height
      return this.isToBottom
    },
    selfClose() {
      this.$emit("close");
    },
    reloadLogs(force = false) {
      const eventId = this.eventId;
      const labId = this.labId;
      const userId = this.userId;
      if (this.reloadTimeout) clearTimeout(this.reloadTimeout);
      this.reloadTimeout = setTimeout(() => {
        this.loading = true;
        if (this.isBottom() || force) this.page += 1;
        recruiterService
          .getDockerLogs(eventId, labId, userId, this.page)
          .then((response) => {
            const logsLength = response.data.logs.length;

            this.dockerLogs = response.data.logs;
            this.isParticipating = response.data.participating;

            if (response.data.logs.length === 0) this.dockerLogs = null;
            if (logsLength >= 1000)
              this.dockerLogs = this.dockerLogs.slice(
                logsLength - 1000,
                logsLength
              );
          })
          .catch((error) => {
            const manageableError = !!error.data && !!error.data.errorDescription;
            if (manageableError) {
              this.flashError(error.data.errorDescription);
            }
            this.selfClose();
          })
          .finally(() => {
            this.loading = false
            const container = document.getElementById("logs-container");
            if (container) container.scrollTo(0, container.scrollHeight);
          });
      }, 1000);
    },
    purifyText(text) {
      return sanitize(text);
    }
  },
  watch: {
    isToBottom: function(new_) {
      if (new_) {
        this.reloadLogs(true)
      }
    },
    isParticipating: function(new_) {
      if (!new_ && this.logs.length === 0) {
        console.log("clearing timeout");
        clearTimeout(this.reloadTimeout)
      }
    }
  }
};
</script>
<style lang="scss">
@import "../assets/css/colors.scss";

.loading-spinner-wrapper{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
}

.circleloader-container {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  // padding: 30px;
  width: 100%;
  height: 140px;
}

.circleloader {
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  border-radius: 16px;
  width: 80px;
  height: 20px;
  padding: 4px;
  transform: translate(-50%, 50%);
  background: rgba(255, 255, 255, 0.4);
  
  &:before {
    content: '';
    position: absolute;
    border-radius: 16px;
    width: 20px;
    height: 12px;
    left: 0;
    background: #FFF;
    animation: push 1s infinite linear;    
  }
}

@keyframes push {
  50% {
    left: 56px;
  }
}

</style>
