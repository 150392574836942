<template lang="html">
  <div class="container-fluid">
    <div class="container-fluid">
      <div class="row justify-content-end">
        <h1 class="main-title-1 margin-right-20">Notifications</h1>
        <px-button class="px-button--secondary outline" @click.native="clearNotifications">clear</px-button>
      </div>
      <div v-for="notification in this.getVisibleNotifications()" :key="notification.id">
        <div class="notification__row row my-3 p-3"
          :class="`notification__row--${getNotificationStyle(notification.priority)}`">
          <div class="col-1 d-flex">
            <div class="col d-flex align-items-center justify-content-center">
              <i v-if="notification.type != 7" class="fa" :class="`fa-${getNotificationIcon(notification.type)}`"></i>
              <i v-if="notification.type == 7" class="fa-solid fa-user-plus"></i>
            </div>
          </div>
          <div class="col-11 p-2">
            <div class="row w-100">
              <div class="col-8">
                <h2 class="mb-0">{{ notification.title }}</h2>
              </div>
              <div class="col-4 pr-0 text-right">
                <p class="notification__row--time mb-0">{{
                  new Date(notification.created + "Z").toLocaleString()
                  }}</p>
              </div>
            </div>
            <div class="row w-100 mt-2">
              <div class="col" v-show="showButtons">
                {{ notification.message }}
              </div>
            </div>
            <div class="row">
              <div class="col mt-3 d-flex align-items-center">
                <p class="mb-0 button--cancel-reset" @click="cancelLabReset(notification.reset_id)"
                  v-if="notification.reset_id != null && (notification.user_id != cur_user.id && !cancelList.includes(notification.reset_id))">
                  <i class="fas fa-times-circle mr-2"></i>Cancel reset
                </p>
                <a href="javascript:void(0)" @click="linkedinShare(notification.completed)"
                  v-if="notification.type == 6 && notification.completed != null && notification.completed.user_id == cur_user.id">
                  <i class="fab fa-linkedin"></i>
                </a>
                <a href="javascript:void(0)" class="ml-1" @click="twitterShare(notification)"
                  v-if="notification.type == 6 && notification.completed != null && notification.completed.user_id == cur_user.id">
                  <i class="fab fa-twitter"></i>
                </a>
                <div
                  v-if="notification.event_reset_id != null && (notification.user_id != cur_user.id && eventLabCancelList.includes(notification.event_reset_id) == false)">
                  <px-button class="px-button--danger" @click.native="cancelEventLabReset(notification.event_reset_id)">
                    <span>Reject</span>
                  </px-button>
                  <!-- <px-button class="px-button--success"
                                 @click.native="notification.event_reset_id ? acceptEventLabReset(notification.event_reset_id) : null">
                        <span>Ignore</span>
                      </px-button> -->
                </div>
                <div v-if="notification.type == 7" class="d-flex flex-row">
                  <px-button @click.native="handleJoin(notification.token, notification.team_id, 'accept')"
                    class="px-button--success outline">accept</px-button>
                  <px-button @click.native="handleJoin(notification.token, notification.team_id, 'reject')"
                    class="px-button--danger ml-1 outline">reject</px-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PxButton from "@/components/PxButton";
import eventService from '../api/event'
import labService from '../api/lab';
import userService from '../api/user';
import teamService from "@/api/team";

export default {
  name: "Notifications",
  components: {
    PxButton
  },
  data() {
    return {
      cur_user: {},
      personalNotifications: [],
      globalNotifications: [],
      teamNotifications: [],
      cancelList: [],
      eventLabCancelList: [],
      showButtons: false,
    }
  },
  methods: {
    handleJoin(token, teamId, action = "accept") {
      this.$parent.loading = true;
      const endpoint = action == "accept" ? teamService.acceptJoin : teamService.rejectJoin
      endpoint(token)
        .then((response) => {
          const manageableMessage = !!response.data && !!response.data.message
          if (manageableMessage) {
            this.flashSuccess(response.data.message);
            setTimeout(() => {
              this.$router.push(["/team", teamId].join("/"))
            }, 1000)
          }
        })
        .catch((err) => {
          const error = err.response;
          const manageableError = !!error.data && !!error.data.errorDescription
          if (manageableError) {
            this.flashError(error.data.errorDescription)
          }
        })
        .finally(() => {
          this.$parent.loading = false;
        })
    },
    linkedinShare(completed) {
      let badge_url = encodeURIComponent(process.env.VUE_APP_API_URL + "/badge?completion_id=" + completed.id);

      window.open("https://www.linkedin.com/sharing/share-offsite/?url=" + badge_url, "_blank");
    },
    twitterShare(notification) {
      let badge_url = encodeURIComponent(process.env.VUE_APP_API_URL + "/badge?completion_id=" + notification.completed.id);
      let title = encodeURIComponent(notification.title)
      let hashtags = "pwnx,labcompleted,pwning"
      window.open(`http://twitter.com/share?text=${title}&url=${badge_url}&hashtags=${hashtags}`, "_blank");
    },
    alreadyCanceled(reset_id) {
      return this.cancelList.includes(reset_id)
    },
    cancelLabReset(reset_id) {
      labService.cancelReset(reset_id).then(() => {
        this.cancelList.push(reset_id);
        this.flashSuccess("Reset cancel request has been sent!");
      });
    },
    cancelEventLabReset(reset_id) {
      eventService.eventLabResetReject(reset_id).then(() => {
        this.eventLabCancelList.push(reset_id);
        this.flashSuccess("Reset rejected")
      })
    },
    acceptEventLabReset(reset_id) {
      eventService.eventLabResetAccept(reset_id).then(() => {
        this.eventLabCancelList.push(reset_id);
        this.flashSuccess("Reset ignored");
      }).catch(error => {
        const manageableError = !!error && !!error.data && !!error.data.errorDescription;
        if (manageableError) {
          this.eventLabCancelList.push(reset_id);
          this.flashError(error.data.errorDescription);
        }
      })
    },
    getNotificationIcon(type) {
      const icons = ["info", "flag", "calendar", "newspaper", "bell", "info", "flag"]
      return icons[type]
    },
    getNotificationStyle(priority) {
      const priorities = ["low", "medium", "high", "critical"]
      return priorities[priority - 1]
    },
    setNotificationTime() {
      let time = new Date().getTime()
      localStorage.setItem("notificationClearTime", time)
    },
    clearNotifications() {
      this.setNotificationTime()
      this.$parent.getNotifications()
    },
    getVisibleNotifications() {
      return this.$parent.notifications.filter(el => new Date(el.created + "Z").getTime() >= localStorage.getItem("notificationClearTime"))
    },
  },
  beforeCreate() {
    localStorage.setItem("lastNotificationCheck", new Date().getTime())
    // this.personalNotifications = this.notifications.filter(el => el.scope === 0)
    // this.globalNotifications = this.notifications.filter(el => el.scope === 1)
    // this.teamNotifications = this.notifications.filter(el => el.scope === 2)
  },
  created() {
    this.$parent.loading = true;
    userService.getCurrentUser().then(user => {
      this.cur_user = user.data.user;
    }).then(() => {
      userService.getEventLabResets().then(response => {
        this.eventLabCancelList = response.data.length > 0 ? response.data[0] : [];
      })
    }).then(() => {
      this.$parent.loading = false;
      this.showButtons = true;
    })
  }
};
</script>
<style lang="scss">
@import "../assets/css/colors";

$map_notification_colors: (
  "low": $soft-green,
  "medium": $soft-yellow,
  "high": $soft-orange,
  "critical": $soft-red
);

h2 {
  text-transform: uppercase;
  //font-weight: bold;
  font-size: 1rem;
}

.notification__row {
  background: $neutral--medium;
  color: $neutral--light;

  i {
    font-size: 1.1rem;
  }

  &--time {
    font-size: .8rem;
    opacity: .8;
  }

  @each $name,
  $color in $map_notification_colors {
    &--#{$name} {
      border-right: 4px solid $color;

      h2,
      i {
        color: $color;
      }
    }
  }
}

.button--cancel-reset {
  z-index: 2;
  cursor: pointer;
  transition: all .3s;

  i {
    color: $soft-red !important;
    position: relative;

    &:after {
      content: "";
      width: 0%;
      height: 0%;
      background: rgba($soft-red, .5);
      position: absolute;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      transition: all .3s;
    }
  }

  &:hover {
    color: $soft-red !important;
    text-decoration: underline;

    i:after {
      height: 150%;
      width: 150%;
    }
  }
}
</style>
