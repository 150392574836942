<template>
  <div class="container-fluid table vh-100">
    <div class="row">
      <div class="col-2">
        <h1 class="main-title-1 margin-right-20">Challenges</h1>
      </div>
      <div class="col-10 d-flex justify-content-end">
        <div class="col-6 text-right mx-0 px-0 pr-1 col-lg-6 d-flex align-items-center justify-content-end">
          <px-button 
            v-if="isUser()" 
            @click.native="downloadOvpnFile()"
            v-responsive.md.lg.xl 
            class="px-button--success outline mr-1">
            <i class="mr-1 fas fa-download"></i>
            VPN CONFIG
          </px-button>
          <px-button
            @click.native="goBack()"
            class="px-button--secondary icon-left outline"
            icon="left-arrow"
          >
            back
          </px-button>
        </div>
      </div>
    </div>
    <input type="hidden" value="" id="labIpClipboard" />
    <div class="row banners">
      <div class="col-md-12 col-lg-7 my-2">
        <px-card class="banner__card h-100 shadow d-flex align-items-center">
          <div class="container">
            <px-card
              class="banner px-card--neon green left glow bg-transparent"
            >
              <div class="banner__content">
                <div class="row">
                  <div class="col-lg-12 col-xl-7">
                    <h3>Latest challenge</h3>
                    <h5>{{ this.upcomingLab.name }}</h5>
                    <p>
                      {{ this.upcomingLab.description }}
                    </p>
                  </div>
                </div>
              </div>
            </px-card>
            <div class="row d-flex justify-content-end">
              <v-chart
                :options="dailySubs"
                autoresize
                class="banner__graph"
              ></v-chart>
              <div class="col-md-5 banner__first-blood text-right">
                <h3 class="m-0">first blood</h3>
                <p
                  v-if="firstBlood"
                  class="m-0 simple-hover"
                  @click="$router.push('/user/' + firstBlood.user_id)"
                >
                  {{ firstBlood.username }}
                </p>
              </div>
            </div>
          </div>
        </px-card>
      </div>
      <div class="col-md-12 col-lg-5 my-2">
        <px-card class="banner__card h-100 shadow d-flex align-items-center">
          <div class="container">
            <px-card
              class="banner px-card--neon animate orange left glow bg-transparent"
            >
              <div class="banner__content">
                <div class="row">
                  <div class="col text-lg-left">
                    <h3>Upcoming Event</h3>
                    <div v-if="upcomingEvent">
                      <h4>{{ this.upcomingEvent.name }}</h4>
                      <p>
                        Event {{ this.upcomingEvent.name }} will start on
                        {{
                          new Date(
                            this.upcomingEvent.date_start
                          ).toLocaleString()
                        }}
                      </p>
                      <px-button
                        class="px-button--orange outline"
                        @click.native="
                          $router.push('/event/' + upcomingEvent.id)
                        "
                        >event details
                      </px-button>
                    </div>
                    <div v-else>
                      <p>No upcoming event, stay tuned!</p>
                    </div>
                  </div>
                </div>
              </div>
            </px-card>
          </div>
        </px-card>
      </div>
    </div>
    <div class="padding-top-20 mb-5">
      <px-table>
        <div
          v-responsive.lg.xl
          class="row table-header mb-3 d-flex align-items-center justify-content-between mx-1"
        >
          <div class="col-6 col-md-1 h-100 py-3 d-flex align-items-center">
            <h2 class="mb-0">name</h2>
          </div>
          <div
            v-responsive.md.lg.xl
            class="col-1 h-100 py-3 d-flex align-items-center text-center"
            v-if="canSeeFlags"
          >
            <h2 class="mb-0">pwnd</h2>
          </div>
          <div class="col-5 col-md-2 h-100 py-3 d-flex align-items-center">
            <h2 class="mb-0">difficulty</h2>
          </div>
          <div
            v-responsive.md.lg.xl
            class="col-2 h-100 py-3 d-flex align-items-center"
          >
            <h2 class="mb-0">points</h2>
          </div>
          <div
            v-responsive.md.lg.xl
            class="col-2 h-100 py-3 d-flex align-items-center"
            v-if="canSeeFlags"
          >
            <h2 class="mb-0">actions</h2>
          </div>
        </div>
        <InfiniteScroll
          ref="infiniteScroll"
          :items="labs"
          @refetch="loadLabs"
          v-if="labs.length > 0"
        >
          <template v-slot:item="lab">
            <div
              class="row table__row justify-content-between align-content-center my-3 p-0 p-lg-2 my-lg-0"
            >
              <div
                v-responsive.xs.sm.md
                @click="$parent.$router.push(`/lab/${lab.item.id}`)"
                class="container-fluid p-3"
              >
                <div class="row py-2 align-items-center">
                  <div class="col-6 d-flex align-items-center lab__name">
                    <span
                      class="lab__flag-status mr-2"
                      :class="{
                        flag2: lab.item.flags.root,
                        flag: lab.item.flags.user,
                      }"
                    >
                    </span>
                    <div>
                      <h2 class="mb-0 mr-2">
                        {{ lab.item.name | truncate(15) }}
                      </h2>
                      <p class="mb-0" v-if="isLabStarting(lab.item.id) && !isLabStarted(lab.item.id) && !ipValid(lab.item.ip)">starting up</p>
                      <p class="mb-0" v-if="!isLabStarting(lab.item.id) && ipValid(lab.item.ip)">{{ lab.item.ip }}</p>
                      <p class="mb-0 join-to-play" v-if="isOwner(lab.item.id) && !isLabStarting(lab.item.id) && !ipValid(lab.item.ip)">RETRIEVING IP</p>
                    </div>
                  </div>
                  <div class="col-3">
                    <div
                      class="solved-users d-flex flex-column justify-content-center align-items-center"
                    >
                      <i
                        :title="`flag 1 users ${lab.item.solved_by.user}`"
                        class="fa-solid fa-user flag1"
                      ></i>
                      {{ lab.item.solved_by.user }}
                    </div>
                  </div>
                  <div class="col-3"
                      v-if="lab.item.type.name !== 'ONESHOT'"
                  >
                    <div
                      class="solved-root d-flex flex-column justify-content-center align-items-center"
                    >
                      <i
                        :title="`flag 1 users ${lab.item.solved_by.root}`"
                        class="fa-solid fa-user flag2"
                      ></i>
                      {{ lab.item.solved_by.root }}
                    </div>
                  </div>
                </div>
                <div class="row py-2 align-items-center">
                  <div class="col-6">
                    <span
                      class="lab__difficulty--bar"
                      :style="`--value:${lab.item.difficulty}%`"
                    >
                      {{ lab.item.difficulty }}
                    </span>
                  </div>
                  <div class="col-3">
                    <px-badge
                      class="h-100 px-badge--success rounded d-flex justify-content-center align-items-center"
                      ><i class="fas fa-flag"></i><br />{{
                        lab.item.userPoints
                      }}
                    </px-badge>
                  </div>
                  <div class="col-3">
                    <px-badge
                      class="h-100 px-badge--orange rounded d-flex justify-content-center align-items-center"
                      ><i class="fas fa-flag"></i><br />{{
                        lab.item.rootPoints
                      }}
                    </px-badge>
                  </div>
                </div>
              </div>

              <div v-responsive.lg.xl class="container-fluid">
                <div class="row">
                  <div class="col-4 col-md-2 d-flex align-items-center">
                    <div class="">
                      <div class="row d-flex align-items-center lab__name">
                        <h2
                          class="mb-0 pointer"
                          @click="$parent.$router.push(`/lab/${lab.item.id}`)"
                        >
                          {{ lab.item.name | truncate(20) }}
                        </h2>
                      </div>
                      <div
                        class="row d-flex align-items-center lab__ip d-flex align-items-center"
                      >
                        <i
                          v-if="!!lab.item.ip"
                          class="far fa-clipboard mr-1"
                        ></i>
                        <p
                          @click="copyToClipboard"
                          class="clipboard-btn pointer simple-hover mb-0"
                          v-if="isAllowed(lab.item.id) && !isLabStarting(lab.item.id) && ipValid(lab.item.ip)"
                        >
                          {{ lab.item.ip }}
                        </p> 
                        <span v-if="isAllowed(lab.item.id) && !isLabStarting(lab.item.id) && ipValid(lab.item.ip)"> - {{ labTimer(lab.item.id) }} </span>
                        <p
                          class="clipboard-btn pointer simple-hover mb-0 starting-up"
                          v-if="isLabStarting(lab.item.id) && !ipValid(lab.item.ip) && !isLabStarted(lab.item.id)"
                        >
                          starting up
                        </p>
                        <p class="mb-0 join-to-play" v-if="isOwner(lab.item.id) && isLabStarted(lab.item.id) && !isLabStarting(lab.item.id) && !ipValid(lab.item.ip)">RETRIEVING IP</p>
                        <p
                          v-if="!isAllowed(lab.item.id) && lab.item.isRunning && !isLabStarting(lab.item.id)"
                          class="clipboard-btn text-uppercase mb-0 join-to-play"
                        >
                          Join To Play
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    v-responsive.md.lg.xl
                    class="col-2 position-relative d-flex align-items-center justify-content-between"
                    v-if="canSeeFlags"
                  >
                    <span
                      class="lab__flag-status"
                      :class="{
                        flag2: lab.item.flags.root,
                        flag: lab.item.flags.user,
                      }"
                    >
                    </span>
                    <div
                      id="solved-by"
                      class="d-flex flex-row align-items-center justify-content-center position-relative"
                      v-if="lab.item.solved_by !== undefined"
                    >
                      <div
                        class="solved-users mx-3 d-flex flex-column justify-content-center align-items-center"
                      >
                        <i
                          :title="`flag 1 users ${lab.item.solved_by.user}`"
                          class="fa-solid fa-user flag1"
                        ></i>
                        {{ lab.item.solved_by.user }}
                      </div>
                      <div
                        class="solved-root mx-3 d-flex flex-column justify-content-center align-items-center"
                        v-if="lab.item.type.name !== 'ONESHOT'"
                      >
                        <i
                          :title="`flag 1 users ${lab.item.solved_by.root}`"
                          class="fa-solid fa-user flag2"
                        ></i>
                        {{ lab.item.solved_by.root }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-6 col-md-3 d-flex align-items-center lab__difficulty"
                  >
                    <span
                      class="lab__difficulty--bar"
                      :style="`--value:${lab.item.difficulty}%`"
                    >
                      {{ lab.item.difficulty }}
                    </span>
                  </div>
                  <div class="col-3 d-flex align-items-center">
                    <div class="col px-1">
                      <px-badge class="px-badge--success rounded"
                        ><i class="fas fa-flag"></i><br />{{
                          lab.item.userPoints
                        }}
                      </px-badge>
                    </div>
                    <div class="col px-1">
                      <px-badge class="px-badge--orange rounded"
                        ><i class="fas fa-flag"></i><br />{{
                          lab.item.rootPoints
                        }}
                      </px-badge>
                    </div>
                  </div>
                  <div
                    class="col-2 d-flex align-items-center"
                    v-if="canSeeFlags"
                  >
                    <div
                      class="col table__col table__col--labs col__name--info"
                    >
                      <div class="row d-flex align-items-center">
                        <div class="col-2 px-0 my-0 py-0 d-flex justify-content-center">
                          <div v-if="!lab.item.isRunning" @click="openLabManagmentModal(lab.item)" class="text-center icon-buttons play-icon d-flex justify-content-center">
                            <i :class="[pendings() ? 'action-disabled':'pointer']" class="my-0 py-0 fa-solid fa-play action-button action-button--play"></i>
                          </div>
                          <div v-if="lab.item.isRunning && isOwner(lab.item.id)" @click="openLabManagmentModal(lab.item, 'stop')" class="text-center icon-buttons stop-icon d-flex justify-content-center">
                            <i :class="[isLabStarting(lab.item.id) ? 'action-disabled':'pointer']" class="my-0 py-0 fa-solid fa-stop action-button action-button--stop"></i>
                          </div>
                          <div v-if="!isJoined(lab.item.id) && lab.item.isRunning && !isOwner(lab.item.id)" @click="labAction(lab.item.id, 'join')" class="text-center icon-buttons join-icon d-flex justify-content-center">
                            <i :class="[isLabStarting(lab.item.id) ? 'action-disabled':'pointer']" class="my-0 py-0 fa fa-user-plus action-button action-button--join"></i>
                          </div>
                          <div v-if="isJoined(lab.item.id) && lab.item.isRunning && !isOwner(lab.item.id)" @click="labAction(lab.item.id, 'leave')" class="text-center icon-buttons join-icon d-flex justify-content-center">
                            <i :class="[isLabStarting(lab.item.id) ? 'action-disabled':'pointer']" class="my-0 py-0 fa fa-window-close action-button action-button--join"></i>
                          </div>
                        </div>
                        <div class="col-2 px-0 d-flex justify-content-center">
                          <div
                            v-if="1"
                            @click="shouldOpenModal('user', lab.item)"
                            class="icon-buttons flag-icon d-flex"
                          >
                            <i
                              :class="
                                !lab.item.isRunning || lab.item.flags.user
                                  ? 'action-disabled'
                                  : 'pointer'
                              "
                              class="icon icon-flag action-button action-button--flag-1"
                            ></i>
                            <!--                        <img src="../assets/images/icon-user-flag.svg" alt="user flag">-->
                          </div>
                        </div>
                        <div class="col-2 px-0 d-flex justify-content-center">
                          <div
                            v-if="lab.item.type.name !== 'ONESHOT'"
                            @click="shouldOpenModal('root', lab.item)"
                            class="icon-buttons flag-icon d-flex"
                          >
                            <i
                              :class="
                                !lab.item.isRunning || lab.item.flags.root
                                  ? 'action-disabled'
                                  : 'pointer'
                              "
                              class="icon icon-flag action-button action-button--flag-2"
                            ></i>
                            <!--                        <img src="../assets/images/icon-user-hashtag.svg" alt="root flag">-->
                          </div>
                        </div>
                        <div class="col-2 px-0 d-flex justify-content-center">
                          <div
                            @click="getLabWriteup(lab.item)"
                            class="icon-buttons"
                          >
                            <i
                              :class="
                                lab.item.isRunning
                                  ? 'pointer'
                                  : 'action-disabled'
                              "
                              class="fa fa-pencil action-button action-button--reset"
                            ></i>
                          </div>
                        </div>
                        <div class="col-2 px-0 d-flex justify-content-center">
                          <div
                            @click="shouldResetLab(lab.item)"
                            class="icon-buttons"
                          >
                            <i
                              :class="
                                lab.item.isRunning
                                  ? 'pointer'
                                  : 'action-disabled'
                              "
                              class="fas fa-redo action-button action-button--reset"
                            ></i>
                          </div>
                        </div>
                        <div class="col-2 px-0 d-flex justify-content-center position-relative">
                          <div
                            @click="labLiked(lab.item)"
                            class="icon-buttons"
                          >
                            <div class="likes-container">
                              <div class="likes-counter p-0 m-0" :style="`--_likes: '${getLikes(lab.item.likes)}'`">
                              </div>
                            </div>
                            <i
                              :class="{'fa-regular': !lab.item.liked, 'fa-solid liked': lab.item.liked}"
                              class="pointer fa-heart action-button action-button--like"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </InfiniteScroll>
        <div
          class="spinner-container d-flex justify-content-center my-5"
          v-if="labs.length === 0"
        >
          <Spinner :full="false"></Spinner>
        </div>
      </px-table>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import labService from "../api/lab";
import FlagSubmissionModal from "@/components/FlagSubmissionModal";
import PxTable from "@/components/PxTable";
import PxButton from "@/components/PxButton";
import PxBadge from "@/components/PxBadge";
import PxCard from "../components/cards/PxCard";
import ECharts from "vue-echarts";
import eventService from "../api/event";
import userService from "@/api/user";
import UserType from "@/enums/user";
import InfiniteScroll from "../components/InfiniteScroll";
import Spinner from "../components/Spinner";
import LabExtendModal from "@/components/LabExtendModal";
import LabManagmentModal from "@/components/LabManagmentModal";

export default {
  name: "Labs",
  components: {
    PxButton,
    PxTable,
    PxBadge,
    PxCard,
    "v-chart": ECharts,
    InfiniteScroll,
    Spinner,
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.id,
      company_id: (state) => state.user.company_id,
      userRole: (state) => state.user.role
    })
  },
  data() {
    return {
      labs: [],
      pendingLabs: [],
      companyLabs: [],
      showCompanyLabs: false,
      canSeeScope: false,
      canSeeType: false,
      canSeeFlags: false,
      canSeeScopeOrType: false,
      upcomingEvent: null,
      upcomingLab: {},
      firstBlood: null,
      upcomingLabStats: [],
      lastPage: false,
      lastCompanyPage: false,
      dailySubs: {
        grid: {
          left: 0,
          right: -65,
          bottom: 0,
          top: 0,
        },
        xAxis: {
          show: false,
          type: "category",
          data: [1, 2, 3, 4, 5, 6, 7],
          axisLine: {
            lineStyle: {
              color: "#768aa3",
              // opacity: 1
              width: 0,
            },
          },
        },
        // tooltip: {
        //   trigger: 'axis',
        //   axisPointer: {
        //     type: 'cross',
        //     label: {
        //       backgroundColor: '#6a7985'
        //     }
        //   }
        // },
        yAxis: {
          type: "value",
          show: false,
          axisLine: {
            lineStyle: {
              color: "#768aa3",
              // opacity: 1
              width: 0,
            },
          },
          splitLine: {
            lineStyle: {
              color: "#555",
              type: "solid",
              opacity: 0,
            },
          },
        },
        series: [
          {
            data: [],
            markLine: {
              symbol: ["none", "none"],
              label: { show: false },
              lineStyle: {
                color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgba(255, 255, 255,0)",
                  },
                  {
                    offset: 1,
                    color: "rgba(255, 255, 255,0.1)",
                  },
                ]),
              },
              data: [
                { xAxis: 1 },
                { xAxis: 2 },
                { xAxis: 3 },
                { xAxis: 4 },
                { xAxis: 5 },
                { xAxis: 6 },
                { xAxis: 7 },
              ],
            },
            smooth: true,
            type: "line",
            symbol: "circle",
            symbolSize: 0,
            lineStyle: {
              color: "rgb(114, 253, 133)",
              width: 0.7,
            },
            areaStyle: {
              opacity: 0.8,
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(114, 253, 133,0.6)",
                },
                {
                  offset: 1,
                  color: "rgb(114, 253, 133,0)",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
          },
        ],
      },
      startedLabs: [],
      startedInterval: null,
      labsInterval: null,
      stopDates: [],
      timers: [],
      timerIntervals: []
    };
  },
  beforeCreate() {
    this.$parent.breadcrumbs = [{ name: "labs" }];
  },
  created() {
    this.loadUpcomingLab();
    this.loadFirstBlood();
    this.loadUpcomingLabStats();
    this.loadUpcomingEvent();
    this.page = 1;
    this.loadLabs();
    this.getStartedLabs();
    this.labsInterval = setInterval(() => this.loadLabs(), 60000);
    this.startedInterval = setInterval(() => this.getStartedLabs(), 60000);
  },
  beforeDestroy() {
    this.clearTimerIntervals();
    clearInterval(this.startedInterval);
    clearInterval(this.labsInterval);
  },
  methods: {
    isUser() {
      const role = this.userRole;
      if (!role) return false;
      return role === UserType.USER;
    },
    downloadOvpnFile() {
      this.$parent.loading = true;

      userService
        .getOvpn()
        .then((response) => {
          const blob = new Blob([response.data], { type: "text/plain" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "config.ovpn";
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          const manageableError =
            !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => (this.$parent.loading = false));
    },
    clearLabInterval(labId) {
      const [interval] = this.timerIntervals.filter(ival => ival.id === labId) 
      if (!interval) return;
      this.timerIntervals = this.timerIntervals.filter(ival => ival.id !== labId)
      this.timers = this.timers.filter(ival => ival.id !== labId)
      clearInterval(interval.interval)
    },
    ipValid(ip) {
      if (!ip) return false;
      if (typeof ip !== "string") return false;
      ip = ip.toUpperCase()
      if (ip === "N/A") return false;
      if (ip === "") return false;
      return true
    },
    pendings() {
      const pendings = this.pendingLabs.length > 0
      const userPendings = this.pendingLabs.some(lab => lab.userId == this.userId)
      return pendings && userPendings
    },
    isAllowed(labId) {
      return this.isJoined(labId) || this.isOwner(labId)
    },
    isJoined(labId) {
      const [lab] = this.startedLabs.filter(lab => lab.id === labId)
      if (!lab) return false;
      return lab.joined;
    },
    isOwner(labId) {
      const [lab] = this.labs.filter(lab => lab.id == labId)
      if (!lab) return false;
      return lab.owner;
    },
    clearTimerIntervals(labId) {
      if (this.timerIntervals.length === 0) return;
      if (!labId) this.timerIntervals.forEach(interval => clearInterval(interval.interval));
      else this.timerIntervals.forEach(interval => {
        if(interval.id === labId) clearInterval(interval.interval)
      })
    },
    sortDateTime(date) {
      const hour = date.split(":")[0] ?? 0;
      const minute = date.split(":")[1] ?? 0;

      const result = [minute]
      if (hour != 0) result.unshift(hour)
      return Number(result.join(""))
    },
    labTimer(labId) {
      let timers = this.timers;
      if (!timers) return "";
      const timerObj = timers.filter(timer => timer.id === labId)[0];
      if (!timerObj) return "";
      return timerObj.timer;
    },
    openExtendModal(lab, minutes) {
      if (this.extendModalOpened) return;
      this.extendModalOpened = true;
      this.$modal.show(
        LabExtendModal,
        {lab:lab, minutes:minutes, tokenBased: false},
        {height: 'auto'}
      )
    },
    resetTimers() {
      this.clearTimerIntervals()
      this.timers = []
    },
    openLabManagmentModal(lab, action='start') {
      this.$modal.show(
        LabManagmentModal,
        {
          manageLab: this.labAction,
          lab: lab,
          action:action,
          tokenBased: false
        },
        {height: 'auto'}
      )
    },
    setupTimers() {
      // this.resetTimers();
      for (const lab of this.stopDates) {
        const stopDate = lab.stopDate;
        const interval = {"id":lab.id, "interval":setInterval(() => {

          this.timers = this.timers.filter(item => item.id != lab.id)
          this.timerIntervals = this.timerIntervals.filter(item => item.id != lab.id)

          let timestamp = (new Date(stopDate + "Z").getTime() - new Date().getTime())
          let seconds = Math.max(Math.floor((timestamp / 1000) % 60), 0);
          let minutes = Math.max(Math.floor((timestamp / (1000 * 60)) % 60), 0);
          let hours = Math.max(Math.floor((timestamp / (1000 * 60 * 60)) % 24), 0);
          let days = Math.max(Math.floor((timestamp / 1000) / 86400), 0);

          const owner = this.userId === lab.userId
          if (minutes <= 10 && hours === 0 && owner) {
            const labObj = this.labs.filter(l => l.id == lab.id)[0];
            this.openExtendModal(labObj, minutes);
          }

          // days = days < 10 ? "0" + days:days;
          seconds = seconds < 10 ? "0" + seconds:seconds;
          minutes = minutes < 10 ? "0" + minutes:minutes;
          hours = hours < 10 ? "0" + hours:hours;

          let timer = null;
          if (days === 0){
            timer = hours + ":" + minutes + ":" + seconds;
          } else {
            timer = days + "d - " + hours + ":" + minutes + ":" + seconds;
          }
        // this.timers = this.filterUniqueLabs([{"id":lab.id, "timer":timer}, ...this.timers]);
          this.timers = [...new Map([{"id": lab.id, "timer": timer}, ...this.timers].map(item => {
            return [item["timer"], item]
          })).values()]
        }, 1000)};

        this.timerIntervals.push(interval);
      }
    },
    labAction(labId, action){
      if (typeof action !== "string") return;
      action = action.toLowerCase()
      if (action === "stop" && this.isLabStarting(labId)) return
      if (action === "start" && this.pendings()) return;
      if (["stop", "leave"].some(act => act === action)) {
        this.timerIntervals = this.timerIntervals.filter(item => item.id !== labId)
        this.timers = this.timers.filter(item => item.id !== labId)
      }

      labService.labAction(labId, action, {}, false, this.userId)
        .then((response) => {
          const manageableMessage = !!response.data && !!response.data.message;
          if (manageableMessage) {
            this.flashSuccess(response.data.message);
          }
        })
        .catch((error) => {
          const manageableError = !!error.data && !!error.data.errorDescription
          if (manageableError) this.flashError(error.data.errorDescription);
        })
        .finally(() => {
          this.loadLabs();
          this.getStartedLabs();
        })
    },
    getStartedLabs() {
      labService.getStartedLabs("FREE")
        .then((response) => {
          this.startedLabs = response.data.labs.filter(lab => lab.status === "COMPLETED");
          this.stopDates = response.data.labs.map(lab => ({id: lab.id, stopDate: lab.stopDate, userId: lab.userId}))
          this.pendingLabs = response.data.labs.filter(lab => lab.status === "PENDING")
          this.setupTimers();
        })
    },
    isLabStarted(labId) {
      const startedLabs = this.startedLabs;
      if (startedLabs.length === 0) return false
      const [startedLab] = startedLabs.filter(lab => lab.id === labId)
      return startedLab && startedLab.status === "COMPLETED"
    },
    isLabStarting(labId) {
      const pendingLabs = this.pendingLabs;
      if (pendingLabs.length === 0) return false;
      const [pendingLab] = pendingLabs.filter(lab => lab.id === labId)
      return pendingLab && pendingLab.status === "PENDING"
    },
    getLikes(likes) {
      if (likes > 99) return "99+";
      return likes
    },
    isLiked(labId) {
      const [lab] = this.labs.filter(item => item.id === labId)
      if (!lab) return;
      return lab.liked;
    },
    labLiked(lab) {
      const wasLiked = lab.liked
      lab.liked = !lab.liked
      lab.likes = wasLiked ? Math.max(0, lab.likes - 1):lab.likes + 1
      labService.labLiked(lab.id)
    },
    getLabWriteup(lab) {
      this.$parent.getLabWriteup(lab);
    },
    copyToClipboard(e) {
      let ip = e.target.innerHTML.split("<")[0];
      var copyText = document.getElementById("labIpClipboard");
      copyText.value = ip;
      copyText.select();
      navigator.clipboard
        .writeText(copyText.value)
        .then(() => {
          this.flashSuccess(`ip ${ip} copied successfully`);
        })
        .catch(() => {
          this.flashError("something went wrong copying the ip");
        });
    },
    loadUpcomingLabStats() {
      this.$parent.loading = true;
      labService
        .upcomingLabStats()
        .then((response) => {
          this.dailySubs["series"][0]["data"] = response.data;
        })
        .finally(() => (this.$parent.loading = false));
    },
    loadFirstBlood() {
      this.$parent.loading = true;
      labService
        .firstBlood()
        .then((response) => {
          this.firstBlood = response.data;
        })
        .finally(() => (this.$parent.loading = false));
    },
    loadUpcomingEvent() {
      this.$parent.loading = true;
      eventService
        .upcomingEvent()
        .then((response) => {
          if (response.data.id !== undefined) {
            this.upcomingEvent = response.data;
          }
        })
        .finally(() => (this.$parent.loading = false));
    },
    loadUpcomingLab() {
      this.$parent.loading = false;
      labService
        .upcomingLab()
        .then((response) => {
          this.upcomingLab = response.data;
        })
        .finally(() => (this.$parent.loading = false));
    },
    loadLabs(page, reset = false) {
      if (this.lastPage && !this.showCompanyLabs) return;
      if (this.lastCompanyPage && this.showCompanyLabs) return;
      if (!page) page = 0;
      // this.$parent.loading = true;
      const loadLabsFunc = !this.showCompanyLabs
        ? labService.getLabs
        : labService.getCompanyLabs;
      loadLabsFunc(page)
        .then((response) => {
          if (reset) this.labs = [];
          // if (!response.data.labs.length) {
          //   if (!this.showCompanyLabs) this.lastPage = true;
          //   if (this.showCompanyLabs) this.lastCompanyPage = true;
          //   return;
          // }
          let labs = [...this.labs, ...response.data.labs];
          labs = [...new Map(labs.map(item => [item["id"], item])).values()]
          this.labs = labs;
          // this.labs.push(...response.data.labs);
          this.canSeeScope =
            this.labs.length && this.labs[0].scope
              ? new Set(this.labs.map((l) => l.scope.id)).size > 1
              : false;
          this.canSeeType =
            this.labs.length && this.labs[0].type
              ? new Set(this.labs.map((l) => l.type.id)).size > 1
              : false;
          this.canSeeScopeOrType = this.canSeeScope || this.canSeeType;
          this.canSeeFlags = this.labs.length ? "flags" in this.labs[0] : false;
        })
        .catch((error) => {
          const manageableError =
            !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => (this.$parent.loading = false));
    },
    shouldOpenModal(flagType, lab) {
      if (!lab.flags[flagType] && lab.isRunning) {
        this.openFlagModal(flagType, lab.id);
      }
    },
    openFlagModal(flagType, labId) {
      this.$modal.show(
        FlagSubmissionModal,
        {
          flagType,
          labId,
          onClose: () => {
            this.labs = [];
            this.lastPage = false;
            this.lastCompanyPage = false;
            this.loadLabs();
            this.$refs.infiniteScroll.resetPage();
          },
        },
        {
          height: "auto",
        }
      );
    },
    userHasBeenOwned(labId) {
      return this.hasBeenOwned("user", labId);
    },
    rootHasBeenOwned(labId) {
      return this.hasBeenOwned("root", labId);
    },
    hasBeenOwned(who, labId) {
      const lab = this.labs.find((l) => l.id === labId);
      return !!lab.flags[who];
    },
    getLabLevel(difficulty) {
      const lvl = Math.round(difficulty / 10);
      if (lvl < 1) {
        return 1;
      } else if (lvl > 9) {
        return 9;
      }
      return lvl;
    },
    shouldResetLab(lab) {
      if (lab.isRunning) {
        // this.resetLab(lab);
        this.labAction(lab.id, 'reset')
      }
      return;
    },
    resetLab(lab) {
      this.$parent.loading = true;
      labService
        .resetLab(lab.id)
        .then((response) => {
          const successMessage =
            !!response && !!response.data && !!response.data.message;
          if (successMessage) {
            this.flashSuccess(response.data.message);
          }
        })
        .catch((error) => {
          const manageableError =
            !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => (this.$parent.loading = false));
    },
    getLabClass(lab, points = null) {
      const classes = [];
      if (!lab[`${points}Points`]) {
        classes.push("hide-me");
      } else {
        if (lab.isRunning) {
          classes.push("pointer");
        } else {
          classes.push("action-disabled");
        }
      }
      return classes;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";
@import "../assets/css/variables";

$linux-logo__inactive--dark: url("../assets/images/icon-set/linux-inactive.png");
$windows-logo__inactive--dark: url("../assets/images/icon-set/windows-logo.png");

.flag1 {
  color: $soft-green;
}

.flag2 {
  color: $soft-orange;
}

.banner {
  &__content {
    margin: 0 10px;
    position: relative;
  }

  &__graph {
    height: 50%;
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &__card {
    position: relative;
  }

  &__first-blood {
  }

  canvas {
    pointer-events: none;
  }
}

.lab__difficulty {
  width: 100%;
}

.lab__difficulty--bar {
  display: block;
  background: rgba($color: $neutral--medium, $alpha: 0.75);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  width: 100%;
  height: 10px;
  position: relative;
  font-size: 0;

  &:after {
    content: "";
    clip-path: inset(0px calc(100% - var(--value)) 0px 0px round 15px);
    border-radius: 10px 10px 10px 10px;
    -webkit-border-radius: 10px 10px 10px 10px;
    -moz-border-radius: 10px 10px 10px 10px;
    width: 100%;
    left: 0;
    position: absolute;
    height: 10px;
    background: #e5405e;
    background: linear-gradient(
      to right,
      #3fffa2 0%,
      #ffdb3a 35%,
      #ff993a 60%,
      #e5405e 100%
    );
  }
}

.table__row {
  min-height: 60px;
}

.os-logo {
  max-width: 1.5rem;
}

.play-icon,
.stop-icon, 
.join-icon {
  margin-right: 1rem !important;
}

.flag-icon {
  margin-right: 1rem !important;
}

.hide-me {
  opacity: 0;
}

.action-disabled {
  opacity: 0.4;
}

.lab__name {
  h2 {
    font-size: 0.8rem;
    font-weight: bold;
    transition: all 0.2s;

    &:hover {
      color: rgba($soft-green, 1);
    }
  }
}

.lab__ip {
  opacity: 0.8;
  font-size: 0.8rem;
}

.clipboard-btn {
  i {
    font-size: 90%;
  }
}

.lab__flag-status {
  position: relative;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: rgba($neutral--real-medium, 0.7);

  &:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    position: absolute;
    height: 160%;
    width: 160%;
    background: rgba($neutral--real-medium, 0.7);
    border-radius: 50%;
    z-index: -1;
  }

  &.flag {
    background: $soft-green !important;

    &:after {
      background: rgba($soft-green, 0.7);
    }
  }

  &.flag2 {
    background: $soft-orange !important;

    &:after {
      background: rgba($soft-orange, 0.7);
    }
  }
}

.action-button {
  color: black;
  padding: 7px;
  border-radius: 50%;
  position: relative;
  transition: all 0.3s;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    height: 0;
    width: 0;
    transform: translate(-50%, -50%);
    z-index: -1;
    border-radius: 50%;
    transition: all 0.3s;
    opacity: 0.5;
  }

  &:not(.action-disabled) {
    &:hover {
      transform: scale(0.8);

      &:after {
        height: 160%;
        width: 160%;
      }
    }
  }

  &--play,
  &--play:after,
  &--stop,
  &--stop:after {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 9px!important;
  }

  &--join,
  &--join:after {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 7px!important;
  }

  &--flag-1,
  &--flag-1:after,
  &--play,
  &--play:after {
    background: $soft-green;
  }

  &--join,
  &--join:after {
    background: $soft-blue;
  }

  &--stop,
  &--stop:after {
    background: $soft-red;
  }

  &--flag-2,
  &--flag-2:after {
    background: $soft-orange;
  }

  &--reset,
  &--reset:after {
    color: white;
    font-size: 0.9rem;
    background: $neutral--real-medium;
  }

  &--like.liked {
    background: $soft-red;
  }

  &--like,
  &--like:after {
    color: white;
    font-size: 0.9rem;
    background: $neutral--real-medium;
  }
}

.spinner-container {
  position: relative;
}

.likes-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.likes-counter:after {
  content: var(--_likes);

  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  transform: translate(0%, -50%);
  font-size: 12px;
  background: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid black;
  padding: 1px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.join-to-play {
  color: $soft-blue;
  letter-spacing: 2px;
  margin-left: 5px;
}

.starting-up {
  color: $soft-green;
  transition: all 0.5s linear;
  animation: starting-up 1s infinite alternate;
}

@keyframes starting-up {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

</style>
