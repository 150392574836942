<template>
  <base-modal :title="modal.title" :buttons="modal.buttons" :close="modal.close" class="position-relative">
    <div id="questions-wrapper" class="rounded position-relative d-flex flex-column flex-nowrap w-100 h-100">
      <div class="my-2" id="question">
        <div class="rounded question p-3 w-100">
          {{ question.question }}
        </div>
        <div :class="{ 'checked': response.checked }" @click="toggleChecked(response)"
          class="p-3 d-flex w-100 pointer rounded align-items-center justify-content-center flex-row bg-darker m-1 rounded"
          id="options" v-for="response of question.responses" :key="response.id">
          <span class="mr-3 rounded" id="response-checkbox" :class="{ 'checked': response.checked }"></span>
          <div class="row w-100">
            <div class="col-8 w-100 d-flex flex-column justify-content-center">
              <span>{{ response.response }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="progress-wrapper" :style="`--_width: ${getProgressWidth()}%`"></div>
    </div>
  </base-modal>
</template>
<script>
import BaseModal from './BaseModal'
import userService from "@/api/user";
import courseService from '@/api/courses.js';

export default {
  name: "SurveyModal",
  props: {
    chapterId: {
      type: String,
      required: false,
      default: null,
    },
    isExam: {
      type: Boolean,
      required: false,
      default: false
    },
    survey: {
      type: Array,
      required: true
    },
    surveySaved: {
      type: Function,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: null
    }
  },
  created() {
    this.modal.title = this.title ?? "HELP US BUILD YOUR TAILOR-MADE PATH"
  },
  computed: {
    question() {
      return this.survey[this.qIndex]
    },
    anyChecked() {
      const index = this.qIndex;
      const question = this.survey[index];
      return question.responses.some(item => item.checked === true)
    },
  },
  components: {
    BaseModal,
  },
  data() {
    return {
      qIndex: 0,
      modal: {
        title: "",
        buttons: [
          {
            name: "Next",
            class: "px-button--success disabled",
            action: this.sendResults
          },
          {
            name: "reset",
            class: "px-button--primary outline",
            action: this.reset
          }
        ],
        close: this.selfClose
      },
      responses: []
    }

  },
  methods: {
    getProgressWidth() {
      const index = this.qIndex;
      const length = Math.max(0, this.survey.length - 1);
      return Math.floor((index / length) * 100)
    },
    nextQuestion() {
      if ((this.qIndex + 1) > this.survey.length - 1) return;
      this.qIndex += 1;
    },
    prevQuestion() {
      this.qIndex += Math.max(0, this.qIndex - 1);
    },
    toggleChecked(response) {
      const index = this.qIndex;
      for (const resp of this.survey[index].responses) {
        if (resp.id === response.id) {
          resp.checked = true;
          continue
        }
        resp.checked = false;
      }

      const buttons = this.modal.buttons;
      const [sendBtn] = buttons.filter(item => item.name.toLowerCase() === "next")
      if (!sendBtn) return;
      sendBtn.class = "px-button--success"

      if (index === this.survey.length - 1) sendBtn.name = "Send"
    },
    selfClose() {
      this.$emit("close");
    },
    reset() {
      for (const question of this.survey) {
        question.responses.forEach(item => item.checked = false)
      }
      this.qIndex = 0;
      const buttons = this.modal.buttons;
      const [sendBtn] = buttons.filter(item => {
        const name = item.name.toLowerCase()
        return name === "next" || name === "send"
      })
      if (!sendBtn) return;
      sendBtn.class = "px-button--success disabled"
      sendBtn.name = "next"
    },
    sendResults() {
      if (this.qIndex != this.survey.length - 1) {
        const buttons = this.modal.buttons;
        const [sendBtn] = buttons.filter(item => item.name.toLowerCase() === "next")
        if (!sendBtn) return;
        sendBtn.class = "px-button--success disabled"

        return this.nextQuestion()
      }
      let data = []
      for (const question of this.survey) {
        const [response] = question.responses.filter(item => item.checked === true)
        if (!response) {
          this.flashError("Invalid data, you should answer to all the questions")
          return;
        }
        const questionId = question.id;
        const responseId = response.id;
        const object = {}
        object[questionId] = responseId
        data.push(object);
      }

      this.$parent.loading = true;
      const isExam = this.isExam;
      const chapterId = this.chapterId;
      const courseName = this.$route.params.name;

      let promise = null;
      if (isExam) {
        let newData = {}
        for (const el of data) {
          newData = { ...newData, ...el };
        }
        data = newData
        promise = courseService.saveExamResponse(courseName, { "answers": data })
      } else if (!isExam && chapterId !== null) {
        promise = userService.saveChapterQuestions({ "answers": data }, chapterId)
      } else {
        promise = userService.saveUserSurvey({ "answers": data })
      }

      promise.then((response) => {
        const manageableMessage = !!response.data && !!response.data.message;
        if (manageableMessage) {
          this.flashSuccess(response.data.message);
          this.surveySaved();
        }
      })
        .catch((err) => {
          const error = err.response;
          const manageableError = !!error.data && !!error.data.errorDescription
          if (manageableError) this.flashError(error.data.errorDescription);
        })
        .finally(() => {
          this.$parent.loading = false;
          this.selfClose()
        })
      // uuid-question -> uuid user-answer
    },
  },
}
</script>
<style scoped lang="scss">
@import "../assets/css/colors.scss";

#response-checkbox.checked {
  background: $soft-blue;
}

#response-checkbox {
  background: transparent;
  border: 1px solid #fff;
  width: 15px;
  height: 15px;
}

#options {
  transition: all 0.5s ease;
}

#options.checked {
  background: $soft-green !important;
  color: $neutral--dark !important;
}

#options.checked:hover {
  background: $soft-green !important;
}

#options:hover {
  background-color: black !important;
  transition: all 0.5s ease;
}

#questions-wrapper {
  // max-height: 500px;
  overflow: scroll;
  position: relative;
}

.question {
  background-color: $soft-green;
  color: black;
  overflow-x: scroll;
  font-weight: 900;
}

.progress-wrapper {
  position: absolute;
  bottom: 0%;
  left: 0%;
  width: var(--_width, 0%);
  height: 10px;
  background: $soft-green;
  border-radius: 600px;
  transition: all 0.5s ease;
}
</style>
