<template>
  <div class="padding-bottom-20 table vh-100">
    <div :class="{'accept-terms':!this.termsAccepted}" v-if="!this.termsAccepted">
    </div>
    <div class="row">
      <div class="col-6">
        <h1 class="main-title-1">Events</h1>
      </div>
      <div class="col-6 text-right">
        <px-button @click.native="goBack"
                   class="px-button--secondary outline icon-left"
                   icon="left-arrow">
          Back
        </px-button>
      </div>
    </div>
    <form @submit.prevent class="rounded-card__item bg-gradient mb-5">
      <div class="row">
        <div class="col-md-7 col-12">
          <div class="form-group">
            <label for="title" class="card__label">Name</label>
            <input autocomplete="off" type="text" name="title" id="title" class="form-control" required
                   v-model="event.name"
                   maxlength="100" placeholder="Event name" :class="(event.name.length ? '' : 'not-')+'valid'"/>
          </div>
          <div class="form-group">
            <label for="datepicker" class="card__label">Start - End date</label>
            <div class="rounded-card__item bg-gradient">
              <date-picker
                  v-if="loadedDates"
                  id="datepicker"
                  class="pwnx-date-picker"
                  v-model="eventDates"
                  mode="range"
                  :input-props="datePickerInputProps"
                  :min-date="minDate"
                  @input="datesHaveChanged"
                  color="green"
                  is-dark
                  is-inline
                  is-expanded/>
            </div>
          </div>

          <div class="form-group">
            <div class="event-point">
              <div class="row align-items-center">
                <figure class="col-3 event-point__figure text-center">
                  <img src="../assets/images/icon-cup-green.svg" class="event-point__image"
                       alt="event-points"/>
                </figure>
                <div class="col-9">
                  <h3 class="event-point__title">
                    Event points
                    <span>{{ eventPoints }}</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <px-button @click.native="openInviteUsersModal"
                       class="icon-left w-100"
                       :class="`px-button--${chosenUsers.length ? 'success' : 'danger'}`"
                       icon="user-plus">
              Invite users
            </px-button>
          </div>
        </div>
        <div class="col-md-5 col-12">
          <div class="form-group">
            <label for="lab-type" class="card__label">Type</label>
            <select id="lab-type" class="form-control form-control-empty"
                    required @change="updateLabs" v-model="event.type">
              <option v-for="t in types" :key="t.key" :value="t.key">{{ t.value }}</option>
            </select>
          </div>
          <div class="form-group padding-top-20">
            <label class="card__label"></label>
            <px-button class="icon-left w-100"
                       :class="`px-button--${chosenLabs.length ? 'success' : 'danger'}`"
                       icon="flask"
                       @click.native="openEventLabsModal">
              Select labs
            </px-button>
          </div>
          <div class="form-group padding-top-20">
            <label for="start_time" class="card__label">Start time</label>
            <select name="start_time" id="start_time" class="form-control form-control-empty" required
                    :class="startTimeValidClass"
                    @change="startTimeHasChanged"
                    v-model="eventTimes.start">
              <option v-for="time in times" :key="time" :value="time">{{ time }}</option>
            </select>
          </div>
          <div class="form-group padding-top-20">
            <label for="end_time" class="card__label">End time</label>
            <select name="end_time" id="end_time" class="form-control form-control-empty" required
                    :class="endTimeValidClass"
                    @change="endTimeHasChanged"
                    v-model="eventTimes.end">
              <option v-for="time in times" :key="time" :value="time">{{ time }}</option>
            </select>
          </div>

          <!-- prices -->
          <div class="padding-top-20" v-if="priceList !== null">
            <div class="event-point">
              <div class="row">
                <figure class="col-3 event-point__figure text-center lmt">
                  <img src="../assets/images/icon-settings.svg" class="event-point__image"
                       alt="event-points"/>
                </figure>
                <div class="col-9">
                  <h3 class="event-point__title">
                    Price list
                  </h3>
                  <table class="price-list">
                    <tbody>
                    <tr v-for="(v, k) in priceList" :key="k" class="">
                      <td class="">{{ k | capitalize }}</td>
                      <td class="">{{ v }}{{ k === "freeHours" ? "" : "€" }}</td>
                      <td v-if="k !== 'freeHours'">
                        ({{ printSelected(k) }} selected)
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Create button -->
      <div class="row">
        <div class="col">
          <div v-if="priceList !== null">
            <div class="h5 mb-0">Estimate: {{ estimate }}€</div>
            <div class="small mb-2">(-{{ coupon }}€ coupon)</div>
            <div v-show="price > 0">
              <div v-show="event.name && chosenLabs.length && chosenUsers.length">
                <div class="h6">Continue on PayPal to checkout to create the event.</div>
                <div id="paypal-button-container"></div>
              </div>
              <div v-show="!event.name || !chosenLabs.length || !chosenUsers.length">
                <div class="h6">Before proceeding, enter all the required information</div>
              </div>
            </div>
          </div>
          <px-button v-if="priceList === null || price <= 0"
                     class="px-button--success icon-left"
                     icon="calendar-plus"
                     @click.native="createEvent"
                     :disabled="!(event.name && chosenLabs.length && chosenUsers.length)">
            New Event
          </px-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import userService from "../api/user";
import eventService from "../api/event";
import paymentService from "../api/payment";
import httpService from "../api/http";
import SelectLabModal from "../components/SelectLabModal";
import InviteUsersModal from "../components/InviteUsersModal";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import PxButton from "@/components/PxButton";
import EventAddTermsAndConditions from "../components/EventAddTermsAndConditions";
import InvoiceRequestModal from "../components/InvoiceRequestModal.vue";

export default {
  name: "EventsAdd",
  components: {
    PxButton,
    DatePicker,
  },
  data() {
    return {
      eventDates: {
        start: null,
        end: null,
      },
      eventTimes: {
        start: null,
        end: null,
      },
      minDate: null,
      datePickerInputProps: {
        placeholder: "Select event start and end date",
      },
      event: {
        name: '',
        labs: [],
        users: [],
        dates: {
          start: null,
          end: null
        },
        type: 0,
      },
      eventPoints: 0,
      times: [],
      labs: [],
      chosenLabs: [],
      users: [],
      chosenUsers: [],
      types: [],
      startTimeValidClass: "valid",
      endTimeValidClass: "valid",
      priceList: {},
      price: 0,
      hoursSelected: 0,
      loadedDates: false,
      coupon: null,
      estimate: 0,
      termsAccepted: false,
      modalInterval:0
    }
  },
  beforeCreate() {
    this.$parent.breadcrumbs = [{name: "events", navigation: "/events"}, {name: "new event"}]
  },
  created() {
    // Initialize date picker dates
    const today = new Date();
    this.eventDates.start = new Date();
    this.eventDates.start.setDate(today.getDate());
    this.eventDates.end = new Date();
    this.eventDates.end.setDate(today.getDate());

    // Set date picker min date
    this.minDate = this.eventDates.start;

    // Get all event start times
    let h;
    let tString;
    for (let t = 0; t < 24; t++) {
      tString = t.toString();
      h = tString.length === 1 ? `0${t}` : tString;
      [0, 15, 30, 45].forEach(i => {
        if ( i == 0) i = "00";
        this.times.push(`${h}:${i.toString()}`);
      })
    }
    this.eventTimes.start = `${today.getHours() + 1}:00`;
    const startTimeIndex = this.times.indexOf(this.eventTimes.start);
    this.eventTimes.end = this.times[startTimeIndex + 8];

    const requests = [
      eventService.getLabs(),
      userService.all(),
      eventService.getTypes(),
      paymentService.getPrices(),
      paymentService.getCoupon(),
    ];

    this.$parent.loading = true;
    httpService.all(requests)
        .then(([labs, users, types, prices, coupon]) => {
          this.labs = labs.data.labs;
          this.users = users.data.users;
          this.types = types.data.types
          this.priceList = prices.data.priceList;
          this.coupon = coupon.data.coupon || 0;

          // Set initial start/end time:
          // get first time as end time is ok
          // but for the start time we should take the next available start time
          const freeHours = this.priceList ? this.priceList.freeHours : 4;
          if (startTimeIndex + freeHours * 2 < this.times.length) {
            this.eventTimes.end = this.times[startTimeIndex + freeHours * 2];
          } else {
            this.eventDates.end.setDate(today.getDate() + 1);
            const endIndex = freeHours * 2 - (this.times.length - startTimeIndex);
            this.eventTimes.end = this.times[endIndex];
          }
          this.loadedDates = true;
          this.updatePrice();

          if (this.priceList) {
            const payPalScript = `https://www.paypal.com/sdk/js?client-id=${process.env.VUE_APP_payPalClientId}&currency=EUR&disable-funding=bancontact,blik,eps,giropay,ideal,mybank,p24,sepa,sofort,venmo`;
            const vm = this;
            this.$loadScript(payPalScript)
                .then(() => {
                  // Fix #9
                  // cfr., https://github.com/paypal/paypal-checkout-components/issues/1506#issuecomment-751780691
                  // eslint-disable-next-line
                  // if (buttons && buttons.close && hasRendered) {
                  //     // eslint-disable-next-line
                  //     buttons.close();
                  // }
                  // Render the PayPal button into #paypal-button-container
                  // eslint-disable-next-line no-undef
                  paypal.Buttons({
                    // Call your server to set up the transaction
                    createOrder: () => {
                      let errorMessage = null;
                      if (!this.isTimeStartValid()) {
                        errorMessage = 'Start time cannot be in the past!';
                      } else if (!this.isTimeEndValid()) {
                        errorMessage = 'End time cannot be lower then Start time!';
                      }

                      if (errorMessage !== null) {
                        this.flashError(errorMessage);
                        return;
                      }

                      const eventData = this.buildEvent();

                      return paymentService.create(eventData)
                          .then(order => {
                            return order.data.id;
                          })
                          .catch(error => {
                            const manageableError = !!error && !!error.data && !!error.data.errorDescription;
                            if (manageableError) {
                              this.flashError(error.data.errorDescription);
                            }
                          });
                    },

                    onCancel: (data) => {
                      return paymentService.cancel(data.orderID).then();
                    },

                    // Call your server to finalize the transaction
                    onApprove: function (data, actions) {
                      return paymentService.approve(data.orderID)
                          .then(response => {
                            const orderData = response.data.data;
                            // Three cases to handle:
                            //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                            //   (2) Other non-recoverable errors -> Show a failure message
                            //   (3) Successful transaction -> Show a success / thank you message
                            if (orderData.status === "INSTRUMENT_DECLINED") {
                              // Recoverable state, see: "Handle Funding Failures"
                              // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
                              return actions.restart();
                            } else if (orderData.status === "COMPLETED") {
                              // window.location.href = `/event/${orderData.event}`;
                              vm.showInvoiceModal(data.orderID, orderData.event)
                            } else {
                              alert("Something went wrong. Please contact the administrators at support@pwnx.io");
                            }
                          })
                    }
                  }).render("#paypal-button-container");
                })
                .catch(() => {
                });
          }
        })
        .catch(error => {
          const manageableError = !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => this.$parent.loading = false);
        this.showModal();
        this.modalInterval = setInterval(this.checkModal, 500);
  },
  beforeDestroy() {
    clearInterval(this.modalInterval)
  },
  destroyed() {
    const payPalScript = `https://www.paypal.com/sdk/js?client-id=${process.env.VUE_APP_payPalClientId}&currency=EUR&disable-funding=bancontact,blik,eps,giropay,ideal,mybank,p24,sepa,sofort,venmo`;
    this.$unloadScript(payPalScript).then(() => {
    }).catch(() => {
    });
  },

  methods: {
    showInvoiceModal(orderId, eventId) {
      this.$modal.show(
        InvoiceRequestModal,
        {
          orderId,
          orderType:"event",
          redirectUser: () => {
            this.$router.push(["/event", eventId].join("/"))
          }
        },
        { height: 'auto', width: '50%'}
      )
    },
    showModal() {
      this.$modal.show(
        EventAddTermsAndConditions,
        {
          toggleLoading:() => (this.$parent.loading = !this.$parent.loading),
          termsAccept: () => {
            this.termsAccepted = true;
            clearInterval(this.modalInterval);
          }
        },
        { height: 'auto'}
      )
    },
    checkModal() {
      if (this.termsAccepted) return;
      const isShown = !!document.querySelector(".modal-container");
      // console.log(isShown)
      if (!isShown) this.showModal()
      if (isShown) this.$parent.loading = false;
    },
    getDateToISOString(t) {
      // Manipulate date
      const year = this.eventDates[t].getFullYear();
      const month = this.fmtDate(this.eventDates[t].getMonth() + 1);
      const day = this.fmtDate(this.eventDates[t].getDate());
      // Manipulate time
      const [hour, minutes] = this.eventTimes[t].split(":");
      // Merge date and time
      const date = `${year}-${month}-${day}T${hour}:${minutes}`;
      return new Date(date).toISOString();
    },
    isTimeStartValid() {
      const dateStart = new Date(this.getDateToISOString("start"));
      const now = new Date();
      return now < dateStart;

    },
    isTimeEndValid() {
      const dateStart = new Date(this.getDateToISOString("start"));
      const dateEnd = new Date(this.getDateToISOString("end"));
      return dateStart < dateEnd;
    },
    datesHaveChanged() {
      this.startTimeHasChanged();
      this.endTimeHasChanged();
    },
    startTimeHasChanged() {
      this.startTimeValidClass = this.isTimeStartValid() ? "valid" : "not-valid";
      this.updatePrice();
    },
    endTimeHasChanged() {
      this.endTimeValidClass = this.isTimeEndValid() ? "valid" : "not-valid";
      this.updatePrice();
    },
    openEventLabsModal() {
      this.$modal.show(
          SelectLabModal,
          {
            initLabs: this.labs,
            initChosenLabs: this.chosenLabs,
            returnChosenLabs: (response) => {
              this.eventPoints = 0;  // Initialize points (we'll calculate them again later)

              this.chosenLabs = response.chosenLabs;
              this.labs = response.labs;

              if (this.event.type != 2) {
                this.calculateNormalEventPoints(this.chosenLabs);
              } else {
                this.calculateBlueEventPoints(this.chosenLabs);
              }

              this.updatePrice();
            }
          },
          {height: "auto", width:"80%"}
      );
    },
    calculateNormalEventPoints(chosenLabs) {
      for (const lab of chosenLabs) {
        this.eventPoints += (parseInt(lab.userPoints) + parseInt(lab.rootPoints));
      }
    },
    calculateBlueEventPoints(chosenLabs) {
      for (const lab of chosenLabs) {
        this.eventPoints += parseInt(lab.userPoints);
      }
    },
    openInviteUsersModal() {
      this.$modal.show(
          InviteUsersModal,
          {
            initUsers: this.users,
            initChosenUsers: this.chosenUsers,
            returnChosenUsers: (response) => {
              this.chosenUsers = response.chosenUsers;
              this.users = response.users;
              this.updatePrice();
            }
          },
          {height: "auto"}
      );
    },
    buildEvent() {
      // Create payload
      const startDate = this.getDateToISOString("start");
      const endDate = this.getDateToISOString("end");

      this.event.dates.start = startDate;
      this.event.dates.end = endDate;
      this.event.labs = this.chosenLabs.map(lab => lab.id);
      this.event.users = this.chosenUsers.map(user => user.id);
      this.event.extraUsers = this.chosenUsers.filter(user => !user.id).map(user => user.email);

      return this.event;
    },
    updatePrice() {
      if (this.priceList) {
        // Update hours price
        this.updateHoursPrice();

        // Calculate unit price
        const ppu = this.chosenUsers.length * this.priceList.user;
        const ppl = this.chosenLabs.length * this.priceList.lab;
        const pph = this.hoursSelected * this.priceList.hour;

        // Calculate estimate
        this.estimate = ppu + ppl + pph;

        // Calculate price
        this.estimate = parseFloat(parseFloat(this.estimate).toFixed(2));
        this.price = this.estimate - this.coupon;
        if (this.price < 0) {
          this.price = 0;
        }
        this.price = parseFloat(parseFloat(this.price).toFixed(2))
      }
    },
    updateHoursPrice() {
      const splitTime = (times) => {
        return times.split(":");
      };
      let h, m;

      const startDate = new Date(this.eventDates.start);
      [h, m] = splitTime(this.eventTimes.start);
      startDate.setHours(h, m, 0, 0);

      const endDate = new Date(this.eventDates.end);
      [h, m] = splitTime(this.eventTimes.end);
      endDate.setHours(h, m, 0, 0);

      this.hoursSelected = (endDate - startDate) / (60 * 60 * 1000);
      this.hoursSelected -= this.priceList.freeHours;
      this.hoursSelected = this.hoursSelected <= 0 ? 0 : this.hoursSelected;
    },
    checkDates() {
      if (!this.isTimeStartValid()) {
        this.flashError("Start time cannot be in the past!");
        return false;
      } else if (!this.isTimeEndValid()) {
        this.flashError("End time cannot be lower or equal then Start time!");
        return false;
      }
      return true;
    },
    createEvent() {
      if (!this.checkDates()) {
        return;
      }

      // Create payload
      const startDate = this.getDateToISOString("start");
      const endDate = this.getDateToISOString("end");

      this.event.dates.start = startDate;
      this.event.dates.end = endDate;
      this.event.labs = this.chosenLabs.map(lab => lab.id);
      this.event.users = this.chosenUsers.map(user => user.id);
      this.event.extraUsers = this.chosenUsers.filter(user => !user.id).map(user => user.email);

      // Save
      this.$parent.loading = true;
      eventService.create(this.event)
          .then(eventData => {
            const event = eventData.data;
            if (event.message) {
              this.flashSuccess(event.message);
            }
            this.$router.push({name: "EventDetails", params: {eventId: event.id}});
          })
          .catch(error => {
            const manageableError = !!error && !!error.data && !!error.data.errorDescription;
            if (manageableError) {
              this.flashError(error.data.errorDescription);
            }
          })
          .finally(() => this.$parent.loading = false);
    },
    printSelected(key) {
      if (key === "user")
        return this.chosenUsers.length;
      else if (key === "lab")
        return this.chosenLabs.length;
      else
        return this.hoursSelected;
    },
    updateLabs() {
      eventService.getLabs(this.event.type)
          .then(response => this.labs = response.data.labs)
          .catch(error => {
            const manageableError = !!error && !!error.data && !!error.data.errorDescription;
            if (manageableError) {
              this.flashError(error.data.errorDescription);
            }
          });
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/css/colors';
.price-list {
  width: 100%;
  border: none;
  font-size: .8rem;

  td {
    border: none;
    padding: .2rem .5rem;
    vertical-align: middle;
  }
}

// Date picker
.pwnx-date-picker {
  background-color: transparent;
  border: none;
}

/* PayPal stuffs */
/* Media query for mobile viewport */
@media screen and (max-width: 400px) {
  #paypal-button-container {
    width: 100%;
  }
}

/* Media query for desktop viewport */
@media screen and (min-width: 400px) {
  #paypal-button-container {
    width: 250px;
  }
}

.lmt {
  margin-top: -.5rem;
}

.accept-terms {
  position:absolute;
  z-index: 2;
  width:100%;
  height:100%;
  left:0;
  top:0;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:rgba(0,0,0,0.5);
  color:white;
}


</style>
