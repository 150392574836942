<template>
  <div class="pb-5">
    <px-card class="no-bg m-0 p-0">
      <div class="row d-flex m-3 justify-content-end">
        <px-button @click.native="goBack()" class="px-button--secondary icon-left outline" icon="left-arrow">
          back
        </px-button>
      </div>
      <div class="container-fluid px-0">
        <div class="row d-flex align-items-center">
          <div class="col-2 px-0 d-flex justify-content-center">
            <figure class="user-details__figure mr-2">
              <img :src="team.picture" alt="team picture'" class="avatar" />
            </figure>
          </div>
          <div class="col-4 px-0 team-info">
            <h1 class="mb-0">#{{ team.rank }}</h1>
            <h3 class="mb-0">{{ team.name }} <span v-if="team.isExtern"><img src="../assets/images/ctftime-favicon.png"
                  alt="CTFTMIE"></span>
            </h3>
            <p class="mb-0">{{ team.url }}</p>
          </div>
          <div class="col-3 offset-3 text-right">
            <px-card class="px-card--neon orange glow animate">
              <h1 class="mr-3 mb-0">#{{ team.rank }}</h1>
            </px-card>
          </div>
        </div> <!-- user infos -->
        <div class="row m-5" v-if="!team.isExtern">
          <px-button v-if="canJoin && !isInviteTokenValid" @click.native="askJoin" class="px-button--primary"
            icon="team-leave">
            join
          </px-button>
          <px-button v-if="isInviteTokenValid" @click.native="handleJoin('accept')" class="ml-1 px-button--success"
            icon="team-accept">
            accept
          </px-button>
          <px-button v-if="isInviteTokenValid" @click.native="handleJoin('reject')" class="ml-1 px-button--danger"
            icon="team-reject">
            reject
          </px-button>
          <px-button v-if="canLeave" @click.native="leaveTeam" class="ml-3 px-button--danger" icon="team-leave">
            leave
          </px-button>
          <px-button v-if="isUserOwner" @click.native="shouldEditTeam" class="ml-3 px-button--secondary" icon="edit">
            Edit
          </px-button>
          <px-button v-if="isUserOwner" @click.native="deleteTeam" class="ml-3 px-button--danger" icon="delete">
            Delete
          </px-button>
          <px-button v-if="canCancelJoin" @click.native="shouldCancelJoinRequest" class="ml-3 px-button--danger"
            icon="delete">
            Cancel join
          </px-button>
          <px-button v-if="user.externId !== null && user.externId !== undefined && teamId == user.team"
            @click.native="$router.push(`/team/${user.externId}`)" class="ml-3 px-button--orange outline">
            <img src="../assets/images/ctftime-favicon.png" alt="CTFTIME" class="mx-2">
          </px-button>
        </div>
        <div v-if="!graphDataEmpty" class="row mt-5 mb-5 mx-3">
          <v-chart :options="chartData" autoresize class="graphs w-100"></v-chart>
        </div> <!-- graph -->
        <div v-if="graphDataEmpty" class="row mt-5 mb-5 mx-3 graph-data-overlay-container">
          <div class="graph-data-overlay">
            <div class="graph-data-text-wrapper">
              <p class="graph-data-text">NO DATA COLLECTED YET</p>
            </div>
          </div>
          <v-chart :options="chartData" autoresize class="graphs w-100"></v-chart>
        </div> <!-- graph -->
        <div class="container-fluid">
          <div class="row">
            <h3 class="mb-3">
              <i class="icon icon-team-member"></i>
              Team members
            </h3>
          </div>
          <div class="row">
            <div class="col-12">
              <px-table>
                <div class="row table-header mb-3 d-flex justify-content-between">
                  <div class="col-1 py-3">
                    <h2 class="mb-0">rank</h2>
                  </div>
                  <div class="col-3 py-3">
                    <h2 class="mb-0">name</h2>
                  </div>
                  <div class="col-2 py-3 d-flex justify-content-center">
                    <h2 class="mb-0">country</h2>
                  </div>
                  <div class="col-2 py-3 d-flex justify-content-center">
                    <h2 class="mb-0">total points</h2>
                  </div>
                </div>
                <div class="row table__row d-flex justify-content-between d-flex align-items-center"
                  v-for="(user, i) of team.users" :key="user.id">
                  <div class="col-1">{{ i + 1 }}</div>
                  <div class="col-3 d-flex flex-row">
                    <h2 class="simple-hover mb-0" @click="$parent.$router.push(`/user/${user.id}`)">
                      {{ user.username }}
                      <i v-if="user.status === 'pending'" class="mx-2 fa-solid fa-clock"></i>
                    </h2>
                  </div>
                  <div class="col-2 d-flex justify-content-center">
                    <p class="mb-0">
                      <flag :iso="user.country" :squared="false" />
                    </p>
                  </div>
                  <div class="col-2 d-flex justify-content-center">
                    <p class="mb-0">{{ user.points.points }}</p>
                  </div>
                </div>
              </px-table>
            </div>
          </div>
        </div> <!-- team members -->
        <!-- invite user -->
        <div class="container-fluid mt-5" v-if="isUserOwner && !team.isExtern">
          <div class="row">
            <h3 class="main-title-1 mb-3">
              <i class="fa-solid fa-user-plus"></i>
              Invite Member
            </h3>
          </div>
          <div class="row">
            <form class="col-12" @submit.prevent>
              <div class="form-group">
                <input v-model="inviteUsername" type="text" placeholder="Type the username to invite here"
                  class="form-control border border-success invite-user-input" @keyup.enter="addUserToInviteList"
                  @keyup.down="gSearchHover()" @keyup.up="gSearchHover('up')">
                <p v-if="gSearchResults.length === 0" class="text-uppercase text-secondary mt-1">The user has to accept
                  / reject your invite within 48 hours.</p>
                <transition name="fadeHeight" mode="out-in">
                  <div class="u-search-users w-100 bg-darker" v-if="gSearchResults.length > 0">
                    <div class="usearch-results p-2" v-for="(user, index) of gSearchResults"
                      :class="{ 'active': hoverOn === index }" :id="'gsearch-result-' + user.id" :key="index"
                      @click="addUserToInviteList({ index })" @keyup.enter="addUserToInviteList">
                      {{ user.res }}
                    </div>
                  </div>
                </transition>
              </div>
            </form>
          </div>
        </div> <!-- invite user -->
        <div class="container-fluid mt-5">
          <div class="row">
            <h3 class="main-title-1 mb-3">
              <i class="icon icon-calendar"></i>
              Team Events
            </h3>
          </div>
          <div class="row">
            <div class="col-12">
              <px-table>
                <div class="row table-header mb-3 d-flex justify-content-between">
                  <div class="col-4 py-3">
                    <h2 class="mb-0">event name</h2>
                  </div>
                  <div class="col-4 py-3 d-flex align-items-center justify-content-center">
                    <h2 class="mb-0">event status</h2>
                  </div>
                  <div class="col-4 py-3 d-flex align-items-center justify-content-center">
                    <h2 class="mb-0">points</h2>
                  </div>
                </div>
                <div class="row table__row d-flex justify-content-between" v-for="event in team.events" :key="event.id">
                  <div class="col-4 d-flex">
                    <h2 class="simple-hover" @click="$parent.$router.push(`/event/${event.id}`)">{{ event.name }}</h2>
                  </div>
                  <div class="col-4 d-flex align-items-center justify-content-center">
                    <px-badge class="px-badge outline" :class="`px-badge--${event.status}`">{{ event.status
                      }}</px-badge>
                  </div>
                  <div class="col-4 d-flex align-items-center justify-content-center">
                    <p>{{ event.points }}</p>
                  </div>
                </div>
              </px-table>
            </div>
          </div>
        </div>
      </div>
    </px-card>
    <div class="row">
      <div class="col">
        <div class="row align-items-center">
          <div class="col-6 d-flex align-items-center">
            <div class="col-6"></div>
            <div class="col-6 p-0"></div>
          </div>
          <div class="col-12">
            <!--            &lt;!&ndash; <p-->
            <!--                class="user-details__team pointer"-->
            <!--                @click="shouldNavigateToTeamPage"> -->
            <!--                {{ team.url }}-->
            <!--              </p> &ndash;&gt;-->
          </div>
        </div>
        <div class="col-6 d-flex justify-content-end">
          <div class="col-6"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ECharts from "vue-echarts";
import { mapMutations, mapState } from "vuex";
import teamService from "../api/team";
import httpService from "../api/http";
import ExternalNavigationModal from "../components/ExternalNavigationModal";
import CancelRequestModal from "../components/CancelRequestModal";
import TeamDeleteModal from '../components/TeamDeleteModal';
import pxButton from "../components/PxButton";
import PxCard from "../components/cards/PxCard";
import PxBadge from "../components/PxBadge.vue";
import PxTable from '../components/PxTable.vue';
import appService from "@/api/app.js";

// import PxTableEvents from "../components/PxTableEvents";

export default {
  name: "TeamDetails",
  components: {
    "v-chart": ECharts,
    'px-table': PxTable,
    pxButton,
    PxCard,
    PxBadge
    // PxTableEvents
  },
  computed: {
    ...mapState({
      user: state => state.user
    }),
    ...mapMutations({
      setPendingRequest: "user/setPendingRequest",
    }),
  },
  data() {
    return {
      isInviteTokenValid: false,
      chartDataEmpty: false,
      inviteUsername: null,
      usersToInvite: [],
      gSearchResults: [],
      pendingUsers: [],
      hoverOn: -1,
      gSearchTypeTimeout: null,
      teamId: this.$route.params.teamId,
      team: {
        id: '',
        name: '',
        url: '',
        // rank: 0,
        ownerId: '',
        nationality: '',
        points: {
          points: 0,
          systems: 0,
          users: 0,
        },
        picture: "",
        users: [],
        actions: [],
      },
      isUserOwner: false,
      canJoin: false,
      canCancelJoin: false,
      canLeave: false,
      chartData: {
        legend: {
          data: ["Flag1", "Flag2"],
          textStyle: {
            color: "#768aa3",
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        xAxis: {
          show: true,
          type: "category",
          data: [],
          axisLine: {
            lineStyle: {
              color: "#768aa3",
              opacity: 0,
            },
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#768aa3",
              opacity: 0
            },
          },
          splitLine: {
            lineStyle: {
              color: "#555",
              type: "dotted",
              opacity: 0,
            },
          },
        },
        color: ["rgb(250, 154, 65)", "rgb(75, 198, 106)"],
        series: [
          {
            data: [],
            type: "line",
            symbol: "circle",
            colors: "rgb(250, 154, 65)",
            symbolSize: 0,
            lineStyle: {
              color: "rgb(250, 154, 65)",
              width: 0.7,
            },
            areaStyle: {
              opacity: 0.8,
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgb(250, 154, 65)'
              }, {
                offset: 1,
                color: 'rgb(250, 154, 65)'
              }])
            },
            name: "Flag2",
          },
          {
            data: [],
            type: "line",
            symbol: "circle",
            symbolSize: 0,
            colors: "rgb(75, 198, 106)",
            lineStyle: {
              color: "rgb(75, 198, 106)",
              width: 0.7,
            },
            areaStyle: {
              opacity: 0.8,
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgb(75, 198, 106)'
              }, {
                offset: 1,
                color: 'rgba(255, 127, 92,0)'
              }])
            },
            name: "Flag1",
          },
        ],
      }
    }
  },
  beforeCreate() {
    this.$parent.breadcrumbs = [{ name: "teams", navigation: "/teams" }];
  },
  created() {
    // Get team info
    const token = this.$route.query.token;
    const requests = [
      teamService.getTeam(this.teamId),
      teamService.getTeamChartData(this.teamId),
      teamService.getPendingsUsers()
    ];
    if (token != null) {
      requests.push(teamService.checkInviteToken({
        "teamId": this.teamId,
        "token": token
      }))
    }

    this.$parent.loading = true;
    httpService.all(requests)
      .then(async ([team, chart, pendings, validity]) => {
        this.team = Object.assign(this.team, team.data.team);
        this.team.users = this.team.users.map((user) => ({ ...user, "status": "joined" }))
        this.$parent.breadcrumbs.push({ name: `team: ${this.team.name}` });
        this.isUserOwner = this.team.ownerId === this.user.id;
        this.pendingUsers = pendings.data.pendings;
        this.appendPendingUsers()

        if (validity) {
          this.isInviteTokenValid = validity.data.valid;
        }

        this.canJoin = !(this.user.team || this.user.pendingRequest || this.user.company);
        this.canCancelJoin = !!this.team.pendingRequest;
        this.canLeave = !!this.user.team && this.user.team === this.teamId && !this.isUserOwner;

        let userSeries = null
        let systemsSeries = null

        if (this.isGraphFlat(chart.data.chartData)) {
          userSeries = Array(50).fill(null).map(() => Number(Math.random() * 100))
          systemsSeries = Array(50).fill(null).map(() => Number(Math.random() * 100))
          this.graphDataEmpty = true;
        } else {
          userSeries = chart.data.chartData.series[0];
          systemsSeries = chart.data.chartData.series[1];
        }

        this.chartData.xAxis.data = chart.data.chartData.xData;
        this.chartData.series[0].data = userSeries;
        this.chartData.series[1].data = systemsSeries;
      })
      .catch(error => {
        const manageableError = !!error && !!error.data && !!error.data.errorDescription;
        if (manageableError) {
          this.flashError(error.data.errorDescription);
        }
      })
      .finally(() => this.$parent.loading = false);
  },
  methods: {
    isGraphFlat(chartData) {
      if (!chartData) return true;
      console.log({ chartData })
      const users = chartData.series[0];
      const systems = chartData.series[1];

      let noUsers = users.every(item => item === 0)
      let noSystems = systems.every(item => item === 0)

      return noUsers && noSystems
    },
    handleJoin(action = "accept") {
      const token = this.$route.query.token;
      const endpoint = action == "accept" ? teamService.acceptJoin : teamService.rejectJoin

      this.$parent.loading = true;
      endpoint(token)
        .then((response) => {
          const manageableMessage = !!response.data && !!response.data.message
          if (manageableMessage) {
            this.flashSuccess(response.data.message);
            window.location.reload()
          }
        })
        .catch((err) => {
          const error = err.response;
          const manageableError = !!error.data && !!error.data.errorDescription
          if (manageableError) {
            this.flashError(error.data.errorDescription)
          }
        })
        .finally(() => {
          this.$parent.loading = false;
        })
    },
    gSearchHover(direction = "down") {
      if (direction == "down") {
        if (this.gSearchResults.length > this.hoverOn + 1) {
          this.hoverOn += 1;
        } else {
          this.hoverOn = 0;
        }
      } else {
        if (this.hoverOn - 1 > -1) {
          this.hoverOn -= 1;
        } else {
          this.hoverOn = this.gSearchResults.length - 1;
        }
      }
    },
    addUserToInviteList({ index = null }) {

      let user = null;
      if (this.hoverOn !== -1 && this.gSearchResults.length === 1) {
        user = this.gSearchResults[0]
      } else if (this.hoverOn !== -1 && this.gSearchResults.length > 1) {
        user = this.gSearchResults[this.hoverOn]
      }


      if (index !== null) {
        user = this.gSearchResults[index]
      }

      if (user === null) {
        return;
      }

      const pendings = {
        "id": user.id,
        "username": user.res,
        "points": { "points": "-" },
        "country": user.country
      };


      this.$parent.loading = true;
      teamService.inviteMembers({ "users": [user.res] })
        .then((response) => {
          const manageableMessage = !!response.data && !!response.data.message;
          if (manageableMessage) {
            this.flashSuccess(response.data.message);
            this.usersToInvite.push(pendings.username);
            this.pendingUsers.push(pendings)
            this.inviteUsername = "";
            this.gSearchResults = [];
            this.hoverOn = -1;
            this.appendPendingUsers()
          }
        })
        .catch((err) => {
          let error = null
          if (!err.data) {
            error = err.response
          } else {
            error = err
          }
          const manageableError = !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription)
            this.inviteUsername = "";
            this.gSearchResults = []
          }
        })
        .finally(() => {
          this.$parent.loading = false;
        })
    },
    appendPendingUsers() {
      const pendings = this.pendingUsers.map((user) => ({ ...user, "status": "pending" }))

      const users = [...this.team.users, ...pendings]
      const results = []

      for (const user of users) {
        if (results.some(item => item.username === user.username)) continue;
        results.push(user)
      }

      this.team.users = results
    },
    shouldNavigateToTeamPage() {
      this.$modal.show(
        ExternalNavigationModal,
        { extLink: this.team.url },
        { height: "auto" }
      );
    },
    shouldEditTeam() {
      if (!this.isUserOwner) {
        this.flashError("An error occurred");
        return;
      }
      this.$router.push({ name: "TeamEdit", params: { teamId: this.teamId } });
    },
    askJoin() {
      this.$parent.loading = true;
      teamService.askJoin(this.teamId)
        .then(response => {
          const successMessage = !!response && !!response.data && !!response.data.message;
          if (successMessage) {
            this.flashSuccess(response.data.message);
          }

          this.team.pendingRequest = response.data.pendingRequest;
          this.$store.commit("user/setPendingRequest", true);
          this.canJoin = false;
          this.canCancelJoin = true;
        })
        .catch(error => {
          const manageableError = !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => this.$parent.loading = false);
    },
    leaveTeam() {
      this.$parent.loading = true;
      teamService.leave(this.teamId)
        .then(response => {
          const successMessage = !!response && !!response.data && !!response.data.message;
          if (successMessage) {
            this.flashSuccess(response.data.message);
          }

          this.$store.dispatch("user/getCurrentUser");
          this.user.team = null;
          this.$router.push("/teams");
        })
        .catch(error => {
          const manageableError = !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => this.$parent.loading = false);
    },
    deleteTeam() {
      this.$modal.show(
        TeamDeleteModal,
        { returnUserChoice: this.manageDeleteTeam },
        { height: 'auto' }
      )
    },
    manageDeleteTeam(choice) {
      if (choice) {
        this.$parent.loading = true;
        teamService.delete(this.teamId)
          .then(response => {
            const successMessage = !!response && !!response.data && !!response.data.message;
            if (successMessage) {
              this.flashSuccess(response.data.message);
            }
            return this.$store.dispatch("user/getCurrentUser");
          })
          .then(() => this.$router.push("/teams"))
          .catch(error => {
            const manageableError = !!error && !!error.data && !!error.data.errorDescription;
            if (manageableError) {
              this.flashError(error.data.errorDescription);
            }
          })
          .finally(() => this.$parent.loading = false);
      }
    },
    kickUser(userId) {
      this.$parent.loading = true;
      teamService.kick(this.teamId, userId)
        .then(response => {
          this.team.users = this.team.users.filter(u => u.id !== userId);

          const successMessage = !!response && !!response.data && !!response.data.message;
          if (successMessage) {
            this.flashSuccess(response.data.message);
          }
        })
        .catch(error => {
          const manageableError = !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => this.$parent.loading = false);
    },
    getEventStyle(status) {
      if (status === "scheduled") {
        return "blue";
      } else if (status === "ongoing") {
        return "green"
      } else {
        return "red"
      }
    },
    shouldCancelJoinRequest() {
      this.$modal.show(
        CancelRequestModal,
        {
          onClose: (response) => {
            if (response) {
              this.cancelJoinRequest();
            }
          }
        },
        { height: 'auto' }
      );
    },
    cancelJoinRequest() {
      this.$parent.loading = true;
      teamService.cancelJoin(this.team.id, this.team.pendingRequest)
        .then(response => {
          const successMessage = !!response && !!response.data && !!response.data.message;
          if (successMessage) {
            this.flashSuccess(response.data.message);
          }

          this.$store.commit("user/setPendingRequest", false);
          this.canCancelJoin = false;
          this.team.pendingRequest = false;
          this.canJoin = true;
        })
        .catch(error => {
          const manageableError = !!error && !!error.data && !!error.data.errorDescription;
          if (manageableError) {
            this.flashError(error.data.errorDescription);
          }
        })
        .finally(() => this.$parent.loading = false)
    }
  },
  watch: {
    'inviteUsername': function (value) {
      clearTimeout(this.gSearchTypeTimeout)
      if (!value || value === "") {
        this.gSearchResults = []
        return;
      }
      this.gSearchTypeTimeout = setTimeout(() => {
        appService.gSearch({ "gSearchTerm": value })
          .then((response) => {
            let results = response.data.results.filter(item => item.type === "user" && item.id != this.user.id);
            let gSearchResults = []

            if (this.pendingUsers.length > 0) {
              for (const user of results) {
                if (this.pendingUsers.some(item => item.username === user.res)) continue;
                gSearchResults.push(user)
              }
            } else {
              gSearchResults = results
            }

            this.gSearchResults = gSearchResults

            if (value === "") {
              this.hoverOn = -1;
            }
          })
      }, 1000)
    }
  },
}
</script>

<style scoped lang="scss">
@import "../assets/css/colors.scss";

.team-info {
  position: relative;

  h1 {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 7rem;
    opacity: 0.08;
    pointer-events: none;
  }
}

.table__row {
  h2 {
    font-size: 0.8rem;
    font-weight: bold;
  }

  p {
    font-size: 0.9rem;
  }
}

.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.2s;
  max-height: 230px;
}

.fadeHeight-enter,
.fadeHeight-leave-to {
  opacity: 0;
  max-height: 0px;
}

.usearch-results {
  background-color: darken($neutral--dark, 5);
  text-transform: uppercase;
}

.usearch-results:hover {
  background-color: darken($neutral--dark, 20) !important;
}

.usearch-results.active {
  background-color: darken($neutral--dark, 20) !important;
}

.graph-data-overlay-container {
  position: relative !important;
  width: 100%;
  height: 100%;
}

.graph-data-overlay {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 1000;
  transition: all 0.2s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0) !important;
    transition: all 0.2s ease;
  }

  &:hover>.graph-data-text-wrapper {
    opacity: 0;
    transition: all 0.2s ease;
  }
}

.graph-data-text-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
}

.graph-data-text {
  font-size: 32px;
  font-weight: 900;
  letter-spacing: 3px;
}

input:focus,
textarea:focus,
select:focus,
a:focus {
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
