<template>
    <span @click="showWriteup" id="submit-btn" class="text-center py-1 mb-3">
        <slot></slot>
    </span>
</template>

<script>
// import WriteupModal from './WriteupModal';

export default {
    name: 'PxWriteupBtn',
    props: ['lab'],
    methods: {
        showWriteup() {
            this.$emit("show-writeup", this.lab)
        },
        stopLoading() {
            this.$parent.loading = !this.$parent.loading;
        }
    }
}
</script>
<style scoped></style>