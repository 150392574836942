<template>
  <div class="container">
    <h2 class="fw-bold my-2">Job Opportunities</h2>
    <div class="row my-1 m-0 p-0" v-for="job of opportunities" :key=job.id>
      <Job :job="job" :expandOpportunity="expandOpportunity" :showApplyModal="showApplyModal" />
    </div>
  </div>
</template>

<script>
import Job from "@/components/Job";
import JobApplyModal from "@/components/JobApplyModal";
import jobService from "@/api/job";

export default {
  name: "JobOpportunities",
  data() {
    return {
      openedMapping: {},
      opportunities: []
    }
  },
  beforeCreate() {
    this.$parent.breadcrumbs = [{name: "jobs"}]
  },
  created() {
    this.getAllJobs();
  },
  components: {
    Job
  },
  methods: {
    async getAllJobs() {
      this.$parent.loading = true;
      jobService.getAllJobs()
        .then((response) => {
          for (let job of response.data.jobs) {
            job = {...job, expanded: false, alreadyApplied: false}
            this.opportunities.push(job)
          }
        })
        .catch((error) => {
          const manageableError = !!error.data && !!error.data.errorDescription;
          if (manageableError) this.flashError(error.data.errorDescription);
        })
        .finally(() => {
          this.$parent.loading = false;
        })
    },
    showApplyModal(job) {
      this.$modal.show(
        JobApplyModal,
        {
          jobName: job.name,
          jobId: job.id,
          appliedCallback: () => {
            job.expanded = false;
            job.alreadyApplied = true;
          }
        },
        { width: '60%', height: 'auto' }
      )
    },
    async expandOpportunity(jobId) {
      const canApply = await jobService.canApply(jobId).then(response => response.data.canApply)
      this.opportunities.forEach(item => {
        if (item.id === jobId) {
          item.expanded = !item.expanded ?? true
        }
        if (item.id === jobId && !canApply){
          item.alreadyApplied = true
        }
      })
    },
    isOpened(jobId) {
      return this.openedMapping[jobId]
    }
  }
}
</script>
<style scoped lang="scss">
</style>
