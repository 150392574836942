<template>
  <div class="container-fluid mb-5">
    <div class="row align-items-center padding-bottom-20 justify-content-between">
      <div class="col-6">
        <h1 class="main-title-1">Settings</h1>
      </div>
      <div class="col-2 text-right pr-4">
        <px-button class="px-button--secondary outline icon-left"
                   icon="left-arrow"
                   @click.native="goBack">
          Back
        </px-button>
      </div>
    </div>
    <div class="settings-row row">
      <div class="col-md-4 col-12 m-0 p-0">
        <px-card class="p-5 h-100 bg-dark">
          <form autocomplete="off" @submit.prevent>
            <h2 class="main-title-1">Change password</h2>
            <div class="form-group padding-top-20">
              <label for="current_password" class="card__label">Current password</label>
              <input type="password" name="current_password" id="current_password" class="form-control"
                     required v-model="input.oldPassword" autocomplete="off"/>
            </div>
            <div class="form-group form-group--password">
              <a @click="togglePasswordView" class="form-group__btn-password" data-input="password">
                <i class="fas fa-eye-slash" v-if="showPassword"></i>
                <i class="fas fa-eye" v-else></i>
              </a>
              <label for="password" class="card__label">Password</label>
              <input :type="showPassword ? 'text' : 'password'" name="password" id="password"
                     autocomplete="new-password"
                     class="form-control" required v-model="input.newPassword" @input="checkPassStrength"/>
              <div class="form-group__password-level"
                   :class="'form-group__password-level--' + passwordStrengthMeter">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div class="form-group__generate-password">
                <px-button class="w-100 px-button--secondary" @click.native="generatePassword">
                  Generate password
                </px-button>
              </div>
            </div>
            <div class="form-group form-group--password">
              <label for="reply_password" class="card__label">Repeat password</label>
              <input type="password" name="reply_password" id="reply_password" class="form-control"
                     autocomplete="off" required v-model="input.newPasswordConfirm"/>
            </div>
            <div>
              <px-button class="w-100 px-button--success" @click.native="updatePassword">
                Update password
              </px-button>
            </div>
          </form>
        </px-card>
      </div>
      <div class="col-md-8 col-12 m-0 p-0 d-flex flex-column justify-content-start bg-darker">
        <px-card v-if="user.role === 1 || user.role === 2" class="p-5 no-bg">
          <div class="row">
            <div class="col">
              <div class="padding-top-20 padding-bottom-20">
                <h2 class="main-title-1 main-title-1--icon main-title-1--icon--vpn">Download VPN File</h2>
                <div class="padding-top-20">
                  <px-button class="px-button--success"
                             @click.native="downloadOvpnFile">
                    VPN Config
                  </px-button>
                </div>
              </div>
            </div>
            <div class="col">
<!--              <div class="padding-top-20 padding-bottom-20">-->
<!--                <h2 class="main-title-1 main-title-1&#45;&#45;icon main-title-1&#45;&#45;icon&#45;&#45;notification">-->
<!--                  Push Notifications-->
<!--                </h2>-->
<!--                <div class="padding-top-20">-->
<!--                  <px-button @click.native="manageSubscription"-->
<!--                             :class="subsStatus ? 'px-button&#45;&#45;danger' : 'px-button&#45;&#45;success'"-->
<!--                             :icon="subsStatus ? 'bell-disable':'bell'">-->
<!--                    {{ subsStatus ? 'Disable' : 'Enable' }}-->
<!--                  </px-button>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </px-card>
        <px-card class="p-5 no-bg">
          <div class="row">
            <div class="col">
              <px-button
                  @click.native="goTour"
                  class="px-button--primary icon-left w-100 mb-3">
                take pwnx tour
              </px-button>
            </div>
            <div class="col">
              <px-button
                  @click.native="deactivateAccount"
                  class="px-button--danger icon-left w-100 outline">
                Deactivate account
              </px-button>
            </div>
          </div>
        </px-card>
      </div>
    </div>
  </div>
</template>

<script>
import userService from "../api/user";
import DeactivateModal from "../components/DeactivateModal";
import {mapState} from "vuex";
import PxButton from "../components/PxButton";
import companyService from "../api/company";
import LeaveCompanyModal from '../components/LeaveCompanyModal.vue';
import PxCard from "../components/cards/PxCard";

export default {
  name: "Settings",
  computed: mapState({
    user: state => state.user
  }),
  data() {
    return {
      input: {
        oldPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
      },
      showPassword: false,
      passwordStrengthMeter: 1,
      subscription: null,
      subsStatus: false,
    }
  },
  components: {
    PxButton,
    PxCard
  },
  beforeCreate() {
    this.$parent.breadcrumbs = [{name: "settings"}];
  },
  created() {
    this.loadSubscription();
  },
  methods: {
    loadSubscription() {
      navigator.serviceWorker.ready.then(() => {
        this.$parent.sw.pushManager.getSubscription()
            .then(subscription => {
              this.subscription = subscription;
              this.subsStatus = !!this.subscription;
            })
      });
    },
    togglePasswordView() {
      this.showPassword = !this.showPassword;
    },
    getRandomByte() {
      // http://caniuse.com/#feat=getrandomvalues
      const result = new Uint8Array(1);
      if (window.crypto && window.crypto.getRandomValues) {
        window.crypto.getRandomValues(result);
        return result[0];
      } else {
        return Math.floor(Math.random() * 256);
      }
    },
    generate(length = 16) {
      let pattern = /[a-zA-Z0-9_\-+.]/;
      return Array.apply(null, {"length": length})
          .map(function () {
            let result = '';
            while (!pattern.test(result)) {
              result = String.fromCharCode(this.getRandomByte());
            }
            return result;
          }, this)
          .join('');
    },
    generatePassword() {
      this.input.newPassword = this.generate();
      this.checkPassStrength();
    },
    scorePassword(pass) {
      let score = 0;
      if (!pass) {
        return score;
      }
      // award every unique letter until 5 repetitions
      const letters = {};
      for (let i = 0; i < pass.length; i++) {
        letters[pass[i]] = (letters[pass[i]] || 0) + 1;
        score += 5.0 / letters[pass[i]];
      }
      // bonus points for mixing it up
      const variations = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass),
      };
      let variationCount = 0;
      for (const check in variations) {
        variationCount += (variations[check] === true) ? 1 : 0;
      }
      score += (variationCount - 1) * 10;
      return score;
    },
    checkPassStrength() {
      const score = this.scorePassword(this.input.newPassword);
      if (score > 100) {
        this.passwordStrengthMeter = 4;
      } else if (score > 80) {
        this.passwordStrengthMeter = 3;
      } else if (score >= 50) {
        this.passwordStrengthMeter = 2;
      } else {
        this.passwordStrengthMeter = 1;
      }
    },
    updatePassword() {
      if (this.input.newPassword !== this.input.newPasswordConfirm) {
        this.flashError("New passwords and password confirm don't match");
        return;
      }
      if (this.input.newPassword.length < 8) {
        this.flashError("Password length must be at least 8 characters");
        return;
      }

      this.$parent.loading = true;
      userService.passwordChange(this.input)
          .then(response => {
            const successMessage = !!response && !!response.data && !!response.data.message;
            if (successMessage) {
              this.flashSuccess(response.data.message);
            }
          })
          .catch(error => {
            const manageableError = !!error && !!error.data && !!error.data.errorDescription;
            if (manageableError) {
              this.flashError(error.data.errorDescription);
            }
          })
          .finally(() => this.$parent.loading = false);
    },
    downloadOvpnFile() {
      this.$parent.loading = true;

      userService.getOvpn()
          .then(response => {
            const blob = new Blob([response.data], {type: "text/plain"});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = "config.ovpn";
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
          })
          .catch(error => {
            const manageableError = !!error && !!error.data && !!error.data.errorDescription;
            if (manageableError) {
              this.flashError(error.data.errorDescription);
            }
          })
          .finally(() => this.$parent.loading = false);
    },
    subscribe() {
      this.$parent.manageUserPushChoice(true);
      this.subsStatus = true;
    },
    unsubscribe() {
      this.$parent.loading = true;
      this.subscription.unsubscribe()
          .then(() => {
            this.flashSuccess("User is unsubscribed");
            this.subsStatus = false;
          })
          .catch(() => this.flashError("Error while unsubscribing"))
          .finally(() => this.$parent.loading = false);
    },
    manageSubscription() {
      if (this.subsStatus) {
        this.unsubscribe();
      } else {
        this.subscribe();
      }
    },
    goTour() {
      this.$router.push("/home?tour=true");
      this.$parent.shouldStartTour();
    },
    leaveCompany() {
      this.$modal.show(
          LeaveCompanyModal, {
            returnUserChoice: this.manageLeaveCompany
          }, {height: "auto"}
      )
    },
    manageLeaveCompany(leave, userCompany = null) {
      if (leave) {
        if (userCompany !== this.user.company_name) {
          alert("Company name is invalid, cannot continue");
          return;
        }
        companyService.leaveCompany()
            .then(response => {
              const successMessage = !!response && !!response.data && !!response.data.message;
              if (successMessage) {
                this.flashSuccess(response.data.message);
              }
            })
            .then(() => {
              this.user.company_name = null;
              this.user.company_id = null;
              this.$store.commit('user/setUser', this.user);
            })
            .catch(error => {
              const manageableError = !!error && !!error.data && !!error.data.errorDescription;
              if (manageableError) {
                this.flashError(error.data.errorDescription);
              }
            });
      }
    },
    deactivateAccount() {
      this.$modal.show(
          DeactivateModal,
          {
            returnUserChoice: this.manageAccountDeactivation
          },
          {height: "auto"}
      )
    },
    manageAccountDeactivation(deactivate, email = null) {
      if (deactivate) {
        if (email !== this.user.email) {
          alert("The provided email is not valid");
          return;
        }

        const confirmation = confirm("This action is not reversible. Are you sure?");

        if (confirmation) {
          userService.deactivateUser(email)
              .then(response => {
                const successMessage = !!response && !!response.data && !!response.data.message;
                if (successMessage) {
                  this.flashSuccess(response.data.message);
                }

                return this.$store.dispatch("user/logout");
              })
              .then(() => this.$router.push("/login"))
              .then(() => {
                setTimeout(() => {
                  const goodByeLink = "https://www.youtube.com/watch?v=wVyggTKDcOE";
                  window.open(goodByeLink, "_blank");
                }, 2000)
              })
              .catch(error => {
                const manageableError = !!error && !!error.data && !!error.data.errorDescription;
                if (manageableError) {
                  this.flashError(error.data.errorDescription);
                }
              })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/css/colors.scss";

i.fas {
  color: #768aa3;
}

a#companyLink {
  color: #5f8e9e;
  font-weight: bold;
}

.settings-row {
  [class*="col-"] {
    background: rgba($neutral--medium, 0.8);
  }
}

.px-card {
  padding: 0;
  margin: 0;
  border: 1px solid rgba(255, 255, 255, 0.04);
  background: none;
}

</style>
