import httpService from "./http";

const userService = {
  /**
   * register
   * @param data
   * @returns {Promise<unknown>}
   */
  send_referral(email) {
    const httpOptions = {
      data: {
        email: email,
      },
    };
    return httpService.post("/v2/users/referral", httpOptions);
  },
  register(data) {
    const httpOptions = {
      data,
    };
    return httpService.post("/register", httpOptions, false);
  },
  confirmSignOn(signOnId) {
    return httpService.post(`/register/${signOnId}`, {}, false);
  },
  /**
   * login
   * @param data
   * @returns {Promise<unknown>}
   */
  login(data) {
    const headers = {
      "content-type": "application/x-www-form-urlencoded;charset=utf-8",
    };
    const auth = {
      username: process.env.VUE_APP_CLIENT_ID,
      password: process.env.VUE_APP_CLIENT_SECRET,
    };
    const httpOptions = {
      headers,
      data,
      auth,
    };
    return httpService.post("/oauth/token", httpOptions, false);
  },
  /**
   * passwordReset
   * @param data
   * @returns {Promise<unknown>}
   */
  passwordReset(data) {
    const httpOptions = {
      data,
    };
    return httpService.post("/password/reset", httpOptions, false);
  },
  resetPassword(resetId) {
    return httpService.post(`/password/reset/${resetId}`, {}, false);
  },
  /**
   * passwordChange
   * @param data
   * @returns {Promise<unknown>}
   */
  passwordChange(data) {
    const httpOptions = {
      data,
    };
    return httpService.post("/password/change", httpOptions);
  },
  /**
   * getUser
   * @returns {Promise<unknown>}
   */
  getCurrentUser() {
    return httpService.get("/users");
  },
  logout() {
    return httpService.get("/v2/users/logout");
  },
  /**
   * getUser
   * @param userId
   * @returns {Promise<unknown>}
   */
  getUser(userId) {
    return httpService.get(`/users/${userId}`);
  },
  /**
   * getActivities
   * @returns {Promise<unknown>}
   */
  getActivities(userId) {
    return httpService.get(`/users/${userId}/activities`);
  },
  /**
   * getTeam
   * @returns {Promise<unknown>}
   */
  getTeam(userId) {
    return httpService.get(`/users/${userId}/team`);
  },
  getOvpn() {
    const httpOptions = {
      responseType: "blob",
    };
    return httpService.get("/users/ovpn", httpOptions);
  },
  update(userId, data) {
    const httpOptions = {
      data,
    };
    return httpService.put(`/users/${userId}`, httpOptions);
  },
  all() {
    return httpService.get("/users/all");
  },
  hasBeenNotified() {
    return httpService.post("/users/notified");
  },
  getData(userId) {
    return httpService.get(`/users/${userId}/data`);
  },
  getRank(userId) {
    return httpService.get(`/users/${userId}/rank`);
  },
  deactivateUser(email) {
    const httpOptions = {
      data: {
        email,
      },
    };
    return httpService.post("/deactivate", httpOptions);
  },
  resendActivationEmail(data) {
    const httpOptions = {
      data,
    };
    return httpService.post("/users/send-activation-email", httpOptions);
  },
  getEventLabResets() {
    return httpService.get("/v2/users/resets");
  },
  checkUsername(username) {
    return httpService.get(`/users/exists/username/${username}`);
  },
  checkEmail(email) {
    return httpService.get(`/users/exists/email/${email}`);
  },
  isInviteLink(companyToken) {
    return httpService.get(`/register?companyToken=${companyToken}`);
  },
  getSurvey(chapterId = null) {
    if (!chapterId) return httpService.get("/v2/users/questions");
    return httpService.get(["/v2/users/questions", chapterId].join("/"));
  },
  saveUserSurvey(data) {
    const httpOptions = { data };
    return httpService.post("/v2/users/questions", httpOptions);
  },
  saveChapterQuestions(data, chapterId) {
    const httpOptions = { data };
    return httpService.post(
      ["/v2/users/questions", chapterId].join("/"),
      httpOptions
    );
  },
  updateUsername(data) {
    const httpOptions = { data };
    return httpService.put("/v2/users/username", httpOptions);
  },
  getLatestSolved(solveType, { userId = null, courseId = null }) {
    let url = ["/v2/users/latest-solved", solveType];

    if (userId !== null) {
      url.push(userId);
    }

    url = url.join("/");
    if (courseId !== null) {
      url += "?courseId=" + courseId;
    }

    return httpService.get(url);
  },
  getAllLatestSolved(courseId) {
    let url = "/v2/users/latest-solved/all";
    if (courseId !== null) url += "?courseId=" + courseId;
    return httpService.get(url);
  },
};

export default userService;
