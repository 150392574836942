<template>
  <div class="container-fluid">
    <div class="row" v-if="!this.$parent.$data.isPurpleEvent && !this.isRedEvent && !this.$parent.$data.isGreenEvent">
      <div class="col-12">
        <div class="row my-2 submission d-flex align-items-center" v-for="(time, key) in lab.flags" :key="time"
          :class="{ 'submission--user': key === 'user', 'submission--root': key === 'root', 'placeholder': lab.placeholder }">
          <div class="col submission--time text-center pl-0">
            <p class="mb-0">{{ getTimeDifference(time) }}</p>
          </div>
          <div class="col text-center">
            <p class="mb-0">
              <i class="fas fa-flag" :class="[key == 'root' ? 'flag2' : 'flag1']"></i>
            </p>
          </div>
          <div class="col text-center">
            <p class="mb-0">
              {{ lab.name }}
            </p>
          </div>
          <div class="col text-center">
            <p class="mb-0">
              {{ lab[`${key}Points`] }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="this.$parent.$data.isBlueEvent">
      <div class="col-12">
        <div class="row my-2 submission d-flex align-items-center" v-for="(time, key) in lab.completed" :key="time.id"
          :class="{ 'submission--user': key === 'user', 'submission--root': key === 'root', 'placeholder': lab.placeholder }">
          <div class="col-2 submission--time text-center pl-0">
            <p class="mb-0">{{ getTimeDifference(time.submitted) }}</p>
          </div>
          <div class="col-4 text-center">
            <p class="mb-0">
              {{ time.name }}
            </p>
          </div>
          <div class="col-4 text-center">
            <p class="mb-0">
              {{ time.username }}
            </p>
          </div>
          <div class="col-2 text-center">
            <p class="mb-0">
              {{ time.points }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="(this.isRedEvent || this.$parent.$data.isGreenEvent) && submissions.length > 0">
      <div class="col-12">
        <div class="row my-2 submission d-flex align-items-center" v-for="(data, key) in submissions" :key="data.id"
          :class="{ 'submission--user': key === 'user', 'submission--root': key === 'root', 'placeholder': data.placeholder }">
          <div :class="[isRedAllowed() ? 'col-2' : 'col-3']" class="submission--time text-center pl-0">
            <p class="mb-0">{{ getTimeDifference(data.submitted, data.ticket_start) }}</p>
          </div>
          <div :class="[isRedAllowed() ? 'col-4' : 'col-6']" class="text-center d-flex flex-row justify-content-center">
            <p class="mb-0">
              {{ data.name }}
            </p>
            <div class="ml-2 text-center">
              <p class="mb-0">
                <i class="fas fa-flag" :class="[data.root ? 'flag2' : 'flag1']"></i>
              </p>
            </div>
          </div>
          <div class="col-4 text-center d-flex flex-row justify-content-center" v-if="isRedAllowed()">
            <flag :iso="data.user_country" :squared="false"></flag>
            <p class="mb-0 ml-2">
              {{ data.username }}
            </p>
          </div>
          <div class="col-1 text-center" v-if="isRedAllowed()">
            <p class="mb-0">
              <i class="fas fa-users" :style="{ cursor: 'pointer' }" @click="$router.push(`/team/${data.team_id}`)"></i>
            </p>
          </div>
          <div class="col-1 text-center" v-if="isRedAllowed()">
            <p class="mb-0">
              {{ data.points }}
            </p>
          </div>
          <div class="col-1 text-center" v-if="!isRedAllowed()">
            <p class="mb-0">
              {{ getFlagPoints(data) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventService from "@/api/event";

export default {
  name: "EventSubmissionsLog",
  data() {
    return {
      eventId: this.$parent.$data.event.id,
      eventStartTime: this.$parent.$data.event.dateStart,
      isRedEvent: this.$parent.$data.isRedEvent,
      submissions: [],
      submissionInterval: null
    }
  },
  props: {
    lab: {
      required: false,
    },
    currentTeam: {
      required: false
    }
  },
  created() {
    const isRedEvent = this.isRedEvent;
    const isGreenEvent = this.$parent.$data.isGreenEvent;
    if (isRedEvent || isGreenEvent) {
      this.loadSubmissions()
      this.submissionsInterval = setInterval(() => this.loadSubmissions(), 30000)
    }
  },
  beforeDestroy() {
    if (this.submissionInterval !== null) clearInterval(this.submissionInterval)
  },
  methods: {
    getFlagPoints(flag) {
      const type = flag.type
      return flag[type + "Points"]
    },
    isRedAllowed() {
      const isRedEvent = this.isRedEvent;
      if (!isRedEvent) return false
      return true;
    },
    loadSubmissions() {
      const eventId = this.eventId;
      eventService.getEventSubmissions(eventId)
        .then((response) => {
          this.submissions = response.data.submissions
        })
    },
    getTimeDifference(time, ticketStart = null) {
      const isGreenEvent = this.$parent.$data.isGreenEvent;
      let startTime;
      if (isGreenEvent) {
        startTime = ticketStart * 1000;
      } else {
        startTime = this.eventStartTime
      }

      let value = new Date(startTime)
      let value2 = new Date(time + "Z")
      let diff = value2 - value
      let diffHrs = Math.floor((diff % 86400000) / 3600000);
      let diffMins = Math.round(((diff % 86400000) % 3600000) / 60000);
      let diffDays = Math.ceil(diff / (1000 * 3600 * 24)) - 1;
      let solvedIn = ``;
      if (diffDays > 0) {
        solvedIn = `${diffDays}d - `
      }
      return solvedIn += `${diffHrs.toString().length < 2 ? `0${diffHrs}` : `${diffHrs}`}:${diffMins.toString().length === 1 ? `0${diffMins}` : diffMins} min`
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";

.submission--purple {
  color: $soft-purple;

  .submission-icon {
    display: none;
  }

  .submission--time {
    p {
      color: $soft-purple;
      background: rgba($soft-purple, .1);
      border-radius: 1px;
    }
  }

  &__current-team {
    color: $soft-red !important;

    .submission--time {
      p {
        color: $soft-red;
        background: rgba($soft-red, .1);
        border-radius: 1px;
      }
    }
  }

  &__current-team-submit {
    color: $soft-green !important;

    .submission--time {
      p {
        color: $soft-green;
        background: rgba($soft-green, .1);
        border-radius: 1px;
      }
    }
  }
}

.submission {
  &.placeholder {
    p {
      margin: 5px 0;
      color: transparent;
      background: white;
      height: 15px;
      opacity: .1;
      border-radius: 9px;
    }
  }
}


.flag1 {
  color: $soft-green;
}

.flag2 {
  color: $soft-orange;
}
</style>
