const LabType = Object.freeze({
  NORMAL: 0,
  REDBLUE: 1,
  BLUE: 2,
  PURPLE: 3,
  ONESHOT: 4,
  RED: 5,
  PREMIUM_WEB: 6,
  PREMIUM_WEB3: 7,
});

const AwsLabStatus = Object.freeze({
  COMPLETED: "COMPLETED",
  DRAINING: "DRAINING",
  PENDING: "PENDING",
  IN_PROGRESS: "IN_PROGRESS"
});

export { LabType, AwsLabStatus };

