<template>
  <div class="container">
    <div class="modal-overlay" v-if="!confirmationModalHidden" style="opacity: 0.5"
      @click="() => confirmationModalHidden = true"></div>
    <div class="confirm-modal" v-show="!confirmationModalHidden">
      <ConfirmationModal :modalTitle="modalTitle" :confirmAction="ticketSubmit"
        :closeAction="() => confirmationModalHidden = true">
        <h2 class="my-3" style="font-size: 20px">
          <span class="text-warning fw-bold">WARNING:</span>
          Once used the ticket will be invalidated, do you want continue?
        </h2>
      </ConfirmationModal>
    </div>
    <div id="banner" class="my-5 text-center">
      <div id="headings" class="d-flex flex-sm-column flex-xl-row justify-content-center">
        <h3>This event is private</h3>
      </div>
      <p>You need a valid ticket to participate to this event</p>
    </div>
    <div class="row">
      <div class="col-md-6 text-center">
        <img src="https://pwnxassets.blob.core.windows.net/pwnx-events-pics/pwnx-ticket-join.png" alt="ticket"
          class="mx-auto d-block" id="join-image" />
      </div>
      <div class="col-md-6 text-center d-sm-flex justify-content-center my-5 d-flex align-items-center">
        <form action="#" @submit="ticketSubmit">
          <div id="ticket-input" class="form-group d-flex justify-content-center flex-column">
            <label for="ticket-input" class="card__label">Ticket</label>
            <input ref="ticketInput" type="text" v-model="ticket" id="ticket-input" placeholder="ticket"
              class="form-control" :class="{ 'border border-danger animate': this.error }" required
              @input="() => this.error = false" />
            <!-- <px-button -->
            <!--   class="px-button--success my-2" -->
            <!--   @click.native="() => confirmationModalHidden = !confirmationModalHidden" -->
            <!--   >join</px-button -->
            <!-- > -->
            <px-button class="px-button--success my-2" @click.native="ticketSubmit">join</px-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import eventService from "../api/event.js";
import PxButton from "../components/PxButton";
import { EventType } from '../enums/event';
import ConfirmationModal from "@/components/ConfirmationModal.vue"

export default {
  name: "EventTicketJoin",
  data() {
    return {
      confirmationModalHidden: true,
      eventId: this.$route.params.eventId,
      ticket: null,
      joinTicket: this.$route.query.joinTicket,
      error: false,
      event: null,
      modalTitle: ["Joining Event", this.eventId].join(" "),
    };
  },
  components: {
    PxButton,
    ConfirmationModal
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    selfClose() {
      this.confirmationModalHidden = true;
    },
    async ticketSubmit(e) {
      try {
        e.preventDefault();
      } catch (e) {
        // joinTicket is probably defined
      }
      if (!this.ticket) {
        this.flashError("Ticket cannot be empty");
        this.error = true;
        return;
      }

      if (this.confirmationModalHidden) {
        this.confirmationModalHidden = false;
        return;
      }

      this.$parent.loading = true;
      eventService.joinEventWithTicket(this.eventId, this.ticket).then(response => {
        const manageableMessage = !!response.data && !!response.data.message
        if (manageableMessage) {
          this.flashSuccess(response.data.message);
          this.$router.push(`/event/${this.eventId}`);
          return;
        }
      }).catch(error => {
        const manageableError = !!error.data && !!error.data.errorCode && !!error.data.errorDescription;
        if (manageableError) this.flashError(error.data.errorDescription)
      }).finally(() => {
        this.$parent.loading = false;
        this.confirmationModalHidden = true;
      });
    },
  },
  created() {
    if (this.joinTicket != null) {
      this.ticket = this.joinTicket;
    }

    let applicable = null;

    eventService.getEvent(this.eventId).then(response => {
      this.event = response.data.event;

      if (this.event.type == EventType.RED)
        applicable = eventService.canApplyTeam
      else
        applicable = eventService.canApplyUser

      const allowedEvents = [EventType.GREEN, EventType.RED, EventType.NORMAL];
      const isAllowed = allowedEvents.some(type_ => this.event.type === type_)
      if (!isAllowed) this.$router.push("/events");
      return applicable(this.eventId)
    }).then((response) => {
      const { canApplyUser } = response.data;
      const { canApplyTeam } = response.data;
      if (!canApplyUser && !canApplyTeam) {
        this.flashError("You're not allowed to visit this page");
        this.$router.push(`/event/${this.eventId}`);
        return;
      }
      const ticketInput = this.$refs.ticketInput;
      if (ticketInput !== undefined) ticketInput.focus();
    }).finally(() => {
      this.$parent.loading = false;
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/colors.scss";

#join-image {
  width: 400px;
  height: 400px;
  transition: all 1s linear;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

@media screen and (max-width: 768px) {
  #join-image {
    width: 350px;
    height: 350px;
  }
}

.animate {
  transition: all 0.1s linear;
  animation: animate_error 0.1s linear alternate;
}

@keyframes animate_error {
  0% {
    transform: translateX(10%);
  }

  50% {
    transform: translateX(-10%);
  }

  100% {
    transform: translateX(10%);
  }
}

.confirm-modal {
  position: absolute;
  width: 800px;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
}

.modal-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  opacity: 0.5;
  z-index: 99;
}

@media screen and (max-width: 768px) {
  .confirm-modal {
    width: 400px;
  }
}
</style>
