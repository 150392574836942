<template>
    <div class="lp-modal d-flex justify-content-center align-items-center flex-column w-100">
        <div class="d-flex flex-column justify-content-start w-100 graph-overlay" :class="{ 'active': graphOverlay }">
            <!-- New -->
            <div class="lp-modal-content d-flex justify-content-start align-items-center flex-column w-100">
                <div id="" class="d-flex flex-row justify-content-start align-items-start w-100 h-100 m-0 p-0">
                    <i @click="closeGraphOverlay()" id="xmark" class="fa-2xl fa-solid fa-xmark mt-1"></i>
                    <div class="d-md-none d-block arrow-right-container" style="position: absolute; cursor: pointer;"
                        @click="modalSelector = true">
                        <div class="arrow right" style="border-color: #fff"></div>
                    </div>
                    <div class="d-flex justify-content-start align-items-start m-0 p-0 w-100 flex-column modal-next-overlay"
                        :class="{ 'active': modalSelector }">
                        <div class="d-flex justify-content-between align-items-center flex-row w-100">
                            <h2 class="w-100 main-title-2 pl-3 d-md-block d-flex justify-content-between">chapters
                                <i @click="modalSelector = false" style="cursor: pointer"
                                    class="d-md-none d-block fa-solid fa-arrow-left fa-2xl pr-2 pt-2"></i>
                            </h2>
                            <select v-model="courseId" name="course-selector" id="course-selector"
                                class="w-100 form-control">
                                <option v-for="(course) of courses" :key="course.id" :value="course.id">{{ course.name
                                    }}</option>
                            </select>
                        </div>
                        <div id="chapters-content" class="w-100 border-bottom border-top h-100"
                            v-if="sChapters.length > 0">
                            <ul class="w-100" id="chapter-ul">
                                <li id="chapter-li" class="d-flex flex-column justify-content-between align-items-start"
                                    @click="getLabsByChapterId(chapter.id)" v-for="(chapter) of sChapters"
                                    :key="chapter.id" :class="{ 'active': chapter.id === sChapterId }">
                                    <div class="d-flex flex-row justify-content-between align-items-start w-100">
                                        <h2 class="m-0 p-0 main-title-2 w-100 li-title"
                                            :class="{ 'active': sChapterId === chapter.id }">
                                            {{ chapter.name }}
                                        </h2>
                                        <div class="d-flex justify-content-end align-items-center w-100">
                                            <i class="fa-solid fa-pen-nib"></i>
                                            <div class="w-100 mx-1" id="chapter-completion-bar"
                                                :style="`--completion: '${chapter.completion.quiz}%'`">
                                                <div id="chapter-completion-bar--progress"
                                                    :style="`--completion: ${chapter.completion.quiz}%`">
                                                </div>
                                            </div>
                                            <i class="fa-solid fa-computer"></i>
                                            <div class="mx-1" id="chapter-completion-bar"
                                                :style="`--completion: '${chapter.completion.labs}%'`">
                                                <div id="chapter-completion-bar--progress"
                                                    :style="`--completion: ${chapter.completion.labs}%`">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <p class="m-0 p-0 w-100">{{ chapter.description }}</p> -->
                                </li>
                            </ul>
                        </div>
                        <div id="chapters-content"
                            class="w-100 d-flex justify-content-center align-items-center border-bottom border-top h-100"
                            v-if="sChaptersLoading">
                            <LocalSpinner></LocalSpinner>
                        </div>

                        <div id="chapters-content"
                            class="w-100 d-flex justify-content-center flex-column align-items-center border-bottom border-top h-100"
                            v-if="!sChaptersLoading && sChapters.length == 0">
                            <h2 class="main-title-2">Seems like you've completed all the available courses</h2>
                            <h2 class="main-title-2">We will let you know when newer courses will be available</h2>
                        </div>

                        <div id="labs"
                            class="d-flex flex-row justify-content-start align-items-start w-100 flex-column h-100 m-0 p-0">
                            <div class="m-0 p-0 d-flex flex-row justify-content-start align-items-center w-100 h-100">
                                <h2 class="main-title-2 pl-3 my-3 w-100">labs</h2>
                                <div
                                    class="py-0 my-0 labglob-toggle d-flex justify-content-end w-100 h-100 align-items-center mr-5">
                                    <h2 class="m-0 p-0 mr-3">
                                        show all course labs
                                    </h2>
                                    <label class="switch">
                                        <input @click="toggleLabGlobal" type="checkbox">
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <div id="labs-content" class="border-top w-100" v-if="showLabs && labsList.length > 0">
                                <ul class="w-100" id="lab-ul">
                                    <li id="lab-li"
                                        class="d-flex flex-column justify-content-between align-items-center"
                                        v-for="(lab) of labsList" :key="lab.id">
                                        <div class="d-flex flex-row justify-content-start align-items-center w-100">
                                            <h2 class="m-0 p-0 main-title-2 w-100">
                                                {{ lab.name }}
                                            </h2>
                                            <div class="lab--difficulty w-100 mx-2"
                                                :style="`--difficulty: '${lab.difficulty}%'`">
                                                <div :style="`--difficulty: ${lab.difficulty}%`"
                                                    class="lab--difficulty--progress w-100">
                                                </div>
                                            </div>
                                            <template v-if="!lab.no_container">
                                                <i class="mx-1 fa-solid fa-spinner fa-spin"
                                                    v-if="!isStarted(lab.id) && isStarting(lab.id)"></i>
                                                <i v-if="!isStarted(lab.id) && !isStarting(lab.id)" id="green"
                                                    @click="labAction(lab.id, 'start')" class="mx-1 fa-solid fa-play"></i>
                                                <i v-if="isStarted(lab.id) && !isStarting(lab.id)" id="red"
                                                    @click="labAction(lab.id, 'stop')" class="mx-1 fa-solid fa-square"></i>
                                                <i v-if="isStarted(lab.id) && !isStarting(lab.id)" id="orange"
                                                    class="mx-1 fa-solid fa-download" @click="downloadVPN(lab.id)"></i>
                                                <i v-if="isStarted(lab.id) && !isStarting(lab.id)" id="blue"
                                                    class="mx-1 fa-solid fa-note-sticky" @click="handleGetWriteup(lab)"></i>
                                                <i v-if="isStarted(lab.id) && !isStarting(lab.id)" 
                                                    @click="copyIpAddress(lab.id)"
                                                    class="mx-1 fa-solid fa-clipboard">
                                                </i>
                                            </template>
                                            <template v-if="lab.no_container">
                                                <i @click="downloadLabTemplate(lab.id)" class="mx-2 fa-solid fa-download"></i>
                                                <i @click="openFlagSubmitModal(lab.id)" class="mx-2 fa-solid fa-flag"></i>
                                            </template>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div id="labs-content"
                                class="d-flex justify-content-center border-top align-items-center border-top w-100"
                                v-if="showLabs && labsList.length === 0 && labsLoading">
                                <LocalSpinner></LocalSpinner>
                            </div>
                            <div id="labs-content"
                                class="d-flex justify-content-center border-top align-items-center border-top w-100"
                                v-if="showLabs && labsList.length === 0 && !labsLoading">
                                <h2 class="main-title-2">This chapter doesn't have any lab</h2>
                            </div>
                        </div>
                    </div>
                    <div class="border-left px-3 chapter-content-wrapper w-100 py-2">
                        <div class="chapter-content w-100" v-if="sChapter !== null && !sChapterLoading"
                            v-html="sChapter"></div>
                        <px-button @click.native="startChapterQuiz" class="px-button--success outline w-100"
                            v-if="!sChapterSolved && sChapter !== null && !sChapterLoading && !sChapterComplete">start
                            quiz</px-button>
                        <div style="min-height: 70vh!important;overflow: hidden!important;"
                            class="d-flex justify-content-center align-items-center w-100 h-100" v-if="sChapterLoading">
                            <LocalSpinner></LocalSpinner>
                        </div>
                        <div class="chapter-content w-100 d-flex justify-content-center flex-column align-items-center text-uppercase"
                            style="min-height: 70vh;" v-if="sChapter === null && !sChapterLoading">
                            <p style="font-weight:700; line-height: 1; margin: 0;padding: 0;">seems like you've
                                completed all the
                                available courses</p>
                            <p style="font-weight:700; line-height: 1; margin: 0;padding: 0;">we will let you know when
                                newer courses
                                will be available</p>
                        </div>
                    </div>
                    <div class="compliance-quiz-btn-wrapper" :class="{ 'active': cOverlay }" v-if="isApplicable">
                        <div @click="toggleCOverlay" :class="{ 'active': cOverlay }"
                            class="compliance-arrow-container d-flex justify-content-center align-items-center">
                            <i v-if="cOverlay" class="arrow right"></i>
                            <i v-if="!cOverlay" style="border-color: #fff" class="arrow left"></i>
                        </div>
                        <div
                            class="compliance-quiz-btn-content d-flex flex-column justify-content-start align-items-start">

                            <h2 class="main-title-2 pr-1 m-0 text-right w-100">Compliance Quiz Available</h2>
                            <p v-if="!isParticipating" style="line-height: 1" class="m-0 p-0 text-right w-100 pr-1">
                                Start now or Delay</p>

                            <div
                                class="compliance-overlay-btns d-flex justify-content-end align-items-end w-100 m-0 p-0 pl-4">
                                <px-button v-if="!isParticipating" @click.native="startComplianceQuiz"
                                    class="w-100 mx-1 px-button--success outline">start</px-button>
                                <px-button v-if="!isParticipating"
                                    class="w-100 mx-1 px-button--danger outline">delay</px-button>
                                <px-button @click.native="startComplianceQuiz" v-if="isParticipating"
                                    class="w-100 mx-1 px-button--secondary">REMAINING: {{ formatDate(timer)
                                    }}</px-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- -->
        </div>
        <div id="header-content" class="d-flex justify-content-start align-items-start flex-row-reverse w-100">
            <div id="user-data" class="d-none d-md-flex justify-content-between flex-row align-items-center"
                :style="`background: url('${image}');`">
                <div id="user-data-details-overlay"
                    class="d-flex justify-content-between flex-row-reverse align-items-center">
                    <div class="d-flex justify-content-center flex-column align-items-center p-4"
                        id="user-data-details">
                        <div id="user-data-title" class="mb-5">
                            <h1 class="main-title-1 m-0 p-0">User Stats</h1>
                        </div>
                        <div class="flex-row d-flex justify-content-between align-items-center w-100">
                            <h2 class="main-title-2 m-0 p-0">username:</h2>
                            <p class="m-0 p-0">{{ stats.username }}</p>
                        </div>
                        <div class="flex-row d-flex justify-content-between align-items-center w-100">
                            <h2 class="main-title-2 m-0 p-0">created:</h2>
                            <p class="m-0 p-0">{{ stats.created.replace("T", " ") }}</p>
                        </div>
                        <div class="flex-row d-flex justify-content-between align-items-center w-100">
                            <h2 class="main-title-2 m-0 p-0">company rank:</h2>
                            <p class="m-0 p-0">{{ stats.rank }}°</p>
                        </div>
                        <div class="flex-row d-flex justify-content-between align-items-center w-100">
                            <h2 class="main-title-2 m-0 p-0">flags:</h2>
                            <p class="m-0 p-0">{{ stats.flags }}</p>
                        </div>
                        <div class="flex-row d-flex justify-content-between align-items-center w-100">
                            <h2 class="main-title-2 m-0 p-0">event flags:</h2>
                            <p class="m-0 p-0">{{ stats.event_flags }}</p>
                        </div>
                        <div class="flex-row d-flex justify-content-between align-items-center w-100">
                            <h2 class="main-title-2 m-0 p-0">company name:</h2>
                            <p class="m-0 p-0">{{ user.company_name }}</p>
                        </div>
                    </div>
                    <div class="d-flex justify-content-start align-items-start" id="profile-pic">
                        <img :src="stats.profile_pic" :alt="stats.username">
                    </div>
                </div>
            </div>
            <div class="w-100 d-md-flex d-none flex-column justify-content-center align-items-center mt-0 mt-md-1">
                <v-chart v-if="!radarIsLoading" id="pwnx-graph" ref="radar"
                    class="graph text-center d-flex justify-content-center align-items-start w-100"
                    :options="radar"></v-chart>
                <LocalSpinner v-if="radarIsLoading"></LocalSpinner>
            </div>
            <div id="course-details-container" class="d-flex w-100 justify-content-start align-items-center px-4">
                <div id="course-details" class="d-flex w-100 justify-content-start align-items-start flex-column">
                    <!-- latest chapter solved -->
                    <div class="d-flex flex-row justify-content-center align-items-center w-100 py-0 my-0">
                        <div id="latest-chapter-solved"
                            class="d-flex w-100 flex-column justify-content-between align-items-center m-0 p-0">
                            <div class="d-md-none d-block text-right w-100 m-0 p-0 xmark-container"><i
                                    style="cursor: pointer;" class="fa-solid fa-xmark fa-2xl m-0 p-0"
                                    @click="selfClose()"></i>
                            </div>
                            <h2 class="m-0 p-0 w-100" style="white-space: nowrap">latest solved</h2>
                            <div id="course-progress-card-container"
                                class="w-100 d-flex justify-content-end align-items-end">
                                <!-- <div id="progress-card" class="d-flex flex-column justify-content-end align-items-end w-100"> -->
                                <!---->
                                <!-- </div> -->
                                <div id="progress-card"
                                    class="d-flex flex-column justify-content-end align-items-end w-100"
                                    v-if="latestChapter !== null && !hasNeverSolved">
                                    <div id="progress-card-banner"
                                        class="d-flex justify-content-between align-items-center flex-row-reverse w-100">
                                        <h2 class="p-0 m-0 main-title-2 text-right w-100">{{ hasNeverSolved ? `This is a
                                            placeholder, solve chapters to populate this card`: latestChapter.name }}
                                        </h2>
                                    </div>
                                    <div id="chapter-card-completion"
                                        class="d-flex justify-content-center flex-column align-items-center w-100 mt-1">
                                        <div id="quiz-row"
                                            class="d-flex w-100 flex-row justify-content-between align-items-center mb-1">

                                            <h2 class="w-100 m-0 p-0 main-title-2 border text-center p-1 mx-3">chapter
                                            </h2>
                                            <div id="chapter-completion-bar"
                                                :style="`--completion: '${hasNeverSolved ? '100' : latestChapter.completion.quiz}%';`">
                                                <div id="chapter-completion-bar--progress"
                                                    :style="`--completion: ${hasNeverSolved ? '100' : latestChapter.completion.quiz}%;`">
                                                </div>
                                            </div>
                                        </div>
                                        <div id="lab-row"
                                            class="d-flex w-100 flex-row justify-content-between align-items-center mb-1">
                                            <h2 class="w-100 m-0 p-0 main-title-2 border text-center p-1 mx-3">lab</h2>
                                            <div id="chapter-completion-bar"
                                                :style="`--completion: '${hasNeverSolved ? '100' : latestChapter.completion.labs}%';`">
                                                <div id="chapter-completion-bar--progress"
                                                    :style="`--completion: ${hasNeverSolved ? '100' : latestChapter.completion.labs}%;`">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="progress-card"
                                    class="d-flex flex-column justify-content-center align-items-center w-100"
                                    v-if="(latestChapterLoading || !latestChapter || hasNeverSolved) && !lspPlaceholder">
                                    <LocalSpinner></LocalSpinner>
                                </div>
                                <div v-if="lspPlaceholder" id="progress-card"
                                    class="d-flex flex-column justify-content-center align-items-center w-100">
                                    <div class="row m-0 p-0 w-100">
                                        <div class="text-center m-0 p-0 col-md-6">
                                            <div class="placeholder-box"></div>
                                            <div class="placeholder-box"></div>
                                        </div>
                                        <div class="text-center m-0 p-0 col-md-6">
                                            <div class="placeholder-box"></div>
                                            <div class="placeholder-box"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- next chapter to solve -->
                    </div>
                    <div id="next-chapter-tosolve"
                        class="d-flex w-100 flex-column justify-content-start align-items-start">
                        <h2 class="w-100 m-0 p-0" style="white-space: nowrap">next to solve</h2>
                        <div id="course-progress-card-container" class="w-100"
                            :class="{ 'd-flex justify-content-end align-items-end': !hasNeverSolved }">
                            <div id="progress-card" class="d-flex justify-content-end align-items-end flex-column w-100"
                                v-if="notSolvedChapter !== null">
                                <div id="progress-card-banner"
                                    class="d-flex justify-content-between align-items-center flex-row-reverse w-100">
                                    <h2 @click="chapterDetails(notSolvedChapter)"
                                        class="p-0 m-0 main-title-2 text-right w-100">{{
                                            hasNeverSolved ? `Start solving
                                        chapters to have suggestions`: notSolvedChapter.name }}</h2>
                                </div>
                                <div id="chapter-card-completion"
                                    class="d-flex justify-content-center flex-column align-items-center w-100 mt-1">
                                    <div id="quiz-row"
                                        class="d-flex w-100 flex-row justify-content-between align-items-center mb-1">

                                        <h2 class="w-100 m-0 p-0 main-title-2 border text-center p-1 mx-3 w-100">chapter
                                        </h2>
                                        <div id="chapter-completion-bar"
                                            :style="`--completion: '${hasNeverSolved ? `100` : notSolvedChapter.completion.quiz}%';`">
                                            <div id="chapter-completion-bar--progress"
                                                :style="`--completion: ${hasNeverSolved ? `100` : notSolvedChapter.completion.quiz}%;`">
                                            </div>
                                        </div>
                                    </div>
                                    <div id="lab-row"
                                        class="d-flex w-100 flex-row justify-content-between align-items-center mb-1 position-relative">
                                        <div v-if="sChaptersNotSolvedIndex > 0"
                                            class="arrow left position-absolute border-white" id="next-solve-left"
                                            @click="updateNextSolved('prev')">
                                        </div>
                                        <div v-if="sChaptersNotSolvedIndex < sChaptersNotSolved.length - 1"
                                            class="arrow right position-absolute border-white" id="next-solve-right"
                                            @click="updateNextSolved('next')">
                                        </div>
                                        <h2 class="w-100 m-0 p-0 main-title-2 border text-center p-1 mx-3">lab</h2>
                                        <div id="chapter-completion-bar"
                                            :style="`--completion: '${hasNeverSolved ? `100` : notSolvedChapter.completion.labs}%';`">
                                            <div id="chapter-completion-bar--progress"
                                                :style="`--completion: ${hasNeverSolved ? `100` : notSolvedChapter.completion.labs}%;`">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="progress-card"
                                class="d-flex justify-content-center align-items-center flex-column w-100"
                                v-if="notSolvedChapter === null && !ntsPlaceholder">
                                <LocalSpinner></LocalSpinner>
                            </div>
                            <div id="progress-card"
                                class="d-flex justify-content-center align-items-center flex-column w-100"
                                v-if="ntsPlaceholder">
                                <div class="row m-0 p-0 w-100">
                                    <div class="m-0 p-0 col-md-6">
                                        <div class="placeholder-box"></div>
                                        <div class="placeholder-box"></div>
                                    </div>
                                    <div class="m-0 p-0 col-md-6">
                                        <div class="placeholder-box"></div>
                                        <div class="placeholder-box"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- -->

        <div class="w-100 flex-row d-flex h-100" id="graphs-container">
            <template>
                <div class="modal-graph w-100" v-for="(graph, index) of graphs" :key="index">
                    <v-chart id="pwnx-graph" :ref="graph.id" v-if="!graph.isEmpty()" class="w-100 modal-graph"
                        :options="graph"></v-chart>
                    <div class="modal-graph-overlay-wrapper" v-if="graph.isEmpty()">
                        <div class="modal-graph-overlay">
                            <LocalSpinner></LocalSpinner>
                        </div>
                    </div>
                </div>
            </template>
            <div id="graph-overlay-arrow" v-if="!graphOverlay" @click="graphOverlay = true">
                <i class="arrow left"></i>
            </div>
        </div>

        <!-- -->
    </div>
</template>
<style lang="scss" scoped>
@import "@/assets/css/colors.scss";

.lp-modal {
    background-color: $neutral--darkest;

    --max-height: 300px;
    --max-height-propic: 250px;
}

#header-content {
    max-height: var(--max-height);
    overflow: hidden;
}

#profile-pic {

    min-width: var(--max-height-propic);
    max-width: var(--max-height-propic);

    max-height: var(--max-height-propic);
    min-height: var(--max-height-propic);

    position: relative;
    overflow: hidden;

    img {

        min-width: var(--max-height-propic);
        max-width: var(--max-height-propic);

        max-height: var(--max-height-propic);
        min-height: var(--max-height-propic);

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        object-fit: cover;

        border-radius: 50%;
        border: 2px solid #fff;
        padding: 5px;
    }

}

#user-data-details {

    position: relative !important;
    overflow: hidden !important;

    min-width: var(--max-height);
    max-width: var(--max-height);

    max-height: var(--max-height);
    min-height: var(--max-height);

    font-weight: 900;
    text-transform: uppercase;
    color: #fff;
}

#user-data {
    border-radius: 500px 0% 0% 500px;
    color: $neutral--darkest;
    background-color: rgba(0, 0, 0, 0.5) !important;
    background-size: cover !important;
    background-position: right !important;
    background-repeat: no-repeat !important;
    object-fit: cover !important;

}

#user-data-title {
    h1 {
        color: #fff !important;
    }
}

#user-latest-stats {
    h1 {
        letter-spacing: 2px;
    }

    background-color: $neutral--darker;
}

#user-latest-stats-right {
    min-height: 100%;
}

#user-latest-stats-left {
    min-height: 100%;
}

#progress-card {
    background-color: $neutral--darkest;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px,
        rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px,
        rgba(0, 0, 0, 0.09) 0px -3px 5px;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    font-weight: 900;
    text-transform: uppercase;
}

#progress-card-type {
    p {
        color: $soft-green;
        padding: 1px 10px;
        margin-right: 5px;
        border: 1px solid $soft-green;
        text-align: center;
        min-width: 50%;
        font-weight: 100;
    }

}

#chapter-details {
    font-weight: 100;
}

#user-latest-stats-content {
    border-right: 5px solid $neutral--darkest;
    border-left: 5px solid $neutral--darkest;
}

#user-data-details-overlay {
    width: 100%;
    height: 100%;
    padding-left: 30px;
    border-radius: 500px 0% 0% 500px;
    background-color: rgba(0, 0, 0, 0.5);
}

#chapter-completion-bar,
#lab-completion-bar {
    width: 100%;
    min-height: 20px;
    max-height: 20px;
    background-color: $neutral--dark;
    position: relative;
}

#chapter-completion-bar::after,
#lab-completion-bar::after {
    content: var(--completion);
    position: absolute;
    top: 50%;
    left: 50%;
    color: #000;
    transform: translate(-50%, -50%);
}

#chapter-completion-bar--progress,
#lab-completion-bar--progress {
    width: var(--completion);
    height: 100%;
    position: absolute;
    background-color: red;
    top: 0;
    left: 0;
    background: linear-gradient(to right,
            #3fffa2 0%,
            #ffdb3a 35%,
            #ff993a 60%,
            #e5405e 100%);
}

#graphs-container {
    padding: 0px;
    background-color: $neutral--dark;
}

#graph-overlay-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $neutral--darker;
    cursor: pointer;
    padding: 10px;

    &:hover {
        background-color: $neutral--darkest;
    }
}

#graph-overlay-arrow i {
    border-color: #fff;
}

.graph-overlay {
    position: absolute;
    right: 0 !important;
    top: 0 !important;
    height: 100% !important;
    width: 0% !important;
    background-color: $neutral--darker;
    z-index: 100;
    border-radius: 1000px 0% 0% 1000px;
    overflow: hidden !important;
    transition: width 0.5s ease-in-out,
        border-radius 0.5s ease-in-out 0.025s;

    &.active {
        width: 100% !important;
        border-radius: 0%;
    }
}

.arrow {
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.lp-modal-content {
    --max-height: 700px;
    --max-width: 768px;

    position: relative;
    background-color: $neutral--darkest;

    min-height: var(--max-height);
    max-height: var(--max-height);
}

.compliance-overlay-btns {
    flex: 0 2 auto !important;
    margin-top: auto !important;
    margin-bottom: 2px !important;
}

.compliance-quiz-btn-content {
    position: relative !important;
    overflow: hidden !important;
    width: 100% !important;
    height: 100% !important;

    white-space: nowrap;

    h2 {
        font-weight: 900;
        font-size: 18px;
    }
}

.compliance-quiz-btn-wrapper {
    position: absolute !important;
    background-color: darken($neutral--darkest, 10) !important;

    max-width: 20% !important;
    width: 0% !important;
    height: 100px !important;

    right: 1% !important;
    bottom: 1% !important;
    transition: all 0.5s ease-in-out;

    &.active {
        width: calc(20 / 100 * 100%) !important;
        box-shadow: darken($neutral--darkest, 10) 0px 5px 15px;
    }

}

.compliance-arrow-container {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0px;
    background-color: $neutral--dark;
    transition: all 0.5s ease-in-out;
    cursor: pointer !important;
    z-index: 10;

    &:hover {
        background-color: lighter($neutral--dark, 20);
    }

    &.active {
        width: 20px !important;
    }
}

.arrow {
    border: solid #000;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

#chapter-li {
    margin: 10px;
}

#lab-li {
    margin: 10px;
}

#labs-content {
    overflow: hidden;
    max-height: calc(calc(var(--max-height) - calc(calc(16px * 4) + calc(20px * 4))) / 2);
    min-height: calc(calc(var(--max-height) - calc(calc(16px * 4) + calc(20px * 4))) / 2);
}

#chapters-content {
    overflow: hidden;
    max-height: calc(calc(var(--max-height) - calc(calc(16px * 2) + 20px)) / 2);
    min-height: calc(calc(var(--max-height) - calc(calc(16px * 2) + 20px)) / 2);
}

#labs-content,
#chapters-content {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: scroll;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

i {
    color: $soft-green;

}

#green {
    color: $soft-green !important;
}

#red {
    color: $soft-red !important;
}

#orange {
    color: $soft-orange !important;
}

#blue {
    color: $soft-blue !important;
}

ul {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

li {
    list-style-type: none;
    letter-spacing: 0.5px;
    padding: 10px;
    cursor: pointer;
    transition: all 200ms linear;

    white-space: nowrap;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px,
        rgba(0, 0, 0, 0.09) 0px 4px 2px,
        rgba(0, 0, 0, 0.09) 0px 8px 4px,
        rgba(0, 0, 0, 0.09) 0px 16px 8px,
        rgba(0, 0, 0, 0.09) 0px 32px 16px;

    p {
        white-space: normal;
        text-justify: center !important;
        text-align: justify !important;
    }

    &:nth-child(even) {
        background-color: $neutral--medium;
    }

    &:nth-child(odd) {
        background-color: $neutral--dark;
    }

    &.active {
        background-color: $soft-green !important;
        box-shadow: rgba($soft-green, 0.3) 0px 0px 0px 3px;
        color: #000 !important;

        i,
        h2 {
            color: #000;
        }
    }
}

#chapter-completion-bar,
#lab-completion-bar {
    width: 100%;
    min-height: 20px;
    max-height: 20px;
    background-color: $neutral--smooth;
    position: relative;
}

#chapter-completion-bar::after,
#lab-completion-bar::after {
    content: var(--completion) !important;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
}

#chapter-completion-bar--progress,
#lab-completion-bar--progress {
    width: var(--completion) !important;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to right,
            #3fffa2 0%,
            #ffdb3a 35%,
            #ff993a 60%,
            #e5405e 100%);
}

.lab--difficulty {
    width: 100% !important;
    height: 20px !important;
    background-color: $neutral--darkest !important;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.lab--difficulty--progress {
    position: absolute;
    height: 100% !important;
    width: var(--difficulty) !important;
    border-radius: 10px;
    background: linear-gradient(to right,
            #3fffa2 0%,
            #ffdb3a 35%,
            #ff993a 60%,
            #e5405e 100%);
}

.lab--difficulty::after {
    content: var(--difficulty);
    position: absolute;

    top: 50% !important;
    left: 50% !important;

    font-weight: 500 !important;
    transform: translate(50%, -50%);
}

.chapter-content-wrapper {
    max-width: var(--max-width);
    max-height: var(--max-height);
    min-height: var(--max-height);

    overflow: scroll;
}

.chapter-content::v-deep {
    text-justify: center !important;
    text-align: justify !important;

    h6,
    h5,
    h4,
    h3,
    h2,
    h1 {
        text-transform: uppercase;
        color: $soft-green;
    }

    h1 {
        font-weight: 500;
        font-size: 25px;
    }

    h2 {
        font-weight: 500;
        font-size: 23px;
    }

    h3 {
        font-weight: 500;
        font-size: 20px;
    }
}

.li-title {
    color: $soft-green;

    .active {
        color: black;
    }
}

#xmark {
    position: absolute;
    top: 2%;
    right: 1%;
    cursor: pointer;
}

.modal-graph-overlay-wrapper {
    position: relative !important;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    max-height: 400px !important;
    min-height: 400px !important;

    max-width: 754px !important;
    min-width: 754px !important;

    width: 100% !important;
    height: 100% !important;
}

@media screen and (max-width: 768px) {


    .xmark-container {
        position: absolute;
        top: 1%;
        right: 9%;
    }

    .lp-modal {
        --max-height: 800px;
        --max-height-propic: 250px;

        min-width: 100% !important;
        max-width: 100% !important;

        margin-top: 100px;
        position: fixed !important;
        z-index: 100000 !important;

        min-height: var(--max-height) !important;
        max-height: var(--max-height) !important;

        left: 0% !important;
        top: 0% !important;
    }

    #header-content {
        max-height: 400px;
    }

    .modal-graph-overlay-wrapper {

        max-height: 300px !important;
        min-height: 300px !important;
    }

    #pwnx-graph {
        width: 100% !important;
        min-width: 100% !important;
    }

    #graphs-container {
        position: relative !important;
        width: 100% !important;
        margin: 0px !important;
        padding: 0px !important;

        overflow-x: scroll !important;
        background-color: $neutral--dark !important;
    }

    .modal-graph {
        width: 100% !important;
        min-width: 100% !important;
        overflow-x: scroll !important;
    }

    .modal-next-overlay {
        width: 100% !important;
        position: absolute !important;
        left: 0% !important;
        transform: translateX(-100%);
        background-color: $neutral--darkest !important;
        transition: all 0.5s ease;


        &.active {
            transform: translateX(0%);
        }
    }

    .li-title {
        overflow: hidden !important;
        max-width: 150px !important;
        text-overflow: ellipsis !important;
    }

    .compliance-quiz-btn-wrapper {
        max-width: 80% !important;

        &.active {

            width: calc(80 / 100 * 100%) !important;
        }
    }

}

pre>code::v-deep {
    color: white !important;
    max-width: 100% !important;
    overflow-x: hidden !important;
}

#course-selector {
    line-height: 1 !important;
    text-transform: uppercase !important;
    font-weight: 500 !important;
}

#next-solve-left {
    top: -50%;
    left: -5%;
    z-index: 1;
    cursor: pointer;
    border-width: 0px 5px 5px 0px;
    transition: all 1s ease-in-out;

    &:hover {
        background-color: $neutral--dark;
    }
}

#next-solve-right {
    top: -50%;
    right: -5%;
    z-index: 1;
    cursor: pointer;
    transition: all 1s ease-in-out;
    border-width: 0px 5px 5px 0px;

    &:hover {
        background-color: $neutral--dark;
    }
}

.graph {
    width: 100% !important;
    height: 300px !important;
    max-width: 100% !important;
    max-height: 300px !important;
}
</style>
<script>
import companyService from "@/api/company";
import coursesService from "@/api/courses";
import labService from "@/api/lab";
import userService from "@/api/user";
import { mapState } from "vuex";

import { marked } from "marked";
import ECharts from "vue-echarts";

import AllowanceModal from "@/components/AllowanceModal";
import LocalSpinner from "@/components/LocalSpinner";
import PxButton from "@/components/PxButton";
import QuizModal from "@/components/QuizModal";
import SurveyModal from "@/components/SurveyModal";
import FlagSubmissionModal from "./FlagSubmissionModal.vue";

export default {
    name: "LearningPathModal",
    components: {
        "v-chart": ECharts,
        PxButton,
        LocalSpinner
    },
    data() {
        return {
            courseLabs: [],
            labGlobal: false,
            ntsPlaceholder: false,
            lspPlaceholder: false,
            timer: null,
            isInit: false,
            courseId: this.injectedCourseId,
            changesCount: 0,
            timerInterval: null,
            sLabs: [],
            sChapter: null,
            sChapterLoading: false,
            sChapters: [],
            sChaptersNotSolved: [],
            sChaptersNotSolvedIndex: 0,
            sChaptersLoading: false,
            sChapterLabs: [],
            sChapterComplete: false,
            showLabs: true,
            latestOneshot: null,
            latestNormal: null,
            latestChapter: null,
            latestChapterLoading: false,
            hasNeverSolved: false,
            sChapterId: null,
            sChapterName: null,
            sChapterSolved: false,

            failThreshold: 3,
            failThresholdCount: 0,

            startingId: null,
            startedLab: null,

            labStarted: false,
            labStarting: false,

            labsLoading: false,
            cOverlay: true,

            startInterval: null,

            isApplicable: false,
            isParticipating: false,
            complianceEndTimestamp: null,

            modalSelector: false,
            coursesIds: [],

            graphOverlay: false,
            graphs: [
                {
                    id: "left",
                    isEmpty: function () {
                        const series = this.series;

                        for (const serie of series) {
                            if (serie.data.length > 0) return false;
                        }

                        return true;
                    },
                    // eslint-disable-next-line no-unused-vars
                    prepare: function (data) {
                        const answers = data["answers"];
                        const months = Object.keys(answers);
                        const values = Object.values(answers);


                        this.xAxis.data = months

                        this.series[0].data = []
                        this.series[1].data = []

                        for (const value of values) {
                            this.series[0].data.push(+value.correct);
                            this.series[1].data.push(+value.wrong);
                        }

                    },
                    chosenDate: null,
                    title: {
                        text: "User correct / wrong answers",
                        textStyle: {
                            color: "#fff"
                        }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: [],
                        axisLabel: {
                            textStyle: {
                                color: "#fff"
                            }
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            textStyle: {
                                color: "#fff"
                            }
                        }
                    },
                    legend: {
                        data: ["correct", "wrong"],
                        textStyle: {
                            color: "#fff"
                        },
                    },
                    series: [
                        {
                            name: "correct",
                            data: [],
                            type: 'line',
                            label: {
                                show: true,
                            },
                            textStyle: {
                                color: "#fff"
                            },
                            areaStyle: {}
                        },
                        {
                            name: "wrong",
                            data: [],
                            type: 'line',
                            label: {
                                show: true,
                            },
                            textStyle: {
                                color: "#fff"
                            },
                            areaStyle: {}
                        }
                    ],
                    color: [
                        "#478FBB",
                        "#5554BE",
                    ],
                    days: [], months: []
                },
                {
                    id: "right",
                    isEmpty: function () {
                        const xAxis = this.xAxis;
                        const series = this.series;

                        const xAxisLength = xAxis.data.length;
                        const seriesLength = series.length;

                        if (xAxisLength > 0) {
                            return false;
                        }
                        if (seriesLength > 0) {
                            return false;
                        }

                        return true;
                    },
                    chosenDate: null,
                    // eslint-disable-next-line no-unused-vars
                    prepare: function (data) {
                        const series = this.series;

                        const courses = data.courses;
                        const answers = courses.answers;
                        const chapters = courses.chapters;
                        const labs = data.labs;

                        const dates = Object.keys(answers);
                        this.months = dates;

                        const courseData = Object.values(answers)
                        courseData.map((item, i) => item + Object.values(chapters)[i]);

                        series.push({
                            name: "labs",
                            type: "line",
                            label: {
                                show: true,
                            },
                            data: Object.values(labs)
                        });

                        series.push({
                            name: "courses",
                            type: "line",
                            label: {
                                show: true,
                            },
                            data: courseData
                        })

                        this.xAxis.data = [...dates]
                        this.series = series
                    },
                    title: {
                        text: "Users completed courses",
                        textStyle: {
                            color: "#fff"
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        }
                    },
                    legend: {
                        data: ["courses", "labs"],
                        textStyle: { color: "#fff" }
                    },
                    xAxis: {
                        type: 'category',
                        data: [],
                        axisLabel: {
                            textStyle: {
                                color: "#fff"
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            textStyle: {
                                color: "#fff"
                            }
                        }
                    },
                    series: [],
                    color: [
                        "#DE5A08",
                        "#6C68FF",
                    ],
                    days: [],
                    months: []
                },
            ],
            radarIsLoading: false,
            radar: {
                labelPosition: function () {
                    return [
                        {
                            name: "Chapters",
                            index: 1,
                        },
                        {
                            name: "Answers",
                            index: 4,
                        },
                        {
                            name: "Labs",
                            index: 7,
                        }
                    ]
                },
                isEmpty: function () {
                    const series = this.series;

                    for (const serie of series) {
                        for (const element of serie.data) {
                            if (element.value.length > 0) return false;
                        }
                    }

                    return true;
                },
                /** 
                 * @param {Object} data
                 * @param {Vue} vctx
                 */
                // eslint-disable-next-line no-unused-vars
                prepare(data, { vctx = null }) {
                    this.radar.indicator = [];
                    this.series = [];

                    const indicator = this.radar.indicator;
                    const seriesData = [];
                    let indicatorMax = 0;

                    for (const course of Object.values(data)) {
                        const chapters = course.chapters;
                        const completion = course.completion;
                        const courseName = course.name;

                        if (indicatorMax < chapters) {
                            indicatorMax = chapters;
                        }

                        indicator.push({ name: courseName, max: chapters });
                        seriesData.push(completion);
                    }

                    for (const element of indicator) {
                        if (element.max !== 0) continue;
                        element.max = indicatorMax;
                    }

                    this.series.push({
                        name: "Courses / Labs Completion",
                        type: "radar",
                        data: [{ value: seriesData, name: "Completion" }]
                    })
                    vctx.radarIsLoading = false;
                },
                title: {
                    show: false
                },
                legend: {
                    show: false
                },
                radar: {
                    indicator: [],
                    axisLine: { show: false }
                },
                series: []
            }
        }
    },
    beforeDestroy() {
        this.clearIntervals();
    },
    created() {
        this.isInit = true;
        this.getData();
    },
    methods: {

        openFlagSubmitModal(labId) {
            const flagType = "user";
            this.$modal.show(FlagSubmissionModal, {
                flagType,
                labId,
                onClose: () => {}
            }, { height: 'auto'})
        },
        async downloadLabTemplate(labId) {
            this.$parent.loading = true;

            const [lab] = this.labsList.filter(lab => lab.id === labId);
            if (!lab) return

            const labName = lab.name.replace(" ", "-")
            const labBytes = await labService.downloadLabTemplate(labId)
                .then((response) => response.data);

            const buffer = await labBytes.arrayBuffer();
            const blob = new Blob([buffer], { type: "application/zip" })
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement("a");
            link.href = url;
            link.download = `${labName}.zip`;
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
            this.$parent.loading = false;

        },
        async toggleLabGlobal() {
            this.labGlobal = !this.labGlobal
            if (!this.labGlobal) return
            await this.getCourseLabs()
        },
        closeGraphOverlay() {
            this.courseId = null;
            this.graphOverlay = false;
        },
        async chapterDetails(chapter, show = true) {
            if (this.hasNeverSoved) return
            // const chapterId = chapter.id;
            console.log({ chapter })

            this.courseId = chapter.courseId;
            if (show) this.graphOverlay = true;

            // this.getLabsByChapterId(chapterId, show);
            // this.getChapter(chapterId, show)
        },
        updateNextSolved(mode = "next") {
            if (this.hasNeverSolved) return;
            const length = this.sChaptersNotSolved.length;
            const index = this.sChaptersNotSolvedIndex;

            if (mode === "next") {
                this.sChaptersNotSolvedIndex = Math.min(index + 1, length - 1);
                return
            }

            this.sChaptersNotSolvedIndex = Math.max(0, index - 1);
        },
        async getLabWriteup(labId) {
            return await labService.getLabWriteup(labId)
                .then((response) => response.data)
                .catch(() => { })
        },
        async acceptWriteupAllowance(labId) {
            const response = await labService.acceptAllowance(labId)
            const isError = !!response.data.errorCode && response.data.errorDescription;

            if (isError) {
                const message = response.data.errorDescription;
                this.flashError(message)
                return;
            }

            const message = response.data.message;
            this.flashSuccess(message);
            await this.getLabWriteup(labId);
        },
        async handleGetWriteup(lab) {

            const labId = lab.id;
            const allowance = lab.allowance;

            if (allowance) {
                return await this.getLabWriteup(labId);
            }

            this.openWriteupAllowanceModal(lab);
        },
        async copyIpAddress(labId) {
            const labType = "FREE";
            labService.getStartedLabIps(labType, null, "notglobal")
                .then((response) => {
                    const ips = response.data.ips;

                    const ip = ips[labId];
                    if (!ip) {
                        this.flashError("IP Not available yet, please try again later");
                        return
                    }

                    navigator.clipboard.writeText(ip);
                    this.flashSuccess(ip + " copied to clipboard.");
                });
        },
        openWriteupAllowanceModal(lab) {
            const labId = lab.id;
            this.$modal.show(
                AllowanceModal,
                {
                    lab,
                    openWriteup: () => this.getLabWriteup(labId),
                    stopLoading: () => {
                        this.$parent.loading = false;
                    }
                },
                { height: "auto" }
            )
        },
        downloadVPN(labId) {
            this.$parent.loading = true;
            labService.downloadVPN(labId, this.tokenBased, this.user.id)
                .then((response) => {
                    const blob = new Blob([response.data], { type: "text/plain" });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = "config.ovpn";
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url);
                })
                .catch((err) => {
                    let error = null;
                    if (err.data) {
                        error = err.data
                    } else {
                        error = err.response.data;
                    }
                    const manageableError = !!error.errorCode && !!error.errorDescription;
                    if (manageableError) this.flashError(error.errorDescription);
                })
                .finally(() => {
                    this.$parent.loading = false;
                })
        },
        isStarting(labId) {
            const lab = this.startedLab;
            const startingId = this.startingId;

            if (!startingId) return false;
            if (lab) return false;
            return startingId === labId;
        },
        isStarted(labId) {
            const lab = this.startedLab;
            const started = this.labStarted;

            if (!lab) return false;
            return started && lab.id === labId;
        },
        toggleCOverlay() {
            this.cOverlay = !this.cOverlay;
        },
        async startComplianceQuiz() {
            this.selfClose();

            const data = await companyService.getUserComplianceQuiz()
                .then((response) => response.data)

            const questions = data.questions;
            const timestamp = data.end;
            const companyName = this.user.company_name;

            this.$modal.show(
                QuizModal,
                {
                    questions: questions,
                    title: `${companyName} - QUIZ`,
                    timestamp: function () { return timestamp },
                    completeCallback: () => { },
                    saveResponseCallback: async (data) => {
                        return await companyService.saveUserComplianceResponse(data)
                    },
                    eventId: null,
                },
                { height: 'auto', width: '80%' }
            )
        },
        async startChapterQuiz() {
            const chapter = this.getChapterObject(this.sChapterId);
            const chapterId = chapter.id;
            const chapterName = chapter.name;
            if (chapter.is_completed) {
                this.flashError("Cannot start quiz, you've already solved this chapter");
                return;
            }
            console.log({ chapterId })
            const survey = await userService.getSurvey(chapterId).then(resp => resp.data.questions);
            this.$modal.show(
                SurveyModal,
                {
                    survey: survey,
                    chapterId: chapterId,
                    surveySaved: () => {
                        window.location.reload();
                    },
                    title: chapterName
                },
                { height: "auto", width: "60%" }
            )
        },
        async getChapter(chapterId, init = false) {
            if (!chapterId) return;
            this.sChapterLoading = true;
            coursesService.getChapter(chapterId)
                .then((response) => {
                    const content = response.data.chapter.content;
                    if (!content) return;
                    if (!init) this.showLabs = false;

                    this.sChapterComplete = response.data.complete;
                    this.sChapter = marked(content);
                    this.sChapterLoading = false;
                })
        },
        getChapterObject(chapterId) {
            const [chapter] = this.sChapters.filter(chapter => chapter.id === chapterId);
            return chapter;
        },
        async getLabsByChapterId(chapterId, show) {

            if (show) {
                this.labsLoading = true;
                this.showLabs = true;
            }
            console.log({ chapterId })
            this.sChapterId = chapterId;
            const chapter = this.getChapterObject(chapterId);
            if (chapter) {
                this.sChapterName = chapter.name || null;
                this.sChapterSolved = chapter.is_solved || false;
            }

            coursesService.getLabsByChapterId(chapterId)
                .then((response) => {
                    this.sChapterLabs = response.data;
                })
                .finally(() => {
                    this.labsLoading = false;
                })
            this.getChapter(chapterId, true)
            if (!this.labGlobal) return
            await this.getCourseLabs()
        },
        async makeRequests() {
            const requests = [
                userService.getAllLatestSolved(null)
            ]

            return await Promise.allSettled(requests)
        },
        async getData() {
            const userId = this.user.id;

            this.sChaptersLoading = true;
            this.latestChapterLoading = true;
            this.radarIsLoading = true;

            this.makeRequests()
                .then(async ([latest]) => {

                    const lab = latest.value.data.lab;
                    const chapter = latest.value.data.course;

                    const latestOneshot = lab.oneshot;
                    const latestNormal = lab.normal;

                    const latestChapter = chapter;

                    this.latestOneshot = latestOneshot;
                    this.latestNormal = latestNormal;
                    this.latestChapter = latestChapter;
                    this.hasNeverSolved = latestChapter === null;
                    this.latestChapterLoading = false;

                    const courses = this.courses;

                    let courseId = this.courseId;
                    if (this.courseId !== null) {
                        console.log({ courseId })
                        const [course] = courses.filter(course => course.id === this.courseId)
                        if (course && course.chapters) {
                            const chs = course.chapters;
                            courseId = this.courseId;
                            this.chapterDetails(chs.length > 0 ? chs[0] : null, true)
                        }

                        this.graphOverlay = true;
                        this.isInit = false
                    }

                    if (this.isInit && courses.length > 0) {
                        const course = courses[0];
                        const chs = course.chapters;
                        if (chs.length > 0) {
                            const ch = chs.length > 0 ? chs[0] : null
                            this.chapterDetails(ch, false);
                        }
                    }

                    this.isInit = false;

                    const newRequests = [
                        companyService.getCompanyUserSuggestedLabs(userId, courseId),
                    ]

                    return await Promise.allSettled(newRequests);

                })
                .then(async ([suggested]) => {

                    const labs = suggested.value.data.suggestions;
                    this.sLabs = labs;
                    const newReqs = [companyService.getMonthlyLabsProgress("all", userId)]

                    return await Promise.allSettled(newReqs)
                })
                .then(async ([monthly]) => {
                    const graphs = this.graphs;

                    const monthlyData = monthly.value.data.data;

                    for (const graph of graphs) {
                        graph.prepare(monthlyData, { vctx: this });
                    }

                    const newReqs = [
                        companyService.getCompanyUserSuggestedChapters(userId, this.courseId),
                    ]
                    return await Promise.allSettled(newReqs);
                })
                .then(async ([suggestions]) => {

                    const chapters = suggestions.value.data.suggestions;

                    this.sChapters = chapters;
                    const latestChapter = this.latestChapter;
                    if (latestChapter) this.sChaptersNotSolved = chapters.filter(chapter => !chapter.is_completed && chapter.id !== latestChapter.id);
                    if (!latestChapter) this.sChaptersNotSolved = []
                    this.sChaptersLoading = false;

                    let chapterId = null;
                    if (chapters.length > 0 && this.chapterId === null) {
                        chapterId = chapters[0].id;
                    } else if (chapters.length > 0 && this.chapterId !== null) {
                        chapterId = this.chapterId;
                        this.graphOverlay = true;
                    }

                    this.sChapterId = chapterId

                    const newReqs = [
                        companyService.getCompanyCoursesChart(),
                    ];
                    return await Promise.allSettled(newReqs);
                })
                .then(async ([chart]) => {
                    const data = chart.value.data.chart
                    const radar = this.radar;
                    radar.prepare(data, { vctx: this });

                    const chapterId = this.sChapterId;
                    const newReqs = [
                        this.getLabsByChapterId(chapterId),
                        this.getChapter(chapterId, true),
                    ]
                    return await Promise.allSettled(newReqs);

                })
                .then(async () => await this.handleLabStarting(null))
                .then(async () => await this.getUserApplicability())
                .finally(() => {
                    const { nts, lsp } = this.checkPlaceholders()

                    this.ntsPlaceholder = nts;
                    this.lspPlaceholder = lsp;
                })

        },
        checkPlaceholders() {
            const nts = this.notSolvedChapter === null;
            const lsp = this.latestChapter === null || this.latestChapter === undefined;

            return { nts, lsp }
        },
        async checkStarting(labId = null) {
            const labType = "FREE"; // default
            return await labService.getStartedLabs(labType, labId)
                .then(resp => resp.data.labs)
                .catch(() => ([]))
        },
        async handleLabStarting(labId) {
            this.startingId = labId;

            this.startInterval = await new Promise(res => {
                const interval = setInterval(() => {
                    this.checkStarting().then((starting) => {
                        if (starting.length > 0) {
                            this.failThresholdCount = 0;
                            const startedLab = starting[0]
                            this.startedLab = startedLab;
                            this.labStarted = true;
                            this.clearIntervals();
                        } else {
                            this.startingId = null;
                            this.labStarted = false;
                            this.failThresholdCount += 1;
                        }

                        if (this.failThresholdCount === this.failThreshold) {
                            this.startingId = null;
                            this.startedLab = null;
                            this.labStarted = false;
                            this.labStarting = false;
                            this.clearIntervals();
                        }

                        res(interval);
                    })
                }, 10000)
            })

        },
        async labAction(labId, action = "stop") {
            action = String(action).toLowerCase();

            const userId = this.user.id;
            const isStarting = action === "start";

            labService.labAction(labId, action, {}, false, userId)
                .then(async (response) => {
                    const success = !!response.data && !!response.data.message;
                    if (success) {
                        const message = response.data.message;
                        this.labStarting = isStarting;

                        this.flashSuccess(message)
                        if (!isStarting) {
                            this.startedLab = null;
                            this.labStarting = false;
                            this.labStarted = false;
                            this.startingId = null;
                            return;
                        }

                        await this.handleLabStarting(labId)
                    }
                })
                .catch((error) => {
                    const manageableError = !!error.data && !!error.data.errorDescription;

                    if (manageableError) {
                        const message = error.data.errorDescription;
                        this.flashError(message)
                    }
                })
                .finally(() => { }) // Intervals ? 
        },
        clearIntervals() {
            clearInterval(this.startInterval);
            clearInterval(this.timerInterval);
        },
        selfClose() {
            this.$emit("close");
        },
        formatDate(timestamp) {
            const date = new Date(timestamp)
            const minute = date.getMinutes();
            const second = Math.max(Math.floor(date.getSeconds() % 60), 0);
            return [
                minute < 10 ? "0" + String(minute) : minute,
                second < 10 ? "0" + String(second) : second,
            ].join(":")
        },
        async getUserApplicability() {
            await companyService.getUserComplianceApplicability()
                .then((response) => {
                    const timestamp = response.data.end;
                    this.isApplicable = response.data.applicable;

                    if (timestamp !== null) {
                        this.isParticipating = true;
                        this.complianceEndTimestamp = (timestamp * 1000);
                        this.timer = (timestamp * 1000) - new Date().getTime();
                        this.startTimer();
                    }
                })
        },
        startTimer() {
            if (this.timer < this.complianceEndTimestamp) {
                this.timerInterval = setInterval(() => { this.timer -= 1000 }, 1000);
            } else {
                this.clearIntervals()
                this.isParticipating = false;
                this.complianceEndTimestamp = null;
                this.timer = null;
            }
        },
        async getCourseLabs() {
            const courseId = this.courseId
            this.courseLabs = await coursesService.getCourseLabs(courseId)
                .then((res) => res.data)
        }
    },
    computed: {
        labsList() {
            const labGlobal = this.labGlobal;
            if (!labGlobal) return this.sChapterLabs;
            return this.courseLabs;
        },
        notSolvedChapter() {
            const notSolved = this.sChaptersNotSolved;
            if (notSolved.length === 0) return null;
            const index = this.sChaptersNotSolvedIndex;
            return notSolved[index] || null;
        },
        lab() {
            const labs = Object.values(this.sLabs);
            if (!labs || labs.length === 0) return null;
            const index = Math.max(0, Math.floor(Math.random() * labs.length - 1));
            return labs[index];
        },
        chapter() {
            const chapters = this.sChapters;
            if (!chapters || chapters.length === 0) return null;
            const index = Math.max(0, Math.floor(Math.random() * chapters.length - 1));
            return chapters[index];
        },
        ...mapState({
            user: state => state.user
        })
    },
    props: {
        courses: {
            type: Array,
            required: true
        },
        stats: {
            type: Object,
            required: true
        },
        image: {
            type: String,
            required: true
        },
        chapterId: {
            type: String,
            required: false,
        },
        injectedCourseId: {
            type: String,
            required: false,
            default: null,
        }
    },
    watch: {
        labGlobal: {
            handler: function (val) {
                if (!val) return
            },
            immediate: false,
        },
        courseId: {
            handler: function () {
                console.log("changes")
                const count = this.changesCount;
                if (count === 0) {
                    this.changesCount += 1;
                    return
                }
                this.getData();
            },
            immediate: false,
        }
    }
}
</script>
<style scoped lang="scss">
@import "@/assets/css/colors.scss";

.placeholder-box {
    height: 20px;
    background-color: $neutral--darker;
    border-radius: 600px;
    margin: 10px 10px;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: $soft-green;
}

input:focus+.slider {
    box-shadow: 0 0 1px $soft-green;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

</style>
