<template>
  <base-modal 
    :title="modal.title"
    :buttons="modal.buttons"
    :close="modal.close">
    <form @submit.prevent class="my-3">
      <div class="form-group">
        <label class="card__label" for="event-name">
          event name
        </label>
        <input v-model="eventData.name" disabled type="text" class="form-control" id="event-name" name="event-name" placeholder="EVENT NAME" />
      </div>
      <div class="selector">
        <label for="event-role" id="event-role" class="card__label">event role</label>
        <select name="event-role" id="event-role" v-model="eventData.eventRole" class="form-control my-2">
          <option v-for="(evRole, index) of evRoles" :value="evRole" :key="index">{{evRole}}</option>
        </select>
      </div>
    </form>
  </base-modal>
</template>
<script>
import { mapState } from "vuex";
import BaseModal from "@/components/BaseModal";
import hrpService from "@/api/hrp";

export default {
  name: "CompanyEventCreateModal",
  props: {
    evRoles: {
      type: Array,
      required: true
    },
    createdCallback: {
      type: Function,
      required: false,
      default: () => {}
    },
    evRoleDefault: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      modal: {
        title: "Company Event Create",
        buttons: [
          {
            name: "Create",
            class: "px-button--success",
            action: () => this.createEvent()
          },
          {
            name: "Close",
            class: "px-button--danger",
            action: () => this.selfClose()
          }
        ],
        close: () => this.selfClose()
      },
      eventData: {
        name: null,
        eventRole: null
      },
    }
  },
  created() {
    const companyName = this.user.company_name;
    if (this.evRoles.length === 0) return this.selfClose()
    if (!this.evRoleDefault) {
      const role = this.evRoles[0];
      const eventName = [companyName, role.replaceAll("_", " ").toLowerCase()].join(" - ");
      this.eventData.name = eventName.toUpperCase()
      this.eventData.eventRole = role
    }
    if (this.evRoleDefault) {
      const role = this.evRoleDefault;
      const eventName = [companyName, role.replaceAll("_", " ").toLowerCase()].join(" - ")
      this.eventData.eventRole = role
      this.eventData.name = eventName.toUpperCase()
    }
  },
  components: {
    BaseModal
  },
  computed: mapState({
    user: state => state.user
  }),
  methods: {
    selfClose() {
      this.$emit("close");
    },
    createEvent() {
      const data = this.eventData;
      const isValid = Object.values(data).every(value => value !== null && String(value).trim() !== "")
      if (!isValid) {
        this.flashError("Invalid data provided")
        return 
      }
      this.$parent.loading = true;
      hrpService.createEvent(data)
        .then((response) => {
          const manageableMessage = !!response.data && !!response.data.message;
          if (manageableMessage) {
            this.flashSuccess(response.data.message);
          }
        })
        .catch((error) => {
          const manageableError = !!error.data && error.data.errorDescription;
          if (manageableError) this.flashError(error.data.errorDescription);
        })
        .finally(() => {
          this.$parent.loading = false;
          this.selfClose();
          this.createdCallback()
        })
    }
  },
  watch: {
    'eventData.eventRole': function(new_) {
      const role = new_;
      const companyName = this.user.company_name;
      const eventName = [companyName, role.replaceAll("_", " ").toLowerCase()].join(" - ")
      this.eventData.name = eventName.toUpperCase()
    }
  }
}
</script>
<style scoped lang="scss">
@import "../assets/css/colors.scss";
</style>
